import { setFilter } from "../amlaunderingUXSlice";
import { setData } from "../amlaunderingDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { AntiLaunderingReportFilter } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";

export const useAntiMnyLndcommReportFilter = () => {
  const storeFilter = useSelector<StoreType, AntiLaunderingReportFilter>(
    (store) => store.ux.reports.antiLaundering.filter
  );
  const [filter, editFilter] = useCopiedState<AntiLaunderingReportFilter>(
    storeFilter
  );

  const dispatch = useDispatch();
  const dispatchSetFilter = React.useCallback(() => {
    dispatch(setFilter(filter!));
    dispatch(setData());
  }, [dispatch, filter]);

  return [filter, editFilter, dispatchSetFilter] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter
  ];
};
