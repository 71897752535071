import React from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { PaymentSideSelect } from "./PaymentSideSelect";
import { PaymentDedicationSelect } from "./PaymentDedicationSelect";
import { DateInput } from "../../components/common/DateInput";
import { LocalizationContext } from "../../../core/localization/Localization";
import { PaymentFilter } from "../../../data/clearing/payments/types";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { useAuth } from "../../../data/auth/hooks";
import { PaymentFilterChipsComponent } from "./PaymentFilterChips";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { StringInput } from "../../components/common/StringInput";
import { FiltredTransactionsSelect } from "../../components/trading/FiltredTransactionsSelect";
import { SimpleTransFilter } from "../../../data/trading/transactions/types";
import { TransSimpleData } from "../../../data/trading/transactions/types";

export enum PaymentFilterEditorType {
  Basic,
  Extended,
}

interface PaymentFilterEditorProps {
//  filter: PaymentFilter;
  filter?: PaymentFilter;
  editFilter: (prop: keyof PaymentFilter, value: any) => void;
  save: () => void;
  type: PaymentFilterEditorType;
  reset?: () => void;
}

export const PaymentFilterEditor: React.FunctionComponent<PaymentFilterEditorProps> = ({
  filter,
  editFilter,
  save,
  type,
  reset,
}) => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const { getString } = React.useContext(LocalizationContext);
  const [auth] = useAuth();

	const [tradesFilter, setTransactionFilter] = React.useState<SimpleTransFilter | undefined>(
		createTransactionFilterPayment(filter)
	);
	const [selectedOrder, setSelectedOrder] = React.useState<TransSimpleData | null>(null);

  React.useEffect(() => {
		setTransactionFilter(createTransactionFilterPayment(filter));
	}, [filter, setTransactionFilter]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      editFilter(event.target.name as any, event.target.value);
    },
    [editFilter]
  );
  
  return (
    <Paper
      style={{
        paddingLeft: "70px",
        paddingRight: "70px",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Grid container direction="column" spacing={6}>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={6}>
            <DateInput
              label={getString("orderFilterFrom")}
              value={filter?.datefrom || null}
              onChange={(date) => editFilter("datefrom", date?.toISOString())}
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              label={getString("orderFilterTo")}
              value={filter?.dateto || null}
              onChange={(date) => editFilter("dateto", date?.toISOString())}
            />
          </Grid>

{/*           <Grid item xs={6}>
            <PaymentSideSelect
              handleChange={(e) => editFilter("inout", e.target.value)}
              side={filter?.inout}
              nullable
            />
          </Grid>
          <Grid item xs={6}>
            <PaymentDedicationSelect
              handleChange={(e) => editFilter("nondedicated", e.target.value)}
              sidededication={filter?.nondedicated}
              nullable
            />
    </Grid> */}


          {type === PaymentFilterEditorType.Extended &&
            auth.status === "successful" &&
            auth.user.userIsEmployee === 1 && (
              <Grid item xs={12}>
                <ClientSelect
                  label={getString("orderClientName")}
                  predicate={(cli) => cli.cliId === filter?.client?.cliId}
                  onChangeItem={(cli) => editFilter("client", cli)}
                />
              </Grid>
            )}
{/*
            <Grid item xs={4}>
 								<StringInput<PaymentFilter> 
                  property="tranId"
									item= {filter ? filter : {}}
									onChange={handleChange}
									label={getString("paymentTranNo")}
								/>
							</Grid> */}

        </Grid>  

        <Grid item xs={12}>
									<FiltredTransactionsSelect
										label={getString("paymentClientTransactions")}
										filter={tradesFilter}
										predicate={trans => trans.ordId === filter?.tranId}
										onChangeItem={trans => {
											setSelectedOrder(trans);
											editFilter("tranId", trans ? trans.ordId : undefined);
											editFilter("tranDesc", trans ? trans.ordData : undefined);
										}}
                    disabled={false}
								/>
  			</Grid>

        <Grid item container direction="row-reverse" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                save();
                showWithDrawer(undefined);
              }}
            >
              {getString("orderFilterConfirm")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text" onClick={reset}>
              {getString("filterReset")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const createTransactionFilterPayment = (filter?: PaymentFilter) => {
	return filter && filter.client && filter.client.cliId
		? {
				cliid: filter.client.cliId,
		  }
		: undefined;
};

export const PaymentFilterChips = PaymentFilterChipsComponent;
