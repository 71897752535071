import { webApi } from "../../../../core/http/httpThunks";
import { ClientListData } from "../types";

export const getAllClientsWebApi = (userId: string) =>
	webApi<ClientListData[]>({
		method: "GET",
		url: "listing/getclilist",
		params: {
			userId,
		},
	});

export default getAllClientsWebApi;
