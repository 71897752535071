import { NavigationItem } from "../../core/navigation/types";
import { attributeComponents } from "./attributes/navigationComponents";
import { bankComponents } from "./banks/navigationComponents";
import { cityComponents } from "./cities/navigationComponents";
import { clientsComponents } from "./clients/navigationComponents";
import { commisionsComponents } from "./commissions/navigationComponents";
import { countriesComponents } from "./countries/navigationComponents";
import { currencyComponents } from "./currencies/navigationComponents";
import { ratesComponents } from "./currencyRates/navigationComponents";
import { employeeComponents } from "./employees/navigationComponents";
import { productGroupComponents } from "./productGroups/navigationComponents";
import { productsComponents } from "./products/navigationComponents";
import { regionsComponents } from "./regions/navigationComponents";
import { rolesComponents } from "./roles/navigationComponents";
import { userComponents } from "./users/navigationComponents";
import { warehouseComponents } from "./warehouses/navigationComponents";
import { payTypeComponents} from "./payTypes/navigationComponents";

export const listingComponents: NavigationItem[] = [
    ...attributeComponents,
    ...bankComponents,
    ...cityComponents,
    ...clientsComponents,
    ...commisionsComponents,
    ...countriesComponents,
    ...currencyComponents,
    ...ratesComponents,
    ...employeeComponents,
    ...productGroupComponents,
    ...productsComponents,
    ...regionsComponents,
    ...rolesComponents,
    ...userComponents,
    ...warehouseComponents,
    ...payTypeComponents,
];