import { memo, useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import {
  ActionPerformed,
  PushNotifications,
} from "@capacitor/push-notifications";
import { useStoreTokenHooks } from "../../data/auth/useStoreTokenHooks";

const PUSH_NOTIFICATIONS_TOKEN_STORAGE_KEY = "PUSH-NOTIFICATION:TOKEN";

type Props = {
  isAuth: boolean;
  authToken: string;
};

export const PushNotificationProvider = memo((props: Props) => {
  const { isAuth, authToken } = props;

  console.log("PushNotificationProvider", isAuth, authToken);

  const [, dispatcheStoreToken] = useStoreTokenHooks();

  useEffect(() => {
    if (Capacitor.getPlatform() === "web") return;
    if (!isAuth || !authToken) return;

    const registrationListener = async () => {
      await PushNotifications.addListener("registrationError", (err) => {
        console.error("Registration error: ", err.error);
      });
      await PushNotifications.addListener("registration", (pushToken) => {
        console.info("Registration token: ", pushToken.value);
        if (
          localStorage.getItem(PUSH_NOTIFICATIONS_TOKEN_STORAGE_KEY) !==
          pushToken.value
        ) {
          localStorage.setItem(
            PUSH_NOTIFICATIONS_TOKEN_STORAGE_KEY,
            pushToken.value
          );
          console.log("Token isn't same");

          // Store token on backend
          // httpWebApi({
          // 	method: "POST",
          // 	url: "user/pushnotificationtoken",
          // 	data: {
          // 		token: pushToken.value,
          // 	},
          // }, authToken);

          dispatcheStoreToken(pushToken.value);
        }
      });
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification: ActionPerformed) => {
          try {
            console.log("Push received: " + JSON.stringify(notification));
          } catch (error) {
            console.log(
              "error from subscribing notification " + JSON.stringify(error)
            );
          }
        }
      );
    };

    registrationListener();

    /// TODO: add event listener on notification received, so i can turn it off if app is active ?
    // or prevent it maybe even?

    const check = async () => {
      let perm = await PushNotifications.checkPermissions();
      if (perm.receive !== "granted") {
        perm = await PushNotifications.requestPermissions();
      }

      if (perm.receive !== "granted") {
        console.log("User denied permissions!");
        return;
      }

      await PushNotifications.register();
    };

    check();

    // dispose
    return () => {
      PushNotifications.removeAllListeners();
    };
  },
    [isAuth, authToken]);

  return null;
});


