import { ThunkType } from "../../../../core/store/reducers";
import { getDisputeExcel } from "../api/getDisputeExcel";
import fileDownload from "js-file-download";
import { setFetchState } from "../disputeDataSlice";

export const downloadDisputeExcel = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }
  
  const { filter } = store.ux.clearing.disputes;
  const user = store.data.auth.current.user;

  dispatch(setFetchState({ type: "started" }));
  const dailyTrading = await dispatch(getDisputeExcel(user, filter));
  if (dailyTrading) {
    fileDownload(
      new Blob([dailyTrading as any], {
        type: "application/vnd.openxmlformat",
      }),
      "Dispute.xlsx"
    );
  }
  dispatch(setFetchState({ type: "not-started" }));
};
