import { ThunkType } from "../../../../../core/store/reducers";
import getAllClientPersons from "../../api/clientPerson/getAllClientPersons";
import { ClientPerson } from "../../types";

export const getClientPersons = (clientId: string): ThunkType<Promise<ClientPerson[] | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getAllClientPersons(userId, clientId));
}
