import { useDispatch, useSelector } from "react-redux";
import { downloadMonthlyExcel } from "../thunks/downloadMonthlyReportExcel";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";

export const useMonthlyExcelDownloader = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (s) => s.data.reports.monthlyTrade.downloadReportFetchState
  );

  const dispatch = useDispatch();
  const download = React.useCallback(() => dispatch(downloadMonthlyExcel()), [
    dispatch,
  ]);

  return [download, fetchState] as [typeof download, typeof fetchState];
};
