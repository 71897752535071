import { EditOrder } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";
import { ValidationState } from "../../../../core/util/types";
import moment from "moment";
import { OrderSide, Franco } from "../../../models";
import { useSelector } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { countDecimals } from "../../../../core/util/functions";

export const orderValidationState: ValidationState<EditOrder> = {
	userId: true,
	clientId: true,
	prodId: true,
	side: true,
	qty: true,
	price: true,
	orderAttrList: true,
	expiryDate: true,
	goodsReadyFrom: true,
	goodsReadyTo: true,
	franco: true,
	payTypeId: true,
};

export const maxExpiryDate = moment().add(30, "day").toDate();

export const useOrderValidator = () => {

	const [isWhsRequired] = useOrderWarehouseRequired();
	return createUseValidator<EditOrder, ValidationState<EditOrder>>(
		orderValidationState,
		(item, validation) => ({
			...validation,
			expiryDate:
				item.expiryDate !== undefined &&
				(moment(maxExpiryDate).isAfter(item.expiryDate) &&
					moment(item.expiryDate).isSameOrAfter(new Date(),'day')),
			orderAttrList:
				item.orderAttrList === undefined ||
				item.orderAttrList.every(
					att => (att.manditory === 0 ) || att.attrValue !== undefined || att.attrValId !== undefined
				),
//			orderAttrList:
//				item.orderAttrList === undefined ||
//				item.orderAttrList.every(
//					att => (att.manditory === 0 && item.side !== OrderSide.Sell) || att.attrValue !== undefined || att.attrValId !== undefined
//				),
			locCountryId: !!item.locCountryId || !isWhsRequired(item),
			locRegionId: !!item.locRegionId || !isWhsRequired(item),
			locDistrictId: !!item.locDistrictId || !isWhsRequired(item),
			locCityId: !!item.locCityId || !isWhsRequired(item),
			locWarehouseId: !!item.locWarehouseId || !isWhsRequired(item),
			price: validation.price && !!item.price && countDecimals(item.price) <= 2,
			qty: validation.qty && !!item.qty && countDecimals(item.qty) === 0,
			minQty: !item.minQty ? true : countDecimals(item.minQty) === 0,
			goodsReadyTo:  item.goodsReadyTo !== undefined && moment(item.goodsReadyTo).isSameOrAfter(item.goodsReadyFrom,'day'),
			paymentDays:  !item.payTypeId || item.payTypeId!=="00000001"  || (item.payTypeId==="00000001"  && item.paymentDays!== undefined && !(item.paymentDays && item.paymentDays<0)) ? true : false ,
//			active: item.active === 1,
		})
	)();
};


export const useOrderWarehouseRequired = () => {
	const francoList = useSelector<StoreType, Franco[]>(s => s.data.initialData.francoList);

	const isWhsRequired = React.useCallback(
		(order?: EditOrder) => {
			if (!order) {
				return false;
			}

			const franco = francoList.find(f => f.ftValue === order?.franco);
			return (
				franco &&
				((franco.ftType === 1 && order.side === OrderSide.Sell) ||
					(franco.ftType === 2 && order.side === OrderSide.Buy) ||
					franco.ftType === 3)
			);
		},
		[francoList]
	);

	return [isWhsRequired] as [typeof isWhsRequired];
};
