import React from "react";
import { useDispatch } from "react-redux";
import { AttributeValue } from "../../types";
import { getAttributeValue } from "../../thunks/attributeValues/loadAttributeValue";

export const useAttributeValue = (valueId?: string) => {
	const displatch = useDispatch();
	const [attribute, setAttribute] = React.useState<AttributeValue | undefined>();

	React.useEffect(() => {
		const loadClient = async () => {
			if (valueId) {
				const loadedAttribute = (await displatch(getAttributeValue(valueId))) as any;
				setAttribute(loadedAttribute);
			}
		};
		loadClient();
	}, [displatch, valueId, setAttribute]);

	return [attribute];
};
