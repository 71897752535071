import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import { TradeFilter } from "../../trading/trades/types";

type TradesReportState = {
	index: PageIndex;
	filter: TradeFilter;
};

export const initialState: TradesReportState = {
	index: {
		itemsPerPage: 20,
		pageNumber: 0,
	},
	filter: {},
};

const name = "tradesReportAll";

const tradesSliceAll = createSlice({
	name,
	initialState,
	reducers: {
		setIndex: (state, action: PayloadAction<PageIndex>) => ({
			...state,
			index: action.payload,
		}),
		resetIndex: state => ({
			...state,
			index: {
				itemsPerPage: state.index.itemsPerPage,
				pageNumber: initialState.index.pageNumber,
			},
		}),
		setFilter: (state, action: PayloadAction<TradeFilter>) => ({
			...state,
			filter: action.payload,
		}),
		removeFilter: (state, action: PayloadAction<keyof TradeFilter>) => ({
			...state,
			filter: {
				...state.filter,
				[action.payload]: undefined,
			},
		}),
	},
	extraReducers: builder => {
		builder.addCase(successfulLogin, state => ({
			...state,
			index: {
				itemsPerPage: state.index.itemsPerPage,
				pageNumber: initialState.index.pageNumber,
			},
		}));
	},
});

export const { setIndex, resetIndex, setFilter, removeFilter } = tradesSliceAll.actions;

export default tradesSliceAll.reducer;
