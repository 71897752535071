import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "./thunks";
import { AuthorizationState } from "./types";
import { StoreType } from "../../core/store/reducers";
import { useCallback } from "react";
import { resetLoginState } from "./authDataSlice";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const useAuth = () => {
	const dispatch = useDispatch();
	const authStatus = useSelector<StoreType, AuthorizationState>(store => store.data.auth.current);
	const token = useSelector<StoreType, string | undefined>(store => store.data.auth.token);

	const theme = useTheme();
	const source = useMediaQuery(theme.breakpoints.only('xs')) ? "M" : "";

	const loginCallback = useCallback(
		(username, password, language, source) => dispatch(loginUser(username, password, language, source)),
		[dispatch]
	);

	const logoutCallback = useCallback(() => dispatch(resetLoginState()), [dispatch]);

	return [authStatus, loginCallback, logoutCallback, token] as [
		typeof authStatus,
		typeof loginCallback,
		typeof logoutCallback,
		typeof token,
	];
};

export const useLastLoginUsername = () =>
	useSelector<StoreType, string>(store => store.ux.auth.lastLoginUsername || "");
