import { ValidationState } from "../../../core/util/types";
import { ClientListData } from "../../../data/listing/clients/types";

export enum PaymentSide {
  in = "IN",
  out = "OUT",
}

export enum PaymentDedication {
  yes = "Y",
  no = "N",
}

export interface PaymentListData {
  payId: string;
  payDate: Date;
  clientIdNo: string;
  clientName: string;
  payTypeDescr: string;
  orderNo: string;
  tranNo: string;
  amtPay: number;
  amtIn: number;
  amtOut: number;
  amtNonAllocBal: number;
  amtBal: number;
  amtTransBal: number;
  amtOrdersBal: number;
  currCode: string;
  status: number;
  grntId: string;
}

export interface GetPaymentParams {
  userid: string;
  inout?: PaymentSide;
  nondedicated?: PaymentDedication;
  //currid?: string;
  cliid?: string;
  //  paytype?: string;
  datefrom?: string;
  dateto?: string;
  fromrow: number;
  numofrows: number;
  paygroup: PayGroup.Payments;
  tranid?: string;
}

export interface GetPaymentParamsKlijenti {
  userid: string;
  inout?: PaymentSide;
  nondedicated?: PaymentDedication;
  cliid?: string;
  datefrom?: string;
  dateto?: string;
  fromrow: number;
  numofrows: number;
  tranid?: string;
}

export interface PaymentData {
  payId: string;
  payDate?: Date;
  clientId: string;
  clientIdNo?: string;
  clientName?: string;
  payType: number;
  payTypeDescr?: string;
  hasOrdTran?: number;
  orderId?: string;
  orderNo?: string;
  tranId?: string;
  tranNo?: string;
  sign?: number;
  amt?: number;
  amtBal?: number;
  amtNonAllocBal?: number;
  currId?: string;
  currCode?: string;
  currRate?: number;
  status?: number;
  amtTransBal?: number;
  amtOrdersBal?: number;
  grntId?: string;
  grntNo?: string;
  price?: number;
  amtPayment?: number;
}

export interface PaymentStatus {
  ftValue: number;
  ftDescr: string;
}

export interface PaymentFilter {
  status?: Partial<PaymentStatus>;
  datefrom?: string;
  dateto?: string;
  client?: Partial<ClientListData>;
  inout?: PaymentSide;
  nondedicated?: PaymentDedication;
  tranId?: string;
  tranDesc?: string;
}

export enum PayGroup {
	Guarantees = "G",
	Payments = "P",
}

export type PaymentValidationState = ValidationState<PaymentData>;
