import React from "react";
import { PaymentData } from "../../../data/clearing/payments/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayPaymentMainAttributesProps {
	payment: PaymentData;
}

export const DisplayPaymentMainAttributes: React.FunctionComponent<DisplayPaymentMainAttributesProps> = ({
	payment,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString, getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);

	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("paymentDataTime")}</span>
				<span className={style.value}>{getDateString(payment.payDate)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("paymentClientCode")}</span>
				<span className={style.value}>{payment.clientIdNo}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("paymentClientName")}</span>
				<span className={style.value}>{payment.clientName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("paymentPayType")}</span>
				<span className={style.value}>{payment.payTypeDescr}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("paymentClmOrdNo")}</span>
				<span className={style.value}>{payment.orderNo}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("paymentTranNo")}</span>
				<span className={style.value}>{payment.tranNo}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("paymentAmount")}</span>
				<span className={style.value}>{getFormatedNumberString(payment.amt, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("paymentCurrCode")}</span>
				<span className={style.value}>{payment.currCode}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("paymentAmtBal")}</span>
				<span className={style.value}>{getFormatedNumberString(payment.amtBal, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("paymentAmtBalNonAlloc")}</span>
				<span className={style.value}>{getFormatedNumberString(payment.amtNonAllocBal, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("paymentAmtOrdersBal")}</span>
				<span className={style.value}>{getFormatedNumberString(payment.amtOrdersBal, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("paymentAmtTradesBal")}</span>
				<span className={style.value}>{getFormatedNumberString(payment.amtTransBal, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("paymentStatus")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={payment.status}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				</span>
			</div>
		</div>
	);
};
