import React from "react";
import { Attribute, AttributeManditory } from "../../../data/listing/attributes/types";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Status } from "../../../data/models";

export interface DisplayAttributeBasicInfoProps {
	attribute: Attribute;
}

export const DisplayAttributeBasicInfo: React.FunctionComponent<DisplayAttributeBasicInfoProps> = ({
	attribute,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);

	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("attributeName")}</span>
				<span className={style.value}>{attribute.attrName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("attributeNameEN")}</span>
				<span className={style.value}>{attribute.attrNameen}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("productAttributeType")}</span>
				<span className={style.value}>{attribute.attrType}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("attributeUnit")}</span>
				<span className={style.value}>{attribute.unitCode}</span>
			</div>
{/*			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("productAttributeManditory")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={attribute.mandatory}
						thrutyValue={AttributeManditory.True}
						className={style.icon}
					/>
				</span>
			</div>  */}
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("productAttributeComparator")}</span>
				<span className={style.value}>{attribute.comparator}</span>
			</div>
{/*				<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("attributeOrder")}</span>
				<span className={style.value}>{attribute.orderNo}</span>
			</div>  */}
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliStatus")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={attribute.status}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				</span>
			</div>
		</div>
	);
};
