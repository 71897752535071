import { ValidationState } from "../../../core/util/types";
import { Status } from "../../models";

export interface Attribute {
	attrId?: string; // 		PK
	attrName: string; // 		naziv
	attrNameen: string; // 		naziv EN
	attrType: AttributeType; // 		tip (TEXT / SELECT)
	unit?: number; // 			jedinica mere
	unitCode?: string; // 		oznaka JM
	mandatory: AttributeManditory; // 		obavezan atribut
	comparator: AttrCompType; // 		komparator  (<=, =, >=)
	orderNo?: number; // 		redosled prikaza atributa
	status: Status; // 			status
}

export interface AttributeValue {
	attrValueId?: string; // 		PK
	attributeId: string; // 		ID atributa
	name: string; // 			naziv attr.
	nameen: string; // 		naziv attr. EN
	value: string; // 			vrednost attr.
	valueen: string; // 		vrednost attr. EN
	orderNo?: number; // 		redosled prikaza
	status: Status; // 			status
}

export enum AttributeManditory {
	True = 1,
	False = 0,
}

export enum AttributeType {
	Text = "TEXT      ",
	Select = "SELECT    ",
}


export enum AttrCompType {
	LessThan = "<=",
	Equal = "= ",
	MoreThan = ">=",
  }
  
export type AttributeValidationState = ValidationState<Attribute>;

export enum ErrorCodeExceptions {
	ReferenceErrorCode = "E-903",
}
