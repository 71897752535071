import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { CheckPointPayer } from "../../models";

const renderLabel = (item: CheckPointPayer) => item.ftDescr

export const useCheckPointPayers = () => {
	const checkPointPayers = useSelector<StoreType, CheckPointPayer[]>(store => store.data.initialData.checkPointPayers);

	return [checkPointPayers, renderLabel] as [typeof checkPointPayers, typeof renderLabel];
};
