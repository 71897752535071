import { combineReducers } from "@reduxjs/toolkit";
import ordersReport from "./ordersReport/ordersReportUXSlice";
import tradesReport from "./tradesReport/tradesReportUXSlice";
import tradesReportAll  from "./tradesReportClientAll/tradesReportUXSliceAll";
import dailyTrade from "./dailyTrade/dailyTradeUXSlice";
import weeklyTrade from "./weeklyTrade/weeklyTradeUXSlice";
import monthlyTrade from "./monthlyTrade/monthlyTradeUXSlice";
import annualTrade from "./annualTrade/annualTradeUXSlice";
import securityCommission from "./securityCommission/securityCommissionReportUXReducer";
import antiLaundering from "./amlaundering/amlaunderingUXSlice";
import exchbusiness from "./exchbusiness/exchBusinessUXReducer";
import exchmembers from "./exmember/exchangeMemberUXSlice";
import pushnotifications from "./notifications/notificationUXSlice";
import news from "./news/newsUXSlice";

const uxReportsReducer = combineReducers({
	ordersReport,
	tradesReport,
	tradesReportAll,
	dailyTrade,
	weeklyTrade,
	monthlyTrade,
	annualTrade,
	securityCommission,
	antiLaundering,
	exchbusiness,
	exchmembers,
	news,
	pushnotifications,
});

export default uxReportsReducer;
