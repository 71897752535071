import { ThunkType } from "../../../../core/store/reducers";
import { deleteInvoiceApi } from "../api/deleteInvoice";
import { setFetchState, invalidateInvoices } from "../invoicesDataSlice";

export const deleteInvoice = (invId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = await dispatch(deleteInvoiceApi(userId, invId));

  dispatch(
    setFetchState(
      result !== undefined ? { type: "successful" } : { type: "error" }
    )
  );
  dispatch(invalidateInvoices());
};
