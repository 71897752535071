import { webApi } from "../../../../core/http/httpThunks";
import { TransportAndStorageCosts, ShouldCalc } from "../types";

export const getTASCostsApi = (
	userid: string,
	tradeid: string,
	calcstoragecost: ShouldCalc,
	distance?: number,
) =>
	webApi<TransportAndStorageCosts>({
		method: "GET",
		url: "clearing/gettransportandstoragecosts",
		params: {
			userid,
			tradeid,
			distance,
			calcstoragecost,
		},
	});
