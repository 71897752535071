import { MarginsData } from "../../../data/clearing/marginsData/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export const useMarginsDataColumns = () => {
  const { getString, getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);

  return React.useMemo(
    () => [
      {
        title: getString("marginsDataCalcDate"),
        render: (marginsData) => getDateString(marginsData.calcDate, "DD.MM.YYYY"),
        sorting: false,
      },
      {
        title: getString("marginsDataTradeNo"),
        field: "tradeNo",
        sorting: false,
      },
      {
        title: getString("marginsDataClientName"),
        field: "clientName",
        sorting: false,
      },
      {
        title: getString("marginsDataProductName"),
        field: "productName",
        sorting: false,
      },
      {
        title: getString("marginsDataContrValue"),
        render: (marginsData) => getFormatedNumberString(marginsData.contrValue, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("marginsDataGainLoss"),
        render: (marginsData) => getFormatedNumberString(marginsData.gainLoss, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("marginsDataMarginReq"),
        render: (marginsData) => getFormatedNumberString(marginsData.marginReq, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("marginsDataMarginCall"),
        render: (marginsData) => getFormatedNumberString(marginsData.marginCall, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
			{
				title: getString("marginsDataMaintTriggered"),
				render: marginsData => <DisplayBoolNumber value={marginsData.maintTriggered} thrutyValue={Status.Active} />,
				customSort: (a, b) => a.maintTriggered - b.maintTriggered,
				width: "80px",
			},
],
    [getString, getFormatedNumberString, getDateString]
  ) as Column<MarginsData>[];
};
