import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { CreateInvoicesTranFilter } from "../../../data/accounting/createInvoices/types";
import { LocalizationContext } from "../../../core/localization/Localization";

export const CreateInvoiceFilterChips: React.FunctionComponent<CreateInvoicesFilterChipsProps> = ({
  filter,
  removefilter,
}) => {
  const { getDateString, getString } = React.useContext(LocalizationContext);

  return (
    <Grid container spacing={1}>
      {filter.datefrom && (
        <Grid item>
          <Chip
            label={`${getString("filterFrom")}: ${getDateString(
              new Date(filter.datefrom)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removefilter("datefrom")}
          />
        </Grid>
      )}
      {filter.dateto && (
        <Grid item>
          <Chip
            label={`${getString("filterTo")}: ${getDateString(
              new Date(filter.dateto)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removefilter("dateto")}
          />
        </Grid>
      )}
    </Grid>
  );
};

interface CreateInvoicesFilterChipsProps {
  filter: CreateInvoicesTranFilter;
  removefilter: (type: keyof CreateInvoicesTranFilter) => void;
}
