import { ThunkType } from "../../../../../core/store/reducers";
import { setCOTradesData } from "../clientOverviewDataSlice";
import { getTradesThunk } from "./getTradesThunk";
import { TableType } from "../types";

export const loadTrades = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	const { pageNumber, itemsPerPage } = store.ux.reports.exchbusiness.clientOverview.index[TableType.Trades];
	const { filter } = store.ux.reports.exchbusiness.clientOverview;

	const tradesData = await dispatch(getTradesThunk(pageNumber, itemsPerPage, filter));

	if (tradesData) {
		dispatch(setCOTradesData(tradesData));
	}
};

