import { UserListData, UserData } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";

interface IUserData {
	all?: UserListData[];
	searchTerm: string;
	displayData?: UserListData[];
	displayedUser?: UserData;
	fetchState: FetchStateType;
}

const initialState: IUserData = {
	searchTerm: "",
	fetchState: { type: "not-started" },
};

const name = "users";

const usersSlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<UserListData[] | undefined>) => ({
			...state,
			all: action.payload,
			displayData: filterData(action.payload, state.searchTerm),
		}),
		setSearchTerm: (state, action: PayloadAction<string>) => ({
			...state,
			searchTerm: action.payload.toLocaleLowerCase(),
			displayData: filterData(state.all, action.payload),
		}),
		setDisplayed: (state, action: PayloadAction<UserData | undefined>) => ({
			...state,
			displayedUser: action.payload,
		}),
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayedUser: action.payload.type === "successful" ? undefined : state.displayedUser,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			fetchState: getFetchStateChange(state.fetchState, action.payload)
		}),
	},
});

const filterData = (data?: UserListData[], searchTerm?: string) =>
	searchTerm === undefined || searchTerm === ""
		? data
		: data?.filter(
				e =>
					(e.userName && e.userName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(e.loginUser &&
						e.loginUser.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(e.role && e.role.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(e.emp && e.emp.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(e.client && e.client.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(e.cliPrs && e.cliPrs.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
		  );

export const { setData, setSearchTerm, setDisplayed, setFetchState } = usersSlice.actions;

export default usersSlice.reducer;
