import React from "react";
import { CssBaseline, Paper, Grid, Typography, Hidden } from "@material-ui/core";
import { LoginForm } from "./LoginForm";
import { useLoginStyles } from "./useLoginStyles";
import { useHistory } from 'react-router-dom';

const donations = require("'../../../public/donation2.png");
const logo = require("'../../../public/NSCOMEX-LOGO-front.png");

export const LoginPage: React.FunctionComponent = () => {

	const styles = useLoginStyles();

	const history = useHistory();

	React.useEffect(() => {
		history.push('/')
	}, []);
	
	return (
		<>
			<CssBaseline />
			<div className={styles.wraper}>
				<Grid
					container
					direction="column"
					alignContent="center"
					justify="space-between"
					spacing={3}
					className={styles.formWrpaer}
				>
					<Hidden only='xs'>
						<Grid item xs={3} style={{ marginTop: "4vh" }}>
							<div className={styles.loginDrawerPaper}>
								<img src={logo} alt="Error" style={{ width: "80%" }} />
							</div>
						</Grid>
					</Hidden>
					<Grid item xs={12} sm={3}>
						<Paper className={styles.loginDrawerPaper} elevation={6}>
							<LoginForm />
						</Paper>
					</Grid>
					<Hidden only='xs'>
						<Grid item xs={3} style={{ flexBasis: 0 }}>
							<Paper style={{ padding: "10px" }} className={styles.loginDrawerPaper} elevation={6}>
								<div>
									<Typography>Funded by:</Typography>{" "}
								</div>
								<img src={donations} alt="Error" style={{ width: "100%" }} />
								<div>
									<Typography style={{ fontSize: "0.65rem", textAlign: "center" }} variant="subtitle2">We are grateful to KfW and BFC for providing financial and technical support.</Typography>{" "}
								</div>
							</Paper>
						</Grid>
					</Hidden>
				</Grid>
			</div>
		</>
	);
};
