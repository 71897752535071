import { setReportData } from "../monthlyTradeUXSlice";
import { setData } from "../monthlyTradeDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { MonthlyTradingReportFilter } from "../types";
import { setReportMonth, setReportYear } from "../monthlyTradeUXSlice";

export const useCurrentTradesReportFilter = () => {
  const filter = useSelector<StoreType, MonthlyTradingReportFilter>(
    (store) => store.ux.reports.monthlyTrade.filter
  );
  const dispatch = useDispatch();
  const dispatchSetReportDate = React.useCallback(
    (reportDate: number) => {
      dispatch(setReportData(reportDate));
      dispatch(setData());
    },
    [dispatch]
  );
  const dispatchSetReportMonth = React.useCallback(
    (month: number) => {
      dispatch(setReportMonth(month));
      dispatch(setData());
    },
    [dispatch]
  );
  const dispatchSetReportYear = React.useCallback(
    (year: number) => {
      dispatch(setReportYear(year));
      dispatch(setData());
    },
    [dispatch]
  );

  return [
    filter,
    dispatchSetReportDate,
    dispatchSetReportMonth,
    dispatchSetReportYear,
  ] as [
    typeof filter,
    typeof dispatchSetReportDate,
    typeof dispatchSetReportMonth,
    typeof dispatchSetReportYear
  ];
};
