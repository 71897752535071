import React from "react";
import { Table, TableDataProps } from "../../../components/common/Table";
import { useClientAccounts } from "../../../../data/listing/clients/hooks/clientAccount/useClientAccounts";
import { useClientAccountDelete } from "../../../../data/listing/clients/hooks/clientAccount/useClientAccountDelete";
import { ClientAccountList } from "../../../../data/listing/clients/types";
import { Column, Action } from "material-table";
import { DisplayBoolNumber } from "../../../components/common/DisplayBoolNumber";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import EditIcon from "@material-ui/icons/EditOutlined";
import { ClientEditParts, getClientsEditLocation } from "../Locations";
import { useHistory } from "react-router-dom";
import { Fab } from "@material-ui/core";
import { usePageStyles } from "../../../pageStyles";
import AddIcon from "@material-ui/icons/Add";
import { Status } from "../../../../data/models";
import { useAuth } from "../../../../data/auth/hooks";

export interface ClientAccountTableProps {
	clientId: string;
}

type ClientAccountTablePropsType = ClientAccountTableProps & TableDataProps<ClientAccountList>;

export const ClientAccountTable: React.FunctionComponent<ClientAccountTablePropsType> = ({
	clientId,
	...tableProps
}) => {
	const pageStyles = usePageStyles();
	const history = useHistory();

	const [auth] = useAuth();

	const [data, reload] = useClientAccounts(clientId);

	const columns = useClientAccountColumns();
	const actions = useClientAccountActions(clientId);
	const onDeleteAccount = useDeleteClientAccountAction();

	return (
		<>
			<Table<ClientAccountList>
				{...tableProps}
				getData={() => {}}
				immutableData={data}
				title=""
				columns={columns}
				actions={actions}
				options={{
					toolbar: false,
					pageSize: 5,
					actionsColumnIndex: -1,
				}}
				editable={ auth.status === "successful" && auth.user.roleCode === "ADMIN" ? {
					onRowDelete: async account => {
						await onDeleteAccount(account);
						reload();
					},
				} : {} }
			/>
			{auth.status === "successful" && auth.user.roleCode === "ADMIN" && (
			<Fab
				color="secondary"
				aria-label="add"
				className={pageStyles.mainFab}
				onClick={() => history.push(getClientsEditLocation(ClientEditParts.Accounts, clientId, ""))}
			>
				<AddIcon />
			</Fab> )}
		</>
	);
};

export default ClientAccountTable;

const useClientAccountColumns = () => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);
	return React.useMemo(
		() => [
			{
				title: getString("accountNumber"),
				field: "account",
			},
			{
				title: getString("accountBank"),
				field: "bankCode",
			},
			{
				title: getString("accountDefault"),
				render: account => (
					<DisplayBoolNumber value={account.defaultAcc} thrutyValue={0} className={style.icon} />
				),
				customSort: (a, b) => a.defaultAcc - b.defaultAcc,
			},
			{
				title: getString("accountCurrency"),
				field: "currCode",
			},
			{
				title: getString("clientPersonStatus"),
				render: person => (
					<DisplayBoolNumber
						value={person.status}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				),
				customSort: (a, b) => a.status - b.status,
			},
		],
		[getString, style.icon]
	) as Column<ClientAccountList>[];
};

const useDeleteClientAccountAction = () => {
	const [, deleteAccount] = useClientAccountDelete();
	return React.useCallback(
		async (account: ClientAccountList) => {
			if (account.monAccId) {
				await deleteAccount(account.monAccId);
			}
		},
		[deleteAccount]
	);
};

export const useClientAccountActions = (clientId: string) => {
	const { getString } = React.useContext(LocalizationContext);
	const onEditAccount = useEditClientAccountAction(clientId);

	const [auth] = useAuth();

	return React.useMemo(() => {
		const result: Action<ClientAccountList>[] = [
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditAccount(rowData as ClientAccountList),
				hidden: auth.status === "successful" && auth.user.roleCode !== "ADMIN",
			},
		];

		return result;
	}, [getString, onEditAccount]);
};

export const useEditClientAccountAction = (clientId: string) => {
	const history = useHistory();
	return React.useCallback(
		(account: ClientAccountList) => {
			history.push(getClientsEditLocation(ClientEditParts.Accounts, clientId, account.monAccId));
		},
		[history, clientId]
	);
};
