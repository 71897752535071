import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Country } from "../types";

export const createCountryApi = (userId: string, country: Country) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "listing/newcnt",
    params: {
      userId,
    },
    data: country,
  });

export default createCountryApi;
