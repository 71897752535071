import { webApi } from "../../../../../core/http/httpThunks";

export const deleteClientContractApi = (userId: string, clicontrid: string) =>
	webApi<string>({
		method: "DELETE",
		url: "listing/delclicontr",
		params: {
			userId,
			clicontrid
		},
	});

export default deleteClientContractApi;
