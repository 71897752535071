import React from "react";
import { Grid, CardContent, CardHeader, Card, Divider, Fab } from "@material-ui/core";
import NewsTable from "./NewsTable";
import AddIcon from "@material-ui/icons/Add";
import { usePageStyles } from "../../pageStyles";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { useNewsData } from "../../../data/reports/news/hooks/useNewsData";
import { useNewsIndex } from "../../../data/reports/news/hooks/useNewsIndex";
import { useHistory } from "react-router-dom";
import { PageDataStatus } from "../../../data/models";
import { NewsFilterButton } from "./NewsFilter";
import { useAuth } from "../../../data/auth/hooks";
import { getNewsEditLocation } from "./Locations";

const NewsPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [pageData, dispatchLoadNews] = useNewsData();
	const [pageIndex, dispatchSetIndex] = useNewsIndex();

	React.useEffect(() => {
		if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
			dispatchLoadNews();
		}
	}, [dispatchLoadNews, pageData]);

	const history = useHistory();

	const [auth] = useAuth();

	if (auth.status !== "successful") {
		return null;
	}

	return (
		<>
			<Grid container className={pageStyles.root}>
				<Grid item xs={12} className={pageStyles.gridItem}>
					<Card>
						<CardHeader
							title={<PageTitle title="newsTitleTitle" />}
							action={<NewsFilterButton />}
						/>
						<Divider />
						<CardContent>
							<NewsTable
								pageIndex={pageIndex}
								pageData={pageData}
								onPageIndexChange={dispatchSetIndex}
								userAccessRights={userAccessRights}
								getData={dispatchLoadNews}
							/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			{userAccessRights === AccessRights.All && (
					<Fab
						color="secondary"
						aria-label="add"
						className={pageStyles.mainFab}
						onClick={() => history.push(getNewsEditLocation())}
					>
						<AddIcon />
					</Fab>
				)}

		</>
	);
};

export default NewsPage;

