import { combineReducers, AnyAction } from "@reduxjs/toolkit";
import createInvoices from "./createInvoices/createInvoicesDataSlice";
import invoices from "./invoices/invoicesDataSlice";
import { resetLoginState } from "../auth/authDataSlice";
import accountingOrder from "./accountingOrder/accountingOrderDataSlice";

const dataAccountingReducer = combineReducers({
	createInvoices,
	invoices,
	accountingOrder,
});

export default (state: any, action: AnyAction) => {
	if (action.type === resetLoginState.type) {
		return dataAccountingReducer(undefined, action);
	} else {
		return dataAccountingReducer(state, action);
	}
};
