import React from "react";
import { useDisplayProduct } from "./useDisplayProduct";
import { Product } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defauiltProduct: Product = {
	prodName: "",
	prodNameen: "",
	status: Status.Active,
};

export const useEditProduct = (productId?: string) => {
	const displayRole = useDisplayProduct(productId);

	return React.useMemo(() => {
		if (productId !== undefined && displayRole === undefined) {
			return undefined;
		}

		if (productId === undefined) {
			return { ...defauiltProduct };
		}

		return displayRole ? { ...displayRole } : undefined;
	}, [productId, displayRole]);
};

export const useEditProductState = (empId?: string) => {
	const role = useEditProduct(empId);
	return useCopiedState(role);
};
