import { ThunkType } from "../../../../core/store/reducers";
import getStockFeeApi from "../api/getStockFee";
import { setDisplayed } from "../stockFeesDataReducer";
import { StockFee } from "../types";

export const loadStockFee = (stfee: number): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const stockfee = await dispatch(getStockFee(stfee));

  dispatch(setDisplayed(stockfee));
};

export const getStockFee = (
  stfee: number
): ThunkType<Promise<StockFee | undefined>> => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getStockFeeApi(userId, stfee));
};
