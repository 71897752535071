import axios from "axios";
import { appConfig } from "../config/config";

const instance = axios.create(appConfig.http);

instance.interceptors.request.use(requestConfig => {
	return requestConfig;
});

export default instance;
