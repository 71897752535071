export interface TransportAndStorageCosts {
    tradeId:	string; // 		ID trejda
    transportDistance:	number; // 	rastojanje u km
    transportFeePerTon:	number; // cena transporta po toni za dato rastojanje
    transportCost:	number; // 	iznos troska prevoza
    transportCostWithVat:	number; // iznos troska prevoza sa pdv
    storageNoOfMonths:	number; // 	broj meseci za koje se obracunava skladistenje
    storageFeePerc:	number; // 	% od vrednosti robe bez pdv
    storageCost:	number; // 	iznos troska skladistenja
    storageCostWithVat:	number; // iznos troska skladistenja sa pdv
}

export interface CalculationParams {
    shouldCalcDistance: ShouldCalc,
    shouldCalcStorage: ShouldCalc,
    distance?: number,
    tradeid: string,
}

export enum ShouldCalc {
    True = 1,
    False = 0
}