import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { TransportFee } from "../../../data/clearing/transportfees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewTransportFee } from "./DisplayTransportFee";
import { useTransportFeeDelete } from "../../../data/clearing/transportfees/hooks/useTransportFeeDelete";
import { getTransportFeesEditLocation } from "./Locations";

export const useTransportFeeActions = () => {
  const { getString } = useContext(LocalizationContext);
  const onShowTransportFee = useViewTransportFee();
  const onEditTransportFee = useEditTransportFeeAction();

  return useMemo(() => {
    const result: Action<TransportFee>[] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("tableShow"),
        onClick: (event, rowData) =>
          onShowTransportFee(rowData as TransportFee),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) =>
          onEditTransportFee(rowData as TransportFee),
      },
    ];

    return result;
  }, [onShowTransportFee, onEditTransportFee, getString]);
};

export const useDeleteTransportFeeAction = () => {
  const [, deleteTransportFee] = useTransportFeeDelete();
  return React.useCallback(
    async (trfee: TransportFee) => {
      if (trfee.id) {
        await deleteTransportFee(trfee.id);
      }
    },
    [deleteTransportFee]
  );
};

export const useEditTransportFeeAction = () => {
  const history = useHistory();
  return React.useCallback(
    (trfee: TransportFee) =>
      history.push(getTransportFeesEditLocation(trfee.id?.toString())),
    [history]
  );
};
