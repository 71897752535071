import React from "react";
import { Grid } from "@material-ui/core";
import { useCurrentTradesReportFilter } from "../../../../data/reports/securityCommission/securityAnnual/hooks/useSecurityReportFilter";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { YearSelect} from "../../../components/reports/DatePartSelect"

export const AnnualTradesReportFilter: React.FunctionComponent = () => {
	const [filter,  setReporthYear] = useCurrentTradesReportFilter();
	const { getString } = React.useContext(LocalizationContext);

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<YearSelect 
					selected={filter.year}
					yearChange={setReporthYear}
					label={getString("monthlyTradeReportReportYear")}
				/>
			</Grid>
		</Grid>
	);
};
