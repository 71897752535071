import { AnnualSecurityReportData } from "../../../../data/reports/securityCommission/securityAnnual/types";
import React from "react";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Column } from "material-table";

export const useAnnualTradesColumns = () => {
  const { getString, getFormatedNumberString } = React.useContext(
    LocalizationContext
  );

  return React.useMemo(
    () => [
      {
        title: getString("secCommReportDate"),
        field: "date",
        sorting: false,
      },
      {
        title: getString("secCommProduct"),
        field: "prodName",
        sorting: false,
      },
      {
        title: getString("secCommReportQty"),
        render: (row) => `${getFormatedNumberString(row.qty)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("secCommTurnoverRSD"),
        render: (row) => `${getFormatedNumberString(row.turnoverRsd)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("secCommTurnoverEUR"),
        render: (row) => `${getFormatedNumberString(row.turnoverEur)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("secCommCountTransaction"),
        field: "tranCount",
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("secCommReportWeightedRSD"),
        render: (row) => `${getFormatedNumberString(row.weightedPrice, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("secCommReportWeightedEUR"),
        render: (row) =>
          `${getFormatedNumberString(row.weightedPriceEur, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
    ],
    [getString, getFormatedNumberString]
  ) as Column<AnnualSecurityReportData>[];
};
