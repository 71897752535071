import React from "react";
import { useDispatch } from "react-redux";
import { ProductAttribute } from "../../types";
import { getAttributes } from "../../thunks/attributes/loadAttributes";

export const useAttributes = (attributeId?: string) => {
	const displatch = useDispatch();
	const [attributes, setAttributes] = React.useState<ProductAttribute[] | undefined>();

	const loadAttributes = React.useCallback(async () => {
		if (attributeId) {
			const loadedAttributes = (await displatch(getAttributes(attributeId))) as any;
			setAttributes(loadedAttributes || []);
		}
	}, [displatch, attributeId])

	React.useEffect(() => {
		loadAttributes();
	}, [loadAttributes]);

	return [attributes, loadAttributes] as [typeof attributes, typeof loadAttributes]
};
