import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Role } from "../types";
import { loadRole } from "../thunks/loadRole";

export const useDisplayRole = (roleId?: string) => {
	const storeRole = useSelector<StoreType, Role | undefined>(
		store => store.data.listing.roles.displayedRole
    );

    const displayedRole = storeRole && storeRole.roleId === roleId ? storeRole : undefined;

    const displatch = useDispatch();

	React.useEffect(() => {
		if (roleId) {
			displatch(loadRole(roleId));
		}
    }, [displatch, roleId]);

	return displayedRole;
};
