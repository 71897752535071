import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { Unit } from "../../models";
import React from "react";

const getUnitLabel = (unit?: Unit) => (unit ? unit.ftDescr : "");

export const useUnits = () => {
	const units = useSelector<StoreType, Unit[]>(store => store.data.initialData.units);

	return [units, getUnitLabel] as [typeof units, typeof getUnitLabel];
};

export const useUnit = (id?: number) => {
	const units = useSelector<StoreType, Unit[]>(store => store.data.initialData.units);

	const unit = React.useMemo(() => units.find(u => u.ftValue === id), [id, units]);

	return [unit] as [typeof unit];
};
