import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { PayType } from "../types";
import { loadPayType } from "../thunks/loadPayType";

export const useDisplayPayType = (paytypeId?: string) => {
  const storePayType = useSelector<StoreType, PayType | undefined>(
    (store) => store.data.listing.payTypes.displayedPayType
  );

  const displayedPayType =
  storePayType && storePayType.payTypeId === paytypeId ? storePayType : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (paytypeId) {
      displatch(loadPayType(paytypeId));
    }
  }, [displatch, paytypeId]);

  return displayedPayType;
};
