import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../models";
import { ExchangeMembersReportData } from "./types";

type TradesReportData = {
  data?: ExchangeMembersReportData[];
  downloadReportFetchState: FetchStateType;
};

const initialState: TradesReportData = {
  downloadReportFetchState: { type: "not-started" },
};

const name = "exchangeMembers";

const exchangeMembersSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<ExchangeMembersReportData[] | undefined>
    ) => ({
      ...state,
      data: action.payload,
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      downloadReportFetchState: action.payload,
    }),
  },
});

export const { setData, setFetchState } = exchangeMembersSlice.actions;

export default exchangeMembersSlice.reducer;
