import React from "react";
import { useEditPaymentState } from "../../../data/clearing/payments/hooks/useEditPaymentState";
import { usePaymentValidator } from "../../../data/clearing/payments/hooks/usePaymentValidator";
import { usePaymentUpsert } from "../../../data/clearing/payments/hooks/usePaymentUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent, Paper, TextField, Typography,  } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { PaymentData } from "../../../data/clearing/payments/types";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { Status, OrderSide } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { paymentLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { NumberInput } from "../../components/common/NumberInput";
import { DateInput } from "../../components/common/DateInput";
import { CurrencySelect } from "../../components/common/CurrencySelect";
import { PaymentTypeSelect } from "../../components/common/PaymentTypeSelect";
import { FiltredTransactionsSelect } from "../../components/trading/FiltredTransactionsSelect";
import { SimpleTransFilter } from "../../../data/trading/transactions/types";
import { TransSimpleData } from "../../../data/trading/transactions/types";


export interface EditPaymentInfo {
	payId?: string;
}

export interface EditPaymentProps extends RouteComponentProps<EditPaymentInfo> {}

export const EditPaymentPage: React.FunctionComponent<EditPaymentProps> = props => {
	const { getString, getFormatedNumberString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [payment, editPayment, changed] = useEditPaymentState(props.match.params.payId);
	const [validationState, validate] = usePaymentValidator();
	const [fetchState, upsert] = usePaymentUpsert();
	const history = useHistory();
	const [tradesFilter, setTransactionFilter] = React.useState<SimpleTransFilter | undefined>(
		createTransactionFilter(payment)
	);
	const [selectedOrder, setSelectedOrder] = React.useState<TransSimpleData | null>(null);

	React.useEffect(() => {
		setTransactionFilter(createTransactionFilter(payment));
	}, [payment, setTransactionFilter]);

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editPayment(event.target.name as any, event.target.value);
		},
		[editPayment]
	);

	const changeAmt=React.useCallback((e: any) => {
		const newValue =  e.target.value;
		editPayment("amt", newValue);
		editPayment("amtPayment", payment && newValue && payment.price ? newValue /payment.price : 0);
	},	[editPayment,payment]	)


	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(paymentLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(payment);

			if (normalized) {
				upsert(normalized);
			}
		}
	}, [payment, validate, fetchState, history, upsert]);

	const pageTitle = payment && payment.payId ? "paymentEdit" : "paymentCreate";

	if (!payment) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={paymentLocation} />} />
					<Divider />
					<CardContent>
						<Grid item container direction="row" justify="flex-start" spacing={3}>
							<Grid item container direction="row" justify="flex-start" spacing={3} xs={6}>
								<Grid item xs={7}>
									<DateInput
										label={getString("paymentDataTime")}
										value={payment.payDate || null}
										onChange={date => editPayment("payDate", date)}
										error={validationState.payDate !== undefined && !validationState.payDate}
										required={validationState.payDate !== undefined}
									/>
								</Grid>
								<Grid item xs={7}>
									<PaymentTypeSelect
										label={getString("paymentPayType")}
										predicate={payTypes => payTypes.ftValue === payment.payType}
										onChangeItem={payment =>{
											editPayment("payType", payment ? payment.ftValue : undefined)
											editPayment("hasOrdTran", payment ? payment.hasOrdTran : undefined)
											setSelectedOrder(null)
											editPayment("tranId", undefined)}
										}
										error={validationState.payType !== undefined && !validationState.payType}
									/>
								</Grid>
								<Grid item xs={12}>
									<ClientSelect
										label={getString("paymentClientName")}
										predicate={client => client.cliId === payment.clientId}
										onChangeItem={client => {
											setSelectedOrder(null)
											editPayment("clientId", client ? client.cliId : undefined);
										}}
										required={validationState.clientId !== undefined}
										error={
											validationState.clientId !== undefined && !validationState.clientId
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<FiltredTransactionsSelect
										label={getString("paymentClientTransactions")}
										filter={tradesFilter}
										predicate={trans => trans.ordId === payment.tranId}
										onChangeItem={trans => {
											setSelectedOrder(trans);
											editPayment("tranId", trans ? trans.ordId : undefined);
											editPayment("price", trans?.price );
										}}
//										required={validationState.tranId !== undefined ||  payment.hasOrdTran===2  }
										required={payment.hasOrdTran===2  }
										error={
//											(validationState.tranId !== undefined && !validationState.tranId) || (payment.tranId !== undefined && payment.hasOrdTran!==2)
											(validationState.tranId !== undefined && !validationState.tranId)  && payment.hasOrdTran===2
										}
										disabled={ payment.hasOrdTran===0 }
									/>
								</Grid>

								<Grid
									container
									item
									direction="row"
									justify="flex-start"
									alignItems="center"
									spacing={3}
								>
									<Grid item xs={6} >
										<NumberInput<PaymentData>
											property="amt"
											item={payment}
											validationState={validationState}
//											onChange={handleChange}
											onChange={changeAmt}
											label={getString("paymentAmount")}
											money
										/>
									</Grid>

									<Grid item xs={6} >
										<CurrencySelect
											label={getString("paymentCurrCode")}
											predicate={currency => currency.currId === payment.currId}
											onChangeItem={currency => editPayment("currId", currency?.currId)}
											required={validationState.currId !== undefined}
											error={
												validationState.currId !== undefined && !validationState.currId
											}
											fullWidth
										/>
									</Grid>

{/*									{selectedOrder && (
									<Grid item xs={12} sm={2}>
										<TextField
											label={getString("orderPrice")}
											value={getFormatedNumberString(payment.price,true)}
											InputProps={{readOnly: true,}}
											fullWidth
											disabled
										/>
									</Grid> )}

									{selectedOrder && (
									<Grid item xs={12} sm={2}>
										<TextField
											label={getString("dlvrDeliverQty")}
											value={getFormatedNumberString(payment.amtPayment,true)}
											InputProps={{readOnly: true,}}
											fullWidth
											disabled
										/>
									</Grid> )}   */}
									
								</Grid>
								<Grid item xs={1}>
									<BoolInput<PaymentData>
										property="status"
										item={payment}
										onChange={handleChange}
										falsyValue={Status.Inactive}
										thrutyValue={Status.Active}
										label={getString("paymentStatus")}
									/>
								</Grid>
							</Grid>
							<Grid item container direction="column" justify="flex-start" spacing={3} xs={6}>
								{selectedOrder && (
									<Paper
										style={{ height: "100%", paddingLeft: "10px", paddingTop: "30px" }}
									>
										<Grid container direction="column" justify="flex-start" spacing={3}>
											<Grid item xs={6}>
												<Typography variant="h5">
													{getString("tradeParameters")}
												</Typography>
											</Grid>
											<Grid item container direction="row" spacing={3}>
												<Grid item xs={6}>
													<TextField
														label={getString("orderDepositeAmount")}
														value={getFormatedNumberString(
															selectedOrder.depAmt || 0,
															true
														)}
														InputProps={{
															readOnly: true,
														}}
														fullWidth
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														label={getString("orderDepositeAmountPayed")}
														value={getFormatedNumberString(
															selectedOrder.depAmtPayed || 0,
															true
														)}
														InputProps={{
															readOnly: true,
														}}
														fullWidth
													/>
												</Grid>
											</Grid>
											<Grid item container direction="row" spacing={3}>
												<Grid item xs={6}>
													<TextField
														label={getString("paymentAmtBalNonAlloc")}
														value={getFormatedNumberString(
															selectedOrder.nonAllocAmt || 0,
															true
														)}
														InputProps={{
															readOnly: true,
														}}
														fullWidth
													/>
												</Grid>
											</Grid>

											<Grid item container direction="row" spacing={3}>
												<Grid item xs={6} >
													<TextField
														label={getString("tradePriceWithVat")}
														value={getFormatedNumberString(payment.price,true)}
														InputProps={{readOnly: true,}}
														fullWidth
														disabled
													/>
												</Grid> 
											</Grid> 

											<Grid item container direction="row" spacing={3}>
												<Grid item xs={6} >
													<TextField
														label={getString("paymentMatchingQty")}
														value={getFormatedNumberString(payment.amtPayment,true)}
														InputProps={{readOnly: true,}}
														fullWidth
														disabled
													/>
												</Grid> 
											</Grid> 	
										</Grid>
									</Paper>
								)}
							</Grid>

						</Grid>

						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

const createTransactionFilter = (payment?: PaymentData) => {
	return payment && payment.clientId
		? {
				cliid: payment.clientId,
				side: payment.payType===21? OrderSide.Buy : (payment.payType===23? OrderSide.Sell :undefined),
		  }
		: undefined;
};
