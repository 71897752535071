import { ThunkType } from "../../../../core/store/reducers";
import createNewseApi from "../api/createNews";
import updateNewsApi from "../api/updateNews";
import { NewsData } from "../types";
import { setFetchState, invalidateNews } from "../newsDataReducer";

export const upsertNews = (news: NewsData ): ThunkType => async ( dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;

  const result = news.newsId
    ? await dispatch(updateNewsApi(userId, news))
    : await dispatch(createNewseApi(userId, news))

    console.log(result)

  if (result &&  result.resCode === "OK") {
    console.log("1")
    dispatch(setFetchState({ type: "successful", result: result.resDesc }));
    dispatch(invalidateNews());
    return result.resDesc;
  } else {
    dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
  }
};
