import React from "react";
import { useBusinessActivitiesColumns } from "./useBusinessActivitiesReportColumns";
import { AccessRights } from "../../../../core/navigation/types";
import { BusinessActivitiesReportData } from "../../../../data/reports/exchbusiness/businessActivities/types";
import { ControledTable } from "../../../components/common/ControledTable";
import { useBusinessActivitiesReportData } from "../../../../data/reports/exchbusiness/businessActivities/hooks/useBusinessActivitiesReportData";
import { useBusinessActivitiesReportIndex } from "../../../../data/reports/exchbusiness/businessActivities/hooks/useBusinessActivitiesReportIndex";

export interface BusinessActivitiesTableProps {
  userAccessRights: AccessRights;
}

type BusinessActivitiesTablePropsType = BusinessActivitiesTableProps;

export const BusinessActivitiesReportTable: React.FunctionComponent<BusinessActivitiesTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useBusinessActivitiesColumns();
  const [data, load] = useBusinessActivitiesReportData();
  const [index, setIndex] = useBusinessActivitiesReportIndex();

  return (
    <ControledTable<BusinessActivitiesReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default BusinessActivitiesReportTable;
