import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { ClientOverviewFilter } from "../../../../data/reports/exchbusiness/clientOverview/types";
import { LocalizationContext } from "../../../../core/localization/Localization";

export const ClientOverviewFilterChipsComponent: React.FunctionComponent<ClientOverviewFilterChipsProps> = ({
  filter,
  removeFilter,
}) => {
  const { getDateString, getString } = React.useContext(LocalizationContext);
  
  return (
    <Grid container spacing={1}>
      {filter.datefrom && (
        <Grid item>
          <Chip
            label={`${getString("filterFrom")}: ${getDateString(
              new Date(filter.datefrom)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("datefrom")}
          />
        </Grid>
      )}
      {filter.dateto && (
        <Grid item>
          <Chip
            label={`${getString("filterTo")}: ${getDateString(
              new Date(filter.dateto)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("dateto")}
          />
        </Grid>
      )}
      {filter.client && (
        <Grid item>
          <Chip
            label={filter.client.cliName}
            color="secondary"
            variant="outlined"
            onDelete={() => {
              removeFilter("client");
              removeFilter("tranId");
            }}
          />
        </Grid>
      )}
      {filter.orderStatus && (
        <Grid item>
          <Chip
            label={filter.orderStatus.ftDescr}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("orderStatus")}
          />
        </Grid>
      )}
      {filter.tradeStatus && (
        <Grid item>
          <Chip
            label={filter.tradeStatus.ftDescr}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("tradeStatus")}
          />
        </Grid>
      )}
      {filter.tranId && (
        <Grid item>
          <Chip
            label={filter.tranDesc}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("tranId")}
          />
        </Grid>
      )}

    </Grid>
  );
};

interface ClientOverviewFilterChipsProps {
  filter: ClientOverviewFilter;
  removeFilter: (type: keyof ClientOverviewFilter) => void;
}
