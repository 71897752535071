import React from "react";
import { useGuaranteesTypes } from "../../../data/initialData/hooks/useGuarantees";
import { Guarantees } from "../../../data/models";
import { AutocompleteSelectType, AutocompleteSelect } from "./AutocompleteSelect";


type GuaranteesTypePropsType = Omit<AutocompleteSelectType<Guarantees>, "data" | "getLabel">;

export const GuaranteesTypeSelect: React.FunctionComponent<GuaranteesTypePropsType> = props => {
	const [guaranteesType, renderLabel] = useGuaranteesTypes();

	return (
		<AutocompleteSelect<Guarantees>
			data={guaranteesType}
			getLabel={renderLabel}
			{...(props as any)}
		/>
	);
};

