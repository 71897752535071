import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { DisputeData } from "../types";

export const createDisputeApi = (userId: string, dispute: DisputeData) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "clearing/newdispsit",
    params: {
      userId,
    },
    data: dispute,
  });

export default createDisputeApi;
