import React from "react";
import { useMarginsDataButton } from "../../../data/clearing/marginsData/hooks/useMarginsDataButton";
import { Fab, IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { LocalizationContext } from "../../../core/localization/Localization";
import DownloadIcon from "@material-ui/icons/DoubleArrowOutlined";
import { usePageStyles } from "../../pageStyles";
import { CustomDialog } from "../../components/common/CustomDialog";

export const MarginsDataButton: React.FunctionComponent = () => {
	const [downloader, fetchState, resetData, hasData] = useMarginsDataButton();
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const messageError = (fetchState.type === "successful" && fetchState.result) ? fetchState.result : ""

	return (
		<>
		<CustomTooltip titleKeyword="marginsDataCalculationTooltip">
			<Fab
				color="secondary"
				aria-label="add"
				className={pageStyles.mainFab}
				onClick={downloader}
				disabled={fetchState.type !== "not-started" || !hasData}
			>
				<DownloadIcon />
			</Fab>
		</CustomTooltip>
		<CustomDialog
				open={fetchState.type === "successful"}
				title={getString("marginsDataMessageTitle")}
				message={messageError==="" ? getString("marginsDataMessage") : messageError}
				closeLabel={getString("marginsDataConfim")}
				onClose={resetData}
			/>
		</>
	);
};
