import { DailySecurityReportData, DailySecurityReportFilter } from "../types";
import { User } from "../../../../auth/types";
import { webApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

const getDailySecurityData = (
  user: User,
  filter: DailySecurityReportFilter
) => {
  return webApi<DailySecurityReportData[]>({
    method: "GET",
    url: "reporting/getsecdailytradinglist",
    params: {
      userid: user.userId,
      repdate: getParamsDateString(filter.reportDate),
    },
  });
};

export default getDailySecurityData;
