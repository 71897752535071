import { EditCurrencyRatePage } from "./EditCurrencyRatePage";
import CurrencyRatePage from "./CurrencyRatePage";
import { NavigationItem } from "../../../core/navigation/types";
import { currencyRateLocation, editRouter } from "./Locations";
export const ratesComponents: NavigationItem[] = [
	{
		Component: CurrencyRatePage,
		isMenuItem: true,
		path: currencyRateLocation,
	},
	{
		Component: EditCurrencyRatePage,
		isMenuItem: false,
		path: editRouter,
	},
];
