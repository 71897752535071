import { ThunkType } from "../../../../../core/store/reducers";
import getClientContractApi from "../../api/clientContract/getClientContract";
import { ClientContract } from "../../types";

export const getClientContract = (clientBrokerId: string): ThunkType<Promise<ClientContract | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getClientContractApi(userId, clientBrokerId));
}
