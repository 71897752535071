import { ThunkType } from "../../../../core/store/reducers";
import { setDelivery, invalidateDelivery } from "../deliveryDataSlice";
import { setDeliveryIndex } from "../deliveryUXSlice";
import getDeliveries from "../api/getDeliveries";
import { PageIndex, PageDataStatus } from "../../../models";

export const loadDeliveries = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const { pageNumber, itemsPerPage } = store.ux.clearing.deliveries.index;
  const { filter } = store.ux.clearing.deliveries;
  const user = store.data.auth.current.user;

  const deliveriesResponse = await dispatch(
    getDeliveries(user, pageNumber, itemsPerPage, filter)
  );

  if (!deliveriesResponse || deliveriesResponse.resCode !== "OK") {
    return;
  }

  const delivery = {
    currentPageData: deliveriesResponse.obj,
    totalCount: Number(deliveriesResponse.resDesc) || 0,
    status: PageDataStatus.DataCurrent,
  };

  dispatch(setDelivery(delivery));
};

export const setDeliveryIndexThunk = (index: PageIndex): ThunkType => (
  dispatch
) => {
  dispatch(setDeliveryIndex(index));
  dispatch(invalidateDelivery());
};
