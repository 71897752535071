import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { CtrlGoodsData } from "../types";

export const updateCtrlGoodsApi = (userId: string, ctrlgoods: CtrlGoodsData) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "clearing/updctrlgoods",
    params: {
      userId,
    },
    data: ctrlgoods,
  });

export default updateCtrlGoodsApi;
