import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import {
	useOrdersReportFilter,
	useCurrentOrdersReportFilter,
} from "../../../data/reports/ordersReport/hooks/useOrdersReportFilter";
import {
	OrderFilterEditor,
	OrderFilterEditorType,
	OrderFilterChips,
} from "../../trading/orders/OrderFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const OrdersReportFilterFilterButton: React.FunctionComponent = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<OrdersReportFilterEditor />), [
		showWithDrawer,
	]);
	
	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	const [filter, remnoveFilter] = useCurrentOrdersReportFilter();
	
	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<OrderFilterChips filter={filter} remnoveFilter={remnoveFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip">
					<IconButton color={!screenExtraSmall || Object.keys(filter).length===1 ? "secondary" : "primary" } component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const OrdersReportFilterEditor: React.FunctionComponent = () => {
	const [filter, editFilter, save, , rest] = useOrdersReportFilter();
	return (
		<OrderFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
			type={OrderFilterEditorType.Full}
			reset={rest}
		/>
	);
};
