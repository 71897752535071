import React from "react";

export type LinkMenuItemType = {
	type: "Link";
	key: string;
	title: string;
	Component: PageComponent;
	path: string;
	icon?: React.FunctionComponent<any>;
	hidden?: boolean;
	accessRights: AccessRights;
};

export type SubmenuMenuItemType = {
	type: "Menu";
	key: string;
	title: string;
	items: MenuItemType[];
	icon?: React.FunctionComponent<any>;
	hidden?: boolean;
};

export enum AccessRights {
	All = 0,
	ReadOnly = 1,
	None = -1,
}

export interface PageComponentProps {
	userAccessRights: AccessRights;
}

export type PageComponent<TProps extends PageComponentProps = PageComponentProps> = React.FunctionComponent<
	TProps
>;

export type MenuItemType = LinkMenuItemType | SubmenuMenuItemType;

export type NavigationItem = {
	isMenuItem: boolean;
	Component: React.FunctionComponent<any>;
	path: string;
	notifications?: boolean;
};