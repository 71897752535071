import { OrderSimpleData, GetSimpleOrdersParams, SimpleOrderFilter } from "../types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";

export const getSimpleOrdersApi = (
	userid: string,
	filter: SimpleOrderFilter,
) => {
  const params: GetSimpleOrdersParams = {
    userid,
    ...filter,
  }

	return webApiWithResponse<OrderSimpleData[]>({
		method: "GET",
		url: "order/getordersimplelist",
		params,
	});
};
