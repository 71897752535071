import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useClientData } from "../../../data/listing/clients/hooks/useClientData";
import { useClientColumns } from "./useClientColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useClientActions, useDeleteClientAction } from "./useClientActions";
import { ClientListData, ErrorCodeExceptions } from "../../../data/listing/clients/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { CustomDialog } from "../../components/common/CustomDialog";

export interface ClientTableProps {
	userAccessRights: AccessRights;
}

type ClientTablePropsType = ClientTableProps & TableDataProps<ClientListData>;

export const ClientTable: React.FunctionComponent<ClientTablePropsType> = ({
	userAccessRights,
	...tableProps
}) => {
	const [data, loadAll] = useClientData();
	const { getString } = React.useContext(LocalizationContext);

	const columns = useClientColumns();
	const actions = useClientActions(userAccessRights);
	const [fetchState, onDelete, resetState] = useDeleteClientAction();

	return (
		<>
		<Table<ClientListData>
			{...tableProps}
			getData={loadAll}
			immutableData={data}
			title=""
			columns={columns}
			actions={actions}
			options={{
				toolbar: false,
				pageSize: 20,
				actionsColumnIndex: -1,
			}}
			editable={userAccessRights !== AccessRights.ReadOnly ? {
				onRowDelete: onDelete,
			  }: {}}
		/>
		<CustomDialog
			open={
				fetchState.type === "error" &&
				fetchState.errorCode === ErrorCodeExceptions.ReferenceErrorCode
			}
			title={getString("errorTitle")}
			message={getString("errorClientDelete")}
			closeLabel={getString("errorConfirm")}
			onClose={resetState}
		/>
		</>
	);
};

export default ClientTable;
