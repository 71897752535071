import AccountingOrderPage from "./AccountingOrderPage";
import { NavigationItem } from "../../../core/navigation/types";
import { accountingOrderLocation } from "./Locations";

export const accountingOrderComponents: NavigationItem[] = [
  {
    Component: AccountingOrderPage,
    isMenuItem: true,
    path: accountingOrderLocation,
  },
];
