import React from "react";
import { useCheckPoints } from "../../../data/initialData/hooks/useCheckPoints";
import { CheckPoint } from "../../../data/models";
import {
  AutocompleteSelect,
  AutocompleteSelectType,
} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<CheckPoint>,
  "data" | "getLabel"
>;

export const CheckPointSelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [checkPoints, renderLabel] = useCheckPoints();

  return (
    <AutocompleteSelect<CheckPoint>
      data={checkPoints}
      getLabel={renderLabel}
      {...(props as any)}
    />
  );
};
