import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Order } from "../../orders/types";
import { loadMBOrder } from "../thunks/loadMainBoardOrder";

export const useDisplayMBOrder = (ordId?: string) => {
  const storeMB = useSelector<StoreType, Order | undefined>(
    (store) => store.data.trading.mainBoard.displayedMBOrder
  );

  const displayedMBOrder =
    storeMB && storeMB.ordId === ordId ? storeMB : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (ordId) {
      displatch(loadMBOrder(ordId));
    }
  }, [displatch, ordId]);

  return displayedMBOrder;
};
