import { setReportMonth, setReportYear } from "../monthlyTradeUXSlice";
import { setData } from "../monthlyTradeDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import React from "react";
import { MonthlyTradingReportFilter } from "../types";

export const useCurrentTradesReportFilter = () => {
	const filter = useSelector<StoreType, MonthlyTradingReportFilter>(
		store => store.ux.reports.securityCommission.monthlyTrade.filter
	);
	const dispatch = useDispatch();
	const dispatchSetReportMonth = React.useCallback(
		(month: number) => {
			dispatch(setReportMonth(month));
			dispatch(setData());
		},
		[dispatch]
	);

	const dispatchSetReportYear = React.useCallback(
		(year: number) => {
			dispatch(setReportYear(year));
			dispatch(setData());
		},
		[dispatch]
	);

	return [filter, dispatchSetReportMonth, dispatchSetReportYear] as [
		typeof filter,
		typeof dispatchSetReportMonth,
		typeof dispatchSetReportYear
	];
};
