import React from "react";
import { useWhsData } from "../../../data/listing/warehouses/hooks/useWhsData";
import { Warehouse } from "../../../data/listing/warehouses/types";
import {
  AutocompleteSelectType,
  AutocompleteSelect,
} from "../common/AutocompleteSelect";

export type WhsSelectPropsType = Omit<
  AutocompleteSelectType<Warehouse>,
  "data" | "getLabel"
>;

export const WhsSelect: React.FunctionComponent<WhsSelectPropsType> = (
  props
) => {
  const [, loadAllWhs, warehouses] = useWhsData();

  React.useEffect(() => {
    if (!warehouses) {
      loadAllWhs();
    }
  }, [warehouses, loadAllWhs]);

  return (
    <AutocompleteSelect<Warehouse>
      {...(props as AutocompleteSelectType<Warehouse>)}
      data={warehouses}
      getLabel={renderWhsLabel}
    />
  );
};

const renderWhsLabel = (warehouse: Warehouse | null) =>
  warehouse ? `${warehouse.whsName} ` : "";
