import { useCopiedState } from "../../../../core/util/hooks";
import { setFilter, removeFilter, initialState } from "../tradesReportUXSliceAll";
import { invalidateOrders } from "../tradesReportDataSliceAll";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { TradeFilter } from "../../../trading/trades/types"
import React from "react";

export const useTradesReportFilter = () => {
	const [storeFilter] = useCurrentTradesReportFilter();
	const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<TradeFilter>(storeFilter);

	const dispatch = useDispatch();
	const dispatchSetFilter = React.useCallback(() => {
		dispatch(setFilter(filter!));
		dispatch(invalidateOrders());
	}, [dispatch, filter]);

	const dispatchResetFilter = React.useCallback(() => {
		setCurrentFilter(initialState.filter)
	}, [setCurrentFilter]);

	return [filter, editFilter, dispatchSetFilter, changeState, dispatchResetFilter] as [
		typeof filter,
		typeof editFilter,
		typeof dispatchSetFilter,
		typeof changeState,
		typeof dispatchResetFilter,
	];
};

export const useCurrentTradesReportFilter = () => {
	const filter = useSelector<StoreType, TradeFilter>(store => store.ux.reports.tradesReportAll.filter);
	const dispatch = useDispatch();
	const dispatchRemoveFilter = React.useCallback(
		(type: keyof TradeFilter) => {
			dispatch(removeFilter(type));
			dispatch(invalidateOrders());
		},
		[dispatch]
	);

	return [filter, dispatchRemoveFilter] as [typeof filter, typeof dispatchRemoveFilter];
};
