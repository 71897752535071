import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllMarginsgData } from "../thunks/loadAllMarginsData";
import { MarginsData } from "../types";
import { PageData } from "../../../models";

export const useMarginsDataData = () => {
  const pageData = useSelector<
    StoreType,
    PageData<MarginsData> | undefined
  >((s) => s.data.clearing.marginsData.currentPage);

  const dispatch = useDispatch();
  const dispatchLoadMarginsDataPage = React.useCallback(
    () => dispatch(loadAllMarginsgData()),
    [dispatch]
  );

  return [pageData, dispatchLoadMarginsDataPage] as [
    typeof pageData,
    typeof dispatchLoadMarginsDataPage
  ];
};
