import React from "react";
import { useDisplayCurrencyRate } from "./useDisplayCurrencyRate";
import { CurrencyRate, CurrencyRateFor } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";

export const defauiltCurrency: CurrencyRate = {
	date: new Date(),
	rateFor: CurrencyRateFor.True,
};

export const useEditCurrency = (currencyId?: number) => {
	const displayCurrency = useDisplayCurrencyRate(currencyId);
	return React.useMemo(() => {
		if (currencyId !== undefined && displayCurrency === undefined) {
			return undefined;
		}

		if (currencyId === undefined) {
			return { ...defauiltCurrency };
		}

		return displayCurrency ? { ...displayCurrency } : undefined;
	}, [currencyId, displayCurrency]);
};

export const useEditCurrencyState = (rateId?: number) => {
	const currency = useEditCurrency(rateId);
	return useCopiedState(currency);
};
