import React from "react";
import { useAccountingOrderColumns } from "./useAccountingOrderColumns";
import { AccessRights } from "../../../core/navigation/types";
import { InvoiceListData } from "../../../data/accounting/accountingOrder/types";
import { ControledTable } from "../../components/common/ControledTable";
import { useAccountingOrderData } from "../../../data/accounting/accountingOrder/hooks/useAccountingOrderData";
import { useAccountingOrderIndex } from "../../../data/accounting/accountingOrder/hooks/useAccountingOrderIndex";

export interface AccountingOrderTableProps {
  userAccessRights: AccessRights;
}

type AccountingOrderTablePropsType = AccountingOrderTableProps;

export const AccountingOrderTable: React.FunctionComponent<AccountingOrderTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useAccountingOrderColumns();
  const [data, load] = useAccountingOrderData();
  const [index, setIndex] = useAccountingOrderIndex();

  return (
    <ControledTable<InvoiceListData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default AccountingOrderTable;
