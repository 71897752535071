import TradeByClientsReport from "./TradeByClientsReport";
import { NavigationItem } from "../../../../core/navigation/types";
import { tradeByClientsLocation } from "./Locations";

export const tradeByClientsReportComponents: NavigationItem[] = [
  {
    Component: TradeByClientsReport,
    isMenuItem: true,
    path: tradeByClientsLocation,
  },
];
