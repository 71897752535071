import React from "react";
import { GuaranteeSide } from "../types";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { exhaustiveCheck } from "../../../../core/util/functions";

export const useGuaranteeSideOptionsTranslator = () => {
  const { getString } = React.useContext(LocalizationContext);
  return React.useCallback(
    (side: GuaranteeSide) => {
      switch (side) {
        case GuaranteeSide.in:
          return getString("paymentSideIn");
        case GuaranteeSide.out:
          return getString("paymentSideOut");
        default:
          exhaustiveCheck(side);
      }
    },
    [getString]
  );
};

interface AccountGuaranteeSideOption {
  label: string;
  value: GuaranteeSide;
}

export const useGuaranteeSideOptions = (): AccountGuaranteeSideOption[] => {
  const translator = useGuaranteeSideOptionsTranslator();
  return React.useMemo(
    () => [
      {
        label: translator(GuaranteeSide.in) as string,
        value: GuaranteeSide.in,
      },
      {
        label: translator(GuaranteeSide.out) as string,
        value: GuaranteeSide.out,
      },
    ],
    [translator]
  );
};
