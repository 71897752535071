import { CurrencyRate, CurrencyRateValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const currencyRateValidationState: CurrencyRateValidationState = {
	currId: true,
	midRate: true,
	date: true,
};

export const useCurrencyRateValidator = createUseValidator<CurrencyRate, CurrencyRateValidationState>(
	currencyRateValidationState
);
