import React from "react";
import { Grid, CardContent, CardHeader, Card, Divider } from "@material-ui/core";
import OrdersTable from "./TradesReportTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { useTradesReportData } from "../../../data/reports/tradesReportClientAll/hooks/useTradesReportData";
import { useTradesReportIndex } from "../../../data/reports/tradesReportClientAll/hooks/useTradesReportIndex";
import { PageDataStatus } from "../../../data/models";
import { TradesFilterButton } from "./TradesReportFilter";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";

const TradesReport: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [pageData, dispatchLoadData] = useTradesReportData();
	const [pageIndex, dispatchSetIndex] = useTradesReportIndex();

	React.useEffect(() => {
		if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
			dispatchLoadData();
		}
	}, [dispatchLoadData, pageData]);

	return (
		<>
			<Grid container className={pageStyles.root}>
				<Grid item xs={12} className={pageStyles.gridItem}>
					<Card>
						<CardHeader
							title={<PageTitle title="navTradesReportAll" />}
							action={<TradesReportActions />}
						/>
						<Divider />
						<CardContent>
							<OrdersTable
								pageIndex={pageIndex}
								pageData={pageData}
								onPageIndexChange={dispatchSetIndex}
								userAccessRights={userAccessRights}
								getData={dispatchLoadData}
							/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

export const TradesReportActions: React.FunctionComponent = () => (
	<Grid container>
		<Grid item>
			<TradesFilterButton />
		</Grid>
		<Grid item>
			<DownloadButton />
		</Grid>
		<Grid item>
			<DownloadExcelButton />
		</Grid>
	</Grid>
)

export default TradesReport;
