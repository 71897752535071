import React from "react";
import { useDispatch } from "react-redux";
import { ClientAccount } from "../../types";
import { getClientAccount } from "../../thunks/clientAccount/loadClientAccount";

export const useClientAccount = (accountId?: string) => {
	const displatch = useDispatch();
	const [account, setAccount] = React.useState<ClientAccount | undefined>();

	React.useEffect(() => {
		const loadClient = async () => {
			if (accountId) {
				const loadedAccount = (await displatch(getClientAccount(accountId))) as any;
				setAccount(loadedAccount);
			}
		};
		loadClient();
	}, [displatch, accountId, setAccount]);

	return [account];
};
