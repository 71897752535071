import { setFilter, removeFilter, initialState } from "../commReviewUXSlice";
import { setData } from "../commReviewDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import React from "react";
import { ComReviewReportFilter } from "../types";
import { useCopiedState } from "../../../../../core/util/hooks";
import { invalidateCommReview } from "../commReviewDataSlice";

export const useCommReviewReportFilter = () => {
  const storeFilter = useSelector<StoreType, ComReviewReportFilter>(
    (store) => store.ux.reports.exchbusiness.commReview.filter
  );
  const [filter, editFilter] = useCopiedState<ComReviewReportFilter>(
    storeFilter
  );

  const dispatch = useDispatch();
  const dispatchSetFilter = React.useCallback(() => {
    dispatch(setFilter(filter!));
    dispatch(setData());
  }, [dispatch, filter]);

  return [filter, editFilter, dispatchSetFilter] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter
  ];
};

export const useCurrentCommReviewFilter = () => {
  const filter = useSelector<StoreType, ComReviewReportFilter>(
    (store) => store.ux.reports.exchbusiness.commReview.filter
  );
  const dispatch = useDispatch();
  const dispatchRemoveFilter = React.useCallback(
    (type: keyof ComReviewReportFilter) => {
      dispatch(removeFilter(type));
      dispatch(invalidateCommReview());
    },
    [dispatch]
  );

  return [filter, dispatchRemoveFilter] as [
    typeof filter,
    typeof dispatchRemoveFilter
  ];
};
