import { Bank } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";

interface IBankData {
  all?: Bank[];
  searchTerm: string;
  displayData?: Bank[];
  displayedBank?: Bank;
  fetchState: FetchStateType;
}

const initialState: IBankData = {
  searchTerm: "",
  fetchState: { type: "not-started" },
};

const name = "banks";

const bankSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Bank[] | undefined>) => ({
      ...state,
      all: action.payload,
      displayData: filterData(action.payload, state.searchTerm),
    }),
    setSearchTerm: (state, action: PayloadAction<string>) => ({
      ...state,
      searchTerm: action.payload.toLocaleLowerCase(),
      displayData: filterData(state.all, action.payload),
    }),
    setDisplayed: (state, action: PayloadAction<Bank | undefined>) => ({
      ...state,
      displayedBank: action.payload,
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      displayedBank:
        action.payload.type === "successful" ? undefined : state.displayedBank,
      all: action.payload.type === "successful" ? undefined : state.all,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: getFetchStateChange(state.fetchState, action.payload),
    }),
  },
});

const filterData = (data?: Bank[], searchTerm?: string) =>
  searchTerm === undefined || searchTerm === ""
    ? data
    : data?.filter(
        (e) =>
          (e.bankCode &&
            e.bankCode
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
          (e.bankName &&
            e.bankName
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
          (e.idNumber &&
            e.idNumber
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase()))
      );

export const {
  setData,
  setSearchTerm,
  setDisplayed,
  setFetchState,
} = bankSlice.actions;

export default bankSlice.reducer;
