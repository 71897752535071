import React from "react";
import { Table, TableDataProps } from "../../../components/common/Table";
import { useClientBrokers } from "../../../../data/listing/clients/hooks/clientBroker/useClientBrokers";
import { ClientBroker } from "../../../../data/listing/clients/types";
import { Column, Action } from "material-table";
import { DisplayBoolNumber } from "../../../components/common/DisplayBoolNumber";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { useClientBrokerDelete } from "../../../../data/listing/clients/hooks/clientBroker/useClientBrokerDelete";
import EditIcon from "@material-ui/icons/EditOutlined";
import { ClientEditParts, getClientsEditLocation } from "../Locations";
import { useHistory } from "react-router-dom";
import { Fab } from "@material-ui/core";
import { usePageStyles } from "../../../pageStyles";
import AddIcon from "@material-ui/icons/Add";
import { Status } from "../../../../data/models";
import { useAuth } from "../../../../data/auth/hooks";

export interface ClientBrokerTableProps {
	clientId: string;
}

type ClientBrokerTablePropsType = ClientBrokerTableProps & TableDataProps<ClientBroker>;

export const ClientBrokerTable: React.FunctionComponent<ClientBrokerTablePropsType> = ({
	clientId,
	...tableProps
}) => {
	const pageStyles = usePageStyles();
	const history = useHistory();

	const [auth] = useAuth();

	const [data, reload] = useClientBrokers(clientId);

	const columns = useClientBrokerColumns();
	const actions = useClientBrokerActions();
	const onDeleteBroker = useDeleteClientBrokerAction();

	return (
		<>
			<Table<ClientBroker>
				{...tableProps}
				getData={() => {}}
				immutableData={data}
				title=""
				columns={columns}
				actions={actions}
				options={{
					toolbar: false,
					pageSize: 5,
					actionsColumnIndex: -1,
				}}
				editable={ auth.status === "successful" && auth.user.roleCode === "ADMIN" ? {
					onRowDelete: async broker => {
						await onDeleteBroker(broker);
						reload();
					},
				} : {} }
			/>
			{auth.status === "successful" && auth.user.roleCode === "ADMIN" && (
			<Fab
				color="secondary"
				aria-label="add"
				className={pageStyles.mainFab}
				onClick={() => history.push(getClientsEditLocation(ClientEditParts.Brokers, clientId, ""))}
			>
				<AddIcon />
			</Fab> )}
		</>
	);
};

export default ClientBrokerTable;

const useClientBrokerColumns = () => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);
	return React.useMemo(
		() => [
			{
				title: getString("clientBrokerName"),
				field: "brokName",
			},
			{
				title: getString("clientBrokerIDNumber"),
				field: "brokIdNo",
			},
			{
				title: getString("clientPersonStatus"),
				render: person => (
					<DisplayBoolNumber
						value={person.status}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				),
				customSort: (a, b) => a.status - b.status,
			},
		],
		[getString, style.icon]
	) as Column<ClientBroker>[];
};

const useDeleteClientBrokerAction = () => {
	const [, deleteBroker] = useClientBrokerDelete();
	return React.useCallback(
		async (client: ClientBroker) => {
			if (client.cliBrokId) {
				await deleteBroker(client.cliBrokId);
			}
		},
		[deleteBroker]
	);
};

export const useClientBrokerActions = () => {
	const { getString } = React.useContext(LocalizationContext);
	const onEditBroker = useEditClientBrokerAction();

	const [auth] = useAuth();

	return React.useMemo(() => {
		const result: Action<ClientBroker>[] = [
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditBroker(rowData as ClientBroker),
				hidden: auth.status === "successful" && auth.user.roleCode !== "ADMIN",
			},
		];

		return result;
	}, [getString, onEditBroker]);
};

export const useEditClientBrokerAction = () => {
	const history = useHistory();
	return React.useCallback(
		(broker: ClientBroker) =>
			history.push(getClientsEditLocation(ClientEditParts.Brokers, broker.clientId, broker.cliBrokId)),
		[history]
	);
};
