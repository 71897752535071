import { ThunkType } from "../../../../core/store/reducers";
import getAllCommissionsAPI from "../api/getAllCommisions";
import { setData } from "../commissionsDataReducer";

export const loadAllCommissions = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setData());

	const { userId } = store.data.auth.current.user;
	const commissions = await dispatch(getAllCommissionsAPI(userId));

	dispatch(setData(commissions || []));
};
