import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { DeliveryFilter } from "../types";
import { getParamsDateString } from "../../../../core/util/functions";

export const getDeliveryExcel = (user: User, filter: DeliveryFilter) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getdlvrgoodsxls",
    params: {
      userid: user.userId,
      sellerid: filter?.seller?.cliId,
      buyerid:filter?.buyer?.cliId,
      cliid:filter?.client?.cliId,
      prodid:filter?.product?.prodId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
    },
  });
};

export default getDeliveryExcel;
