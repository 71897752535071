import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { GuaranteeData } from "../types";

export const updateGuaranteeApi = (userId: string, guarantee: GuaranteeData) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "clearing/updpayment",
    params: {
      userId,
    },
    data: guarantee,
  });

export default updateGuaranteeApi;
