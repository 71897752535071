import { useDispatch, useSelector } from "react-redux";
import { downloadMarginsDataButton } from "../thunks/loadMarginsDataButton";
import { resetData } from "../marginsDataSlice";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { MarginsData, MarginsDataFilter } from "../types";
import moment from "moment";

var tomorow = moment().add(1, "day").toDate();

export const useMarginsDataButton = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.clearing.marginsData.fetchState
	);

	const data = useSelector<StoreType, MarginsData[] | undefined>(
		s => s.data.clearing.marginsData.currentPage?.currentPageData
	);

	const filter = useSelector<StoreType, MarginsDataFilter | undefined>(
		s => s.ux.clearing.marginsData.filter
	);
  
console.log(filter?.reportDate)

	const dateOk = filter?.datefrom === filter?.dateto
   	const reportDateOk = filter?.reportDate && moment(tomorow).isAfter(filter.reportDate);

	   console.log(reportDateOk)
	   console.log(data)
	   console.log(dateOk)

//	const hasData = (data !== undefined &&  data !== null && data.length > 0) || !dateOk || !reportDateOk;
//	const hasData =  !dateOk || !reportDateOk;

	const hasData =  true

	const dispatch = useDispatch();
	const download = React.useCallback(() => 
	{dispatch(downloadMarginsDataButton());
	}, [dispatch]);

	const dispatchResetData = React.useCallback(() => {
		dispatch(resetData());
	}, [dispatch]);

	return [download, fetchState, dispatchResetData, hasData] as [
		typeof download,
		typeof fetchState,
		typeof dispatchResetData,
		typeof hasData
	];
};
