import React from "react";
import { useDispatch } from "react-redux";
import { getSimpleTradesThunk } from "../thunks/getSimpleTradesThunk";
import { TradeSimpleFilter, TradesSimpleData, TradesSimpleDataTrade } from "../types";

export const useSimpleTradesData = (filter?: TradeSimpleFilter) => {
	const dispatch = useDispatch();
	const [trades, setTrades] = React.useState<TradesSimpleDataTrade[] | undefined>();

	React.useEffect(() => {
		const loadOrders = async () => {
			if (filter) {
				const loaded = ((await dispatch(getSimpleTradesThunk(filter))) as any) as TradesSimpleDataTrade[];
				setTrades(loaded);
			}
		};
		if (!filter) {
			setTrades(undefined);
		} else {
			loadOrders();
		}
	}, [filter, dispatch]);

	return [trades] as [typeof trades];
};
