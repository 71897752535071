import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deletePayment } from "../thunks/deletePayment";
import { setFetchState } from "../paymentsDataSlice";

export const usePaymentDelete = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.clearing.payments.cancelFetchState
  );

  const dispatch = useDispatch();
  const dispatchDeletePayment = React.useCallback(
    (id: string) => dispatch(deletePayment(id)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchDeletePayment] as [
    typeof fetchState,
    typeof dispatchDeletePayment
  ];
};
