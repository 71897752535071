import { WeeklyTradingReportData } from "../../../data/reports/weeklyTrade/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";

export const useWTReportClientColumns = () => {
  const { getString, getFormatedNumberString } = React.useContext(
    LocalizationContext
  );

  return React.useMemo(
    () => [
      {
        title: getString("weeklyTradeReportClient"),
        field: "displayVal",
        sorting: false,
      },
      {
        title: getString("dailyTradeReportTurnoverRSD"),
        render: (row) => `${getFormatedNumberString(row.turnoverRsd, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("dailyTradeReportTurnoverEUR"),
        render: (row) => `${getFormatedNumberString(row.turnoverEur, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("dailyTradeReportCountTransaction"),
        field: "tranCount",
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
    ],
    [getString, getFormatedNumberString]
  ) as Column<WeeklyTradingReportData>[];
};
