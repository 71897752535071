import { webApi } from "../../../../core/http/httpThunks";

export const deleteDisputeApi = (userId: string, dispid: string) =>
  webApi<string>({
    method: "DELETE",
    url: "clearing/deldispsit",
    params: {
      userId,
      dispid,
    },
  });

export default deleteDisputeApi;
