import React from "react";
import { StringInput, StringInputPropsType } from "./StringInput";
import { LocalizationContext } from "../../../core/localization/Localization";
import NumberFormat from "react-number-format";

export type NumberInputProps<T> = StringInputPropsType<T> & {
	money?: boolean;
	decimalCount?: number;
};

interface NumberFormatCustomProps {
	inputRef: (instance: NumberFormat<any> | null) => void;
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const createNumberFormatCustom = (money?: boolean, decimalCount?: number) => (props: NumberFormatCustomProps) => {
	const { inputRef, onChange, ...other } = props;
	const { getSeparators } = React.useContext(LocalizationContext);
	const separators = getSeparators()
	const decimalScale = money ? 2 : decimalCount;
	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={separators.thousands}
			decimalSeparator={separators.decimal}
			isNumericString
			decimalScale={decimalScale}
			fixedDecimalScale={decimalScale !== undefined}
		/>
	);
}

interface IDisplayValue {
	value: any,
	displayValue: string
}

const getDisplayValue = (value: any): IDisplayValue => ({
	value,
	displayValue: value ? value.toString() : ""
})

export function NumberInput<T>(props: NumberInputProps<T>) {
	const { onChange, item, property, InputProps, money, decimalCount, ...otherProps } = props;
	const itemValue: any = item && item[property];

	const { getNumber } = React.useContext(LocalizationContext);

	const [displayValuePair, setDisplayPair] = React.useState<IDisplayValue>(getDisplayValue(itemValue));
	const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

	React.useEffect(() => {
		if (itemValue !== displayValuePair.value) {
			setDisplayPair(getDisplayValue(itemValue))
		}
	}, [itemValue, displayValuePair])

	const onNumberChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const displayValue = e.target.value;
			const numberValue = getNumber(displayValue);
			const newErrorMessage = !numberValue && displayValue !== "" && numberValue !== 0 ? "Not a number!" : undefined;
			onChange({
				...e,
				target: {
					...e.target,
					value: numberValue as any,
					name: property,
				},
			});
			setErrorMessage(newErrorMessage);
			setDisplayPair({ 
				value: numberValue,
				displayValue: displayValue,
			});
		},
		[onChange, setDisplayPair, setErrorMessage, property, getNumber]
	);

	const NumberFormatCustom = React.useMemo(() => createNumberFormatCustom(money, decimalCount), [money, decimalCount]);
	return (
		<StringInput<T>
			value={displayValuePair.displayValue}
			property={property}
			onChange={onNumberChange}
			errorMessage={errorMessage}
			item={item}
			InputProps={{
				...InputProps,
				inputComponent: NumberFormatCustom as any,
			}}
			{...otherProps}
		/>
	);
}
