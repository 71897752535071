import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertRole } from "../thunks/upsertUser";
import { setFetchState } from "../usersDataReducer";
import { UserData } from "../types";

export const useUserUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.users.fetchState
	);

	const dispatch = useDispatch();
	const dispatchUpsertRole = React.useCallback(
		(user: UserData) => dispatch(upsertRole(user)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchState({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchUpsertRole] as [typeof fetchState, typeof dispatchUpsertRole]
};
