import React from "react";
import {
  Grid,
  CardContent,
  CardHeader,
  Card,
  Divider,
} from "@material-ui/core";
import MemberContractsReportTable from "./MemberContractsReportTable";
import { usePageStyles } from "../../../pageStyles";
import { PageComponent } from "../../../../core/navigation/types";
import { PageTitle } from "../../../components/common/PageTitle";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";

const MemberContractsReport: PageComponent = ({ userAccessRights }) => {
  const pageStyles = usePageStyles();

  return (
    <>
      <Grid container className={pageStyles.root}>
        <Grid item xs={12} className={pageStyles.gridItem}>
          <Card>
            <CardHeader
              title={<PageTitle title="memcontTitle" />}
              action={<MemberContractsReportActions />}
            />
            <Divider />
            <CardContent>
              <MemberContractsReportTable userAccessRights={userAccessRights} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export const MemberContractsReportActions: React.FunctionComponent = () => (
  <Grid container>
    <Grid item>
      <DownloadButton />
    </Grid>
    <Grid item>
      <DownloadExcelButton />
    </Grid>
  </Grid>
);

export default MemberContractsReport;
