import { ThunkType } from "../../../../core/store/reducers";
import getAttributeApi from "../api/getAttribute";
import { setDisplayed } from "../attributeDataReducer";
import { Attribute } from "../types";

export const loadAttribute = (attributeId: string): ThunkType => async (dispatch, getStore) => {
	dispatch(setDisplayed());

	const role = await dispatch(getAttribute(attributeId));

	dispatch(setDisplayed(role));
};

export const getAttribute = (attributeId: string): ThunkType<Promise<Attribute | undefined>> => async (
	dispatch,
	getStore
) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getAttributeApi(userId, attributeId));
};
