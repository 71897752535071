import { ThunkType } from "../../../../../core/store/reducers";
import createClientPersonApi from "../../api/clientPerson/createClientPerson";
import updateClientPersonApi from "../../api/clientPerson/updateClientPerson";
import { setFetchStatePerson } from "../../clientsDataReducer";
import { ClientPerson } from "../../types";

export const upsertClientPerson = (client: ClientPerson): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchStatePerson({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = client.cliPrsId
		? await dispatch(updateClientPersonApi(userId, client))
		: await dispatch(createClientPersonApi(userId, client));

	if (result && result.resCode === "OK") {
		dispatch(setFetchStatePerson({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchStatePerson({ type: "error", errorCode: result?.resCode }));
	}
};
