import { OrderFilter } from "../../../models";
import { ViewOrder, GetOrdersParams } from "../types";
import { User } from "../../../auth/types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getOrdersWebApi = (
  user: User,
  page?: number,
  itemsPerPage?: number,
  filter?: OrderFilter,
  defaultOrderStatus?: number,
) => {
  const params: GetOrdersParams = {
    userid: user.userId,
    side: filter?.side,
    prodid: filter?.product?.prodId,
    fromrow: page && itemsPerPage ? page * itemsPerPage : 0,
    numofrows: itemsPerPage,
    cliprsid: undefined,
    status: filter?.orderStatus?.ftValue || defaultOrderStatus,
    cliid: filter?.client?.cliId,
    empid: filter?.employee?.empId,
    datefrom: getParamsDateString(filter?.fromDate),
    dateto: getParamsDateString(filter?.toDate),
    market: filter?.market,
  };

  return webApiWithResponse<ViewOrder[]>({
    method: "GET",
    url: "order/getorderlist",
    params,
  });
};

export default getOrdersWebApi;
