import { NewsData } from "../../../data/reports/news/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";
import { useAuth } from "../../../data/auth/hooks";

export const useNewsColumns = () => {
  const {
    getString,
    getDateString,
    getFormatedNumberString,
    getDateTimeString,
  } = React.useContext(LocalizationContext);

  const [auth] = useAuth();

  return React.useMemo(
    () => [
      {
        title: getString("newsTime"),
//        render: (news) => getDateString(news.newsTime, "DD.MM.YYYY"),
				render: news => getDateTimeString(news.newsTime),
        sorting: false,
      },

      {
        title: getString("newsTitle"),
        field: "title",
        sorting: false,
      },
      auth.status==="successful" && auth.user.roleCode !== "MEMTRADER" &&  auth.user.roleCode !== "MEMMANAGER" &&  auth.user.roleCode !== "MEMCONTACT" ? {
        title: getString("stockFeeStatus"),
        render: (news) => (
          <DisplayBoolNumber value={news.status} thrutyValue={Status.Active} />
        ),
        customSort: (a, b) => a.status - b.status, 
      } : {},
    ],
    [getString, getFormatedNumberString]
  ) as Column<NewsData>[];
};
