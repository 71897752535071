import { webApi } from "../../../../../core/http/httpThunks";

export const deleteClientPersonApi = (userId: string, cliprsid: string) =>
	webApi<string>({
		method: "DELETE",
		url: "listing/delcliprs",
		params: {
			userId,
			cliprsid
		},
	});

export default deleteClientPersonApi;
