import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { TradeFilter } from "../../../data/trading/trades/types"
import { LocalizationContext } from "../../../core/localization/Localization";
import { useOrderMarketOptionsTranslator } from "../../../data/trading/orders/hooks/useOrderMarketOptions";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const TradesFilterChips: React.FunctionComponent<TradeFilterChipsProps> = ({
	filter,
	remnoveFilter,
}) => {
	const { getDateString, getString } = React.useContext(LocalizationContext);
	const marketTranslator = useOrderMarketOptionsTranslator();

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	return (
		<Grid container spacing={1}>
			{filter.datefrom &&  !screenExtraSmall &&  (
				<Grid item>
					<Chip
						label={`${getString("filterFrom")}: ${getDateString(new Date(filter.datefrom))}`}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("datefrom")}
					/>
				</Grid>
			)}
			{filter.dateto &&  !screenExtraSmall &&  (
				<Grid item>
					<Chip
						label={`${getString("filterTo")}: ${getDateString(new Date(filter.dateto))}`}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("dateto")}
					/>
				</Grid>
			)}
			{filter.product &&  !screenExtraSmall &&  (
				<Grid item>
					<Chip
						label={filter.product.prodName}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("product")}
					/>
				</Grid>
			)}
			{filter.client &&  !screenExtraSmall &&  (
				<Grid item>
					<Chip
						label={filter.client.cliName}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("client")}
					/>
				</Grid>
			)}
			{filter.market !== undefined && typeof(filter.market) == 'number' &&  !screenExtraSmall &&  (
				<Grid item>
					<Chip
						label={marketTranslator(filter.market)}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("market")}
					/>
				</Grid>
			)}
			{filter.tradeStatus &&  !screenExtraSmall &&  (
				<Grid item>
					<Chip
						label={filter.tradeStatus.ftDescr}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("tradeStatus")}
					/>
				</Grid>
			)}
		</Grid>
	);
};

interface TradeFilterChipsProps {
	filter: TradeFilter;
	remnoveFilter: (type: keyof TradeFilter) => void;
}
