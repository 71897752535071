import { useDispatch, useSelector } from "react-redux";
import { printTradeThunk } from "../thunks/printTradeThunk";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { TradeList } from "../types";
import { setPrintTradeFetchState } from "../../../../data/trading/trades/tradesDataSlice";

export const useTradePrinter = (trade?: TradeList) => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.trading.trades.printTradeFetchState
	);
	const dispatch = useDispatch();

	const download = React.useCallback(() => {
			dispatch(printTradeThunk(trade));
	}, [dispatch, trade]);

	const dispatchSetFetch = React.useCallback(() => {                   //  Ovo da prvo izađe customDialog box
		dispatch(setPrintTradeFetchState({ type: "started" }))
	},	[dispatch] );
	
	return [download, fetchState, dispatchSetFetch] as [typeof download, typeof fetchState, typeof dispatchSetFetch];
};
