import React from "react";
import { Bank } from "../../../data/listing/banks/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayBankMainAttributesProps {
  bank: Bank;
}

export const DisplayBankMainAttributes: React.FunctionComponent<DisplayBankMainAttributesProps> = ({
  bank,
}) => {
  const style = useDisplaySingleEmementStyles();
  const { getString } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("bankCode")}</span>
        <span className={style.value}>{bank.bankCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("bankName")}</span>
        <span className={style.value}>{bank.bankName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("bankIdNumber")}</span>
        <span className={style.value}>{bank.idNumber}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("bankRegNumber")}</span>
        <span className={style.value}>{bank.regNumber}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("cliCity")}</span>
        <span className={style.value}>{bank.city}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("cliAddress")}</span>
        <span className={style.value}>{bank.address}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("cliEmail")}</span>
        <span className={style.value}>{bank.email}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("accountNumber")}</span>
        <span className={style.value}>{bank.account}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("cliStatus")}</span>
        <span className={style.value}>
          <DisplayBoolNumber
            value={bank.status}
            thrutyValue={Status.Active}
            className={style.icon}
          />
        </span>
      </div>
    </div>
  );
};
