import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { StockFee } from "../../../data/clearing/stockfees/types";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import React from "react";
import { Status } from "../../../data/models";

export function useStockFeeColumns() {
  const { getString, getDateString } = useContext(LocalizationContext);
  return useMemo(
    () => [
      {
        title: getString("stockFeeStartDate"),
        render: (payment) => getDateString(payment.startDate, "DD.MM.YYYY"),
        sorting: false,
      },
      {
        title: getString("stockFeeStockFee"),
        field: "stockFee",
      },
      {
        title: getString("stockFeeStatus"),
        render: (stfee) => (
          <DisplayBoolNumber value={stfee.status} thrutyValue={Status.Active} />
        ),
        customSort: (a, b) => a.status - b.status,
      },
    ],
    [getString]
  ) as Column<StockFee>[];
}
