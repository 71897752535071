import { getEditLocation } from "../../../core/util/functions";

export enum CommissionEditParts {
	BasicInfo = "basicinfo",
	Details = "details",
}

export const commissionLocation = "/listing/codebook/commissions";

export const getCommissionEditLocation = (
	part: CommissionEditParts = CommissionEditParts.BasicInfo,
	commissionId?: string,
	detailId?: string
) => getEditLocation(commissionLocation, part, commissionId, detailId);

export const basicInfoEdit = getCommissionEditLocation(":editPart" as any, ":commissionId?");
export const detailEdit = getCommissionEditLocation(
	CommissionEditParts.Details,
	":commissionId",
	":detaileId?"
);
