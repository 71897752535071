import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { TransportFee } from "../../../data/clearing/transportfees/types";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import React from "react";
import { Status } from "../../../data/models";

export function useTransportFeeColumns() {
  const { getString, getDateString } = useContext(LocalizationContext);
  return useMemo(
    () => [
      {
        title: getString("transFeeStartDate"),
        render: (payment) => getDateString(payment.startDate, "DD.MM.YYYY"),
        sorting: false,
      },
      {
        title: getString("transFeeDistance"),
        field: "distance",
      },
      {
        title: getString("transFeePice"),
        field: "price",
      },
      {
        title: getString("transFeeStatus"),
        render: (stfee) => (
          <DisplayBoolNumber value={stfee.status} thrutyValue={Status.Active} />
        ),
        customSort: (a, b) => a.status - b.status,
      },
    ],
    [getString]
  ) as Column<TransportFee>[];
}
