import React from "react";
import { useDispatch } from "react-redux";
import { CommissionDetail } from "../../types";
import { loadCommissionDetails } from "../../thunks/detailes/loadCommissionDetails";

export const useDetails = (commissionId?: string) => {
	const displatch = useDispatch();
	const [details, setDetails] = React.useState<CommissionDetail[] | undefined>();

	const loadDetails = React.useCallback(async () => {
		if (commissionId) {
			const loaded = (await displatch(loadCommissionDetails(commissionId))) as any;
			setDetails(loaded || []);
		}
	}, [displatch, commissionId])

	React.useEffect(() => {
		loadDetails();
	}, [loadDetails]);

	return [details, loadDetails] as [typeof details, typeof loadDetails]
};
