import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import {
	useCurrentTradesReportFilter,
	useTradesReportFilter,
} from "../../../data/reports/tradesReport/hooks/useTradesReportFilter";
import { TradesFilterChips } from "../../trading/trades/TradesFilterChips";
import { TradesFilterEditor } from "../../trading/trades/TradesFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const TradesFilterButton: React.FunctionComponent = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<TradesFilterDrawer />), [showWithDrawer]);

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	const [filter, remnoveFilter] = useCurrentTradesReportFilter();

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<TradesFilterChips filter={filter} remnoveFilter={remnoveFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip">
					<IconButton  color={!screenExtraSmall || Object.keys(filter).length===0 ? "secondary" : "primary" } component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const TradesFilterDrawer: React.FunctionComponent = () => {
	const [filter, editFilter, save, , reset] = useTradesReportFilter();

	return <TradesFilterEditor filter={filter} editFilter={editFilter} save={save} reset={reset} />;
};
