import { ThunkType } from "../../../../core/store/reducers";
import deleteProductApi from "../api/deleteProduct";
import { setDeleteFetchState } from "../productsDataReducer";
import { ErrorCodeExceptions } from "../types";

export const deleteProduct = (roleId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setDeleteFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteProductApi(userId, roleId, [ErrorCodeExceptions.ReferenceErrorCode]));

	dispatch(
		setDeleteFetchState(
			result !== undefined && result.resCode === "OK"
				? { type: "successful" }
				: { type: "error", errorCode: result && result.resCode }
		)
	);
};
