import React from "react";
import { ViewOrder } from "../../../../../data/trading/orders/types";
import { PaginationTable } from "../../../../components/common/PagginationTable";
import { useOrderBookColumns } from "../../../../../views/trading/orderBook/useOrderBookColumns";
import { useOrderBookData } from "../../../../../data/reports/exchbusiness/clientOverview/hooks/useOrderBookData";
import { useClientOverviewIndex } from "../../../../../data/reports/exchbusiness/clientOverview/hooks/useClientOverviewIndex";
import { TableType } from "../../../../../data/reports/exchbusiness/clientOverview/types";
import { PageDataStatus } from "../../../../../data/models";
import { useOrderActions } from "./useOrderActions";

const ClientOverviewOrdersTable: React.FunctionComponent = () => {
	const columns = useOrderBookColumns();

	const [data, loadData] = useOrderBookData();
	const [index, setIndex] = useClientOverviewIndex(TableType.Orders);

	React.useEffect(() => {
		if (data && data.status === PageDataStatus.DataInvalidated) {
			loadData();
		}
	}, [loadData, data]);

	const actions = useOrderActions()

	return (
		<PaginationTable<ViewOrder>
			getData={loadData}
			pageData={data}
			onPageIndexChange={setIndex}
			pageIndex={index}
			title=""
			columns={columns}
			actions={actions}
			options={{
				actionsColumnIndex: -1,
				search: false,
				toolbar: false,
			}}
		/>
	);
};

export default ClientOverviewOrdersTable;
