import { ThunkType } from "../../../../core/store/reducers";
import { setGuarantees, invalidateGuarantee } from "../guaranteesDataSlice";
import { setGuaranteeIndex } from "../guaranteesUXSlice";
import getGuarantees from "../api/getGuarantees";
import { PageIndex, PageDataStatus } from "../../../models";

export const loadAllGuarantees = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const { pageNumber, itemsPerPage } = store.ux.clearing.guarantees.index;
  const { filter } = store.ux.clearing.guarantees;
  const user = store.data.auth.current.user;

  const guaranteesResponse = await dispatch(
    getGuarantees(user, pageNumber, itemsPerPage, filter)
  );

  if (!guaranteesResponse || guaranteesResponse.resCode !== "OK") {
    return;
  }

  const guarantees = {
    currentPageData: guaranteesResponse.obj,
    totalCount: Number(guaranteesResponse.resDesc) || 0,
    status: PageDataStatus.DataCurrent,
  };

  dispatch(setGuarantees(guarantees));
};

export const setGuaranteesIndexThunk = (index: PageIndex): ThunkType => (
  dispatch
) => {
  dispatch(setGuaranteeIndex(index));
  dispatch(invalidateGuarantee());
};
