import { ThunkType } from "../../../../core/store/reducers";
import { getTASCostsApi } from "../api/getTASCosts";
import { CalculationParams, TransportAndStorageCosts } from "../types";
import { setCalculationFetchState } from "../TASDataSlice";

export const calculateTASCostsThunk = (
	params: CalculationParams
): ThunkType<Promise<TransportAndStorageCosts | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const user = store.data.auth.current.user;

	dispatch(setCalculationFetchState({ type: "started" }));

	const costs = await dispatch(
		getTASCostsApi(user.userId, params.tradeid, params.shouldCalcStorage, params.distance)
	);

	dispatch(setCalculationFetchState({ type: "successful", result: costs }));

	return costs;
};
