import { NavigationItem } from "../../../core/navigation/types";
import TransportFeesPage from "./TransportFeesPage";
import { EditTransportFeePage } from "./EditTransportFeePage";
import { transportFeeLocation, editRouter } from "./Locations";

export const transportFeesComponents: NavigationItem[] = [
  {
    Component: TransportFeesPage,
    isMenuItem: true,
    path: transportFeeLocation,
  },
  {
    Component: EditTransportFeePage,
    isMenuItem: false,
    path: editRouter,
  },
];
