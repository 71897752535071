import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteWhs } from "../thunks/deleteWhs";
import { setFetchState } from "../warehousesDataReducer";

export const useWhsDelete = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.listing.warehouses.fetchState
  );

  const dispatch = useDispatch();
  const dispatchDeleteWhs = React.useCallback(
    (id: string) => dispatch(deleteWhs(id)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchDeleteWhs] as [
    typeof fetchState,
    typeof dispatchDeleteWhs
  ];
};
