import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Bank } from "../types";

export const createBankApi = (userId: string, bank: Bank) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "listing/newbank",
    params: {
      userId,
    },
    data: bank,
  });

export default createBankApi;
