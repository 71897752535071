import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { TradingData } from "../types";
import { loadTradingData } from "../thunks/loadTradingData";

export const useDisplayTradingData = (prodId?: string) => {
  const storeTradingData = useSelector<StoreType, TradingData | undefined>(
    (store) => store.data.clearing.tradingData.displayedTradingData
  );

  const displayedTradingData =
  storeTradingData && storeTradingData.ptdId === prodId ? storeTradingData : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (prodId) {
      displatch(loadTradingData(prodId));
    }
  }, [displatch, prodId]);

  return displayedTradingData;
};
