import React from "react";
import { useEditStockFeeState } from "../../../data/clearing/stockfees/hooks/useEditstockFeeState";
import { useStockFeeValidator } from "../../../data/clearing/stockfees/hooks/useStockFeeValidator";
import { useStockFeeUpsert } from "../../../data/clearing/stockfees/hooks/useStockFeeUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import {
  Card,
  Grid,
  CardHeader,
  Divider,
  CardContent,
} from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { StockFee } from "../../../data/clearing/stockfees/types";
import { StringInput } from "../../components/common/StringInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { Status } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { stockFeeLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { DateInput } from "../../components/common/DateInput";
import { NumberInput } from "../../components/common/NumberInput";

export interface EditStockFeeInfo {
  id?: string;
}

export interface EditStockFeeProps
  extends RouteComponentProps<EditStockFeeInfo> {}

export const EditStockFeePage: React.FunctionComponent<EditStockFeeProps> = (
  props
) => {
  const { getString } = React.useContext(LocalizationContext);
  const pageStyles = usePageStyles();

  const [stockFee, editStockFee, changed] = useEditStockFeeState(
    props.match.params.id
  );
  const [validationState, validate] = useStockFeeValidator();
  const [fetchState, upsert] = useStockFeeUpsert();
  const history = useHistory();

  const unsavedChanges = fetchState.type === "not-started" && changed;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      editStockFee(event.target.name as any, event.target.value);
    },
    [editStockFee]
  );

  const save = React.useCallback(() => {
    if (fetchState.type === "successful") {
      history.push(stockFeeLocation);
    } else if (fetchState.type !== "started") {
      const normalized = validate(stockFee);
      if (normalized) {
        upsert(normalized);
      }
    }
  }, [stockFee, validate, fetchState, history, upsert]);

  const pageTitle = stockFee && stockFee.id ? "stockFeeEdit" : "stockFeeCreate";

  if (!stockFee) {
    return null;
  }

  return (
    <Grid container className={pageStyles.root}>
      <Grid item xs={12} className={pageStyles.gridItem}>
        <Card>
          <CardHeader
            title={
              <PageTitle title={pageTitle} backLocation={stockFeeLocation} />
            }
          />
          <Divider />
          <CardContent>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={2}>
                <DateInput
                  label={getString("stockFeeStartDate")}
                  value={stockFee.startDate || null}
                  onChange={(date) => editStockFee("startDate", date)}
                  error={
                    validationState.startDate !== undefined &&
                    !validationState.startDate
                  }
                  required={validationState.startDate !== undefined}
                />
              </Grid>
              <Grid item xs={3}>
                <NumberInput<StockFee>
                  property="stockFee"
                  item={stockFee}
                  validationState={validationState}
                  onChange={handleChange}
                  label={getString("stockFeeStockFee")}
                  money
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={1}>
                <BoolInput<StockFee>
                  property="status"
                  item={stockFee}
                  onChange={handleChange}
                  falsyValue={Status.Inactive}
                  thrutyValue={Status.Active}
                  label={getString("stockFeeStatus")}
                />
              </Grid>
            </Grid>

            <FabSave fetchState={fetchState} onClick={save} />
            <LeavePrompt shouldShow={unsavedChanges} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
