import { TransportFee } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";

interface ITransportFee {
  all?: TransportFee[];
  searchTerm: string;
  displayData?: TransportFee[];
  displayedTransportFee?: TransportFee;
  fetchState: FetchStateType;
}

const initialState: ITransportFee = {
  searchTerm: "",
  fetchState: { type: "not-started" },
};

const name = "transportfees";

const transportFeesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setTransportFee: (
      state,
      action: PayloadAction<TransportFee[] | undefined>
    ) => ({
      ...state,
      all: action.payload,
      displayData: filterData(action.payload, state.searchTerm),
    }),
    setSearchTerm: (state, action: PayloadAction<string>) => ({
      ...state,
      searchTerm: action.payload.toLocaleLowerCase(),
      displayData: filterData(state.all, action.payload),
    }),
    setDisplayed: (state, action: PayloadAction<TransportFee | undefined>) => ({
      ...state,
      displayedTransportFee: action.payload,
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      displayedTransportFee:
        action.payload.type === "successful"
          ? undefined
          : state.displayedTransportFee,
      all: action.payload.type === "successful" ? undefined : state.all,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: getFetchStateChange(state.fetchState, action.payload),
    }),
  },
});

const filterData = (data?: TransportFee[], searchTerm?: string) =>
  searchTerm === undefined || searchTerm === ""
    ? data
    : data?.filter(
        (e) =>
          e.startDate &&
          e.startDate
            .toString()
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())
      );

export const {
  setTransportFee,
  setSearchTerm,
  setDisplayed,
  setFetchState,
} = transportFeesSlice.actions;

export default transportFeesSlice.reducer;
