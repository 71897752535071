import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadReport } from "../thunks/loadReportClientData";
import { WeeklyTradingReportData } from "../types";
import { setData } from "../weeklyTradeDataSlice";

export const useReportClientData = () => {
  const data = useSelector<StoreType, WeeklyTradingReportData[] | undefined>(
    (s) => s.data.reports.weeklyTrade.data
  );

  const dispatch = useDispatch();
  const dispatchLoadData = React.useCallback(() => dispatch(loadReport()), [
    dispatch,
  ]);

  const resetData = React.useCallback(() => {
    dispatch(setData(undefined));
  }, [dispatch]);

  return [data, dispatchLoadData, resetData] as [
    typeof data,
    typeof dispatchLoadData,
    typeof resetData
  ];
};
