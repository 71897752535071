import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { NewsData } from "../../../data/reports/news/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewNews } from "./DisplayNews";
import { useHistory } from "react-router-dom";
import { getNewsEditLocation } from "./Locations";
import { useNewsDelete } from "../../../data/reports/news/hooks/useNewsDelete";
import { useAuth } from "../../../data/auth/hooks";

export function useNewsActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowNews = useViewNews();
  const onEditNewse = useEditNewsAction();

  const [auth] = useAuth();

  return useMemo(() => {
    const result: MaterialTableProps<NewsData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("newsShow"),
        onClick: (event, rowData) => onShowNews(rowData as NewsData),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) => onEditNewse(rowData as NewsData),
//        hidden: auth.status === "successful" && auth.user.roleCode==="MEMTRADER"
        hidden: auth.status === "successful" && (auth.user.roleCode === "MEMTRADER" || auth.user.roleCode === "MEMMANAGER"  ||  auth.user.roleCode === "MEMCONTACT" )  
      },
    ];
    return result;
  }, [onShowNews, onEditNewse, getString]);
}

export const useDeleteNewsAction = () => {
  const [, deleteNews] = useNewsDelete();
  return React.useCallback(
    async (news: NewsData) => {
      if (news.newsId) {
        await deleteNews(news.newsId);
      }
    },
    [deleteNews]
  );
};

export const useEditNewsAction = () => {
  const history = useHistory();
  return React.useCallback(
    (news: NewsData) =>
        history.push(getNewsEditLocation(news.newsId)),
    [history]
  );
};
