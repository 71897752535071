import { memberContractsReportComponents } from "./memberContracts/navigationComponents";
import { tradeReviewReportComponents } from "./tradeReview/navigationComponents";
import { tradeByProductsReportComponents } from "./tradeByProducts/navigationComponents";
import { tradeByClientsReportComponents } from "./tradeByClients/navigationComponents";
import { tradeByBrokersReportComponents } from "./tradeByBrokers/navigationComponents";
import { weightedPricesReportComponents } from "./weightedPrices/navigationComponents";
import { businessActivitiesReportComponents } from "./businessActivities/navigationComponents";
import { commReviewReportComponents } from "./commReview/navigationComponents";
import { clientOverviewComponents } from "./clientOverview/navigationComponents";
import { historicalPricesReportComponents } from "./historicalPrices/navigationComponents";

export const exchBusinessComponents = [
  ...memberContractsReportComponents,
  ...tradeReviewReportComponents,
  ...tradeByProductsReportComponents,
  ...tradeByClientsReportComponents,
  ...tradeByBrokersReportComponents,
  ...weightedPricesReportComponents,
  ...businessActivitiesReportComponents,
  ...commReviewReportComponents,
  ...clientOverviewComponents,
  ...historicalPricesReportComponents,
];
