import { AnnualSecurityReportData, AnnualSecurityReportFilter } from "../types";
import { User } from "../../../../auth/types";
import { webApi } from "../../../../../core/http/httpThunks";

const getAnnualSecurityData = (
  user: User,
  filter: AnnualSecurityReportFilter,
) => {

  return webApi<AnnualSecurityReportData[]>({
    method: "GET",
    url: "reporting/getsecannualtradinglist",
    params: {
      userid: user.userId,
      year: filter.year,
    },
  });
};

export default getAnnualSecurityData;
