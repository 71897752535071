import React from "react";
import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { ProductGroupData } from "../../../data/listing/productGroups/types";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export function useProductGroupColumns() {
	const { getString } = useContext(LocalizationContext);
	return useMemo(
		() => [
			{
				title: getString("productGroupName"),
				field: "prodGroupName",
			},
			{
				title: getString("productGroupStatus"),
				render: group => <DisplayBoolNumber value={group.status} thrutyValue={Status.Active} />,
				customSort: (a, b) => a.status - b.status,
			},
		],
		[getString]
	) as Column<ProductGroupData>[];
}
