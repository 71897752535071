import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { CurrencyRate } from "../types";

export const createCurrencyRateApi = (userId: string, rate: CurrencyRate) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/newcurrrate",
		params: {
			userId
		},
		data: rate
	});

export default createCurrencyRateApi;
