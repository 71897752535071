import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import { TradeList } from "../../../../../data/trading/trades/types";
import { LocalizationContext } from "../../../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewTrade } from "../../../../trading/trades/DisplayTrade";

export function useTradeActions() {
	const { getString } = useContext(LocalizationContext);
	const onShowTrade = useViewTrade();

	return useMemo<MaterialTableProps<TradeList>["actions"]>(
		() => [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tradeViewAction"),
				onClick: (event, rowData) => onShowTrade(rowData as TradeList),
			},
		],
		[onShowTrade, getString]
	);
}
