import { ThunkType } from "../../../../core/store/reducers";
import getAllProductGroupsAPI from "../api/getAllProductGroups";
import { setData } from "../productGroupDataReducer";

export const loadAllProductGroups = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setData());

	const { userId } = store.data.auth.current.user;
	const groups = await dispatch(getAllProductGroupsAPI(userId));

	dispatch(setData(groups || []));
};
