import { webApi } from "../../../../../core/http/httpThunks";
import { ClientPerson } from "../../types";

export const getClientPersonApi = (userId: string, cliid: string) =>
	webApi<ClientPerson[]>({
		method: "GET",
		url: "listing/getcliprslist",
		params: {
			userId,
			cliid
		},
	});

export default getClientPersonApi;
