

import { NewsData, NewsDataFilter} from "../types";
import { NewsParams } from "../types";
import { User } from "../../../auth/types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getAllNewsApi = (
  user: User,
  page: number,
  itemsPerPage: number,
  filter: NewsDataFilter
) => {
  const params: NewsParams = {
    userid: user.userId,
    fromrow: page * itemsPerPage,
    numofrows: itemsPerPage,
    datefrom: getParamsDateString(filter.datefrom),
    dateto: getParamsDateString(filter.dateto),
    status: filter.status,
  };

  return webApiWithResponse<NewsData[]>({
    method: "GET",
    url: "news/getnewslist",
    params,
  });
};

export default getAllNewsApi;
