import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteNews } from "../thunks/deleteNews";
import { setFetchState } from "../newsDataReducer";

export const useNewsDelete = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.reports.news.cancelFetchState
  );

  const dispatch = useDispatch();
  const dispatchDeleteNews = React.useCallback(
    (id: string) => dispatch(deleteNews(id)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchDeleteNews] as [
    typeof fetchState,
    typeof dispatchDeleteNews
  ];
};
