import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { PayType } from "../../../data/listing/payTypes/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewPayType } from "./DisplayPayType";
import { usePayTypeDelete } from "../../../data/listing/payTypes/hooks/usePayTypeDelete";
import { getPayTypeEditLocation } from "./Locations";
import { AccessRights } from "../../../core/navigation/types";


export const usePayTypeActions = (userAccessRights: AccessRights) => {
  const { getString } = useContext(LocalizationContext);
  const onShowPayType = useViewPayType();
  const onEditPayType = useEditPayTypeAction();

  return useMemo(() => {
    const result: Action<PayType>[] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("tableShow"),
        onClick: (event, rowData) => onShowPayType(rowData as PayType),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) => onEditPayType(rowData as PayType),
        hidden: userAccessRights===AccessRights.ReadOnly
      },
    ];

    return result;
  }, [onShowPayType, onEditPayType, getString]);
};

export const useDeletePayTypeAction = () => {
  const [, deletePayType] = usePayTypeDelete();
  return React.useCallback(
    async (payType: PayType) => {
      if (payType.payTypeId ) {
        await deletePayType(payType.payTypeId);
      }
    },
    [deletePayType]
  );
};

export const useEditPayTypeAction = () => {
  const history = useHistory();
  return React.useCallback(
    (payType: PayType) => history.push(getPayTypeEditLocation(payType.payTypeId)),
    [history]
  );
};
