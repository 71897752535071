import { webApi } from "../../../../../core/http/httpThunks";
import { AttributeValue } from "../../types";

export const getAttributeValueApi = (userId: string, attrvalid: string) =>
	webApi<AttributeValue>({
		method: "GET",
		url: "listing/getattrval",
		params: {
			userId,
			attrvalid
		},
	});

export default getAttributeValueApi;
