import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";
import { TradeByClientsReportFilter } from "../types";

export const getTradeByClientsReport = (
  user: User,
  filter: TradeByClientsReportFilter
) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getturnoverbyclireport",
    params: {
      userid: user.userId,
      datefrom: filter.datefrom,
      dateto: filter.dateto,
      clientId: filter?.clientId,
      cliStatus: filter?.cliStatus,
    },
  });
};

export default getTradeByClientsReport;
