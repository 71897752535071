import { ThunkType } from "../../../../../core/store/reducers";
import { setData } from "../dailySecurityDataSlice";
import getDailySecurityDataApi from "../api/getDailySecurityData";

export const loadReport = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const user = store.data.auth.current.user;
	const { filter } = store.ux.reports.securityCommission.dailySecurity;

	const pagedTrades = await dispatch(getDailySecurityDataApi(user, filter));

	dispatch(setData(pagedTrades || []));
};
