import { webApi } from "../../../../core/http/httpThunks";

export const deleteControlApi = (userId: string, ctrlid: string) =>
  webApi<string>({
    method: "DELETE",
    url: "clearing/delctrlgoods",
    params: {
      userId,
      ctrlid,
    },
  });

export default deleteControlApi;
