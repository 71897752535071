import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { usePayTypeData } from "../../../data/listing/payTypes/hooks/usePayTypeData";
import { PayType } from "../../../data/listing/payTypes/types";
import { usePayTypeColumns } from "./usePayTypeColumns";
import { AccessRights } from "../../../core/navigation/types";
import { usePayTypeActions, useDeletePayTypeAction } from "./usePayTypeActions";

export interface PayTypeTableProps {
  userAccessRights: AccessRights;
}

type PayTypeTablePropsType = PayTypeTableProps & TableDataProps<PayType>;

export const PayTypesTable: React.FunctionComponent<PayTypeTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const [data, loadAll] = usePayTypeData();

  const columns = usePayTypeColumns();
  const actions = usePayTypeActions(userAccessRights);
  const onDeletePayType = useDeletePayTypeAction();

  return (
    <Table<PayType>
      {...tableProps}
      getData={loadAll}
      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        toolbar: false,
        pageSize: 10,
        actionsColumnIndex: -1,
      }}
       editable={userAccessRights !== AccessRights.ReadOnly ? {
        onRowDelete:  onDeletePayType,
      }: {}}
    />
  );
};

export default PayTypesTable;
