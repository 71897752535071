import { useCopiedState } from "../../../../core/util/hooks";
import { setFilter, removeFilter, initialState } from "../bankguaranteesUXSlice";
import { invalidateBankGuarantee } from "../bankguaranteesDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { BankGuaranteeFilter } from "../types";

export const useBankGuaranteeFilter = () => {
	const [storeFilter] = useCurrentBankGuaranteeFilter();
	const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<BankGuaranteeFilter>(storeFilter);

	const dispatch = useDispatch();
	const dispatchSetFilter = React.useCallback(() => {
		dispatch(setFilter(filter!));
		dispatch(invalidateBankGuarantee());
	}, [dispatch, filter]);

	const dispatchResetFilter = React.useCallback(() => {
		setCurrentFilter(initialState.filter);
	}, [setCurrentFilter]);

	return [filter, editFilter, dispatchSetFilter, changeState, dispatchResetFilter] as [
		typeof filter,
		typeof editFilter,
		typeof dispatchSetFilter,
		typeof changeState,
		typeof dispatchResetFilter
	];
};

export const useCurrentBankGuaranteeFilter = () => {
	const filter = useSelector<StoreType, BankGuaranteeFilter>(
	  (store) => store.ux.clearing.bankguarantees.filter
	);
	const dispatch = useDispatch();
	const dispatchRemoveFilter = React.useCallback(
	  (type: keyof BankGuaranteeFilter) => {
		dispatch(removeFilter(type));
		dispatch(invalidateBankGuarantee());
	  },
	  [dispatch]
	);
  
	return [filter, dispatchRemoveFilter] as [
	  typeof filter,
	  typeof dispatchRemoveFilter
	];
  };
  