import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { ProductGroupData } from "../../../data/listing/productGroups/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewProductGroup } from "./DisplayProductGroup";
import { useProductGroupDelete } from "../../../data/listing/productGroups/hooks/useProductGroupDelete";
import { getProductGroupEditLocation } from "./Locations";
import { AccessRights } from "../../../core/navigation/types";

export const useProductGroupActions = (userAccessRights: AccessRights) => {
	const { getString } = useContext(LocalizationContext);
	const onShowRole = useViewProductGroup();
	const onEditRole = useEditRoleAction();

	return useMemo(() => {
		const result: Action<ProductGroupData>[] = [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tableShow"),
				onClick: (event, rowData) => onShowRole(rowData as ProductGroupData),
			},
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditRole(rowData as ProductGroupData),
				hidden: userAccessRights===AccessRights.ReadOnly
			},
		];

		return result;
	}, [onShowRole, onEditRole, getString]);
};

export const useDeleteRoleAction = () => {
	const [, deleteGroup] = useProductGroupDelete();
	return React.useCallback(
		async (group: ProductGroupData) => {
			if (group.prodGroupID) {
				await deleteGroup(group.prodGroupID);
			}
		},
		[deleteGroup]
	);
};

export const useEditRoleAction = () => {
	const history = useHistory();
	return React.useCallback(
		(group: ProductGroupData) => history.push(getProductGroupEditLocation(group.prodGroupID)),
		[history]
	);
};
