import React from "react";
import { useMemberContractsColumns } from "./useMemberContractsReportColumns";
import { AccessRights } from "../../../../core/navigation/types";
import { MemberContractsReportData } from "../../../../data/reports/exchbusiness/memberContracts/types";
import { ControledTable } from "../../../components/common/ControledTable";
import { useMemberContractsReportData } from "../../../../data/reports/exchbusiness/memberContracts/hooks/useMemberContractsReportData";
import { useMemberContractsReportIndex } from "../../../../data/reports/exchbusiness/memberContracts/hooks/useMemberContractsReportIndex";

export interface MemberContractsTableProps {
  userAccessRights: AccessRights;
}

type MemberContractsTablePropsType = MemberContractsTableProps;

const MemberContractsReportTable: React.FunctionComponent<MemberContractsTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useMemberContractsColumns();
  const [data, load] = useMemberContractsReportData();
  const [index, setIndex] = useMemberContractsReportIndex();

  return (
    <ControledTable<MemberContractsReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default MemberContractsReportTable;
