import { MonthlyTradingReportFilter } from "../types";
import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

export const getMonthlyTradingExcel = (
  user: User,
  filter: MonthlyTradingReportFilter
) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getsecmonthlytradingxls",
    params: {
      userid: user.userId,
      month: filter.month,
      year: filter.year,
    },
  });
};

export default getMonthlyTradingExcel;
