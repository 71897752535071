import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import { GuaranteeFilter } from "./types";

type GuaranteePageState = {
  index: PageIndex;
  filter: GuaranteeFilter;
};

export const initialState: GuaranteePageState = {
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
  filter: {
    status: {
      ftValue: -1,
      ftDescr: "",
    },
  },
};

const name = "guarantees";

const guaranteesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setGuaranteeIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    resetGuaranteeIdices: (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }),
    setFilter: (state, action: PayloadAction<GuaranteeFilter>) => ({
      ...state,
      filter: action.payload,
    }),
    removeFilter: (state, action: PayloadAction<keyof GuaranteeFilter>) => ({
      ...state,
      filter: {
        ...state.filter,
        [action.payload]: undefined,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(successfulLogin, (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }));
  },
});

export const {
  setGuaranteeIndex,
  resetGuaranteeIdices,
  setFilter,
  removeFilter,
} = guaranteesSlice.actions;

export default guaranteesSlice.reducer;
