import { ThunkType } from "../../../../core/store/reducers";
import deleteAttributeApi from "../api/deleteAttribute";
import { setDeleteFetchState } from "../attributeDataReducer";
import { ErrorCodeExceptions } from "../types";

export const deleteAttribute = (attributeId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setDeleteFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(
		deleteAttributeApi(userId, attributeId, [ErrorCodeExceptions.ReferenceErrorCode])
	);

	dispatch(
		setDeleteFetchState(
			result !== undefined && result.resCode === "OK"
				? { type: "successful" }
				: { type: "error", errorCode: result && result.resCode }
		)
	);
};
