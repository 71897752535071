import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Client } from "../types";
import { loadClient } from "../thunks/loadClient";

export const useDisplayClient = (cliId?: string) => {
	const storeClient = useSelector<StoreType, Client | undefined>(
		store => store.data.listing.clients.displayedClient
    );

    const displayedEmployee = storeClient && storeClient.cliId === cliId ? storeClient : undefined;

    const displatch = useDispatch();

	React.useEffect(() => {
		if (cliId) {
			displatch(loadClient(cliId));
		}
    }, [displatch, cliId]);

	return displayedEmployee;
};
