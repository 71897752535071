import React from "react";
import { useEmployeeData } from "../../../data/listing/employees/hooks/useEmployeeData";
import { Employee } from "../../../data/listing/employees/types";
import { AutocompleteSelectType, AutocompleteSelect } from "../common/AutocompleteSelect";

export type EmployeeSelectPropsType = Omit<
	AutocompleteSelectType<Employee>,
	"data" | "getLabel"
>

export const EmployeeSelect: React.FunctionComponent<EmployeeSelectPropsType> = props => {
	const [, loadAllEmployees, employees] = useEmployeeData();

	React.useEffect(() => {
		if (!employees) {
			loadAllEmployees();
		}
	}, [employees, loadAllEmployees]);

	return (
		<AutocompleteSelect<Employee>
			data={employees}
			getLabel={renderEmployeeLabel}
			{...props}
		/>
	);
};

const renderEmployeeLabel = (employee: Employee | null) =>
	employee ? `${employee.lastName} ${employee.firstName} ${renderIdNumber(employee.empIdNumber)}` : "";

const renderIdNumber = (id?: string) => (id ? `(${id})` : "");
