import { useCopiedState } from "../../../../core/util/hooks";
import { setFilter, removeFilter, initialState, setReportData } from "../tradingUXSlice";
import { invalidateTradingsData } from "../tradingDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { TradingDataFilter } from "../types";

export const useTradingDataFilter = () => {
  const [storeFilter] = useCurrentTradingDataFilter();
  const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<
  TradingDataFilter
  >(storeFilter);

  const dispatch = useDispatch();
  const dispatchSetFilter = React.useCallback(() => {
    dispatch(setFilter(filter!));
    dispatch(invalidateTradingsData());
  }, [dispatch, filter]);

  const dispatchResetFilter = React.useCallback(() => {
    setCurrentFilter(initialState.filter);
  }, [setCurrentFilter]);

  return [
    filter,
    editFilter,
    dispatchSetFilter,
    changeState,
    dispatchResetFilter,
  ] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter,
    typeof changeState,
    typeof dispatchResetFilter
  ];
};

export const useCurrentTradingDataFilter = () => {
  const filter = useSelector<StoreType, TradingDataFilter>(
    (store) => store.ux.clearing.tradingData.filter
  );
  const dispatch = useDispatch();
  const dispatchRemoveFilter = React.useCallback(
    (type: keyof TradingDataFilter) => {
      dispatch(removeFilter(type));
      dispatch(invalidateTradingsData());
    },
    [dispatch]
  );
	const dispatchSetReportDate = React.useCallback(
		(reportDate: string) => {
			dispatch(setReportData(reportDate));
			dispatch(invalidateTradingsData());
		},
		[dispatch]
	);


  return [filter, dispatchRemoveFilter,dispatchSetReportDate] as [
    typeof filter,
    typeof dispatchRemoveFilter,
    typeof dispatchSetReportDate
  ];
};
