import React from "react";
import { useDisplayCurrency } from "./useDisplayCurrency";
import { Currency } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";

export const defauiltCurrency: Currency = {
	currCode: "",
	currName: "",
	numCode: "",
};

export const useEditCurrency = (currencyId?: string) => {
	const displayCurrency = useDisplayCurrency(currencyId);

	return React.useMemo(() => {
		if (currencyId !== undefined && displayCurrency === undefined) {
			return undefined;
		}

		if (currencyId === undefined) {
			return { ...defauiltCurrency };
		}

		return displayCurrency ? { ...displayCurrency } : undefined;
	}, [currencyId, displayCurrency]);
};

export const useEditCurrencyState = (empId?: string) => {
	const currency = useEditCurrency(empId);
	return useCopiedState(currency);
};
