import React from "react";
import { useCICreateButton } from "../../../data/accounting/createInvoices/hooks/useCICreateButton";
import DownloadIcon from "@material-ui/icons/DoubleArrowOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { LocalizationContext } from "../../../core/localization/Localization";
import { CustomDialog } from "../../components/common/CustomDialog";

export const CreateButton: React.FunctionComponent = () => {
	const [downloader, fetchState, resetData, hasData] = useCICreateButton();
	const { getString } = React.useContext(LocalizationContext);
	return (
		<>
			<CustomTooltip titleKeyword="iInvoiceTooltip">
				<IconButton
					color="secondary"
					component="span"
					onClick={downloader}
					disabled={fetchState.type !== "not-started" || !hasData}
				>
					<DownloadIcon />
				</IconButton>
			</CustomTooltip>
			<CustomDialog
				open={fetchState.type === "successful"}
				title={getString("invoicingMessageTitle")}
				message={getString("invoicingMessage")}
				closeLabel={getString("invoicingMessageConfim")}
				onClose={resetData}
			/>
		</>
	);
};
