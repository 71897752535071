import { useDispatch, useSelector } from "react-redux";
import { printOrderThunk } from "../thunks/printOrderThunk";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { EditOrder, Order } from "../../orders/types";

export const useOrderPrinter = (order?: Order) => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.trading.orderBook.printOrderFetchState
	);
	const dispatch = useDispatch();
	const download = React.useCallback(() => {
			dispatch(printOrderThunk(order));
	}, [dispatch, order]);

	return [download, fetchState] as [typeof download, typeof fetchState];
};
