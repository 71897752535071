import React from "react";
import { AlarmData } from "../../../data/surveillance/alarmParameters/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { AlarmType, Status } from "../../../data/models";
import { useAlarmTypeTranslator } from "../../../data/surveillance/alarmParameters/hooks/useAlarmType";

export interface DisplayAlarmMainAttributesProps {
	alarm: AlarmData;
}

export const DisplayAlarmMainAttributes: React.FunctionComponent<DisplayAlarmMainAttributesProps> = ({
	alarm,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);
	const translator = useAlarmTypeTranslator();

	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("alarmAlmType")}</span>
				<span className={style.value}>{translator(alarm.almType as any)}</span>
			</div>
			{alarm.almType === AlarmType.Client && (
				<div className={`${style.row} ${style.rowBorder}`}>
					<span className={style.label}>{getString("alarmClient")}</span>
					<span className={style.value}>{alarm.clientName}</span>
				</div>
			)}
			{alarm.almType === AlarmType.Product && (
				<>
					<div className={`${style.row} ${style.rowBorder}`}>
						<span className={style.label}>{getString("alarmProductCode")}</span>
						<span className={style.value}>{alarm.productCode}</span>
					</div>
					<div className={`${style.row} ${style.rowBorder}`}>
						<span className={style.label}>{getString("alarmProduct")}</span>
						<span className={style.value}>{alarm.productName}</span>
					</div>
				</>
			)}
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("dlvrstatus")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={alarm.active}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				</span>
			</div>
		</div>
	);
};
