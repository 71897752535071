import React from "react";
import { CardContent, CardHeader, Card, Divider, Typography, Grid } from "@material-ui/core";
import { useGraphData } from "../../../data/trading/ticker/hooks/useGraphData";
import { useGraphPeriod } from "../../../data/trading/ticker/hooks/useGraphPeriod";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ChartPeriod } from "../../../data/trading/ticker/types";
import { CartesianGrid, Line, LineChart, XAxis, Tooltip, YAxis, ResponsiveContainer } from "recharts";
import { LocalizationContext } from "../../../core/localization/Localization";
import { ProductSelect } from "../../components/listing/ProductSelect";
import { useGraphProduct } from "../../../data/trading/ticker/hooks/useGraphProduct";

export interface ProductGraphProps {
	graphId: string;
}

export const ProductGraph: React.FunctionComponent<ProductGraphProps> = ({ graphId }) => {
	const { getString, getDateString, getDateTimeString, getFormatedNumberString } = React.useContext(
		LocalizationContext
	);

	const [prodId, setProdId] = useGraphProduct(graphId);
	const [data] = useGraphData(graphId);
	const [period, setPeriod] = useGraphPeriod(graphId);
	
	return (
		<Card>
			<CardHeader
				title={
					<Grid container spacing={2} alignItems="baseline" alignContent="space-between" justify="space-between">
						<Grid item xs={12} >
							<ProductSelect
								label={getString("ctrlproductName")}
								predicate={prod => prod.prodId === prodId}
								onChangeItem={prod => {
									setProdId(prod ? prod.prodId : undefined);
								}}
							/>
						</Grid>
						<Grid item xs={12} >
							<ToggleButtonGroup
								value={period}
								exclusive
								onChange={(e, v) => setPeriod(v)}
								size="small"
							>
								<ToggleButton size="small" value={ChartPeriod.Month}>1M</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.ThreeMonts}>3M</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.HalfYear}>6M</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.FromYearBeggining}>YTD</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.Year}>1Y</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.All}>All</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
					</Grid>
				}
			>
				</CardHeader>
			<Divider />
			<CardContent>
				<ResponsiveContainer width="100%" height={400}>
					<LineChart data={data} margin={{ top: 5, right: 20, left: 0, bottom: 5 }}>
						<XAxis dataKey="tradeTime" tickFormatter={date => getDateString(date, "DD.MM.")} />
						<YAxis yAxisId={0} domain={["auto", "auto"]} />
						<Tooltip
							formatter={value => [
								getFormatedNumberString(value as any, true),
								getString("orderPrice"),
							]}
//							labelFormatter={(date: any) => getDateTimeString(date)}
							labelFormatter={(date: any) => getDateString(date)}
						/>
						<CartesianGrid stroke="#f5f5f5" />
						<Line type="monotone" dataKey="price" stroke="#772E25" yAxisId={0} dot={false} />
					</LineChart>
				</ResponsiveContainer>
			</CardContent>
		</Card>
	);
};
