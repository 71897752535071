import { ValidationState } from "../../../core/util/types";
import { Status } from "../../models";
import { AttributeManditory, AttributeType } from "../attributes/types";

export interface ProductList {
	prodId: string;
	prodName: string;
	prodCode?: string; // 		sifra proizvoda
	prodGroupName: string;
	unit: string;
	vat: string;
	status: Status;
}

export interface Product {
	prodId?: string; // 		PK
	prodName: string; // 		naziv
	prodNameen: string; // 		naziv EN
	prodCode?: string; // 		sifra proizvoda
	productGroupId?: string; // 	ID grupe proizvoda
	productGroupName?: string; // 	naziv grupe (za prikaz)
	unit?: number; // 			jedinica mere
	unitCode?: string; // 		naziv JM (za prikaz)
	vat?: number; // 			PDV grupa (vatrate iz commondata)
	vatRate?: string; // 		% pdv (za prikaz)
	status: Status; // 			status
}

export interface ProductAttribute {
	prodAttrId?: string;
	productId: string;
	attributeId?: string;
	prodAttrName: string;
	type: string;
	hasStandard: ProducAttributeHasStandard;
	standardValue?: number;
	mandatory: ProducAttributeManditory;
	comparator: string;
	orderNo?: number;
	status: Status;
}

export enum ProducAttributeManditory {
	True = 1,
	False = 0,
}

export enum ProducAttributeHasStandard {
	True = 1,
	False = 0,
}

export interface ProductSelectData {
	prodId: string;
	prodName: string;
	prodCode: string;
	unit?: string;
	vatRate?: number;
	status: Status; // 			status
	prodAttrList: ProductSelectAttribute[];
}


export interface ProductSelectAttribute {
	attrId: string; 
	attrName: string; 
	type: AttributeType; 
	mandatory: AttributeManditory; 
	comparator: string; 
	hasStandard: ProducAttributeHasStandard;
	standardValue?: number;
	status: Status;
}

export type ProductValidationState = ValidationState<Product>;

export enum ErrorCodeExceptions {
	ReferenceErrorCode = "E-903",
}
