import { Role, RoleValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const roleValidationState: RoleValidationState = {
	roleCode: true,
	roleName: true,
	roleNameEn: true,
};

export const useRoleValidator = createUseValidator<Role, RoleValidationState>(roleValidationState);
