import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import {
	usePaymentsFilter,
	useCurrentPaymentsFilter,
} from "../../../data/clearing/payments/hooks/usePaymentsFilter";
import {
	PaymentFilterEditor,
	PaymentFilterEditorType,
	PaymentFilterChips,
} from "./PaymentFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const PaymentFilterButton: React.FunctionComponent = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<PaymentsFilterEditor />), [showWithDrawer]);

	const [filter, removeFilter] = useCurrentPaymentsFilter();
	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<PaymentFilterChips filter={filter} removeFilter={removeFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip">
					<IconButton color="secondary" component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const PaymentsFilterEditor: React.FunctionComponent = () => {
	const [filter, editFilter, save, , rest] = usePaymentsFilter();
	return (
		<PaymentFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
			type={PaymentFilterEditorType.Extended}
			reset={rest}
		/>
	);
};
