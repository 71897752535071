import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Role } from "../types";

export const createRoleApi = (userId: string, role: Role) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/newrole",
		params: {
			userId
		},
		data: role
	});

export default createRoleApi;
