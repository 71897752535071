import React from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { DateInput } from "../../../components/common/DateInput";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { ClientOverviewFilter } from "../../../../data/reports/exchbusiness/clientOverview/types";
import { ClientSelect } from "../../../components/listing/ClientSelect";
import { ClientOverviewFilterChipsComponent } from "./ClientOverviewFilterChips";
import { DisplayDrawerContext } from "../../../../layout/DisplayDrawerContext";
import { isValidDate } from "../../../../core/util/functions";
import { OrderStatusSelect } from "../../../components/common/OrderStatusSelect";
import { ClientOverviewParts } from "./Locations";
import { TradeStatusSelect } from "../../../components/common/TradeStatusSelect";
import { FiltredTransactionsSelect } from "../../../components/trading/FiltredTransactionsSelect";
import { SimpleTransFilter } from "../../../../data/trading/transactions/types";
import { TransSimpleData } from "../../../../data/trading/transactions/types";

interface ClientOverviewFilterEditorProps {
	filter?: ClientOverviewFilter;
	editFilter: (prop: keyof ClientOverviewFilter, value: any) => void;
	save: () => void;
	type?: ClientOverviewParts;
	reset?: () => void;
}

export const ClientOverviewFilterEditor: React.FunctionComponent<ClientOverviewFilterEditorProps> = ({
	filter,
	editFilter,
	save,
	type,
	reset,
}) => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const { getString } = React.useContext(LocalizationContext);

	const [tradesFilter, setTransactionFilter] = React.useState<SimpleTransFilter | undefined>(
		createTransactionFilterPayment(filter)
	);
	const [selectedOrder, setSelectedOrder] = React.useState<TransSimpleData | null>(null);

  React.useEffect(() => {
		setTransactionFilter(createTransactionFilterPayment(filter));
	}, [filter, setTransactionFilter]);


	return (
		<Paper
			style={{
				paddingLeft: "70px",
				paddingRight: "70px",
				paddingTop: "15px",
				paddingBottom: "15px",
			}}
		>
			<Grid container direction="column" spacing={6}>
				<Grid item container direction="row" spacing={2}>
					<Grid item xs={6}>
						<DateInput
							label={getString("tradingDatadatefrom")}
							value={filter?.datefrom || null}
							onChange={date =>
								editFilter("datefrom", isValidDate(date) ? date.toISOString() : undefined)
							}
							disableFuture
						/>
					</Grid>
					<Grid item xs={6}>
						<DateInput
							label={getString("tradingDatadateto")}
							value={filter?.dateto || null}
							onChange={date =>
								editFilter("dateto", isValidDate(date) ? date.toISOString() : undefined)
							}
							disableFuture
						/>
					</Grid>
					<Grid item xs={12}>
						<ClientSelect
							label={getString("orderClientName")}
							predicate={client => client.cliId === filter?.client?.cliId}
							onChangeItem={client => editFilter("client", client)}
						/>
					</Grid>
					{type === ClientOverviewParts.Orders && (
						<Grid item xs={12}>
							<OrderStatusSelect
								label={getString("orderStatus")}
								predicate={status => status.ftValue === filter?.orderStatus?.ftValue}
								onChangeItem={status => {
									editFilter("orderStatus", status);
								}}
							/>
						</Grid>
					)}
					{type === ClientOverviewParts.Trades && (
						<Grid item xs={12}>
							<TradeStatusSelect
								label={getString("orderStatus")}
								predicate={status => status.ftValue === filter?.tradeStatus?.ftValue}
								onChangeItem={status => {
									editFilter("tradeStatus", status);
								}}
							/>
						</Grid>
					)}
				</Grid>

				{type === ClientOverviewParts.Payments && (
				<Grid item xs={12}>
									<FiltredTransactionsSelect
										label={getString("paymentClientTransactions")}
										filter={tradesFilter}
										predicate={trans => trans.ordId === filter?.tranId}
										onChangeItem={trans => {
											setSelectedOrder(trans);
											editFilter("tranId", trans ? trans.ordId : undefined);
											editFilter("tranDesc", trans ? trans.ordData : undefined);
										}}
                    disabled={false}
								/>
	  			</Grid>
				  )}


				<Grid item container direction="row-reverse" spacing={2}>
					<Grid item>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								save();
								showWithDrawer(undefined);
							}}
						>
							{getString("orderFilterConfirm")}
						</Button>
					</Grid>
					<Grid item>
						<Button variant="text" onClick={reset}>
							{getString("filterReset")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};


const createTransactionFilterPayment = (filter?: ClientOverviewFilter) => {
	return filter && filter.client && filter.client.cliId
		? {
				cliid: filter.client.cliId,
		  }
		: undefined;
};

export const ClientOverviewFilterChips = ClientOverviewFilterChipsComponent;
