import React from "react";
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { LocalizationContext } from "../../core/localization/Localization";
import { useAlarmsChangedHandler, useNotificationHandler } from "../../core/notifications/hooks";
import { useSnackbarMessageSender } from "../../data/snackbar/hooks/useSnackbarMessageSender";
import { NotificationManager } from '../../core/notifications/NotificationsManager';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export interface IProductAlarmNotification {
	productName: string;
	clientName: string;
	orderNumber: string;
}

export interface IProductClientAlarmNotification {
	productName: string;
	clientName: string;
	orderNumber: string;
}

export interface IClientAlarmNotification {
	productName: string;
	clientName: string;
	orderNumber: string;
}

export interface ICrossAlarmNotification {
	productCode: string;
	clientName: string;
	buyOrderNumber: string;
	sellOrderNumber: string;
	price: number;
	qty: number;
	qtyMatched: number;
	date: Date;
}

export interface IMobileOrderNotification {
	title: string;
	product: string;
	qty: number;
	unit: string;
	price: number;
	francoDescr : string; 
	districtName : string;
	quality: string;
	guaranteeDescr : string;
	payType : string;
	goodsReadyFrom : string;
	goodsReadyTo : string;
	packing : string;
	origin : string;
	directorate : string;
}

export const SurveillanceNotificationHandlers: React.FunctionComponent = () => {
	const [pushInfo, pushWarning] = useSnackbarMessageSender();
	const { getString, getDateTimeString } = React.useContext(LocalizationContext);

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	console.log(screenExtraSmall)

	useAlarmsChangedHandler();

	useNotificationHandler<IProductAlarmNotification>(
		"OrderProduct",
		e => {
			console.log("OrderProduct", e);
			pushInfo(`${getString("alarmProductName")}`, [
				`${getString("exchOrderNumber")}: ${e.orderNumber}`,
				`${getString("orderClientName")}: ${e.clientName}`,
				`${getString("orderProduct")}: ${e.productName}`,
			]);
		},
		[pushInfo]
	);
	useNotificationHandler<IProductClientAlarmNotification>(
		"OrderProductClient",
		e => {
			console.log("OrderProductClient", e);
			pushInfo(`${getString("alarmProductClientName")}`, [
				`${getString("exchOrderNumber")}: ${e.orderNumber}`,
				`${getString("orderClientName")}: ${e.clientName}`,
				`${getString("orderProduct")}: ${e.productName}`,
			]);
		},
		[pushInfo]
	);
	useNotificationHandler<IClientAlarmNotification>(
		"OrderClient",
		e => {
			console.log("OrderClient", e);
			pushInfo(`${getString("alarmClientName")}`, [
				`${getString("exchOrderNumber")}: ${e.orderNumber}`,
				`${getString("orderClientName")}: ${e.clientName}`,
				`${getString("orderProduct")}: ${e.productName}`,
			]);
		},
		[pushInfo]
	);
	useNotificationHandler<ICrossAlarmNotification>(
		"OrderCross",
		e => {
			console.log("OrderCross", e);
			pushInfo(`${getString("alarmCrossName")}`, [
				`${getString("alarmBuyOrderNumber")}: ${e.buyOrderNumber}`,
				`${getString("alarmSellOrderNumber")}: ${e.sellOrderNumber}`,
				`${getString("orderClientName")}: ${e.clientName}`,
				`${getString("orderProduct")}: ${e.productCode}`,
				`${getString("orderPrice")}/${getString("orderQty")}: ${e.price}/${e.qty}`,
				`${getString("orderQtyMatched")}: ${e.qtyMatched}`,
				`${getString("alarmTime")}: ${getDateTimeString(e.date)}`,
			]);
		},
		[pushInfo]
	);

	const notifyIfNecessary = (title: string, messages: Array<string>, id?: number) => {
		if (Capacitor.getPlatform() === 'web') return;
		App.getState()
			.then((state) => {
				if (state.isActive) return;
				// NotificationManager.schedule({
				// 	id,
				// 	title,
				// 	body: messages.join('\r\n'),
				// 	iconPath: process.env.PUBLIC_URL + "favicon.ico",
				// });

				// we don't notify anymore from app like before
				// because we gonna install push notifications
			});
	}

	useNotificationHandler<IMobileOrderNotification>(
		"MobileOrder",
		e => {
			if (!screenExtraSmall) return;

			const title = getString("notificationNewOrder");
			const messages = [
				`${getString("orderSide")}: ${e.title}`,
				`${getString("orderProduct")}: ${e.product}`,
				`${getString("orderQty")}: ${e.qty}`,
				`${getString("orderUnit")}: ${e.unit}`,
				`${getString("orderPrice")}: ${e.price}`,
				`${getString("regNameDistrict")}: ${e.districtName}`,
				`${getString("orderQuality")}: ${e.quality}`,
				`${getString("orderFranco")}: ${e.francoDescr}`,
				`${getString("orderBankGuarantee")}: ${e.guaranteeDescr}`,
				`${getString("orderPayType")}: ${e.payType}`,
				`${getString("orderGoodsReadyFrom")}: ${e.goodsReadyFrom}`,
				`${getString("orderGoodsReadyTo")}: ${e.goodsReadyTo}`,
				`${getString("notifPacking")}: ${e.packing}`,
				`${getString("notifOrigin")}: ${e.origin}`,
				`${getString("notifDirectorate")}: ${e.directorate}`,
			];

			console.log("MobileOrder", e);
			//			pushInfo(title, messages);
			pushWarning(title, messages);
			notifyIfNecessary(title, messages); //, -1
		},
		[pushInfo, screenExtraSmall]
	);

	useNotificationHandler<IMobileOrderNotification>(
		"MobileTrade",
		e => {
			if (!screenExtraSmall) return;

			console.log("MobileTrade", e);
			const title = getString("notificationNewTrade")
			const messages = [
				`${getString("orderSide")}: ${e.title}`,
				`${getString("orderProduct")}: ${e.product}`,
				`${getString("orderQty")}: ${e.qty}`,
				`${getString("orderUnit")}: ${e.unit}`,
				`${getString("orderPrice")}: ${e.price}`,
				`${getString("regNameDistrict")}: ${e.districtName}`,
				`${getString("orderQuality")}: ${e.quality}`,
				`${getString("orderFranco")}: ${e.francoDescr}`,
				`${getString("orderBankGuarantee")}: ${e.guaranteeDescr}`,
				`${getString("orderPayType")}: ${e.payType}`,
				`${getString("orderGoodsReadyFrom")}: ${e.goodsReadyFrom}`,
				`${getString("orderGoodsReadyTo")}: ${e.goodsReadyTo}`,
				`${getString("notifPacking")}: ${e.packing}`,
				`${getString("notifOrigin")}: ${e.origin}`,
				`${getString("notifDirectorate")}: ${e.directorate}`,
			]
//			pushInfo(title, messages);
			pushWarning(title, messages);
			notifyIfNecessary(title, messages); //,-2
		},
		[pushInfo, screenExtraSmall]
	);

	{/*	useNotificationHandler<IMobileOrderNotification>(
		"MobileTrade",
		e => {
			console.log("MobileTrade", e);
			pushInfo(`${getString("notificationNewTrade")}`, [
				`${getString("orderSide")}: ${e.title}`,
				`${getString("orderProduct")}: ${e.product}`,
				`${getString("orderQty")}: ${e.qty}`,
				`${getString("orderUnit")}: ${e.unit}`,
				`${getString("orderPrice")}: ${e.price}`,
			]);
		},
		[pushInfo]
	);  */}

	{/*	useNotificationHandler<IMobileOrderNotification>(
		"MobileOrder",
		e => {
			console.log("MobileOrder", e);
			pushInfo(`${getString("notificationNewOrder")}`, [
				`${getString("orderSide")}: ${e.title}`,
				`${getString("orderProduct")}: ${e.product}`,
				`${getString("orderQty")}: ${e.qty}`,
				`${getString("orderUnit")}: ${e.unit}`,
				`${getString("orderPrice")}: ${e.price}`,
			]) ;
		},
		[pushInfo]
	);  */}

	return null;
};
