import { ClientListData } from "../../../../data/listing/clients/types";
import { OrderStatus, TradeStatus } from "../../../models";

export interface GetClientOverviewParams {
	userid: string;
	cliid?: string;
	datefrom?: string;
	dateto?: string;
	fromrow: number;
	numofrows: number;
	status?: number;
	tranid?: string;
}

export interface ClientOverviewFilter {
	datefrom?: string;
	dateto?: string;
	client?: Partial<ClientListData>;
	orderStatus?: OrderStatus;
	tradeStatus?: TradeStatus;
	tranId?: string;
	tranDesc?: string;
  }

export enum TableType {
	Orders = "orders",
	Trades = "trades",
	Payments = "pamens",
	Deliveries = "deliveries",
}
