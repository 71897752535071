import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useNewsFilter, useCurrentNewsFilter, } from "../../../data/reports/news/hooks/useNewsFilter";
import { LocalizationContext } from "../../../core/localization/Localization";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { NewsFilterEditor } from "./NewsEditor";
import { NewsFilterChips } from "./NewsFilterChips";

export const NewsFilterButton: React.FunctionComponent = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const showFilter = React.useCallback(
    () => showWithDrawer(<NewsFiltersEditor />),
    [showWithDrawer]
  );

  const [filter, removefilter] = useCurrentNewsFilter();
  const { getString } = React.useContext(LocalizationContext);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <NewsFilterChips filter={filter} removefilter={removefilter} />
      </Grid>
      <Grid item>
        <CustomTooltip titleKeyword="filterTooltip">
          <IconButton color="secondary" component="span" onClick={showFilter}>
            <FilterIcon />
          </IconButton>
        </CustomTooltip>
      </Grid>
    </Grid>
  );
};

const NewsFiltersEditor: React.FunctionComponent = () => {
  const [filter, editFilter, save] = useNewsFilter();
  return (
    <NewsFilterEditor
      filter={filter}
      editFilter={editFilter}
      save={save}
    />
  );
};
