import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";

export const printClientContract = (user: User, cliContrId: string) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getcontractreport",
		params: {
			userid: user.userId,
			cliContrId,
		},
	});
};

export default printClientContract;
