import { ThunkType } from "../../../../../core/store/reducers";
import getClientBrokerApi from "../../api/clientBroker/getClientBroker";
import { ClientBroker } from "../../types";

export const getClientBroker = (clientBrokerId: string): ThunkType<Promise<ClientBroker | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getClientBrokerApi(userId, clientBrokerId));
}
