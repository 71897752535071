import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Product } from "../types";
import { loadProduct } from "../thunks/loadProduct";

export const useDisplayProduct = (productId?: string) => {
	const storeProduct = useSelector<StoreType, Product | undefined>(
		store => store.data.listing.products.displayed
    );

    const displayedProduct = storeProduct && storeProduct.prodId === productId ? storeProduct : undefined;

    const displatch = useDispatch();

	React.useEffect(() => {
		if (productId) {
			displatch(loadProduct(productId));
		}
    }, [displatch, productId]);

	return displayedProduct;
};
