import { CommissionDetail } from "../../types";
import { ValidationState } from "../../../../../core/util/types";
import { createUseValidator } from "../../../../../core/util/hooks";

export const detailValidation: ValidationState<CommissionDetail> = {
	fromDate: true,
	commId: true,
};

export const useDetailValidator = createUseValidator<CommissionDetail, ValidationState<CommissionDetail>>(
	detailValidation,
	(item, validation) => ({
		...validation,
		percBuy: !item.percBuy || item.percBuy < 100,
		percSell: !item.percSell || item.percSell < 100
	})
);
