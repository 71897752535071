import React from "react";
import { useDailyTradesColumns } from "./useDailyTradingReportColumns";
import { AccessRights } from "../../../core/navigation/types";
import { ControledTable } from "../../components/common/ControledTable";
import { DailyTradingReportData } from "../../../data/reports/dailyTrade/types";
import { useDailyTradesReportIndex } from "../../../data/reports/dailyTrade/hooks/useDailyTradesReportIndex";
import { useTradesReportData } from "../../../data/reports/dailyTrade/hooks/useTradesReportData";

export interface OrderTableProps {
  userAccessRights: AccessRights;
}

type OrderTablePropsType = OrderTableProps;

const DailyTradesReportTable: React.FunctionComponent<OrderTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useDailyTradesColumns();
  const [data, load] = useTradesReportData();
  const [index, setIndex] = useDailyTradesReportIndex();

  return (
    <ControledTable<DailyTradingReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default DailyTradesReportTable;
