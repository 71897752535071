import React from "react";
import { useDispatch } from "react-redux";
import { setReg } from "../regionsDataReducer";
import { RegType } from "../../../models";

export const useRegionType = (regtype: RegType) => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(setReg(regtype));
	}, [dispatch, regtype]);

	return [regtype] as [typeof regtype];
};
