import CommReviewReport from "./CommReviewReport";
import { NavigationItem } from "../../../../core/navigation/types";
import { commReviewLocation } from "./Locations";

export const commReviewReportComponents: NavigationItem[] = [
  {
    Component: CommReviewReport,
    isMenuItem: true,
    path: commReviewLocation,
  },
];
