import { useDispatch, useSelector } from "react-redux";
import { downloadReport } from "../thunks/downloadMonthlyClientReport";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";

export const useMonthlyReportDownloaderClient = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (s) => s.data.reports.monthlyTrade.downloadReportFetchState
  );
  const dispatch = useDispatch();
  const download = React.useCallback(() => dispatch(downloadReport()), [
    dispatch,
  ]);

  return [download, fetchState] as [typeof download, typeof fetchState];
};
