import React from "react";
import { useEditTradingDataState } from "../../../data/clearing/tradingData/hooks/useEditTradingDataState";
import { useTradingDataValidator } from "../../../data/clearing/tradingData/hooks/useTradingDataValidator";
import { useTradingDataUpsert } from "../../../data/clearing/tradingData/hooks/useTradingDataUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { TradingData } from "../../../data/clearing/tradingData/types";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { Status } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { tradingDataLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { ProductSelect } from "../../components/listing/ProductSelect";
import { NumberInput } from "../../components/common/NumberInput";
import { DateInput } from "../../components/common/DateInput";

export interface EditTradingDataInfo {
	ptdId?: string;
}

export interface EditTradingDataProps extends RouteComponentProps<EditTradingDataInfo> {}

export const EditTradingDataPage: React.FunctionComponent<EditTradingDataProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [tradingData, editTradingData, changed] = useEditTradingDataState(props.match.params.ptdId);
	const [validationState, validate] = useTradingDataValidator();
	const [fetchState, upsert] = useTradingDataUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editTradingData(event.target.name as any, event.target.value);
		},
		[editTradingData]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(tradingDataLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(tradingData);

			if (normalized) {
				upsert(normalized);
			}
		}
	}, [tradingData, validate, fetchState, history, upsert]);

	const pageTitle = tradingData && tradingData.ptdId ? "tradingDataEdit" : "tradingDataEdit";

	if (!tradingData) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={tradingDataLocation} />} />
					<Divider />
					<CardContent>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={3}>
								<DateInput
									label={getString("tradingDataDataTime")}
									value={tradingData.tradingDate || null}
									onChange={date => editTradingData("tradingDate", date)}
									error={validationState.tradingDate !== undefined && !validationState.tradingDate}
									required={validationState.tradingDate !== undefined}
									disabled={!!tradingData.ptdId}
									disableFuture
								/>
							</Grid>
						</Grid>

						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={6}>
								<ProductSelect
									label={getString("tradingDataProductName")}
									predicate={product => product.prodId === tradingData.productId}
									onChangeItem={product => {
										editTradingData("productId", product ? product.prodId : undefined);
									}}
									required={validationState.productId !== undefined}
									error={
										validationState.productId !== undefined && !validationState.productId
									}
									disabled={!!tradingData.ptdId}
								/>
							</Grid>
							<Grid
								container
								item
								direction="row"
								justify="flex-start"
								alignItems="center"
								spacing={3}
							>
								<Grid item xs={3}>
									<NumberInput<TradingData>
										property="refPrice"
										item={tradingData}
										validationState={validationState}
										onChange={handleChange}
										label={getString("tradingDataRefPrice")}
										money
									/>
								</Grid>
							</Grid>
						</Grid>

						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

