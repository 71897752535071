import React from "react";
import { useAttributeValue } from "./useAttributeValue";
import { AttributeValue } from "../../types";
import { Status } from "../../../../models";
import { useCopiedState } from "../../../../../core/util/hooks";

export const createDefaultAttributeValue = (attributeId: string): AttributeValue => ({
	attributeId,
	name: "",
	nameen: "",
	value: "",
	valueen: "",
	status: Status.Active,
});

export const useEditAttributeValue = (productId: string, attributeId?: string) => {
	const [displayAttributeValue] = useAttributeValue(attributeId);

	return React.useMemo(() => {
		if (attributeId !== undefined && displayAttributeValue === undefined) {
			return undefined;
		}

		if (attributeId === undefined) {
			const defaultAttribute = createDefaultAttributeValue(productId);
			return { ...defaultAttribute };
		}

		return displayAttributeValue ? { ...displayAttributeValue } : undefined;
	}, [productId, attributeId, displayAttributeValue]);
};

export const useEditAttributeValueState = (productId: string, attributeId?: string) => {
	const value = useEditAttributeValue(productId, attributeId);
	return useCopiedState(value);
};
