import { srTranslation } from "./srTranslation";

export type LocalizationKey = keyof typeof srTranslation;

export type TranslationsType = [
	{
		[key in LocalizationKey]: string;
	},
	{
		[key in string]: string;
	}
];

export enum LocalizationLanguage {
	EN = "EN",
	SR = "SR",
}
