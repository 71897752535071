import { ExchangeMembersReportData, ExchangeMemebersFilter } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";

const getExchangeMembersData = (
  user: User,
  filter: ExchangeMemebersFilter
  ) => {
  return webApi<ExchangeMembersReportData[]>({
    method: "GET",
    url: "reporting/getexchangememberlist",
    params: {
      userid: user.userId,
      brokerId: filter?.brokerId,
      cliStatus: filter?.cliStatus,    
    },
  });
};

export default getExchangeMembersData;
