import React from "react";
import { useDisplayDispute } from "./useDisplayDispute";
import { DisputeData } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defaultDispute: DisputeData = {
  dispSitId: "",
  tradeId: "",
  status: Status.Inactive,
//  situationDescr: " ",
};

export const useEditDispute = (dispId?: string) => {
  const displayDispute = useDisplayDispute(dispId);

  return React.useMemo(() => {
    if (dispId !== undefined && displayDispute === undefined) {
      return undefined;
    }

    if (dispId === undefined) {
      return { ...defaultDispute };
    }

    return displayDispute ? { ...displayDispute } : undefined;
  }, [dispId, displayDispute]);
};

export const useEditDisputeState = (dispId?: string) => {
  const disputes = useEditDispute(dispId);
  return useCopiedState(disputes);
};
