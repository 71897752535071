import { InvoiceFilter } from "../types";
import { User } from "../../../auth/types";
import { getParamsDateString } from "../../../../core/util/functions";
import { httpWebApi } from "../../../../core/http/httpThunks";

const getAccountingOrderButton = (user: User, filter: InvoiceFilter) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getaccountingorderxls",
    params: {
      userid: user.userId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      accordtype: 1,
    },
  });
};

export default getAccountingOrderButton;
