import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid} from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useCtrlGoodsFilter, useCurrentCtrlGoodsFilter } from "../../../data/clearing/ctrlgoods/hooks/useCtrlGoodsFilter";
import {
  CtrlGoodsFilterEditor,
  CtrlGoodsFilterEditorType,
  CtrlGoodsFilterChips,
} from "../ctrlgoods/CtrlGoodsFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const CtrlGoodsFilterButton: React.FunctionComponent = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const showFilter = React.useCallback(
    () => showWithDrawer(<ControlGoodsFilterEditor />),
    [showWithDrawer]
  );

  const [filter, removeFilter] = useCurrentCtrlGoodsFilter();

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<CtrlGoodsFilterChips filter={filter} removeFilter={removeFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip">
					<IconButton color="secondary" component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};


const ControlGoodsFilterEditor: React.FunctionComponent = () => {
  const [filter, editFilter, save, ,reset] = useCtrlGoodsFilter();
  return (
    <CtrlGoodsFilterEditor
      filter={filter}
      editFilter={editFilter}
      save={save}
      type={CtrlGoodsFilterEditorType.Extended}
			reset={reset}
    />
  );
};
