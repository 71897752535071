import { ValidationState } from "../../../core/util/types";

export interface CurrencyRate {
	currRateId?: number;
	currId?: string; // ID valute
	date: Date; // datum kursa
	buyRate?: number; // kupovni
	sellRate?: number; // prodajni
	midRate?: number; // srednji
	rateFor: CurrencyRateFor; //
}

export enum CurrencyRateFor {
	True = 1,
	False = 0,
}

export type CurrencyRateValidationState = ValidationState<CurrencyRate>;
