import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { ProductAttribute } from "../../models";

const getAttributeLabel = (attribute?: ProductAttribute) => (attribute ? attribute.attrName : "");

export const useProductAttributes = () => {
	const attributes = useSelector<StoreType, ProductAttribute[]>(
		store => store.data.initialData.productAttributes
	);

	return [attributes, getAttributeLabel] as [typeof attributes, typeof getAttributeLabel];
};
