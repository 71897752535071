import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewCommission } from "./DisplayCommission";
import { useCommissionDelete } from "../../../data/listing/commissions/hooks/useCommissionDelete";
import { Commission } from "../../../data/listing/commissions/types";
import { CommissionEditParts, getCommissionEditLocation } from "./Locations";
import { AccessRights } from "../../../core/navigation/types";

export const useCommissionActions = (userAccessRights: AccessRights) => {
	const { getString } = useContext(LocalizationContext);
	const onShowClient = useViewCommission();
	const onEditClient = useEditCommissionAction();

	return useMemo(() => {
		const result: Action<Commission>[] = [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tableShow"),
				onClick: (event, rowData) => onShowClient(rowData as Commission),
			},
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditClient(rowData as Commission),
				hidden: userAccessRights===AccessRights.ReadOnly
			},
		];

		return result;
	}, [onShowClient, getString, onEditClient]);
};

export const useDeleteCommissionAction = () => {
	const [, deleteCommission] = useCommissionDelete();
	return React.useCallback(
		async (commission: Commission) => {
			if (commission.commId) {
				await deleteCommission(commission.commId);
			}
		},
		[deleteCommission]
	);
};

export const useEditCommissionAction = () => {
	const history = useHistory();
	return React.useCallback(
		(comm: Commission) =>
			history.push(getCommissionEditLocation(CommissionEditParts.BasicInfo, comm.commId)),
		[history]
	);
};
