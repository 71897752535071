import React from "react";
import { DeliveryListData } from "../../../data/clearing/delivery/types";
import {
  PaginationTable,
  PaginationDataProps,
} from "../../components/common/PagginationTable";
import {
  useDeliveriesActions,
  useDeleteDeliveryAction,
} from "./useDeliveriesActions";
import { useDeliveriesColumns } from "./useDeliveriesColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface DeliveryTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}

type DeliveryTablePropsType = DeliveryTableProps &
  PaginationDataProps<DeliveryListData>;

const DeliveriesTable: React.FunctionComponent<DeliveryTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = useDeliveriesActions();
  const columns = useDeliveriesColumns();
  const onDeleteDelivery = useDeleteDeliveryAction();

  return (
    <PaginationTable<DeliveryListData>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
      editable={{
        onRowDelete: onDeleteDelivery,
      }}
    />
  );
};

export default DeliveriesTable;
