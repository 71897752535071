import React from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { DateInput } from "../../../components/common/DateInput";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { TurnoverByBrokersReportFilter } from "../../../../data/reports/exchbusiness/tradeByBrokers/types";
import { useAuth } from "../../../../data/auth/hooks";
import { DisplayDrawerContext } from "../../../../layout/DisplayDrawerContext";
import { ActStatusClientsSelect } from "../../../components/common/ActStatusClients";
import { EmployeeSelect } from "../../../components/listing/EmployeeSelect";

interface TradeByBrokersFilterEditorProps {
  filter?: TurnoverByBrokersReportFilter;
  editFilter: (prop: keyof TurnoverByBrokersReportFilter, value: any) => void;
  save: () => void;
}

export const TradeByBrokersFilterEditor: React.FunctionComponent<TradeByBrokersFilterEditorProps> = ({
  filter,
  editFilter,
  save,
}) => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const { getString } = React.useContext(LocalizationContext);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      editFilter(event.target.name as any, event.target.value);
    },
    [editFilter]
  );

  if (!filter) {
    return null;
  }

  return (
    <Paper
      style={{
        paddingLeft: "70px",
        paddingRight: "70px",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Grid container direction="column" spacing={6}>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={6}>
            <DateInput
              label={getString("exchDateFrom")}
              value={filter?.datefrom || null}
              onChange={(date) => editFilter("datefrom", date?.toISOString())}
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              label={getString("exchDateTo")}
              value={filter?.dateto || null}
              onChange={(date) => editFilter("dateto", date?.toISOString())}
            />
          </Grid>
        </Grid>

{/*        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12}>
          <ClientSelect
								label={getString("orderClientName")}
								predicate={(client) => client.cliId === filter.clientId}
								onChangeItem={(client) => {editFilter("clientId", client ? client.cliId :undefined);}}
                />
          </Grid>
    </Grid>  */}

        <Grid item>
						<EmployeeSelect
							label={getString("orderbrokName")}
							predicate={employee => employee.empId === filter?.brokerId}
							onChangeItem={(employee) => {	editFilter("brokerId", employee ? employee.empId : undefined);	}}
              />
					</Grid>

        <Grid item>  
						<ActStatusClientsSelect
							label={getString("cliStatus")}
							predicate={status => status.ftValue === filter.cliStatus}
							onChangeItem={status => {
								editFilter("cliStatus", status?.ftValue);
							}}
						/>  
        </Grid>   


        <Grid item container direction="row-reverse" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                save();
                showWithDrawer(undefined);
              }}
            >
              {getString("orderFilterConfirm")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
