import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Commission } from "../types";

export const createCommisionApi = (userId: string, commision: Commission) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/newcomm",
		params: {
			userId
		},
		data: commision
	});

export default createCommisionApi;
