import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { InvoiceData } from "../types";

export const createInvoiceApi = (userId: string, invoice: InvoiceData) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "accounting/newinvoice",
    params: {
      userId,
    },
    data: invoice,
  });

export default createInvoiceApi;
