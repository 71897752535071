import React from "react";
import { usePageStyles } from "../../pageStyles";
import { Fab, CircularProgress } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CheckIcon from "@material-ui/icons/Check";
import { FetchStateType } from "../../../data/models";

interface FabSaveProps {
	onClick: () => void;
	fetchState: FetchStateType;
}

export const FabSave: React.FunctionComponent<FabSaveProps> = ({ fetchState, onClick }) => {
	const pageStyles = usePageStyles();
	const fabClass =
		fetchState.type === "successful"
			? `${pageStyles.mainFab} ${pageStyles.fabSuccess}`
			: pageStyles.mainFab;
	const disabled = fetchState.type === "started";

	return (
		<div className={pageStyles.fabProgressWrapper}>
			<Fab
				color="secondary"
				aria-label="save"
				className={fabClass}
				onClick={onClick}
				disabled={disabled}
			>
				{fetchState.type !== "successful" ? <SaveIcon /> : <CheckIcon />}
			</Fab>
			{fetchState.type === "started" && <CircularProgress size={68} className={pageStyles.fabProgress} />}
		</div>
	);
};
