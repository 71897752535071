import {  setReportYear } from "../annualSecurityUXSlice";
import { setData } from "../annualSecurityDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import React from "react";
import { AnnualSecurityReportFilter } from "../types";

export const useCurrentTradesReportFilter = () => {
	const filter = useSelector<StoreType, AnnualSecurityReportFilter>(
		store => store.ux.reports.securityCommission.annualSecurity.filter
	);
	const dispatch = useDispatch();

	const dispatchSetReportYear = React.useCallback(
		(year: number) => {
			dispatch(setReportYear(year));
			dispatch(setData());
		},
		[dispatch]
	);

	return [filter,  dispatchSetReportYear] as [
		typeof filter,
		typeof dispatchSetReportYear
	];
};
