import { useDispatch, useSelector } from "react-redux";
import { downloadNewsThunk } from "../thunks/downloadNewsThunk";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { NewsData } from "../types";
import { setDownloadNewsFetchState } from "../newsDataReducer";

export const useNewsDownload = (invoice?: NewsData) => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (s) => s.data.reports.news.downloadNewsFetchState
  );
  const dispatch = useDispatch();
  const download = React.useCallback(() => {
    dispatch(downloadNewsThunk(invoice));
  }, [dispatch, invoice]);

  const dispatchSetFetch = React.useCallback(() => {                   
		dispatch(setDownloadNewsFetchState({ type: "started" }))
	},	[dispatch] );

  return [download, fetchState, dispatchSetFetch] as [typeof download, typeof fetchState, typeof dispatchSetFetch];
};



