import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useAttributeData } from "../../../data/listing/attributes/hooks/useAttributeData";
import { Attribute, ErrorCodeExceptions } from "../../../data/listing/attributes/types";
import { useAttributeColumns } from "./useAttributeColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useAttributeActions, useDeleteAttributeAction } from "./useAttributeActions";
import { LocalizationContext } from "../../../core/localization/Localization";
import { CustomDialog } from "../../components/common/CustomDialog";

export interface AttributeTableProps {
	userAccessRights: AccessRights;
}

type AttributeTablePropsType = AttributeTableProps & TableDataProps<Attribute>;

export const AttributeTable: React.FunctionComponent<AttributeTablePropsType> = ({
	userAccessRights,
	...tableProps
}) => {
	const [data, loadAll] = useAttributeData();
	const { getString } = React.useContext(LocalizationContext);

	const columns = useAttributeColumns();
	const actions = useAttributeActions(userAccessRights);
	const [fetchState, onDelete, resetState] = useDeleteAttributeAction();


	return (
		<>
			<Table<Attribute>
				{...tableProps}
				getData={loadAll}
				immutableData={data}
				title=""
				columns={columns}
				actions={actions}
				options={{
					toolbar: false,
					pageSize: 10,
					actionsColumnIndex: -1,
				}}
				editable={userAccessRights !== AccessRights.ReadOnly ? {
					onRowDelete: onDelete,
				} : {}}
			/>
			<CustomDialog
				open={
					fetchState.type === "error" &&
					fetchState.errorCode === ErrorCodeExceptions.ReferenceErrorCode
				}
				title={getString("errorTitle")}
				message={getString("errorProductAttributeDelete")}
				closeLabel={getString("errorConfirm")}
				onClose={resetState}
			/>
		</>
	);
};

export default AttributeTable;
