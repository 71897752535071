import { ThunkType } from "../../../../core/store/reducers";
import getEmployee from "../api/getEmployee";
import { setDisplayedEmployee } from "../employeesDataReducer";

export const loadEmployee = (employeeId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setDisplayedEmployee());

	const { userId } = store.data.auth.current.user;
	const employee = await dispatch(getEmployee(userId, employeeId));

	dispatch(setDisplayedEmployee(employee));
};
