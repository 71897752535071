import React from "react";
import { BankGuaranteeData } from "../../../data/clearing/bankguarantees/types";
import {
  PaginationTable,
  PaginationDataProps,
} from "../../components/common/PagginationTable";
import {
  useBankGuaranteesActions,
  useDeleteBankGuaranteeAction,
} from "./useBankGuaranteesActions";
import { useBankGuaranteeColumns } from "./useBankGuaranteesColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface GuaranteeTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}

type GuaranteeTablePropsType = GuaranteeTableProps &
  PaginationDataProps<BankGuaranteeData>;

const BankGuaranteesTable: React.FunctionComponent<GuaranteeTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = useBankGuaranteesActions();
  const columns = useBankGuaranteeColumns();
  const onDeleteBankGuarantee = useDeleteBankGuaranteeAction();

  return (
    <PaginationTable<BankGuaranteeData>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
      editable={{
        onRowDelete: onDeleteBankGuarantee,
      }}
    />
  );
};

export default BankGuaranteesTable;
