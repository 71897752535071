import React from "react";
import { useClientValidator } from "../../../data/listing/clients/hooks/useClientValidator";
import { useClientUpsert } from "../../../data/listing/clients/hooks/useClientUpsert";
import { useHistory } from "react-router-dom";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Grid } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { DateInput } from "../../components/common/DateInput";
import { StringInput } from "../../components/common/StringInput";
import { City, Status } from "../../../data/models";
import { Client, ClientHeadquaters, ClientActive } from "../../../data/listing/clients/types";
import { ClientTypeSelect } from "./ClientTypeSelect";
import { CitySelect } from "../../components/common/CitySelect";
import { BoolInput } from "../../components/common/BoolInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { clientsLocation } from "./Locations";
import { useAuth } from "../../../data/auth/hooks";

export interface EditClientBasicProps {
	client: Client;
	editClient: (field: keyof Client, value: any) => void;
	changed: boolean;
}

export const EditClientBasicInfo: React.FunctionComponent<EditClientBasicProps> = props => {
	const { getString } = React.useContext(LocalizationContext);

	const { client, editClient, changed } = props;
	const [validationState, validate] = useClientValidator();
	const [fetchState, upsert] = useClientUpsert();
	const history = useHistory();

	const [auth] = useAuth();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editClient(event.target.name as any, event.target.value);
		},
		[editClient]
	);

	const handleActiveFromChange = React.useCallback(
		(date: Date | null) => {
			editClient("activeFrom", date);
		},
		[editClient]
	);

	const handleActiveToChange = React.useCallback(
		(date: Date | null) => {
			editClient("activeTo", date);
		},
		[editClient]
	);

	const changeCity = React.useCallback(
		(city: City | null) => {
			editClient("cityId", city ? city.cityId : "");
		},
		[editClient]
	);

	const changeMailCity = React.useCallback(
		(city: City | null) => {
			editClient("mailCityId", city ? city.cityId : "");
		},
		[editClient]
	);

	const save = React.useCallback(() => {
		const save = async () => {
			const normalized = validate(client);
			if (normalized) {
				const newId = await upsert(normalized);
				if (newId) {
					editClient("cliId", newId);
				}
			}
		};

		if (fetchState.type === "successful") {
			history.push(clientsLocation);
		} else if (fetchState.type !== "started") {
			save();
		}
	}, [client, validate, fetchState, editClient, history, upsert]);

	if (!client) {
		return null;
	}

	return (
		<>
			<Grid container direction="column" justify="center" alignItems="center" spacing={4}>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={3}>
					<Grid item xs={8}>
						<StringInput<Client>
							property="fullName"
							item={client}
							validationState={validationState}
							onChange={handleChange}
							label={getString("clilFullName")}
						/>
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={3}>
					<Grid item xs={3}>
						<StringInput<Client>
							property="cliName"
							item={client}
							validationState={validationState}
							onChange={handleChange}
							label={getString("cliName")}
						/>
					</Grid>
					<Grid item xs={2}>
						<StringInput<Client>
							property="cliIdNo"
							item={client}
							validationState={validationState}
							onChange={handleChange}
							label={getString("cliIdNo")}
						/>
					</Grid>
					<Grid item xs={2}>
						<StringInput<Client>
							property="taxCode"
							item={client}
							validationState={validationState}
							onChange={handleChange}
							label={getString("cliTaxCode")}
						/>
					</Grid>
					<Grid item xs={2}>
						<StringInput<Client>
							property="cliJBKJS"
							item={client}
							validationState={validationState}
							onChange={handleChange}
							label={getString("clijbkjs")}
						/>
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={3}>
					<Grid item xs={3}>
						<BoolInput<Client>
							property="foreignCli"
							item={client}
							onChange={handleChange}
							falsyValue={ClientHeadquaters.Domestic}
							thrutyValue={ClientHeadquaters.Foreign}
							label={getString("cliForeign")}
						/>
					</Grid>
					<Grid item xs={3}>
						<ClientTypeSelect handleChange={handleChange} typeValue={client.cliType} />
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={3}>
					<Grid item xs={3}>
						<StringInput<Client>
							property="address"
							item={client}
							validationState={validationState}
							onChange={handleChange}
							label={getString("cliAddress")}
						/>
					</Grid>
					<Grid item xs={3}>
						<CitySelect
							label={getString("cliCity")}
							predicate={city => city.cityId === client.cityId}
							onChangeItem={changeCity}
						/>
					</Grid>
					<Grid item xs={3}>
						<StringInput<Client>
							property="mailAddress"
							item={client}
							validationState={validationState}
							onChange={handleChange}
							label={getString("cliMailAddress")}
						/>
					</Grid>
					<Grid item xs={3}>
						<CitySelect
							label={getString("cliMailCity")}
							predicate={city => city.cityId === client.mailCityId}
							onChangeItem={changeMailCity}
						/>
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={3}>
					<Grid item xs={3}>
						<StringInput<Client>
							property="phone"
							item={client}
							validationState={validationState}
							onChange={handleChange}
							label={getString("cliPhone")}
						/>
					</Grid>
					<Grid item xs={3}>
						<StringInput<Client>
							property="mobile"
							item={client}
							validationState={validationState}
							onChange={handleChange}
							label={getString("cliMobile")}
						/>
					</Grid>
					<Grid item xs={3}>
						<StringInput<Client>
							property="fax"
							item={client}
							validationState={validationState}
							onChange={handleChange}
							label={getString("cliFax")}
						/>
					</Grid>
					<Grid item xs={3}>
						<StringInput<Client>
							property="email"
							item={client}
							validationState={validationState}
							onChange={handleChange}
							label={getString("cliEmail")}
						/>
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={3}>
{/*					<Grid item xs={3}>
						<BoolInput<Client>
							property="active"
							item={client}
							onChange={handleChange}
							falsyValue={ClientActive.AccessForbiden}
							thrutyValue={ClientActive.HasAccess}
							label={getString("cliActive")}
						/>
					</Grid>  */}
					<Grid item xs={2}>
						<DateInput
							label={getString("cliActiveFrom")}
							value={client.activeFrom || null}
							onChange={handleActiveFromChange}
//							disabled={client.active !== ClientActive.HasAccess}
						/>
					</Grid>
					<Grid item xs={2}>
						<DateInput
							label={getString("cliActiveTo")}
							value={client.activeTo || null}
							onChange={handleActiveToChange}
//							disabled={client.active !== ClientActive.HasAccess}
						/>
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={3}>
					<Grid item xs={8}>
						<StringInput<Client>
							property="comment"
							item={client}
							validationState={validationState}
							onChange={handleChange}
							label={getString("cliComment")}
						/>
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={3}>
					<Grid item xs={1}>
						<BoolInput<Client>
							property="status"
							item={client}
							onChange={handleChange}
							falsyValue={Status.Inactive}
							thrutyValue={Status.Active}
							label={getString("cliStatus")}
						/>
					</Grid>
				</Grid>
			</Grid>
			{auth.status === "successful" && auth.user.roleCode === "ADMIN" && (<FabSave fetchState={fetchState} onClick={save} />)}
			<LeavePrompt shouldShow={unsavedChanges} />
		</>
	);
};
