import { TradeList, GetTradesParams, TradeFilter } from "../types";
import { User } from "../../../auth/types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getTradesWebApi = (
  user: User,
  page?: number,
  itemsPerPage?: number,
  filter?: TradeFilter,
) => {

  const params: GetTradesParams = {
    userid: user.userId,
    numofrows: itemsPerPage,
    fromrow: page && itemsPerPage ? page * itemsPerPage : undefined,
    status: filter?.tradeStatus?.ftValue,
    datefrom: getParamsDateString(filter?.datefrom),
    dateto: getParamsDateString(filter?.dateto),
    prodid: filter?.product?.prodId,
    delivstatus: filter?.delivstatus?.ftValue,
    cliid: user.clientId,
    market: filter?.market,
    clientid: user.roleCode !== "MEMTRADER" &&  user.roleCode !== "MEMMANAGER" &&  user.roleCode !== "MEMCONTACT"? filter?.client?.cliId : "",
    client2id: user.roleCode === "MEMTRADER" ||  user.roleCode === "MEMMANAGER" ||  user.roleCode === "MEMCONTACT"? filter?.client?.cliId : "",
  };

  return webApiWithResponse<TradeList[]>({
    method: "GET",
    url: "trade/gettradelist",
    params,
  });
};

export default getTradesWebApi;
