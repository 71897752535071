import { ThunkType } from "../../../../../core/store/reducers";
import { setCOPaymentsData } from "../clientOverviewDataSlice";
import getPayments from "../api/getPayments";
import { PageDataStatus } from "../../../../models";
import { TableType } from "../types";

export const loadAllPayments = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	const { pageNumber, itemsPerPage } = store.ux.reports.exchbusiness.clientOverview.index[
		TableType.Payments
	];
	const { filter } = store.ux.reports.exchbusiness.clientOverview;
	const user = store.data.auth.current.user;

	const paymentsResponse = await dispatch(getPayments(user, pageNumber, itemsPerPage, filter));

	if (!paymentsResponse || paymentsResponse.resCode !== "OK") {
		return;
	}

	const payments = {
		currentPageData: paymentsResponse.obj,
		totalCount: Number(paymentsResponse.resDesc) || 0,
		status: PageDataStatus.DataCurrent,
	};

	dispatch(setCOPaymentsData(payments));
};
