import { ProductList } from "../../listing/products/types";
import { ClientListData } from "../../listing/clients/types";
import { TradeStatus } from "../../models";
import { OrderMarkt, OrderAttribute } from "../orders/types";
import { DeliveryStatuses } from "../../../data/models";

export interface TradeList {
	tradeId: string;
	tradeNo: string; // Broj zaključnice
	tradeTime: Date; // datum/vreme prometa
	tranIdSell: string; //
	tranNoSell: string;
	orderNoSell: string;
	clientIdNoSell: string;
	clientNameSell: string;
	tranIdBuy: string; //
	tranNoBuy: string;
	orderNoBuy: string;
	clientIdNoBuy: string;
	clientNameBuy: string;
	prodID: string;
	prodCode: string;
	prodName: string;
	qty: number;
	unit: number;
	unitDescr: string;
	price: number;
	vatPerc: number;
	priceWithVat: number;
	amt: number; // ovo nadole treba obrisati
	amtWithVat: number;
	status: number;
	statusDescr: string;
	market: OrderMarkt;
	fulfillmentDate: Date;
	startDeliveryDate: Date;
	transCostSell: number; //	trosak transporta prodavca
	storCostSell: number; //	trosak skladistenja prodavca
	commPercSell: number; //	% provizije prodavca
	commAmtSell: number; //	    iznos provizije prodavca
	transCostBuy: number; //	isto za kupca
	storCostBuy: number; 
	commPercBuy: number; 
	commAmtBuy: number; 
	deliveryWhsId: string;
	deliveryWhsName: string;
	payTypeId: string;
	payTypeDescr: string;
	userIsEmployee?: number; 
	tradeLogList?: TradeLogData[];
	orderAttrList?: OrderAttribute[];
	orderAttributes?:string;
	districtName ?:string;
	quality?:string;
	//  totNoOfRows: number;
}
export interface GetTradesParams {
	userid: string;
	prodid?: string;
	status?: number;
	datefrom?: string;
	dateto?: string;
	fromrow?: number;
	numofrows?: number;
	cliid?: string;
	delivstatus?: number;
	market?: OrderMarkt;
	allTrades?: string;
	clientid?: string;
	client2id?:string;
}


export interface TradeFilter {
	product?: ProductList;
	tradeStatus?: TradeStatus;
	datefrom?: string;
	dateto?: string;
	delivstatus?: DeliveryStatuses;
	market?: OrderMarkt;
	client?: ClientListData;
}

export enum MarketFilter {
	Forward = "F",
	Spot = "S",
}

export interface TradeSimpleFilter {
	prodId?: string;
	market?: MarketFilter;
}

export interface TradesSimpleData {
	ordData: string;
	ordId: string;
}

export interface TradesSimpleDataTrade {
	tradeData: string;
	tradeId: string;
}

export interface TradesSimpleListData {
	tradeId: string;
	tradeData: string;
	seller: string;
	sellerId: string;
	buyer: string;
	buyerId: string;
	product: string;
	productId: string;
	price:number;
	unit: string;
	unitDescr: string;
}

export interface TradeLogData {
	changeTime?: Date; 
	userName?: string; 
	activity?: string; 
	status?: string; 
}
