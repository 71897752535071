import React from "react";
import { useDisplayRole } from "../../../data/listing/roles/hooks/useDisplayRole";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { Role } from "../../../data/listing/roles/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayEmployeeMainAttributes } from "./DisplayRoleMainAttributes";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid } from "@material-ui/core";

export interface DisplayEmployeeProps {
	id?: string;
}

export const DisplayEmployee: React.FunctionComponent<DisplayEmployeeProps> = ({ id }) => {
	const role = useDisplayRole(id);
	const { getString } = React.useContext(LocalizationContext);

	return !role ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel expanded>
				<ExpansionPanelSummary>
					<Typography>{getString("tableBasciInfo")}</Typography>
				</ExpansionPanelSummary>
				<DisplayEmployeeMainAttributes role={role} />
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewRole = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(role: Role) => showWithDrawer(<DisplayEmployee id={role.roleId} />),
		[showWithDrawer]
	);
};
