import React from "react";
import { useDispatch } from "react-redux";
import { ClientBroker } from "../../types";
import { getClientBroker } from "../../thunks/clientBroker/loadClientBroker";

export const useClientBroker = (clientBrokerId?: string) => {
	const displatch = useDispatch();
	const [broker, setBroker] = React.useState<ClientBroker | undefined>();

	React.useEffect(() => {
		const loadClient = async () => {
			if (clientBrokerId) {
				const loadedBroker = (await displatch(getClientBroker(clientBrokerId))) as any;
				setBroker(loadedBroker);
			}
		};
		loadClient();
	}, [displatch, clientBrokerId, setBroker]);

	return [broker];
};
