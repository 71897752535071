import { ThunkType } from "../../../../core/store/reducers";
import getCurrencyRateApi from "../api/getCurrencyRate";
import { setDisplayed } from "../currencyRatesDataReducer";
import { CurrencyRate } from "../types";

export const loadCurrencyRate = (currencyId: number): ThunkType => async (dispatch, getStore) => {
	dispatch(setDisplayed());

	const currency = await dispatch(getCurrencyRate(currencyId));

	dispatch(setDisplayed(currency));
};

export const getCurrencyRate = (currencyId: number): ThunkType<Promise<CurrencyRate | undefined>> => async (
	dispatch,
	getStore
) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getCurrencyRateApi(userId, currencyId));
};
