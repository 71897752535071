import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import VerifyIcon from '@material-ui/icons/EventAvailableOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import MofifyIcoon from '@material-ui/icons/EditOutlined';
import DeleteIcoon from '@material-ui/icons/DeleteOutlined';
import { ViewOrder } from "../../../data/trading/orders/types";
import { OrdersEditAction } from "../../../data/trading/orders/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewOrder } from "../orders/DisplayOrder";
import { useHistory } from "react-router-dom";
import { getOrderEditLocation, OrdersEditParts } from "../mainBoard/Locations";
import { useShowCancelOrder } from "../orders/CancelOrder";
import { DiplayOrderMod } from "../orders/DisplayOrderMainAttributes";
import { useAuth } from "../../../data/auth/hooks";
import { useDeleteOrder} from "../../../data/trading/orders/hooks/useOrderDel";
import { RowingOutlined } from "@material-ui/icons";
import { useShowDeleteOrder } from "../orders/DeleteOrder";

export function useOrderBookActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowOBOrder = useViewOrder(DiplayOrderMod.Full, true);
  const onModifyOrder = useEditOrder(OrdersEditAction.Modify);
  const onVerifyOrder = useEditOrder(OrdersEditAction.Verify);
  const onCancelOrder = useShowCancelOrder();
//  const onDeleteOrder = useDeleteOrderAction();
  const onDeleteOrder = useShowDeleteOrder();

  const [auth] = useAuth();

  return useMemo(() => {
    const result: MaterialTableProps<ViewOrder>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) => onShowOBOrder(rowData as ViewOrder),
      },
      row => ({
        icon: () => <VerifyIcon />,
        tooltip: row.statusN === 2  ? getString("orderConfirm") : getString("orderVerify"),
//        tooltip: auth.status === "successful" && auth.user.roleCode==="MEMTRADER"  ? getString("orderConfirm") : getString("orderVerify"),
        onClick: (event, rowData) => onVerifyOrder(rowData as ViewOrder),
        hidden: row.canVerify !== 1,
      }),
      row => ({
        icon: () => <CancelIcon />,
        tooltip: getString("orderCancel"),
        onClick: (event, rowData) => onCancelOrder(rowData as ViewOrder),
        hidden: row.canCancel !== 1,
      }),
      row => ({
        icon: () => <MofifyIcoon />,
        tooltip: getString("orderModify"),
        onClick: (event, rowData) => onModifyOrder(rowData as ViewOrder),
        hidden: row.canReplace !== 1,
      }),
      row => ({
        icon: () => <DeleteIcoon />,
        tooltip: getString("orderDelete"),
        onClick: (event, rowData) => onDeleteOrder(rowData as ViewOrder),
        hidden: row.canDelete !== 1,
      }),
    ];
    return result;
  }, [onShowOBOrder, onVerifyOrder, getString, onModifyOrder, onCancelOrder,onDeleteOrder]);
}

export const useReplyOrderBookOrder = () => {
  const history = useHistory();
  return React.useCallback((order: ViewOrder) => history.push(""), [history]);
};

export const useEditOrder = (action: OrdersEditAction) => {
  const history = useHistory();
	return React.useCallback(
		(order: ViewOrder) =>
			history.push(
				getOrderEditLocation(action, OrdersEditParts.BasicInfo, order.ordId)
			),
		[history, action]
	);
};

export const useDeleteOrderAction = () => {
  const [, deleteOrder] = useDeleteOrder();
  return React.useCallback(
    async (order: ViewOrder) => {
      if (order.ordId ) {
        await deleteOrder(order.ordId);
      }
    },
    [deleteOrder]
  );
};
