import { ThunkType } from "../../../../core/store/reducers";
import createCurrencyApi from "../api/createCurrency";
import updateCurrencyApi from "../api/updateCurrency";
import { setFetchState } from "../currenciesDataReducer";
import { Currency } from "../types";

export const upsertCurrency = (currency: Currency): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = currency.currId
		? await dispatch(updateCurrencyApi(userId, currency))
		: await dispatch(createCurrencyApi(userId, currency));

	if (result && result.resCode === "OK") {
		dispatch(setFetchState({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
	}
};
