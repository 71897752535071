import React from "react";
import { AccountOrderSide } from "../../types";
import { LocalizationContext } from "../../../../../core/localization/Localization";
import { exhaustiveCheck } from "../../../../../core/util/functions";

export const useClientAccountSideTranslator = () => {
	const { getString } = React.useContext(LocalizationContext);
	return React.useCallback(
		(side: AccountOrderSide) => {
			switch (side) {
				case AccountOrderSide.Both:
					return getString("accountSideBoth");
				case AccountOrderSide.Buy:
					return getString("accountSideBuy");
				case AccountOrderSide.Sell:
					return getString("accountSideSell");
				default:
					exhaustiveCheck(side);
			}
		},
		[getString]
	);
};

interface AccountOrderSideOption {
	label: string;
	value: AccountOrderSide;
}

export const useClientAccountSideOptions = (): AccountOrderSideOption[] => {
	const translator = useClientAccountSideTranslator();
	return React.useMemo(
		() => [
			{
				label: translator(AccountOrderSide.Both) as string,
				value: AccountOrderSide.Both,
			},
			{
				label: translator(AccountOrderSide.Buy) as string,
				value: AccountOrderSide.Buy,
			},
			{
				label: translator(AccountOrderSide.Sell) as string,
				value: AccountOrderSide.Sell,
			},
		],
		[translator]
	);
};
