import React from "react";
import {
  Grid,
  CardContent,
  CardHeader,
  Card,
  Divider,
} from "@material-ui/core";
import { TradeByBrokersReportTable } from "./TradeByBrokersReportTable";
import { usePageStyles } from "../../../pageStyles";
import { PageComponent } from "../../../../core/navigation/types";
import { PageTitle } from "../../../components/common/PageTitle";
import { TradeByBrokersReportFilter } from "./TradeByBrokersReportFilter";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";

const TradeByBrokersReport: PageComponent = ({ userAccessRights }) => {
  const pageStyles = usePageStyles();

  return (
    <>
      <Grid container className={pageStyles.root}>
        <Grid item xs={12} className={pageStyles.gridItem}>
          <Card>
            <CardHeader
              title={<PageTitle title="exchTurnoverByBrokers" />}
              action={<TradeByBrokersReportActions />}
            />
            <Divider />
            <CardContent>
              <TradeByBrokersReportTable userAccessRights={userAccessRights} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export const TradeByBrokersReportActions: React.FunctionComponent = () => (
  <Grid container>
    <Grid item>
      <TradeByBrokersReportFilter />
    </Grid>
    <Grid item>
      <DownloadButton />
    </Grid>
    <Grid item>
      <DownloadExcelButton />
    </Grid>
  </Grid>
);

export default TradeByBrokersReport;
