import { NavigationItem } from "../../../core/navigation/types";
import PayTypePage from "./PayTypePage";
import { EditPayTypePage } from "./EditPayTypePage";
import { payTypesLocation, editRouter } from "./Locations";
export const payTypeComponents: NavigationItem[] = [
	{
		Component: PayTypePage,
		isMenuItem: true,
		path: payTypesLocation,
	},
	{
		Component: EditPayTypePage,
		isMenuItem: false,
		path: editRouter,
	},
];
