import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { Country } from "../../listing/countries/types";

const getCountryLabel = (country?: Country) => (country ? country.cntName : "");

export const useCountries = () => {
	const topRegions = useSelector<StoreType, Country[]>(store => store.data.initialData.countries);

	return [topRegions, getCountryLabel] as [typeof topRegions, typeof getCountryLabel];
};
