import { useDispatch, useSelector } from "react-redux";
import { downloadCtrlGoodsExcel } from "../thunks/downloadReportExcel";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { CtrlGoodsData  } from "../types";

export const useCtrlGoodsExcelDownloader = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (s) =>
      s.data.clearing.controls.downloadReportFetchState
  );

  const data = useSelector<StoreType, CtrlGoodsData[] | undefined>(
    s => s.data.clearing.controls.currentPage?.currentPageData
  );
  
  const hasData = (data !== undefined && data !== null && data.length > 0) ;

  const dispatch = useDispatch();
  const download = React.useCallback(
    () => dispatch(downloadCtrlGoodsExcel()),
    [dispatch]
  );

  return [download, fetchState, hasData] as [typeof download, typeof fetchState, typeof hasData];
};
