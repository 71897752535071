import HistoricalPricesPage from "./HistoricalPricesPage";
import { NavigationItem } from "../../../../core/navigation/types";
import { historicalPricesReportLocation } from "./Locations";

export const historicalPricesReportComponents: NavigationItem[] = [
  {
    Component: HistoricalPricesPage,
    isMenuItem: true,
    path: historicalPricesReportLocation,
  },
];
