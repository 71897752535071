import React from "react";
import {
	useOrderValidator,
	maxExpiryDate,
	useOrderWarehouseRequired,
} from "../../../data/trading/orders/hooks/useOrderValidator";
import { useOrderUpsert } from "../../../data/trading/orders/hooks/useOrderUpsert";
import { EditOrder, OrderAttribute, FundsCheckStatus } from "../../../data/trading/orders/types";
import { useHistory } from "react-router-dom";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Grid, InputAdornment, Typography } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { OrderRecieveMethodSelect } from "../../components/common/OrderRecieveMethodSelect";
import { BoolInput } from "../../components/common/BoolInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { useAuth } from "../../../data/auth/hooks";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { ProductSelect } from "../../components/listing/ProductSelect";
import { ProductSelectData, ProductSelectAttribute } from "../../../data/listing/products/types";
import { OrderSideSelect } from "./OrderSideSelect";
import { NumberInput } from "../../components/common/NumberInput";
import { FrancoSelect } from "../../components/common/FrancoSelect";
import { GuaranteesTypeSelect } from "../../components/common/GuaranteesSelect";
import { DateInput } from "../../components/common/DateInput";
import { CheckPointSelect } from "../../components/common/CheckPointSelect";
import { CheckPointPayerSelect } from "../../components/common/CheckPointPayerSelect";
import { OrdersEditAction } from "../../../data/trading/orders/types";
import { orderBookLocation } from "../orderBook/Locations";
import { mainBoardLocation } from "../mainBoard/Locations";
import { mainBoardMLocation } from "../mainpanelm/Locations";
import { EditAttributes } from "./attributes/EditAttributes";
import { CountriesSelect } from "../../components/common/CountriesSelect";
import { TopRegionSelect } from "../../components/common/TopRegionSelect";
import { RegionSelect } from "../../components/common/RegionSelect";
import { CitySelect } from "../../components/common/CitySelect";
import { WhsSelect } from "../../components/listing/WarehouseSelect";
import { OrderMarketSelect } from "./OrderMarketSelect";
// import { OrderSide, Franco } from "../../../data/models";
import { OrderNoteSelect } from "../../components/common/OrderNoteSelect";
import { CustomDialog } from "../../components/common/CustomDialog";
import { OrderSide, Status } from "../../../data/models";
import { Pay_TypeSelect } from "../../components/common/Pay_TypeSelect";
import { ProducAttributeHasStandard } from "../../../data/listing/products/types";
import { AttributeManditory } from "../../../data/listing/attributes/types";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


export interface EditOrderBasicInfoPageProps {
	order: EditOrder;
	editOrder: (field: keyof EditOrder, value: any) => void;
	changed: boolean;
	action: OrdersEditAction;
}

export type FundCheckState =
	| { status: "INIT"; message?: string }
	| { status: "SHOWMESSAGE"; message: string }
	| { status: "RESUBMITED"; message?: string }
	| { status: "CONFIRMED"; message?: string };

export type ActiveCheckState =
	| { status: "INIT"; message?: string }
	| { status: "SHOWMESSAGE"; message: string }
	| { status: "CONFIRMED"; message?: string };

export const EditOrderBasicInfoPage: React.FunctionComponent<EditOrderBasicInfoPageProps> = props => {
	const { getString } = React.useContext(LocalizationContext);

	const { action, order, editOrder, changed } = props;
	const [auth] = useAuth();
	const [validationState, validate] = useOrderValidator();
	const [fetchState, upsert] = useOrderUpsert(action);
	const [selectedProduct, setSelectedProduct] = React.useState<ProductSelectData | null>(null);

	const history = useHistory();
	const [isWhsRequired] = useOrderWarehouseRequired();
	const requiredWhs = isWhsRequired(order);
	const [fundCheckState, setFundCheckState] = React.useState<FundCheckState>({ status: "INIT" });
	const [activeCheckState, setActiveCheckState] = React.useState<ActiveCheckState>({ status: "INIT" });

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editOrder(event.target.name as any, event.target.value);
		},
		[editOrder]
	);

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	const backLocation = action === OrdersEditAction.Replay ? (screenExtraSmall===true ? mainBoardMLocation : mainBoardLocation) : orderBookLocation;
	const statusProducts = Status.Active

console.log(backLocation)

	const saveOrder = React.useCallback(
		async (order: EditOrder) => {
			if (order && order.ordStatus === 2) {
				order.fundsCheckStatus = FundsCheckStatus.Confirmed
			}
			const normalized = validate(order);
			if (normalized) {
				const result = await upsert(normalized);
				if (result.status === "CREATED") {
					editOrder("ordId", result.newId);
				} else if (result.status === "NOFUNDS") {
					setFundCheckState({
						status: "SHOWMESSAGE",
						message: result.message,
					});
				}
			}
		},
		[validate, editOrder, upsert]
	);

	const fabClick = React.useCallback(
		(order: EditOrder) => {
			if (fetchState.type === "successful") {
				history.push(backLocation);
			} else if (fetchState.type !== "started") {
				saveOrder(order);
			}
		},
		[fetchState, history, backLocation, saveOrder]
	);

	React.useEffect(() => {
		if (fundCheckState.status === "CONFIRMED") {
			setFundCheckState(s => ({ status: "RESUBMITED", message: s.message }));
			order.fundsCheckStatus = FundsCheckStatus.Ignore;
			fabClick(order);
		}
	}, [fabClick, fundCheckState, order]);

	if (!order || auth.status !== "successful") {
		return null;
	}


	return (
		<>
			<Grid container direction="row" spacing={2}>
				<Grid
					item
					container
					direction="column"
					justify="center"
					alignItems="center"
					spacing={2}
					xs={12} sm={8}
				>
					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={6}>
							<OrderSideSelect
								handleChange={handleChange}
								side={order.side}
								disabled={action === OrdersEditAction.Modify || action === OrdersEditAction.Verify}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<OrderMarketSelect
								handleChange={handleChange}
								market={order.market}
								disabled={action === OrdersEditAction.Verify}
							/>
						</Grid>
					</Grid>
					{auth.user.userIsEmployee === 1 && (
						<Grid
							container
							item
							direction="row"
							justify="flex-start"
							alignItems="center"
							spacing={2}
						>
							<Grid item xs={12} sm={6}>
								<OrderRecieveMethodSelect
									label={getString("orderRecieveMethod")}
									predicate={unit => unit.ftValue === order.receiveMethod}
									onChangeItem={unit => editOrder("receiveMethod", unit?.ftValue)}
									required={validationState.receiveMethod !== undefined}
									error={
										validationState.receiveMethod !== undefined &&
										!validationState.receiveMethod
									}
									disabled={action === OrdersEditAction.Verify}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<ClientSelect
									label={getString("orderClientName")}
									predicate={client => client.cliId === order.clientId}
									onChangeItem={client => {
										editOrder("clientId", client ? client.cliId : undefined)
										editOrder("active", client ? client.active : undefined)
										setActiveCheckState(client && client.active === 0 ? { status: "SHOWMESSAGE", message: "neaktivan", } : { status: "INIT", message: "", })
									}}
									required={validationState.clientId !== undefined}
									error={
										validationState.clientId !== undefined && !validationState.clientId
										//										validationState.clientId !== undefined && !validationState.clientId && !validationState.active
									}
									disabled={action === OrdersEditAction.Modify || action === OrdersEditAction.Verify}
								/>
							</Grid>
						</Grid>
					)}

					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={6}>
							<ProductSelect
								label={getString("orderProduct")}
								status={statusProducts}
								predicate={prod => prod.prodId === order.prodId}
								onChangeItem={prod => {
									console.log(prod)
									editOrder("prodId", prod ? prod.prodId : undefined);
									editOrder(
										"orderAttrList",
										prod
											? createOrderAttributes(prod.prodAttrList, order.ordId)
											: undefined
									);
									setSelectedProduct(prod);
								}}
								required={validationState.prodId !== undefined}
								error={validationState.prodId !== undefined && !validationState.prodId}
								disabled={action === OrdersEditAction.Modify || action === OrdersEditAction.Verify}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<NumberInput<EditOrder>
								property="price"
								item={order}
								validationState={validationState}
								onChange={handleChange}
								label={getString("orderPriceWithoutVat")}
								money
								disabled={action === OrdersEditAction.Verify}
							/>
						</Grid>
					</Grid>
					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={3}>
							<NumberInput<EditOrder>
								property="qty"
								item={order}
								validationState={validationState}
								onChange={handleChange}
								label={getString("orderQty")}
								InputProps={{
									endAdornment: selectedProduct ? (
										<InputAdornment position="end">{selectedProduct.unit}</InputAdornment>
									) : undefined,
								}}
								disabled={action === OrdersEditAction.Verify}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<NumberInput<EditOrder>
								property="minQty"
								item={order}
								validationState={validationState}
								onChange={handleChange}
								label={getString("orderMinQty")}
								InputProps={{
									endAdornment: selectedProduct ? (
										<InputAdornment position="end">{selectedProduct.unit}</InputAdornment>
									) : undefined,
								}}
								disabled={action === OrdersEditAction.Verify}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Pay_TypeSelect
								label={getString("orderPayType")}
								predicate={pay_type => pay_type.payTypeId === order.payTypeId}
								onChangeItem={pay_type => {
									editOrder("payTypeId", pay_type ? pay_type.payTypeId : undefined);
									editOrder("paymentDays", pay_type && pay_type.payTypeId === "00000001" ? 0 : undefined);
								}}
								required={validationState.payTypeId !== undefined}
								error={validationState.payTypeId !== undefined && !validationState.payTypeId}
								disabled={action === OrdersEditAction.Verify}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<NumberInput<EditOrder>
								property="paymentDays"
								item={order}
								validationState={validationState}
								onChange={handleChange}
								label={getString("orderPayDays")}
								required={order.payTypeId === "00000001"}
								error={!(validationState.paymentDays === undefined || validationState.paymentDays)}
								disabled={action === OrdersEditAction.Verify || order.payTypeId !== "00000001"}
							/>
						</Grid>
					</Grid>
					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={6}>
							<BoolInput<EditOrder>
								property="allOrNothing"
								item={order}
								onChange={handleChange}
								falsyValue={0}
								thrutyValue={1}
								label={getString("orderAllOrNothing")}
								disabled={action === OrdersEditAction.Verify}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<DateInput
								label={getString("orderExpiryDate")}
								value={order.expiryDate || null}
								onChange={date => editOrder("expiryDate", date)}
								required={validationState.expiryDate !== undefined}
								error={
									validationState.expiryDate !== undefined && !validationState.expiryDate
								}
								maxDate={maxExpiryDate}
								disablePast
								disabled={action === OrdersEditAction.Verify}
							/>
						</Grid>
					</Grid>
					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={6}>
							<DateInput
								label={getString("orderGoodsReadyFrom")}
								value={order.goodsReadyFrom || null}
								onChange={date => editOrder("goodsReadyFrom", date)}
								required={validationState.goodsReadyFrom !== undefined}
								error={
									validationState.goodsReadyFrom !== undefined &&
									!validationState.goodsReadyFrom
								}
								disabled={action === OrdersEditAction.Verify}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<DateInput
								label={getString("orderGoodsReadyTo")}
								value={order.goodsReadyTo || null}
								onChange={date => editOrder("goodsReadyTo", date)}
								required={validationState.goodsReadyTo !== undefined}
								error={
									validationState.goodsReadyTo !== undefined &&
									!validationState.goodsReadyTo
								}
								disabled={action === OrdersEditAction.Verify}
							/>
						</Grid>
					</Grid>
					{/* <Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={6}>
							<CheckPointSelect
								label={getString("orderCheckPoint")}
								predicate={checkPoint => checkPoint.ftValue === order.checkPoint}
								onChangeItem={checkPoint => {
									editOrder("checkPoint", checkPoint ? checkPoint.ftValue : undefined);
									// if (!checkPoint) {
									// 	editOrder("paysCheck", undefined);
									// } else if (order.paysCheck === undefined && checkPoint.ftValue > 0) {
									// 	editOrder("paysCheck", 1);
									// }
								}}
								required={validationState.checkPoint !== undefined}
								error={
									validationState.checkPoint !== undefined && !validationState.checkPoint
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<CheckPointPayerSelect
								label={getString("orderCheckPays")}
								predicate={paysCheck => paysCheck.ftValue === order.paysCheck}
								onChangeItem={paysCheck =>
									editOrder("paysCheck", paysCheck ? paysCheck.ftValue : undefined)
								}
								required={validationState.paysCheck !== undefined}
								error={validationState.paysCheck !== undefined && !validationState.paysCheck}
							/>
						</Grid>
					</Grid> */}
					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={6}>
							<FrancoSelect
								label={getString("orderFranco")}
								predicate={prod => prod.ftValue === order.franco}
								onChangeItem={franco =>
									editOrder("franco", franco ? franco.ftValue : undefined)
								}
								required={validationState.franco !== undefined}
								error={validationState.franco !== undefined && !validationState.franco}
								disabled={action === OrdersEditAction.Verify}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<BoolInput<EditOrder>
								property="exportReady"
								item={order}
								onChange={handleChange}
								falsyValue={0}
								thrutyValue={1}
								label={getString("orderexportReady")}
								//								disabled
								disabled={action === OrdersEditAction.Verify}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						item
						direction="row"
						justify="flex-start"
						alignItems="center"
						spacing={2}
					></Grid>
					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={6}>
							<Typography variant="h6">{`${getString("orderDeliveryLocation")}:`}</Typography>
						</Grid>
						<Grid item xs={6}>
							<CountriesSelect
								label={getString("orderLocCountry")}
								predicate={country => country.cntId === order.locCountryId?.trim()}
								onChangeItem={country => {
									editOrder("locCountryId", country ? country.cntId : undefined);
									editOrder("locRegionId", undefined);
									editOrder("locDistrictId", undefined);
									editOrder("locCityId", undefined);
									editOrder("locWarehouseId", undefined);
								}}
								required={requiredWhs}
								error={
									validationState.locCountryId !== undefined &&
									!validationState.locCountryId
								}
								disabled={action === OrdersEditAction.Verify}
							//								disabled={ action === OrdersEditAction.Verify || (auth.user.roleCode==='MEMTRADER' && action === OrdersEditAction.Replay) }  
							/>
						</Grid>
					</Grid>
					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={6}>
							<TopRegionSelect
								label={getString("orderLocRegion")}
								predicate={region => region.regId === order.locRegionId}
								onChangeItem={region => {
									editOrder("locRegionId", region ? region.regId : undefined);
									editOrder("locDistrictId", undefined);
									editOrder("locCityId", undefined);
									editOrder("locWarehouseId", undefined);
								}}
								dataFilter={region =>
									order.locCountryId !== undefined &&
									region.country === order.locCountryId?.trim()
								}
								required={requiredWhs}
								error={
									validationState.locRegionId !== undefined && !validationState.locRegionId
								}
								disabled={action === OrdersEditAction.Verify}
							//								disabled={ action === OrdersEditAction.Verify || (auth.user.roleCode==='MEMTRADER' && action === OrdersEditAction.Replay) }  
							/>
						</Grid>
						<Grid item xs={6}>
							<RegionSelect
								label={getString("orderLocDistrict")}
								predicate={dist => dist.regId === order.locDistrictId}
								onChangeItem={dist => {
									editOrder("locDistrictId", dist ? dist.regId : undefined);
									editOrder("locCityId", undefined);
									editOrder("locWarehouseId", undefined);
								}}
								dataFilter={dist =>
									order.locRegionId !== undefined && dist.parentRegId === order.locRegionId
								}
								required={requiredWhs}
								error={validationState.locCityId !== undefined && !validationState.locCityId}
								disabled={action === OrdersEditAction.Verify}
							//								disabled={ action === OrdersEditAction.Verify || (auth.user.roleCode==='MEMTRADER' && action === OrdersEditAction.Replay) }  
							/>
						</Grid>
					</Grid>
					{/* !(auth.user.roleCode==='MEMTRADER' && action === OrdersEditAction.Replay)  && (<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}> */}
					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						<Grid item xs={6}>
							<CitySelect
								label={getString("orderLocCity")}
								predicate={city => city.cityId === order.locCityId}
								onChangeItem={city => {
									editOrder("locCityId", city ? city.cityId : undefined);
									editOrder("locWarehouseId", undefined);
								}}
								dataFilter={city =>
									order.locDistrictId !== undefined && city.regionId === order.locDistrictId
								}
								required={requiredWhs}
								error={validationState.locCityId !== undefined && !validationState.locCityId}
								disabled={action === OrdersEditAction.Verify}
							/>
						</Grid>
						<Grid item xs={6}>
							<WhsSelect
								label={getString("orderLocWhs")}
								predicate={whs => whs.whsId === order.locWarehouseId}
								onChangeItem={whs => {
									editOrder("locWarehouseId", whs ? whs.whsId : undefined);
								}}
								dataFilter={whs =>
									order.locCityId !== undefined && whs.cityId === order.locCityId && whs.status===1 
								}
								required={requiredWhs}
								error={
									validationState.locWarehouseId !== undefined &&
									!validationState.locWarehouseId
								}
								disabled={action === OrdersEditAction.Verify}
							/>
						</Grid>
					</Grid>

					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
						{/*<Grid item xs={6}>
							<CheckPointSelect
								label={getString("orderCheckPoint")}
								predicate={checkPoint => checkPoint.ftValue === order.checkPoint}
								onChangeItem={checkPoint => {
									editOrder("checkPoint", checkPoint ? checkPoint.ftValue : undefined);
									// if (!checkPoint) {
									// 	editOrder("paysCheck", undefined);
									// } else if (order.paysCheck === undefined && checkPoint.ftValue > 0) {
									// 	editOrder("paysCheck", 1);
									// }
								}}
								required={validationState.checkPoint !== undefined}
								error={
									validationState.checkPoint !== undefined && !validationState.checkPoint
								}
							/>
							</Grid>  */}
						{/*<Grid item xs={6}>
							<CheckPointPayerSelect
								label={getString("orderCheckPays")}
								predicate={paysCheck => paysCheck.ftValue === order.paysCheck}
								onChangeItem={paysCheck =>
									editOrder("paysCheck", paysCheck ? paysCheck.ftValue : undefined)
								}
								required={validationState.paysCheck !== undefined}
								error={validationState.paysCheck !== undefined && !validationState.paysCheck}
							/>
							</Grid> */}
					</Grid> 

					<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={2}>
					<Grid item xs={12} sm={6}>
							<GuaranteesTypeSelect
								label={getString("orderBankGuarantee")}
								predicate={prod => prod.ftValue === order.bankGuarantee}
								onChangeItem={bankGuarantee =>
									editOrder("bankGuarantee", bankGuarantee ? bankGuarantee.ftValue : undefined)
								}
//								required={validationState.franco !== undefined}
//								error={validationState.franco !== undefined && !validationState.franco}
								disabled={action === OrdersEditAction.Modify || action === OrdersEditAction.Verify}
							/>
						</Grid>

{/*						<Grid item xs={12} sm={6}>
							<BoolInput<EditOrder>
								property="bankGuarantee"
								item={order}
								onChange={handleChange}
								falsyValue={0}
								thrutyValue={1}
								label={getString("orderBankGuarantee")}
								disabled={action === OrdersEditAction.Verify}
							/>
							</Grid>*/}

						<Grid item xs={12} sm={6}>
							<OrderNoteSelect
								label={getString("orderComment")}
								predicate={note => note.ftDescr === order.comment}
								onChangeItem={note => editOrder("comment", note?.ftDescr)}
								required={validationState.comment !== undefined}
								error={validationState.comment !== undefined && !validationState.comment}
								disabled
							//								disabled={ action === OrdersEditAction.Verify }
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="flex-start"
					alignItems="center"
					spacing={4}
					xs={12} sm={4}
				>
					<EditAttributes
						attributes={order.orderAttrList}
						onChange={list => editOrder("orderAttrList", list)}
						error={validationState.orderAttrList === false}
						//						allManditory={order.side === OrderSide.Sell}
						allManditory={false}
						action={action}
					/>
				</Grid>
			</Grid>
			<FabSave fetchState={fetchState} onClick={() => fabClick(order)} />
			<LeavePrompt shouldShow={unsavedChanges} />
			<CustomDialog
				open={fundCheckState.status === "SHOWMESSAGE"}
				title={""}
				message={fundCheckState.message || "ERROR"}
				closeLabel={getString("orderFundCheckButton")}
				onClose={close => {
					setFundCheckState(s => ({ status: "CONFIRMED", message: s.message }));
				}}
				disableBackdropClick
			/>
			<CustomDialog
				open={activeCheckState.status === "SHOWMESSAGE"}
				title={""}
				message={getString("orderActive") || "ERROR"}
				closeLabel={getString("orderFundCheckButton")}
				onClose={close => {
					setActiveCheckState(s => ({ status: "CONFIRMED", message: s.message }));
					editOrder("clientId", undefined)
				}}
				disableBackdropClick
			/>
		</>
	);
};


const createOrderAttributes = (attributes: ProductSelectAttribute[], ordId?: string): OrderAttribute[] =>
		attributes?.map(att => ({
		ordId,
		attrId: att.attrId,
		attrName: att.attrName,
		comparator: att.comparator,
		manditory: att.mandatory,
		type: att.type,
		hasStandard: att.hasStandard,
		standardValue: att.standardValue,
		attrValue: att.hasStandard ? att.standardValue : undefined,  // ovo ubačeno da odmah upiše vrednost kad se odabere proizvod
	}));


	
