import React from "react";
import {
  Grid,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Fab,
} from "@material-ui/core";
import GuaranteesTable from "./GuaranteesTable";
import AddIcon from "@material-ui/icons/Add";
import { usePageStyles } from "../../pageStyles";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { useGuaranteeData } from "../../../data/clearing/guarantees/hooks/useGuaranteeData";
import { useGuaranteeIndex } from "../../../data/clearing/guarantees/hooks/useGuaranteeIndex";
import { useHistory } from "react-router-dom";
import { getGuaranteeEditLocation } from "../guarantees/Locations";
import { PageDataStatus } from "../../../data/models";
import { GuaranteeFilterButton } from "./GuaranteeFilter";
import { useAuth } from "../../../data/auth/hooks";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";

const GuaranteesPage: PageComponent = ({ userAccessRights }) => {
  const pageStyles = usePageStyles();

  const [pageData, dispatchLoadGuarantee, invalidate] = useGuaranteeData();
  const [pageIndex, dispatchSetIndex] = useGuaranteeIndex();

  React.useEffect(() => {
    if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
      dispatchLoadGuarantee();
    }
  }, [dispatchLoadGuarantee, pageData]);
  React.useEffect(() => () => { invalidate() }, [])

  const history = useHistory();

  const [auth] = useAuth();

  if (auth.status !== "successful") {
    return null;
  }

  return (
    <>
      <Grid container className={pageStyles.root}>
        <Grid item xs={12} className={pageStyles.gridItem}>
          <Card>
            <CardHeader
              title={<PageTitle title="guarGuaranteeTitle" />}
              action={<GuaranteesActions />}
            />
            <Divider />
            <CardContent>
              <GuaranteesTable
                pageIndex={pageIndex}
                pageData={pageData}
                onPageIndexChange={dispatchSetIndex}
                userAccessRights={userAccessRights}
                getData={dispatchLoadGuarantee}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {userAccessRights === AccessRights.All && (
          <Fab
            color="secondary"
            aria-label="add"
            className={pageStyles.mainFab}
            onClick={() => history.push(getGuaranteeEditLocation())}
          >
            <AddIcon />
          </Fab>
        )}
    </>
  );
};

export const GuaranteesActions: React.FunctionComponent = () => (
  <Grid container>
    <Grid item>
      <DownloadButton />
    </Grid>
    <Grid item>
      <DownloadExcelButton />
    </Grid>
    <Grid item>
      <GuaranteeFilterButton />
    </Grid>
  </Grid>
);

export default GuaranteesPage;
