import React from "react";
import { useDisplayCountry } from "./useDisplayCountry";
import { Country } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";

export const defaultCountry: Country = {
	cntName: "",
	cntNameen: "",
};

export const useEditCountry = (cntId?: string) => {
	const displayCountry = useDisplayCountry(cntId);

	return React.useMemo(() => {
		if (cntId !== undefined && displayCountry === undefined) {
			return undefined;
        }

		if (cntId === undefined) {
			return { ...defaultCountry };
        }

		return displayCountry ? { ...displayCountry } : undefined;
	}, [cntId, displayCountry]);
};

export const useEditCountryState = (cntId?: string) => {
	const country = useEditCountry(cntId);
	return useCopiedState(country)
};
