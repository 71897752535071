import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { BankGuaranteeData } from "../../../data/clearing/bankguarantees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewBankGuarantee } from "./DisplayBankGuarantee";
import { useHistory } from "react-router-dom";
import { getBankGuaranteeEditLocation } from "../bankguarantees/Locations";
import { useBankGuaranteeDelete } from "../../../data/clearing/bankguarantees/hooks/useBankGuaranteeDelete";

export function useBankGuaranteesActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowBankGuarantee = useViewBankGuarantee();
  const onEditBankGuarantee = useEditBankGuaranteeAction();

  return useMemo(() => {
    const result: MaterialTableProps<BankGuaranteeData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("grntActView"),
        onClick: (event, rowData) =>
        onShowBankGuarantee(rowData as BankGuaranteeData),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) =>
        onEditBankGuarantee(rowData as BankGuaranteeData),
      },
    ];
    return result;
  }, [onShowBankGuarantee, onEditBankGuarantee, getString]);
}

export const useDeleteBankGuaranteeAction = () => {
  const [, deleteBankGuarantee] = useBankGuaranteeDelete();
  return React.useCallback(
    async (bankguarantees: BankGuaranteeData) => {
      if (bankguarantees.grntId) {
        await deleteBankGuarantee(bankguarantees.grntId);
      }
    },
    [deleteBankGuarantee]
  );
};

export const useEditBankGuaranteeAction = () => {
  const history = useHistory();
  return React.useCallback(
    (bankguarantee: BankGuaranteeData) =>
      history.push(getBankGuaranteeEditLocation(bankguarantee.grntId)),
    [history]
  );
};
