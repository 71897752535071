import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Employee } from "../types";
import { loadEmployee } from "../thunks/loadEmployee";

export const useDisplayEmployee = (empId?: string) => {
	const storeEmploye = useSelector<StoreType, Employee | undefined>(
		store => store.data.listing.employees.displayedEmployee
    );

    const displayedEmployee = storeEmploye && storeEmploye.empId === empId ? storeEmploye : undefined;

    const displatch = useDispatch();

	React.useEffect(() => {
		if (empId) {
			displatch(loadEmployee(empId));
		}
    }, [displatch, empId]);

	return displayedEmployee;
};
