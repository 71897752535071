import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Employee } from "../types";

export const updateEmployee = (userId: string, employee: Employee) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/updemployee",
		params: {
			userId
		},
		data: employee
	});

export default updateEmployee;
