import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertNews } from "../thunks/upsertNews";
import { setFetchState } from "../newsDataReducer";
import { NewsData } from "../types";

export const useNewsUpsert = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.reports.news.fetchState
  );

  const dispatch = useDispatch();
  const dispatchUpsertNews = React.useCallback(
    (news: NewsData) => dispatch(upsertNews(news)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchUpsertNews] as [
    typeof fetchState,
    typeof dispatchUpsertNews
  ];
};
