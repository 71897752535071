import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertDispute } from "../thunks/upsertDispute";
import { setFetchState } from "../disputeDataSlice";
import { DisputeData } from "../types";

export const useDisputeUpsert = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.clearing.disputes.fetchState
  );

  const dispatch = useDispatch();
  const dispatchUpsertDispute = React.useCallback(
    (disputes: DisputeData) => dispatch(upsertDispute(disputes)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchUpsertDispute] as [
    typeof fetchState,
    typeof dispatchUpsertDispute
  ];
};
