import { webApi } from "../../../../core/http/httpThunks";
import { Attribute } from "../types";

export const getAllAttributesApi = (userId: string) =>
	webApi<Attribute[]>({
		method: "GET",
		url: "listing/getattrlist",
		params: {
			userId,
		},
	});

export default getAllAttributesApi;
