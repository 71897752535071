import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { CtrlGoodsData } from "../types";
import { loadCtrlGood } from "../thunks/loadCtrlGood";

export const useDisplayCtrlGood = (controlId?: string) => {
  const storeCtrlGoods = useSelector<StoreType, CtrlGoodsData | undefined>(
    (store) => store.data.clearing.controls.displayedControl
  );

  const displayedctrlGood =
    storeCtrlGoods && storeCtrlGoods.controlId === controlId
      ? storeCtrlGoods
      : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (controlId) {
      displatch(loadCtrlGood(controlId));
    }
  }, [displatch, controlId]);

  return displayedctrlGood;
};
