import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { Franco } from "../../models";

const renderLabel = (item: Franco) => `${item.ftDescrLong} (${item.ftDescr})`

export const useFrancoList = () => {
	const francoList = useSelector<StoreType, Franco[]>(store => store.data.initialData.francoList);

	return [francoList, renderLabel] as [typeof francoList, typeof renderLabel];
};
