import { ThunkType } from "../../../../core/store/reducers";
import { getAnnualDateReport } from "../api/getAnnualDateReport";
import fileDownload from "js-file-download";
import { setFetchState } from "../annualTradeDataSlice";
import moment from "moment";

export const downloadReport = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.reports.annualTrade;
  const user = store.data.auth.current.user;
  dispatch(setFetchState({ type: "started" }));
  const annualReport = await dispatch(getAnnualDateReport(user, filter));
  if (annualReport) {
    fileDownload(
      new Blob([annualReport as any], { type: "application/pdf" }),
      "AnnualDTrading_" + moment().format("YYYYMMDDhhmmss") + ".pdf"
    );
  }
  dispatch(setFetchState({ type: "not-started" }));
};
