import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";

export const printDelivery = (user: User, dlvrid: string) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getdeliveryconfreport",
		params: {
			userid: user.userId,
			dlvrid,
		},
	});
};

export default printDelivery;
