import React from "react";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { useAttributes } from "../../../../data/trading/mainBoard/hooks/attributes/useAttributes";
import { OrderAttribute } from "../../../../data/trading/orders/types";
import {
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	TableBody,
	Typography,
	Grid,
} from "@material-ui/core";
import { useAttributeValues } from "../../../../data/initialData/hooks/useAttributeValues";

export interface DisplayAttributesProps {
	attributes?: OrderAttribute[];
}

export const DisplayAttributes: React.FunctionComponent<DisplayAttributesProps> = ({ attributes }) => {
	const style = useDisplaySingleEmementStyles();
	// const [attributes] = useAttributes(orderId);

	return (
		<div className={style.root}>
			<div className={style.row}>
				<AttributesDisplayTable attributes={attributes} />
			</div>
		</div>
	);
};

interface AttributesDisplayTableProps {
	attributes?: OrderAttribute[];
}

export const AttributesDisplayTable: React.FunctionComponent<AttributesDisplayTableProps> = ({
	attributes,
}) => {
	const { getString, getNumberString } = React.useContext(LocalizationContext);
	const [values, displayValue] = useAttributeValues();

	if (!attributes || attributes.length === 0) {
		return (
			<Grid container justify="center">
				<Typography>{getString("productAttributeEmpty")}</Typography>
			</Grid>
		);
	}

	return (
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell><b>{getString("orderAttrName")}</b></TableCell>
						<TableCell><b>{getString("orderAttrValue")}</b></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{attributes &&
						attributes.map(attribute => (
							<TableRow key={attribute.ordAttrId}>
								<TableCell>{attribute.attrName}</TableCell>
								<TableCell>
									{attribute.attrValue ? getNumberString(attribute.attrValue, "0,0.0") :
											displayValue(
												values && values.find(v => attribute.attrValId === v.attrValId)
											)}
								</TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
