import { useContext } from "react";
import { Localization } from "material-table";
import { LocalizationContext } from "../../../core/localization/Localization";

export const useMaterialTableLocalization = (): Localization => {
	const { getString } = useContext(LocalizationContext);
	return {
		toolbar: {
			searchPlaceholder: getString("search"),
			searchTooltip: getString("search"),
		},
		header: {
			actions: "",
		},
		pagination: {
			firstAriaLabel: getString("tableFirsPage"),
			firstTooltip: getString("tableFirsPage"),
			lastAriaLabel: getString("tableLastPage"),
			lastTooltip: getString("tableLastPage"),
			labelDisplayedRows: getString("tableDisplayedRows"),
			labelRowsSelect: getString("tabledDisplayedPerPage"),
			labelRowsPerPage: getString("tabledDisplayedPerPage"),
			nextAriaLabel: getString("tableNextPage"),
			nextTooltip: getString("tableNextPage"),
			previousAriaLabel: getString("tablePreviousPage"),
			previousTooltip: getString("tablePreviousPage"),
		},
		body: {
			emptyDataSourceMessage: "",
			addTooltip: getString("tableAdd"),
			editTooltip: getString("tableEdit"),
			deleteTooltip: getString("tableDelete"),
			editRow: {
				saveTooltip: getString("tableDelete"),
				cancelTooltip: getString("tableCancel"),
				deleteText: getString("tableConfirmationDelete"),
			},
		},
	};
};
