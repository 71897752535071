import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { UserListData } from "../../../data/listing/users/types";

export function useUserColumns() {
	const { getString } = useContext(LocalizationContext);
	return useMemo(
		() => [
			{
				title: getString("userUserName"),
				field: "userName",
			},
			{
				title: getString("userLoginUser"),
				field: "loginUser",
			},
			{
				title: getString("userRoleId"),
				field: "role",
			},
			{
				title: getString("userEmpId"),
				field: "emp",
			},
			{
				title: getString("userClientId"),
				field: "client",
			},
			{
				title: getString("userCliPrsId"),
				field: "cliPrs",
			},
		],
		[getString]
	) as Column<UserListData>[];
}
