import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Currency } from "../types";

export const createCurrencyApi = (userId: string, currency: Currency) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/newcurr",
		params: {
			userId
		},
		data: currency
	});

export default createCurrencyApi;
