import { QuarterlySecurityReportFilter } from "../types";
import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

export const getQuarterlySecurityExcel = (
  user: User,
  filter: QuarterlySecurityReportFilter
) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getsecquartertradingxls",
    params: {
      userid: user.userId,
      year: filter.year,
			qtnumber: filter.qtnumber,
    },
  });
};

export default getQuarterlySecurityExcel;
