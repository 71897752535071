import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { resetTradingDateIdices } from "../tradingUXSlice";
import { PageIndex } from "../../../models";
import { setTradingDataIndexThunk } from "../thunks/loadAllTradingData";

export const useTradingDataIndex = () => {
  const pageIndex = useSelector<StoreType, PageIndex>(
    (s) => s.ux.clearing.tradingData.index
  );

  const dispatch = useDispatch();
  const dispatchSetIndex = React.useCallback(
    (index: PageIndex) => dispatch(setTradingDataIndexThunk(index)),
    [dispatch]
  );
  const dispatchResetIndex = React.useCallback(
    () => dispatch(resetTradingDateIdices()),
    [dispatch]
  );

  return [pageIndex, dispatchSetIndex, dispatchResetIndex] as [
    typeof pageIndex,
    typeof dispatchSetIndex,
    typeof dispatchResetIndex
  ];
};
