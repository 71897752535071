import React from "react";
import { PaymentListData } from "../../../../../data/clearing/payments/types";
import { PaginationTable } from "../../../../components/common/PagginationTable";
import { usePaymentsColumns } from "./usePaymentsColumns";
import { useClientOverviewIndex } from "../../../../../data/reports/exchbusiness/clientOverview/hooks/useClientOverviewIndex";
import { usePaymentsData } from "../../../../../data/reports/exchbusiness/clientOverview/hooks/usePaymentsData";
import { TableType } from "../../../../../data/reports/exchbusiness/clientOverview/types";
import { PageDataStatus } from "../../../../../data/models";
import { usePaymentsActions } from "./usePaymentsActions";

const ClientOverviewPaymentsTable: React.FunctionComponent = () => {
	const columns = usePaymentsColumns();
	const [data, loadData] = usePaymentsData();
	const [index, setIndex] = useClientOverviewIndex(TableType.Payments);
	const actions = usePaymentsActions()

	React.useEffect(() => {
		if (data && data.status === PageDataStatus.DataInvalidated) {
			loadData();
		}
	}, [loadData, data]);

	return (
		<PaginationTable<PaymentListData>
			getData={loadData}
			pageData={data}
			onPageIndexChange={setIndex}
			pageIndex={index}
			title=""
			columns={columns}
			actions={actions}
			options={{
				actionsColumnIndex: -1,
				search: false,
				toolbar: false,
			}}
		/>
	);
};

export default ClientOverviewPaymentsTable;
