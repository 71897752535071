import { webApi } from "../../../../core/http/httpThunks";
import { Currency } from "../types";

export const getAllCurrenciesApi = (userId: string) =>
	webApi<Currency[]>({
		method: "GET",
		url: "listing/getcurrlist",
		params: {
			userId,
		},
	});

export default getAllCurrenciesApi;
