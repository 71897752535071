import { ThunkType } from "../../../../core/store/reducers";
import createUserApi from "../api/createUser";
import updateUserApi from "../api/updateUser";
import { setFetchState } from "../usersDataReducer";
import { UserData } from "../types";

export const upsertRole = (user: UserData): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;

	console.log(user)
	const result = user.userId
		? await dispatch(updateUserApi(userId, user))
		: await dispatch(createUserApi(userId, user));

	if (result && result.resCode === "OK") {
		dispatch(setFetchState({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
	}
};
