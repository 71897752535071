import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { MarginsDataFilter  } from "../types";
import { getParamsDateString } from "../../../../core/util/functions";

export const getMarginsReport = (user: User, filter: MarginsDataFilter) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getmarginsreport",
    params: {
      userid: user.userId,
      tradeid: filter.trade?.tradeId,
      cliid: filter.client?.cliId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      },
  });
};

export default getMarginsReport;
