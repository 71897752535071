import { webApi } from "../../../../../core/http/httpThunks";
import { ClientContractList } from "../../types";

export const getClientContractsApi = (userId: string, cliid: string) =>
	webApi<ClientContractList[]>({
		method: "GET",
		url: "listing/getclicontrlist",
		params: {
			userId,
			cliid
		},
	});

export default getClientContractsApi;
