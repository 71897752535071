import { ThunkType } from "../../../../core/store/reducers";
import getProductsFroSelectApi from "../api/getProductsFroSelect";
import { setProductsForSelect } from "../productsDataReducer";
import { Status } from "../../../models";

export const loadProductsForSelect = (status?: Status): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	const { userId } = store.data.auth.current.user;
	const products = await dispatch(getProductsFroSelectApi(userId, status));

	dispatch(setProductsForSelect(products || []));
};
