import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { Attribute, AttributeManditory } from "../../../data/listing/attributes/types";
import React from "react";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export function useAttributeColumns() {
	const { getString } = useContext(LocalizationContext);
	return useMemo(
		() => [
			{
				title: getString("attributeName"),
				field: "attrName",
			},
			{
				title: getString("productAttributeType"),
				field: "attrType",
			},

/*			{
				title: getString("productAttributeManditory"),
				render: product => (
					<DisplayBoolNumber value={product.mandatory} thrutyValue={AttributeManditory.True} />
				),
				customSort: (a, b) => a.mandatory - b.mandatory,
				width: "80px",
			}, */
			{
				title: getString("productAttributeComparator"),
				field: "comparator",
			},
			{
				title: getString("productAttributeStatus"),
				render: product => <DisplayBoolNumber value={product.status} thrutyValue={Status.Active} />,
				customSort: (a, b) => a.status - b.status,
				width: "80px",
			},
		],
		[getString]
	) as Column<Attribute>[];
}
