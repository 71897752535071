import { ClientListData, Client, ClientContract } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, createFetchState, getFetchStateChange } from "../../models";

interface IClientData {
	all?: ClientListData[];
	searchTerm: string;
	displayData?: ClientListData[];
	displayedClient?: Client;
	fetchState: FetchStateType;
	fetchStatePerson: FetchStateType;
	fetchStateBroker: FetchStateType;
	fetchStateContract: FetchStateType;
	fetchStateAccount: FetchStateType;
	downloadContractFetchState: FetchStateType;
	deleteFetchState: FetchStateType;
}

const initialState: IClientData = {
	searchTerm: "",
	fetchState: createFetchState("not-started"),
	fetchStatePerson: createFetchState("not-started"),
	fetchStateBroker: createFetchState("not-started"),
	fetchStateContract: createFetchState("not-started"),
	fetchStateAccount: createFetchState("not-started"),
	downloadContractFetchState: createFetchState("not-started"),
	deleteFetchState: { type: "not-started" },
};

const name = "clients";

const clientsSlice = createSlice({
	name,
	initialState,
	reducers: {
		setClients: (state, action: PayloadAction<ClientListData[] | undefined>) => ({
			...state,
			all: action.payload,
			displayData: filterData(action.payload, state.searchTerm),
		}),
		setSearchTerm: (state, action: PayloadAction<string>) => ({
			...state,
			searchTerm: action.payload.toLocaleLowerCase(),
			displayData: filterData(state.all, action.payload),
		}),
		setDisplayedClient: (state, action: PayloadAction<Client | undefined>) => ({
			...state,
			displayedClient: action.payload,
		}),

		setFetchStateClient: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayedClient: action.payload.type === "successful" ? undefined : state.displayedClient,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			fetchState: getFetchStateChange(state.fetchState, action.payload),
		}),
		setFetchStatePerson: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			fetchStatePerson: getFetchStateChange(state.fetchStatePerson, action.payload),
		}),
		setFetchStateBroker: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			fetchStateBroker: getFetchStateChange(state.fetchStateBroker, action.payload),
		}),
		setFetchStateContract: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			fetchStateContract: getFetchStateChange(state.fetchStateContract, action.payload),
		}),
		setFetchStateAccount: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			fetchStateAccount: getFetchStateChange(state.fetchStateAccount, action.payload),
		}),
		setPrintContractFetchState: (
			state,
			action: PayloadAction<FetchStateType>
		  ) => ({
			...state,
			downloadContractFetchState: action.payload,
		  }),
		  setDeleteFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayed: action.payload.type === "successful" ? undefined : state.displayedClient,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			deleteFetchState: getFetchStateChange(state.deleteFetchState, action.payload),
		}),

	  },
});

const filterData = (data?: ClientListData[], searchTerm?: string) =>
	searchTerm === undefined || searchTerm === ""
		? data
		: data?.filter(
				client =>
					(client.cliName &&
						client.cliName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(client.cliIdNo &&
						client.cliIdNo.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(client.taxCode &&
						client.taxCode.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(client.city &&
						client.city.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(client.phone &&
						client.phone.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(client.broker &&
						client.broker.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
		  );

export const {
	setClients,
	setSearchTerm,
	setDisplayedClient,
	setFetchStateClient,
	setFetchStatePerson,
	setFetchStateBroker,
	setFetchStateContract,
	setFetchStateAccount,
	setPrintContractFetchState,
	setDeleteFetchState,
} = clientsSlice.actions;

export default clientsSlice.reducer;
