import React from "react";
import { DisputeListData } from "../../../data/clearing/dispute/types";
import {
  PaginationTable,
  PaginationDataProps,
} from "../../components/common/PagginationTable";
import {
  useDisputesActions,
  useDeleteDisputeAction,
} from "./useDisputesActions";
import { useDisputeColumns } from "./useDisputesColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface DisputeTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}

type DisputeTablePropsType = DisputeTableProps &
  PaginationDataProps<DisputeListData>;

const DisputeTable: React.FunctionComponent<DisputeTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = useDisputesActions();
  const columns = useDisputeColumns();
  const onDeleteDispute = useDeleteDisputeAction();

  return (
    <PaginationTable<DisputeListData>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
      editable={{
        onRowDelete: onDeleteDispute,
      }}
    />
  );
};

export default DisputeTable;
