import { ThunkType } from "../../../../core/store/reducers";
import { setDisputes, invalidateDispute } from "../disputeDataSlice";
import { setDisputeIndex } from "../disputeUXSlice";
import getDisputes from "../api/getDisputes";
import { PageIndex, PageDataStatus } from "../../../models";

export const loadDisputes = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const { pageNumber, itemsPerPage } = store.ux.clearing.disputes.index;
  const { filter } = store.ux.clearing.disputes;
  const user = store.data.auth.current.user;

  const disputesResponse = await dispatch(
    getDisputes(user, pageNumber, itemsPerPage, filter)
  );

  if (!disputesResponse || disputesResponse.resCode !== "OK") {
    return;
  }

  const disputes = {
    currentPageData: disputesResponse.obj,
    totalCount: Number(disputesResponse.resDesc) || 0,
    status: PageDataStatus.DataCurrent,
  };

  dispatch(setDisputes(disputes));
};

export const setDisputeIndexThunk = (index: PageIndex): ThunkType => (
  dispatch
) => {
  dispatch(setDisputeIndex(index));
  dispatch(invalidateDispute());
};
