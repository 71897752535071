import React from "react";
import { MarginsData } from "../../../data/clearing/marginsData/types";
import {
  PaginationTable,
  PaginationDataProps,
} from "../../components/common/PagginationTable";
import { useMarginsDataActions } from "./useMarginsDataActions";
import { useMarginsDataColumns } from "./useMarginsDataColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface MarginsDataTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}

type MarginsDataTablePropsType = MarginsDataTableProps &
  PaginationDataProps<MarginsData>;

const MarginsDataTable: React.FunctionComponent<MarginsDataTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = useMarginsDataActions();
  const columns = useMarginsDataColumns();
  
  return (
    <PaginationTable<MarginsData>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default MarginsDataTable;
