import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteCommission } from "../thunks/deleteCommission";
import { setFetchState } from "../commissionsDataReducer";

export const useCommissionDelete = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.products.fetchState
	);

	const dispatch = useDispatch();
	const dispatchDeleteProduct = React.useCallback((id: string) => dispatch(deleteCommission(id)), [dispatch]);

	React.useEffect(() => {
		dispatch(setFetchState({ type: "not-started" }));
	}, [dispatch]);

	return [fetchState, dispatchDeleteProduct] as [typeof fetchState, typeof dispatchDeleteProduct];
};
