import { webApi } from "../../../../core/http/httpThunks";
import { ProductGroupData } from "../types";

export const getProductGroupApi = (userId: string, prodgroupid: string) =>
	webApi<ProductGroupData>({
		method: "GET",
		url: "listing/getprodgroup",
		params: {
			userId,
			prodgroupid
		},
	});

export default getProductGroupApi;
