import { ThunkType } from "../../../../core/store/reducers";
import deleteWhsApi from "../api/deleteWhs";
import { setFetchState } from "../warehousesDataReducer";

export const deleteWhs = (whsId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = await dispatch(deleteWhsApi(userId, whsId));

  dispatch(setFetchState(result !== undefined ? { type: "successful" } : { type: "error"}));
};
