import { createSlice } from "@reduxjs/toolkit";
import { successfulLogin } from "../auth/authDataSlice";
import {
	City,
	ClientType,
	Bank,
	Currency,
	ProductAttribute,
	ProductGroup,
	VatRate,
	Unit,
	Commissions,
	Region,
	CommissionType,
	PersonRole,
	UserMenuItem,
	OrderRecievedMethod,
	CheckPoint,
	CheckPointPayer,
	Franco,
	OrderStatus,
	TradeStatus,
	PayTypes,
	CheckDoneByList,
	PayTypesG,
	AttributesValue,
	OrderNote,
	InvTypes,
	DispSitList,
	QualityCtrlList,
	DeliveryStatuses,
	Ports,
	Pay_Type,
	ContractType,
	Guarantees,
	ActStatuses,
} from "../models";

import { Country } from "../listing/countries/types";

interface IInitialDataState {
	cities: City[];
	clientTypes: ClientType[];
	banks: Bank[];
	currencies: Currency[];
	productAttributes: ProductAttribute[];
	productGroups: ProductGroup[];
	vatRates: VatRate[];
	units: Unit[];
	commissions: Commissions[];
	regions: Region[];
	subRegions: Region[];
	topRegions: Region[];
	countries: Country[];
	commisionTypes: CommissionType[];
	personRoles: PersonRole[];
	userMenu: UserMenuItem[];
	orderRecieveMethods: OrderRecievedMethod[];
	checkPointPayers: CheckPointPayer[];
	checkPoints: CheckPoint[];
	francoList: Franco[];
	orderStatuses: OrderStatus[];
	tradeStatuses: TradeStatus[];
	payTypes: PayTypes[];
	checkDoneByList: CheckDoneByList[];
	payTypesG: PayTypesG[];
	attributeValues: AttributesValue[];
	orderNotes: OrderNote[];
	invTypes: InvTypes[];
	dispSitList: DispSitList[];
	qualityCtrlList: QualityCtrlList[];
	deliveryStatuses: DeliveryStatuses[];
	ports: Ports[];
	pay_Types: Pay_Type[];
	contractTypes: ContractType[];
	guarantees: Guarantees[];
	actStatuses: ActStatuses[];
}

const initialState: IInitialDataState = {
	cities: [],
	clientTypes: [],
	banks: [],
	currencies: [],
	productAttributes: [],
	productGroups: [],
	vatRates: [],
	units: [],
	commissions: [],
	regions: [],
	subRegions: [],
	topRegions: [],
	countries: [],
	commisionTypes: [],
	personRoles: [],
	userMenu: [],
	orderRecieveMethods: [],
	checkPointPayers: [],
	checkPoints: [],
	francoList: [],
	orderStatuses: [],
	tradeStatuses: [],
	payTypes: [],
	checkDoneByList: [],
	payTypesG: [],
	attributeValues: [],
	orderNotes: [],
	invTypes: [],
	dispSitList: [],
	qualityCtrlList: [],
	deliveryStatuses: [],
	ports: [],
	pay_Types: [],
	contractTypes: [],
	guarantees: [],
	actStatuses: [],};

const name = "initialData";

const initialDataSlice = createSlice({
	name,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(successfulLogin, (state, action) => ({
			...state,
			cities: action.payload.cities,
			clientTypes: action.payload.clientTypes,
			banks: action.payload.banks,
			currencies: action.payload.currencies,
			productAttributes: action.payload.attributes,
			productGroups: action.payload.productsGroups,
			vatRates: action.payload.vatRates,
			units: action.payload.units,
			commissions: action.payload.commissions,
			regions: action.payload.regions,
			subRegions: action.payload.subRegions,
			topRegions: action.payload.topRegions,
			countries: action.payload.countries,
			commisionTypes: action.payload.commissionTypes,
			personRoles: action.payload.personRoles,
			userMenu: action.payload.userMenu,
			orderRecieveMethods: action.payload.orderReceiveMethods,
			checkPointPayers: action.payload.checkPointPayers,
			checkPoints: action.payload.checkPoints,
			francoList: action.payload.francoList,
			orderStatuses: action.payload.orderStatuses,
			tradeStatuses: action.payload.tradeStatuses,
			payTypes: action.payload.payTypes,
			checkDoneByList: action.payload.checkDoneByList,
			payTypesG: action.payload.payTypesG,
			attributeValues: action.payload.attributesValues,
			orderNotes: action.payload.orderNotes,
			invTypes: action.payload.invTypes,
			dispSitList: action.payload.dispSitList,
			qualityCtrlList: action.payload.qualityCtrlList,
			deliveryStatuses: action.payload.deliveryStatuses,
			ports: action.payload.ports,
			pay_Types: action.payload.pay_Types,
			contractTypes: action.payload.contractTypes,
			guarantees: action.payload.guarantees,
			actStatuses: action.payload.actStatuses,
		}));
	},
});

export default initialDataSlice.reducer;
