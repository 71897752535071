import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllProducts } from "../thunks/loadAllProducts";
import { ProductList } from "../types";

export const useProductData = () => {
	const data = useSelector<StoreType, ProductList[] | undefined>(s => s.data.listing.products.displayData);
	const allData = useSelector<StoreType, ProductList[] | undefined>(s => s.data.listing.products.all);

	const dispatch = useDispatch();
	const dispatchLoadAllProducts = React.useCallback(() => dispatch(loadAllProducts()), [dispatch]);

	return [data, dispatchLoadAllProducts, allData] as [
		typeof data,
		typeof dispatchLoadAllProducts,
		typeof allData
	];
};
