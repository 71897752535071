import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllBanks } from "../thunks/loadAllBanks";
import { Bank } from "../types";

export const useBankData = () => {
  const data = useSelector<StoreType, Bank[] | undefined>(
    (s) => s.data.listing.banks.displayData
  );
  const allData = useSelector<StoreType, Bank[] | undefined>(
    (s) => s.data.listing.banks.all
  );

  const dispatch = useDispatch();
  const dispatchLoadAllBanks = React.useCallback(
    () => dispatch(loadAllBanks()),
    [dispatch]
  );

  return [data, dispatchLoadAllBanks, allData] as [
    typeof data,
    typeof dispatchLoadAllBanks,
    typeof allData
  ];
};

export const useBank = (id?: string) => {
  const allData = useSelector<StoreType, Bank[] | undefined>(
    (s) => s.data.listing.banks.all
  );

  const dispatch = useDispatch();
  const dispatchLoadAllBanks = React.useCallback(
    () => dispatch(loadAllBanks()),
    [dispatch]
  );

  React.useEffect(() => {
    if (!allData && id) {
      dispatchLoadAllBanks();
    }
  }, [allData, id, dispatchLoadAllBanks]);

  const bank = React.useMemo(() => {
    if (!allData) {
      return undefined;
    } else {
      return allData?.find((e) => e.bankId === id);
    }
  }, [id, allData]);

  return [bank] as [typeof bank];
};
