import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { ProductGroupData } from "../types";

export const updateProductGroupApi = (userId: string, group: ProductGroupData) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/updprodgroup",
		params: {
			userId
		},
		data: group
	});

export default updateProductGroupApi;
