import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAlarms } from "../thunks/loadAlarms";
import { AlarmData } from "../types";
import { PageData } from "../../../models";

export const useAlarmData = () => {
  const pageData = useSelector<
    StoreType,
    PageData<AlarmData> | undefined
  >((s) => s.data.surveillance.alarms.currentPage);

  const dispatch = useDispatch();
  const dispatchLoadAlarmPage = React.useCallback(
    () => dispatch(loadAlarms()),
    [dispatch]
  );

  return [pageData, dispatchLoadAlarmPage] as [
    typeof pageData,
    typeof dispatchLoadAlarmPage
  ];
};
