import React from "react";
import { GuaranteeListData } from "../../../data/clearing/guarantees/types";
import {
  PaginationTable,
  PaginationDataProps,
} from "../../components/common/PagginationTable";
import {
  useGuaranteesActions,
  useDeleteGuaranteeAction,
} from "./useGuaranteesActions";
import { useGuaranteeColumns } from "./useGuaranteesColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface GuaranteeTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}

type GuaranteeTablePropsType = GuaranteeTableProps &
  PaginationDataProps<GuaranteeListData>;

const GuaranteesTable: React.FunctionComponent<GuaranteeTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = useGuaranteesActions();
  const columns = useGuaranteeColumns();
  const onDeleteGuarantee = useDeleteGuaranteeAction();

  return (
    <PaginationTable<GuaranteeListData>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
      editable={{
        onRowDelete: onDeleteGuarantee,
      }}
    />
  );
};

export default GuaranteesTable;
