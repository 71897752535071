import { ClientOverviewPage } from "./ClientOverviewPage";
import { NavigationItem } from "../../../../core/navigation/types";
import { clientsOverviewLocation } from "./Locations";

export const clientOverviewComponents: NavigationItem[] = [
	{
		Component: ClientOverviewPage,
		isMenuItem: true,
		path: clientsOverviewLocation,
	}
];
