import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../../models";
import { successfulLogin } from "../../../auth/authDataSlice";
import { TradeByProductsReportFilter } from "./types";

type PaymentPageState = {
  index: PageIndex;
  filter: TradeByProductsReportFilter;
};

const now = new Date();
export const initialState: PaymentPageState = {
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
  filter: {
    dateto: now.toISOString(),
    datefrom: new Date(now.getFullYear(), 0, 1).toISOString(),
  },
};

const name = "tradeByProducts";

const tradeByProductsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    resetPaymentIdices: (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }),
    setFilter: (state, action: PayloadAction<TradeByProductsReportFilter>) => ({
      ...state,
      filter: action.payload,
    }),
    removeFilter: (
      state,
      action: PayloadAction<keyof TradeByProductsReportFilter>
    ) => ({
      ...state,
      filter: {
        ...state.filter,
        [action.payload]: undefined,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(successfulLogin, (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }));
  },
});

export const {
  setIndex,
  resetPaymentIdices,
  setFilter,
  removeFilter,
} = tradeByProductsSlice.actions;

export default tradeByProductsSlice.reducer;
