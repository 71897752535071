import React from "react";
import { PayType } from "../../../data/listing/payTypes/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayPayTypeMainAttributesProps {
  payType: PayType;
}

export const DisplayPayTypeMainAttributes: React.FunctionComponent<DisplayPayTypeMainAttributesProps> = ({
  payType,
}) => {
  const style = useDisplaySingleEmementStyles();
  const { getString } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("payTypeDesc")}</span>
        <span className={style.value}>{payType.payTypeDescr}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("payTypeDescEn")}</span>
        <span className={style.value}>{payType.payTypeDescrEn}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("payTypeStatus")}</span>
        <span className={style.value}>
          <DisplayBoolNumber
            value={payType.status}
            thrutyValue={Status.Active}
            className={style.icon}
          />
        </span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("payTypePonder")}</span>
        <span className={style.value}>
          <DisplayBoolNumber
            value={payType.inPonder}
            thrutyValue={Status.Active}
            className={style.icon}
          />
        </span>
      </div>    </div>
  );
};
