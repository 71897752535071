import { LocalizationLanguage } from "../../core/localization/types";
import { ThunkType } from "../../core/store/reducers";
import { firstLoginWebApi } from "./api/firstLogin";
import { loginWebApi } from "./api/login";
import { successfulLogin, failedLogin, setPendingLoginState, setToken } from "./authDataSlice";
import { deleteUserToken } from "./authUXSlice";
import { ErrorCodes } from "../../core/http/types";
import { LoginResponse } from "../models";

export const loginUser = (
	username: string,
	password: string,
	language: LocalizationLanguage,
	source: string,
): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status === "successful") {
		return;
	}


	const token = store.ux.auth.users[username.toLowerCase()];

	dispatch(setPendingLoginState());

	const loginResponse = !token
		? await dispatch(firstLoginWebApi(username, password, language,source))
		: await dispatch(loginWebApi(username, password, language, token,source));

	if (!loginResponse) {
		dispatch(
			failedLogin({
				code: ErrorCodes.HTTPLoginError,
				message: "Greška u komunikaciji sa serverom.",
			})
		);
	} else if (loginResponse.resCode !== "OK") {
		if (loginResponse.resCode === ErrorCodes.AuthWrongToken) {
			dispatch(deleteUserToken(username))
		}

		dispatch(
			failedLogin({
				code: loginResponse.resCode,
				message: loginResponse.resDesc,
			})
		);
	} else {
		dispatch(setToken(loginResponse.obj.uData.token || token));
		dispatch(successfulLogin(loginResponse.obj));
	}
};
