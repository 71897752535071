import React from "react";
import { useDisplayTrade } from "../../../data/trading/trades/hooks/useDisplayTrades";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { TradeList } from "../../../data/trading/trades/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayTradeMainAttributes } from "./DisplayTradeMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,IconButton,
} from "@material-ui/core";
import { DisplayTradeLogData } from "./DisplayTradeLogData";
import { useTradePrinter } from "../../../data/trading/trades/hooks/useTradePrinter";
import { CustomTooltip } from "../../components/common/CustomTooltip";
//import PrintIcon from "@material-ui/icons/PrintOutlined";
//import { useAuth } from "../../../data/auth/hooks";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { CustomDialog } from "../../components/common/CustomDialog";
import { DisplayAttributes } from "../orders/attributes/DisplayAttributes";

export interface DisplayTradeProps {
  id?: string;
	enablePrint?: boolean;
}

export const DisplayTrade: React.FunctionComponent<DisplayTradeProps> = ({
  id,
  enablePrint,
}) => {
  const trade = useDisplayTrade(id);
  const { getString } = React.useContext(LocalizationContext);
	const [print, fetchState, setfetch] = useTradePrinter(trade);
  
  //const [auth] = useAuth();
  //{auth.status === "successful" &&  auth.user.roleCode == "MEMTRADER" && (

  return !trade ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Grid container justify="space-between">
            <Grid item>
              <Typography>{getString("tableBasciInfo")}</Typography>
            </Grid>
							<Grid item>
								<CustomTooltip titleKeyword="exportPDFTooltip">
									<IconButton
										color="secondary"
										component="span"
										onClick={print}
										disabled={fetchState.type !== "not-started"}
									>
										<DownloadIcon />
									</IconButton>
								</CustomTooltip>
                { fetchState.type !== "not-started"  ? getString("pleaseWait") : null }
							</Grid>
          </Grid>
        </ExpansionPanelSummary>
        <DisplayTradeMainAttributes trade={trade} />
				<DisplayAttributes attributes={trade.orderAttrList} />
				<DisplayTradeLogData tradelog={trade.tradeLogList}  />  
      </ExpansionPanel>

	  	{/*<CustomDialog
				open={fetchState.type === "started"}
				title={getString("tradesReportTitle")}
				message={getString("tradesReportMessage")}
				closeLabel={getString("tradesReportMessageConfim")}
        onClose={print}
      />
      */}

    </Grid>
  );
};

export const useViewTrade = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (trade: TradeList) => showWithDrawer(<DisplayTrade id={trade.tradeId} />),
    [showWithDrawer]
  );
};
