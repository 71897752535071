import { webApi } from "../../../../core/http/httpThunks";
import { Product } from "../types";

export const getProductApi = (userId: string, prodid: string) =>
	webApi<Product>({
		method: "GET",
		url: "listing/getprod",
		params: {
			userId,
			prodid
		},
	});

export default getProductApi;
