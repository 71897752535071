import React from "react";
import { useDisplayCtrlGood } from "./useDisplayCtrlGoods";
import { CtrlGoodsData } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";

export const defaultCtrlGoods: CtrlGoodsData = {
  //  payId: "",
  //  payTypeDescr: "",
  //  status: Status.Active,
};

export const useEditCtrlGood = (controlId?: string) => {
  const displayCtrlGood = useDisplayCtrlGood(controlId);

  return React.useMemo(() => {
    if (controlId !== undefined && displayCtrlGood === undefined) {
      return undefined;
    }

    if (controlId === undefined) {
      return { ...defaultCtrlGoods };
    }

    return displayCtrlGood ? { ...displayCtrlGood } : undefined;
  }, [controlId, displayCtrlGood]);
};

export const useEditCtrlGoodState = (controlId?: string) => {
  const ctrlGood = useEditCtrlGood(controlId);
  return useCopiedState(ctrlGood);
};
