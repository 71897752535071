import { ClientAccount } from "../../types";
import { ValidationState } from "../../../../../core/util/types";
import { createUseValidator } from "../../../../../core/util/hooks";

export const AccountValidation: ValidationState<ClientAccount> = {
	clientId: true,
	account: true,
	bankId: true,
	currId: true,
};

export const useClientAccountValidator = createUseValidator<ClientAccount, ValidationState<ClientAccount>>(
	AccountValidation
);
