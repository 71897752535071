import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Role } from "../types";

export const updateRoleApi = (userId: string, role: Role) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/updrole",
		params: {
			userId
		},
		data: role
	});

export default updateRoleApi;
