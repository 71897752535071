import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Country } from "../types";
import { loadCountry } from "../thunks/loadCountry";

export const useDisplayCountry = (cntId?: string) => {
	const storeCountry = useSelector<StoreType, Country | undefined>(
		store => store.data.listing.countries.displayedCountry
    );

    const displayedCountry = storeCountry && storeCountry.cntId === cntId ? storeCountry : undefined;

    const displatch = useDispatch();

	React.useEffect(() => {
		if (cntId) {
			displatch(loadCountry(cntId));
		}
    }, [displatch, cntId]);

	return displayedCountry;
};
