import React from "react";
import { AlarmData } from "../../../data/surveillance/alarmParameters/types";
import { PaginationTable,PaginationDataProps,} from "../../components/common/PagginationTable";
import {
  useAlarmsActions,
  useDeleteAlarmAction,
} from "./useAlarmsActions";
import { useAlarmsColumns } from "./usealarmsColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface AlarmsTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}

type AlarmsTablePropsType = AlarmsTableProps &
  PaginationDataProps<AlarmData>;

const AlarmsTable: React.FunctionComponent<AlarmsTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = useAlarmsActions();
  const columns = useAlarmsColumns();
  const onDeleteAlarm = useDeleteAlarmAction();

  return (
    <PaginationTable<AlarmData>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
      editable={{
        onRowDelete: onDeleteAlarm,
      }}
    />
  );
};

export default AlarmsTable;
