import { ThunkType } from "../../../../core/store/reducers";
import getCommisionApi from "../api/getCommision";
import { setDisplayed } from "../commissionsDataReducer";
import { Commission } from "../types";

export const loadCommission = (commId: string): ThunkType => async (dispatch, getStore) => {
	dispatch(setDisplayed());

	const role = await dispatch(getCommission(commId));

	dispatch(setDisplayed(role));
};

export const getCommission = (proiductId: string): ThunkType<Promise<Commission | undefined>> => async (
	dispatch,
	getStore
) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getCommisionApi(userId, proiductId));
};
