import React from "react";
import { useDailySecurityColumns } from "./useDailySecurityReportColumns";
import { AccessRights } from "../../../../core/navigation/types";
import { ControledTable } from "../../../components/common/ControledTable";
import { DailySecurityReportData } from "../../../../data/reports/securityCommission/securityDaily/types";
import { useSecurityReportData } from "../../../../data/reports/securityCommission/securityDaily/hooks/useSecurityReportData";
import { useSecurityReportIndex } from "../../../../data/reports/securityCommission/securityDaily/hooks/useSecurityReportIndex";

export interface SecurityTableProps {
  userAccessRights: AccessRights;
}

type SecurityTablePropsType = SecurityTableProps;

const DailySecurityReportTable: React.FunctionComponent<SecurityTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useDailySecurityColumns();
  const [data, load] = useSecurityReportData();
  const [index, setIndex] = useSecurityReportIndex();

  return (
    <ControledTable<DailySecurityReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default DailySecurityReportTable;
