import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useStockFeeData } from "../../../data/clearing/stockfees/hooks/useStockFeeData";
import { StockFee } from "../../../data/clearing/stockfees/types";
import { useStockFeeColumns } from "./useStockFeeColumns";
import { AccessRights } from "../../../core/navigation/types";
import {
  useStockFeeActions,
  useDeleteStockFeeAction,
} from "./useStockFeeActions";

export interface StockFeeTableProps {
  userAccessRights: AccessRights;
}

type StockFeeTablePropsType = StockFeeTableProps & TableDataProps<StockFee>;

export const StockFeesTable: React.FunctionComponent<StockFeeTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const [data, loadAll] = useStockFeeData();

  const columns = useStockFeeColumns();
  const actions = useStockFeeActions();
  const onDeleteStockFee = useDeleteStockFeeAction();

  return (
    <Table<StockFee>
      {...tableProps}
      getData={loadAll}
      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        toolbar: false,
        pageSize: 10,
        actionsColumnIndex: -1,
      }}
      editable={{
        onRowDelete: onDeleteStockFee,
      }}
    />
  );
};

export default StockFeesTable;
