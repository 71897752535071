import React from "react";
import { usePaymentGuarTypes } from "../../../data/initialData/hooks/usePaymentGuarTypes";
import { PayTypesG } from "../../../data/models";
import {
  AutocompleteSelect,
  AutocompleteSelectType,
} from "./AutocompleteSelect";

type PayTypesGPropsType = Omit<
  AutocompleteSelectType<PayTypesG>,
  "data" | "getLabel"
>;

export const PaymentTypeGuarSelect: React.FunctionComponent<PayTypesGPropsType> = (
  props
) => {
  const [payTypesG, renderLabel] = usePaymentGuarTypes();

  return (
    <AutocompleteSelect<PayTypesG>
      data={payTypesG}
      getLabel={renderLabel}
      {...(props as any)}
    />
  );
};
