import React from "react";
import { useRegionData } from "../../../data/listing/regions/hooks/useRegionData";
import { Region } from "../../../data/listing/regions/types";
import { AutocompleteSelectType, AutocompleteSelect } from "../common/AutocompleteSelect";
import { RegType } from "../../../data/models";

export type RegionSelectPropsType = Omit<AutocompleteSelectType<Region>, "data" | "getLabel"> & {
	regionType: RegType;
};

export const RegionSelect: React.FunctionComponent<RegionSelectPropsType> = ({ regionType, ...otherProps }) => {
	const [, loadAllRegions, regions] = useRegionData(regionType);

	React.useEffect(() => {
		if (!regions) {
			loadAllRegions();
		}
	}, [regions, loadAllRegions]);

	const trimedRegions = React.useMemo(
		() =>
			regions?.map(
				reg =>
					({
						regName: reg.regName,
						regType: reg.regType,
						regId: reg.regId,
						parentRegId: reg.parentRegId,
					} as Region)
			),
		[regions]
	);

	return (
		<AutocompleteSelect<Region>
			data={trimedRegions}
			getLabel={renderRegionLabel}
			{...otherProps}
		/>
	);
};

const renderRegionLabel = (region: Region | null) => (region ? region.regName : "");
