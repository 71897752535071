import React from "react";
import {
  Grid,
  CardContent,
  CardHeader,
  Card,
  Divider,
} from "@material-ui/core";
import MonthlyTradesReportTable from "./MonthlyTradesReportTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";
import { MTRFilterButton } from "./MTRFilter";

const MonthlyTradesReport: PageComponent = ({ userAccessRights }) => {
  const pageStyles = usePageStyles();

  return (
    <>
      <Grid container className={pageStyles.root}>
        <Grid item xs={12} className={pageStyles.gridItem}>
          <Card>
            <CardHeader
              title={<PageTitle title="monthlyTradeReportTitle" />}
              action={<MonthActions />}
            />
            <Divider />
            <CardContent>
              <MonthlyTradesReportTable userAccessRights={userAccessRights} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export const MonthActions: React.FunctionComponent = () => (
  <Grid container>
    <Grid item>
      <MTRFilterButton />
    </Grid>
    <Grid item>
      <DownloadButton />
    </Grid>
    <Grid item>
      <DownloadExcelButton />
    </Grid>
  </Grid>
);

export default MonthlyTradesReport;
