import React from "react";
import {
  CssBaseline,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { useToggleState } from "../core/util/hooks";
import { MainBar } from "./MainBar";
import { NavDrawer } from "./NavDrawer";
import { ThemeComponent } from "./Theme";
import { DisplayDrawer } from "./DisplayDrawer";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
    },
    mainContent: {
      width: "100%",
    },
  });
});

export const ContentLayout: React.FunctionComponent = (props) => {
  const styles = useStyles();
  const [drawerOpen, toggleDrawerOpen, setToogleDrawer] = useToggleState(false);

  return (
    <>
      <CssBaseline />
      <MainBar toggleNavMenu={toggleDrawerOpen} />
      <div className={styles.root}>
        <NavDrawer
          open={drawerOpen}
          toggleOpen={toggleDrawerOpen}
          setToogle={setToogleDrawer}
        />
        <DisplayDrawer>
          <main className={styles.mainContent}>{props.children}</main>
        </DisplayDrawer>
      </div>
    </>
  );
};

const ThemedContentLayout: React.FunctionComponent = (props) => (
  <ThemeComponent>
    <ContentLayout>{props.children}</ContentLayout>
  </ThemeComponent>
);

export default ThemedContentLayout;
