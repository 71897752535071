import React from "react";
import { useDispatch } from "react-redux";
import { ClientBroker } from "../../types";
import { getClientBrokers } from "../../thunks/clientBroker/loadClientBrokers";

export const useClientBrokers = (clientId?: string) => {
	const displatch = useDispatch();
	const [clientBrokers, setClientBrokers] = React.useState<ClientBroker[] | undefined>();

	const loadClientBrokers = React.useCallback(async () => {
		if (clientId) {
			const loadedClientBroker = (await displatch(getClientBrokers(clientId))) as any;
			setClientBrokers(loadedClientBroker || []);
		}
	}, [displatch, clientId])

	React.useEffect(() => {
		loadClientBrokers();
	}, [loadClientBrokers]);

	return [clientBrokers, loadClientBrokers] as [typeof clientBrokers, typeof loadClientBrokers]
};
