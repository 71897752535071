import React, { useContext, useCallback } from "react";
import { TextField, InputAdornment, createStyles, makeStyles, Theme } from "@material-ui/core";
import { LocalizationContext } from "../../../core/localization/Localization";
import SearchIcon from "@material-ui/icons/SearchOutlined";

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		searchButton: {
			color: theme.palette.secondary.dark,
			width: "1.2rem",
		},
	});
});

export interface SearchBoxProps {
	search: string;
	setSearch: (search: string) => void;
}

export const SearchBox: React.FunctionComponent<SearchBoxProps> = ({ search, setSearch }) => {
	const { getString } = useContext(LocalizationContext);
	const styles = useStyles();

	const onChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSearch(e.target.value),
		[setSearch]
	);

	return (
		<TextField
			placeholder={getString("search")}
			value={search}
			onChange={onChange}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon className={styles.searchButton} />
					</InputAdornment>
				),
			}}
			InputLabelProps={{
				shrink: true,
			}}
			type="search"
			color="secondary"
		/>
	);
};
