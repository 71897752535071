import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { ActStatuses } from "../../models";

// const renderLabel = (item: ActStatuses) => `${item.ftDescr} `;

const renderLabel = (item?: ActStatuses) => (item ? item.ftDescr : "");


export const useActStatusClinetsTypes = () => {
  const actstatusClients = useSelector<StoreType, ActStatuses[]>(
    (store) => store.data.initialData.actStatuses
  );

  return [actstatusClients, renderLabel] as [typeof actstatusClients, typeof renderLabel];
};
