import { ThunkType } from "../../../../core/store/reducers";
import { getMonthlyDateExcel } from "../api/getMonthlyDateExcel";
import fileDownload from "js-file-download";
import { setFetchState } from "../monthlyTradeDataSlice";
import moment from "moment";

export const downloadMonthlyDateExcel = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.reports.monthlyTrade;
  const user = store.data.auth.current.user;
  dispatch(setFetchState({ type: "started" }));

  const monthlyTrading = await dispatch(getMonthlyDateExcel(user, filter));
  if (monthlyTrading) {
    fileDownload(
      new Blob([monthlyTrading as any], {
        type: "application/vnd.openxmlformat",
      }),
      "MonthlyDTrading_" + moment().format("YYYYMMDDhhmmss") + ".xlsx"
    );
  }
  dispatch(setFetchState({ type: "not-started" }));
};
