import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { ProductGroup } from "../../models";

const getGroupLabel = (attribute?: ProductGroup) => (attribute ? attribute.prodGroupName : "");

export const useInitProductGroups = () => {
	const groups = useSelector<StoreType, ProductGroup[]>(store => store.data.initialData.productGroups);

	return [groups, getGroupLabel] as [typeof groups, typeof getGroupLabel];
};
