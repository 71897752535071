import { ThunkType } from "../../../../core/store/reducers";
import deleteRoleApi from "../api/deleteRole";
import { setFetchState } from "../rolesDataReducer";

export const deleteRole = (roleId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteRoleApi(userId, roleId));

	dispatch(setFetchState(result !== undefined ? { type: "successful" } : { type: "error"}));
};
