import { ThunkType } from "../../../../../core/store/reducers";
import { getTradeReviewReport } from "../api/getTradeReviewReport";
import fileDownload from "js-file-download";
import { setFetchState } from "../tradeReviewDataSlice";

export const downloadTradeReviewReport = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.reports.exchbusiness.tradeReview;
  const user = store.data.auth.current.user;
  dispatch(setFetchState({ type: "started" }));
  const ordersReport = await dispatch(getTradeReviewReport(user, filter));
  if (ordersReport) {
    fileDownload(
      new Blob([ordersReport as any], { type: "application/pdf" }),
      "TradeReview.pdf"
    );
  }
  dispatch(setFetchState({ type: "not-started" }));
};
