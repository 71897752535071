import React from "react";
import { useEditBankGuaranteeState } from "../../../data/clearing/bankguarantees/hooks/useEditBankGuaranteeState";
import { useBankGuaranteeValidator} from "../../../data/clearing/bankguarantees/hooks/useBankGuaranteeValidator";
import { useBankGuaranteeUpsert } from "../../../data/clearing/bankguarantees/hooks/useBankGuaranteeUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import {
	Card,
	Grid,
	CardHeader,
	Divider,
	CardContent,
} from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { BankGuaranteeData } from "../../../data/clearing/bankguarantees/types";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { Status } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { StringInput } from "../../components/common/StringInput";
import { bankguaranteeLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { NumberInput } from "../../components/common/NumberInput";
import { DateInput } from "../../components/common/DateInput";
import { CurrencySelect } from "../../components/common/CurrencySelect";
import { OrderSimpleData, SimpleOrderFilter } from "../../../data/trading/orders/types";
import { BankSelect } from "../../components/common/BankSelect";

export interface EditBankGuaranteeInfo {
	grntId?: string;
}

export interface EditBankGuaranteeProps extends RouteComponentProps<EditBankGuaranteeInfo> {}

export const EditBankGuaranteePage: React.FunctionComponent<EditBankGuaranteeProps> = props => {
	const { getString, getFormatedNumberString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [bankguarantee, editBankGuarantee, changed] = useEditBankGuaranteeState(props.match.params.grntId);
	const [validationState, validate] = useBankGuaranteeValidator();
	const [fetchState, upsert] = useBankGuaranteeUpsert();
	const history = useHistory();
/*	const [orderFilter, setOrderFilter] = React.useState<SimpleOrderFilter | undefined>(
		createOrderFilter(bankguarantee)
	);


	React.useEffect(() => {
		setOrderFilter(createOrderFilter(bankguarantee));
	}, [bankguarantee, setOrderFilter]);  */

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editBankGuarantee(event.target.name as any, event.target.value);
		},
		[editBankGuarantee]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(bankguaranteeLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(bankguarantee);

			if (normalized) {
				upsert(normalized);
			}
		}
	}, [bankguarantee, validate, fetchState, history, upsert]);

	const pageTitle = bankguarantee && bankguarantee.grntId ? "grntEdit" : "grntCreate";

	if (!bankguarantee) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={bankguaranteeLocation} />} />
					<Divider />
					<CardContent>
						<Grid item container direction="row" justify="flex-start" spacing={3}>
							<Grid item container direction="row" justify="flex-start" spacing={3} xs={6}>
								<Grid item xs={4}>
									<StringInput<BankGuaranteeData>
										property="grntNo"
										item={bankguarantee}
										validationState={validationState}
										onChange={handleChange}
										label={getString("grntNo")}
									/>
								</Grid>	

								<Grid item xs={3}>
									<DateInput
										label={getString("grntDate")}
										value={bankguarantee.grntDate || null}
										onChange={date => editBankGuarantee("grntDate", date)}
										error={
											validationState.grntDate !== undefined && !validationState.grntDate
										}
										required={validationState.grntDate !== undefined}
									/>
								</Grid>
								<Grid item xs={12}>
									<ClientSelect
										label={getString("grntClientName")}
										predicate={client => client.cliId === bankguarantee.clientId}
										onChangeItem={client => {
											editBankGuarantee("clientId", client ? client.cliId : undefined);
										}}
										required={validationState.clientId !== undefined}
										error={
											validationState.clientId !== undefined &&
											!validationState.clientId
										}
									/>
								</Grid>
								<Grid item xs={7}>
									<BankSelect
										label={getString("accountBank")}
										predicate={bank => bank.bankId === bankguarantee.bankId}
										onChangeItem={bank => {
											editBankGuarantee("bankId", bank ? bank.bankId : undefined);
										}}
										required={validationState.bankId !== undefined}
										error={validationState.bankId !== undefined && !validationState.bankId}
									/>
								</Grid>

								<Grid
									container
									item
									direction="row"
									justify="flex-start"
									alignItems="center"
									spacing={3}
								>
									<Grid item xs={7}>
										<NumberInput<BankGuaranteeData>
											property="amt"
											item={bankguarantee}
											validationState={validationState}
											onChange={handleChange}
											label={getString("grntAmount")}
											money
										/>
									</Grid>
									<Grid item xs={7}>
										<CurrencySelect
											label={getString("grntCurrCode")}
											predicate={currency => currency.currId === bankguarantee.currId}
											onChangeItem={currency =>
												editBankGuarantee("currId", currency?.currId)
											}
											required={validationState.currId !== undefined}
											error={
												validationState.currId !== undefined &&
												!validationState.currId
											}
											fullWidth
										/>
									</Grid>
									<Grid item xs={3}>
										<DateInput
											label={getString("grntExpiryDate")}
											value={bankguarantee.expiryDate || null}
											onChange={date => editBankGuarantee("expiryDate", date)}
											error={
												validationState.expiryDate !== undefined && !validationState.expiryDate
											}
											required={validationState.expiryDate !== undefined}
										/>
									</Grid>
								</Grid>
								<Grid item xs={1}>
									<BoolInput<BankGuaranteeData>
										property="status"
										item={bankguarantee}
										onChange={handleChange}
										falsyValue={Status.Inactive}
										thrutyValue={Status.Active}
										label={getString("grntStatus")}
									/>
								</Grid>
							</Grid>
						</Grid>

						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

const createOrderFilter = (guarantee?: BankGuaranteeData): SimpleOrderFilter | undefined => {
	return guarantee && guarantee.clientId
		? {
				cliid: guarantee.clientId,
				status: -2,
		  }
		: undefined;
};
