import { ThunkType } from "../../../../../core/store/reducers";
import { printClientContract } from "../../api/clientContract/printClientContract";
import fileDownload from "js-file-download";
import { setPrintContractFetchState } from "../../clientsDataReducer";
import { ClientContract } from "../../types";

export const printContractThunk = (contract?: ClientContract): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful" || !contract || !contract.cliContrId) {
		return undefined;
	}
	const lan = store.ux.localization.language
	const filename = `Contract_${contract.cliContrId}.pdf`
	const user = store.data.auth.current.user;
	dispatch(setPrintContractFetchState({ type: "started" }));
	const contractReport = await dispatch(printClientContract(user, contract.cliContrId));
	if (contractReport) {
		fileDownload(new Blob([contractReport as any], { type: "application/pdf" }), filename);
	}
	dispatch(setPrintContractFetchState({ type: "not-started" }));
};
