import { ThunkType } from "../../../../core/store/reducers";
import deleteRegionApi from "../api/deleteRegion";
import { setFetchState } from "../regionsDataReducer";
import { RegType } from "../../../models";

export const deleteRegion = (regId: string, type: RegType): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(
		setFetchState({
			data: { type: "started" },
			type,
		})
	);

	const { userId } = store.data.auth.current.user;
	await dispatch(deleteRegionApi(userId, regId));

	dispatch(
		setFetchState({
			data: { type: "successful" },
			type,
		})
	);
};
