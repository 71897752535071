import { webApi } from "../../../../core/http/httpThunks";
import { ProductSelectData } from "../types";
import { Status } from "../../../models";

export const getProductsFroSelect = (userId: string, status?:Status ) =>
	webApi<ProductSelectData[]>({
		method: "GET",
		url: "listing/getprodselect",
		params: {
			userId,
			status,
		},
	});

export default getProductsFroSelect;
