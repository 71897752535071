import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../../models";
import { DailySecurityReportData } from "./types";

type TradesReportData = {
	data?: DailySecurityReportData[];
	downloadReportFetchState: FetchStateType;
};

const initialState: TradesReportData = {
	downloadReportFetchState: { type: "not-started" },
};

const name = "dailySecurity";

const dailySecuritySlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<DailySecurityReportData[] | undefined>) => ({
			...state,
			data: action.payload,
		}),
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			downloadReportFetchState: action.payload,
		}),
	},
});

export const { setData, setFetchState } = dailySecuritySlice.actions;

export default dailySecuritySlice.reducer;
