import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { EditOrder } from "../types";

export const modifyOrderApi = (order: EditOrder, userid: string) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "order/replaceorder",
    data: order,
    params: {
      userid,
    }
  });

export default modifyOrderApi;
