import { ThunkType } from "../../../../core/store/reducers";
import getRefPriceButton from "../api/getRefPriceButton";
import { setFetchState, invalidateTradingsData } from "../tradingDataSlice";

export const downloadRefPriceButton = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { filter } = store.ux.clearing.tradingData;
	const user = store.data.auth.current.user;
	dispatch(setFetchState({ type: "started" }));
	const refPrice = await dispatch(getRefPriceButton(user, filter));
	dispatch(invalidateTradingsData());
	dispatch(setFetchState({ type: "successful" }));

};
