import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import { PaymentFilter } from "./types";

type PaymentPageState = {
  index: PageIndex;
  filter: PaymentFilter;
};

export const initialState: PaymentPageState = {
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
  filter: {
    status: {
      ftValue: -1,
      ftDescr: "",
    },
  },
};

const name = "payments";

const paymentsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setPaymentIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    resetPaymentIdices: (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }),
    setFilter: (state, action: PayloadAction<PaymentFilter>) => ({
      ...state,
      filter: action.payload,
    }),
    removeFilter: (state, action: PayloadAction<keyof PaymentFilter>) => ({
      ...state,
      filter: {
        ...state.filter,
        [action.payload]: undefined,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(successfulLogin, (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }));
  },
});

export const {
  setPaymentIndex,
  resetPaymentIdices,
  setFilter,
  removeFilter,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
