import React from "react";
import StoreProvider from "./core/store/StoreProvider";
import { HashRouter as Router } from "react-router-dom";
import { LocalizationContextProvider } from "./core/localization/Localization";
import { ContentRoot } from "./views/ContentRoot";
import { appConfig } from "./core/config/config"

export default () => (
	<StoreProvider>
		<LocalizationContextProvider>
			<Router basename={appConfig.general.deploymentDir}>
				<ContentRoot />
			</Router>
		</LocalizationContextProvider>
	</StoreProvider>
);
