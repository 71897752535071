import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { Region } from "../../../data/listing/regions/types";
import { RegType } from "../../../data/models";
import { useCountryGetter } from "../../../data/listing/countries/hooks/useCountryData";

export function useRegionsColumns(type?: RegType) {
	const { getString } = useContext(LocalizationContext);
	const [getCountry] = useCountryGetter();

	const regionName = type === RegType.TopRegion ? "regName" : "regNameDistrict"

	return useMemo(() => {
		const columns = [
			{
				title: getString(regionName),
				field: "regName",
			},
		];

		if (type === RegType.TopRegion) {
			columns.push({
				title: getString("countryReg"),
				render: (region: Region) => getCountry(region.country)?.cntName
			} as any);
		} else {
			columns.push({
				title: getString("parentRegName"),
				field: "parentRegName",
			});
		}

		return columns;
	}, [getString, type, getCountry]) as Column<Region>[];
}
