import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import appStore from "./store";

const StoreProvider: React.FunctionComponent = props => (
	<Provider store={appStore.store}>
		<PersistGate loading={null} persistor={appStore.persistor}>
			{props.children}
		</PersistGate>
	</Provider>
);

export default StoreProvider;
