import React from "react";
import { City } from "../../../data/listing/cities/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayCityMainAttributesProps {
  city: City;
}

export const DisplayCityMainAttributes: React.FunctionComponent<DisplayCityMainAttributesProps> = ({
  city,
}) => {
  const style = useDisplaySingleEmementStyles();
  const { getString } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("cityName")}</span>
        <span className={style.value}>{city.cityName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("cityZipCode")}</span>
        <span className={style.value}>{city.zipCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("cityRegion")}</span>
        <span className={style.value}>{city.regionName}</span>
      </div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cityIsPort")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={city.isPort}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				</span>
			</div>
    </div>
  );
};
