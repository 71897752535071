import { ThunkType } from "../../../../core/store/reducers";
import createClient from "../api/createClient";
import updateClientApi from "../api/updateClient";
import { setFetchStateClient } from "../clientsDataReducer";
import { Client } from "../types";

export const upsertClient = (client: Client): ThunkType<Promise<string | undefined>> => async (
	dispatch,
	getStore
) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchStateClient({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = client.cliId
		? await dispatch(updateClientApi(userId, client))
		: await dispatch(createClient(userId, client));

	if (result && result.resCode === "OK") {
		dispatch(setFetchStateClient({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchStateClient({ type: "error", errorCode: result?.resCode }));
	}
};
