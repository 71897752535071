import React from "react";
import { useTopRegions } from "../../../data/initialData/hooks/useTopRegions";
import { Region } from "../../../data/models";
import {
  AutocompleteSelect,
  AutocompleteSelectType,
} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<Region>,
  "data" | "getLabel"
>;

export const TopRegionSelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [topRegions, renderTopRegionLabel] = useTopRegions();

  return (
    <AutocompleteSelect<Region>
      data={topRegions}
      getLabel={renderTopRegionLabel}
      {...(props as any)}
    />
  );
};
