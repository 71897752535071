import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { AlarmFilter } from "../../../data/surveillance/alarmParameters/types";
import { LocalizationContext } from "../../../core/localization/Localization";


export const AlarmFilterChipsComponent: React.FunctionComponent<AlarmsDataFilterChipsProps> = ({
  filter,
  removeFilter,
}) => {
  const { getDateString, getString } = React.useContext(LocalizationContext);
  

  return (
    <Grid container spacing={1}>
      {filter.client && (
        <Grid item>
          <Chip
            label={filter.client.cliName}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("client")}
          />
        </Grid>
      )}

      {filter.product && (
        <Grid item>
          <Chip
            label={filter.product.prodName}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("product")}
          />
        </Grid>
      )}
      {filter.user && (
        <Grid item>
          <Chip
            label={filter.user.userName} 
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("user")}
          />
        </Grid>
      )}
    </Grid>
  );
};

interface AlarmsDataFilterChipsProps {
  filter: AlarmFilter;
  removeFilter: (type: keyof AlarmFilter) => void;
}
