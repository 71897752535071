import { webApi } from "../../../../../core/http/httpThunks";

export const deleteAttributeValueApi = (userId: string, attrvalid: string) =>
	webApi<string>({
		method: "DELETE",
		url: "listing/delattrval",
		params: {
			userId,
			attrvalid
		},
	});

export default deleteAttributeValueApi;
