import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageData, PageDataStatus, FetchStateType } from "../../models";
import { TradeList } from "../../trading/trades/types";

type TradesReportData = {
  currentPage?: PageData<TradeList>;
  downloadReportFetchState: FetchStateType;
};

const initialState: TradesReportData = {
  downloadReportFetchState: { type: "not-started" },
};

const name = "tradesReport";

const tradesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setTrades: (state, action: PayloadAction<PageData<TradeList>>) => ({
      ...state,
      currentPage: action.payload,
    }),
    invalidateOrders: (state) => {
      if (!state.currentPage) return state;

      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          status: PageDataStatus.DataInvalidated,
        },
      };
    },
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			downloadReportFetchState: action.payload,
		}),
  },
});

export const {
  setTrades,
  invalidateOrders,
  setFetchState,
} = tradesSlice.actions;

export default tradesSlice.reducer;
