import React from "react";
import { useEditAttributeValueState } from "../../../../data/listing/attributes/hooks/attributeValues/useEditAttributeValueState";
import { useAttributeValueValidator } from "../../../../data/listing/attributes/hooks/attributeValues/useAttributeValueValidator";
import { useAttributeValueUpsert } from "../../../../data/listing/attributes/hooks/attributeValues/useAttributeValueUpsert";
import { AttributeValue } from "../../../../data/listing/attributes/types";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../../pageStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../../components/common/FabSave";
import { StringInput } from "../../../components/common/StringInput";
import { getAttributeEditLocation, AttributeEditParts } from "../Locations";
import { BoolInput } from "../../../components/common/BoolInput";
import { Status } from "../../../../data/models";
import { LeavePrompt } from "../../../components/common/LeavePrompt";
import { PageTitle } from "../../../components/common/PageTitle";

export interface EditAttributeValueInfo {
	attributeId: string;
	valueId?: string;
}

export interface EditAttributeValueProps extends RouteComponentProps<EditAttributeValueInfo> {}

export const EditAttributeValue: React.FunctionComponent<EditAttributeValueProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const { valueId, attributeId } = props.match.params;
	const [value, editValue, changed] = useEditAttributeValueState(attributeId, valueId);
	const [validationState, validate] = useAttributeValueValidator();
	const [fetchState, upsert] = useAttributeValueUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editValue(event.target.name as any, event.target.value);
		},
		[editValue]
	);

	const handleChangeNum = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editValue(event.target.name as any, parseInt(event.target.value));
		},
		[editValue]
	);

	const backLocation = React.useMemo(
		() => getAttributeEditLocation(AttributeEditParts.Values, attributeId),
		[attributeId]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(backLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(value);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [value, validate, fetchState, history, upsert, backLocation]);

	const pageTitle = value && value.attrValueId ? "attributeValuesEditTitle" : "attributeValuesCreateTitle";

	if (!value) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={backLocation} />} />
					<Divider />
					<CardContent>
						<Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
							<Grid item xs={3}>
								<StringInput<AttributeValue>
									property="name"
									item={value}
									validationState={validationState}
									onChange={handleChange}
									label={getString("attributeValueName")}
								/>
							</Grid>
							<Grid item xs={3}>
								<StringInput<AttributeValue>
									property="nameen"
									item={value}
									validationState={validationState}
									onChange={handleChange}
									label={getString("attributeValueNameEN")}
								/>
							</Grid>
						</Grid>
						<Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
							<Grid item xs={3}>
								<StringInput<AttributeValue>
									property="value"
									item={value}
									validationState={validationState}
									onChange={handleChange}
									label={getString("attributeValueValue")}
								/>
							</Grid>
							<Grid item xs={3}>
								<StringInput<AttributeValue>
									property="valueen"
									item={value}
									validationState={validationState}
									onChange={handleChange}
									label={getString("attributeValueValueEN")}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="flex-start"
							spacing={4}
						>
							<Grid item xs={3}>
								<StringInput<AttributeValue>
									property="orderNo"
									item={value}
									validationState={validationState}
									onChange={handleChangeNum}
									label={getString("attributeValueOrder")}
									type="number"
								/>
							</Grid>
							<Grid item xs={3}>
								<BoolInput<AttributeValue>
									property="status"
									item={value}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("attributeValueStatus")}
								/>
							</Grid>
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
