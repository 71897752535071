import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { CheckDoneByList } from "../../models";

const renderLabel = (item: CheckDoneByList) => item.ftDescr;

export const useCheckDoneByList = () => {
  const checDoneByList = useSelector<StoreType, CheckDoneByList[]>(
    (store) => store.data.initialData.checkDoneByList
  );

  return [checDoneByList, renderLabel] as [
    typeof checDoneByList,
    typeof renderLabel
  ];
};
