import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import logger from "redux-logger";
import reducer from "./reducers";
import { persistStore, persistReducer } from 'redux-persist'
import { appConfig } from "../config/config"
// import storage from 'redux-persist/lib/storage'

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['ux']
// }

// const persistedReducer = persistReducer(persistConfig, reducer)

const defaultMiddlware = getDefaultMiddleware({
	immutableCheck: false,
	serializableCheck: false,
	thunk: true,
});

const middlware = appConfig.general.useLogger ? [...defaultMiddlware, logger] : defaultMiddlware

const store = configureStore({
	reducer,
	middleware: middlware as any,
});
export const persistor = persistStore(store)
export default { store, persistor };
