import { NavigationItem } from "../../../core/navigation/types";
import { EditCurrencyPage } from "./EditCurrencyPage";
import CurrencyPage from "./CurrencyPage";
import { currencyLocation, editRouter } from "./Locations";
export const currencyComponents: NavigationItem[] = [
	{
		Component: CurrencyPage,
		isMenuItem: true,
		path: currencyLocation,
	},
	{
		Component: EditCurrencyPage,
		isMenuItem: false,
		path: editRouter,
	},
];
