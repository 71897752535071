import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertDelivery } from "../thunks/upsertDelivery";
import { setFetchState } from "../deliveryDataSlice";
import { DeliveryData } from "../types";

export const useDeliveryUpsert = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.clearing.deliveries.fetchState
  );

  const dispatch = useDispatch();
  const dispatchUpsertDelivery = React.useCallback(
    (deliveries: DeliveryData) => dispatch(upsertDelivery(deliveries)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchUpsertDelivery] as [
    typeof fetchState,
    typeof dispatchUpsertDelivery
  ];
};
