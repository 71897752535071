import React from "react";
import { useTradeReviewColumns } from "./useTradeReviewReportColumns";
import { AccessRights } from "../../../../core/navigation/types";
import { TradeReviewReportData } from "../../../../data/reports/exchbusiness/tradeReview/types";
import { ControledTable } from "../../../components/common/ControledTable";
import { useTradeReviewReportData } from "../../../../data/reports/exchbusiness/tradeReview/hooks/useTradeReviewReportData";
import { useTradeReviewReportIndex } from "../../../../data/reports/exchbusiness/tradeReview/hooks/useTradeReviewReportIndex";

export interface TradeReviewTableProps {
  userAccessRights: AccessRights;
}

type TradeReviewTablePropsType = TradeReviewTableProps;

export const TradeReviewReportTable: React.FunctionComponent<TradeReviewTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useTradeReviewColumns();
  const [data, load] = useTradeReviewReportData();
  const [index, setIndex] = useTradeReviewReportIndex();

  return (
    <ControledTable<TradeReviewReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default TradeReviewReportTable;
