import { NavigationItem } from "../../../core/navigation/types";
import GuaranteesPage from "./GuaranteesPage";
import { EditGuaranteePage } from "./EditGuaranteePage";
import { guaranteeLocation, editRouter } from "./Locations";
export const guaranteeComponents: NavigationItem[] = [
  {
    Component: GuaranteesPage,
    isMenuItem: true,
    path: guaranteeLocation,
  },
  {
    Component: EditGuaranteePage,
    isMenuItem: false,
    path: editRouter,
  },
];
