import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { ProductList } from "../../../data/listing/products/types";
import React from "react";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export function useProductColumns() {
	const { getString } = useContext(LocalizationContext);
	return useMemo(
		() => [
			{
				title: getString("productName"),
				field: "prodName",
			},
			{
				title: getString("productCode"),
				field: "prodCode",
			},
			{
				title: getString("productGroupName"),
				field: "prodGroupName",
			},
			{
				title: getString("productUnit"),
				field: "unit",
			},
			{
				title: getString("productVat"),
				field: "vat",
			},
			{
				title: getString("productStatus"),
				render: product => <DisplayBoolNumber value={product.status} thrutyValue={Status.Active} />,
				customSort: (a, b) => a.status - b.status,
				width: "80px",
			},
		],
		[getString]
	) as Column<ProductList>[];
}
