import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertPayType } from "../thunks/upsertPayType";
import { setFetchState } from "../payTypesDataReducer";
import { PayType } from "../types";

export const usePayTypeUpsert = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.listing.payTypes.fetchState
  );

  const dispatch = useDispatch();
  const dispatchUpsertPayType = React.useCallback(
    (payType: PayType) => dispatch(upsertPayType(payType)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchUpsertPayType] as [
    typeof fetchState,
    typeof dispatchUpsertPayType
  ];
};
