import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import {
	useMarginsDataFilter,
	useCurrentMarginsDataFilter,
} from "../../../data/clearing/marginsData/hooks/useMarginsDataFilter";
import {
	MarginsDataFilterEditor,
	MarginsDataFilterEditorType,
	MarginsDataFilterChips,
} from "../marginsData/MarginsDataFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const MarginsDataFilterButton: React.FunctionComponent = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<MarginDataFilterEditor />), [showWithDrawer]);

	const [filter, removeFilter] = useCurrentMarginsDataFilter();
	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<MarginsDataFilterChips filter={filter} removeFilter={removeFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip">
					<IconButton color="secondary" component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const MarginDataFilterEditor: React.FunctionComponent = () => {
	const [filter, editFilter, save, , rest] = useMarginsDataFilter();
	return (
		<MarginsDataFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
			type={MarginsDataFilterEditorType.Extended}
			reset={rest}
		/>
	);
};
