import { ValidationState } from "../../../core/util/types";
import { Status } from "../../models";

export enum ClientActive {
	HasAccess = 1,
	AccessForbiden = 0,
}

export enum ClientHeadquaters {
	Domestic = 0,
	Foreign = 1,
}
export interface ClientListData {
	cliId: string; //		PK
	cliName: string; //	naziv klijenta
	cliIdNo: string; //	maticni broj klijenta
	taxCode: string; //	PIB broj
	cliType: string; //	tip klijenta
	foreignCli: ClientHeadquaters; //	0=domaci klijent (default), 1=strani
	city: string; //
	phone: string; //
	broker: string; //
	active: ClientActive; //		0=ima pristup app (def), 1=zabranjen pristup
	status: Status; //		0=aktivan (def), 1=pasivan
}

export interface Client {
	cliId?: string;
	cliName: string;
	fullName: string;
	cliIdNo: string;
	taxCode: string;
	cliJBKJS?: string;
	rpgCode: string;
	cliType: number; //id Client type
	foreignCli: ClientHeadquaters; // u stvari enum 0/1
	cityId?: string; // id grada
	city?: string;
	address: string;
	mailCityId?: string;
	mailCity?: string;
	mailAddress: string;
	phone: string;
	mobile: string;
	fax: string;
	email: string;
	hasContract?: ClientHasContract; //	ne popunjava se, setovace se unosom ugovora:
	active: ClientActive; //		0=ima pristup app (def), 1=zabranjen pristup
	activeFrom?: Date; //; placena clanarina od datuma
	activeTo?: Date; //;	  placena clanarina do datuma
	commId?: string; //	??
	fixedComm?: number; //	??
	commission?: number; // ??
	initialTurnover?: number; // NE TREBA
	currentTurnover?: number;
	comment: string; //	komentar
	status: Status; //		status klijenta: 0-enabled, 1-disabled
	creUser?: string; //	popunjava se na serveru
	creTime?: Date; //	isto
}

export enum ClientHasContract {
	True = 0,
	False = 1
}

export interface ClientPerson {
	cliPrsId?: string;
	clientId: string;
	cliPrsIdNo: string;
	cliPrsName: string;
	cliPrsRole?: number; // 		uloga lica (iz commondata PersonRoles)
	cliPrsRoleName?: string; // 	naziv uloge lica => dodati jos u listu
	dateCertified?: Date; // 	datum sticanja sertifikata (za trejdera)
	phone?: string; //  		telefon lica
	email?: string; //  		email lica
	status: Status;
}

export interface ClientBroker {
	cliBrokId?: string;
	clientId: string;
	brokerId?: string;
	brokName: string;
	brokIdNo: string;
	status: Status;
}

export interface ClientContractList {
	cliContrId: string;
	contrNo: string;
	contrDate: Date;
	linkedContrNo: string;
	contrTypeDescr: string;
	contrDescr: string;
	status: Status;
}

export interface ClientContract {
	cliContrId?: string;
	clientId: string;
	contrNo: string;
	contrDate?: Date;
	contrType: number; // 10
	contrTypeDescr: string;
	linkedContrId?: string;
	linkedContrNo?: string;
	contrDescr: string;
	phoneOrder: string;
	webUser: ContractWebUser;
	webUserBlocked: ContractWebUserBlocked;
	status: Status;
}

export enum ContractWebUser {
	True = 1,
	False = 0,
}

export enum ContractWebUserBlocked {
	True = 1,
	False = 0,
}

export interface ClientAccountList {
	monAccId?: string; // 		PK
	account: string; // 		broj racuna
	bankCode: string; // 		oznaka banke
	defaultAcc: number; // 		default racun
	currCode: string; // 		oznaka valuta
	forBuySell: AccountOrderSide; // 		za kupovinu/prodaju (1=za kupovinu, 2=za prodaju, 0=za oba - default)
	status: Status; //
}

export interface ClientAccount {
	monAccId?: string; // 		PK
	clientId: string; // 		ID klijenta
	account?: string; // 		racun
	bankId?: string; // 		ID banke
	bankCode?: string; // 		oznaka banke
	defaultAcc: AccountDefault; // 		default racun
	clientBankCode: string; // 	sifra klijenta u banci
	jointAccId?: string; // 		ID zbirnog racuna (ovo se moze izostaviti, za sada se ne koristi)
	currId?: string; // 		ID valute
	currCode?: string; // 		oznaka valute
	forBuySell: AccountOrderSide; // 		za kupovinu/prodaju
	account1?: string; // 		vezani racun (i ovo se za sada moze izostaviti)
	status: Status; //
}

export enum AccountDefault {
	True = 1,
	False = 0,
}

export enum AccountOrderSide {
	Buy = 1,
	Sell = 2,
	Both = 0,
}

export type ClientValidationState = ValidationState<Client>;

export enum ErrorCodeExceptions {
	ReferenceErrorCode = "E-903",
}

