import { webApi } from "../../../../core/http/httpThunks";
import { Attribute } from "../types";

export const getAttributeApi = (userId: string, attrid: string) =>
	webApi<Attribute>({
		method: "GET",
		url: "listing/getattr",
		params: {
			userId,
			attrid
		},
	});

export default getAttributeApi;
