import { AxiosRequestConfig } from "axios";

export interface GeneralConfig {
	titleSufix: string;
	deploymentDir?: string;
	useLogger?: boolean
}

export interface NotificationsConfig {
	uri: string
}

export interface ConfigType {
	http: AxiosRequestConfig;
	general: GeneralConfig;
	notifications: NotificationsConfig;
}

const windowENV: any = (window as any).ENV;

export const appConfig: ConfigType = {
	http: {
		// baseURL: "http://195.178.54.171/prodberzaws/api",
		// baseURL: "http://195.178.54.171/commexchws/api",
		baseURL: windowENV.API_BASE,
		// baseURL: "/commexchwsdevcli/api",
	},
	general: {
		titleSufix: windowENV.TITLE_SUFIX,
		deploymentDir: windowENV.DEPLOYMENT_DIR !== "" ? windowENV.DEPLOYMENT_DIR : undefined,
	},
	notifications: {
		uri: windowENV.NOTIFICATION_BASE_URI,
	}
};
