import { ThunkType } from "../../../../core/store/reducers";
import { setPayments, invalidatePayment } from "../paymentsDataSlice";
import { setPaymentIndex } from "../paymentsUXSlice";
import getPayments from "../api/getPaymentsClient";
import { PageIndex, PageDataStatus } from "../../../models";

export const loadAllPayments = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const { pageNumber, itemsPerPage } = store.ux.clearing.payments.index;
  const { filter } = store.ux.clearing.payments;
  const user = store.data.auth.current.user;

  const paymentsResponse = await dispatch(
    getPayments(user, pageNumber, itemsPerPage, filter)
  );

  if (!paymentsResponse || paymentsResponse.resCode !== "OK") {
    return;
  }

  const payments = {
    currentPageData: paymentsResponse.obj,
    totalCount: Number(paymentsResponse.resDesc) || 0,
    status: PageDataStatus.DataCurrent,
  };

  dispatch(setPayments(payments));
};

export const setPaymentsIndexThunk = (index: PageIndex): ThunkType => (
  dispatch
) => {
  dispatch(setPaymentIndex(index));
  dispatch(invalidatePayment());
};
