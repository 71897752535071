import React from "react";
import { EditOrder, OrderMarkt, FundsCheckStatus } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { OrderSide } from "../../../models";
import { User } from "../../../auth/types";
import { useAuth } from "../../../auth/hooks";
import { OrdersEditAction } from "../types";
import { useOrderForEdit } from "./useOrderForEdit";

export const createDefauiltOrder = (user: User): EditOrder => ({
	userId: user.userId,
	clientId: user.clientId,
	side: OrderSide.Buy,
	receiveMethod: user.userIsEmployee === 1 ? 60 : undefined,
	exportReady: 0,
	paysCheck: 3,
	locCountryId: "RS",
	market: OrderMarkt.Spot,
	fundsCheckStatus: FundsCheckStatus.NotChecked,
});

export const useEditOrder = (action: OrdersEditAction, orderId?: string): EditOrder | undefined => {
	const diplayedOrder = useOrderForEdit(action, orderId);
	const [auth] = useAuth();

	return React.useMemo(() => {
		if ((orderId !== undefined && diplayedOrder === undefined) || auth.status !== "successful") {
			return undefined;
		}

		if (orderId === undefined) {
			const defaultOrder = createDefauiltOrder(auth.user);
			return { ...defaultOrder };
		}
		const order = diplayedOrder
			? (({ ...diplayedOrder } as unknown) as EditOrder)
//			? (({ ...diplayedOrder, userId: auth.user.userId } as unknown) as EditOrder)
			: undefined;

		if (order && auth.user.clientId) {
			order.clientId = auth.user.clientId;
		}

		return order;
	}, [orderId, diplayedOrder, auth]);
};

export const useEditOrderState = (action: OrdersEditAction, orderId?: string) => {
	const order = useEditOrder(action, orderId);
	return useCopiedState(order);
};
