import { ThunkType } from "../../../../core/store/reducers";
import { setTrades, invalidateOrders } from "../tradesReportDataSliceAll";
import { setIndex } from "../../tradesReport/tradesReportUXSlice";
import { PageIndex } from "../../../models";
import { getTradesThunk } from "./getTradesThunk";

export const loadReport = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	const { pageNumber, itemsPerPage } = store.ux.reports.tradesReportAll.index;
	const { filter } = store.ux.reports.tradesReportAll;

	const pagedTrades = await dispatch(getTradesThunk(pageNumber, itemsPerPage, filter));


	if (pagedTrades) {
		dispatch(setTrades(pagedTrades));
	}
};

export const setIndexThunk = (index: PageIndex): ThunkType => dispatch => {
	dispatch(setIndex(index));
	dispatch(invalidateOrders());
};
