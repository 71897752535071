import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageData, PageDataStatus, FetchStateType, getFetchStateChange,} from "../../models";
import { BankGuaranteeData } from "./types";

type BankGuaranteesData = {
  all?: BankGuaranteeData[];
  displayData?: BankGuaranteeData[];
  currentPage?: PageData<BankGuaranteeData>;
  displayedBankGuarantee?: BankGuaranteeData;
  fetchState: FetchStateType;
  cancelFetchState: FetchStateType;
  downloadReportFetchState: FetchStateType;
};

const initialState: BankGuaranteesData = {
  cancelFetchState: { type: "not-started" },
  fetchState: { type: "not-started" },
  downloadReportFetchState: { type: "not-started" },
};

const name = "bankguarantees";

const bankguaranteesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setBankGuarantees: (
      state,
      action: PayloadAction<PageData<BankGuaranteeData>>
    ) => ({
      ...state,
      currentPage: action.payload,
    }),

    setDisplayed: (
      state,
      action: PayloadAction<BankGuaranteeData | undefined>
    ) => ({
      ...state,
      displayedBankGuarantee: action.payload,
    }),

    invalidateBankGuarantee: (state) => {
      if (!state.currentPage) return state;

      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          status: PageDataStatus.DataInvalidated,
        },
      };
    },
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      displayedBankGuarantee:
        action.payload.type === "successful"
          ? undefined
          : state.displayedBankGuarantee,
      all: action.payload.type === "successful" ? undefined : state.all,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: getFetchStateChange(state.fetchState, action.payload),
      downloadReportFetchState: action.payload,
    }),
    setCancelBankGuaranteeFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      cancelFetchState: action.payload,
    }),
    setDownloadFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      downloadReportFetchState: action.payload,
    }),
  },
});

export const {
  setBankGuarantees,
  setDisplayed,
  invalidateBankGuarantee,
  setFetchState,
  setCancelBankGuaranteeFetchState,
  setDownloadFetchState,
} = bankguaranteesSlice.actions;

export default bankguaranteesSlice.reducer;
