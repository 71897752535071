import { Client, ClientValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const clientValidation: ClientValidationState = {
	cliName: true,
	fullName: true,
	cliIdNo: true,
	taxCode: true,
	cliType: true,
}

export const useClientValidator = createUseValidator<Client, ClientValidationState>(clientValidation);
