import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { CurrencyRate } from "../types";

export const updateCurrencyRateApi = (userId: string, rate: CurrencyRate) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/updcurrrate",
		params: {
			userId
		},
		data: rate
	});

export default updateCurrencyRateApi;
