import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageData, PageDataStatus, FetchStateType } from "../../models";
import { TradeList } from "../../trading/trades/types";
import { TransportAndStorageCosts } from "./types";

type TradesData = {
	currentPage?: PageData<TradeList>;
	displayedTrade?: TradeList;
	calculateFetchState: FetchStateType<TransportAndStorageCosts>;
	saveCalcFetchState: FetchStateType;
};

const initialState: TradesData = {
	calculateFetchState: { type: "not-started" },
	saveCalcFetchState: { type: "not-started" },
};

const name = "TAStrades";

const tasTradesSlice = createSlice({
	name,
	initialState,
	reducers: {
		setTrades: (state, action: PayloadAction<PageData<TradeList>>) => ({
			...state,
			currentPage: action.payload,
		}),
		setDisplayed: (state, action: PayloadAction<TradeList | undefined>) => ({
			...state,
			displayedTrade: action.payload,
		}),
		invalidateTrades: state => {
			if (!state.currentPage) return state;

			return {
				...state,
				currentPage: {
					...state.currentPage,
					status: PageDataStatus.DataInvalidated,
				},
			};
		},
		setCalculationFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			calculateFetchState: action.payload,
		}),
		setSaveCalcFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			saveCalcFetchState: action.payload,
		}),
	},
});

export const { setTrades, setDisplayed, invalidateTrades, setCalculationFetchState, setSaveCalcFetchState } = tasTradesSlice.actions;

export default tasTradesSlice.reducer;
