import { AlarmData, AlarmValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const alarmValidationState: AlarmValidationState = {
  almType: true,
};

export const useAlarmValidator = createUseValidator<
AlarmData,
  AlarmValidationState
>(alarmValidationState);
