import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PageData,
  PageDataStatus,
  FetchStateType,
  getFetchStateChange,
} from "../../models";
import { AlarmData } from "./types";

type AlarmsData = {
  all?: AlarmData[];
  displayData?: AlarmData[];
  currentPage?: PageData<AlarmData>;
  displayedAlarm?: AlarmData;
  fetchState: FetchStateType;
  cancelFetchState: FetchStateType;
};

const initialState: AlarmsData = {
  cancelFetchState: { type: "not-started" },
  fetchState: { type: "not-started" },
};

const name = "alarms";

const alarmsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setAlarm: (
      state,
      action: PayloadAction<PageData<AlarmData>>
    ) => ({
      ...state,
      currentPage: action.payload,
    }),

    setDisplayed: (state, action: PayloadAction<AlarmData | undefined>) => ({
      ...state,
      displayedAlarm: action.payload,
    }),

    invalidateAlarm: (state) => {
      if (!state.currentPage) return state;

      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          status: PageDataStatus.DataInvalidated,
        },
      };
    },

    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      displayedAlarm:
        action.payload.type === "successful"
          ? undefined
          : state.displayedAlarm,
      all: action.payload.type === "successful" ? undefined : state.all,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: getFetchStateChange(state.fetchState, action.payload),
    }),
    setCancelAlarmFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      cancelFetchState: action.payload,
    }),
  },
});

export const {
  setAlarm,
  setDisplayed,
  invalidateAlarm,
  setFetchState,
  setCancelAlarmFetchState,
} = alarmsSlice.actions;

export default alarmsSlice.reducer;
