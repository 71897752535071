import { webApi } from "../../../../core/http/httpThunks";
import { InvoiceData } from "../types";

export const getInvoiceApi = (userId: string, invid: string) =>
  webApi<InvoiceData>({
    method: "GET",
    url: "accounting/getinvoice",
    params: {
      userId,
      invid,
    },
  });

export default getInvoiceApi;
