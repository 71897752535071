import React from "react";
import {
  Grid,
  CardContent,
  CardHeader,
  Card,
  Divider,
} from "@material-ui/core";
import PaymentsTable from "./PaymentsTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { usePaymentsData } from "../../../data/clearing/payments/hooks/usePaymentsDataClient";
import { usePaymentIndex } from "../../../data/clearing/payments/hooks/usePaymentsIndex";
import { useHistory } from "react-router-dom";
import { PageDataStatus } from "../../../data/models";
import { PaymentFilterButton } from "./PaymentFilter";
import { useAuth } from "../../../data/auth/hooks";
import { DownloadPaymentsButton, DownloadPaymentsButtonExcel } from "./DownloadPaymentsButton";

const PaymentsPage: PageComponent = ({ userAccessRights }) => {
  const pageStyles = usePageStyles();

  const [pageData, dispatchLoadPayments, invalidate] = usePaymentsData();
  const [pageIndex, dispatchSetIndex] = usePaymentIndex();

  React.useEffect(() => {
    if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
      dispatchLoadPayments();
    }
  }, [dispatchLoadPayments, pageData]);
  React.useEffect(() => () => { invalidate() }, [])

  const history = useHistory();

  const [auth] = useAuth();

  if (auth.status !== "successful") {
    return null;
  }

  return (
    <>
      <Grid container className={pageStyles.root}>
        <Grid item xs={12} className={pageStyles.gridItem}>
          <Card>
            <CardHeader
              title={<PageTitle title="paymentTitle" />}
              action={<PaymentAction />}
            />
            <Divider />
            <CardContent>
              <PaymentsTable
                pageIndex={pageIndex}
                pageData={pageData}
                onPageIndexChange={dispatchSetIndex}
                userAccessRights={userAccessRights}
                getData={dispatchLoadPayments}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export const PaymentAction: React.FunctionComponent = () => (
  <Grid container>
    <Grid item>
      <DownloadPaymentsButton />
    </Grid>
    <Grid item>
      <DownloadPaymentsButtonExcel />
    </Grid>
    <Grid item>
      <PaymentFilterButton />
    </Grid>
  </Grid>
);

export default PaymentsPage;
