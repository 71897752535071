import { combineReducers, AnyAction } from "@reduxjs/toolkit";
import mainBoard from "./mainBoard/ordersDataSlice";
import trades from "./trades/tradesDataSlice";
import orderBook from "./orderBook/orderBookDataSlice";
import { resetLoginState } from "../auth/authDataSlice";
import ticker from "./ticker/tickerDataSlice"

const dataTradingReducer = combineReducers({
	mainBoard,
	trades,
	orderBook,
	ticker
});

export default (state: any, action: AnyAction) => {
	if (action.type === resetLoginState.type) {
		return dataTradingReducer(undefined, action);
	} else {
		return dataTradingReducer(state, action);
	}
};
