import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Attribute } from "../types";
import { loadAttribute } from "../thunks/loadAttribute";

export const useDisplayAttribute = (attrId?: string) => {
	const storeAttribute = useSelector<StoreType, Attribute | undefined>(
		store => store.data.listing.attributes.displayed
	);

	const displayedProduct = storeAttribute && storeAttribute.attrId === attrId ? storeAttribute : undefined;

	const displatch = useDispatch();

	React.useEffect(() => {
		if (attrId) {
			displatch(loadAttribute(attrId));
		}
	}, [displatch, attrId]);

	return displayedProduct;
};
