import { ThunkType } from "../../../../core/store/reducers";
import getWhsApi from "../api/getWhs";
import { setDisplayed } from "../warehousesDataReducer";
import { Warehouse } from "../types";

export const loadWhs = (whsId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const whs = await dispatch(getWhs(whsId));

  dispatch(setDisplayed(whs));
};

export const getWhs = (
  whsId: string
): ThunkType<Promise<Warehouse | undefined>> => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getWhsApi(userId, whsId));
};
