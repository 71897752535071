import React from "react";
import { TradeList } from "../../../data/trading/trades/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { useOrderMarketOptionsTranslator } from "../../../data/trading/orders/hooks/useOrderMarketOptions";

export interface DisplayTradeMainAttributesProps {
	trade: TradeList;
}

export const DisplayTradeMainAttributes: React.FunctionComponent<DisplayTradeMainAttributesProps> = ({
	trade,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString, getDateTimeString, getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);
	const marketTranslator = useOrderMarketOptionsTranslator();
	
	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeNo")}</span>
				<span className={style.value}>{trade.tradeNo}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeTime")}</span>
				<span className={style.value}>{getDateTimeString(trade.tradeTime)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("startDeliveryDate")}</span>
				<span className={style.value}>{getDateString(trade.startDeliveryDate, "DD.MM.YYYY")}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeFulfillmentDate")}</span>
				<span className={style.value}>{getDateString(trade.fulfillmentDate, "DD.MM.YYYY")}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tranNoSell")}</span>
				<span className={style.value}>{trade.tranNoSell}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderNoSell")}</span>
				<span className={style.value}>{trade.orderNoSell}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("clientNameSell")}</span>
				<span className={style.value}>{trade.clientNameSell}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tranNoBuy")}</span>
				<span className={style.value}>{trade.tranNoBuy}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderNoBuy")}</span>
				<span className={style.value}>{trade.orderNoBuy}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("clientNameBuy")}</span>
				<span className={style.value}>{trade.clientNameBuy}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeProdName")}</span>
				<span className={style.value}>{`${trade.prodName} (${trade.prodCode})`}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeQty")}</span>
				<span className={style.value}>
					{`${getFormatedNumberString(trade.qty)} ${trade.unitDescr}`}
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradePrice")}</span>
				<span className={style.value}>{getFormatedNumberString(trade.price, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeVatPerc")}</span>
				<span className={style.value}>{`${trade.vatPerc} %`}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradePriceWithVat")}</span>
				<span className={style.value}>{getFormatedNumberString(trade.priceWithVat, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeAmt")}</span>
				<span className={style.value}>{getFormatedNumberString(trade.amt, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeAmtWithVat")}</span>
				<span className={style.value}>{getFormatedNumberString(trade.amtWithVat, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderMarket")}</span>
				<span className={style.value}>{marketTranslator(trade.market)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeStatusDescr")}</span>
				<span className={style.value}>{trade.statusDescr}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeCommPercSell")}</span>
				<span className={style.value}>{`${getFormatedNumberString(trade.commPercSell,true)}%`}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeCommAmtSell")}</span>
				<span className={style.value}>{getFormatedNumberString(trade.commAmtSell,true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeCommPercBuy")}</span>
				<span className={style.value}>{`${getFormatedNumberString(trade.commPercBuy,true)}%`}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeCommAmtBuy")}</span>
				<span className={style.value}>{getFormatedNumberString(trade.commAmtBuy,true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradeDeliveryWhsName")}</span>
				<span className={style.value}>{trade.deliveryWhsName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("tradePayTypeDescr")}</span>
				<span className={style.value}>{trade.payTypeDescr}</span>
			</div>
		</div>
	);
};
