import { useDispatch, useSelector } from "react-redux";
import { printGuaranteeThunk } from "../thunks/printGuaranteeThunk";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { GuaranteeData } from "../types";
import { setPrintGuaranteeFetchState } from "../guaranteesDataSlice";

export const useGuaranteePrinter = (guarantee?: GuaranteeData) => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.clearing.guarantees.printGuaranteeFetchState
	);
	const dispatch = useDispatch();

	const download = React.useCallback(() => {
			dispatch(printGuaranteeThunk(guarantee));
	}, [dispatch,guarantee ]);

	const dispatchSetFetch = React.useCallback(() => {                   //  Ovo da prvo izađe customDialog box
		dispatch(setPrintGuaranteeFetchState({ type: "started" }))
	},	[dispatch] );
	
	return [download, fetchState, dispatchSetFetch] as [typeof download, typeof fetchState, typeof dispatchSetFetch];
};
