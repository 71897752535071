import { ThunkType } from "../../../../core/store/reducers";
import { setOrders, invalidateOrders } from "../orderBookDataSlice";
import { setOrdersIndex } from "../orderBookUXSlice";
import { PageIndex } from "../../../models";
import { getOrdersThunk } from "../../orders/thunks/getOrdersThunk";

export const loadOrderBook = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	const { pageNumber, itemsPerPage } = store.ux.trading.orderBook.index;
	const { filter } = store.ux.trading.orderBook;

	const pagedOrders = await dispatch(getOrdersThunk(pageNumber, itemsPerPage, filter));

	if (pagedOrders) {
		dispatch(setOrders(pagedOrders));
	}
};

export const setOrdersIndexThunk = (index: PageIndex): ThunkType => dispatch => {
	dispatch(setOrdersIndex(index));
	dispatch(invalidateOrders());
};
