import TradesPage from "./TradesPage";
import { NavigationItem } from "../../../core/navigation/types";
import { tradesLocation } from "./Locations";

export const tradesComponents: NavigationItem[] = [
  {
    Component: TradesPage,
    isMenuItem: true,
    path: tradesLocation,
    notifications: true,
  },
];
