import React from "react";
import { Grid } from "@material-ui/core";
import { useCurrentTradesReportFilter } from "../../../../data/reports/securityCommission/securityQuarterly/hooks/useSecurityReportFilter";
import { LocalizationContext } from "../../../../core/localization/Localization";
import {
  MonthSelect,
  YearSelect,
  QuarterSelect,
} from "../../../components/reports/DatePartSelect";

export const QuarterlyTradesReportFilter: React.FunctionComponent = () => {
  const [
    filter,
    setReporthYear,
    setReportQuarter,
  ] = useCurrentTradesReportFilter();
  const { getString } = React.useContext(LocalizationContext);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <YearSelect
          selected={filter.year}
          yearChange={setReporthYear}
          label={getString("monthlyTradeReportReportYear")}
        />
      </Grid>
      <Grid item>
        <QuarterSelect
          selected={filter.qtnumber}
          quarterChange={setReportQuarter}
          label={getString("secCommReportQuarter")}
        />
      </Grid>
    </Grid>
  );
};
