import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { PayTypesG } from "../../models";

const renderLabel = (item: PayTypesG) => `${item.ftDescr} `;

export const usePaymentGuarTypes = () => {
  const payTypesG = useSelector<StoreType, PayTypesG[]>(
    (store) => store.data.initialData.payTypesG
  );

  return [payTypesG, renderLabel] as [typeof payTypesG, typeof renderLabel];
};
