import React from "react";
import { InvoiceListData } from "../../../data/accounting/invoices/types";
import {
  PaginationTable,
  PaginationDataProps,
} from "../../components/common/PagginationTable";
import {
  useInvoiceActions,
  useDeleteInvoiceAction,
} from "./useInvoicesActions";
import { useInvoicesColumns } from "./useInvoicesColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface InvoicesTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}

type InvoicesTablePropsType = InvoicesTableProps &
  PaginationDataProps<InvoiceListData>;

const InvoicesTable: React.FunctionComponent<InvoicesTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = useInvoiceActions();
  const columns = useInvoicesColumns();
  const onDeleteInvoice = useDeleteInvoiceAction();

  return (
    <PaginationTable<InvoiceListData>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
      editable={{
        onRowDelete: onDeleteInvoice,
      }}
    />
  );
};

export default InvoicesTable;
