import { webApi } from "../../../../core/http/httpThunks";
import { Role } from "../types";

export const getRoleApi = (userId: string, roleid: string) =>
	webApi<Role>({
		method: "GET",
		url: "listing/getrole",
		params: {
			userId,
			roleid
		},
	});

export default getRoleApi;
