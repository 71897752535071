import { webApi } from "../../../../core/http/httpThunks";
import { Bank } from "../types";

export const getBankApi = (userId: string, bankid: string) =>
  webApi<Bank>({
    method: "GET",
    url: "listing/getbank",
    params: {
      userId,
      bankid,
    },
  });

export default getBankApi;
