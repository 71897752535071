import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { PayTypes } from "../../models";

const renderLabel = (item: PayTypes) => `${item.ftDescr} `;

export const usePaymentTypes = () => {
  const payTypes = useSelector<StoreType, PayTypes[]>(
    (store) => store.data.initialData.payTypes
  );

  return [payTypes, renderLabel] as [typeof payTypes, typeof renderLabel];
};
