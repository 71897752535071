import TASCostPage from "./TASCostPage";
import { NavigationItem } from "../../../core/navigation/types";
import { transportAndStorageLocation } from "./Locations";

export const transportAndStorageComponents: NavigationItem[] = [
  {
    Component: TASCostPage,
    isMenuItem: true,
    path: transportAndStorageLocation,
  },
];
