import React from "react";
import { TradeList } from "../../../../../data/trading/trades/types";
import { PaginationTable } from "../../../../components/common/PagginationTable";
import { useTradeColumns } from "../../../../../views/trading/trades/useTradeColumns";
import { useClientOverviewIndex } from "../../../../../data/reports/exchbusiness/clientOverview/hooks/useClientOverviewIndex";
import { useTradesData } from "../../../../../data/reports/exchbusiness/clientOverview/hooks/useTradesData";
import { TableType } from "../../../../../data/reports/exchbusiness/clientOverview/types";
import { PageDataStatus } from "../../../../../data/models";
import { useTradeActions } from "./useTradeActions";

const ClientOverviewTradesTable: React.FunctionComponent = () => {
	const columns = useTradeColumns();

	const [data, loadData] = useTradesData();
	const [index, setIndex] = useClientOverviewIndex(TableType.Trades);
	const actions = useTradeActions()

	React.useEffect(() => {
		if (data && data.status === PageDataStatus.DataInvalidated) {
			loadData();
		}
	}, [loadData, data]);

	return (
		<PaginationTable<TradeList>
			getData={loadData}
			pageData={data}
			onPageIndexChange={setIndex}
			pageIndex={index}
			title=""
			columns={columns}
			actions={actions}
			options={{
				actionsColumnIndex: -1,
				search: false,
				toolbar: false,
			}}
		/>
	);
};

export default ClientOverviewTradesTable;
