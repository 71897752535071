import TradesReport from "./TradesReport";
import { NavigationItem } from "../../../core/navigation/types";
import { tradesReportLocation, } from "./Locations";

export const tradesReportComponents: NavigationItem[] = [
	{
		Component: TradesReport,
		isMenuItem: true,
		path: tradesReportLocation,
	},
];
