import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { Currency } from "../../models";

export const getCurrencyLabel = (currency?: Currency) =>
	currency ? `${currency.currName} (${currency.currCode})` : "";

export const useCurrencies = () => {
	const currencies = useSelector<StoreType, Currency[]>(store => store.data.initialData.currencies);

	return [currencies, getCurrencyLabel] as [typeof currencies, typeof getCurrencyLabel];
};

export const useCurrency = (currId?: string) => {
	const [currencies] = useCurrencies();

	const currency = React.useMemo(() => currencies.find(c => c.currId === currId), [currencies, currId]);
	const currencyLabel = React.useMemo(() => getCurrencyLabel(currency), [currency]);

	return [currency, currencyLabel] as [typeof currency, typeof currencyLabel];
};
