import { ThunkType } from "../../../../core/store/reducers";
import deleteBankApi from "../api/deleteBank";
import { setFetchState } from "../banksDataReducer";

export const deleteBank = (bankId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = await dispatch(deleteBankApi(userId, bankId));

  dispatch(setFetchState(result !== undefined ? { type: "successful" } : { type: "error"}));
};
