import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

declare module "@material-ui/core/styles/createMuiTheme" {
	interface Theme {
		navDrawer: {
			fullWidth: number;
			hiddenWidth: number;
			permanentBreakpoint: string;
		};
	}
	interface ThemeOptions {
		navDrawer?: {
			fullWidth: number;
		};
	}
}

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#0091d1",
		},
		secondary: {
			main: "#f58345",
		},
		action:{
			hover: "#0091d1",
		},
		background:{
			default:"#0091d1"
		},
	},
	navDrawer: {
		fullWidth: 240,
	},
});

export const ThemeComponent: React.FunctionComponent = props => {
	theme.navDrawer.permanentBreakpoint = theme.breakpoints.up("md");
	theme.navDrawer.hiddenWidth = theme.spacing(7);

	return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
