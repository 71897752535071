import { QuarterlySecurityReportData, QuarterlySecurityReportFilter } from "../types";
import { User } from "../../../../auth/types";
import { webApi } from "../../../../../core/http/httpThunks";

const getQuarterlySecurityData = (
  user: User,
  filter: QuarterlySecurityReportFilter,
) => {

  return webApi<QuarterlySecurityReportData[]>({
    method: "GET",
    url: "reporting/getsecquartertradinglist",
    params: {
      userid: user.userId,
      year: filter.year,
			qtnumber: filter.qtnumber,
    },
  });
};

export default getQuarterlySecurityData;
