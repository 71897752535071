import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";
import { TradeByProductsReportFilter } from "../types";

export const getTradeByProductsReport = (
  user: User,
  filter: TradeByProductsReportFilter
) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/gettradebyprodreport",
    params: {
      userid: user.userId,
      datefrom: filter.datefrom,
      dateto: filter.dateto,
    },
  });
};

export default getTradeByProductsReport;
