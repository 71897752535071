import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import { DeliveryFilter } from "./types";

type DeliveryPageState = {
  index: PageIndex;
  filter: DeliveryFilter;
};

export const initialState: DeliveryPageState = {
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
  filter: {},
};

const name = "deliveries";

const deliveriesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setDeliveryIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    resetDeliveryIdices: (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }),
    setFilter: (state, action: PayloadAction<DeliveryFilter>) => ({
      ...state,
      filter: action.payload,
    }),
    removeFilter: (state, action: PayloadAction<keyof DeliveryFilter>) => ({
      ...state,
      filter: {
        ...state.filter,
        [action.payload]: undefined,
      },
    }),

  },
  extraReducers: (builder) => {
    builder.addCase(successfulLogin, (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }));
  },
});

export const {
  setDeliveryIndex,
  resetDeliveryIdices,
  setFilter,
  removeFilter,
} = deliveriesSlice.actions;

export default deliveriesSlice.reducer;
