import React from "react";
import { useCtrlGoodsReportDownloader } from "../../../data/clearing/ctrlgoods/hooks/useCtrlGoodsReportDownloader";
import { useCtrlGoodsExcelDownloader } from "../../../data/clearing/ctrlgoods/hooks/useCtrlGoodsExcelDownloader";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import ExcelIcon from "@material-ui/icons/TableChartOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { LocalizationContext } from "../../../core/localization/Localization";

export const DownloadButton: React.FunctionComponent = () => {
	const [downloader, fetchState, hasdata] = useCtrlGoodsReportDownloader();
	const { getString } = React.useContext(LocalizationContext);
	return (
		<>
			<CustomTooltip titleKeyword="exportPDFTooltip">
				<IconButton
					color="secondary"
					component="span"
					onClick={downloader}
					disabled={fetchState.type !== "not-started"  || !hasdata}
				>
					<DownloadIcon />
				</IconButton>
			</CustomTooltip>
			{fetchState.type !== "not-started" ? getString("pleaseWait") : null}
		</>
	);
};

export const DownloadExcelButton: React.FunctionComponent = () => {
	const [downloader, fetchState, hasdata] = useCtrlGoodsExcelDownloader();
	return (
		<CustomTooltip titleKeyword="exportXLSXTooltip">
			<IconButton
				color="secondary"
				component="span"
				onClick={downloader}
				disabled={fetchState.type !== "not-started"  || !hasdata}
				>
				<ExcelIcon />
			</IconButton>
		</CustomTooltip>
	);
};
