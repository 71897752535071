import { ThunkType } from "../../../../../core/store/reducers";
import createAttributeApi from "../../api/attributeValues/createAttributeValue";
import updateAttributeApi from "../../api/attributeValues/updateAttribute";
import { setFetchStateValue } from "../../attributeDataReducer";
import { AttributeValue } from "../../types";

export const upsertAttributeValue = (value: AttributeValue): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchStateValue({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = value.attrValueId
		? await dispatch(updateAttributeApi(userId, value))
		: await dispatch(createAttributeApi(userId, value));

	if (result && result.resCode === "OK") {
		dispatch(setFetchStateValue({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchStateValue({ type: "error", errorCode: result?.resCode }));
	}
};
