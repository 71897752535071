import { setSearchTerm } from "../regionsDataReducer";
import { useSearch } from "../../../../core/util/storeHooks";
import { RegType } from "../../../models";

export const useRegionSearch = (type: RegType) =>
	useSearch(
		s => s.data.listing.regions.searchTerm[type],
		term =>
			setSearchTerm({
				type,
				data: term,
			})
	);
