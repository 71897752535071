import { ThunkType } from "../../../../core/store/reducers";
import getAllCountriesAPI from "../api/getAllCountries";
import { setData } from "../countriesDataReducer";

export const loadAllCountries = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setData());

	const { userId } = store.data.auth.current.user;
	const countris = await dispatch(getAllCountriesAPI(userId));

	dispatch(setData(countris || []));
};
