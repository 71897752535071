import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnnualSecurityReportFilter } from "./types";
import { PageIndex } from "../../../models";

type TradesReportState = {
  filter: AnnualSecurityReportFilter;
  index: PageIndex;
};

const now = new Date();
export const initialState: TradesReportState = {
  filter: {
    year: now.getFullYear(),
  },
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
};

const name = "annualSecurity";

const annualSecuritySlice = createSlice({
  name,
  initialState,
  reducers: {
    setIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    setReportYear: (state, action: PayloadAction<number>) => ({
      ...state,
      filter: {
        ...state.filter,
        year: action.payload,
      },
    }),
  },
});

export const { setReportYear, setIndex } = annualSecuritySlice.actions;

export default annualSecuritySlice.reducer;
