import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useProductGroupData } from "../../../data/listing/productGroups/hooks/useProductGroupData";
import { ProductGroupData } from "../../../data/listing/productGroups/types";
import { useProductGroupColumns } from "./useProductGroupColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useProductGroupActions, useDeleteRoleAction } from "./useProductGroupActions";

export interface ProductGroupsTableProps {
	userAccessRights: AccessRights;
}

type ProductGroupsTableTablePropsType = ProductGroupsTableProps & TableDataProps<ProductGroupData>;

export const ProductGroupsTable: React.FunctionComponent<ProductGroupsTableTablePropsType> = ({
	userAccessRights,
	...tableProps
}) => {
	const [data, loadAll] = useProductGroupData();

	const columns = useProductGroupColumns();
	const actions = useProductGroupActions(userAccessRights);
	const onDeleteGroup = useDeleteRoleAction();

	return (
		<Table<ProductGroupData>
			{...tableProps}
			getData={loadAll}
			immutableData={data}
			title=""
			columns={columns}
			actions={actions}
			options={{
				toolbar: false,
				pageSize: 10,
				actionsColumnIndex: -1,
			}}
			editable={userAccessRights !== AccessRights.ReadOnly ? {
				onRowDelete: onDeleteGroup,
			} : {}}
		/>
	);
};

export default ProductGroupsTable;
