import React from "react";
import { useEditRegionState } from "../../../data/listing/regions/hooks/useEditRegionState";
import { useRegionValidator } from "../../../data/listing/regions/hooks/useRegionValidator";
import { useRegionUpsert } from "../../../data/listing/regions/hooks/useRegionUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { Region } from "../../../data/listing/regions/types";
import { StringInput } from "../../components/common/StringInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { Status, RegType } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { getRegionsLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { StoreType } from "../../../core/store/reducers";
import { useSelector } from "react-redux";
import { CountriesSelect } from "../../components/common/CountriesSelect";
import { RegionSelect } from "../../components/listing/RegionSelect";

export interface EditRegionInfo {
	regId?: string;
}

export interface EditRegionProps extends RouteComponentProps<EditRegionInfo> {}

export const EditRegionPage: React.FunctionComponent<EditRegionProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();
	const regtype = useSelector<StoreType, RegType | undefined>(s => s.data.listing.regions.regType);

	const [region, editRegion, changed] = useEditRegionState(regtype, props.match.params.regId);

	const [validationState, validate] = useRegionValidator();
	const [fetchState, upsert] = useRegionUpsert(regtype);
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editRegion(event.target.name as any, event.target.value);
		},
		[editRegion]
	);

	const backLocation = getRegionsLocation(regtype);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(backLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(region);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [region, validate, fetchState, history, upsert, backLocation]);


	if (!region || !regtype) {
		return null;
	}
	const editDisplayName =  regtype === RegType.TopRegion ? "regEdit" : "regEditDistrict";
	const createDisplayName =  regtype === RegType.TopRegion ? "regCreate" : "regCreateDistrict";
	const pageTitle = region.regId ? editDisplayName : createDisplayName;

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={backLocation} />} />
					<Divider />
					<CardContent>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={4}>
								<StringInput<Region>
									property="regName"
									item={region}
									validationState={validationState}
									onChange={handleChange}
									label={getString("regName")}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							{regtype === RegType.Region && (
								<Grid item xs={3}>
									<RegionSelect
										regionType={RegType.TopRegion}
										label={getString("parentRegName")}
										predicate={reg => reg.regId === region.parentRegId}
										onChangeItem={reg => editRegion("parentRegId", reg?.regId || "")}
										required
										error={
											validationState.parentRegId !== undefined &&
											!validationState.parentRegId
										}
									/>
								</Grid>
							)}
							{regtype === RegType.SubRegion && (
								<Grid item xs={3}>
									<RegionSelect
										regionType={RegType.Region}
										label={getString("parentRegName")}
										predicate={reg => reg.regId === region.parentRegId}
										onChangeItem={reg => editRegion("parentRegId", reg?.regId || "")}
										required
										error={
											validationState.parentRegId !== undefined &&
											!validationState.parentRegId
										}
									/>
								</Grid>
							)}
							{regtype === RegType.TopRegion && (
								<Grid item xs={3}>
									<CountriesSelect
										label={getString("countryReg")}
										predicate={country => country.cntId === region.country}
										onChangeItem={country => editRegion("country", country?.cntId || "")}
										required
										error={
											validationState.country !== undefined && !validationState.country
										}
									/>
								</Grid>
							)}
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={3}>
								<BoolInput<Region>
									property="status"
									item={region}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("cliStatus")}
								/>
							</Grid>
						</Grid>

						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
