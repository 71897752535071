import { ThunkType } from "../../../../../core/store/reducers";
import getClientAccountApi from "../../api/clientAccount/getClientAccount";
import { ClientAccount } from "../../types";

export const getClientAccount = (
	clientAccountId: string
): ThunkType<Promise<ClientAccount | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getClientAccountApi(userId, clientAccountId));
};
