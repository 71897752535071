import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { PaymentListData } from "../../../data/clearing/payments/types";
import { PaymentData } from "../../../data/clearing/payments/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewPayment } from "./DisplayPayment";
import { useHistory } from "react-router-dom";
import { getPaymentEditLocation } from "../payments/Locations";
import { DisplayPaymentMainAttributes } from "../payments/DisplayPaymentMainAttributes";
import { usePaymentDelete } from "../../../data/clearing/payments/hooks/usePaymentDelete";

export function usePaymentsActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowPayment = useViewPayment();
  const onEditPayment = useEditPaymentAction();

  return useMemo(() => {
    const result: MaterialTableProps<PaymentListData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) => onShowPayment(rowData as PaymentListData),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) => onEditPayment(rowData as PaymentListData),
      },
    ];
    return result;
  }, [onShowPayment, onEditPayment, getString]);
}

export const useDeletePaymentAction = () => {
  const [, deletePayment] = usePaymentDelete();
  return React.useCallback(
    async (payments: PaymentListData) => {
      if (payments.payId) {
        await deletePayment(payments.payId);
      }
    },
    [deletePayment]
  );
};

export const useEditPaymentAction = () => {
  const history = useHistory();
  return React.useCallback(
    (payments: PaymentListData) =>
      history.push(getPaymentEditLocation(payments.payId)),
    [history]
  );
};
