import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useProductData } from "../../../data/listing/products/hooks/useProductData";
import { ErrorCodeExceptions, ProductList } from "../../../data/listing/products/types";
import { useProductColumns } from "./useProductColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useProductActions, useDeleteClientAction } from "./useProductActions";
import { CustomDialog } from "../../components/common/CustomDialog";
import { LocalizationContext } from "../../../core/localization/Localization";

export interface ProductTableProps {
	userAccessRights: AccessRights;
}

type ProductTablePropsType = ProductTableProps & TableDataProps<ProductList>;

export const ProductTable: React.FunctionComponent<ProductTablePropsType> = ({
	userAccessRights,
	...tableProps
}) => {
	const [data, loadAll] = useProductData();
	const { getString } = React.useContext(LocalizationContext);

	const columns = useProductColumns();
	const actions = useProductActions(userAccessRights);
	const [fetchState, onDeleteClient, resetState] = useDeleteClientAction();

	return (
		<>
			<Table<ProductList>
				{...tableProps}
				getData={loadAll}
				immutableData={data}
				title=""
				columns={columns}
				actions={actions}
				options={{
					toolbar: false,
					pageSize: 10,
					actionsColumnIndex: -1,
				}}
				editable={userAccessRights !== AccessRights.ReadOnly ? {
					onRowDelete:  onDeleteClient,
				  }: {}}
			/>
			<CustomDialog
				open={
					fetchState.type === "error" &&
					fetchState.errorCode === ErrorCodeExceptions.ReferenceErrorCode
				}
				title={getString("errorTitle")}
				message={getString("errorProductDelete")}
				closeLabel={getString("errorConfirm")}
				onClose={resetState}
			/>
		</>
	);
};

export default ProductTable;
