import { useDispatch, useSelector } from "react-redux";
import { downloadRefPriceButton } from "../thunks/loadRefPriceButton";
import { resetData } from "../tradingDataSlice";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { TradingData, TradingDataFilter } from "../types";
import moment from "moment";

var tomorow = moment().add(1, "day").toDate();

export const useRefPriceButton = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(s => s.data.clearing.tradingData.fetchState);

	const data = useSelector<StoreType, TradingData[] | undefined>(
		s => s.data.clearing.tradingData.currentPage?.currentPageData
	);

	const filter = useSelector<StoreType, TradingDataFilter | undefined>(
		s => s.ux.clearing.tradingData.filter
	);

  
   	const dateOk = filter?.datefrom === filter?.dateto
   	const reportDateOk = filter?.reportDate && moment(tomorow).isAfter(filter.reportDate);

	const hasData = (data !== undefined && data !== null && data.length > 0) || !dateOk || !reportDateOk;

	const dispatch = useDispatch();
	const download = React.useCallback(() => {
		dispatch(downloadRefPriceButton());
	}, [dispatch]);

	const dispatchResetData = React.useCallback(() => {
		dispatch(resetData());
	}, [dispatch]);

	return [download, fetchState, dispatchResetData, hasData] as [
		typeof download,
		typeof fetchState,
		typeof dispatchResetData,
		typeof hasData
	];
};
