import React from "react";
import { Switch, Route, useHistory  } from "react-router-dom";
import {
  useNavigationItems,
  useLinkMenuItems,
} from "./hooks/useNavigationItems";
import MainBoard from "../trading/mainBoard/MainBoard"
import MainBoardM from "../trading/mainpanelm/MainBoard"
import { useAuth } from "../../data/auth/hooks";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const PageRouterSwitch = () => {
  const linkMenuItems = useLinkMenuItems();
  const editComponents = useNavigationItems(false);
  const [auth] = useAuth();

  const theme = useTheme();
	const source = useMediaQuery(theme.breakpoints.only('xs')) ? "M" : "";

  if (auth.status !== "successful") {
		return null;
	}


  return (
    <Switch>
      {editComponents.map((Item) => (
        <Route key={Item.path} path={Item.path} component={Item.Component} />
      ))}
      {linkMenuItems.map((Link) => (
        <Route key={Link.key} path={Link.path}>
          <Link.Component userAccessRights={Link.accessRights} />
        </Route>
      ))}
      {(auth.user.roleCode === "MEMTRADER" || auth.user.roleCode === "MEMMANAGER"  ||  auth.user.roleCode === "MEMCONTACT" ) && source!=="M" && (
      <Route path="/" exact component={MainBoard} />
      )}
      {(auth.user.roleCode === "MEMTRADER" || auth.user.roleCode === "MEMMANAGER"  ||  auth.user.roleCode === "MEMCONTACT" ) && source==="M" && (
      <Route path="/" exact component={MainBoardM} />
      )}

    </Switch>
  );
};
