import React from "react";
import { useDisplayProduct } from "../../../data/listing/products/hooks/useDisplayProduct";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { LocalizationContext } from "../../../core/localization/Localization";
import { ProductList } from "../../../data/listing/products/types";
import { DisplayProductBasicInfo } from "./DisplayProductBasicInfo";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useToggleStateDictionary } from "../../../core/util/hooks";
import { DisplayAttributes } from "./attributes/DisplayAttributes";

enum AccordionNames {
	BasicInfo = "basicInfo",
	Attributes = "attributes",
}

const accordionNames = Object.values(AccordionNames);

export interface DisplayEmployeeProps {
	id?: string;
}

export const DisplayProduct: React.FunctionComponent<DisplayEmployeeProps> = ({ id }) => {
	const product = useDisplayProduct(id);
	const { getString } = React.useContext(LocalizationContext);

	const [open, toggleOpen] = useToggleStateDictionary(accordionNames, [AccordionNames.BasicInfo]);

	return !product || !product.prodId ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel
				expanded={open[AccordionNames.BasicInfo]}
				onChange={toggleOpen[AccordionNames.BasicInfo]}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{getString("tableBasciInfo")}</Typography>
				</ExpansionPanelSummary>
				<DisplayProductBasicInfo product={product} />
			</ExpansionPanel>
			<ExpansionPanel
				expanded={open[AccordionNames.Attributes]}
				onChange={toggleOpen[AccordionNames.Attributes]}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{getString("productAttributeTitle")}</Typography>
				</ExpansionPanelSummary>
				<DisplayAttributes productId={product.prodId} />
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewProduct = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(product: ProductList) => showWithDrawer(<DisplayProduct id={product.prodId} />),
		[showWithDrawer]
	);
};
