import React from "react";
import { useDispatch } from "react-redux";
import { ClientAccountList } from "../../types";
import { getClientAccounts } from "../../thunks/clientAccount/loadClientAccounts";

export const useClientAccounts = (clientId?: string) => {
	const displatch = useDispatch();
	const [accounts, setAccounts] = React.useState<ClientAccountList[] | undefined>();

	const loadClientAccounts = React.useCallback(async () => {
		if (clientId) {
			const loadedAccounts = (await displatch(getClientAccounts(clientId))) as any;
			setAccounts(loadedAccounts || []);
		}
	}, [displatch, clientId])

	React.useEffect(() => {
		loadClientAccounts();
	}, [loadClientAccounts]);

	return [accounts, loadClientAccounts] as [typeof accounts, typeof loadClientAccounts]
};
