import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useCommissionData } from "../../../data/listing/commissions/hooks/useCommissionData";
import { Commission } from "../../../data/listing/commissions/types";
import { useProductColumns } from "./useCommissionColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useCommissionActions, useDeleteCommissionAction } from "./useCommissionActions";

export interface CommissionTableProps {
	userAccessRights: AccessRights;
}

type CommissionTablePropsType = CommissionTableProps & TableDataProps<Commission>;

export const CommissionTable: React.FunctionComponent<CommissionTablePropsType> = ({
	userAccessRights,
	...tableProps
}) => {
	const [data, loadAll] = useCommissionData();

	const columns = useProductColumns();
	const actions = useCommissionActions(userAccessRights);
	const onDeleteClient = useDeleteCommissionAction();

	return (
		<Table<Commission>
			{...tableProps}
			getData={loadAll}
			immutableData={data}
			title=""
			columns={columns}
			actions={actions}
			options={{
				toolbar: false,
				pageSize: 10,
				actionsColumnIndex: -1,
			}}
			editable={userAccessRights !== AccessRights.ReadOnly ? {
				onRowDelete: onDeleteClient,
			} : {}}
		/>
	);
};

export default CommissionTable;
