import { ViewOrder, OrderMarkt } from "./trading/orders/types";
import { User } from "./auth/types";
import { Country } from "./listing/countries/types";
import { AccessRights } from "../core/navigation/types";
import { ProductList } from "./listing/products/types";
import { ClientListData } from "./listing/clients/types";
import { Employee } from "./listing/employees/types";

export enum OrderSide {
  Sell = "SELL",
  Buy = "BUY",
}

export interface CompanyMember {
  id: string;
  name: string;
}

export enum PageDataStatus {
  DataCurrent,
  DataInvalidated,
}
export interface PageData<T> {
  currentPageData: T[];
  totalCount: number;
  status: PageDataStatus;
}

export interface PageIndex {
  pageNumber: number;
  itemsPerPage: number;
}

export interface Product {
  prodId: string;
  prodName: string;
  unit: string;
  hasYear: boolean;
  vatRate: number;
  prodAtrList: ProductAttr[];
}

export interface ProductAttr {
  prodAtrId: string;
  prodAtrName: string;
  prodAtrType: string;
  mandatory: boolean;
  comparator: string;
}

export enum OrdersPageType {
  Listing = "listing",
  User = "user",
  Archive = "archive",
}

export interface Report {
  id: string;
  name: string;
}

export type PendingValueState = "invalid" | "valid" | "initial";

export type PendingValue<T> = {
  status: PendingValueState;
  value?: T;
};

export type PendingOrderType = {
  [field in keyof ViewOrder]: PendingValue<ViewOrder[field]>;
};

export enum Gender {
  Male = 1,
  Female = 2,
}

export interface City {
  cityId: string;
  cityName: string;
  zipCode: string;
  region?: string;
  regionId?: string;
}

export interface ClientType {
  ftValue: number;
  ftDescr: string;
}

export interface Bank {
  bankId: string;
  bankCode: string;
  bankName: string;
}

export interface Currency {
  currId: string;
  currCode: string;
  currName: string;
}

export interface ProductAttribute {
  attrId: string;
  attrName: string;
  attrType: string;
  mandatory: ProductAttributeManditory;
  comparator: string;
}

export enum ProductAttributeManditory {
  True = 1,
  False = 0,
}

export interface ProductGroup {
  prodGroupId: string;
  prodGroupName: string;
}

export interface Unit {
  ftValue: number;
  ftDescr: string;
}

export interface VatRate {
  ftValue: number;
  ftDescr: string;
  rate: number;
}

export interface Commissions {
  commId: string;
  commCode: string;
  commName: string;
  commType: number;
}

export interface Region {
  regId: string;
  regName: string;
  parentRegId: string;
  country: string;
}

export interface CommissionType {
  ftDescr: string;
  ftValue: number;
}

export interface PersonRole {
  ftValue: number;
  ftDescr: string;
}

export interface UserMenuItem {
  level: number;
  miId: string;
  miName: string;
  parentId: string;
  path: string;
  readOnly: AccessRights;
}

export interface OrderRecievedMethod {
  ftValue: number;
  ftDescr: string;
  descrAbr: string;
}

export interface CheckPoint {
  ftValue: number;
  ftDescr: string;
}

export interface CheckPointPayer {
  ftValue: number;
  ftDescr: string;
}

export interface Franco {
  ftValue: number;
  ftDescr: string;
  ftDescrLong: String;
  ftType: number;
}

export interface OrderStatus {
  ftValue: number;
  ftDescr: string;
}

export interface TradeStatus {
  ftValue: number;
  ftDescr: string;
}

export interface PayTypes {
  ftValue: number;
  ftDescr: string;
  ftSign: number;
  hasOrdTran: number;
}

export interface CheckDoneByList {
  ftValue: number;
  ftDescr: string;
}

export interface PayTypesG {
  ftValue: number;
  ftDescr: string;
  ftSign: number;
  hasOrdTran: number;
}

export interface AttributesValue {
  attrId: string;
  attrVal: string;
  attrValId: string;
  attrValName: string;
}

export interface OrderNote {
  ftValue: number;
  ftDescr: string;
}

export interface InvTypes {
  ftValue: number;
  ftDescr: string;
}

export interface DispSitList {
  ftValue: number;
  ftDescr: string;
}

export interface QualityCtrlList {
  ftValue: number;
  ftDescr: string;
}

export interface DeliveryStatuses {
  ftValue: number;
  ftDescr: string;
}

export interface Ports {
  cityId: string;
  cityName: string;
  zipCode: string;
  region?: string;
  regionId?: string;
}

export interface Pay_Type {
  payTypeId?: string; 
  payTypeDescr: string; 		
}

export interface ContractType {
  ftValue: number;
  ftDescr: string;
}

export interface Guarantees {
  ftValue: number;
  ftDescr: string;
}

export interface LoginResponse {
  uData: User;
  attributes: ProductAttribute[];
  productsGroups: ProductGroup[];
  products: Product[];
  cities: City[];
  clientTypes: ClientType[];
  banks: Bank[];
  currencies: Currency[];
  units: Unit[];
  vatRates: VatRate[];
  commissions: Commissions[];
  regions: Region[];
  subRegions: Region[];
  topRegions: Region[];
  countries: Country[];
  commissionTypes: CommissionType[];
  personRoles: PersonRole[];
  userMenu: UserMenuItem[];
  orderReceiveMethods: OrderRecievedMethod[];
  checkPointPayers: CheckPointPayer[];
  checkPoints: CheckPoint[];
  francoList: Franco[];
  orderStatuses: OrderStatus[];
  tradeStatuses: TradeStatus[];
  payTypes: PayTypes[];
  checkDoneByList: CheckDoneByList[];
  payTypesG: PayTypesG[];
  attributesValues: AttributesValue[]
  orderNotes: OrderNote[]
  invTypes: InvTypes[];
  dispSitList: DispSitList[];
  qualityCtrlList: QualityCtrlList[];
  deliveryStatuses: DeliveryStatuses[];
  ports: Ports[];
  pay_Types: Pay_Type[];
  contractTypes: ContractType[];
  guarantees: Guarantees[];
  actStatuses: ActStatuses[];
}

  

export enum RegType {
  TopRegion = 10,
  Region = 20,
  SubRegion = 30,
}

export enum Status {
  Active = 1,
  Inactive = 0,
}

export type FetchStateTypeType =
  | "not-started"
  | "started"
  | "successful"
  | "error";

export type FetchStateType<TSucessResult = any> =
  | { type: "not-started" }
  | { type: "started" }
  | { type: "successful"; result?: TSucessResult }
  | { type: "error"; errorCode?: string };

export const createFetchState = (
  type: FetchStateTypeType,
  payload?: any
): FetchStateType => {
  switch (type) {
    case "started":
    case "not-started":
      return {
        type,
      };
    case "successful":
      return {
        type,
        result: payload,
      };
    case "error":
      return {
        type,
        errorCode: payload,
      };
  }
};

export const getFetchStateChange = (
  current: FetchStateType,
  change: FetchStateType
) =>
  current.type !== "started" || change.type !== "not-started"
    ? change
    : current;

export interface OrderFilter {
  product?: Partial<ProductList>,
  fromDate?: string,
  toDate?: string,
  side?: OrderSide,
  orderStatus?: Partial<OrderStatus>,
  client?: Partial<ClientListData>;
  market?: OrderMarkt;
  employee?: Employee
}

export enum AlarmType {
  Cross = "Cross",
  Client = "Client",
  Product = "Product",
  ProductClient = "ProductClient",
}

export interface ActStatuses {
  ftValue: number;
  ftDescr: string;
}
