import React from "react";
import { useAntiMnyLndCommColumns } from "./useAntiMnyLndCommReportColumns";
import { AccessRights } from "../../../core/navigation/types";
import { AntiLaunderingReportData } from "../../../data/reports/amlaundering/types";
import { ControledTable } from "../../components/common/ControledTable";
import { useAntiMnyLndCommReportData } from "../../../data/reports/amlaundering/hooks/useAntiMnyLndCommReportData";
import { useAntiMnyLndCommReportIndex } from "../../../data/reports/amlaundering/hooks/useAntiMnyLndCommReportIndex";

export interface AntiMnyLndCommTableProps {
  userAccessRights: AccessRights;
}

type OrderTablePropsType = AntiMnyLndCommTableProps;

export const AntiMnyLndCommReportTable: React.FunctionComponent<OrderTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useAntiMnyLndCommColumns();
  const [data, load] = useAntiMnyLndCommReportData();
  const [index, setIndex] = useAntiMnyLndCommReportIndex();

  return (
    <ControledTable<AntiLaunderingReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default AntiMnyLndCommReportTable;
