import { NavigationItem } from "../../../core/navigation/types";
import { EditEmployeePage } from "./EditEmployeePage";
import EmployeePage from "./EmployeesPage";
import { employeeLocation, editRouter } from "./Locations";
export const employeeComponents: NavigationItem[] = [
	{
		Component: EmployeePage,
		isMenuItem: true,
		path: employeeLocation,
	},
	{
		Component: EditEmployeePage,
		isMenuItem: false,
		path: editRouter,
	},
];
