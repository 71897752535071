import { ThunkType } from "../../../../core/store/reducers";
import { getSimpleOrdersApi } from "../api/getSimpleOrders";
import { OrderSimpleData, SimpleOrderFilter } from "../types";

export const getSimpleOrdersThunk = (
	filter: SimpleOrderFilter
): ThunkType<Promise<OrderSimpleData[] | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const user = store.data.auth.current.user;
	const ordersResponse = await dispatch(getSimpleOrdersApi(user.userId, filter));

	if (!ordersResponse || ordersResponse.resCode !== "OK") {
		return undefined;
	}

	return ordersResponse.obj;
};
