import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageData, PageDataStatus, FetchStateType, getFetchStateChange } from "../../../models";
import { ViewOrder } from "../../../trading/orders/types";
import { TradeList } from "../../../trading/trades/types";
import { PaymentListData } from "../../../clearing/payments/types";
import { DeliveryListData } from "../../../clearing/delivery/types";

type ClientOverviewData = {
	currentPageDelivery?: PageData<DeliveryListData>;
	currentPagePayment?: PageData<PaymentListData>;
	currentPageOrder?: PageData<ViewOrder>;
	currentPageTrade?: PageData<TradeList>;
	fetchState: FetchStateType;
	cancelFetchState: FetchStateType;
	downloadReportFetchState: FetchStateType;
};

const initialState: ClientOverviewData = {
	cancelFetchState: { type: "not-started" },
	fetchState: { type: "not-started" },
	downloadReportFetchState: { type: "not-started" },
};

const name = "clientOverview";

const clientOverviewSlice = createSlice({
	name,
	initialState,
	reducers: {
		setCOPaymentsData: (state, action: PayloadAction<PageData<PaymentListData>>) => ({
			...state,
			currentPagePayment: action.payload,
		}),
		setCODeliveriesData: (state, action: PayloadAction<PageData<DeliveryListData>>) => ({
			...state,
			currentPageDelivery: action.payload,
		}),
		setCOOrdersData: (state, action: PayloadAction<PageData<ViewOrder>>) => ({
			...state,
			currentPageOrder: action.payload,
		}),
		setCOTradesData: (state, action: PayloadAction<PageData<TradeList>>) => ({
			...state,
			currentPageTrade: action.payload,
		}),

		invalidateData: state => {
			let newState = state;
			if (state.currentPageTrade) {
				newState = {
					...newState,
					currentPageTrade: {
						...state.currentPageTrade,
						status: PageDataStatus.DataInvalidated,
					},
				};
			}

			if (state.currentPageOrder) {
				newState = {
					...newState,
					currentPageOrder: {
						...state.currentPageOrder,
						status: PageDataStatus.DataInvalidated,
					},
				};
			}

			if (state.currentPageDelivery) {
				newState = {
					...newState,
					currentPageDelivery: {
						...state.currentPageDelivery,
						status: PageDataStatus.DataInvalidated,
					},
				};
			}

			if (state.currentPagePayment) {
				newState = {
					...newState,
					currentPagePayment: {
						...state.currentPagePayment,
						status: PageDataStatus.DataInvalidated,
					},
				};
			}

			return newState;
		},

		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			fetchState: action.payload,
		}),
		setCancelTradingDataFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			cancelFetchState: action.payload,
		}),
		resetData: state => ({
			...state,
			downloadReportFetchState: initialState.downloadReportFetchState,
			fetchState: initialState.fetchState,
		}),
	},
});

export const {
	setCOPaymentsData,
	setCODeliveriesData,
	setCOOrdersData,
	setCOTradesData,
	invalidateData,
	setFetchState,
	setCancelTradingDataFetchState,
	resetData,
} = clientOverviewSlice.actions;

export default clientOverviewSlice.reducer;
