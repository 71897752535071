import { NavigationItem } from "../../../core/navigation/types";
import TradingDataPage from "./TradingDataPage";
import { EditTradingDataPage } from "./EditTradingDataPage";
import { tradingDataLocation, editRouter } from "./Locations";
export const tradingDataComponents: NavigationItem[] = [
  {
    Component: TradingDataPage,
    isMenuItem: true,
    path: tradingDataLocation,
  },
  {
    Component: EditTradingDataPage,
    isMenuItem: false,
    path: editRouter,
  },
];
