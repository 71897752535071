import { ProductGroupData, ProductGroupValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const roleValidationState: ProductGroupValidationState = {
	prodGroupName: true,
	prodGroupNameEn: true,
};

export const useProductGroupValidator = createUseValidator<ProductGroupData, ProductGroupValidationState>(
	roleValidationState
);
