import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";

export const printGuarantee = (user: User, payid: string) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getguaranteeconfreport",
		params: {
			userid: user.userId,
			payid,
		},
	});
};

export default printGuarantee;
