import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { ComReviewReportFilter } from "../../../../data/reports/exchbusiness/commReview/types";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { useOrderSideOptionsTranslator } from "../../../../data/trading/orders/hooks/useOrderSideOptions";

export const CommReviewFilterChips: React.FunctionComponent<CommReviewFilterChipsProps> = ({
  filter,
  removefilter,
}) => {
  const { getDateString, getString } = React.useContext(LocalizationContext);
  const sideTranslator = useOrderSideOptionsTranslator();

  return (
    <Grid container spacing={1}>
      {filter.datefrom && (
        <Grid item>
          <Chip
            label={`${getString("filterFrom")}: ${getDateString(
              new Date(filter.datefrom)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removefilter("datefrom")}
          />
        </Grid>
      )}
      {filter.dateto && (
        <Grid item>
          <Chip
            label={`${getString("filterTo")}: ${getDateString(
              new Date(filter.dateto)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removefilter("dateto")}
          />
        </Grid>
      )}
      {filter.client && (
        <Grid item>
          <Chip
            label={filter.client.cliName}
            color="secondary"
            variant="outlined"
            onDelete={() => removefilter("client")}
          />
        </Grid>
      )}

      {filter.product && (
        <Grid item>
          <Chip
            label={filter.product.prodName}
            color="secondary"
            variant="outlined"
            onDelete={() => removefilter("product")}
          />
        </Grid>
      )}
      {filter.broker && (
        <Grid item>
          <Chip
            label={`${filter.broker.firstName} ${filter.broker.lastName}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removefilter("broker")}
          />
        </Grid>
      )}

      {filter.side && (
        <Grid item>
          <Chip
            label={sideTranslator(filter.side)}
            color="secondary"
            variant="outlined"
            onDelete={() => removefilter("side")}
          />
        </Grid>
      )}
    </Grid>
  );
};

interface CommReviewFilterChipsProps {
  filter: ComReviewReportFilter;
  removefilter: (type: keyof ComReviewReportFilter) => void;
}
