import { ThunkType } from "../../../../../core/store/reducers";
import { getDeliveryReport } from "../api/getDeliveryReport";
import fileDownload from "js-file-download";
import { setFetchState } from "../clientOverviewDataSlice"

export const downloadDeliveryReport = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { filter } = store.ux.reports.exchbusiness.clientOverview;

	console.log(filter)
	const user = store.data.auth.current.user;
	dispatch(setFetchState({ type: "started"}))
	const ordersReport = await dispatch(getDeliveryReport(user, filter));
	if (ordersReport) {
		fileDownload(new Blob([ordersReport as any], { type: "application/pdf" }), "Delivery.pdf");
	}
	dispatch(setFetchState({ type: "not-started"}))
};
