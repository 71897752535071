import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { ProductSelectData } from "../types";
import { loadProductsForSelect } from "../thunks/loadProductsForSelect";
import { Status } from "../../../models";

export const useProductSelectData = (status?: Status) => {
	const selectProducts = useSelector<StoreType, ProductSelectData[] | undefined | "loading">(
		store => store.data.listing.products.productsForSelect
    );

    const displatch = useDispatch();

/*	React.useEffect(() => {
		if (!selectProducts) {
			displatch(loadProductsForSelect(status));
		}
    }, [displatch, selectProducts,status]);  */

	React.useEffect(() => {
			displatch(loadProductsForSelect(status));
    }, [displatch, status]);  


	return selectProducts !== "loading" ? selectProducts : undefined;
};
