import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { CurrencyRate } from "../../../data/listing/curencyRates/types"
import moment from "moment";

export function useCurrencyRatesColumns() {
	const { getString, getDateString, getFormatedNumberString } = useContext(LocalizationContext);
	return useMemo(
		() => [
			{
				title: getString("curRateDate"),
				field: "Date",
				render: rate => getDateString(rate.date),
				customSort: (a, b) => moment(a.date).diff(b.date),
			},
			{
				title: getString("curRateMid"),
				render: rate => getFormatedNumberString(rate.midRate, true),
				customSort: (a, b) => (a.midRate || 0) - (b.midRate || 0),
			},
			{
				title: getString("curRateBuy"),
				render: rate => getFormatedNumberString(rate.buyRate, true),
				customSort: (a, b) => (a.buyRate || 0) - (b.buyRate || 0),
			},
			{
				title: getString("curRateSell"),
				render: rate => getFormatedNumberString(rate.sellRate, true),
				customSort: (a, b) => (a.sellRate || 0) - (b.sellRate || 0),
			},
		],
		[getString, getDateString, getFormatedNumberString]
	) as Column<CurrencyRate>[];
}
