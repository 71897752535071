import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../models";
import { CreateInvoicesTranData } from "./types";

type CreateInvoicesTranReportData = {
  data?: CreateInvoicesTranData[];
  downloadReportFetchState: FetchStateType;
};

const initialState: CreateInvoicesTranReportData = {
  downloadReportFetchState: { type: "not-started" },
};

const name = "createInvoices";

const createInvoicesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<CreateInvoicesTranData[] | undefined>
    ) => ({
      ...state,
      data: action.payload,
    }),
    invalidateCreateInvoice: (state) => {
      return {
        ...state,
        data: undefined,
      };
    },
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      downloadReportFetchState: action.payload,
    }),
    resteData: (state) => ({
      ...state,
      data: initialState.data,
      downloadReportFetchState: initialState.downloadReportFetchState,
    }),
  },
});

export const {
  setData,
  invalidateCreateInvoice,
  setFetchState,
  resteData,
} = createInvoicesSlice.actions;

export default createInvoicesSlice.reducer;
