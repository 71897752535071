import { ThunkType } from "../../../../core/store/reducers";
import getAllProductsAPI from "../api/getAllProducts";
import { setData } from "../productsDataReducer";

export const loadAllProducts = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setData());

	const { userId } = store.data.auth.current.user;
	const products = await dispatch(getAllProductsAPI(userId));

	dispatch(setData(products || []));
};
