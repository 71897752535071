import { webApi } from "../../../../core/http/httpThunks";
import { Region } from "../types";

export const getAllRegionApi = (userId: string, regtype: number) =>
  webApi<Region[]>({
    method: "GET",
    url: "listing/getreglist",
    params: {
      userId,
      regtype,
    },
  });

export default getAllRegionApi;
