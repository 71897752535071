import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useWhsData } from "../../../data/listing/warehouses/hooks/useWhsData";
import { Warehouse } from "../../../data/listing/warehouses/types";
import { useWhsColumns } from "./useWhsColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useWhsActions, useDeleteWhsAction } from "./useWhsActions";

export interface WhsTableProps {
  userAccessRights: AccessRights;
}

type WhsTablePropsType = WhsTableProps & TableDataProps<Warehouse>;

export const WhsTable: React.FunctionComponent<WhsTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const [data, loadAll] = useWhsData();

  const columns = useWhsColumns();
  const actions = useWhsActions(userAccessRights);
  const onDeleteWhs = useDeleteWhsAction();

  return (
    <Table<Warehouse>
      {...tableProps}
      getData={loadAll}
      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        toolbar: false,
        pageSize: 10,
        actionsColumnIndex: -1,
      }}
      editable={userAccessRights !== AccessRights.ReadOnly ?{
        onRowDelete: onDeleteWhs,
      } : {}}
    />
  );
};

export default WhsTable;
