import { webApiWithResponse } from "../../../../../core/http/httpThunks";
import { AttributeValue } from "../../types";

export const updateAttributeApi = (userId: string, value: AttributeValue) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/updattrval",
		params: {
			userId
		},
		data: value
	});

export default updateAttributeApi;
