import { webApi } from "../../../../../core/http/httpThunks";
import { ClientContract } from "../../types";

export const getClientContractApi = (userId: string, clicontrid: string) =>
	webApi<ClientContract>({
		method: "GET",
		url: "listing/getclicontr",
		params: {
			userId,
			clicontrid
		},
	});

export default getClientContractApi;
