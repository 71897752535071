import React from "react";
import { useATReportProductColumns } from "./useATReportProductColumns";
import { AccessRights } from "../../../core/navigation/types";
import { TradeList } from "../../../data/trading/trades/types";
import { Table, TableDataProps } from "../../components/common/Table";
import { AnnualTradingReportData } from "../../../data/reports/annualTrade/types";
import { useReportProductData } from "../../../data/reports/annualTrade/hooks/useReportProductData";
import { useAnnualActions } from "./useAnnualActions";

export interface AnnualTradesTableProps {
  userAccessRights: AccessRights;
}

type AnnualTradesTablePropsType = AnnualTradesTableProps &
  TableDataProps<TradeList>;

const ATRaProductTable: React.FunctionComponent<AnnualTradesTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useATReportProductColumns();
  const [data, load, reset] = useReportProductData();
  React.useEffect(() => reset, [reset]);

  return (
    <Table<AnnualTradingReportData>
      {...tableProps}
      getData={load}
      immutableData={data}
      title=""
      columns={columns}
      //      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default ATRaProductTable;
