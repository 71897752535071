import AnnualTradesReport from "./AnnualTradesReport";
import { NavigationItem } from "../../../core/navigation/types";
import {
  annualTradesReportLocation,
  annualProductLocation,
  annualClientLocation,
  annualDateLocation,
} from "./Locations";
import ATRProductPage from "./ATRProductPage";
import ATRClientPage from "./ATRClientPage";
import ATRDatePage from "./ATRDatePage";

export const annualTradesReportComponents: NavigationItem[] = [
  {
    Component: AnnualTradesReport,
    isMenuItem: true,
    path: annualTradesReportLocation,
  },
  {
    Component: ATRProductPage,
    isMenuItem: false,
    path: annualProductLocation,
  },
  {
    Component: ATRClientPage,
    isMenuItem: false,
    path: annualClientLocation,
  },
  {
    Component: ATRDatePage,
    isMenuItem: false,
    path: annualDateLocation,
  },
];
