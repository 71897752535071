import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { BankGuaranteeFilter } from "../types";
import { getParamsDateString } from "../../../../core/util/functions";

export const getBankGuaranteesReport = (user: User, filter: BankGuaranteeFilter) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getbankguaranteesreport",
    params: {
      userid: user.userId,
      cliid: filter?.client?.cliId,
      bankid: filter?.bank?.bankId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
        },
  });
};

export default getBankGuaranteesReport;
