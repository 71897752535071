import { MonthlyTradingReportData, MonthlyTradingReportFilter } from "../types";
import { User } from "../../../../auth/types";
import { webApi } from "../../../../../core/http/httpThunks";

const getMonthlyTradingData = (
  user: User,
  filter: MonthlyTradingReportFilter,
) => {

  return webApi<MonthlyTradingReportData[]>({
    method: "GET",
    url: "reporting/getsecmonthlytradinglist",
    params: {
      userid: user.userId,
      month: filter.month,
      year: filter.year,
    },
  });
};

export default getMonthlyTradingData;
