import { ThunkType } from "../../../../core/store/reducers";
import getCityApi from "../api/getCity";
import { setDisplayed } from "../citiesDataReducer";
import { City } from "../types";

export const loadCity = (cityId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const city = await dispatch(getCity(cityId));

  dispatch(setDisplayed(city));
};

export const getCity = (
  cityId: string
): ThunkType<Promise<City | undefined>> => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;

  return await dispatch(getCityApi(userId, cityId));
};
