import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllEmployees } from "../thunks/loadAllEmployees";
import { Employee } from "../types";

export const useEmployeeData = () => {
	const data = useSelector<StoreType, Employee[] | undefined>(s => s.data.listing.employees.displayData);
	const allData = useSelector<StoreType, Employee[] | undefined>(s => s.data.listing.employees.all);

	const dispatch = useDispatch();
	const dispatchLoadAllEmployees = React.useCallback(() => dispatch(loadAllEmployees()), [dispatch]);

	return [data, dispatchLoadAllEmployees, allData] as [
		typeof data,
		typeof dispatchLoadAllEmployees,
		typeof allData
	];
};

export const useEmployee = (id?: string) => {
	const allData = useSelector<StoreType, Employee[] | undefined>(s => s.data.listing.employees.all);

	const dispatch = useDispatch();
	const dispatchLoadAllEmployees = React.useCallback(() => dispatch(loadAllEmployees()), [dispatch]);

	React.useEffect(() => {
		if (!allData && id) {
			dispatchLoadAllEmployees();
		}
	}, [allData, id, dispatchLoadAllEmployees]);

	const employee = React.useMemo(() => {
		if (!allData) {
			return undefined;
		} else {
			return allData?.find(e => e.empId === id);
		}
	}, [id, allData]);

	return [employee] as [typeof employee];
};
