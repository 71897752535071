import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { ChartPeriod, TradePricesChartData } from "../types";
import { loadGraphData } from "../thunks/loadGraphData";

export const useGraphData = (graphId: string) => {
	const dispatch = useDispatch();
	const period = useSelector<StoreType, ChartPeriod>(s => s.data.trading.ticker.graphPeriod[graphId]);
	const prodId = useSelector<StoreType, string | undefined>(s => s.ux.trading.ticker.graphProductId[graphId]);
	const data = useSelector<StoreType, TradePricesChartData[] | undefined>(
		s => s.data.trading.ticker.graphData[graphId || ""]
	);

	React.useEffect(() => {
		if (prodId) {
			dispatch(loadGraphData(graphId, prodId, period || ChartPeriod.Month));
		}
	}, [graphId, period, prodId, dispatch]);

	return [data];
};
