import { webApi } from "../../../../core/http/httpThunks";
import { Warehouse } from "../types";

export const getWhsApi = (userId: string, whsid: string) =>
  webApi<Warehouse>({
    method: "GET",
    url: "listing/getwhs",
    params: {
      userId,
      whsid,
    },
  });

export default getWhsApi;
