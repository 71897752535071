import React from "react";
import { AttributeType, AttributeManditory } from "../../../../data/listing/attributes/types";
import { OrderAttribute } from "../../../../data/trading/orders/types";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { EditAttributeValue } from "./EditAttributeValue";
import { ProducAttributeHasStandard } from "../../../../data/listing/products/types";
import { OrdersEditAction } from "../../../../data/trading/orders/types";
import { useOrderPSTM } from "../../../../data/trading/orders/hooks/useOrderPSTM";

export interface EditAttributesProps {
	onChange: (attributes?: OrderAttribute[]) => void;
	attributes?: OrderAttribute[];
	error?: boolean;
	allManditory: boolean
	action: OrdersEditAction;
}

export const EditAttributes: React.FunctionComponent<EditAttributesProps> = ({
	onChange,
	error,
	attributes,
	allManditory,
	action
}) => {
	const { getString } = React.useContext(LocalizationContext);
	const [setPSTM, pstm] = useOrderPSTM();

	const createValueChanger = (attribute: OrderAttribute, prop: keyof OrderAttribute) => (value: any) => {
		const newAttrs =
			attributes &&
			attributes.map(attr =>
				attr !== attribute
					? attr
					: {
							...attr,
							[prop]: value,
					  }
			);
		onChange(newAttrs);
	};

	const createValueChangerSrps = () => (value: any) => {

//		setPSTM();

		const newAttrs =
			attributes &&
			attributes.map(attr =>
				attr.hasStandard !== ProducAttributeHasStandard.True
					? attr
					: {
							...attr,
							attrValue: attr.standardValue,
					  }
			);
		onChange(newAttrs);
	};   

		

	const hasStandardValues = React.useMemo(
		() => attributes && attributes.some(attr => attr.hasStandard === ProducAttributeHasStandard.True)
		, [attributes])


	return (
		<Paper
			style={{
				width: "calc(100% - 24px)",
				height: "100%",
				marginTop: "-8px",
				padding: "16px",
				background: "inherit",
			}}
		>
			<Grid
				container
				item
				direction="row"
				justify="flex-start"
				alignItems="flex-start"
				alignContent="flex-start"
				spacing={2}
				xs={12}
			>
				<Grid
					item
					xs={12}
					style={{
						textAlign: "center",
						marginBottom: "24px",
					}}
					justify="center"
					alignContent="center"
					alignItems="center"
				>
					<Typography variant="h6">{getString("AttrPsmtTitle")}</Typography>
				</Grid>
{/*				<Grid item>
					<Button size="small" variant="contained" color="secondary" onClick={createValueChangerSrps()} disabled={!hasStandardValues}>
						{getString("attributeSRPS")}
					</Button>
</Grid>  */}
				{attributes &&
					attributes.map((attr, i) => (
						<Grid item xs={12} key={i}>
							<EditAttributeValue
								attrId={attr.attrId}
								attrName={attr.attrName}
								attrType={attr.type}
								attrValId={attr.attrValId}
								attrValue={attr.attrValue}
								comparator={attr.comparator}
//								manditory={ allManditory ? AttributeManditory.True : attr.manditory}
								manditory={action===OrdersEditAction.New || action===OrdersEditAction.Modify ? (  allManditory ? AttributeManditory.True : attr.manditory) : AttributeManditory.False}
//								manditory={AttributeManditory.False}
								valueChange={createValueChanger(attr, "attrValue")}
								valueIdChange={createValueChanger(attr, "attrValId")}
								error={error}
								action={ action }
							/>
						</Grid>
					))}
			</Grid>
		</Paper>
	);
};

const getComaprator = (comp?: string) => (comp ? ` (${comp})` : "");
