import { ThunkType } from "../../../../core/store/reducers";
import getAllRolesAPI from "../api/getAllCurrencies";
import { setData } from "../currenciesDataReducer";

export const loadAllCurrencies = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setData());

	const { userId } = store.data.auth.current.user;
	const curerencies = await dispatch(getAllRolesAPI(userId));

	dispatch(setData(curerencies || []));
};
