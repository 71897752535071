import { ThunkType } from "../../../../core/store/reducers";
import getProductApi from "../api/getProduct";
import { setDisplayed } from "../productsDataReducer";
import { Product } from "../types";

export const loadProduct = (productId: string): ThunkType => async (dispatch, getStore) => {
	dispatch(setDisplayed());

	const role = await dispatch(getProduct(productId));

	dispatch(setDisplayed(role));
};

export const getProduct = (proiductId: string): ThunkType<Promise<Product | undefined>> => async (
	dispatch,
	getStore
) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getProductApi(userId, proiductId));
};
