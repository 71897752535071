import { ThunkType } from "../../../../core/store/reducers";
import { getAnnualClientExcel } from "../api/getAnnualClientExcel";
import fileDownload from "js-file-download";
import { setFetchState } from "../annualTradeDataSlice";
import moment from "moment";

export const downloadAnnualClientExcel = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.reports.annualTrade;
  const user = store.data.auth.current.user;
  dispatch(setFetchState({ type: "started" }));

  const annualTrading = await dispatch(getAnnualClientExcel(user, filter));
  if (annualTrading) {
    fileDownload(
      new Blob([annualTrading as any], {
        type: "application/vnd.openxmlformat",
      }),
      "AnnualCTrading_" + moment().format("YYYYMMDDhhmmss") + ".xlsx"
    );
  }
  dispatch(setFetchState({ type: "not-started" }));
};
