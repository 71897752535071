import React from "react";
import { SwitchProps, FormControlLabel, Switch } from "@material-ui/core";

export interface BoolInputProps<T> {
	property: keyof T;
	item: T;
	label: string;
	thrutyValue: number;
	falsyValue: number;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type BoolInputPropsType<T> = BoolInputProps<T> & SwitchProps;

export function BoolInput<T>(props: BoolInputPropsType<T>) {
	const { property, item, label, onChange, thrutyValue, falsyValue, ...otherProps } = props;
	return (
		<FormControlLabel
			control={
				<Switch
					name={property as any}
					checked={item[property] === (thrutyValue as any)}
					onChange={(e, value) => {
						onChange({
							...e,
							target: {
								...e.target,
								value: value ? thrutyValue : falsyValue as any,
								name: property
							}
						})
					}}
					{...otherProps}
				/>
			}
			label={label}
		/>
	);
}
