import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Employee } from "../types";

export const createEmployee = (userId: string, employee: Employee) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/newemployee",
		params: {
			userId
		},
		data: employee
	});

export default createEmployee;
