import React from "react";
import { useProductSelectData } from "../../../data/listing/products/hooks/useProductSelectData";
import { ProductSelectData } from "../../../data/listing/products/types";
import { AutocompleteSelectType, AutocompleteSelect } from "../common/AutocompleteSelect";
import { Status } from "../../../data/models";

export type ProductSelectPropsType = Omit<
	AutocompleteSelectType<ProductSelectData>,
	"data" | "getLabel">& {	status?: Status;};

export const ProductSelect: React.FunctionComponent<ProductSelectPropsType> =  ({ status, ...props }) => {
	const products = useProductSelectData(status);

	return (
		<AutocompleteSelect<ProductSelectData>
			{...props as AutocompleteSelectType<ProductSelectData>}
			data={products}
			getLabel={renderEmployeeLabel}
		/>
	);
};

const renderEmployeeLabel = (product: ProductSelectData | null) =>
	product ? `${product.prodName} ${renderCode(product.prodCode)}` : "";

const renderCode = (id?: string) => (id ? `(${id})` : "");
