import { ViewOrder } from "../../../data/trading/orders/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { renderOrderPrice, TooltipElises } from "../orders/columRenderers";
import { useOrderMarketOptionsTranslator } from "../../../data/trading/orders/hooks/useOrderMarketOptions";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const useOrderColumns = () => {
	const { getString, getFormatedNumberString } = React.useContext(LocalizationContext);
	const marketTranslator = useOrderMarketOptionsTranslator();

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	return React.useMemo(
		() => [
			{
				title: getString("orderTimeReceived"),
				render: order => order.timeReceived,
				sorting: false,
				width: "160px",
				cellStyle: {
					maxWidth: "160px",
					minWidth: "160px",
					width: "160px",
					paddingRight: 0,
				},
				headerStyle: {
					maxWidth: "160px",
					minWidth: "160px",
					width: "160px",
					paddingRight: 0,
				},
				hidden: screenExtraSmall,
				
			},
			{
				title: getString("orderProduct"),
				render: order => (
					<TooltipElises text={order.product} />
				),
				sorting: false,
				width: "125px",
				cellStyle: {
					maxWidth: "125px",
					minWidth: "110px",
					width: "125px",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					overflowX: "hidden",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "125px",
					minWidth: "110px",
					width: "125px",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					overflowX: "hidden",
					paddingRight: "10px",
				},
			},
			{
				title: getString("orderPrice"),
				render: renderOrderPrice,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("orderQty"),
				render: order => `${getFormatedNumberString(order.qtyLeft || order.qty)} ${order.unit}`,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("orderMarket"),
				render: order => marketTranslator(order.market),
				sorting: false,
				width: "80px",
				cellStyle: {
					maxWidth: "80px",
					minWidth: "80px",
					width: "80px",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "80px",
					minWidth: "80px",
					width: "80px",
					paddingRight: "10px",
				},
				hidden: screenExtraSmall,
			},
		],
		[getString, marketTranslator, getFormatedNumberString, screenExtraSmall]
	) as Column<ViewOrder>[];
};
