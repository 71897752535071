import { webApi } from "../../../../../core/http/httpThunks";
import { ProductAttribute } from "../../types";

export const getAllAttributesApi = (userId: string, prodid: string) =>
	webApi<ProductAttribute[]>({
		method: "GET",
		url: "listing/getprodattrlist",
		params: {
			userId,
			prodid
		},
	});

export default getAllAttributesApi;
