import { MonthlyTradingReportData, MonthlyTradingReportFilter } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getMonthlyTradingData = (
  user: User,
  filter: MonthlyTradingReportFilter
) => {
  return webApi<MonthlyTradingReportData[]>({
    method: "GET",
    url: "reporting/getmonthlytradinglist",
    params: {
      userid: user.userId,
      numofmonths: filter.numofmonths,
    },
  });
};

export default getMonthlyTradingData;
