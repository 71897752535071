import { useDispatch, useSelector } from "react-redux";
import { printPaymentThunk } from "../thunks/printPaymentThunk";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { PaymentData } from "../types";
import { setPrintPaymentFetchState } from "../paymentsDataSlice";

export const usePaymentPrinter = (payment?: PaymentData) => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.clearing.payments.printReportFetchState
	);
	const dispatch = useDispatch();

	const download = React.useCallback(() => {
			dispatch(printPaymentThunk(payment));
	}, [dispatch,payment ]);

	const dispatchSetFetch = React.useCallback(() => {                   //  Ovo da prvo izađe customDialog box
		dispatch(setPrintPaymentFetchState({ type: "started" }))
	},	[dispatch] );
	
	return [download, fetchState, dispatchSetFetch] as [typeof download, typeof fetchState, typeof dispatchSetFetch];
};
