import React from "react";
import { useATReportDateColumns } from "./useATReportDateColumns";
import { AccessRights } from "../../../core/navigation/types";
import { TradeList } from "../../../data/trading/trades/types";
import { Table, TableDataProps } from "../../components/common/Table";
import { AnnualTradingReportData } from "../../../data/reports/annualTrade/types";
import { useReportDateData } from "../../../data/reports/annualTrade/hooks/useReportDateData";
import { useAnnualActions } from "./useAnnualActions";

export interface AnnualTradesTableProps {
  userAccessRights: AccessRights;
}

type AnnualTradesTablePropsType = AnnualTradesTableProps &
  TableDataProps<TradeList>;

const ATRDateTable: React.FunctionComponent<AnnualTradesTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useATReportDateColumns();
  const [data, load, reset] = useReportDateData();
  React.useEffect(() => reset, [reset]);

  return (
    <Table<AnnualTradingReportData>
      {...tableProps}
      getData={load}
      immutableData={data}
      title=""
      columns={columns}
      //      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default ATRDateTable;
