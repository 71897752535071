import React from "react";
import { DisputeData } from "../../../data/clearing/dispute/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";

export interface DisplayDisputeMainAttributesProps {
  dispute: DisputeData;
}

export const DisplayDisputeMainAttributes: React.FunctionComponent<DisplayDisputeMainAttributesProps> = ({
  dispute,
}) => {
  const style = useDisplaySingleEmementStyles();
  const { getString, getDateString } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("disDispSitDate")}</span>
        <span className={style.value}>{getDateString(dispute.dispSitDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("disTradeNo")}</span>
        <span className={style.value}>{dispute.tradeNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dissellerCode")}</span>
        <span className={style.value}>{dispute.sellerCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dissellerName")}</span>
        <span className={style.value}>{dispute.sellerName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("disbuyerCode")}</span>
        <span className={style.value}>{dispute.buyerCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("disbuyerName")}</span>
        <span className={style.value}>{dispute.buyerName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("disproductCode")}</span>
        <span className={style.value}>{dispute.productCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("disproductName")}</span>
        <span className={style.value}>{dispute.productName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("disDisputeDescr")}</span>
        <span className={style.value}>{dispute.situationDescr}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("disstatus")}</span>
        <span className={style.value}>{dispute.statusDescr}</span>
      </div>
    </div>
  );
};
