import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../models";
import { InvoiceListData } from "./types";

type AccountingOrderData = {
  data?: InvoiceListData[];
  downloadReportFetchState: FetchStateType;
};

const initialState: AccountingOrderData = {
  downloadReportFetchState: { type: "not-started" },
};

const name = "accountingOrder";

const accountingOrderSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<InvoiceListData[] | undefined>) => ({
      ...state,
      data: action.payload,
    }),
    invalidateAccOrder: (state) => {
      return {
        ...state,
        data: undefined,
      };
    },
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      downloadReportFetchState: action.payload,
    }),
    resteData: (state) => ({
      ...state,
      data: initialState.data,
      downloadReportFetchState: initialState.downloadReportFetchState,
    }),
  },
});

export const {
  setData,
  invalidateAccOrder,
  setFetchState,
  resteData,
} = accountingOrderSlice.actions;

export default accountingOrderSlice.reducer;
