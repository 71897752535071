import React from "react";
import { DisplayTrade } from "./DisplayTrade";
import { TradeList } from "../../../data/trading/trades/types";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { Grid, Typography, Button, Paper } from "@material-ui/core";
import { useTradeCancel } from "../../../data/trading/trades/hooks/useTradeCancel";
import { LocalizationContext } from "../../../core/localization/Localization";

export interface CancelTradeProps {
	tradeId: string;
	cancel: () => void;
}

export const CancelTrade: React.FunctionComponent<CancelTradeProps> = ({ tradeId: orderId, cancel }) => {
	const [state, cancelTrade] = useTradeCancel();
	const { getString } = React.useContext(LocalizationContext);

	React.useEffect(() => {
		if (state.type === "successful") cancel();
	}, [state, cancel]);

	return (
		<Grid container direction="column" spacing={3}>
			<Grid item>
				<Paper style={{ padding: "20px" }}>
					<Grid container direction="row" justify="space-around" alignItems="center">
						<Typography variant="h5">{getString("orderTradeQuestion")}</Typography>
						<span>
							<Button
								onClick={() => cancelTrade(orderId)}
								disabled={state.type !== "not-started"}
							>
								{getString("orderCancelQuestionYes")}
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={cancel}
								disabled={state.type !== "not-started"}
							>
								{getString("orderCancelQuestionNo")}
							</Button>
						</span>
					</Grid>
				</Paper>
			</Grid>
			<Grid item>
				<DisplayTrade id={orderId} />
			</Grid>
		</Grid>
	);
};

export const useShowCancelTrade = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(trade: TradeList) =>
			showWithDrawer(<CancelTrade tradeId={trade.tradeId} cancel={() => showWithDrawer(undefined)} />),
		[showWithDrawer]
	);
};
