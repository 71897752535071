import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertGuarantee } from "../thunks/upsertGuarantees";
import { setFetchState } from "../guaranteesDataSlice";
import { GuaranteeData } from "../types";

export const useGuaranteeUpsert = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.clearing.guarantees.fetchState
  );

  const dispatch = useDispatch();
  const dispatchUpsertGuarantee = React.useCallback(
    (guarantee: GuaranteeData) => dispatch(upsertGuarantee(guarantee)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchUpsertGuarantee] as [
    typeof fetchState,
    typeof dispatchUpsertGuarantee
  ];
};
