import React from "react";
import { useDispatch } from "react-redux";
import { ClientContractList } from "../../types";
import { getClientContracts } from "../../thunks/clientContract/loadClientContracts";

export const useClientContracts = (clientId?: string) => {
	const displatch = useDispatch();
	const [contracts, setContracts] = React.useState<ClientContractList[] | undefined>();

	const loadClientContracts = React.useCallback(async () => {
		if (clientId) {
			const loadedContracts = (await displatch(getClientContracts(clientId))) as any;
			setContracts(loadedContracts || []);
		}
	}, [displatch, clientId])

	React.useEffect(() => {
		loadClientContracts();
	}, [loadClientContracts]);

	return [contracts, loadClientContracts] as [typeof contracts, typeof loadClientContracts]
};
