import { NavigationItem } from "../../../core/navigation/types";
import MarginsDataPage from "./MarginsDataPage";
import { marginsDataLocation, editRouter } from "./Locations";
export const marginsDataComponents: NavigationItem[] = [
  {
    Component: MarginsDataPage,
    isMenuItem: true,
    path: marginsDataLocation,
  },
];
