import { OrderFilter } from "../../../models";
import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";
import { GetOrdersParams } from "../../../trading/orders/types";

export const getOrderListReportExcel = (
	user: User,
	filter?: OrderFilter,
) => {
	const params: GetOrdersParams = {
		userid: user.userId,
		side: filter?.side,
		prodid: filter?.product?.prodId,
		cliprsid: undefined,
		status: filter?.orderStatus?.ftValue,
		cliid: filter?.client?.cliId,
		empid: undefined,
		datefrom: getParamsDateString(filter?.fromDate),
		dateto: getParamsDateString(filter?.toDate),
		market: filter?.market,
	};

	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getorderbookxls",
		params,
	});
};

export default getOrderListReportExcel;
