import React from "react";
import { Grid } from "@material-ui/core";
import { useCurrentTradesReportFilter } from "../../../../data/reports/securityCommission/monthlyTrade/hooks/useTradesReportFilter";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { MonthSelect, YearSelect} from "../../../components/reports/DatePartSelect"

export const MonthlyTradesReportFilter: React.FunctionComponent = () => {
	const [filter, setReportMonth, setReporthYear] = useCurrentTradesReportFilter();
	const { getString } = React.useContext(LocalizationContext);

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<YearSelect 
					selected={filter.year}
					yearChange={setReporthYear}
					label={getString("monthlyTradeReportReportYear")}
				/>
			</Grid>
			<Grid item>
				<MonthSelect 
					selected={filter.month}
					monthChange={setReportMonth}
					label={getString("monthlyTradeReportReportMonth")}
				/>

			</Grid>
		</Grid>
	);
};
