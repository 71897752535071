import { ThunkType } from "../../../../core/store/reducers";
import createPayTypeApi from "../api/createPayType";
import updatePayTypeApi from "../api/updatePayType";
import { setFetchState } from "../payTypesDataReducer";
import { PayType } from "../types";

export const upsertPayType = (paytype: PayType): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = paytype.payTypeId
		? await dispatch(updatePayTypeApi(userId, paytype))
		: await dispatch(createPayTypeApi(userId, paytype));

	if (result && result.resCode === "OK") {
		dispatch(setFetchState({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
	}
};
