import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageData, PageDataStatus, FetchStateType } from "../../models";
import { TradeList } from "./types";

type TradesData = {
	currentPage?: PageData<TradeList>;
	displayedTrade?: TradeList;
	cancelFetchState: FetchStateType;
	printTradeFetchState: FetchStateType;
};

const initialState: TradesData = {
	cancelFetchState: { type: "not-started" },
	printTradeFetchState: { type: "not-started" },
};

const name = "trades";

const tradesSlice = createSlice({
	name,
	initialState,
	reducers: {
		setTrades: (state, action: PayloadAction<PageData<TradeList>>) => ({
			...state,
			currentPage: action.payload,
		}),
		setDisplayed: (state, action: PayloadAction<TradeList | undefined>) => ({
			...state,
			displayedTrade: action.payload,
		}),
		invalidateTrades: state => {
			if (!state.currentPage) return state;

			return {
				...state,
				currentPage: {
					...state.currentPage,
					status: PageDataStatus.DataInvalidated,
				},
			};
		},
		setCancelTradeFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			cancelFetchState: action.payload,
		}),
		setPrintTradeFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			printTradeFetchState: action.payload,
		}),
	},
});

export const { setTrades, setDisplayed, invalidateTrades, setCancelTradeFetchState, setPrintTradeFetchState,
} = tradesSlice.actions;

export default tradesSlice.reducer;
