import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";

export const getInvoiceXml = ( user: User, invid: string) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "accounting/generateinvoicexml",
    params: {
      userid: user.userId,
      invid,
    },
  });
};

export default getInvoiceXml;
