import { ThunkType } from "../../../../core/store/reducers";
import deleteProductGroupApi from "../api/deleteProductGroup";
import { setFetchState } from "../productGroupDataReducer";

export const deleteProductGroup = (groupId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteProductGroupApi(userId, groupId));

	dispatch(setFetchState(result !== undefined ? { type: "successful" } : { type: "error"}));
};
