import React, { useContext } from "react";
import { AppBar, IconButton, Toolbar, Typography, Grid } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useMainBarStyles } from "./MainBarStyles";
import { LocalizationContext } from "../core/localization/Localization";
import { UserAvatar } from "./UserAvatar";
import { appConfig } from "../core/config/config";

interface MainBarProps {
	toggleNavMenu: () => void;
}

export const MainBar: React.FunctionComponent<MainBarProps> = props => {
	const styles = useMainBarStyles();
	const { getString } = useContext(LocalizationContext);

	const nscomexlogo = require("'../../../public/NSCOMEX-LOGO.png");

	return (
		<AppBar position="sticky" className={styles.appBar}>
			<Toolbar className={styles.appBaraContent}>
				<span className={styles.titleArea}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="menu"
						className={styles.menuButton}
						onClick={props.toggleNavMenu}
					>
						<MenuIcon />
					</IconButton>
					<img className={styles.logo} src={ nscomexlogo } /> 
					{/*<Typography variant="h6" className={styles.title}>{`${getString("mainTitle")} ${
						appConfig.general.titleSufix}`}  </Typography> */}
				</span>  
 			 	<UserAvatar />  
			</Toolbar>
		</AppBar>
	);
};
