import { ThunkType } from "../../../../core/store/reducers";
import getBankApi from "../api/getBank";
import { setDisplayed } from "../banksDataReducer";
import { Bank } from "../types";

export const loadBank = (bankId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const bank = await dispatch(getBank(bankId));

  dispatch(setDisplayed(bank));
};

export const getBank = (
  bankId: string
): ThunkType<Promise<Bank | undefined>> => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getBankApi(userId, bankId));
};
