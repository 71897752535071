import AntiMnyLndCommReport from "./AntiMnyLndCommReport";
import { NavigationItem } from "../../../core/navigation/types";
import { antiMnyLndCommLocation } from "./Locations";

export const antiMnyLndCommReportComponents: NavigationItem[] = [
  {
    Component: AntiMnyLndCommReport,
    isMenuItem: true,
    path: antiMnyLndCommLocation,
  },
];
