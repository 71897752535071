import React from "react";
import { Location } from "history";
import { useHistory, Prompt } from "react-router-dom";
import { LocalizationContext } from "../../../core/localization/Localization";
import { CustomDialog } from "./CustomDialog";

interface LeavePromptProps {
	shouldShow: boolean;
	title?: string;
	message?: string;
}

export const LeavePrompt: React.FunctionComponent<LeavePromptProps> = ({ shouldShow, title, message }) => {
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [location, setLocation] = React.useState<Location | undefined>();
	const [confirmed, setConfirmed] = React.useState(false);
	const history = useHistory();
	const { getString } = React.useContext(LocalizationContext);

	const handleRouterPrompt = React.useCallback(
		(location: Location) => {
			if (!confirmed) {
				setLocation(location);
				setDialogOpen(true);
				return false;
			} else {
				return true;
			}
		},
		[confirmed, setLocation, setDialogOpen]
	);

	const dialogClosed = React.useCallback((confirmed: boolean) => {
		setDialogOpen(false);
		setConfirmed(confirmed);
	}, [setDialogOpen, setConfirmed]);

	React.useEffect(() => {
		if (confirmed && location) {
			history.push(location.pathname);
		}
	}, [history, confirmed, location]);

	return (
		<>
			<Prompt when={shouldShow} message={handleRouterPrompt} />
			<CustomDialog
				open={dialogOpen}
				onClose={dialogClosed}
				title={title || getString("leavePageTitle")}
				message={message || getString("leavePageMessage")}
				confirmLabel={getString("leavePageConfirm")}
				closeLabel={getString("leavePageCancel")}
			/>
		</>
	);
};
