import { ThunkType } from "../../../../core/store/reducers";
import getAllRatesAPI from "../api/getAllCurrencyRates";
import { setData } from "../currencyRatesDataReducer";

export const loadAllCurrencyRates = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setData());

	const { userId } = store.data.auth.current.user;
	const { displayCurrencyId } = store.data.listing.currencyRates;
	const rates = displayCurrencyId ? await dispatch(getAllRatesAPI(userId, displayCurrencyId)) : [];

	dispatch(setData(rates || []));
};
