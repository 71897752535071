import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import { PaymentListData } from "../../../../../data/clearing/payments/types";
import { LocalizationContext } from "../../../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewPayment } from "../../../../clearing/payments/DisplayPayment";

export function usePaymentsActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowPayment = useViewPayment();

  return useMemo(() => {
    const result: MaterialTableProps<PaymentListData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) => onShowPayment(rowData as PaymentListData),
      },
    ];
    return result;
  }, [onShowPayment, getString]);
}
