import React from "react";
import { ClientBroker } from "../../../../data/listing/clients/types";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../../components/common/DisplayBoolNumber";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { useClientBrokers } from "../../../../data/listing/clients/hooks/clientBroker/useClientBrokers";
import {
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	TableBody,
	Typography,
	Grid,
} from "@material-ui/core";
import { Status } from "../../../../data/models";

export interface DisplayClientBrokersProps {
	clientId: string;
}

export const DisplayClientBrokers: React.FunctionComponent<DisplayClientBrokersProps> = ({ clientId }) => {
	const style = useDisplaySingleEmementStyles();
	const [brokers] = useClientBrokers(clientId);

	return (
		<div className={style.root}>
			<div className={style.row}>
				{brokers ? <ClientBrokersDisplayTable brokers={brokers} /> : "loading placeholder"}
			</div>
		</div>
	);
};

interface ClientBrokersDisplayTableProps {
	brokers: ClientBroker[];
}

const ClientBrokersDisplayTable: React.FunctionComponent<ClientBrokersDisplayTableProps> = ({ brokers: persons }) => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);

	if (persons.length === 0) {
		return (
			<Grid container justify="center">
				<Typography>{getString("clientBrokerEmpty")}</Typography>
			</Grid>
		);
	}

	return (
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>{getString("clientBrokerName")}</TableCell>
						<TableCell>{getString("clientBrokerIDNumber")}</TableCell>
						<TableCell>{getString("clientBrokerStatus")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{persons.map(broker => (
						<TableRow key={broker.brokIdNo}>
							<TableCell>{broker.brokName}</TableCell>
							<TableCell>{broker.brokIdNo}</TableCell>
							<TableCell>
								<DisplayBoolNumber
									value={broker.status}
									thrutyValue={Status.Active}
									className={style.icon}
								></DisplayBoolNumber>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
