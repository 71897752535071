import { useCopiedState } from "../../../../core/util/hooks";
import { setFilter, removeFilter, initialState, setReportData} from "../marginsUXSlice";
import { invalidateMarginssData } from "../marginsDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { MarginsDataFilter } from "../types";

export const useMarginsDataFilter = () => {
  const [storeFilter] = useCurrentMarginsDataFilter();
  const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<
  MarginsDataFilter
  >(storeFilter);

  const dispatch = useDispatch();
  const dispatchSetFilter = React.useCallback(() => {
    dispatch(setFilter(filter!));
    dispatch(invalidateMarginssData());
  }, [dispatch, filter]);

  const dispatchResetFilter = React.useCallback(() => {
    setCurrentFilter(initialState.filter);
  }, [setCurrentFilter]);

  return [
    filter,
    editFilter,
    dispatchSetFilter,
    changeState,
    dispatchResetFilter,
  ] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter,
    typeof changeState,
    typeof dispatchResetFilter
  ];
};

export const useCurrentMarginsDataFilter = () => {
  const filter = useSelector<StoreType, MarginsDataFilter>(
    (store) => store.ux.clearing.marginsData.filter
  );
  const dispatch = useDispatch();
  const dispatchRemoveFilter = React.useCallback(
    (type: keyof MarginsDataFilter) => {
      dispatch(removeFilter(type));
      dispatch(invalidateMarginssData());
    },
    [dispatch]
  );
	const dispatchSetReportDate = React.useCallback(
		(reportDate: string) => {
			dispatch(setReportData(reportDate));
			dispatch(invalidateMarginssData());
		},
		[dispatch]
	);

  return [filter, dispatchRemoveFilter, dispatchSetReportDate] as [
    typeof filter,
    typeof dispatchRemoveFilter,
    typeof dispatchSetReportDate
  ];
};
