import { ThunkType } from "../../../../../core/store/reducers";
import { setData } from "../tradeReviewDataSlice";
import getTradeReviewDataApi from "../api/getTradeReviewData";

export const loadReport = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const user = store.data.auth.current.user;
  const { filter } = store.ux.reports.exchbusiness.tradeReview;

  const pagedTrades = await dispatch(getTradeReviewDataApi(user, filter));

  dispatch(setData(pagedTrades || []));
};
