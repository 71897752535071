import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { resetControlIdices } from "../ctrlGoodsUXSlice";
import { PageIndex } from "../../../models";
import { setCtrlGoodsIndexThunk } from "../thunks/loadAllCtrlGoods";

export const useCtrlGoodsIndex = () => {
  const pageIndex = useSelector<StoreType, PageIndex>(
    (s) => s.ux.clearing.controls.index
  );

  const dispatch = useDispatch();
  const dispatchSetIndex = React.useCallback(
    (index: PageIndex) => dispatch(setCtrlGoodsIndexThunk(index)),
    [dispatch]
  );
  const dispatchResetIndex = React.useCallback(
    () => dispatch(resetControlIdices()),
    [dispatch]
  );

  return [pageIndex, dispatchSetIndex, dispatchResetIndex] as [
    typeof pageIndex,
    typeof dispatchSetIndex,
    typeof dispatchResetIndex
  ];
};
