import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PageData,
  PageDataStatus,
  FetchStateType,
  getFetchStateChange,
} from "../../models";
import { PaymentData, PaymentListData } from "./types";

type PaymentsData = {
  all?: PaymentData[];
  displayData?: PaymentData[];
  currentPage?: PageData<PaymentListData>;
  displayedPayment?: PaymentData;
  fetchState: FetchStateType;
  cancelFetchState: FetchStateType;
  downloadReportFetchState: FetchStateType;
  printReportFetchState: FetchStateType;
};

const initialState: PaymentsData = {
  cancelFetchState: { type: "not-started" },
  fetchState: { type: "not-started" },
  downloadReportFetchState: { type: "not-started" },
  printReportFetchState:  { type: "not-started" },
};

const name = "payments";

const paymentsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setPayments: (state, action: PayloadAction<PageData<PaymentListData>>) => ({
      ...state,
      currentPage: action.payload,
    }),

    setDisplayed: (state, action: PayloadAction<PaymentData | undefined>) => ({
      ...state,
      displayedPayment: action.payload,
    }),

    invalidatePayment: (state) => {
      if (!state.currentPage) return state;

      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          status: PageDataStatus.DataInvalidated,
        },
      };
    },
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      displayedPayment:
        action.payload.type === "successful"
          ? undefined
          : state.displayedPayment,
      all: action.payload.type === "successful" ? undefined : state.all,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: getFetchStateChange(state.fetchState, action.payload),
      downloadReportFetchState: action.payload,
    }),
    setCancelPaymentFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      cancelFetchState: action.payload,
    }),
    setPrintPaymentFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      printReportFetchState: action.payload,
    }),
  },
});

export const {
  setPayments,
  setDisplayed,
  invalidatePayment,
  setFetchState,
  setCancelPaymentFetchState,
  setPrintPaymentFetchState,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
