import { Employee } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";

interface IEmployeeData {
	all?: Employee[];
	searchTerm: string;
	displayData?: Employee[];
	displayedEmployee?: Employee;
	fetchState: FetchStateType;
}

const initialState: IEmployeeData = {
	searchTerm: "",
	fetchState: { type: "not-started" },
};

const name = "employees";

const employeesSlice = createSlice({
	name,
	initialState,
	reducers: {
		setEmployees: (state, action: PayloadAction<Employee[] | undefined>) => ({
			...state,
			all: action.payload,
			displayData: filterData(action.payload, state.searchTerm),
		}),
		setSearchTerm: (state, action: PayloadAction<string>) => ({
			...state,
			searchTerm: action.payload.toLocaleLowerCase(),
			displayData: filterData(state.all, action.payload),
		}),
		setDisplayedEmployee: (state, action: PayloadAction<Employee | undefined>) => ({
			...state,
			displayedEmployee: action.payload,
		}),
		setFetchStateEmployee: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayedEmployee: action.payload.type === "successful" ? undefined : state.displayedEmployee,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			fetchState: getFetchStateChange(state.fetchState, action.payload),
		}),
	},
});

const filterData = (data?: Employee[], searchTerm?: string) =>
	searchTerm === undefined || searchTerm === ""
		? data
		: data?.filter(
				e =>
					(e.firstName &&
						e.firstName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(e.lastName && e.lastName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(e.email && e.email.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(e.phoneOffice &&
						e.phoneOffice.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
					(e.empIdNumber &&
						e.empIdNumber.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
		  );

export const {
	setEmployees,
	setSearchTerm,
	setDisplayedEmployee,
	setFetchStateEmployee,
} = employeesSlice.actions;

export default employeesSlice.reducer;
