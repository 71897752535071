import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useCountryData } from "../../../data/listing/countries/hooks/useCountryData";
import { Country } from "../../../data/listing/countries/types";
import { useCountryColumns } from "./useCountryColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useCountryActions, useDeleteCountryAction } from "./useCountryActions";

export interface CountriesTableProps {
	userAccessRights: AccessRights;
}

type CountryTablePropsType = CountriesTableProps & TableDataProps<Country>;

export const CountriesTable: React.FunctionComponent<CountryTablePropsType> = ({
	userAccessRights,
	...tableProps
}) => {
	const [data, loadAll] = useCountryData();

	const columns = useCountryColumns();
	const actions = useCountryActions(userAccessRights);
	const onDeleteCountry = useDeleteCountryAction();

	return (
		<Table<Country>
			{...tableProps}
			getData={loadAll}
			immutableData={data}
			title=""
			columns={columns}
			actions={actions}
			options={{
				toolbar: false,
				pageSize: 10,
				actionsColumnIndex: -1,
			}}
			editable={userAccessRights !== AccessRights.ReadOnly ?{
				onRowDelete: onDeleteCountry,
			}: {}}
		/>
	);
};

export default CountriesTable;
