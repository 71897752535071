import { ThunkType } from "../../../../../core/store/reducers";
import getAllCommissionDetailsApi from "../../api/commissionDetails/getAllCommissionDetails";
import { CommissionDetail } from "../../types";

export const loadCommissionDetails = (commissionId: string): ThunkType<Promise<CommissionDetail[] | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getAllCommissionDetailsApi(userId, commissionId));
}
