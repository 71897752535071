import { ThunkType } from "../../../../core/store/reducers";
import getClientApi from "../api/getClient";
import { setDisplayedClient } from "../clientsDataReducer";
import { Client } from "../types"

export const loadClient = (clientId: string): ThunkType => async (dispatch, getStore) => {
	dispatch(setDisplayedClient());

	const client = await dispatch(getClient(clientId));

	dispatch(setDisplayedClient(client));
};

export const getClient = (clientId: string): ThunkType<Promise<Client | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getClientApi(userId, clientId));
}
