import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { GuaranteeListData } from "../../../data/clearing/guarantees/types";
import { GuaranteeData } from "../../../data/clearing/guarantees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewGuarantee } from "./DisplayGuarantee";
import { useHistory } from "react-router-dom";
import { getGuaranteeEditLocation } from "../guarantees/Locations";
import { DisplayGuaranteeMainAttributes } from "../guarantees/DisplayGuaranteeMainAttributes";
import { useGuaranteeDelete } from "../../../data/clearing/guarantees/hooks/useGuaranteeDelete";

export function useGuaranteesActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowGuarantee = useViewGuarantee();
  const onEditGuarantee = useEditGuaranteeAction();

  return useMemo(() => {
    const result: MaterialTableProps<GuaranteeListData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) =>
          onShowGuarantee(rowData as GuaranteeListData),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) =>
          onEditGuarantee(rowData as GuaranteeListData),
      },
    ];
    return result;
  }, [onShowGuarantee, onEditGuarantee, getString]);
}

export const useDeleteGuaranteeAction = () => {
  const [, deleteGuarantee] = useGuaranteeDelete();
  return React.useCallback(
    async (guarantees: GuaranteeListData) => {
      if (guarantees.payId) {
        await deleteGuarantee(guarantees.payId);
      }
    },
    [deleteGuarantee]
  );
};

export const useEditGuaranteeAction = () => {
  const history = useHistory();
  return React.useCallback(
    (guarantee: GuaranteeListData) =>
      history.push(getGuaranteeEditLocation(guarantee.payId)),
    [history]
  );
};
