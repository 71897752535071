import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../models";
import { WeeklyTradingReportData } from "./types";

type WeeklyTradesReportData = {
  data?: WeeklyTradingReportData[];
  downloadReportFetchState: FetchStateType;
};

const initialState: WeeklyTradesReportData = {
  downloadReportFetchState: { type: "not-started" },
};

const name = "weeklyTrade";

const weeklyTradeSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<WeeklyTradingReportData[] | undefined>
    ) => ({
      ...state,
      data: action.payload,
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      downloadReportFetchState: action.payload,
    }),
  },
});

export const { setData, setFetchState } = weeklyTradeSlice.actions;

export default weeklyTradeSlice.reducer;
