import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";
import { DailyTradingReportFilter } from "../types";

export const getDailyTradingReport = (user: User, filter: DailyTradingReportFilter) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getdailytradingreport",
		params: {
			userid: user.userId,
			reportdate: getParamsDateString(filter.reportDate),
		},
	});
};

export default getDailyTradingReport;
