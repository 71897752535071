import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { MarginsData } from "../types";
import { loadMarginsgData } from "../thunks/loadMarginsData";

export const useDisplayMarginsData = (mrgId?: string) => {
  const storeMarginsData = useSelector<StoreType, MarginsData | undefined>(
    (store) => store.data.clearing.marginsData.displayedMarginsgData
  );

  const displayedMarginsData =
  storeMarginsData && storeMarginsData.mrgId === mrgId ? storeMarginsData : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (mrgId) {
      displatch(loadMarginsgData(mrgId));
    }
  }, [displatch, mrgId]);

  return displayedMarginsData;
};
