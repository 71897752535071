import React from "react";
import { OrderMarkt } from "../types";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { exhaustiveCheck } from "../../../../core/util/functions";

export const useOrderMarketOptionsTranslator = () => {
	const { getString } = React.useContext(LocalizationContext);
	return React.useCallback(
		(market: OrderMarkt) => {
			switch (market) {
				case OrderMarkt.Spot:
					return getString("orderMarketSpot");
				case OrderMarkt.Forward:
					return getString("orderMarketForward");
				default:
					exhaustiveCheck(market);
			}
		},
		[getString]
	);
};

interface OrderMarketOption {
	label: string;
	value: OrderMarkt;
}

export const useOrderMarketOptions = (): OrderMarketOption[] => {
	const translator = useOrderMarketOptionsTranslator();
	return React.useMemo(
		() => [
			{
				label: translator(OrderMarkt.Spot) as string,
				value: OrderMarkt.Spot,
			},
			{
				label: translator(OrderMarkt.Forward) as string,
				value: OrderMarkt.Forward,
			},
		],
		[translator]
	);
};
