import { TradingData, TradingDataFilter } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getRefPriceButton = (user: User, filter: TradingDataFilter) => {
  return webApi<TradingData[]>({
    method: "PUT",
    url: "clearing/createprodtradingdata",
    params: {
      userid: user.userId,
      tradingdate: getParamsDateString(filter.reportDate),
    },
  });
};
export default getRefPriceButton;
