import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteBankGuarantee } from "../thunks/deleteBankGuarantees";
import { setFetchState } from "../bankguaranteesDataSlice";

export const useBankGuaranteeDelete = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.clearing.bankguarantees.cancelFetchState
  );

  const dispatch = useDispatch();
  const dispatchDeleteBankGuarantee = React.useCallback(
    (id: string) => dispatch(deleteBankGuarantee(id)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchDeleteBankGuarantee] as [
    typeof fetchState,
    typeof dispatchDeleteBankGuarantee
  ];
};
