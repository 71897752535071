import React from "react";
import { useDailyTradesColumns } from "./useMonthlyTradingReportColumns";
import { AccessRights } from "../../../../core/navigation/types";
import { MonthlyTradingReportData } from "../../../../data/reports/securityCommission/monthlyTrade/types";
import { ControledTable } from "../../../components/common/ControledTable";
import { useTradesReportData } from "../../../../data/reports/securityCommission/monthlyTrade/hooks/useTradesReportData";
import { useTradesReportIndex } from "../../../../data/reports/securityCommission/monthlyTrade/hooks/useTradesReportIndex";

export interface OrderTableProps {
  userAccessRights: AccessRights;
}

type OrderTablePropsType = OrderTableProps;

const DailyTradesReportTable: React.FunctionComponent<OrderTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useDailyTradesColumns();
  const [data, load] = useTradesReportData();
  const [index, setIndex] = useTradesReportIndex();

  return (
    <ControledTable<MonthlyTradingReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default DailyTradesReportTable;
