import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { loadAllPayments } from "../thunks/loadAllPayment";
import { PageData } from "../../../../models";
import { PaymentListData } from "../../../../clearing/payments/types";

export const usePaymentsData = () => {
	const pageData = useSelector<StoreType, PageData<PaymentListData> | undefined>(
		s => s.data.reports.exchbusiness.clientOverview.currentPagePayment
	);

	const dispatch = useDispatch();
	const dispatchLoadPaymentsPage = React.useCallback(() => dispatch(loadAllPayments()), [dispatch]);

	return [pageData, dispatchLoadPaymentsPage] as [typeof pageData, typeof dispatchLoadPaymentsPage];
};
