import { ThunkType } from "../../../../core/store/reducers";
import deletePaymentApi from "../api/deletePayment";
import { setFetchState, invalidatePayment } from "../paymentsDataSlice";

export const deletePayment = (payId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = await dispatch(deletePaymentApi(userId, payId));

  dispatch(
    setFetchState(
      result !== undefined ? { type: "successful" } : { type: "error" }
    )
  );
  dispatch(invalidatePayment())
};
