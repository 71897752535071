import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllCtrlGoods } from "../thunks/loadAllCtrlGoods";
import { CtrlGoodsListData } from "../types";
import { PageData } from "../../../models";

export const useCtrlGoodsData = () => {
  const pageData = useSelector<
    StoreType,
    PageData<CtrlGoodsListData> | undefined
  >((s) => s.data.clearing.controls.currentPage);

  const dispatch = useDispatch();
  const dispatchLoadCtrlGoodsPage = React.useCallback(
    () => dispatch(loadAllCtrlGoods()),
    [dispatch]
  );

  return [pageData, dispatchLoadCtrlGoodsPage] as [
    typeof pageData,
    typeof dispatchLoadCtrlGoodsPage
  ];
};
