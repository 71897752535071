import { InvoiceListData } from "../../../data/accounting/invoices/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export const useInvoicesColumns = () => {
  const {
    getString,
    getDateString,
    getFormatedNumberString,
  } = React.useContext(LocalizationContext);

  return React.useMemo(
    () => [
      {
        title: getString("invoiceInvNo"),
        field: "invNo",
        sorting: false,
      },
      {
        title: getString("invoiceInvDate"),
        render: (invoice) => getDateString(invoice.invDate, "DD.MM.YYYY"),
        sorting: false,
      },
      {
        title: getString("invoiceInvTypeDescr"),
        field: "invTypeDescr",
        sorting: false,
      },
      {
        title: getString("invoiceTranNo"),
        field: "tranNo",
        sorting: false,
      },
      {
        title: getString("invoiceClientName"),
        field: "clientName",
        sorting: false,
      },
      {
        title: getString("invoiceAmt"),
        render: (invoice) => getFormatedNumberString(invoice.amt, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("invoiceVatAmt"),
        render: (invoice) => getFormatedNumberString(invoice.vatAmt, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("invoiceInvAmt"),
        render: (invoice) => getFormatedNumberString(invoice.invAmt, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("cliStatus"),
        render: (invoice) => (
          <DisplayBoolNumber
            value={invoice.status}
            thrutyValue={Status.Active}
          />
        ),
        customSort: (a, b) => a.status - b.status,
        width: "80px",
      },
    ],
    [getString, getFormatedNumberString, getDateString]
  ) as Column<InvoiceListData>[];
};
