import { MarginsDataFilter  } from "../types";
import { MarginsData, GetMarginsDataParams } from "../types";
import { User } from "../../../auth/types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getMarginsDataWebApi = (
  user: User,
  page: number,
  itemsPerPage: number,
  filter: MarginsDataFilter
) => {
  const params: GetMarginsDataParams = {
    userid: user.userId,
    fromrow: page * itemsPerPage,
    numofrows: itemsPerPage,
    tradeid: filter.trade?.tradeId,
    cliid: filter.client?.cliId,
    datefrom: getParamsDateString(filter.datefrom),
    dateto: getParamsDateString(filter.dateto),
  };

  return webApiWithResponse<MarginsData[]>({
    method: "GET",
    url: "clearing/getmarginsdatalist",
    params,
  });
};

export default getMarginsDataWebApi;
