import { webApi } from "../../../../core/http/httpThunks";
import { TradeList } from "../types";

export const cancelTradeApi = (userId: string, tradeid: string) =>
  webApi<TradeList>({
    method: "PUT",
    url: "trade/canceltrade",
    params: {
      userId,
      tradeid,
    },
  });

export default cancelTradeApi;
