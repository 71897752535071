import React from "react";
import { useDisplayPayment } from "../../../data/clearing/payments/hooks/useDisplayPayments";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import {
  PaymentData,
  PaymentListData,
} from "../../../data/clearing/payments/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayPaymentMainAttributes } from "./DisplayPaymentMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,IconButton,
} from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { usePaymentPrinter } from "../../../data/clearing/payments/hooks/usePaymentPrinter";

export interface DisplayPaymentProps {
  id?: string;
}

export const DisplayPayment: React.FunctionComponent<DisplayPaymentProps> = ({
  id,
}) => {
  const payment = useDisplayPayment(id);
  const { getString } = React.useContext(LocalizationContext);
	const [download, fetchState, setfetch] = usePaymentPrinter(payment);

  return !payment ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Grid container justify="space-between">
            <Grid item>
              <Typography>{getString("tableBasciInfo")}</Typography>
            </Grid>
							<Grid item>
								<CustomTooltip titleKeyword="exportPDFTooltip">
									<IconButton
										color="secondary"
										component="span"
										onClick={download}
										disabled={fetchState.type !== "not-started"}
									>
										<DownloadIcon />
									</IconButton>
								</CustomTooltip>
                { fetchState.type !== "not-started"  ? getString("pleaseWait") : null }
							</Grid>
          </Grid>
        </ExpansionPanelSummary>
        <DisplayPaymentMainAttributes payment={payment} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewPayment = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (payment: PaymentListData) =>
      showWithDrawer(<DisplayPayment id={payment.payId} />),
    [showWithDrawer]
  );
};
