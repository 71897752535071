import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";
import { ClientOverviewFilter } from "../types";

export const getPaymentsReport = (user: User, filter?: ClientOverviewFilter) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getpaymentsreport",
		params: {
			userid: user.userId,
			cliid: user.roleCode !== "MEMTRADER" &&  user.roleCode !== "MEMMANAGER" &&  user.roleCode !== "MEMCONTACT" ? filter?.client?.cliId : user.clientId ,
			datefrom: getParamsDateString(filter?.datefrom),
			dateto: getParamsDateString(filter?.dateto),
			clicard: "C",
		},
	});
};

export default getPaymentsReport;
