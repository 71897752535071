import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalizationLanguage } from "../../core/localization/types";

interface ILocalizationState {
	language: LocalizationLanguage;
}

const initialState: ILocalizationState = {
	language: LocalizationLanguage.SR,
};

const name = "localization";

const reportsSlice = createSlice({
	name,
	initialState,
	reducers: {
		setLocalization: (state, action: PayloadAction<LocalizationLanguage>) => ({
			...state,
			language: action.payload,
		}),
	},
});

export const {
	setLocalization,
} = reportsSlice.actions;

export default reportsSlice.reducer;
