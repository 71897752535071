import { webApi } from "../../../../core/http/httpThunks";
import { CurrencyRate } from "../types";

export const getCurrencyRateApi = (userId: string, currrateid: number) =>
	webApi<CurrencyRate>({
		method: "GET",
		url: "listing/getcurrrate",
		params: {
			userId,
			currrateid
		},
	});

export default getCurrencyRateApi;
