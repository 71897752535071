import React from "react";
import { useDisplayClient } from "../../../data/listing/clients/hooks/useDisplayClient";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { LocalizationContext } from "../../../core/localization/Localization";
import { ClientListData } from "../../../data/listing/clients/types";
import { DisplayClientBasicInfo } from "./DisplayClientBasicInfo";
import { DisplayPersons } from "./clientPersons/DisplayClientPersons";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useToggleStateDictionary } from "../../../core/util/hooks";
import { DisplayClientBrokers } from "./clientBrokers/DisplayClientBrokers";
import { DisplayClientContracts } from "./clientContracts/DisplayClientContracts";
import { DisplayClientAccounts } from "./clientAccounts/DisplayClientAccounts";

enum AccordionNames {
	BasicInfo = "basicInfo",
	Persons = "persons",
	Brokers = "brokers",
	Contracts = "contracts",
	Accounts = "accounts",
}

const accordionNames = Object.values(AccordionNames);

export interface DisplayEmployeeProps {
	id?: string;
}

export const DisplayClient: React.FunctionComponent<DisplayEmployeeProps> = ({ id }) => {
	const client = useDisplayClient(id);
	const { getString } = React.useContext(LocalizationContext);

	const [open, toggleOpen] = useToggleStateDictionary(accordionNames, [AccordionNames.BasicInfo]);

	return !client || !client.cliId ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel
				expanded={open[AccordionNames.BasicInfo]}
				onChange={toggleOpen[AccordionNames.BasicInfo]}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{getString("tableBasciInfo")}</Typography>
				</ExpansionPanelSummary>
				<DisplayClientBasicInfo client={client} />
			</ExpansionPanel>
			<ExpansionPanel
				expanded={open[AccordionNames.Persons]}
				onChange={toggleOpen[AccordionNames.Persons]}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{getString("clientPersonTitle")}</Typography>
				</ExpansionPanelSummary>
				<DisplayPersons clientId={client.cliId} />
			</ExpansionPanel>
			<ExpansionPanel
				expanded={open[AccordionNames.Brokers]}
				onChange={toggleOpen[AccordionNames.Brokers]}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{getString("clientBrokerTitle")}</Typography>
				</ExpansionPanelSummary>
				<DisplayClientBrokers clientId={client.cliId} />
			</ExpansionPanel>
			<ExpansionPanel
				expanded={open[AccordionNames.Contracts]}
				onChange={toggleOpen[AccordionNames.Contracts]}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{getString("contractTitle")}</Typography>
				</ExpansionPanelSummary>
				<DisplayClientContracts clientId={client.cliId} />
			</ExpansionPanel>
			<ExpansionPanel
				expanded={open[AccordionNames.Accounts]}
				onChange={toggleOpen[AccordionNames.Accounts]}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{getString("accountTitle")}</Typography>
				</ExpansionPanelSummary>
				<DisplayClientAccounts clientId={client.cliId} />
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewClient = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(client: ClientListData) => showWithDrawer(<DisplayClient id={client.cliId} />),
		[showWithDrawer]
	);
};
