import React from "react";
import { useCreateInvoicesColumns } from "./useCreateInvoicesColumns";
import { AccessRights } from "../../../core/navigation/types";
import { CreateInvoicesTranData } from "../../../data/accounting/createInvoices/types";
import { ControledTable } from "../../components/common/ControledTable";
import { useCreateInvoicesData } from "../../../data/accounting/createInvoices/hooks/useCreateInvoicesData";
import { useCreateInvoicesIndex } from "../../../data/accounting/createInvoices/hooks/useCreateInvoicesIndex";

export interface CreateInvoicesTableProps {
  userAccessRights: AccessRights;
}

type CreateInvoicesTablePropsType = CreateInvoicesTableProps;

export const CreateInvoicesTable: React.FunctionComponent<CreateInvoicesTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useCreateInvoicesColumns();
  const [data, load] = useCreateInvoicesData();
  const [index, setIndex] = useCreateInvoicesIndex();

  return (
    <ControledTable<CreateInvoicesTranData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default CreateInvoicesTable;
