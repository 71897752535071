import React from "react";
import { useTradeByClientsColumns } from "./useTradeByClientsReportColumns";
import { AccessRights } from "../../../../core/navigation/types";
import { TradeByClientsReportData } from "../../../../data/reports/exchbusiness/tradeByClients/types";
import { ControledTable } from "../../../components/common/ControledTable";
import { useTradeByClientsReportData } from "../../../../data/reports/exchbusiness/tradeByClients/hooks/useTradeByClientsReportData";
import { useTradeByClientsReportIndex } from "../../../../data/reports/exchbusiness/tradeByClients/hooks/useTradeByClientsReportIndex";

export interface TradeByClientsTableProps {
  userAccessRights: AccessRights;
}

type TradeByClientsTablePropsType = TradeByClientsTableProps;

export const TradeByClientsReportTable: React.FunctionComponent<TradeByClientsTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useTradeByClientsColumns();
  const [data, load] = useTradeByClientsReportData();
  const [index, setIndex] = useTradeByClientsReportIndex();

  return (
    <ControledTable<TradeByClientsReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default TradeByClientsReportTable;
