import React from "react";
import { useTradeByProductsColumns } from "./useTradeByProductsReportColumns";
import { AccessRights } from "../../../../core/navigation/types";
import { TradeByProductsReportData } from "../../../../data/reports/exchbusiness/tradeByProducts/types";
import { ControledTable } from "../../../components/common/ControledTable";
import { useTradeByProductsReportData } from "../../../../data/reports/exchbusiness/tradeByProducts/hooks/useTradeByProductsReportData";
import { useTradeByProductsReportIndex } from "../../../../data/reports/exchbusiness/tradeByProducts/hooks/useTradeByProductsReportIndex";

export interface TradeByProductsTableProps {
  userAccessRights: AccessRights;
}

type TradeByProductsTablePropsType = TradeByProductsTableProps;

export const TradeByProductsReportTable: React.FunctionComponent<TradeByProductsTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useTradeByProductsColumns();
  const [data, load] = useTradeByProductsReportData();
  const [index, setIndex] = useTradeByProductsReportIndex();

  return (
    <ControledTable<TradeByProductsReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default TradeByProductsReportTable;
