import { NavigationItem } from "../../../core/navigation/types";
import AttributePage from "./AttributePage";
import { EditAttributePage } from "./EditAttributePage";
import { EditAttributeValue } from "./attributeValues/EditAttributeValue";
import { attributesLocation, valuesEdit, basicInfoEdit } from "./Locations";
export const attributeComponents: NavigationItem[] = [
	{
		Component: AttributePage,
		isMenuItem: true,
		path: attributesLocation,
	},
	{
		Component: EditAttributeValue,
		isMenuItem: false,
		path: valuesEdit,
	},
	{
		Component: EditAttributePage,
		isMenuItem: false,
		path: basicInfoEdit,
	},
];
