import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex, OrderFilter } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";

type OrderPageState = {
	index: PageIndex;
	filter: OrderFilter;
};

export const initialState: OrderPageState = {
	index: {
		itemsPerPage: 20,
		pageNumber: 0,
	},
	filter: {
		orderStatus: {
			ftValue: 0,
			ftDescr: ""
		}
	},
};

const name = "ordersReport";

const ordersSlice = createSlice({
	name,
	initialState,
	reducers: {
		setOrdersIndex: (state, action: PayloadAction<PageIndex>) => ({
			...state,
			index: action.payload,
		}),
		resetOrderIdices: state => ({
			...state,
			index: {
				itemsPerPage: state.index.itemsPerPage,
				pageNumber: initialState.index.pageNumber,
			},
		}),
		setFilter: (state, action: PayloadAction<OrderFilter>) => ({
			...state,
			filter: action.payload,
		}),
		removeFilter: (state, action: PayloadAction<keyof OrderFilter>) => ({
			...state,
			filter: {
				...state.filter,
				[action.payload]: undefined,
			},
		}),
	},
	extraReducers: builder => {
		builder.addCase(successfulLogin, state => ({
			...state,
			index: {
				itemsPerPage: state.index.itemsPerPage,
				pageNumber: initialState.index.pageNumber,
			},
		}));
	},
});

export const { setOrdersIndex, resetOrderIdices, setFilter, removeFilter } = ordersSlice.actions;

export default ordersSlice.reducer;
