import { ThunkType } from "../../../../core/store/reducers";
import getMarginsgDataApi from "../api/getMarginsData";
import { setDisplayed } from "../marginsDataSlice";
import { MarginsData } from "../types";

export const loadMarginsgData = (mrgId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const marginsData = await dispatch(getMarginsData(mrgId));

  dispatch(setDisplayed(marginsData));
};

export const getMarginsData = (
  prodId: string
): ThunkType<Promise<MarginsData | undefined>> => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getMarginsgDataApi(userId, prodId));
};
