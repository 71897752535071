import React from "react";
import { Skeleton } from "@material-ui/lab";

export interface SkeletorLoaderProps<T> {
	item?: T;
	render: (item: T) => React.ReactNode;
	width?: string;
}

export function SkeletonLoader<T>({ item, render, width }: SkeletorLoaderProps<T>) {
	const finalWidth = width || "100%";
	return <>{item ? render(item) : <Skeleton variant="text" width={finalWidth} />}</>;
}
