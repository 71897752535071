import React from "react";
import { useDisplayTradingData } from "./useDisplayTradingData";
import { TradingData } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defaultTradingData: TradingData = {
  ptdId: "",
  productId: "",
};

export const useEditTradingData = (ptdId?: string): TradingData | undefined => {
  const displayTradingData = useDisplayTradingData(ptdId);

  return React.useMemo(() => {
    if (ptdId !== undefined && displayTradingData === undefined) {
      return undefined;
    }

    if (ptdId === undefined) {
      return { ...defaultTradingData };
    }

    return displayTradingData ? { ...displayTradingData } : undefined;
  }, [ptdId, displayTradingData]);
};

export const useEditTradingDataState = (ptdId?: string) => {
  const tradingData = useEditTradingData(ptdId);
  return useCopiedState(tradingData);
};
