import React from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { LocalizationContext } from "../../../core/localization/Localization";
import { AlarmFilter } from "../../../data/surveillance/alarmParameters/types";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { useAuth } from "../../../data/auth/hooks";
import { ProductSelect } from "../../components/listing/ProductSelect";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { AlarmTypeSelect } from "./AlarmTypeSelect";

export enum AlarmFilterEditorType {
  Basic,
  Extended,
}

interface AlarmFilterEditorProps {
  filter?: AlarmFilter;
  editFilter: (prop: keyof AlarmFilter, value: any) => void;
  save: () => void;
  type: AlarmFilterEditorType;
	reset?: () => void;
}

export const AlarmFilterEditor: React.FunctionComponent<AlarmFilterEditorProps> = ({
  filter,
  editFilter,
  save,
  type,
  reset,
}) => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
  const { getString } = React.useContext(LocalizationContext);
  const [auth] = useAuth();

  return (
    <Paper
      style={{
        paddingLeft: "70px",
        paddingRight: "70px",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Grid container direction="column" spacing={6}>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12}>
            <ProductSelect
              label={getString("alarmProduct")}
              predicate={(prod) => prod.prodId === filter?.product?.prodId}
              onChangeItem={(prod) => {
                editFilter("product", prod);
              }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <ClientSelect
            label={getString("alarmClient")}
            predicate={(client) => client.cliId === filter?.client?.cliId}
            onChangeItem={(client) => editFilter("client", client)}
          />
        </Grid>

				<Grid item container direction="row-reverse" spacing={2}>
					<Grid item>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								save();
								showWithDrawer(undefined);
							}}
						>
							{getString("orderFilterConfirm")}
						</Button>
					</Grid>
					<Grid item>
						<Button variant="text" onClick={reset}>
							{getString("filterReset")}
						</Button>
					</Grid>
				</Grid>
      </Grid>
    </Paper>
  );
};
