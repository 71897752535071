import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import snackbar from "./snackbar/snackbarDataSlice";
import auth from "./auth/authDataSlice";
import initialData from "./initialData/initialDataReducer";
import listing from "./listing/listingDataReducer";
import trading from "./trading/tradingDataReducer"
import clearing from "./clearing/clearingDataReducer"
import reports from "./reports/reportsDataRecucer";
import accounting from "./accounting/accountingDataReducer";
import surveillance from "./surveillance/surveillanceDataReducer";

const dataPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["auth", "initialData"],
};

const dataComReducer = combineReducers({
  snackbar,
  auth,
  initialData,
  listing,
  trading,
  clearing,
  reports,
  accounting,
  surveillance
});

export default persistReducer(dataPersistConfig, dataComReducer);

