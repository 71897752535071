import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewUser } from "./DisplayUser";
import { useRoleDelete } from "../../../data/listing/users/hooks/useUserDelete";
import { UserListData } from "../../../data/listing/users/types";
import { getUserEditLocation } from "./Locations";
import { AccessRights } from "../../../core/navigation/types";

export const useUserActions = (userAccessRights: AccessRights) => {
	const { getString } = useContext(LocalizationContext);
	const onShowUser = useViewUser();
	const onEditUser = useEditUserAction();

	return useMemo(() => {
		const result: Action<UserListData>[] = [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tableShow"),
				onClick: (event, rowData) => onShowUser(rowData as UserListData),
			},
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditUser(rowData as UserListData),
				hidden: userAccessRights===AccessRights.ReadOnly
			},
		];

		return result;
	}, [onShowUser, onEditUser, getString]);
};

export const useDeleteUserAction = () => {
	const [, deleteUser] = useRoleDelete();
	return React.useCallback(
		async (user: UserListData) => {
			if (user.userId) {
				await deleteUser(user.userId);
			}
		},
		[deleteUser]
	);
};

export const useEditUserAction = () => {
	const history = useHistory();
	return React.useCallback(
		(user: UserListData) => history.push(getUserEditLocation(user.userId)),
		[history]
	);
};
