import React from "react";
import { useDisplayBank } from "../../../data/listing/banks/hooks/useDisplayBank";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { Bank } from "../../../data/listing/banks/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayBankMainAttributes } from "./DisplayBankMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,
} from "@material-ui/core";

export interface DisplayBankProps {
  id?: string;
}

export const DisplayBank: React.FunctionComponent<DisplayBankProps> = ({
  id,
}) => {
  const bank = useDisplayBank(id);
  const { getString } = React.useContext(LocalizationContext);

  return !bank ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Typography>{getString("tableBasciInfo")}</Typography>
        </ExpansionPanelSummary>
        <DisplayBankMainAttributes bank={bank} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewBank = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (bank: Bank) => showWithDrawer(<DisplayBank id={bank.bankId} />),
    [showWithDrawer]
  );
};
