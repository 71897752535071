import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { DisputeListData } from "../../../data/clearing/dispute/types";
import { DisputeData } from "../../../data/clearing/dispute/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewDispute } from "./DisplayDispute";
import { useHistory } from "react-router-dom";
import { getDisputesEditLocation } from "../dispute/Locations";
import { DisplayDisputeMainAttributes } from "../dispute/DisplayDisputeMainAttributes";
import { useDisputeDelete } from "../../../data/clearing/dispute/hooks/useDisputeDelete";

export function useDisputesActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowDispute = useViewDispute();
  const onEditDispute = useEditDisputeAction();

  return useMemo(() => {
    const result: MaterialTableProps<DisputeListData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) => onShowDispute(rowData as DisputeListData),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) => onEditDispute(rowData as DisputeListData),
      },
    ];
    return result;
  }, [onShowDispute, onEditDispute, getString]);
}

export const useDeleteDisputeAction = () => {
  const [, deleteDispute] = useDisputeDelete();
  return React.useCallback(
    async (dispute: DisputeListData) => {
      if (dispute.dispSitDate) {
        await deleteDispute(dispute.dispSitId);
      }
    },
    [deleteDispute]
  );
};

export const useEditDisputeAction = () => {
  const history = useHistory();
  return React.useCallback(
    (dispute: DisputeListData) =>
      history.push(getDisputesEditLocation(dispute.dispSitId)),
    [history]
  );
};
