import React from "react";
import {
	UserData,
	UserActive,
	UserEmployee,
	UserChangePass,
	UserSubscribtionUnlimited,
} from "../../../data/listing/users/types";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useEmployee } from "../../../data/listing/employees/hooks/useEmployeeData";
import { useClient } from "../../../data/listing/clients/hooks/useClient";
import { useClientPerson } from "../../../data/listing/clients/hooks/clientPerson/useClientPerson";
import { useRole } from "../../../data/listing/roles/hooks/useRole";
import { SkeletonLoader } from "../../components/common/SkeletonLoader";
import { Role } from "../../../data/listing/roles/types";
import { Employee } from "../../../data/listing/employees/types";
import { Client, ClientPerson } from "../../../data/listing/clients/types";

export interface DisplayUserMainAttributesProps {
	user: UserData;
}

export const DisplayUserMainAttributes: React.FunctionComponent<DisplayUserMainAttributesProps> = ({
	user,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString, getDateString } = React.useContext(LocalizationContext);
	const [employee] = useEmployee(user.empId);
	const [client] = useClient(user.clientId);
	const [clientPerson] = useClientPerson(user.cliPrsId);
	const [role] = useRole(user.roleId);

	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("userUserName")}</span>
				<span className={style.value}>{user.userName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("userLoginUser")}</span>
				<span className={style.value}>{user.loginUser}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("userChangePass")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={user.changePass}
						thrutyValue={UserChangePass.True}
						className={style.icon}
					/>
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("userRoleId")}</span>
				<span className={style.value}>
					{user.roleId && (
						<SkeletonLoader<Role>
							item={role}
							render={r => r.roleName || r.roleNameEn}
							width={"8rem"}
						/>
					)}
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("userIsEmployee")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={user.isEmployee}
						thrutyValue={UserEmployee.True}
						className={style.icon}
					/>
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("userEmpId")}</span>
				<span className={style.value}>
					{user.empId && (
						<SkeletonLoader<Employee>
							item={employee}
							render={e => `${e.lastName} ${e.firstName}`}
							width={"8rem"}
						/>
					)}
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("userClientId")}</span>
				<span className={style.value}>
					{user.clientId && (
						<SkeletonLoader<Client> item={client} render={e => e.cliName} width={"8rem"} />
					)}
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("userCliPrsId")}</span>
				<span className={style.value}>
					{user.cliPrsId && (
						<SkeletonLoader<ClientPerson>
							item={clientPerson}
							render={e => e.cliPrsName}
							width={"8rem"}
						/>
					)}
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("userSubscribedUntil")}</span>
				<span className={style.value}>
					{user.subscribed_Until && getDateString(user.subscribed_Until)}
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("userSubscrUnlimited")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={user.subscrUnlimited}
						thrutyValue={UserSubscribtionUnlimited.True}
						className={style.icon}
					/>
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliPhone")}</span>
				<span className={style.value}>{user.phone}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliEmail")}</span>
				<span className={style.value}>{user.email}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("userIsActive")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={user.isActive}
						thrutyValue={UserActive.True}
						className={style.icon}
					/>
				</span>
			</div>

		</div>
	);
};
