import React from "react";
import { useDispatch } from "react-redux";
import { Client } from "../types";
import { getClient } from "../thunks/loadClient";

export const useClient = (clientId?: string) => {
	const displatch = useDispatch();
	const [client, setClient] = React.useState<Client | undefined>();

	React.useEffect(() => {
		const loadClient = async () => {
			if (clientId) {
				const loadedClient = (await displatch(getClient(clientId))) as any;
				setClient(loadedClient);
			}
		};
		loadClient();
	}, [displatch, clientId, setClient]);

	return [client];
};
