import { TradePricesChartData } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";

export const getTickerData = (user: User) => {
	return webApi<TradePricesChartData[]>({
		method: "GET",
		url: "reporting/gettradepricestickerdata",
		params: {
			userid: user.userId,
		},
	});
};

