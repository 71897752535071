import { ThunkType } from "../../../../core/store/reducers";
import getPayTypeApi from "../api/getPayType";
import { setDisplayed } from "../payTypesDataReducer";
import { PayType } from "../types";

export const loadPayType = (paytypeId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const payType = await dispatch(getPayType(paytypeId));

  dispatch(setDisplayed(payType));
};

export const getPayType = (
  paytypeId: string
): ThunkType<Promise<PayType | undefined>> => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getPayTypeApi(userId, paytypeId));
};
