import { Theme, makeStyles, createStyles } from "@material-ui/core";

export const useOrderTableStyles = makeStyles((theme: Theme) => {
	return createStyles({
		cellHasTooltip: {
			whiteSpace: "nowrap",
			display: "flex",
			justifyContent: "space-between",
			"& > svg": {
				width: "12px",
				marginLeft: "5px",
				display: "inline",
				color: theme.palette.info.dark,
			},
		},
		longCell: {
			whiteSpace: "nowrap",
		},
		valueLabel: {
			display: "flex",
			justifyContent: "space-between",
			"& > span:not(:first-child)": {
				marginLeft: "5px",
			},
		},
	});
});

export const useTooltipStyles = makeStyles((theme: Theme) => ({
	arrow: {
		color: theme.palette.common.black,
	},
	tooltip: {
		backgroundColor: theme.palette.common.black,
	},
}));
