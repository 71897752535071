import React from "react";
import { DisplayTrade } from "../../trading/trades/DisplayTrade";
import { TradeList } from "../../../data/trading/trades/types";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { Grid, Typography, Paper } from "@material-ui/core";
import { ClaculateTASCosts } from "./ClaculateTASCosts";

export interface CancelTradeProps {
	trade: TradeList;
	close: () => void;
}

export const ClaculateTASCostsDrawer: React.FunctionComponent<CancelTradeProps> = ({ trade, close }) => {
	return (
		<Grid container direction="column" spacing={3}>
			<Grid item>
				<Paper style={{ padding: "20px" }}>
					<Grid container direction="row" justify="space-around" alignItems="center">
						<ClaculateTASCosts trade={trade} close={close}/>
					</Grid>
				</Paper>
			</Grid>
			<Grid item>
				<DisplayTrade id={trade.tradeId} />
			</Grid>
		</Grid>
	);
};

export const useShowClaculateTASCosts = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(trade: TradeList) =>
			showWithDrawer(<ClaculateTASCostsDrawer trade={trade} close={() => showWithDrawer(undefined)} />),
		[showWithDrawer]
	);
};
