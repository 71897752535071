import { DeliveryData, DeliveryValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const deliveryValidationState: DeliveryValidationState = {
  deliveryDate: true,
  tradeId: true,
};

export const useDeliveryValidator = createUseValidator<
  DeliveryData,
  DeliveryValidationState
>(deliveryValidationState);
