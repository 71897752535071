import "moment/locale/sr";
import { TranslationsType, LocalizationLanguage } from "./types";
import { useLocalizationStore } from "../../data/localization/hooks";
import { srTranslation } from "./srTranslation";
import { srErrors } from "./srErrors";
import { enTranslations } from "./enTranslations";
import { enErrors } from "./enErrors";

export const useTransalation = (): TranslationsType => {
	const [selected] = useLocalizationStore();
	if (selected === LocalizationLanguage.SR) {
		return [srTranslation, srErrors];
	} else {
		return [enTranslations, enErrors];
	}
};
