import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../../models";
import { AnnualSecurityReportData } from "./types";

type TradesReportData = {
	data?: AnnualSecurityReportData[];
	downloadReportFetchState: FetchStateType;
};

const initialState: TradesReportData = {
	downloadReportFetchState: { type: "not-started" },
};

const name = "annualSecurity";

const annualSecurityTradeSlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<AnnualSecurityReportData[] | undefined>) => ({
			...state,
			data: action.payload,
		}),
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			downloadReportFetchState: action.payload,
		}),
	},
});

export const { setData, setFetchState } = annualSecurityTradeSlice.actions;

export default annualSecurityTradeSlice.reducer;
