import { ThunkType } from "../../../../core/store/reducers";
import { getChartDataHistorical } from "../api/getChartDataH";
import { setGraphData } from "../tickerDataSlice";
import { ChartPeriod } from "../types";

export const loadGraphDataHistorical = (graphId: string, prodid: string, period: ChartPeriod): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful" || !prodid) {
		return;
	}

	const { user } = store.data.auth.current;
	prodid =  graphId==='0001' ? '00000001' : graphId==='0002' ? '00000002' : '00000003';
	const graphData = await dispatch(getChartDataHistorical(user, prodid, period));

	dispatch(
		setGraphData({
			graphId: graphId,
			data: graphData || [],
		})
	);
};
