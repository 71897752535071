import React from "react";
import { useDispatch } from "react-redux";
import { Attribute } from "../types";
import { getAttribute } from "../thunks/loadAttribute";

export const useAttribute = (attributeId?: string) => {
	const displatch = useDispatch();
	const [attribute, setAttribute] = React.useState<Attribute | undefined>();

	React.useEffect(() => {
		const loadAttribute = async () => {
			if (attributeId) {
				const loadedAttribute = (await displatch(getAttribute(attributeId))) as any;
				setAttribute(loadedAttribute);
			}
		};
		loadAttribute();
	}, [displatch, attributeId, setAttribute]);

	return [attribute];
};
