import React from "react";
import { useClientPersons } from "../../../data/listing/clients/hooks/clientPerson/useClientPersons";
import { ClientPerson } from "../../../data/listing/clients/types";
import { AutocompleteSelectType, AutocompleteSelect } from "../common/AutocompleteSelect";

export interface ClientPersonSelectProps {
	clientId: string;
}

export type ClientPersonSelectPropsType = Omit<
	AutocompleteSelectType<ClientPerson>,
	"data" | "getLabel" | "selected"
> &
	ClientPersonSelectProps;

export const ClientPersonSelect: React.FunctionComponent<ClientPersonSelectPropsType> = ({
	onChangeItem,
	clientId,
	predicate,
	...otherProps
}) => {
	const [persons] = useClientPersons(clientId);

	const selected = React.useMemo(() => predicate && persons && persons.find(predicate), [predicate, persons]);

	React.useEffect(() => {
		if (!selected && persons && persons.length === 1) {
			onChangeItem(persons[0]);
		}
	}, [selected, persons, onChangeItem]);

	return (
		<AutocompleteSelect<ClientPerson>
			data={persons}
			getLabel={renderClientPersonLabel}
			onChangeItem={onChangeItem}
			selected={selected}
			{...otherProps}
		/>
	);
};

const renderClientPersonLabel = (client: ClientPerson | null) =>
	client ? `${client.cliPrsName} ${renderIdNumber(client.cliPrsIdNo)}` : "";

const renderIdNumber = (id?: string) => (id ? `(${id})` : "");
