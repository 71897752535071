import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { setGraphProductId } from "../tickerUXSlice";

export const useGraphProduct = (graphId: string) => {
	const dispatch = useDispatch();
	const prodId = useSelector<StoreType, string | undefined>(
		s => s.ux.trading.ticker.graphProductId[graphId]
	);

	const dispatchSetProduct = React.useCallback(
		(newPeriod?: string) => {
			dispatch(
				setGraphProductId({
					graphId,
					data: newPeriod,
				})
			);
		},
		[dispatch, graphId]
	);

	return [prodId, dispatchSetProduct] as [typeof prodId, typeof dispatchSetProduct];
};
