import { ThunkType } from "../../../../core/store/reducers";
import { setInvoices, invalidateInvoices } from "../invoicesDataSlice";
import { setInvoiceIndex } from "../invoicesUXSlice";
import getInvoices from "../api/getInvoices";
import { PageIndex, PageDataStatus } from "../../../models";

export const loadAllInvoices = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const { pageNumber, itemsPerPage } = store.ux.accounting.invoices.index;
  const { filter } = store.ux.accounting.invoices;
  const user = store.data.auth.current.user;

  const invoicesResponse = await dispatch(
    getInvoices(user, pageNumber, itemsPerPage, filter)
  );

  if (!invoicesResponse || invoicesResponse.resCode !== "OK") {
    return;
  }

  const invoices = {
    currentPageData: invoicesResponse.obj,
    totalCount: Number(invoicesResponse.resDesc) || 0,
    status: PageDataStatus.DataCurrent,
  };

  dispatch(setInvoices(invoices));
};

export const setInvoicesIndexThunk = (index: PageIndex): ThunkType => (
  dispatch
) => {
  dispatch(setInvoiceIndex(index));
  dispatch(invalidateInvoices());
};
