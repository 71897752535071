import { ThunkType } from "../../../../core/store/reducers";
import { printCtrlGoods } from "../api/printCtrlGoods";
import fileDownload from "js-file-download";
import { setDownloadFetchState } from "../ctrlGoodsDataSlice";
import { CtrlGoodsData } from "../types";

export const printCtrlGoodsThunk = (ctrlgoods?: CtrlGoodsData): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful" || !ctrlgoods || !ctrlgoods.controlId) {
		return undefined;
	}
	const lan = store.ux.localization.language
	const filename = `ControlGoodsConf_${ctrlgoods.controlId}.pdf`
	const user = store.data.auth.current.user;
	dispatch(setDownloadFetchState({ type: "started" }));
	const ctrlGoodsReport = await dispatch(printCtrlGoods(user, ctrlgoods.controlId));
	if (ctrlGoodsReport) {
		fileDownload(new Blob([ctrlGoodsReport as any], { type: "application/pdf" }), filename);
	}
	dispatch(setDownloadFetchState({ type: "not-started" }));
};
