import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { PayType } from "../types";

export const createPayTypeApi = (userId: string, paytype: PayType) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "listing/newpaytype",
    params: {
      userId,
    },
    data: paytype,
  });

export default createPayTypeApi;
