import React from "react";
import {
  Grid,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Fab,
} from "@material-ui/core";
import CtrlGoodsTable from "./CtrlGoodsTable";
import AddIcon from "@material-ui/icons/Add";
import { usePageStyles } from "../../pageStyles";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { useCtrlGoodsData } from "../../../data/clearing/ctrlgoods/hooks/useCtrlGoodsData";
import { useCtrlGoodsIndex } from "../../../data/clearing/ctrlgoods/hooks/useCtrlGoodsIndex";
import { useHistory } from "react-router-dom";
import { getCtrlGoodsEditLocation } from "../ctrlgoods/Locations";
import { PageDataStatus } from "../../../data/models";
import { CtrlGoodsFilterButton } from "./CtrlGoodsFilter";
import { useAuth } from "../../../data/auth/hooks";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";

const CtrlGoodsPage: PageComponent = ({ userAccessRights }) => {
  const pageStyles = usePageStyles();

  const [pageData, dispatchLoadCtrlGoods] = useCtrlGoodsData();
  const [pageIndex, dispatchSetIndex] = useCtrlGoodsIndex();

  React.useEffect(() => {
    if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
      dispatchLoadCtrlGoods();
    }
  }, [dispatchLoadCtrlGoods, pageData]);

  const history = useHistory();

  const [auth] = useAuth();

  if (auth.status !== "successful") {
    return null;
  }

  return (
    <>
      <Grid container className={pageStyles.root}>
        <Grid item xs={12} className={pageStyles.gridItem}>
          <Card>
            <CardHeader
              title={<PageTitle title="ctrlGoodsTitle" />}
							action={<CtrlGoodsDataActions />}
            />
            <Divider />
            <CardContent>
              <CtrlGoodsTable
                pageIndex={pageIndex}
                pageData={pageData}
                onPageIndexChange={dispatchSetIndex}
                userAccessRights={userAccessRights}
                getData={dispatchLoadCtrlGoods}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {userAccessRights === AccessRights.All && (
          <Fab
            color="secondary"
            aria-label="add"
            className={pageStyles.mainFab}
            onClick={() => history.push(getCtrlGoodsEditLocation())}
          >
            <AddIcon />
          </Fab>
        )}
    </>
  );
};

export const CtrlGoodsDataActions: React.FunctionComponent = () => (
	<Grid  container  spacing={4}>
		<Grid item>
 		    <DownloadButton />
   	</Grid>
   	<Grid item>
      		<DownloadExcelButton />
   	</Grid>
		<Grid item>
			<CtrlGoodsFilterButton />
		</Grid>
	</Grid>
);


export default CtrlGoodsPage;
