import React from "react";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import ExcelIcon from "@material-ui/icons/TableChartOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../../../components/common/CustomTooltip";
import { useDeliveryReportDownloader } from "../../../../../data/reports/exchbusiness/clientOverview/hooks/useDeliveryReportDownloader";
import { useDeliveryExcelReportDownloader } from "../../../../../data/reports/exchbusiness/clientOverview/hooks/useDeliveryExcelReportDownloader";

export const DownloadDeliveryButton: React.FunctionComponent = () => {
	const [downloader, fetchState] = useDeliveryReportDownloader();
	return (
		<CustomTooltip titleKeyword="exportPDFTooltip">
			<IconButton
				color="secondary"
				component="span"
				onClick={downloader}
				disabled={fetchState.type !== "not-started"}
			>
				<DownloadIcon />
			</IconButton>
		</CustomTooltip>
	);
};

export const DownloadDeliveryButtonExcel: React.FunctionComponent = () => {
	const [downloader, fetchState] = useDeliveryExcelReportDownloader();
	return (
		<CustomTooltip titleKeyword="exportXLSXTooltip">
			<IconButton
				color="secondary"
				component="span"
				onClick={downloader}
				disabled={fetchState.type !== "not-started"}
			>
				<ExcelIcon />
			</IconButton>
		</CustomTooltip>
	);
};
