import React, { useContext } from "react";
import { useAuth } from "../data/auth/hooks";
import { Avatar, IconButton, Typography, Box, Menu, MenuItem, styled, Badge } from "@material-ui/core";
import { useUserAvatarStyles } from "./UserAvatarStyle";
import { LocalizationContext } from "../core/localization/Localization";
import { canRecieveNotifications, useNotificationStatus } from "../core/notifications/hooks";
import { NotificationManager } from "../core/notifications/NotificationsManager";

const getInitials = (name: string) => {
	if (!name) {
		return "NN";
	}

	const nameWords = name.split(" ");
	if (nameWords.length === 1) {
		return nameWords[0].substring(0, 1).toUpperCase();
	}

	return nameWords[0].substring(0, 1).toUpperCase() + nameWords[1].substring(0, 1).toUpperCase();
};

export const UserAvatar: React.FunctionComponent = porps => {
	const styles = useUserAvatarStyles();
	const { getString } = useContext(LocalizationContext);
	const [authState, , logaout] = useAuth();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const notificationStatus = useNotificationStatus()
	const open = Boolean(anchorEl);

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	if (authState.status !== "successful") {
		return null;
	}

	const askForPermission = async () => NotificationManager.requestPermission();
	const showSystemNotification =
		NotificationManager.permission === "default" && canRecieveNotifications(authState.user);

	const name = authState.user.employeeName || authState.user.clientPersonName;

	return (
		<Box className={styles.root}>
			<Typography className={styles.name}>{name}</Typography>
			<IconButton onClick={handleMenu}>
				<Badge
				 	overlap="circle"
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					variant="dot"
					classes={{
						badge: notificationStatus ? styles.badgeGreen : styles.badgeError
					}}
				>
					<Avatar className={styles.avatar}>{getInitials(name)}</Avatar>
				</Badge>
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={open}
				onClose={handleClose}
			>
				{showSystemNotification && (
					<MenuItem onClick={askForPermission}>{getString("allowSystemNotifications")}</MenuItem>
				)}
				<MenuItem onClick={logaout}>{getString("logout")}</MenuItem>
			</Menu>
		</Box>
	);
};
