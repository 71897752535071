import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteCtrlGoods } from "../thunks/deleteCtrlGood";
import { setFetchState } from "../ctrlGoodsDataSlice";

export const useCtrlGoodDelete = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.clearing.controls.cancelFetchState
  );

  const dispatch = useDispatch();
  const dispatchDeleteCtrlGood = React.useCallback(
    (id: string) => dispatch(deleteCtrlGoods(id)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchDeleteCtrlGood] as [
    typeof fetchState,
    typeof dispatchDeleteCtrlGood
  ];
};
