import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { DisplayDrawerContext } from "../../../../layout/DisplayDrawerContext";
import { useTradeByClientsReportFilter } from "../../../../data/reports/exchbusiness/tradeByClients/hooks/useTradeByClientsReportFilter";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { CustomTooltip } from "../../../components/common/CustomTooltip";
import { TradeByClientsFilterEditor } from "./TradeByClientsEditor";

export const TradeByClientsReportFilter: React.FunctionComponent = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const showFilter = React.useCallback(
    () => showWithDrawer(<TradesByClientsFilterEditor />),
    [showWithDrawer]
  );

  const [filter, editfilter, save] = useTradeByClientsReportFilter();
  const { getString } = React.useContext(LocalizationContext);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <CustomTooltip titleKeyword="filterTooltip">
          <IconButton color="secondary" component="span" onClick={showFilter}>
            <FilterIcon />
          </IconButton>
        </CustomTooltip>
      </Grid>
    </Grid>
  );
};

const TradesByClientsFilterEditor: React.FunctionComponent = () => {
  const [filter, editFilter, save] = useTradeByClientsReportFilter();
  return (
    <TradeByClientsFilterEditor
      filter={filter}
      editFilter={editFilter}
      save={save}
    />
  );
};
