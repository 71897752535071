import { ThunkType } from "../../../../core/store/reducers";
import { setTrades, invalidateOrders } from "../tradesReportDataSlice";
import { setIndex } from "../tradesReportUXSlice";
import { PageIndex } from "../../../models";
import { getTradesThunk } from "../../../trading/trades/thunks/getTradesThunk";

export const loadReport = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  const { pageNumber, itemsPerPage } = store.ux.reports.tradesReport.index;
  const { filter } = store.ux.reports.tradesReport;

  const pagedTrades = await dispatch(
    getTradesThunk(pageNumber, itemsPerPage, filter)
  );

  if (pagedTrades) {
    dispatch(setTrades(pagedTrades));
  }
};

export const setIndexThunk =
  (index: PageIndex): ThunkType =>
  (dispatch) => {
    dispatch(setIndex(index));
    dispatch(invalidateOrders());
  };
