import { ThunkType } from "../../../../core/store/reducers";
import getAllPayTypeAPI from "../api/getAllPayTypes";
import { setData } from "../payTypesDataReducer";

export const loadAllPayTypes = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setData());

  const { userId } = store.data.auth.current.user;
  const payTypes = await dispatch(getAllPayTypeAPI(userId));

  dispatch(setData(payTypes || []));
};
