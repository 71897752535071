import React from "react";
import { useClientAccount } from "./useClientAccount";
import { ClientAccount, AccountOrderSide, AccountDefault } from "../../types";
import { Status } from "../../../../../data/models";
import { useCopiedState } from "../../../../../core/util/hooks";

export const createDefaultAccount = (clientId: string): ClientAccount => ({
	account: "",
	clientBankCode: "",
	defaultAcc: AccountDefault.False,
	forBuySell: AccountOrderSide.Both,
	clientId: clientId,
	status: Status.Active
});

export const useEditClientAccount = (clientId: string, accountId?: string) => {
	const [displayAccount] = useClientAccount(accountId);

	return React.useMemo(() => {
		if (accountId !== undefined && displayAccount === undefined) {
			return undefined;
        }

		if (accountId === undefined) {
			const defaultPerson = createDefaultAccount(clientId);
			return { ...defaultPerson };
        }

		return displayAccount ? { ...displayAccount } : undefined;
	}, [clientId, accountId, displayAccount]);
};

export const useEditClientAccountState = (clientId: string, clientPersonId?: string) => {
	const account = useEditClientAccount(clientId, clientPersonId);
	return useCopiedState(account)
};
