import { NavigationItem } from "../../../core/navigation/types";
import RolesPage from "./RolesPage";
import { EditRolePage } from "./EditRolePage";
import { rolesLocation, editRouter } from "./Locations";
export const rolesComponents: NavigationItem[] = [
	{
		Component: RolesPage,
		isMenuItem: true,
		path: rolesLocation,
	},
	{
		Component: EditRolePage,
		isMenuItem: false,
		path: editRouter,
	},
];
