import { DeliveryListData } from "../../../../../data/clearing/delivery/types";
import React from "react";
import { LocalizationContext } from "../../../../../core/localization/Localization";
import { Column } from "material-table";

export const useDeliveriesColumns = () => {
  const { getString, getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);

  return React.useMemo(
    () => [
      {
        title: getString("dlvrDeliveryDate"),
        render: (delivery) => getDateString(delivery.deliveryDate),
        sorting: false,
      },
      {
        title: getString("dlvrTradeNo"),
        field: "tradeNo",
        sorting: false,
      },
      {
        title: getString("dlvrsellerName"),
        field: "sellerName",
        sorting: false,
      },
      {
        title: getString("dlvrbuyerName"),
        field: "buyerName",
        sorting: false,
      },
      {
        title: getString("dlvrproductName"),
        field: "productName",
        sorting: false,
      },
      {
        title: getString("dlvrDeliverQty"),
        render: (delivery) => getFormatedNumberString(delivery.deliveryQty),
        sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
      },
      {
        title: getString("deliveryTradeQty"),
        render: (delivery) => getFormatedNumberString(delivery.tradeQty),
        sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
      },
      {
        title: getString("deliveryLeftQty"),
        render: (delivery) => getFormatedNumberString(delivery.leftQty),
        sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
      },
      {
        title: getString("dlvrValue"),
        render: (delivery) => getFormatedNumberString(delivery.deliveryAmt,true),
        sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
      },
    ],
    [getString, getDateString, getFormatedNumberString]
  ) as Column<DeliveryListData>[];
};
