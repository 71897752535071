import React from "react";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import {TableContainer,	Table,	TableHead,	TableCell,	TableRow,	Paper,	TableBody,	Typography,	Grid,} from "@material-ui/core";
import { OrderLogData } from "../../../data/trading/orders/types";
import { useAuth } from "../../../data/auth/hooks";
import { DiplayOrderMod } from "./DisplayOrderMainAttributes"

export interface DisplayOrderLogDataProps {
	orderlog?: OrderLogData[];
	mod: DiplayOrderMod;
}

export const DisplayOrderLogData: React.FunctionComponent<DisplayOrderLogDataProps> = ({ orderlog, mod }) => {
	const style = useDisplaySingleEmementStyles();
	// const [attributes] = useAttributes(orderId);

	return (
		<div className={style.root}>
			<div className={style.row}>
				{ mod === DiplayOrderMod.Full && ( <OrderLogDisplayTable orderlog={orderlog} />)}
			</div>
		</div>
	);
};

interface OrderLogDisplayTableProps {
	orderlog?: OrderLogData[];
}

export const OrderLogDisplayTable: React.FunctionComponent<OrderLogDisplayTableProps> = ({
	orderlog,
}) => {
	const { getString, getNumberString } = React.useContext(LocalizationContext);
//	const [values, displayValue] = useAttributeValues();

	const [auth] = useAuth();

	if (auth.status !== "successful") {
		return null;
	}

	if (!orderlog || orderlog.length === 0) {
		return (
			<Grid container justify="center">
					{ auth.user.roleCode !== "MEMTRADER" && auth.user.roleCode !== "MEMMANAGER" && auth.user.roleCode !== "MEMCONTACT" && ( 
				<Typography>{getString("orderLogDataEmpty")}</Typography> )}
			</Grid>
		);
	}

	return (
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell><b>{getString("orderLogDataTable")}</b></TableCell> 
					</TableRow>
				</TableHead>
				<TableBody>
					{orderlog &&
						orderlog.map(orderlog => (
							<TableRow >
{/*								<TableCell>{attribute.attrName}</TableCell>  */}
								<TableCell>
									{orderlog.changeTime}
								</TableCell>
								<TableCell>
									{orderlog.userName}
								</TableCell>
								<TableCell>
									{orderlog.activity}
								</TableCell>
								<TableCell>
									{orderlog.status}
								</TableCell>
{/*								<TableCell>
									{orderlog.refOrder}
</TableCell>   */}
							</TableRow>  
						))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
