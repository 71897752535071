import { webApi } from "../../../../core/http/httpThunks";

export const deleteCityApi = (userId: string, cityId: string) =>
  webApi<string>({
    method: "DELETE",
    url: "listing/delcity",
    params: {
      userId,
      cityId,
    },
  });

export default deleteCityApi;
