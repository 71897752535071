import { useCopiedState } from "../../../../core/util/hooks";
import { setFilter, removeFilter, initialState } from "../annualTradeUXSlice";
import { setData } from "../annualTradeDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { AnnualTradingReportFilter } from "../types";

export const useATRFilter = () => {
  const storeFilter = useSelector<StoreType, AnnualTradingReportFilter>(
    (store) => store.ux.reports.annualTrade.filter
  );

  const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<
    AnnualTradingReportFilter
  >(storeFilter);

  const dispatch = useDispatch();

  const dispatchSetFilter = React.useCallback(
    (numofyears: number) => {
      dispatch(setFilter(numofyears));
      dispatch(setData());
    },
    [dispatch]
  );

  const dispatchResetFilter = React.useCallback(() => {
    setCurrentFilter(initialState.filter);
  }, [setCurrentFilter]);

  return [
    filter,
    editFilter,
    dispatchSetFilter,
    changeState,
    dispatchResetFilter,
  ] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter,
    typeof changeState,
    typeof dispatchResetFilter
  ];
};
