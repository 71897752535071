import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Bank } from "../types";
import { loadBank } from "../thunks/loadBank";

export const useDisplayBank = (bankId?: string) => {
  const storeBank = useSelector<StoreType, Bank | undefined>(
    (store) => store.data.listing.banks.displayedBank
  );

  const displayedBank =
    storeBank && storeBank.bankId === bankId ? storeBank : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (bankId) {
      displatch(loadBank(bankId));
    }
  }, [displatch, bankId]);

  return displayedBank;
};
