import { ThunkType } from "../../../../core/store/reducers";
import createTransportFeeApi from "../api/createTransportFee";
import updateTransportFeeApi from "../api/updateTransportFee";
import { setFetchState } from "../transportFeesDataReducer";
import { TransportFee } from "../types";

export const upsertTransportFee = (trfee: TransportFee): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = trfee.id
    ? await dispatch(updateTransportFeeApi(userId, trfee))
    : await dispatch(createTransportFeeApi(userId, trfee));

  if (result && result.resCode === "OK") {
    dispatch(setFetchState({ type: "successful", result: result.resDesc }));
    return result.resDesc;
  } else {
    dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
  }
};
