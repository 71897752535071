import MonthlyTradesReport from "./MonthlyTradesReport";
import { NavigationItem } from "../../../../core/navigation/types";
import { dailyTradesReportLocation, } from "./Locations";

export const monthlyTradesReportComponents: NavigationItem[] = [
	{
		Component: MonthlyTradesReport,
		isMenuItem: true,
		path: dailyTradesReportLocation,
	},
];
