import { useCopiedState } from "../../../../core/util/hooks";
import { setFilter, removeFilter, initialState } from "../deliveryUXSlice";
import { invalidateDelivery } from "../deliveryDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { DeliveryFilter } from "../types";

export const useDeliveryFilter = () => {

  const [storeFilter] = useCurrentDeliveryilter();

  const [filter, editFilter, changeState,setCurrentFilter] = useCopiedState<DeliveryFilter>(
    storeFilter
  );

  const dispatch = useDispatch();
  const dispatchSetFilter = React.useCallback(() => {
    dispatch(setFilter(filter!));
    dispatch(invalidateDelivery());
  }, [dispatch, filter]);

  const dispatchResetFilter = React.useCallback(() => {
    setCurrentFilter(initialState.filter);
  }, [setCurrentFilter]);

  return [filter, editFilter, dispatchSetFilter, changeState, dispatchResetFilter ] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter,
    typeof changeState,
    typeof dispatchResetFilter,
  ];
};

export const useCurrentDeliveryilter = () => {
  const filter = useSelector<StoreType, DeliveryFilter>(
    (store) => store.ux.clearing.deliveries.filter
  );
  const dispatch = useDispatch();
  const dispatchRemoveFilter = React.useCallback(
    (type: keyof DeliveryFilter) => {
      dispatch(removeFilter(type));
      dispatch(invalidateDelivery());
    },
    [dispatch]
  );
  
  return [filter, dispatchRemoveFilter] as [
    typeof filter,
    typeof dispatchRemoveFilter
  ];
};
