import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TradeFilter } from "../../trading/trades/types";
import { WeeklyTradingReportFilter } from "./types";
import { PageIndex } from "../../models";

type TradesReportState = {
  filter: WeeklyTradingReportFilter;
  index: PageIndex;
};

export const initialState: TradesReportState = {
  filter: {
    numofweeks: 8,
  },
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
};

const name = "weeklyTrade";

const weeklyTrade = createSlice({
  name,
  initialState,
  reducers: {
    setIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    setReportData: (state, action: PayloadAction<number>) => ({
      ...state,
      filter: {
        numofweeks: action.payload,
      },
    }),
    setReportWeek: (state, action: PayloadAction<number>) => ({
      ...state,
      filter: {
        ...state.filter,
        yearPart: action.payload,
      },
    }),
    setReportYear: (state, action: PayloadAction<number>) => ({
      ...state,
      filter: {
        ...state.filter,
        year: action.payload,
      },
    }),
    setFilter: (state, action: PayloadAction<number>) => ({
      ...state,
      filter: {
        ...state.filter,
        numofweeks: action.payload,
      },
    }),
    removeFilter: (state, action: PayloadAction<keyof TradeFilter>) => ({
      ...state,
      filter: {
        ...state.filter,
        [action.payload]: undefined,
      },
    }),
  },
});

export const {
  setReportData,
  setReportWeek,
  setReportYear,
  setFilter,
  removeFilter,
  setIndex,
} = weeklyTrade.actions;

export default weeklyTrade.reducer;
