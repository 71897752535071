import { TransportFee, TransportFeeValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const transportfeeValidationState: TransportFeeValidationState = {
  startDate: true,
  price: true,
  distance: true,
  status: true,
};

export const useTransportFeeValidator = createUseValidator<
  TransportFee,
  TransportFeeValidationState
>(transportfeeValidationState);
