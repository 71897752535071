import React from "react";
import {
  Grid,
  CardContent,
  CardHeader,
  Divider,
  Card,
  Fab,
} from "@material-ui/core";
import TransportFeesTable from "./TransportFeesTable";
import { usePageStyles } from "../../pageStyles";
import { SearchBox } from "../../components/common/SearchBox";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import AddIcon from "@material-ui/icons/Add";
import { useTransportFeeSearch } from "../../../data/clearing/transportfees/hooks/useTransportFeeSearch";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../../components/common/PageTitle";
import { getTransportFeesEditLocation } from "./Locations";

const TransportFeesPage: PageComponent = ({ userAccessRights }) => {
  const pageStyles = usePageStyles();

  const [search, setSearch] = useTransportFeeSearch();
  const history = useHistory();

  return (
    <Grid container className={pageStyles.root}>
      <Grid item xs={12} className={pageStyles.gridItem}>
        <Card>
          <CardHeader
            title={<PageTitle title="transFeeTitle" />}
            action={<SearchBox search={search} setSearch={setSearch} />}
          />
          <Divider />
          <CardContent>
            <TransportFeesTable userAccessRights={userAccessRights} />
          </CardContent>
        </Card>
        {userAccessRights === AccessRights.All && (
          <Fab
            color="secondary"
            aria-label="add"
            className={pageStyles.mainFab}
            onClick={() => history.push(getTransportFeesEditLocation())}
          >
            <AddIcon />
          </Fab>
        )}
      </Grid>
    </Grid>
  );
};

export default TransportFeesPage;
