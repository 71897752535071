import { ThunkType } from "../../../../core/store/reducers";
import { setData } from "../notificationDataSlice";
import getPushNotificationsListApi from "../api/getPushnotificationsList";

export const loadReport = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const user = store.data.auth.current.user;

  const pagedNotifications = await dispatch(getPushNotificationsListApi(user));

  dispatch(setData(pagedNotifications || []));
};
