import { ThunkType } from "../../../../../core/store/reducers";
import { getPaymentsReportExcel } from "../api/getPaymentsReportExcel";
import fileDownload from "js-file-download";
import { setFetchState } from "../clientOverviewDataSlice";

export const downloadPaymentsReportExcel = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { filter } = store.ux.reports.exchbusiness.clientOverview;
	const user = store.data.auth.current.user;
	dispatch(setFetchState({ type: "started" }));
	const ordersReport = await dispatch(getPaymentsReportExcel(user, filter));
	if (ordersReport) {
		fileDownload(
			new Blob([ordersReport as any], { type: "application/vnd.openxmlformat" }),
			"Payments.xlsx"
		);
	}
	dispatch(setFetchState({ type: "not-started" }));
};
