import { ThunkType } from "../../../../core/store/reducers";
import getAllBanksAPI from "../api/getAllBanks";
import { setData } from "../banksDataReducer";

export const loadAllBanks = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setData());

  const { userId } = store.data.auth.current.user;
  const banks = await dispatch(getAllBanksAPI(userId));

  dispatch(setData(banks || []));
};
