import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { Employee } from "../../../data/listing/employees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewEmployee } from "./DisplayEmployee";
import { useEmployeeDelete } from "../../../data/listing/employees/hooks/useEmployeeDelete";
import { getEmployeeEditLocation } from "./Locations";
import { AccessRights } from "../../../core/navigation/types";

export const useEmployeeActions = (userAccessRights: AccessRights) => {
	const { getString } = useContext(LocalizationContext);
	const onShowEmployee = useViewEmployee();
	const onEditEmployee = useEditEmployeeAction();

	return useMemo(() => {
		const result: Action<Employee>[] = [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tableShow"),
				onClick: (event, rowData) => onShowEmployee(rowData as Employee),
			},
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditEmployee(rowData as Employee),
				hidden: userAccessRights===AccessRights.ReadOnly
			},
		];

		return result;
	}, [onShowEmployee, onEditEmployee, getString]);
};

export const useDeleteEmployeeAction = () => {
	const [, deleteEmployee] = useEmployeeDelete();
	return React.useCallback(
		async (employee: Employee) => {
			if (employee.empId) {
				await deleteEmployee(employee.empId);
			}
		},
		[deleteEmployee]
	);
};

export const useEditEmployeeAction = () => {
	const history = useHistory();
	return React.useCallback(
		(employee: Employee) => history.push(getEmployeeEditLocation(employee.empId)),
		[history]
	);
};
