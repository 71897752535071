import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllRoles } from "../thunks/loadAllRoles";
import { Role } from "../types";

export const useRoleData = () => {
	const data = useSelector<StoreType, Role[] | undefined>(s => s.data.listing.roles.displayData);
	const allData = useSelector<StoreType, Role[] | undefined>(s => s.data.listing.roles.all);

	const dispatch = useDispatch();
	const dispatchLoadAllRoles = React.useCallback(() => dispatch(loadAllRoles()), [dispatch]);

	return [data, dispatchLoadAllRoles, allData] as [
		typeof data,
		typeof dispatchLoadAllRoles,
		typeof allData
	];
};

export const useRole = (id?: string) => {
	const allData = useSelector<StoreType, Role[] | undefined>(s => s.data.listing.roles.all);

	const dispatch = useDispatch();
	const dispatchLoadAllRoles = React.useCallback(() => dispatch(loadAllRoles()), [dispatch]);

	React.useEffect(() => {
		if (!allData && id) {
			dispatchLoadAllRoles();
		}
	}, [allData, id, dispatchLoadAllRoles]);

	const role = React.useMemo(() => {
		if (!allData) {
			return undefined;
		} else {
			return allData?.find(e => e.roleId === id);
		}
	}, [id, allData]);

	return [role] as [typeof role];
};
