import { getEditLocation } from "../../../core/util/functions";
export const orderBookLocation = "/trading/orders"

export enum OrdersEditParts {
	BasicInfo = "basicinfo",
	Attributes = "attributes",
}

export const getOrderBookEditLocation = (
	part: OrdersEditParts = OrdersEditParts.BasicInfo,
	productId?: string,
	attributeId?: string
) => getEditLocation(orderBookLocation, part, productId, attributeId);

export const baseEdit = getOrderBookEditLocation(":editPart" as any, ":orderId?");
export const atributesEdit = getOrderBookEditLocation(
	OrdersEditParts.Attributes,
	":orderId?",
	":attributeId?"
);