import { PushNotification } from "../../../data/reports/notifications/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const usePushNotificationsColumns = () => {
  const { getString, getDateString, getDateTimeString, getFormatedNumberString } = React.useContext(
    LocalizationContext
  );

  
  const theme = useTheme();
  const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

  return React.useMemo(
    () => [
      {
        title: getString("contractDate"),
				render: (notification) => getDateTimeString(notification.pnTime),
        sorting: false,
        width: screenExtraSmall ? "50px" : "90px",
				cellStyle: {
					maxWidth: screenExtraSmall ? "50px" : "90px",
					minWidth: screenExtraSmall ? "50px" : "90px",
					width: screenExtraSmall ? "50px" : "90px",
				},
				headerStyle: {
					maxWidth: screenExtraSmall ? "50px" : "90px",
					minWidth: screenExtraSmall ? "50px" : "90px",
					width: screenExtraSmall ? "50px" : "90px",
				},
      },
      {
        title: getString("orderOrdType")  ,
        field: "pnTitle",
        sorting: false,
        width: screenExtraSmall ? "60px" : "80px",
				cellStyle: {
					maxWidth: screenExtraSmall ? "60px" : "80px",
					minWidth: screenExtraSmall ? "60px" : "80px",
					width: screenExtraSmall ? "60px" : "80px",
				},
				headerStyle: {
					maxWidth: screenExtraSmall ? "60px" : "80px",
					minWidth: screenExtraSmall ? "60px" : "80px",
					width: screenExtraSmall ? "60px" : "80px",
				},
      },

      {
        title: getString("pushNotification"),
        field: "pnText",
        sorting: false,
        width: screenExtraSmall ? "500px" : "350px",
				cellStyle: {
					maxWidth: screenExtraSmall ? "500px" : "350px",
					minWidth: screenExtraSmall ? "500px" : "350px",
					width: screenExtraSmall ? "500px" : "350px",
				},
				headerStyle: {
					maxWidth: screenExtraSmall ? "500px" : "350px",
					minWidth: screenExtraSmall ? "500px" : "350px",
					width: screenExtraSmall ? "500px" : "350px",
				},
      },
    ],
    [getString, getFormatedNumberString]
  ) as Column<PushNotification>[];
};
