import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { Region } from "../../models";

const getSubRegionLabel = (subRegion?: Region) => (subRegion ? subRegion.regName : "");

export const useTopRegions = () => {
	const topRegions = useSelector<StoreType, Region[]>(store => store.data.initialData.topRegions);

	return [topRegions, getSubRegionLabel] as [typeof topRegions, typeof getSubRegionLabel];
};
