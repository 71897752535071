import { getEditLocation } from "../../../core/util/functions";

export enum AttributeEditParts {
	BasicInfo = "basicinfo",
	Values = "values",
}

export const attributesLocation = "/listing/codebook/attributes";

export const getAttributeEditLocation = (
	part: AttributeEditParts = AttributeEditParts.BasicInfo,
	attributeId?: string,
	valueId?: string
) => getEditLocation(attributesLocation, part, attributeId, valueId);

export const basicInfoEdit = getAttributeEditLocation(":editPart" as any, ":attributeId?");
export const valuesEdit = getAttributeEditLocation(AttributeEditParts.Values, ":attributeId", ":valueId?");
