import React from "react";
import { useCheckPointPayers } from "../../../data/initialData/hooks/useCheckPointPayers";
import { CheckPointPayer } from "../../../data/models";
import {
  AutocompleteSelect,
  AutocompleteSelectType,
} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<CheckPointPayer>,
  "data" | "getLabel"
>;

export const CheckPointPayerSelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [checkPoints, renderLabel] = useCheckPointPayers();

  return (
    <AutocompleteSelect<CheckPointPayer>
      data={checkPoints}
      getLabel={renderLabel}
      {...(props as any)}
    />
  );
};
