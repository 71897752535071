import React from "react";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../../components/common/DisplayBoolNumber";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { useAttributeValuesData } from "../../../../data/listing/attributes/hooks/attributeValues/useAttributeValuesData";
import { AttributeValue } from "../../../../data/listing/attributes/types";
import {
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	TableBody,
	Typography,
	Grid,
} from "@material-ui/core";
import { Status } from "../../../../data/models";

export interface DisplayAttributeValuesProps {
	attributeId: string;
}

export const DisplayAttributeValues: React.FunctionComponent<DisplayAttributeValuesProps> = ({
	attributeId,
}) => {
	const style = useDisplaySingleEmementStyles();
	const [values] = useAttributeValuesData(attributeId);

	return (
		<div className={style.root}>
			<div className={style.row}>
				{values ? <AttributesDisplayTable values={values} /> : "loading placeholder"}
			</div>
		</div>
	);
};

interface AttributesDisplayTableProps {
	values: AttributeValue[];
}

const AttributesDisplayTable: React.FunctionComponent<AttributesDisplayTableProps> = ({ values }) => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);

	if (values.length === 0) {
		return (
			<Grid container justify="center">
				<Typography>{getString("productAttributeEmpty")}</Typography>
			</Grid>
		);
	}

	return (
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>{getString("attributeValueName")}</TableCell>
						<TableCell>{getString("attributeValueValue")}</TableCell>
						<TableCell>{getString("attributeValueStatus")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{values.map(value => (
						<TableRow key={value.attrValueId}>
							<TableCell>{value.name}</TableCell>
							<TableCell>{value.value}</TableCell>
							<TableCell>
								<DisplayBoolNumber
									value={value.status}
									thrutyValue={Status.Active}
									className={style.icon}
								></DisplayBoolNumber>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
