import React from "react";
import { Table, TableDataProps } from "../../../components/common/Table";
import { useClientContracts } from "../../../../data/listing/clients/hooks/clientContract/useClientContracts";
import { useClientContractDelete } from "../../../../data/listing/clients/hooks/clientContract/useClientContractDelete";
import { ClientContractList } from "../../../../data/listing/clients/types";
import { Column, Action } from "material-table";
import { DisplayBoolNumber } from "../../../components/common/DisplayBoolNumber";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import EditIcon from "@material-ui/icons/EditOutlined";
import { ClientEditParts, getClientsEditLocation } from "../Locations";
import { useHistory } from "react-router-dom";
import { Fab } from "@material-ui/core";
import { usePageStyles } from "../../../pageStyles";
import AddIcon from "@material-ui/icons/Add";
import ViewIcon from "@material-ui/icons/InputOutlined";
import { Status } from "../../../../data/models";
import { useViewContract } from "./DisplayContract";
import { useAuth } from "../../../../data/auth/hooks";

export interface ClientContractTableProps {
	clientId: string;
}

type ClientContractTablePropsType = ClientContractTableProps & TableDataProps<ClientContractList>;

export const ClientContractTable: React.FunctionComponent<ClientContractTablePropsType> = ({
	clientId,
	...tableProps
}) => {
	const pageStyles = usePageStyles();
	const history = useHistory();

	const [auth] = useAuth();

	const [data, reload] = useClientContracts(clientId);

	const columns = useClientContractColumns();
	const actions = useClientContractActions(clientId);
	const onDeleteContract = useDeleteClientContractAction();

	return (
		<>
			<Table<ClientContractList>
				{...tableProps}
				getData={() => {}}
				immutableData={data}
				title=""
				columns={columns}
				actions={actions}
				options={{
					toolbar: false,
					pageSize: 5,
					actionsColumnIndex: -1,
				}}
				editable={ auth.status === "successful" && auth.user.roleCode === "ADMIN" ? {
					onRowDelete: async contract => {
						await onDeleteContract(contract);
						reload();
					},
				} : {} }
			/>
			{auth.status === "successful" && auth.user.roleCode === "ADMIN" && (
			<Fab
				color="secondary"
				aria-label="add"
				className={pageStyles.mainFab}
				onClick={() => history.push(getClientsEditLocation(ClientEditParts.Contracts, clientId, ""))}
			>
				<AddIcon />
			</Fab> )}
		</>
	);
};

export default ClientContractTable;

const useClientContractColumns = () => {
	const style = useDisplaySingleEmementStyles();
	const { getString, getDateString } = React.useContext(LocalizationContext);
	return React.useMemo(
		() => [
			{
				title: getString("contractNumber"),
				field: "contrNo",
			},
			{
				title: getString("contractDate"),
				render: contract => getDateString(contract.contrDate),
			},
			{
				title: getString("contractLincedContrNo"),
				field: "linkedContrNo",
			},
			{
				title: getString("contractTypeDescription"),
				field: "contrTypeDescr",
			},
			{
				title: getString("contractDescription"),
				field: "contrDescr",
			},
			{
				title: getString("contractStatus"),
				render: contract => (
					<DisplayBoolNumber
						value={contract.status}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				),
				customSort: (a, b) => a.status - b.status,
			},
		],
		[getString, style.icon, getDateString]
	) as Column<ClientContractList>[];
};

const useDeleteClientContractAction = () => {
	const [, deleteContract] = useClientContractDelete();
	return React.useCallback(
		async (contract: ClientContractList) => {
			if (contract.cliContrId) {
				await deleteContract(contract.cliContrId);
			}
		},
		[deleteContract]
	);
};

export const useClientContractActions = (clientId: string) => {
	const { getString } = React.useContext(LocalizationContext);
	const onEditContract = useEditClientContractAction(clientId);
	const onShowContract = useViewContract();

	const [auth] = useAuth();

	return React.useMemo(() => {
		const result: Action<ClientContractList>[] = [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tableShow"),
				onClick: (event, rowData) => onShowContract(rowData as ClientContractList),
			  },
					{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditContract(rowData as ClientContractList),
				hidden: auth.status === "successful" && auth.user.roleCode !== "ADMIN",
			},
		];

		return result;
	}, [getString, onEditContract]);
};

export const useEditClientContractAction = (clientId: string) => {
	const history = useHistory();
	return React.useCallback(
		(contract: ClientContractList) =>
			history.push(getClientsEditLocation(ClientEditParts.Contracts, clientId, contract.cliContrId)),
		[history, clientId]
	);
};
