import { webApi } from "../../../../../core/http/httpThunks";

export const deleteClientAccountApi = (userId: string, climonaccid: string) =>
	webApi<string>({
		method: "DELETE",
		url: "listing/delclimonacc",
		params: {
			userId,
			climonaccid
		},
	});

export default deleteClientAccountApi;
