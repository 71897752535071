import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { Role } from "../../../data/listing/roles/types";
import React from "react";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";

export function useRoleColumns() {
	const { getString } = useContext(LocalizationContext);
	return useMemo(
		() => [
			{
				title: getString("roleCode"),
				field: "roleCode"
			},
			{
				title: getString("roleNameColumn"),
				field: "roleName"
			},
			{
				title: getString("roleIsEmployee"),
				render: role => <DisplayBoolNumber value={role.isEmployee} thrutyValue={1} />,
				customSort: (a, b) => a.isEmployee - b.isEmployee,
				width: "80px",
			},
		],
		[getString]
	) as Column<Role>[];
}
