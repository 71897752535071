import React from "react";
import { useDisplayNews } from "../../../data/reports/news/hooks/useDisplayNews";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { NewsData,} from "../../../data/reports/news/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayNewsMainAttributes } from "./DisplayNewsMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,
  IconButton,
} from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import PrintIcon from "@material-ui/icons/PrintOutlined";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { useNewsDownload } from "../../../data/reports/news/hooks/useNewsDownload";

export interface DisplayNewsProps {
  id?: string;
  enablePrint?: boolean;
}

export const DisplayNews: React.FunctionComponent<DisplayNewsProps> = ({
  id,
  enablePrint,
}) => {
  const news = useDisplayNews(id);
  const { getString } = React.useContext(LocalizationContext);
	const [download, fetchState, setfetch] = useNewsDownload(news);

  return !news ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Grid container justify="space-between">
            <Grid item>
              <Typography>{getString("tableBasciInfo")}</Typography>
            </Grid>
            <Grid item>
            <CustomTooltip titleKeyword="exportPDFTooltip">
									<IconButton
										color="secondary"
										component="span"
										onClick={download}
										disabled={fetchState.type !== "not-started"}
									>
										<DownloadIcon />
									</IconButton>
								</CustomTooltip>
                { fetchState.type !== "not-started"  ? getString("pleaseWait") : null }
							</Grid>
            </Grid>
        </ExpansionPanelSummary>
        <DisplayNewsMainAttributes news={news} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewNews = (enablePrint?: boolean) => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (news: NewsData) =>
      showWithDrawer(
        <DisplayNews id={news.newsId} enablePrint={enablePrint} />
      ),
    [showWithDrawer]
  );
};
