import React from "react";
import { CardContent, Card, Typography } from "@material-ui/core";
import { useTickerData } from "../../../data/trading/ticker/hooks/useTickerData";
import { LocalizationContext } from "../../../core/localization/Localization";
import Ticker from "react-ticker";
import { usePageVisibility } from 'react-page-visibility'

export const PriceTicker: React.FunctionComponent = () => {
	const { getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);
	const isVisiable = usePageVisibility()
	const [data] = useTickerData(isVisiable);
	const [move, setMove] = React.useState<boolean>(true);
	console.log("Ticker visiable: ", isVisiable)

	return (
		<>
			{data && data.length > 0 && (
				<Card>
					<CardContent
						style={{ padding: "8px" }}
						onMouseEnter={() => setMove(false)}
						onMouseLeave={() => setMove(true)}
					>
						<Ticker move={move} speed={3}>
							{({ index }) => {
								const priceData = data[index % data.length];
								return (
									<>
										<div style={{ marginLeft: "16px" }}>
											<Typography>{`${priceData.prodName} (${getDateString(priceData.tradeTime, "DD.MM.")}): ${getFormatedNumberString(priceData.price, true)}`}</Typography>
										</div>
									</>
								);
							}}
						</Ticker>
					</CardContent>
				</Card>
			)}
		</>
	);
};
