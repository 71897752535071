import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { Warehouse } from "../../../data/listing/warehouses/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewWhs } from "./DisplayWhs";
import { useWhsDelete } from "../../../data/listing/warehouses/hooks/useWhsDelete";
import { getWhsEditLocation } from "./Locations";
import { AccessRights } from "../../../core/navigation/types";

export const useWhsActions = (userAccessRights: AccessRights) => {
  const { getString } = useContext(LocalizationContext);
  const onShowWhs = useViewWhs();
  const onEditWhs = useEditWhsAction();

  return useMemo(() => {
    const result: Action<Warehouse>[] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("tableShow"),
        onClick: (event, rowData) => onShowWhs(rowData as Warehouse),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) => onEditWhs(rowData as Warehouse),
        hidden: userAccessRights===AccessRights.ReadOnly
      },
    ];

    return result;
  }, [onShowWhs, onEditWhs, getString]);
};

export const useDeleteWhsAction = () => {
  const [, deleteWhs] = useWhsDelete();
  return React.useCallback(
    async (whs: Warehouse) => {
      if (whs.whsId) {
        await deleteWhs(whs.whsId);
      }
    },
    [deleteWhs]
  );
};

export const useEditWhsAction = () => {
  const history = useHistory();
  return React.useCallback(
    (whs: Warehouse) =>
      history.push(getWhsEditLocation(whs.whsId)),
    [history]
  );
};
