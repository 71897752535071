import { webApi } from "../../../../core/http/httpThunks";
import { Region } from "../types";

export const getRegApi = (userId: string, regid: string) =>
  webApi<Region>({
    method: "GET",
    url: "listing/getreg",
    params: {
      userId,
      regid,
    },
  });

export default getRegApi;
