import React from "react";
import { useDisplayRegion } from "../../../data/listing/regions/hooks/useDisplayRegion";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { Region } from "../../../data/listing/regions/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayRegionMainAttributes } from "./DisplayRegionMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,
} from "@material-ui/core";

export interface DisplayRegionProps {
  id?: string;
}

export const DisplayRegion: React.FunctionComponent<DisplayRegionProps> = ({
  id,
}) => {
  const region = useDisplayRegion(id);
  const { getString } = React.useContext(LocalizationContext);


  return !region ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Typography>{getString("tableBasciInfo")}</Typography>
        </ExpansionPanelSummary>
        <DisplayRegionMainAttributes region={region} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewRegion = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (region: Region) => showWithDrawer(<DisplayRegion id={region.regId} />),
    [showWithDrawer]
  );
};
