import React from "react";
import { useDispatch } from "react-redux";
import { getSimpleOrdersThunk } from "../thunks/getSimpleOrdersThunk";
import { SimpleOrderFilter, OrderSimpleData } from "../types";

export const useSimpleOrdersData = (filter?: SimpleOrderFilter) => {
	const dispatch = useDispatch();
	const [orders, setOrders] = React.useState<OrderSimpleData[] | undefined>();

	React.useEffect(() => {
		const loadOrders = async () => {
			if (filter) {
				const loaded = ((await dispatch(getSimpleOrdersThunk(filter))) as any) as OrderSimpleData[];
				setOrders(loaded);
			}
		};
		if (!filter) {
			setOrders(undefined);
		} else {
			loadOrders();
		}
	}, [filter, dispatch]);

	return [orders] as [typeof orders];
};
