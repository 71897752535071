import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent, Tabs, Tab } from "@material-ui/core";
import { EditClientBasicInfo } from "./EditClientBasicInfoPage";
import ClientPersonsTable from "./clientPersons/ClientPersonsTable";
import { ClientBrokerTable } from "./clientBrokers/ClientBrokerTable";
import { ClientContractTable } from "./clientContracts/ClientContractTable";
import { useEditClientState } from "../../../data/listing/clients/hooks/useEditClientState";
import { ClientAccountTable } from "./clientAccounts/ClientAccountTable";
import { PageTitle } from "../../components/common/PageTitle";
import { clientsLocation, ClientEditParts, getClientsEditLocation } from "./Locations";

export interface EditClientInfo {
	editPart?: string;
	clientId?: string;
}

export interface EditClientProps extends RouteComponentProps<EditClientInfo> {}

export const EditClientPage: React.FunctionComponent<EditClientProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();
	const { editPart, clientId } = props.match.params;

	const history = useHistory();
	const [client, editClient, changed] = useEditClientState(clientId);

	const handleSideChange = React.useCallback(
		(event: React.ChangeEvent<{}>, newValue: any) => {
			history.push(getClientsEditLocation(newValue, clientId));
		},
		[history, clientId]
	);

	const pageTitle = clientId ? "cilEditTitle" : "cilCreateTitle";
	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={clientsLocation} />} />
					<Divider />
					<CardContent>
						<Tabs
							value={editPart}
							onChange={handleSideChange}
							indicatorColor="secondary"
							textColor="secondary"
							variant="fullWidth"
						>
							<Tab label={getString("tableBasciInfo")} value={ClientEditParts.BasicInfo} />
							<Tab
								label={getString("clientPersonTitle")}
								value={ClientEditParts.Persons}
								disabled={!client || !client.cliId}
							/>
							<Tab
								label={getString("clientBrokerTitle")}
								value={ClientEditParts.Brokers}
								disabled={!client || !client.cliId}
							/>
							<Tab
								label={getString("contractTitle")}
								value={ClientEditParts.Contracts}
								disabled={!client || !client.cliId}
							/>
							<Tab
								label={getString("accountTitle")}
								value={ClientEditParts.Accounts}
								disabled={!client || !client.cliId}
							/>
						</Tabs>
						<div style={{ marginTop: "20px" }}>
							{editPart === ClientEditParts.BasicInfo && client && (
								<EditClientBasicInfo
									client={client}
									editClient={editClient}
									changed={changed}
								/>
							)}
							{editPart === ClientEditParts.Persons && client && client.cliId && (
								<ClientPersonsTable clientId={client.cliId} />
							)}
							{editPart === ClientEditParts.Brokers && client && client.cliId && (
								<ClientBrokerTable clientId={client.cliId} />
							)}
							{editPart === ClientEditParts.Contracts && client && client.cliId && (
								<ClientContractTable clientId={client.cliId} />
							)}
							{editPart === ClientEditParts.Accounts && client && client.cliId && (
								<ClientAccountTable clientId={client.cliId} />
							)}
						</div>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
