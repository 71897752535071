import { ThunkType } from "../../../../core/store/reducers";
import getProductGroupApi from "../api/getProductGroup";
import { setDisplayed } from "../productGroupDataReducer";
import { ProductGroupData } from "../types";

export const loadProductGroup = (groupId: string): ThunkType => async (dispatch, getStore) => {
	dispatch(setDisplayed());

	const role = await dispatch(getProductGroup(groupId));

	dispatch(setDisplayed(role));
};

export const getProductGroup = (groupId: string): ThunkType<Promise<ProductGroupData | undefined>> => async (
	dispatch,
	getStore
) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getProductGroupApi(userId, groupId));
};
