import { ThunkType } from "../../../../../core/store/reducers";
import { getAnnualSecurityExcel } from "../api/getAnnualSecurityExcel";
import fileDownload from "js-file-download";
import { setFetchState } from "../annualSecurityDataSlice";

export const downloadAnnualSecurityExcel = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.reports.securityCommission.annualSecurity;
  const user = store.data.auth.current.user;
  dispatch(setFetchState({ type: "started" }));
  const dailyTrading = await dispatch(getAnnualSecurityExcel(user, filter));
  if (dailyTrading) {
    fileDownload(
      new Blob([dailyTrading as any], {
        type: "application/vnd.openxmlformat",
      }),
      "AnnualSecurityTrading.xlsx"
    );
  }
  dispatch(setFetchState({ type: "not-started" }));

};
