import { MemberContractsReportData } from "../types";
import { User } from "../../../../auth/types";
import { webApi } from "../../../../../core/http/httpThunks";

const getMemberContractsData = (user: User) => {
  return webApi<MemberContractsReportData[]>({
    method: "GET",
    url: "reporting/getclicontrlist",
    params: {
      userid: user.userId,
      status: 1,
    },
  });
};

export default getMemberContractsData;
