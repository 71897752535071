import React from "react";
import { useNextMessage } from "../../data/snackbar/hooks/useSnackbarMessage";
import { SnackbarProvider, useSnackbar } from "notistack";
import { CollapsableMessage } from "./CollapsableMessage";

export const SnackbarMessages: React.FunctionComponent = ({ children }) => (
	<SnackbarProvider
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "left",
		}}
		content={(key, message) => <CollapsableMessage id={key} message={message} />}
	>
		<>
			<MessageQueueManager />
			{children}
		</>
	</SnackbarProvider>
);

export const MessageQueueManager: React.FunctionComponent = () => {
	const [message] = useNextMessage(3);

	const { enqueueSnackbar } = useSnackbar();
	React.useEffect(() => {
		if (message !== undefined) {
			enqueueSnackbar(message.id, {
				persist: true,
			});
		}
	}, [message, enqueueSnackbar]);
	return null;
};
