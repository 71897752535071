import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { ContractType } from "../../models";

const getGroupLabel = (attribute?: ContractType) => (attribute ? attribute.ftDescr : "");

export const useContractTypes = () => {
	const groups = useSelector<StoreType, ContractType[]>(store => store.data.initialData.contractTypes);

	return [groups, getGroupLabel] as [typeof groups, typeof getGroupLabel];
};
