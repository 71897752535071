import { ThunkType } from "../../../../core/store/reducers";
import getInvoiceApi from "../api/getInvoice";
import { setDisplayed } from "../invoicesDataSlice";
import { InvoiceData } from "../types";

export const loadInvoice = (invId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const invoice = await dispatch(getInvoice(invId));

  dispatch(setDisplayed(invoice));
};

export const getInvoice = (
  invId: string
): ThunkType<Promise<InvoiceData | undefined>> => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getInvoiceApi(userId, invId));
};
