import React from "react";
import { GuaranteeDedication } from "../types";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { exhaustiveCheck } from "../../../../core/util/functions";

export const useGuaranteeDedicationOptionsTranslator = () => {
  const { getString } = React.useContext(LocalizationContext);
  return React.useCallback(
    (side: GuaranteeDedication) => {
      switch (side) {
        case GuaranteeDedication.yes:
          return getString("paymentSideDedicated");
        case GuaranteeDedication.no:
          return getString("paymentSideNonDedicated");
        default:
          exhaustiveCheck(side);
      }
    },
    [getString]
  );
};

interface AccountGuaranteeSideOption {
  label: string;
  value: GuaranteeDedication;
}

export const useGuaranteeDedicationOptions = (): AccountGuaranteeSideOption[] => {
  const translator = useGuaranteeDedicationOptionsTranslator();
  return React.useMemo(
    () => [
      {
        label: translator(GuaranteeDedication.yes) as string,
        value: GuaranteeDedication.yes,
      },
      {
        label: translator(GuaranteeDedication.no) as string,
        value: GuaranteeDedication.no,
      },
    ],
    [translator]
  );
};
