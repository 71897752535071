import { CreateInvoicesTranData, CreateInvoicesTranFilter } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getCICreateButton = (user: User, filter: CreateInvoicesTranFilter) => {
  return webApi<CreateInvoicesTranData[]>({
    method: "PUT",
    url: "accounting/createinvoices",
    params: {
      userid: user.userId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      status: 1,
    },
  });
};
export default getCICreateButton;
