import React from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { DateInput } from "../../components/common/DateInput";
import { LocalizationContext } from "../../../core/localization/Localization";
import { BankGuaranteeFilter } from "../../../data/clearing/bankguarantees/types";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { useAuth } from "../../../data/auth/hooks";
import { BankGuaranteesFilterChipsComponent } from "./BankGuaranteesFilterChips";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { BankSelect } from "../../components/common/BankSelect";

export enum BankGuaranteeFilterEditorType {
  Basic,
  Extended,
}

interface BankGuaranteeFilterEditorProps {
  filter?: BankGuaranteeFilter;
  editFilter: (prop: keyof BankGuaranteeFilter, value: any) => void;
  save: () => void;
  reset: () => void;
  type: BankGuaranteeFilterEditorType;
}

export const BankGuaranteeFilterEditor: React.FunctionComponent<BankGuaranteeFilterEditorProps> = ({
  filter,
  editFilter,
  save,
  type,
  reset
}) => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const { getString } = React.useContext(LocalizationContext);
  const [auth] = useAuth();


  return (
    <Paper
      style={{
        paddingLeft: "70px",
        paddingRight: "70px",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Grid container direction="column" spacing={6}>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={6}>
            <DateInput
              label={getString("exchDateFrom")}
              value={filter?.datefrom || null}
              onChange={(date) => editFilter("datefrom", date?.toISOString())}
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              label={getString("exchDateTo")}
              value={filter?.dateto || null}
              onChange={(date) => editFilter("dateto", date?.toISOString())}
            />
          </Grid>
          <Grid item xs={12}>
            <ClientSelect
              label={getString("orderClientName")}
              predicate={(client) => client.cliId === filter?.client?.cliId}
              onChangeItem={(client) =>
                editFilter("client", client)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <BankSelect
              label={getString("accountBank")}
              predicate={(bank) => bank.bankId === filter?.bank?.bankId}
              onChangeItem={(bank) =>
                editFilter("bank", bank)
              }
            />
          </Grid>
        </Grid>

        <Grid item container direction="row-reverse" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                save();
                showWithDrawer(undefined);
              }}
            >
              {getString("orderFilterConfirm")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text" onClick={reset}>
              {getString("filterReset")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const BankGuaranteesFilterChips = BankGuaranteesFilterChipsComponent;
