import { webApi } from "../../../../core/http/httpThunks";
import { Employee } from "../types";

export const getAllEmployeesWebApi = (userId: string) =>
	webApi<Employee[]>({
		method: "GET",
		url: "listing/getemplist",
		params: {
			userId,
		},
	});

export default getAllEmployeesWebApi;
