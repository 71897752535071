
import React from "react";
import { useNewsColumns } from "./useNewsColumns";
import { NewsData } from "../../../data/reports/news/types";
import { PaginationTable, PaginationDataProps,} from "../../components/common/PagginationTable";
import { useNewsActions, useDeleteNewsAction,} from "./useNewsActions";
import { AccessRights } from "../../../core/navigation/types";

export interface NewsTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}


type NewsTablePropsType = NewsTableProps &
  PaginationDataProps<NewsData>;

const NewsTable: React.FunctionComponent<NewsTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = useNewsActions();
  const columns = useNewsColumns();
  const onDeleteNews = useDeleteNewsAction();

  return (
    <PaginationTable<NewsData>
      {...tableProps}
      getData={getData}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
//      editable={{
//        onRowDelete: onDeleteNews,
//      }}

    />
  );
};

export default NewsTable;
