import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteClient } from "../thunks/deleteClient";
import { setFetchStateClient } from "../clientsDataReducer";
import { setDeleteFetchState } from "../clientsDataReducer";

export const useClientDelete = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.clients.deleteFetchState
	);

	const dispatch = useDispatch();
	const dispatchDeleteEmployee = React.useCallback(
		(id: string) => dispatch(deleteClient(id)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setDeleteFetchState({ type: "not-started" }))
	}, [dispatch])

	const resetState = React.useCallback(() => dispatch(setDeleteFetchState({ type: "not-started" })), [
		dispatch,
	]);

	
	return [fetchState, dispatchDeleteEmployee, resetState] as [typeof fetchState, typeof dispatchDeleteEmployee, typeof resetState]
};
