import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { resetClientOverviewIdices } from "../clientOverviewUXSlice";
import { PageIndex } from "../../../../models";
import { TableType } from "../types";
import { setCOIndex } from "../thunks/setCOIndexThunk";

export const useClientOverviewIndex = (type: TableType) => {
	const pageIndex = useSelector<StoreType, PageIndex>(s => s.ux.reports.exchbusiness.clientOverview.index[type]);

	const dispatch = useDispatch();
	const dispatchSetIndex = React.useCallback((index: PageIndex) => dispatch(setCOIndex(type, index)), [
		dispatch,
		type,
	]);

	const dispatchResetIndex = React.useCallback(() => dispatch(resetClientOverviewIdices(type)), [
		dispatch,
		type,
	]);

	return [pageIndex, dispatchSetIndex, dispatchResetIndex] as [
		typeof pageIndex,
		typeof dispatchSetIndex,
		typeof dispatchResetIndex
	];
};
