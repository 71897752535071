import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { Commission, CommissionStandard } from "../../../data/listing/commissions/types";
import React from "react";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";

export function useProductColumns() {
	const { getString } = useContext(LocalizationContext);
	return useMemo(
		() => [
			{
				title: getString("commissionName"),
				field: "commName",
			},
			{
				title: getString("commissionCode"),
				field: "commCode",
			},
			{
				title: getString("commissionType"),
				field: "commTypeName",
			},
			{
				title: getString("commissionStandard"),
				render: commission => <DisplayBoolNumber value={commission.isStandard} thrutyValue={CommissionStandard.True} />,
				customSort: (a, b) => a.isStandard - b.isStandard,
				width: "80px",
			},
		],
		[getString]
	) as Column<Commission>[];
}
