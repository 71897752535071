import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Product } from "../types";

export const createProductApi = (userId: string, product: Product) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/newprod",
		params: {
			userId
		},
		data: product
	});

export default createProductApi;
