import { ProductAttribute } from "../../types";
import { ValidationState } from "../../../../../core/util/types";
import { createUseValidator } from "../../../../../core/util/hooks";

export const AttributeValidation: ValidationState<ProductAttribute> = {
	attributeId: true,
};

export const useAttributeValidator = () => {
	return createUseValidator<ProductAttribute, ValidationState<ProductAttribute>>(
		AttributeValidation,
		(item, validation) => ({
			...validation,
			hasStandard:  (item.hasStandard === 0 &&  (item.standardValue===0 || item.standardValue===undefined)) || (item.hasStandard === 1 &&  item.standardValue!==0  &&  item.standardValue !== undefined ),
		})
	)();
};


