import React from "react";
import { useOrderNotes } from "../../../data/initialData/hooks/useOrderNotes";
import { OrderNote } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType } from "./AutocompleteSelect";

type OrderNoteSelectPropsType = Omit<AutocompleteSelectType<OrderNote>, "data" | "getLabel">;

export const OrderNoteSelect: React.FunctionComponent<OrderNoteSelectPropsType> = props => {
	const [notes, renderLabel] = useOrderNotes();

	return (
		<AutocompleteSelect<OrderNote> data={notes} getLabel={renderLabel} {...(props as any)} />
	);
};
