import React from "react";
import { AutocompleteSelect, AutocompleteSelectType } from "../common/AutocompleteSelect";
import { useSimpleTradesData } from "../../../data/trading/trades/hooks/useSimpleTradesData"
import { TradesSimpleData, TradeSimpleFilter, TradesSimpleDataTrade } from "../../../data/trading/trades/types"

type FiltredOrdersSelectPropsType = Omit<AutocompleteSelectType<TradesSimpleDataTrade>, "data" | "getLabel"> & {
	filter?: TradeSimpleFilter;
};

export const FiltredSimpleTradesSelect: React.FunctionComponent<FiltredOrdersSelectPropsType> = ({
	filter,
	...otherProps
}) => {
	const [trades] = useSimpleTradesData(filter);

console.log(filter)
console.log(trades)

	return (
		<AutocompleteSelect<TradesSimpleDataTrade>
			{...(otherProps as AutocompleteSelectType<TradesSimpleDataTrade>)}
			data={trades || []}
			getLabel={renderAttributeLabel}
			disabled={filter === undefined}
		/>
	);
};

const renderAttributeLabel = (order: TradesSimpleDataTrade) => order.tradeData;
