import { MarginsData, MarginsDataFilter } from "../types";
import { User } from "../../../auth/types";
// import { webApi } from "../../../../core/http/httpThunks";
import { webApiWithResponse } from "../../../../core/http/httpThunks";

import { getParamsDateString } from "../../../../core/util/functions";

const getMarginsDataButton = (user: User, filter: MarginsDataFilter) => {
  return webApiWithResponse<MarginsData[]>({
    method: "PUT",
    url: "clearing/createmarginsdata",
    params: {
      userid: user.userId,
      tradingdate: getParamsDateString(filter.reportDate),
    },
  });
};
export default getMarginsDataButton;
