import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";
import { deleteClientAccount } from "../../thunks/clientAccount/deleteClientAccount";
import { setFetchStateAccount } from "../../clientsDataReducer";

export const useClientAccountDelete = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.clients.fetchStateAccount
	);

	const dispatch = useDispatch();
	const dispatchDeleteAccount = React.useCallback(
		(id: string) => dispatch(deleteClientAccount(id)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateAccount({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchDeleteAccount] as [typeof fetchState, typeof dispatchDeleteAccount]
};
