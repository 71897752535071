import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllGuarantees } from "../thunks/loadAllGuarantees";
import { GuaranteeListData } from "../types";
import { PageData } from "../../../models";
import { invalidateGuarantee } from "../guaranteesDataSlice";

export const useGuaranteeData = () => {
  const pageData = useSelector<
    StoreType,
    PageData<GuaranteeListData> | undefined
  >((s) => s.data.clearing.guarantees.currentPage);

  const dispatch = useDispatch();
  const dispatchLoadGuaranteePage = React.useCallback(
    () => dispatch(loadAllGuarantees()),
    [dispatch]
  );

  const invalidate = React.useCallback(() => dispatch(invalidateGuarantee()), [dispatch]);

  return [pageData, dispatchLoadGuaranteePage, invalidate] as [
    typeof pageData,
    typeof dispatchLoadGuaranteePage,
    typeof invalidate,
  ];
};
