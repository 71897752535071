import React from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import { DateInput } from "../../components/common/DateInput";
import { LocalizationContext } from "../../../core/localization/Localization";
import { NewsDataFilter } from "../../../data/reports/news/types";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { isValidDate } from "../../../core/util/functions";

interface NewsFilterEditorProps {
  filter?: NewsDataFilter;
  editFilter: (prop: keyof NewsDataFilter, value: any) => void;
  save: () => void;
}

export const NewsFilterEditor: React.FunctionComponent<NewsFilterEditorProps> = ({
  filter,
  editFilter,
  save,
}) => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const { getString } = React.useContext(LocalizationContext);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      editFilter(event.target.name as any, event.target.value);
    },
    [editFilter]
  );

  if (!filter) {
    return null;
  }

  return (
    <Paper
      style={{
        paddingLeft: "70px",
        paddingRight: "70px",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Grid container direction="column" spacing={6}>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={6}>
            <DateInput
              label={getString("exchDateFrom")}
              value={filter?.datefrom || null}
              onChange={(date) => editFilter("datefrom", isValidDate(date) ? date.toISOString() : undefined)}
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              label={getString("exchDateTo")}
              value={filter?.dateto || null}
              onChange={(date) => editFilter("dateto", isValidDate(date) ? date.toISOString() : undefined)}
            />
          </Grid>
        </Grid>

        <Grid item container direction="row-reverse" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                save();
                showWithDrawer(undefined);
              }}
            >
              {getString("orderFilterConfirm")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
