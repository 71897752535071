import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";

export const getMemberContractsReport = (user: User) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getclicontrreport",
    params: {
      userid: user.userId,
      status: 1,
    },
  });
};

export default getMemberContractsReport;
