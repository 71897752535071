import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Attribute } from "../types";

export const createAttributeApi = (userId: string, attribute: Attribute) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/newattr",
		params: {
			userId
		},
		data: attribute
	});

export default createAttributeApi;
