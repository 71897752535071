import React from "react";
import { useClientContract } from "./useClientContract";
import { ClientContract, ContractWebUser, ContractWebUserBlocked } from "../../types";
import { Status } from "../../../../../data/models";
import { useCopiedState } from "../../../../../core/util/hooks";

export const createDefaultBroker = (clientId: string): ClientContract => ({
	contrDescr: "",
	contrNo: "",
	contrType: 10,
	contrTypeDescr: "",
	clientId: clientId,
	phoneOrder: "",
	webUser: ContractWebUser.False,
	webUserBlocked: ContractWebUserBlocked.False,
	status: Status.Active
});

export const useEditClientContract = (clientId: string, contractId?: string) => {
	const [displayContract] = useClientContract(contractId);

	return React.useMemo(() => {
		if (contractId !== undefined && displayContract === undefined) {
			return undefined;
        }

		if (contractId === undefined) {
			const defaultPerson = createDefaultBroker(clientId);
			return { ...defaultPerson };
        }

		return displayContract ? { ...displayContract } : undefined;
	}, [contractId, clientId, displayContract]);
};

export const useEditClientContractState = (clientId: string, clientPersonId?: string) => {
	const contract = useEditClientContract(clientId, clientPersonId);
	return useCopiedState(contract)
};
