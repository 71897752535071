import { ComReviewReportFilter } from "../types";
import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

export const getCommReviewExcel = (
  user: User,
  filter: ComReviewReportFilter
) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getcommreviewxls",
    params: {
      userid: user.userId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      productid: filter.product?.prodId,
      clientid: filter.client?.cliId,
      broker: filter.broker?.empId,
      side: filter.side,
    },
  });
};

export default getCommReviewExcel;
