import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertCurrency } from "../thunks/upsertCurrency";
import { setFetchState } from "../currenciesDataReducer";
import { Currency } from "../types";

export const useCurrencyUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.currencies.fetchState
	);

	const dispatch = useDispatch();
	const dispatchUpsert = React.useCallback(
		(product: Currency) => dispatch(upsertCurrency(product)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchState({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchUpsert] as [typeof fetchState, typeof dispatchUpsert]
};
