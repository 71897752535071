import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { saveTASCostsThunk } from "../thunks/saveTASCostsThunk";
import { setSaveCalcFetchState } from "../TASDataSlice";
import { TransportAndStorageCosts } from "../types";

export const useTASCostSaver = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.clearing.transportAndStorage.saveCalcFetchState
	);

	const dispatch = useDispatch();
	const dispatchSave = React.useCallback(
		(costs: TransportAndStorageCosts) => dispatch(saveTASCostsThunk(costs)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setSaveCalcFetchState({ type: "not-started" }));
	}, [dispatch]);

	return [fetchState, dispatchSave] as [FetchStateType, typeof dispatchSave];
};
