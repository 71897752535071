import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadTrades } from "../thunks/loadTrades";
import { TradeList } from "../types";
import { PageData } from "../../../../data/models";
import { invalidateTrades } from "../tradesDataSlice";

export const useTradesData = () => {
	const pageData = useSelector<StoreType, PageData<TradeList> | undefined>(
		s => s.data.trading.trades.currentPage
	);

	const dispatch = useDispatch();
	const dispatchLoadTradesPage = React.useCallback(() => dispatch(loadTrades()), [dispatch]);

	const dispatchInvalidate = React.useCallback(() => dispatch(invalidateTrades()), [dispatch]);

	return [pageData, dispatchLoadTradesPage, dispatchInvalidate] as [
		typeof pageData,
		typeof dispatchLoadTradesPage,
		typeof dispatchInvalidate
	];
};
