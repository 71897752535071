import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";
import { deleteClientContract } from "../../thunks/clientContract/deleteClientContract";
import { setFetchStateContract } from "../../clientsDataReducer";

export const useClientContractDelete = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.clients.fetchStateContract
	);

	const dispatch = useDispatch();
	const dispatchDeleteBroker = React.useCallback(
		(id: string) => dispatch(deleteClientContract(id)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateContract({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchDeleteBroker] as [typeof fetchState, typeof dispatchDeleteBroker]
};
