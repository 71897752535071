import { ThunkType } from "../../../../core/store/reducers";
import { setMarginsData, invalidateMarginssData } from "../marginsDataSlice";
import { setMarginsDateIndex } from "../marginsUXSlice";
import getMarginsDataWebApi from "../api/getAllMarginsData";
import { PageIndex, PageDataStatus } from "../../../models";

export const loadAllMarginsgData = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const { pageNumber, itemsPerPage } = store.ux.clearing.marginsData.index;
  const { filter } = store.ux.clearing.marginsData;
  const user = store.data.auth.current.user;

  const marginsDataResponse = await dispatch(
    getMarginsDataWebApi(user, pageNumber, itemsPerPage, filter)
  );

  if (!marginsDataResponse || marginsDataResponse.resCode !== "OK") {
    return;
  }

  const marginData = {
    currentPageData: marginsDataResponse.obj,
    totalCount: Number(marginsDataResponse.resDesc) || 0,
    status: PageDataStatus.DataCurrent,
  };

  dispatch(setMarginsData(marginData));
};

export const setMarginsDataIndexThunk = (index: PageIndex): ThunkType => (
  dispatch
) => {
  dispatch(setMarginsDateIndex(index));
  dispatch(invalidateMarginssData());
};
