import { SimplBankGuaranteeFilter } from "../types";
import { BankGuaranteeData, GetBankGuaranteeParams } from "../types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";

const getBankGuaranteeWebApi = (
  userid: string,
  filter: SimplBankGuaranteeFilter
) => {
  const params: GetBankGuaranteeParams = {
    userid,
    fromrow: 0,
    numofrows: 100,
    ...filter,
  };

  return webApiWithResponse<BankGuaranteeData[]>({
    method: "GET",
    url: "clearing/getbankgrntlist",
    params,
  });
};

export default getBankGuaranteeWebApi;
