import { getEditLocation } from "../../../core/util/functions";

export enum ProductEditParts {
	BasicInfo = "basicinfo",
	Attributes = "attributes",
}

export const productsLocation = "/listing/products";

export const getProductEditLocation = (
	part: ProductEditParts = ProductEditParts.BasicInfo,
	productId?: string,
	attributeId?: string
) => getEditLocation(productsLocation, part, productId, attributeId);

export const baseEdit = getProductEditLocation(":editPart" as any, ":productId?");
export const atributesEdit = getProductEditLocation(
	ProductEditParts.Attributes,
	":productId?",
	":attributeId?"
);
