import React from "react";
import { useRecieveMethods } from "../../../data/initialData/hooks/useRecieveMethods";
import { OrderRecievedMethod } from "../../../data/models";
import {
  AutocompleteSelect,
  AutocompleteSelectType,
} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<OrderRecievedMethod>,
  "data" | "getLabel"
>;

export const OrderRecieveMethodSelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [recieveMethods, renderLabel] = useRecieveMethods();

  return (
    <AutocompleteSelect<OrderRecievedMethod>
      data={recieveMethods}
      getLabel={renderLabel}
      {...(props as any)}
    />
  );
};
