import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { cancelOrder } from "../thunks/cancelOrderThunk";
import { setCancelOrderFetchState } from "../../mainBoard/ordersDataSlice";

export const useCancelOrder = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.trading.mainBoard.cancelOrderFetchState
	);

	const dispatch = useDispatch();
	const dispatchCancelOrder = React.useCallback(
		(id: string) => dispatch(cancelOrder(id)),
		[dispatch]
	);

	React.useEffect(() => {
		return () => {
			dispatch(setCancelOrderFetchState({ type: "not-started" }))
		}
	}, [dispatch])

	return [fetchState, dispatchCancelOrder] as [typeof fetchState, typeof dispatchCancelOrder]
};
