import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadReport } from "../thunks/loadReportData";
import { AntiLaunderingReportData } from "../types";

export const useAntiMnyLndCommReportData = () => {
  const data = useSelector<StoreType, AntiLaunderingReportData[] | undefined>(
    (s) => s.data.reports.antiLaundering.data
  );

  const dispatch = useDispatch();
  const dispatchLoadData = React.useCallback(() => dispatch(loadReport()), [
    dispatch,
  ]);

  return [data, dispatchLoadData] as [typeof data, typeof dispatchLoadData];
};
