import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertAlarm } from "../thunks/upsertAlarm";
import { setFetchState } from "../alarmDataSlice";
import { AlarmData } from "../types";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { ErrorCodes } from "../../../../core/http/types";

export const useAlarmUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.surveillance.alarms.fetchState
	);
	const { getString } = React.useContext(LocalizationContext);

	const dispatch = useDispatch();
	const dispatchUpsertAlarm = React.useCallback(
		(alarm: AlarmData) =>
			dispatch(
				upsertAlarm(alarm, error =>
					error.resCode === ErrorCodes.UniqueConstraint
						? getString("alarmUniqueMessage")
						: error.resDesc
				)
			),
		[dispatch, getString]
	);

	React.useEffect(() => {
		dispatch(setFetchState({ type: "not-started" }));
	}, [dispatch]);

	return [fetchState, dispatchUpsertAlarm] as [typeof fetchState, typeof dispatchUpsertAlarm];
};
