import { ThunkType } from "../../../../core/store/reducers";
import deleteDisputeApi from "../api/deleteDispute";
import { setFetchState, invalidateDispute } from "../disputeDataSlice";

export const deleteDispute = (disputeId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteDisputeApi(userId, disputeId));

	dispatch(setFetchState(result !== undefined ? { type: "successful" } : { type: "error" }));
	dispatch(invalidateDispute());
};
