import { webApi } from "../../../../core/http/httpThunks";
import { CurrencyRate } from "../types";

export const getAllCurrencyRatesApi = (userId: string, currid?: string) =>
	webApi<CurrencyRate[]>({
		method: "GET",
		url: "listing/getcurrratelist",
		params: {
			userId,
			currid
		},
	});

export default getAllCurrencyRatesApi;
