import { NavigationItem } from "../../../core/navigation/types";
import BankGuaranteesPage from "./BankGuaranteesPage";
import { EditBankGuaranteePage } from "./EditBankGuaranteePage";
import { bankguaranteeLocation, editRouter } from "./Locations";

export const bankguaranteeComponents: NavigationItem[] = [
  {
    Component: BankGuaranteesPage,
    isMenuItem: true,
    path: bankguaranteeLocation,
  },
  {
    Component: EditBankGuaranteePage,
    isMenuItem: false,
    path: editRouter,
  },
];
