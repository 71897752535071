import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { PayType } from "../types";

export const updatePayTypeApi = (userId: string, payType: PayType) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "listing/updpaytype",
    params: {
      userId,
    },
    data: payType,
  });

export default updatePayTypeApi;
