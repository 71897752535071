import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import { DisputeFilter } from "./types";

type DisputePageState = {
  index: PageIndex;
  filter: DisputeFilter;
};

export const initialState: DisputePageState = {
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
  filter: {},
};

const name = "disputes";

const disputesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setDisputeIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    resetDisputeIdices: (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }),
    setFilter: (state, action: PayloadAction<DisputeFilter>) => ({
      ...state,
      filter: action.payload,
    }),
    removeFilter: (state, action: PayloadAction<keyof DisputeFilter>) => ({
      ...state,
      filter: {
        ...state.filter,
        [action.payload]: undefined,
      },
    }),

  },
  extraReducers: (builder) => {
    builder.addCase(successfulLogin, (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }));
  },
});

export const {
  setDisputeIndex,
  resetDisputeIdices,
  setFilter,
  removeFilter,
} = disputesSlice.actions;

export default disputesSlice.reducer;
