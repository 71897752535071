import React from "react";
import { useBankGuaranteesReportDownloader } from "../../../data/clearing/bankguarantees/hooks/useBankGuaranteesReportDownloader";
import { useBankGuaranteesExcelDownloader } from "../../../data/clearing/bankguarantees/hooks/useBankGuaranteesExcelDownloader";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import ExcelIcon from "@material-ui/icons/TableChartOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { LocalizationContext } from "../../../core/localization/Localization";

export const DownloadButton: React.FunctionComponent = () => {
	const [downloader, fetchState] = useBankGuaranteesReportDownloader();
	const { getString } = React.useContext(LocalizationContext);
	return (
		<>
			<CustomTooltip titleKeyword="exportPDFTooltip">
				<IconButton
					color="secondary"
					component="span"
					onClick={downloader}
					disabled={fetchState.type !== "not-started"}
				>
					<DownloadIcon />
				</IconButton>
			</CustomTooltip>
			{fetchState.type !== "not-started" ? getString("pleaseWait") : null}
		</>
	);
};

export const DownloadExcelButton: React.FunctionComponent = () => {
	const [downloader, fetchState] = useBankGuaranteesExcelDownloader();
	return (
		<CustomTooltip titleKeyword="exportXLSXTooltip">
			<IconButton
				color="secondary"
				component="span"
				onClick={downloader}
				disabled={fetchState.type !== "not-started"}
			>
				<ExcelIcon />
			</IconButton>
		</CustomTooltip>
	);
};
