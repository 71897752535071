import { useDispatch, useSelector } from "react-redux";
import { downloadRefPriceExcel } from "../thunks/downloadReportExcel";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { TradingData  } from "../types";

export const useRefPriceExcelDownloader = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (s) =>
      s.data.clearing.tradingData.downloadReportFetchState
  );

  const data = useSelector<StoreType, TradingData[] | undefined>(
    s => s.data.clearing.tradingData.currentPage?.currentPageData
  );
  
  const hasData = (data !== undefined && data !== null && data.length > 0) ;

  const dispatch = useDispatch();
  const download = React.useCallback(
    () => dispatch(downloadRefPriceExcel()),
    [dispatch]
  );

  return [download, fetchState, hasData] as [typeof download, typeof fetchState, typeof hasData];
};
