import React from "react";
import { useDisplayProductGroup } from "./useDisplayProductGroup";
import { ProductGroupData } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defauiltGroup: ProductGroupData = {
	prodGroupName: "",
	prodGroupNameEn: "",
	status: Status.Active,
};

export const useEditProductGroup = (roleId?: string) => {
	const displayGroup = useDisplayProductGroup(roleId);

	return React.useMemo(() => {
		if (roleId !== undefined && displayGroup === undefined) {
			return undefined;
		}

		if (roleId === undefined) {
			return { ...defauiltGroup };
		}

		return displayGroup ? { ...displayGroup } : undefined;
	}, [roleId, displayGroup]);
};

export const useEditProductGroupState = (empId?: string) => {
	const group = useEditProductGroup(empId);
	return useCopiedState(group);
};
