import { webApi } from "../../../../core/http/httpThunks";

export const deleteEmployeeApi = (userId: string, empid: string) =>
	webApi<string>({
		method: "DELETE",
		url: "listing/delemployee",
		params: {
			userId,
			empid
		},
	});

export default deleteEmployeeApi;
