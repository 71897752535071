import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useCurrencyData } from "../../../data/listing/currencies/hooks/useCurrencyData";
import { Currency } from "../../../data/listing/currencies/types";
import { useCurrenciesColumns } from "./useCurrenciesColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useCurrenciesActions } from "./useCurrenciesActions";

export interface CompaniesTableProps {
	userAccessRights: AccessRights;
}

type RolesTablePropsType = CompaniesTableProps & TableDataProps<Currency>;

export const RolesTable: React.FunctionComponent<RolesTablePropsType> = ({
	userAccessRights,
	...tableProps
}) => {
	const [data, loadAll] = useCurrencyData();

	const columns = useCurrenciesColumns();
	const actions = useCurrenciesActions(userAccessRights);

	return (
		<Table<Currency>
			{...tableProps}
			getData={loadAll}
			immutableData={data}
			title=""
			columns={columns}
			actions={actions}
			options={{
				toolbar: false,
				pageSize: 10,
				actionsColumnIndex: -1,
			}}
		/>
	);
};

export default RolesTable;
