import { webApi } from "../../../../../core/http/httpThunks";
import { ClientPerson } from "../../types";

export const getClientPersonApi = (userId: string, cliprsid: string) =>
	webApi<ClientPerson>({
		method: "GET",
		url: "listing/getcliprs",
		params: {
			userId,
			cliprsid
		},
	});

export default getClientPersonApi;
