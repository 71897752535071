import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { TradeList } from "../types";
import { loadTrade } from "../thunks/loadTrade";

export const useDisplayTrade = (tradeId?: string) => {
  const storeTrade = useSelector<StoreType, TradeList | undefined>(
    (store) => store.data.trading.trades.displayedTrade
  );

  const displayedTrade =
    storeTrade && storeTrade.tradeId === tradeId ? storeTrade : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (tradeId) {
      displatch(loadTrade(tradeId));
    }
  }, [displatch, tradeId]);

  return displayedTrade;
};
