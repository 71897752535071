import React from "react";
import { StockFee } from "../../../data/clearing/stockfees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayStockFeeMainAttributesProps {
  stfee: StockFee;
}

export const DisplayStockFeeMainAttributes: React.FunctionComponent<DisplayStockFeeMainAttributesProps> = ({
  stfee,
}) => {
  const style = useDisplaySingleEmementStyles();
  const {
    getString,
    getDateString,
    getFormatedNumberString,
  } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("stockFeeStartDate")}</span>
        <span className={style.value}>{getDateString(stfee.startDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("stockFeeStockFee")}</span>
        <span className={style.value}>
          {getFormatedNumberString(stfee.stockFee, true)}
        </span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("stockFeeStatus")}</span>
        <span className={style.value}>
          <DisplayBoolNumber
            value={stfee.status}
            thrutyValue={Status.Active}
            className={style.icon}
          />
        </span>
      </div>
    </div>
  );
};
