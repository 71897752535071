import { useDispatch, useSelector } from "react-redux";
import { printInvoiceThunk } from "../thunks/printInvoiceThunk";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { InvoiceData } from "../types";

export const useInvoicePrinter = (invoice?: InvoiceData) => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (s) => s.data.accounting.invoices.printInvoiceFetchState
  );
  const dispatch = useDispatch();
  const download = React.useCallback(() => {
    dispatch(printInvoiceThunk(invoice));
  }, [dispatch, invoice]);

  return [download, fetchState] as [typeof download, typeof fetchState];
};
