import { ThunkType } from "../../../../core/store/reducers";
import getCountryApi from "../api/getCountry";
import { setDisplayed } from "../countriesDataReducer";
import { Country } from "../types";

export const loadCountry = (cntId: string): ThunkType => async (dispatch, getStore) => {
	dispatch(setDisplayed());

	const country = await dispatch(getCountry(cntId));

	dispatch(setDisplayed(country));
};

export const getCountry = (cntId: string): ThunkType<Promise<Country | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getCountryApi(userId, cntId));
}
