import { webApi } from "../../../../../core/http/httpThunks";
import { ProductAttribute } from "../../types";

export const getAttributeApi = (userId: string, prodattrid: string) =>
	webApi<ProductAttribute>({
		method: "GET",
		url: "listing/getprodattr",
		params: {
			userId,
			prodattrid
		},
	});

export default getAttributeApi;
