import { ThunkType } from "../../../../core/store/reducers";
import createControlApi from "../api/createCtrlGoods";
import updateCtrlGoodsApi from "../api/updateCtrlGoods";
import { CtrlGoodsData } from "../types";
import { setFetchState, invalidateControl } from "../ctrlGoodsDataSlice";

export const upsertCtrlGood = (ctrlGoods: CtrlGoodsData): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = ctrlGoods.controlId
    ? await dispatch(updateCtrlGoodsApi(userId, ctrlGoods))
    : await dispatch(createControlApi(userId, ctrlGoods));

  if (result && result.resCode === "OK") {
    dispatch(setFetchState({ type: "successful", result: result.resDesc }));
    dispatch(invalidateControl());
    return result.resDesc;
  } else {
    dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
  }
};
