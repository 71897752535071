import { AntiLaunderingReportData } from "../../../data/reports/amlaundering/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";

export const useAntiMnyLndCommColumns = () => {
  const { getString, getFormatedNumberString } = React.useContext(
    LocalizationContext
  );

  return React.useMemo(
    () => [
      {
        title: getString("amtimnylndcommTradeNumber"),
        field: "tradeNumber",
        sorting: false,
      },
      {
        title: getString("amtimnylndcommTradeDate"),
        field: "tradeDate",
        sorting: false,
      },
      {
        title: getString("amtimnylndcommProduct"),
        field: "product",
        sorting: false,
      },
      {
        title: getString("amtimnylndcommQuantity"),
        render: (row) => `${getFormatedNumberString(row.quantity)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("amtimnylndcommPrice"),
        render: (row) => `${getFormatedNumberString(row.price, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("amtimnylndcommAmount"),
        render: (row) => `${getFormatedNumberString(row.amount, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("amtimnylndcommBuyer"),
        field: "buyer",
        sorting: false,
      },
      {
        title: getString("amtimnylndcommSeller"),
        field: "seller",
        sorting: false,
      },
    ],
    [getString, getFormatedNumberString]
  ) as Column<AntiLaunderingReportData>[];
};
