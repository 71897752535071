import { webApi } from "../../../../core/http/httpThunks";

export const deleteRegionApi = (userId: string, regid: string) =>
  webApi<string>({
    method: "DELETE",
    url: "listing/delreg",
    params: {
      userId,
      regid,
    },
  });

export default deleteRegionApi;
