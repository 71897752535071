import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useAntiMnyLndcommReportFilter } from "../../../data/reports/amlaundering/hooks/useAntiMnyLndCommReportFilter";
import { LocalizationContext } from "../../../core/localization/Localization";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { AntiMnyLndCommFilterEditor } from "../amlaundering/AntiMnyLndCommFilterEditor";

export const AntiMnyLndCommReportFilter: React.FunctionComponent = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const showFilter = React.useCallback(
    () => showWithDrawer(<AntiMnyLndCommReportsFiltEditor />),
    [showWithDrawer]
  );

  const [filter, editfilter, save] = useAntiMnyLndcommReportFilter();
  const { getString } = React.useContext(LocalizationContext);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <CustomTooltip titleKeyword="filterTooltip">
          <IconButton color="secondary" component="span" onClick={showFilter}>
            <FilterIcon />
          </IconButton>
        </CustomTooltip>
      </Grid>
    </Grid>
  );
};

const AntiMnyLndCommReportsFiltEditor: React.FunctionComponent = () => {
  const [filter, editFilter, save] = useAntiMnyLndcommReportFilter();
  return (
    <AntiMnyLndCommFilterEditor
      filter={filter}
      editFilter={editFilter}
      save={save}
    />
  );
};
