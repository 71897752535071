import { ThunkType } from "../../../../core/store/reducers";
import createCountryApi from "../api/createCountry";
import updateCountryApi from "../api/updateCountry";
import { setFetchState } from "../countriesDataReducer";
import { Country } from "../types";

export const upsertCountry = (country: Country): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = country.cntId
    ? await dispatch(updateCountryApi(userId, country))
    : await dispatch(createCountryApi(userId, country));

  if (result && result.resCode === "OK") {
    dispatch(setFetchState({ type: "successful", result: result.resDesc }));
    return result.resDesc;
  } else {
    dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
  }
};
