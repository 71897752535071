import React from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { DateInput } from "../../components/common/DateInput";
import { LocalizationContext } from "../../../core/localization/Localization";
import { TradingDataFilter } from "../../../data/clearing/tradingData/types";
import { ProductSelect } from "../../components/listing/ProductSelect";
import { TradingDataFilterChipsComponent } from "./TradingDataFilterChips";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { isValidDate } from "../../../core/util/functions";
import { useTradingFilterDataValidator } from "../../../data/clearing/tradingData/hooks/useTradingDataFilterValidator";

export enum TradingDataFilterEditorType {
	Basic,
	Extended,
}

interface TradingDataFilterEditorProps {
	filter?: TradingDataFilter;
	editFilter: (prop: keyof TradingDataFilter, value: any) => void;
	save: () => void;
	type: TradingDataFilterEditorType;
	reset?: () => void;
}

export const TradingDataFilterEditor: React.FunctionComponent<TradingDataFilterEditorProps> = ({
	filter,
	editFilter,
	save,
	type,
	reset,
}) => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const { getString } = React.useContext(LocalizationContext);
//	const [validationState, validate] = useTradingFilterDataValidator();

	return (
		<Paper
			style={{
				paddingLeft: "70px",
				paddingRight: "70px",
				paddingTop: "15px",
				paddingBottom: "15px",
			}}
		>
			<Grid container direction="column" spacing={6}>
				<Grid item container direction="row" spacing={2}>
					<Grid item xs={6}>
						<DateInput
							label={getString("tradingDatadatefrom")}
							value={filter?.datefrom || null}
							onChange={date =>
								editFilter("datefrom", isValidDate(date) ? date.toISOString() : undefined)
							}
							disableFuture
//							error={validationState.datefrom !== undefined && !validationState.datefrom}
//							required={validationState.datefrom !== undefined}
							errorMessage={getString("noFutureDate")}
						/>
					</Grid>
					<Grid item xs={6}>
						<DateInput
							label={getString("tradingDatadateto")}
							value={filter?.dateto || null}
							onChange={date =>
								editFilter("dateto", isValidDate(date) ? date.toISOString() : undefined)
							}
							disableFuture
//							error={validationState.dateto !== undefined && !validationState.dateto}
//							required={validationState.dateto !== undefined}
							errorMessage={getString("noFutureDate")}
						/>
					</Grid>
					<Grid item xs={12}>
						<ProductSelect
							label={getString("tradingDataProductName")}
							predicate={prod => prod.prodId === filter?.product?.prodId}
							onChangeItem={prod => editFilter("product", prod)}
						/>
					</Grid>
				</Grid>

				<Grid item container direction="row-reverse" spacing={2}>
					<Grid item>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
//								const valid = validate(filter);
//								if (valid) {
									save();
									showWithDrawer(undefined);
//								}
							}}
						>
							{getString("orderFilterConfirm")}
						</Button>
					</Grid>
					<Grid item>
						<Button variant="text" onClick={reset}>
							{getString("filterReset")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export const TradingDataFilterChips = TradingDataFilterChipsComponent;
