import { webApi } from "../../../../core/http/httpThunks";
import { Role } from "../types";

export const getAllRolesApi = (userId: string) =>
	webApi<Role[]>({
		method: "GET",
		url: "listing/getrolelist",
		params: {
			userId,
		},
	});

export default getAllRolesApi;
