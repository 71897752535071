import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadReport } from "../thunks/loadAccountingOrderData";
import { InvoiceListData } from "../types";

export const useAccountingOrderData = () => {
  const data = useSelector<StoreType, InvoiceListData[] | undefined>(
    (s) => s.data.accounting.accountingOrder.data
  );

  const dispatch = useDispatch();
  const dispatchLoadData = React.useCallback(() => dispatch(loadReport()), [
    dispatch,
  ]);

  return [data, dispatchLoadData] as [typeof data, typeof dispatchLoadData];
};
