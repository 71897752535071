import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth/authUXSlice";
import localization from "./localization/localizationUXSlice";
import trading from "./trading/tradingUXReducer"
import clearing from "./clearing/clearingUXReducer"
import reports from "./reports/reportsUXRecucer"
import accounting from "./accounting/accountingUXReducer";
import surveillance from "./surveillance/surveillanceUXReducer";

export default combineReducers({
  auth,
  localization,
  trading,
  clearing,
  reports,
  accounting,
  surveillance
});
