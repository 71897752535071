import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteDelivery } from "../thunks/deleteDelivery";
import { setFetchState } from "../deliveryDataSlice";

export const useDeliveryDelete = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.clearing.deliveries.cancelFetchState
  );

  const dispatch = useDispatch();
  const dispatchDeleteDelivery = React.useCallback(
    (id: string) => dispatch(deleteDelivery(id)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchDeleteDelivery] as [
    typeof fetchState,
    typeof dispatchDeleteDelivery
  ];
};
