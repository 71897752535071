import { webApi } from "../../../../core/http/httpThunks";
import { City } from "../types";

export const getAllCityApi = (userId: string) =>
  webApi<City[]>({
    method: "GET",
    url: "listing/getcitylist",
    params: {
      userId,
    },
  });

export default getAllCityApi;
