import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";
import { upsertAttributeValue } from "../../thunks/attributeValues/upsertAttributeValue";
import { setFetchStateValue } from "../../attributeDataReducer";
import { AttributeValue } from "../../types";

export const useAttributeValueUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.attributes.fetchStateAttribute
	);

	const dispatch = useDispatch();
	const dispatchUpsert = React.useCallback(
		(attribute: AttributeValue) => dispatch(upsertAttributeValue(attribute)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateValue({ type: "not-started" }));
	}, [dispatch]);

	return [fetchState, dispatchUpsert] as [typeof fetchState, typeof dispatchUpsert];
};
