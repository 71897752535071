import { webApi } from "../../../../../core/http/httpThunks";

export const deleteClientBrokerApi = (userId: string, clibrokid: string) =>
	webApi<string>({
		method: "DELETE",
		url: "listing/delclibrok",
		params: {
			userId,
			clibrokid
		},
	});

export default deleteClientBrokerApi;
