import ExchangeMembersReport from "./ExchangeMembersReport";
import { NavigationItem } from "../../../core/navigation/types";
import { exchangeMembersReportLocation } from "./Locations";

export const exchangeMembersReportComponents: NavigationItem[] = [
  {
    Component: ExchangeMembersReport,
    isMenuItem: true,
    path: exchangeMembersReportLocation,
  },
];
