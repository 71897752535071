import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { BankGuaranteeData } from "../types";
import { loadBankGuarantee } from "../thunks/loadBankGuarantees";

export const useDisplayBankGuarantee = (grntId?: string) => {
  const storeBankGuarantee = useSelector<StoreType, BankGuaranteeData | undefined>(
    (store) => store.data.clearing.bankguarantees.displayedBankGuarantee
  );

  const displayedBankGuarantee =
  storeBankGuarantee && storeBankGuarantee.grntId === grntId
      ? storeBankGuarantee
      : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (grntId) {
      displatch(loadBankGuarantee(grntId));
    }
  }, [displatch, grntId]);

  return displayedBankGuarantee;
};
