import { webApiWithResponse } from "../../../../core/http/httpThunks";

export const deleteAttributeApi = (userId: string, attrid: string, errorExceptions: string[]) =>
	webApiWithResponse<string>({
		method: "DELETE",
		url: "listing/delattr",
		params: {
			userId,
			attrid,
		},
	}, undefined, errorExceptions);

export default deleteAttributeApi;
