import { BankGuaranteeFilter } from "../types";
import { BankGuaranteeData, GetBankGuaranteeParams } from "../types";
import { User } from "../../../auth/types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getBankGuaranteeWebApi = (
  user: User,
  page: number,
  itemsPerPage: number,
  filter: BankGuaranteeFilter
) => {
  const params: GetBankGuaranteeParams = {
    userid: user.userId,
    fromrow: page * itemsPerPage,
    numofrows: itemsPerPage,
    cliid: filter?.client?.cliId,
    bankid: filter?.bank?.bankId,
    datefrom: getParamsDateString(filter.datefrom),
    dateto: getParamsDateString(filter.dateto),
  };

  return webApiWithResponse<BankGuaranteeData[]>({
    method: "GET",
    url: "clearing/getbankgrntlist",
    params,
  });
};

export default getBankGuaranteeWebApi;
