import React from "react";
import { Table, TableDataProps } from "../../../components/common/Table";
import { useClientPersons } from "../../../../data/listing/clients/hooks/clientPerson/useClientPersons";
import { ClientPerson } from "../../../../data/listing/clients/types";
import { Column, Action } from "material-table";
import { DisplayBoolNumber } from "../../../components/common/DisplayBoolNumber";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { useClientPersonDelete } from "../../../../data/listing/clients/hooks/clientPerson/useClientPersonDelete";
import EditIcon from "@material-ui/icons/EditOutlined";
import { ClientEditParts, getClientsEditLocation } from "../Locations";
import { useHistory } from "react-router-dom";
import { Fab } from "@material-ui/core";
import { usePageStyles } from "../../../pageStyles";
import AddIcon from "@material-ui/icons/Add";
import { Status } from "../../../../data/models";
import { useAuth } from "../../../../data/auth/hooks";

export interface ClientPersonTableProps {
	clientId: string;
}

type ClientPersonTablePropsType = ClientPersonTableProps & TableDataProps<ClientPerson>;

export const ClientPersonsTable: React.FunctionComponent<ClientPersonTablePropsType> = ({
	clientId,
	...tableProps
}) => {
	const pageStyles = usePageStyles();
	const history = useHistory();

	const [auth] = useAuth();

	const [data, reload] = useClientPersons(clientId);

	const columns = useClientPersonColumns();
	const actions = useClientActions();
	const onDeleteClient = useDeleteClientPersonAction();

	return (
		<>
			<Table<ClientPerson>
				{...tableProps}
				getData={() => {}}
				immutableData={data}
				title=""
				columns={columns}
				actions={actions}
				options={{
					toolbar: false,
					pageSize: 5,
					actionsColumnIndex: -1,
				}}
	
				editable={ auth.status === "successful" && auth.user.roleCode === "ADMIN" ? {
					onRowDelete: async person => {
						await onDeleteClient(person);
						reload();
					},
				} : {} }
			/>
			{auth.status === "successful" && auth.user.roleCode === "ADMIN" && (
				<Fab
				color="secondary"
				aria-label="add"
				className={pageStyles.mainFab}
				onClick={() => history.push(getClientsEditLocation(ClientEditParts.Persons, clientId, ""))}
			>
				<AddIcon />
			</Fab>)}
		</>
	);
};

export default ClientPersonsTable;

const useClientPersonColumns = () => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);
	return React.useMemo(
		() => [
			{
				title: getString("clientPersonName"),
				field: "cliPrsName",
			},
			{
				title: getString("clientPersonIDNumber"),
				field: "cliPrsIdNo",
			},
			{
				title: getString("clientPersonRole"),
				field: "cliPrsRoleName",
			},
			{
				title: getString("clientPersonStatus"),
				render: person => (
					<DisplayBoolNumber
						value={person.status}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				),
				customSort: (a, b) => a.status - b.status,
			},
		],
		[getString, style.icon]
	) as Column<ClientPerson>[];
};

const useDeleteClientPersonAction = () => {
	const [, deleteClient] = useClientPersonDelete();
	return React.useCallback(
		async (client: ClientPerson) => {
			if (client.cliPrsId) {
				await deleteClient(client.cliPrsId);
			}
		},
		[deleteClient]
	);
};

export const useClientActions = () => {
	const { getString } = React.useContext(LocalizationContext);
	const onEditClient = useEditClientAction();

	const [auth] = useAuth();

	return React.useMemo(() => {
		const result: Action<ClientPerson>[] = [
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditClient(rowData as ClientPerson),
				hidden: auth.status === "successful" && auth.user.roleCode !== "ADMIN",
			},
		];

		return result;
	}, [getString, onEditClient]);
};

export const useEditClientAction = () => {
	const history = useHistory();
	return React.useCallback(
		(person: ClientPerson) =>
			history.push(getClientsEditLocation(ClientEditParts.Persons, person.clientId, person.cliPrsId)),
		[history]
	);
};
