import React from "react";
import { useRefPriceButton } from "../../../data/clearing/tradingData/hooks/useRefPriceButton";
import { Fab } from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import DownloadIcon from "@material-ui/icons/DoubleArrowOutlined";
import { usePageStyles } from "../../pageStyles";
import { CustomDialog } from "../../components/common/CustomDialog";
import { LocalizationContext } from "../../../core/localization/Localization";

export const RefPriceButton: React.FunctionComponent = () => {
	const [downloader, fetchState, resetData, hasData] = useRefPriceButton();
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	return (
		<>
			<CustomTooltip titleKeyword="tradingDataRefPriceTooltip">
				<Fab
					color="secondary"
					aria-label="add"
					className={pageStyles.mainFab}
					onClick={downloader}
					disabled={fetchState.type !== "not-started" || hasData}
				>
					<DownloadIcon />
				</Fab>
			</CustomTooltip>
			<CustomDialog
				open={fetchState.type === "successful"}
				title={getString("tradingDataMessageTitle")}
				message={getString("tradingDataMessage")}
				closeLabel={getString("tradingDataConfim")}
				onClose={resetData}
			/>
		</>
	);
};
