import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useClientTypes } from "../../../data/initialData/hooks/useClientTypes";
import { TextField, MenuItem } from "@material-ui/core";

interface CLientTypeSelectProps {
	typeValue: number;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ClientTypeSelect: React.FunctionComponent<CLientTypeSelectProps> = ({
	typeValue,
	handleChange,
}) => {
	const { getString } = React.useContext(LocalizationContext);
	const [types] = useClientTypes();

	return (
		<TextField
			name={"cliType"}
			value={typeValue}
			onChange={handleChange}
			fullWidth
			select
			label={getString("cliType")}
			required
		>
			{types.map(option => (
				<MenuItem key={option.ftValue} value={option.ftValue}>
					{option.ftDescr}
				</MenuItem>
			))}
		</TextField>
	);
};
