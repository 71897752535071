import ClientsPage from "./ClientPage";
import { EditClientPage } from "./EditClientPage";
import { EditAccount } from "./clientAccounts/EditAccounts";
import { EditBroker } from "./clientBrokers/EditBroker";
import { EditContract } from "./clientContracts/EditContract";
import { EditPerson } from "./clientPersons/EditPerson";
import { NavigationItem } from "../../../core/navigation/types";
import { clientsLocation, personsEdit, brokersEdit, contractsEdit, accountEdit, basicInfoEdit } from "./Locations";

export const clientsComponents: NavigationItem[] = [
	{
		Component: ClientsPage,
		isMenuItem: true,
		path: clientsLocation,
	},
	{
		Component: EditPerson,
		isMenuItem: false,
		path: personsEdit,
	},
	{
		Component: EditBroker,
		isMenuItem: false,
		path: brokersEdit,
	},
	{
		Component: EditContract,
		isMenuItem: false,
		path: contractsEdit,
	},
	{
		Component: EditAccount,
		isMenuItem: false,
		path: accountEdit,
	},
	{
		Component: EditClientPage,
		isMenuItem: false,
		path: basicInfoEdit,
	},
];
