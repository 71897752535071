import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { NewsData } from "../types";

export const createNewsApi = (userId: string, news: NewsData) =>{
  const form = new FormData();

  news.fileName1 && form.append('filesr',news.fileName1);
  news.fileName2 && form.append('fileen',news.fileName2);

   return webApiWithResponse<string>({
    method: "POST",
    url: "news/createnews",
    params: {
      userId,
      newstime: news.newsTime,
      titlesr: news.title,
      titleen: news.titleEn,
    },
    headers: { "Content-Type": "multipart/form-data" },
    data: form,
  });
}
export default createNewsApi;
