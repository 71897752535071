import { httpWebApi } from "../../../core/http/httpThunks";
import { LoginResponse } from "../../models";
import { LocalizationLanguage } from "../../../core/localization/types";

export const firstLoginWebApi = (username: string, password: string, lang: LocalizationLanguage, source: string) =>
	httpWebApi<LoginResponse>({
		method: "POST",
		url: "user/firstuserlogin",
		data: {
			username,
			password,
			lang,
			source,
		},
	});
