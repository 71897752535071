import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Region } from "../types";

export const updateRegionApi = (userId: string, region: Region) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "listing/updreg",
    params: {
      userId,
    },
    data: region,
  });

export default updateRegionApi;
