import { webApi } from "../../../../core/http/httpThunks";
import { StockFee } from "../types";

export const getAllStockFeesApi = (userId: string) =>
  webApi<StockFee[]>({
    method: "GET",
    url: "clearing/getstockfeeslist",
    params: {
      userId,
    },
  });

export default getAllStockFeesApi;
