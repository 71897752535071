import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertCtrlGood } from "../thunks/upsertCtrlGood";
import { setFetchState } from "../ctrlGoodsDataSlice";
import { CtrlGoodsData } from "../types";

export const useCtrlGoodUpsert = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.clearing.controls.fetchState
  );

  const dispatch = useDispatch();
  const dispatchUpsertCtrlGoods = React.useCallback(
    (ctrlGoods: CtrlGoodsData) => dispatch(upsertCtrlGood(ctrlGoods)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchUpsertCtrlGoods] as [
    typeof fetchState,
    typeof dispatchUpsertCtrlGoods
  ];
};
