import { ThunkType } from "../../../../core/store/reducers";
import getBankGuaranteeApi from "../api/getBankGuarantee";
import { setDisplayed } from "../bankguaranteesDataSlice";
import { BankGuaranteeData } from "../types";

export const loadBankGuarantee = (grntId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const bankguarantee = await dispatch(getBankGuarantee(grntId));

  dispatch(setDisplayed(bankguarantee));
};

export const getBankGuarantee = (
  grntId: string
): ThunkType<Promise<BankGuaranteeData | undefined>> => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getBankGuaranteeApi(userId, grntId));
};
