import { NavigationItem } from "../../../core/navigation/types";
import StockFeesPage from "./StockFeesPage";
import { EditStockFeePage } from "./EditStockFeePage";
import { stockFeeLocation, editRouter } from "./Locations";

export const stockFeesComponents: NavigationItem[] = [
  {
    Component: StockFeesPage,
    isMenuItem: true,
    path: stockFeeLocation,
  },
  {
    Component: EditStockFeePage,
    isMenuItem: false,
    path: editRouter,
  },
];
