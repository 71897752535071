import React from "react";
import { useCurrencyData } from "../../../data/listing/currencies/hooks/useCurrencyData";
import { Currency } from "../../../data/listing/currencies/types";
import { AutocompleteSelectType, AutocompleteSelect } from "./AutocompleteSelect";

type CurrencySelectPropsType = Omit<AutocompleteSelectType<Currency>, "data" | "getLabel">;

export const CurrencySelect: React.FunctionComponent<CurrencySelectPropsType> = props => {
	const [, loadCurencies, curencies] = useCurrencyData();

	React.useEffect(() => {
		if (!curencies) {
			loadCurencies();
		}
	}, [curencies, loadCurencies]);

	return (
		<AutocompleteSelect<Currency>
			{...(props as AutocompleteSelectType<Currency>)}
			data={curencies}
			getLabel={getCurrencyLabel}
		/>
	);
};

export const getCurrencyLabel = (currency: Currency) =>
	currency ? `${currency.currName} (${currency.currCode})` : "";
