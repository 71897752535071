import { webApi } from "../../../../core/http/httpThunks";
import { Warehouse } from "../types";

export const getAllWhsApi = (userId: string) =>
  webApi<Warehouse[]>({
    method: "GET",
    url: "listing/getwhslist",
    params: {
      userId,
    },
  });

export default getAllWhsApi;
