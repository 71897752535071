import React from "react";
import { useDisplayEmployee } from "../../../data/listing/employees/hooks/useDisplayEmployee";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { Employee } from "../../../data/listing/employees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayEmployeeMainAttributes } from "./DisplayEmployeeMainAttributes";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid } from "@material-ui/core";

export interface DisplayEmployeeProps {
	id?: string;
}

export const DisplayEmployee: React.FunctionComponent<DisplayEmployeeProps> = ({ id }) => {
	const employee = useDisplayEmployee(id);
	const { getString } = React.useContext(LocalizationContext);

	return !employee ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel expanded>
				<ExpansionPanelSummary>
					<Typography>{getString("tableBasciInfo")}</Typography>
				</ExpansionPanelSummary>
				<DisplayEmployeeMainAttributes employee={employee} />
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewEmployee = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(employee: Employee) => showWithDrawer(<DisplayEmployee id={employee.empId} />),
		[showWithDrawer]
	);
};
