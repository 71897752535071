import React from "react";
import {
	Client,
	ClientHeadquaters,
	ClientActive,
	ClientHasContract,
} from "../../../data/listing/clients/types";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { useClientType } from "../../../data/initialData/hooks/useClientTypes";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Status } from "../../../data/models";

export interface DisplayClientBasicInfoProps {
	client: Client;
}

export const DisplayClientBasicInfo: React.FunctionComponent<DisplayClientBasicInfoProps> = ({ client }) => {
	const style = useDisplaySingleEmementStyles();
	const { getString, getDateString } = React.useContext(LocalizationContext);
	const [, typeLabel] = useClientType(client?.cliType);

	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliName")}</span>
				<span className={style.value}>{client.cliName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("clilFullName")}</span>
				<span className={style.value}>{client.fullName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliIdNo")}</span>
				<span className={style.value}>{client.cliIdNo}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliType")}</span>
				<span className={style.value}>{typeLabel}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliTaxCode")}</span>
				<span className={style.value}>{client.taxCode}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("clijbkjs")}</span>
				<span className={style.value}>{client.cliJBKJS}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliForeign")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={client.foreignCli}
						thrutyValue={ClientHeadquaters.Foreign}
						className={style.icon}
					/>
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliCity")}</span>
				<span className={style.value}>{client.city}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliAddress")}</span>
				<span className={style.value}>{client.address}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliMailCity")}</span>
				<span className={style.value}>{client.mailCity}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliMailAddress")}</span>
				<span className={style.value}>{client.mailAddress}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliPhone")}</span>
				<span className={style.value}>{client.phone}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliMobile")}</span>
				<span className={style.value}>{client.mobile}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliFax")}</span>
				<span className={style.value}>{client.fax}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliEmail")}</span>
				<span className={style.value}>{client.email}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliHasContract")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={client.hasContract}
						thrutyValue={ClientHasContract.True}
						className={style.icon}
					/>
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliStatus")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={client.status}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliActive")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={client.active}
						thrutyValue={ClientActive.HasAccess}
						className={style.icon}
					/>
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliActiveFrom")}</span>
				<span className={style.value}>{client.activeFrom && getDateString(client.activeFrom)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliActiveTo")}</span>
				<span className={style.value}>{client.activeTo && getDateString(client.activeTo)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliComment")}</span>
				<span className={style.value}>{client.comment}</span>
			</div>
		</div>
	);
};
