import React from "react";
import { usePorts } from "../../../data/initialData/hooks/usePorts";
import { Ports } from "../../../data/models";
import { AutocompleteSelectType, AutocompleteSelect } from "./AutocompleteSelect";

type PortSelectPropsType = Omit<AutocompleteSelectType<Ports>, "data" | "getLabel">;

export const PortSelect: React.FunctionComponent<PortSelectPropsType> = props => {
	const [ports, renderPortLabel] = usePorts();

	return (
		<AutocompleteSelect<Ports>
			data={ports}
			getLabel={renderPortLabel}
			{...props}
		/>
	);
};
