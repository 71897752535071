import { ThunkType } from "../../../../core/store/reducers";
import createBankGuarantees from "../api/createBankGuarantees";
import updateBankGuarantees from "../api/updateBankGuarantees";
import { BankGuaranteeData } from "../types";
import { setFetchState, invalidateBankGuarantee } from "../bankguaranteesDataSlice";

export const upsertBankGuarantee = (bankguarantee: BankGuaranteeData): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = bankguarantee.grntId
    ? await dispatch(updateBankGuarantees(userId, bankguarantee))
    : await dispatch(createBankGuarantees(userId, bankguarantee));

  if (result && result.resCode === "OK") {
    dispatch(setFetchState({ type: "successful", result: result.resDesc }));
    dispatch(invalidateBankGuarantee());
    return result.resDesc;
  } else {
    dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
  }
};
