import React from "react";
import { useVatRates } from "../../../data/initialData/hooks/useVatRates";
import { VatRate } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType } from "./AutocompleteSelect";

type VatRatePropsType = Omit<AutocompleteSelectType<VatRate>, "data" | "getLabel">;

export const VatRateSelect: React.FunctionComponent<VatRatePropsType> = props => {
	const [units, renderUnitLabel] = useVatRates();

	return (
		<AutocompleteSelect<VatRate>
			{...(props as AutocompleteSelectType<VatRate>)}
			data={units}
			getLabel={renderUnitLabel}
		/>
	);
};
