import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { AntiLaunderingReportFilter } from "../types";

export const getAntiLaunderingReport = (
  user: User,
  filter: AntiLaunderingReportFilter
) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getantimnylndcommreport",
    params: {
      userid: user.userId,
      datefrom: filter.datefrom,
      dateto: filter.dateto,
      limit: filter.limit,
    },
  });
};

export default getAntiLaunderingReport;
