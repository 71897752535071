import React from "react";
import { useQuarterlySecurityReportDownloader } from "../../../../data/reports/securityCommission/securityQuarterly/hooks/useSecurityReportDownloader";
import { useQuarterlySecurityExcelDownloader } from "../../../../data/reports/securityCommission/securityQuarterly/hooks/useQuarterlySecurityExcelDownloader";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import ExcelIcon from "@material-ui/icons/TableChartOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../../components/common/CustomTooltip";

export const DownloadButton: React.FunctionComponent = () => {
	const [downloader, fetchState] = useQuarterlySecurityReportDownloader();
	return (
		<CustomTooltip titleKeyword="exportPDFTooltip">
			<IconButton
				color="secondary"
				component="span"
				onClick={downloader}
				disabled={fetchState.type !== "not-started"}
			>
				<DownloadIcon />
			</IconButton>
		</CustomTooltip>
	);
};


export const DownloadExcelButton: React.FunctionComponent = () => {
	const [downloader, fetchState] = useQuarterlySecurityExcelDownloader();
	return (
	  <CustomTooltip titleKeyword="exportXLSXTooltip">
		<IconButton
		  color="secondary"
		  component="span"
		  onClick={downloader}
		  disabled={fetchState.type !== "not-started"}
		>
		  <ExcelIcon />
		</IconButton>
	  </CustomTooltip>
	);
  };
  