import { ThunkType } from "../../../../../core/store/reducers";
import deleteClientContractApi from "../../api/clientContract/deleteClientContract";
import { setFetchStateContract } from "../../clientsDataReducer";

export const deleteClientContract = (contractId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchStateContract({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteClientContractApi(userId, contractId));

	dispatch(setFetchStateContract(result !== undefined ? { type: "successful" } : { type: "error"}));
};
