import React from "react";
import { ViewOrder } from "../../../data/trading/orders/types";
import {
  PaginationTable,
  PaginationDataProps,
} from "../../components/common/PagginationTable";
import { useOrderActions } from "./useOrderActions";
import { useOrderColumns } from "./useOrderColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useOrderRowColor } from "../orders/useOrderRowColor";

export interface OrderTableProps {
  //  onShowOrder?: (order: ViewOrder) => void;
  //  onReplayToOrder?: (order: ViewOrder) => void;
  userAccessRights: AccessRights;
  getData: () => void;
}

type OrderTablePropsType = OrderTableProps & PaginationDataProps<ViewOrder>;

const OrdersTable: React.FunctionComponent<OrderTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = useOrderActions();
  const columns = useOrderColumns();
  const rowStyle = useOrderRowColor()

  return (
    <PaginationTable<ViewOrder>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
        rowStyle
      }}
    />
  );
};

export default OrdersTable;
