import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { Ports } from "../../models";

const getPortLabel = (port?: Ports) => (port ? `${port.cityName} (${port.zipCode})` : "");

export const usePorts = () => {
	const ports = useSelector<StoreType, Ports[]>(store => store.data.initialData.ports);

	return [ports, getPortLabel] as [typeof ports, typeof getPortLabel];
};

export const usePort = (cityId?: string) => {
	const [ports] = usePorts();

	const port = React.useMemo(() => ports.find(c => c.cityId === cityId), [ports, cityId]);
	const portLabel = React.useMemo(() => getPortLabel(port), [port]);

	return [port, portLabel] as [typeof port, typeof portLabel];
};
