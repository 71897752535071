import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteProduct } from "../thunks/deleteProduct";
import { setDeleteFetchState } from "../productsDataReducer";

export const useProductDelete = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.products.deleteFetchState
	);

	const dispatch = useDispatch();
	const dispatchDeleteProduct = React.useCallback((id: string) => dispatch(deleteProduct(id)), [dispatch]);

	React.useEffect(() => {
		dispatch(setDeleteFetchState({ type: "not-started" }));
	}, [dispatch]);

	const resetState = React.useCallback(() => dispatch(setDeleteFetchState({ type: "not-started" })), [
		dispatch,
	]);

	return [fetchState, dispatchDeleteProduct, resetState] as [
		typeof fetchState,
		typeof dispatchDeleteProduct,
		typeof resetState
	];
};
