import { NewsData } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageData, PageDataStatus, FetchStateType, getFetchStateChange } from "../../models";


type INewsData = {
	all?: NewsData[];
	displayData?: NewsData[];
	currentPage?: PageData<NewsData>;
	displayedNews?: NewsData;
	fetchState: FetchStateType;
	cancelFetchState: FetchStateType;
	downloadNewsFetchState: FetchStateType;
};

const initialState: INewsData = {
	cancelFetchState: { type: "not-started" },
	fetchState: { type: "not-started" },
	downloadNewsFetchState: { type: "not-started" },
};


const name = "news";

const newsSlice = createSlice({
	name,
	initialState,
	reducers: {
		setNews: (state, action: PayloadAction<PageData<NewsData>>) => ({
			...state,
			currentPage: action.payload,
		}),

		setDisplayed: (state, action: PayloadAction<NewsData | undefined>) => ({
			...state,
			displayedNews: action.payload,
		}),

		invalidateNews: state => {
			if (!state.currentPage) return state;

			return {
				...state,
				currentPage: {
					...state.currentPage,
					status: PageDataStatus.DataInvalidated,
				},
			};
		},
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayedNews: action.payload.type === "successful" ? undefined : state.displayedNews,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			fetchState: getFetchStateChange(state.fetchState, action.payload),
		}),
		setCancelNewsFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			cancelFetchState: action.payload,
		}),
		setDownloadNewsFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			downloadNewsFetchState: action.payload,
		}),
	},
});

export const {
	setNews,
	setDisplayed,
	invalidateNews,
	setFetchState,
	setCancelNewsFetchState,
	setDownloadNewsFetchState,
} = newsSlice.actions;

export default newsSlice.reducer;



