import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MonthlyTradingReportFilter } from "./types";
import { PageIndex } from "../../../models";

type TradesReportState = {
  filter: MonthlyTradingReportFilter;
  index: PageIndex;
};

const now = new Date();
export const initialState: TradesReportState = {
  filter: {
    month: now.getMonth() + 1,
    year: now.getFullYear(),
  },
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
};

const name = "SECMonthlyTradeUX";

const monthlyTrade = createSlice({
  name,
  initialState,
  reducers: {
    setIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    setReportMonth: (state, action: PayloadAction<number>) => ({
      ...state,
      filter: {
        ...state.filter,
        month: action.payload,
      },
    }),
    setReportYear: (state, action: PayloadAction<number>) => ({
      ...state,
      filter: {
        ...state.filter,
        year: action.payload,
      },
    }),
  },
});

export const { setReportMonth, setReportYear, setIndex } = monthlyTrade.actions;

export default monthlyTrade.reducer;
