import { ThunkType } from "../../../../core/store/reducers";
import { deleteNewsApi } from "../api/deleteNews";
import { setFetchState, invalidateNews } from "../newsDataReducer";

export const deleteNews = (newsid: string): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = await dispatch(deleteNewsApi(userId, newsid));

  dispatch(
    setFetchState(
      result !== undefined ? { type: "successful" } : { type: "error" }
    )
  );
  dispatch(invalidateNews());
};
