import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { ClientListData } from "../../../data/listing/clients/types";
import React from "react";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";
import { ClientActive, ClientHeadquaters } from "../../../data/listing/clients/types";

export function useClientColumns() {
	const { getString } = useContext(LocalizationContext);
	return useMemo(
		() => [
			{
				title: getString("cliName"),
				field: "cliName",
			},
			{
				title: getString("cliIdNo"),
				field: "cliIdNo",
			},
			{
				title: getString("cliCity"),
				field: "city",
			},
			{
				title: getString("cliPhone"),
				field: "phone",
			},
			{
				title: getString("cliActive"),
				render: client => <DisplayBoolNumber value={client.active} thrutyValue={ClientActive.HasAccess} />,
				customSort: (a, b) => a.active - b.active,
				width: "80px",
			},
			{
				title: getString("cliStatus"),
				render: client => <DisplayBoolNumber value={client.status} thrutyValue={Status.Active} />,
				customSort: (a, b) => a.status - b.status,
				width: "80px",
			},
		],
		[getString]
	) as Column<ClientListData>[];
}
