import { ThunkType } from "../../../../core/store/reducers";
import { setBankGuarantees, invalidateBankGuarantee } from "../bankguaranteesDataSlice";
import { setBankGuaranteeIndex } from "../bankguaranteesUXSlice";
import getBankGuarantees from "../api/getBankGuarantees";
import { PageIndex, PageDataStatus } from "../../../models";

export const loadAllBankGuarantees = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const { pageNumber, itemsPerPage } = store.ux.clearing.bankguarantees.index;
  const { filter } = store.ux.clearing.bankguarantees;
  const user = store.data.auth.current.user;

  const bankguaranteesResponse = await dispatch(
    getBankGuarantees(user, pageNumber, itemsPerPage, filter)
  );

  if (!bankguaranteesResponse || bankguaranteesResponse.resCode !== "OK") {
    return;
  }

  const bankguarantees = {
    currentPageData: bankguaranteesResponse.obj,
    totalCount: Number(bankguaranteesResponse.resDesc) || 0,
    status: PageDataStatus.DataCurrent,
  };

  dispatch(setBankGuarantees(bankguarantees));
};

export const setGuaranteesIndexThunk = (index: PageIndex): ThunkType => (
  dispatch
) => {
  dispatch(setBankGuaranteeIndex(index));
  dispatch(invalidateBankGuarantee());
};
