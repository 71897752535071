import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllPayTypes } from "../thunks/loadAllPayTypes";
import { PayType } from "../types";

export const usePayTypeData = () => {
  const data = useSelector<StoreType, PayType[] | undefined>(
    (s) => s.data.listing.payTypes.displayData
  );
  const allData = useSelector<StoreType, PayType[] | undefined>(
    (s) => s.data.listing.payTypes.all
  );

  const dispatch = useDispatch();
  const dispatchLoadAllPayTypes = React.useCallback(
    () => dispatch(loadAllPayTypes()),
    [dispatch]
  );

  return [data, dispatchLoadAllPayTypes, allData] as [
    typeof data,
    typeof dispatchLoadAllPayTypes,
    typeof allData
  ];
};

export const usePayType = (id?: string) => {
  const allData = useSelector<StoreType, PayType[] | undefined>(
    (s) => s.data.listing.payTypes.all
  );

  const dispatch = useDispatch();
  const dispatchLoadAllPayTypes = React.useCallback(
    () => dispatch(loadAllPayTypes()),
    [dispatch]
  );

  React.useEffect(() => {
    if (!allData && id) {
      dispatchLoadAllPayTypes();
    }
  }, [allData, id, dispatchLoadAllPayTypes]);

  const payType = React.useMemo(() => {
    if (!allData) {
      return undefined;
    } else {
      return allData?.find((e) => e.payTypeId === id);
    }
  }, [id, allData]);

  return [payType] as [typeof payType];
};
