import React from "react";
import { useAttributeValidator } from "../../../data/listing/attributes/hooks/useAttributeValidator";
import { useAttributeUpsert } from "../../../data/listing/attributes/hooks/useAttributeUpsert";
import { Attribute, AttributeManditory, AttributeType } from "../../../data/listing/attributes/types";
import { useHistory } from "react-router-dom";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Grid } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { StringInput } from "../../components/common/StringInput";
import { Status } from "../../../data/models";
import { UnitSelect } from "../../components/common/UnitSelect";
import { BoolInput } from "../../components/common/BoolInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { AttributeTypeSelect } from "./AttributeTypeSelect"
import { attributesLocation } from "./Locations";
import { AttrCompTypeSelect } from "./AttributeComparatorTypeSelect"

export interface EditAttributeBasicInfoPageProps {
	attribute: Attribute;
	editAttribute: (field: keyof Attribute, value: any) => void;
	changed: boolean;
}

export const EditAttributeBasicInfoPage: React.FunctionComponent<EditAttributeBasicInfoPageProps> = props => {
	const { getString } = React.useContext(LocalizationContext);

	const { attribute, editAttribute, changed } = props;
	const [validationState, validate] = useAttributeValidator();
	const [fetchState, upsert] = useAttributeUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	console.log(attribute)


	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editAttribute(event.target.name as any, event.target.value);
		},
		[editAttribute]
	);

	const handleChangeType = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editAttribute("unit", undefined);
			editAttribute("comparator", undefined);
			editAttribute("attrType", event.target.value);
		},
		[editAttribute]
	);

	const handleChangeNum = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editAttribute(event.target.name as any, parseInt(event.target.value));
		},
		[editAttribute]
	);

	const save = React.useCallback(() => {
		const save = async () => {
			const normalized = validate(attribute);
			if (normalized) {
				const newId = await upsert(normalized);
				if (newId) {
					editAttribute("attrId", newId);
				}
			}
		};
		if (fetchState.type === "successful") {
			history.push(attributesLocation);
		} else if (fetchState.type !== "started") {
			save();
		}
	}, [attribute, validate, fetchState, editAttribute, history, upsert]);

	if (!attribute) {
		return null;
	}

	return (
		<>
			<Grid container direction="column" justify="center" alignItems="center" spacing={4}>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={6}>
					<Grid item xs={3}>
						<StringInput<Attribute>
							property="attrName"
							item={attribute}
							validationState={validationState}
							onChange={handleChange}
							label={getString("attributeName")}
						/>
					</Grid>
					<Grid item xs={3}>
						<StringInput<Attribute>
							property="attrNameen"
							item={attribute}
							validationState={validationState}
							onChange={handleChange}
							label={getString("attributeNameEN")}
						/>
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={6}>
					<Grid item xs={3}>
						<AttributeTypeSelect
							handleChange={handleChangeType}
							type={attribute.attrType}
						/>
					</Grid>
					<Grid item xs={3}>
						<UnitSelect
							label={getString("attributeUnit")}
							predicate={unit => unit.ftValue === attribute.unit}
							onChangeItem={unit => editAttribute("unit", unit?.ftValue)}
//							required={validationState.unit !== undefined}
//							error={validationState.unit !== undefined && !validationState.unit}
							disabled={attribute.attrType ===  AttributeType.Select}
						/>	
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={6}>
					<Grid item xs={3}>
						<AttrCompTypeSelect
							handleChange={handleChange}
							comparator={attribute.comparator}
							status={attribute.attrType === AttributeType.Select}
						/>
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={6}>
					<Grid item xs={1}>
						<BoolInput<Attribute>
							property="status"
							item={attribute}
							onChange={handleChange}
							falsyValue={Status.Inactive}
							thrutyValue={Status.Active}
							label={getString("productAttributeStatus")}
						/>
					</Grid>
				</Grid>
			</Grid>
			<FabSave fetchState={fetchState} onClick={save} />
			<LeavePrompt shouldShow={unsavedChanges} />
		</>
	);
};
