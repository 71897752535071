import { ClientOverviewFilter, GetClientOverviewParams } from "../types";
import { PaymentListData } from "../../../../clearing/payments/types";
import { User } from "../../../../auth/types";
import { webApiWithResponse } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

const getPaymentsWebApi = (
  user: User,
  page: number,
  itemsPerPage: number,
  filter: ClientOverviewFilter
) => {
  const params: GetClientOverviewParams = {
    userid: user.userId,
    fromrow: page * itemsPerPage,
    numofrows: itemsPerPage,
    cliid: filter.client?.cliId,
    datefrom: getParamsDateString(filter.datefrom),
    dateto: getParamsDateString(filter.dateto),
    tranid: filter?.tranId,
  };

  return webApiWithResponse<PaymentListData[]>({
    method: "GET",
    url: "clearing/getpaymentlist",
    params,
  });
};

export default getPaymentsWebApi;
