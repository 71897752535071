import React from "react";
import { useEditClientAccountState } from "../../../../data/listing/clients/hooks/clientAccount/useEditClientAccountState";
import { useClientAccountValidator } from "../../../../data/listing/clients/hooks/clientAccount/useClientAccountValidator";
import { useClientAccountUpsert } from "../../../../data/listing/clients/hooks/clientAccount/useClientAccountUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../../pageStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../../components/common/FabSave";
import { ClientAccount, AccountDefault } from "../../../../data/listing/clients/types";
import { StringInput } from "../../../components/common/StringInput";
import { BoolInput } from "../../../components/common/BoolInput";
import { ClientEditParts, getClientsEditLocation } from "../Locations";
import { BankSelect } from "../../../components/common/BankSelect";
import { CurrencySelect } from "../../../components/common/CurrencySelect";
import { AccountOrderSideSelect } from "./AccountOrderSideSelect";
import { Bank } from "../../../../data/models";
import { Currency } from "../../../../data/listing/currencies/types"
import { Status } from "../../../../data/models";
import { LeavePrompt } from "../../../components/common/LeavePrompt";
import { PageTitle } from "../../../components/common/PageTitle";

export interface EditAccountInfo {
	accountId?: string;
	clientId: string;
}

export interface EditAccountProps extends RouteComponentProps<EditAccountInfo> {}

export const EditAccount: React.FunctionComponent<EditAccountProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const { clientId, accountId } = props.match.params;
	const [account, editAccount, changed] = useEditClientAccountState(clientId, accountId);
	const [validationState, validate] = useClientAccountValidator();
	const [fetchState, upsert] = useClientAccountUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editAccount(event.target.name as any, event.target.value);
		},
		[editAccount]
	);

	const changeBank = React.useCallback(
		(bank: Bank | null) => {
			editAccount("bankId", bank ? bank.bankId : "");
			editAccount("bankCode", bank ? bank.bankCode : "");
		},
		[editAccount]
	);

	const changeCurrency = React.useCallback(
		(currency: Currency | null) => {
			editAccount("currId", currency ? currency.currId : "");
			editAccount("currCode", currency ? currency.currCode : "");
		},
		[editAccount]
	);

	const backLocation = React.useMemo(() => getClientsEditLocation(ClientEditParts.Accounts, clientId), [
		clientId,
	]);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(backLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(account);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [account, validate, fetchState, history, upsert, backLocation]);

	const pageTitle = account && account.monAccId ? "accountTitleEdit" : "accountTitleCreate";

	if (!account) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={backLocation} />} />
					<Divider />
					<CardContent>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={3}>
								<StringInput<ClientAccount>
									property="account"
									item={account}
									validationState={validationState}
									onChange={handleChange}
									label={getString("accountNumber")}
								/>
							</Grid>
							<Grid item xs={3}>
								<CurrencySelect
									label={getString("accountCurrency")}
									predicate={currency => currency.currId === account.currId}
									onChangeItem={changeCurrency}
									required={validationState.currId !== undefined}
									error={validationState.currId !== undefined && !validationState.currId}
								/>
							</Grid>
							<Grid item xs={3}>
								<AccountOrderSideSelect
									side={account.forBuySell}
									handleChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={3}>
								<BankSelect
									label={getString("accountBank")}
									predicate={bank => bank.bankId === account.bankId}
									onChangeItem={changeBank}
									required={validationState.bankId !== undefined}
									error={validationState.bankId !== undefined && !validationState.bankId}
								/>
							</Grid>
							<Grid item xs={3}>
								<StringInput<ClientAccount>
									property="clientBankCode"
									item={account}
									validationState={validationState}
									onChange={handleChange}
									label={getString("accountClientBankCode")}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="flex-start"
							spacing={4}
						>
							<Grid item xs={3}>
								<BoolInput<ClientAccount>
									property="defaultAcc"
									item={account}
									onChange={handleChange}
									falsyValue={AccountDefault.False}
									thrutyValue={AccountDefault.True}
									label={getString("accountDefault")}
								/>
							</Grid>
							<Grid item xs={3}>
								<BoolInput<ClientAccount>
									property="status"
									item={account}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("accountStatus")}
								/>
							</Grid>
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
