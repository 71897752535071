import { StockFee, StockFeeValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const stockFeeValidationState: StockFeeValidationState = {
  startDate: true,
  stockFee: true,
  status: true,
};

export const useStockFeeValidator = createUseValidator<
  StockFee,
  StockFeeValidationState
>(stockFeeValidationState);
