import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";
import { MonthlyTradingReportFilter } from "../types";

export const getMonthlyTradingReport = (user: User, filter: MonthlyTradingReportFilter) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getsecmonthlytradingreport",
		params: {
			userid: user.userId,
			month: filter.month,
			year: filter.year,
		},
	});
};

export default getMonthlyTradingReport;
