import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../models";
import { PushNotification } from "./types";

type NotificationReportData = {
  data?: PushNotification[];
  downloadReportFetchState: FetchStateType;
};

const initialState: NotificationReportData = {
  downloadReportFetchState: { type: "not-started" },
};

const name = "pushnotifications";

const pushnotificationsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<PushNotification[] | undefined>
    ) => ({
      ...state,
      data: action.payload,
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      downloadReportFetchState: action.payload,
    }),
  },
});

export const { setData, setFetchState } = pushnotificationsSlice.actions;

export default pushnotificationsSlice.reducer;
