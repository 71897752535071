import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../core/store/reducers";
import { useCallback } from "react";
import { setLocalization } from "./localizationUXSlice";
import { LocalizationLanguage } from "../../core/localization/types";

export const useLocalizationStore = () => {
	const dispatch = useDispatch();
	const localization = useSelector<StoreType, LocalizationLanguage>(store => store.ux.localization.language);

	const setLocalizationCallback = useCallback(language => dispatch(setLocalization(language)), [dispatch]);

	return [localization, setLocalizationCallback] as [typeof localization, typeof setLocalizationCallback];
};
