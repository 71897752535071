import React from "react";
import { Theme, makeStyles, Tooltip, TooltipProps } from "@material-ui/core";
import { LocalizationContext } from "../../../core/localization/Localization";
import { LocalizationKey } from "../../../core/localization/types";

export const useTooltipStyles = makeStyles((theme: Theme) => ({
	arrow: {
		//color: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 14,
	},
	tooltip: {
		//backgroundColor: theme.palette.common.black,
		backgroundColor: theme.palette.common.white,
		color: theme.palette.common.black,
		boxShadow: theme.shadows[2],
		fontSize: 15,
	},
}));

export type CustomTooltipProps = Omit<TooltipProps, "title"> & {
	titleKeyword?: LocalizationKey;
	title?: React.ReactNode;
};

export const CustomTooltip: React.FunctionComponent<CustomTooltipProps> = ({
	children,
	titleKeyword,
	...otherProps
}) => {
	const tooltipStyles = useTooltipStyles();
	const { getString } = React.useContext(LocalizationContext);
	return (
		<Tooltip
			classes={tooltipStyles}
			title={titleKeyword && getString(titleKeyword)}
			interactive
			arrow
			{...otherProps}
		>
			{children}
		</Tooltip>
	);
};
