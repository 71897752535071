import React from "react";
import { useRoleData } from "../../../data/listing/roles/hooks/useRoleData";
import { Role } from "../../../data/listing/roles/types";
import { AutocompleteSelectType, AutocompleteSelect } from "../common/AutocompleteSelect";

export type RoleSelectPropsType = Omit<
	AutocompleteSelectType<Role>,
	"data" | "getLabel"
>

export const RoleSelect: React.FunctionComponent<RoleSelectPropsType> = props => {
	const [, loadAllRoles, roles] = useRoleData();

	React.useEffect(() => {
		if (!roles) {
			loadAllRoles()
		}
	}, [roles, loadAllRoles])

	return (
		<AutocompleteSelect<Role>
			data={roles}
			getLabel={renderEmployeeLabel}
			{...props}
		/>
	);
};

const renderEmployeeLabel = (role: Role | null) => role ? role.roleName || role.roleNameEn : ""
