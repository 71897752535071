import { webApi } from "../../../../core/http/httpThunks";

export const deleteCommisionApi = (userId: string, commid: string) =>
	webApi<string>({
		method: "DELETE",
		url: "listing/delcomm",
		params: {
			userId,
			commid,
		},
	});

export default deleteCommisionApi;
