import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useBankGuaranteeFilter, useCurrentBankGuaranteeFilter } from "../../../data/clearing/bankguarantees/hooks/useBankGuaranteeFilter";
import { BankGuaranteeFilterEditor, BankGuaranteeFilterEditorType, BankGuaranteesFilterChips } from "../bankguarantees/BankGuaranteeFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const BankGuaranteeFilterButton: React.FunctionComponent = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<BankGuaranteesFilterEditor />), [showWithDrawer]);

	const [filter, removeFilter] = useCurrentBankGuaranteeFilter();
	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<BankGuaranteesFilterChips filter={filter} removeFilter={removeFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip">
					<IconButton color="secondary" component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const BankGuaranteesFilterEditor: React.FunctionComponent = () => {
	const [filter, editFilter, save, , reset] = useBankGuaranteeFilter();
	return (
		<BankGuaranteeFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
			type={BankGuaranteeFilterEditorType.Extended}
			reset={reset}
		/>
	);
};
