import { InvoiceFilter } from "../types";
import { InvoiceListData, GetInvoiceParams } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getAccountingOrdereWebApi = (user: User, filter: InvoiceFilter) => {
  return webApi<InvoiceListData[]>({
    method: "GET",
    url: "accounting/getinvoicelist",
    params: {
      userid: user.userId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      status: 1,
    },
  });
};

export default getAccountingOrdereWebApi;
