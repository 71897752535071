import { ordersReportComponents } from "./ordersReport/navigationComponents";
import { tradesReportComponents } from "./tradesReport/navigationComponents";
import { tradesReportAllComponents } from "./tradesReportClientAll/navigationComponents";
import { dailyTradesReportComponents } from "./dailyTrades/navigationComponents";
import { weeklyTradesReportComponents } from "./weeklyTrades/navigationComponents";
import { monthlyTradesReportComponents } from "./monthlyTrades/navigationComponents";
import { annualTradesReportComponents } from "./annualTrades/navigationComponents";
import { securityCommissionComponents } from "./securityCommission/securityCommissionComponents";
import { antiMnyLndCommReportComponents } from "./amlaundering/navigationComponents";
import { exchBusinessComponents } from "./exchbusiness/exchBusinessComponents";
import { exchangeMembersReportComponents } from "./exmember/navigationComponents";
import { deliveryClientComponents } from "../clearing/deliveryClient/navigationComponents";
import { paymentClientComponents } from "../clearing/paymentsClient/navigationComponents";
import { newsComponents } from "../reports/news/navigationComponents";
import { pushNotificationsComponents } from "../reports/pushNotifications/navigationComponents";

export const reportsComponents = [
	...ordersReportComponents,
	...tradesReportComponents,
	...tradesReportAllComponents,
	...dailyTradesReportComponents,
	...weeklyTradesReportComponents,
	...monthlyTradesReportComponents,
	...annualTradesReportComponents,
	...securityCommissionComponents,
	...antiMnyLndCommReportComponents,
	...exchBusinessComponents,
	...exchangeMembersReportComponents,
	...deliveryClientComponents,
	...paymentClientComponents,
	...newsComponents,
	...pushNotificationsComponents,
];
