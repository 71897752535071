import React from "react";
import { useDisplayMBOrder } from "../../../data/trading/mainBoard/hooks/useDisplayMainBoard";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { ViewOrder } from "../../../data/trading/orders/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayOrderMainAttributes, DiplayOrderMod, } from "./DisplayOrderMainAttributes";
import { DisplayOrderLogData } from "./DisplayOrderLogData";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid, IconButton } from "@material-ui/core";
import { DisplayAttributes } from "./attributes/DisplayAttributes";
import { useOrderPrinter } from "../../../data/trading/orderBook/hooks/useOrderPrinter";
import { CustomTooltip } from "../../components/common/CustomTooltip";
//import PrintIcon from "@material-ui/icons/PrintOutlined";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
//import { useAuth } from "../../../data/auth/hooks";
//import { useConfirmOrder } from "../../../data/trading/orders/hooks/useOrderConfirmation";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export interface DisplayMBOrderProps {
	//	id: string;
	id?: string;
	displayMod: DiplayOrderMod;
	enablePrint?: boolean;
	//	cancel?: () => void;
}


export const DisplayOrder: React.FunctionComponent<DisplayMBOrderProps> = ({
	id,
	displayMod,
	enablePrint,
	//	cancel,
}) => {
	const order = useDisplayMBOrder(id);
	const { getString } = React.useContext(LocalizationContext);
	const [print, fetchState] = useOrderPrinter(order);

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	//	const [state, confirmOrder] = useConfirmOrder();
	///	const [auth] = useAuth();

	return !order ? (
		<>{"loading placeholder"}</>
	) : (

		<Grid container justify="center" direction="column">
			<ExpansionPanel expanded>
				<ExpansionPanelSummary>
					{/*<Grid container direction="column" spacing={6}>
					{/* auth.status === "successful" && auth.user.roleCode === "MEMTRADER" && order.ordStatus ==='2' && (
					<Grid item>
						<Paper style={{ padding: "20px" }}>
							<Grid container direction="row" justify="space-around" alignItems="center">
								<Grid item>
									<Typography variant="h5">{getString("orderConfirmQuestion")}</Typography>
								</Grid>
							
							<span>
							<Button onClick={cancel} disabled={state.type !== "not-started"}>
								{getString("orderCancelQuestionNo")}
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={() => confirmOrder(id)}
								disabled={state.type !== "not-started"}
							>
								{getString("orderCancelQuestionYes")}
							</Button>
							</span>
							
							</Grid>
						</Paper>
					</Grid> )*/}
					{/*					<Grid container direction="row" justify="space-around" alignItems="center">  */}
					<Grid container justify="space-around" alignItems="center">
						{/*					<Grid container justify="space-between">   */}
						<Grid item>
							<Typography>{getString("tableBasciInfo")}</Typography>
						</Grid>
						{enablePrint && (
							<Grid item>
								<CustomTooltip titleKeyword="exportPDFTooltip">
									<IconButton
										color="secondary"
										component="span"
										onClick={print}
										disabled={fetchState.type !== "not-started"}
									>
										<DownloadIcon />
									</IconButton>
								</CustomTooltip>
								{fetchState.type !== "not-started" ? getString("pleaseWait") : null}
							</Grid>
						)}
					</Grid>
					{/*				</Grid>  */}
				</ExpansionPanelSummary>
				<DisplayOrderMainAttributes order={order} mod={displayMod} />
				<DisplayAttributes attributes={order.orderAttrList} />
				{/*				<DisplayOrderLogData orderlog={order.orderLogList} mod={displayMod} />   */}
				{!screenExtraSmall && (<DisplayOrderLogData orderlog={order.orderLogList} mod={displayMod} />)}
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewOrder = (displayMod: DiplayOrderMod, enablePrint?: boolean) => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(order: ViewOrder) => showWithDrawer(<DisplayOrder id={order.ordId} displayMod={displayMod} enablePrint={enablePrint} />),
		[showWithDrawer, displayMod]
	);
};
