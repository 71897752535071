import { ThunkType } from "../../../../core/store/reducers";
import getAllClientsAPI from "../api/getAllClients";
import { setClients } from "../clientsDataReducer";

export const loadAllClients = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setClients());

	const { userId } = store.data.auth.current.user;
	const clients = await dispatch(getAllClientsAPI(userId));

	dispatch(setClients(clients || []));
};
