import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { BankGuaranteeData } from "../types";

export const createBankGuaranteeApi = (userId: string, dataguarantee: BankGuaranteeData) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "clearing/newbankgrnt",
    params: {
      userId,
    },
    data: dataguarantee,
  });

export default createBankGuaranteeApi;
