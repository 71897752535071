import { LocalNotifications } from '@capacitor/local-notifications';
import { makeAutoIncrement } from '../util/increment';

const autoIncrement = makeAutoIncrement();

/**
 * Creation of this object is lazy
 * We are missing it when we try to access before its loaded
 * 
 * App should work normally
 */
if (!window.Notification) {
	// @ts-ignore
	window.Notification = {
		permission: 'default',
	};
}

type TNotification = {
	title: string;
	body: string;
	id?: number;
	iconPath?: string;
	style?: string;
    summary?: string;
    priority?: string;
	actionId?: string;
	click_action?: string;
}

export const NotificationManager = {
	get permission() {
		return Notification.permission;
	},
	schedule(notification: TNotification | Array<TNotification>) {
		let notifications = Array.isArray(notification) ? notification : [notification];

		LocalNotifications.schedule({
			notifications: notifications.map((n) => {
				return {
					/**
					 * NOTE:
					 * it would be awesome that we can provide id here instead of making it fake
					 */
					id: n.id ?? autoIncrement.increment(),
					title: n.title,
					body: n.body,
					style: 'BigText',
					summary: 'Expanded notification',
					priority: 'high',
					actionId: 'openMainActivity', 
					click_action: 'window.open("wwww.mvi.rs", "_blank")',
				};
			}),
		});
	},
	async requestPermission(): Promise<any> {
		return LocalNotifications.requestPermissions();
	},
}
