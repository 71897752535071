import { ThunkType } from "../../../../../core/store/reducers";
import { setData } from "../memberContractsDataSlice";
import getMemberContractsDataApi from "../api/getMemberContractsData";

export const loadReport = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const user = store.data.auth.current.user;

  const pagedTrades = await dispatch(getMemberContractsDataApi(user));

  dispatch(setData(pagedTrades || []));
};
