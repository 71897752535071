import React from "react";
import { useEditCurrencyState } from "../../../data/listing/curencyRates/hooks/useEditCurrencyRateState";
import { useCurrencyRateValidator } from "../../../data/listing/curencyRates/hooks/useCurrencyRateValidator";
import { useCurrencyRateUpsert } from "../../../data/listing/curencyRates/hooks/useCurrencyRateUpsert";
import { CurrencyRate } from "../../../data/listing/curencyRates/types";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { PageTitle } from "../../components/common/PageTitle";
import { currencyRateLocation } from "./Locations";
import { CurrencySelect } from "../../components/common/CurrencySelect";
import { NumberInput } from "../../components/common/NumberInput";
import { DateInput } from "../../components/common/DateInput";

export interface EditCurrencyPageInfo {
	rateId?: string;
}

export interface EditCurrencyProps extends RouteComponentProps<EditCurrencyPageInfo> {}

export const EditCurrencyRatePage: React.FunctionComponent<EditCurrencyProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const rateId = props.match.params.rateId ? parseInt(props.match.params.rateId) : undefined;
	const [rate, editRate, changed] = useEditCurrencyState(rateId);
	const [validationState, validate] = useCurrencyRateValidator();
	const [fetchState, upsert] = useCurrencyRateUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editRate(event.target.name as any, event.target.value);
		},
		[editRate]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(currencyRateLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(rate);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [rate, validate, fetchState, history, upsert]);

	const pageTitle = rate && rate.currId ? "curRateEditTitle" : "curRateCreateTitle";

	if (!rate) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={currencyRateLocation} />} />
					<Divider />
					<CardContent>
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
							spacing={4}
						>
							<Grid item xs={3}>
								<CurrencySelect
									label={getString("accountCurrency")}
									predicate={currency => currency.currId === rate.currId}
									onChangeItem={currency => editRate("currId", currency?.currId)}
									required={validationState.currId !== undefined}
									error={validationState.currId !== undefined && !validationState.currId}
									fullWidth
								/>
							</Grid>
							<Grid item xs={3}>
								<DateInput
									label={getString("curRateDate")}
									value={rate.date || null}
									onChange={date => editRate("date", date)}
									required
									error={validationState.date !== undefined && !validationState.date}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="flex-start"
							spacing={4}
						>
							<Grid item xs={3}>
								<NumberInput<CurrencyRate>
									property="midRate"
									item={rate}
									validationState={validationState}
									onChange={handleChange}
									label={getString("curRateMid")}
								/>
							</Grid>
							<Grid item xs={3}>
								<NumberInput<CurrencyRate>
									property="buyRate"
									item={rate}
									validationState={validationState}
									onChange={handleChange}
									label={getString("curRateBuy")}
								/>
							</Grid>
							<Grid item xs={3}>
								<NumberInput<CurrencyRate>
									property="sellRate"
									item={rate}
									validationState={validationState}
									onChange={handleChange}
									label={getString("curRateSell")}
								/>
							</Grid>
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
