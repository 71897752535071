import React from "react";
import { useTradesReportDownloader } from "../../../data/reports/dailyTrade/hooks/useTradesReportDownloader";
import { useDailyTradingExcelDownloader } from "../../../data/reports/dailyTrade/hooks/useDailyTradingExcelDownloader";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import ExcelIcon from "@material-ui/icons/TableChartOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const DownloadButton: React.FunctionComponent = () => {
  const [downloader, fetchState] = useTradesReportDownloader();
  return (
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadExcelButton: React.FunctionComponent = () => {
  const [downloader, fetchState] = useDailyTradingExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};
