import React from "react";
import { useDisplayInvoice } from "../../../data/accounting/invoices/hooks/useDisplayInvoice";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import {
  InvoiceData,
  InvoiceListData,
} from "../../../data/accounting/invoices/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayInvoiceMainAttributes } from "./DisplayInvoiceMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,
  IconButton,
} from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import PrintIcon from "@material-ui/icons/PrintOutlined";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { useInvoicePrinter } from "../../../data/accounting/invoices/hooks/useInvoicePrinter";
import { useInvoiceXml } from "../../../data/accounting/invoices/hooks/useInvoiceXml";

export interface DisplayInvoiceProps {
  id?: string;
  enablePrint?: boolean;
}

export const DisplayInvoice: React.FunctionComponent<DisplayInvoiceProps> = ({
  id,
  enablePrint,
}) => {
  const invoice = useDisplayInvoice(id);
  const { getString } = React.useContext(LocalizationContext);
  const [print, fetchState] = useInvoicePrinter(invoice);
  const [xml, xmlfetchState] = useInvoiceXml(invoice);

  return !invoice ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Grid container justify="space-between">
            <Grid item>
              <Typography>{getString("tableBasciInfo")}</Typography>
            </Grid>
            <Grid item>
              <CustomTooltip titleKeyword="printTooltip">
                <IconButton
                  color="secondary"
                  component="span"
                  onClick={print}
                  disabled={fetchState.type !== "not-started"}
                >
                  <PrintIcon />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip titleKeyword="exportXMLTooltip">
                <IconButton
                  color="secondary"
                  component="span"
                  onClick={xml}
                  disabled={xmlfetchState.type !== "not-started"}
                >
                  <DownloadIcon />
                </IconButton>
              </CustomTooltip>  
            </Grid>
          </Grid>
        </ExpansionPanelSummary>

        <DisplayInvoiceMainAttributes invoice={invoice} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewInvoice = (enablePrint?: boolean) => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (invoice: InvoiceListData) =>
      showWithDrawer(
        <DisplayInvoice id={invoice.invId} enablePrint={enablePrint} />
      ),
    [showWithDrawer]
  );
};
