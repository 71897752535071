import { NavigationItem } from "../../../core/navigation/types";
import ProductGroupPage from "./ProductGroupPage";
import { EditProductGroupPage } from "./EditProductGroupPage";
import { productGroupsLocation, editRouter } from "./Locations";
export const productGroupComponents: NavigationItem[] = [
	{
		Component: ProductGroupPage,
		isMenuItem: true,
		path: productGroupsLocation,
	},
	{
		Component: EditProductGroupPage,
		isMenuItem: false,
		path: editRouter,
	},
];
