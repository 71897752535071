import React from "react";
import { MarginsData } from "../../../data/clearing/marginsData/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";
import { useOrderSideOptionsTranslator } from "../../../data/trading/orders/hooks/useOrderSideOptions";

export interface DisplayMarginsDataMainAttributesProps {
  marginsData: MarginsData;
}

export const DisplayMarginsDataMainAttributes: React.FunctionComponent<DisplayMarginsDataMainAttributesProps> = ({
  marginsData,
}) => {
  const style = useDisplaySingleEmementStyles();
  const { getString, getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);
	const sideTranslator = useOrderSideOptionsTranslator();

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataCalcDate")}</span>
        <span className={style.value}>{getDateString(marginsData.calcDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataTradeNo")}</span>
        <span className={style.value}>{marginsData.tradeNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataTradeDate")}</span>
        <span className={style.value}>{getDateString(marginsData.tradeDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataSide")}</span>
				<span className={style.value}>{sideTranslator(marginsData.side)}</span>  
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataClientIdNo")}</span>
        <span className={style.value}>{marginsData.clientIdNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataClientName")}</span>
        <span className={style.value}>{marginsData.clientName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataProductCode")}</span>
        <span className={style.value}>{marginsData.productCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataProductName")}</span>
        <span className={style.value}>{marginsData.productName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataContrPrice")}</span>
        <span className={style.value}>{getFormatedNumberString(marginsData.contrPrice, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataContrValue")}</span>
        <span className={style.value}>{getFormatedNumberString(marginsData.contrValue, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataGainLoss")}</span>
        <span className={style.value}>{getFormatedNumberString(marginsData.gainLoss, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataMarginReq")}</span>
        <span className={style.value}>{getFormatedNumberString(marginsData.marginReq, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataMarginCall")}</span>
        <span className={style.value}>{getFormatedNumberString(marginsData.marginCall, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataMarginBal")}</span>
        <span className={style.value}>{getFormatedNumberString(marginsData.marginBal, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataBalPlusGL")}</span>
        <span className={style.value}>{getFormatedNumberString(marginsData.marginBalPlusGL, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("marginsDataCoverPerc")}</span>
        <span className={style.value}>{getFormatedNumberString(marginsData.marginCoverPerc, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("marginsDataMaintTriggered")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={marginsData.maintTriggered}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				</span>
			</div>
    </div>
  );
};
