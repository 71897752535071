import { NavigationItem } from "../../../core/navigation/types";
import DisputePage from "./DisputePage";
import { EditDisputePage } from "./EditDisputePage";
import { disputesLocation, editRouter } from "./Locations";
export const disputeComponents: NavigationItem[] = [
  {
    Component: DisputePage,
    isMenuItem: true,
    path: disputesLocation,
  },
  {
    Component: EditDisputePage,
    isMenuItem: false,
    path: editRouter,
  },
];
