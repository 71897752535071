import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PageData,
  PageDataStatus,
  FetchStateType,
  getFetchStateChange,
} from "../../models";
import { CtrlGoodsListData, CtrlGoodsData } from "./types";

type ControlGoodsData = {
  all?: CtrlGoodsData[];
  displayData?: CtrlGoodsData[];
  currentPage?: PageData<CtrlGoodsListData>;
  displayedControl?: CtrlGoodsData;
  fetchState: FetchStateType;
  cancelFetchState: FetchStateType;
  downloadReportFetchState: FetchStateType;
};

const initialState: ControlGoodsData = {
  cancelFetchState: { type: "not-started" },
  fetchState: { type: "not-started" },
  downloadReportFetchState: { type: "not-started" },
};

const name = "controls";

const controlsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setControl: (
      state,
      action: PayloadAction<PageData<CtrlGoodsListData>>
    ) => ({
      ...state,
      currentPage: action.payload,
    }),

    setDisplayed: (
      state,
      action: PayloadAction<CtrlGoodsData | undefined>
    ) => ({
      ...state,
      displayedControl: action.payload,
    }),

    invalidateControl: (state) => {
      if (!state.currentPage) return state;

      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          status: PageDataStatus.DataInvalidated,
        },
      };
    },

    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      displayedControl:
        action.payload.type === "successful"
          ? undefined
          : state.displayedControl,
      all: action.payload.type === "successful" ? undefined : state.all,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: getFetchStateChange(state.fetchState, action.payload),
    }),
    setCancelCtrlGoodsFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      cancelFetchState: action.payload,
    }),
    setDownloadFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      downloadReportFetchState: action.payload,
    }),
  },
});

export const {
  setControl,
  setDisplayed,
  invalidateControl,
  setFetchState,
  setCancelCtrlGoodsFetchState,
  setDownloadFetchState,
} = controlsSlice.actions;

export default controlsSlice.reducer;
