import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteAlarm } from "../thunks/deleteAlarm";
import { setFetchState } from "../alarmDataSlice";

export const useAlarmDelete = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.surveillance.alarms.cancelFetchState
  );

  const dispatch = useDispatch();
  const dispatchDeleteAlarm = React.useCallback(
    (id: string) => dispatch(deleteAlarm(id)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchDeleteAlarm] as [
    typeof fetchState,
    typeof dispatchDeleteAlarm
  ];
};
