import { ThunkType } from "../../../../core/store/reducers";
import createRegionApi from "../api/createRegion";
import updateRegionApi from "../api/updateRegion";
import { setFetchState } from "../regionsDataReducer";
import { Region } from "../types";

export const upsertRegion = (region: Region): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(
		setFetchState({
			data: { type: "started" },
			type: region.regType,
		})
	);

	const { userId } = store.data.auth.current.user;
	const result = region.regId
		? await dispatch(updateRegionApi(userId, region))
		: await dispatch(createRegionApi(userId, region));

	if (result && result.resCode === "OK") {
		dispatch(
			setFetchState({
				data: { type: "successful", result: result.resDesc },
				type: region.regType,
			})
		);
		return result.resDesc;
	} else {
		dispatch(
			setFetchState({
				data: { type: "error", errorCode: result?.resCode },
				type: region.regType,
			})
		);
	}
};
