import React from "react";
import { useFrancoList } from "../../../data/initialData/hooks/useFrancoList";
import { Franco } from "../../../data/models";
import {
  AutocompleteSelect,
  AutocompleteSelectType,
} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<Franco>,
  "data" | "getLabel"
>;

export const FrancoSelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [francoList, renderLabel] = useFrancoList();

  return (
    <AutocompleteSelect<Franco>
      data={francoList}
      getLabel={renderLabel}
      {...(props as any)}
    />
  );
};
