import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { resetOrderIdices } from "../ordersUXSlice";
import { OrderSide, PageIndex } from "../../../../data/models";
import { setOrdersIndexThunk } from "../thunks/loadMainBoard";

export const useMainBoardIndex = (side: OrderSide) => {
	const pageIndex = useSelector<StoreType, PageIndex>(s => s.ux.trading.mainBoard.index[side]);

	const dispatch = useDispatch();
	const dispatchSetIndex = React.useCallback(
		(index: PageIndex) => dispatch(setOrdersIndexThunk(side, index)),
		[dispatch, side]
	);
	const dispatchResetIndex = React.useCallback(() => dispatch(resetOrderIdices(side)), [dispatch, side]);

	return [pageIndex, dispatchSetIndex, dispatchResetIndex] as [
		typeof pageIndex,
		typeof dispatchSetIndex,
		typeof dispatchResetIndex
	];
};
