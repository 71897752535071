import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";
import { upsertAttribute } from "../../thunks/attributes/upsertAttribute";
import { setFetchStateAttribute } from "../../productsDataReducer";
import { ProductAttribute } from "../../types";

export const useAttributeUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.products.fetchStateAttribute
	);

	const dispatch = useDispatch();
	const dispatchUpsertAttribute = React.useCallback(
		(attribute: ProductAttribute) => dispatch(upsertAttribute(attribute)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateAttribute({ type: "not-started" }));
	}, [dispatch]);

	return [fetchState, dispatchUpsertAttribute] as [typeof fetchState, typeof dispatchUpsertAttribute];
};
