import { useDispatch, useSelector } from "react-redux";
import { xmlInvoiceThunk } from "../thunks/xmlInvoiceThunk";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { InvoiceData } from "../types";

export const useInvoiceXml = (invoice?: InvoiceData) => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (s) => s.data.accounting.invoices.xmlInvoiceFetchState
  );
  const dispatch = useDispatch();
  const download = React.useCallback(() => {
    dispatch(xmlInvoiceThunk(invoice));
  }, [dispatch, invoice]);

  return [download, fetchState] as [typeof download, typeof fetchState];
};
