import { ThunkType } from "../../../../../core/store/reducers";
import getClientPersonApi from "../../api/clientPerson/getClientPerson";
import { ClientPerson } from "../../types";

export const getClientPerson = (clientPersonId: string): ThunkType<Promise<ClientPerson | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getClientPersonApi(userId, clientPersonId));
}
