import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../models";

type NotificationReportState = {
	index: PageIndex;
};

export const initialState: NotificationReportState = {
	index: {
		itemsPerPage: 20,
		pageNumber: 0,
	},
};

const name = "pushnotifications";

const pushnotificationsList = createSlice({
	name,
	initialState,
	reducers: {
		setIndex: (state, action: PayloadAction<PageIndex>) => ({
			...state,
			index: action.payload,
		}),
	},
});

export const { setIndex } = pushnotificationsList.actions;

export default pushnotificationsList.reducer;
