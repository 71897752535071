import { ThunkType } from "../../../../core/store/reducers";
import getDeliveryApi from "../api/getDelivery";
import { setDisplayed } from "../deliveryDataSlice";
import { DeliveryData } from "../types";

export const loadDelivery = (deliveryId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const delivery = await dispatch(getDelivery(deliveryId));

  dispatch(setDisplayed(delivery));
};

export const getDelivery = (
  deliveryId: string
): ThunkType<Promise<DeliveryData | undefined>> => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getDeliveryApi(userId, deliveryId));
};
