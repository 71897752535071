import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../core/store/reducers";
import { FetchStateType } from "../models";
import { storepushnotiftokenThunks } from "./storetokenthunks";
import { setFetchState } from "./authDataSlice";

export const useStoreTokenHooks = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.auth.fetchState
  );

  const dispatch = useDispatch();
  const dispatcStoreToken = React.useCallback(
    (pushToken: string) => dispatch(storepushnotiftokenThunks(pushToken)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatcStoreToken] as [
    typeof fetchState,
    typeof dispatcStoreToken
  ];
};
