import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../../models";
import { TurnoverByBrokersReportData } from "./types";

type TradesReportData = {
  data?: TurnoverByBrokersReportData[];
  downloadReportFetchState: FetchStateType;
};

const initialState: TradesReportData = {
  downloadReportFetchState: { type: "not-started" },
};

const name = "tradeByBrokers";

const tradeByBrokersSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<TurnoverByBrokersReportData[] | undefined>
    ) => ({
      ...state,
      data: action.payload,
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      downloadReportFetchState: action.payload,
    }),
  },
});

export const { setData, setFetchState } = tradeByBrokersSlice.actions;

export default tradeByBrokersSlice.reducer;
