import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllPayments } from "../thunks/loadAllPaymentClient";
import { PaymentListData } from "../types";
import { PageData } from "../../../models";
import { invalidatePayment } from "../paymentsDataSlice";

export const usePaymentsData = () => {
  const pageData = useSelector<
    StoreType,
    PageData<PaymentListData> | undefined
  >((s) => s.data.clearing.payments.currentPage);

  const dispatch = useDispatch();
  const dispatchLoadPaymentsPage = React.useCallback(
    () => dispatch(loadAllPayments()),
    [dispatch]
  );

  const invalidate = React.useCallback(() => dispatch(invalidatePayment()), [dispatch]);

  return [pageData, dispatchLoadPaymentsPage, invalidate] as [
    typeof pageData,
    typeof dispatchLoadPaymentsPage,
    typeof invalidate,
  ];
};
