import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent, Tabs, Tab } from "@material-ui/core";
import { EditCommissionBasicInfo } from "./EditCommissionBasicInfoPage";
import { CommissionTable } from "./details/CommissionTable";
import { useEditCommissionState } from "../../../data/listing/commissions/hooks/useEditCommissionState";
import { PageTitle } from "../../components/common/PageTitle";
import { commissionLocation, CommissionEditParts, getCommissionEditLocation } from "./Locations";

export interface EditCommissionPageInfo {
	editPart?: string;
	commissionId?: string;
}

export interface EditCommissionPageProps extends RouteComponentProps<EditCommissionPageInfo> {}

export const EditCommissionPage: React.FunctionComponent<EditCommissionPageProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();
	const { editPart, commissionId } = props.match.params;

	const history = useHistory();
	const [commission, editCommission, changed] = useEditCommissionState(commissionId);

	const handleSideChange = React.useCallback(
		(event: React.ChangeEvent<{}>, newValue: any) => {
			history.push(getCommissionEditLocation(newValue, commissionId));
		},
		[history, commissionId]
	);

	const pageTitle = commissionId ? "commissionEditTitle" : "commissionCreateTitle";
	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={commissionLocation} />} />
					<Divider />
					<CardContent>
						<Tabs
							value={editPart}
							onChange={handleSideChange}
							indicatorColor="secondary"
							textColor="secondary"
							variant="fullWidth"
						>
							<Tab label={getString("tableBasciInfo")} value={CommissionEditParts.BasicInfo} />
							<Tab
								label={getString("commDetailsTitle")}
								value={CommissionEditParts.Details}
								disabled={!commission || !commission.commId}
							/>
						</Tabs>
						<div style={{ marginTop: "20px" }}>
							{editPart === CommissionEditParts.BasicInfo && commission && (
								<EditCommissionBasicInfo
									commission={commission}
									editCommission={editCommission}
									changed={changed}
								/>
							)}
							{editPart === CommissionEditParts.Details && commission && commission.commId && (
								<CommissionTable commissionId={commission.commId} />
							)}
						</div>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
