import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";
import { upsertClientBroker } from "../../thunks/clientBroker/upsertClientBroker";
import { setFetchStateBroker } from "../../clientsDataReducer";
import { ClientBroker } from "../../types";

export const useClientBrokerUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.clients.fetchStateBroker
	);

	const dispatch = useDispatch();
	const dispatchUpsertBroker = React.useCallback(
		(client: ClientBroker) => dispatch(upsertClientBroker(client)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateBroker({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchUpsertBroker] as [typeof fetchState, typeof dispatchUpsertBroker]
};
