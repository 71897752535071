import { useDispatch, useSelector } from "react-redux";
import { downloadCreateInvoicesCreateButton } from "../thunks/loadCIDataCreateButton";
import { resteData } from "../createInvoicesDataSlice";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { CreateInvoicesTranData } from "../types";

export const useCICreateButton = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.accounting.createInvoices.downloadReportFetchState
	);

	const data = useSelector<StoreType, CreateInvoicesTranData[] | undefined>(
		s => s.data.accounting.createInvoices.data
	);

	const hasData = data !== undefined && data.length > 0;

	const dispatch = useDispatch();
	const download = React.useCallback(() => dispatch(downloadCreateInvoicesCreateButton()), [dispatch]);

	const dispatchResetData = React.useCallback(() => {
		dispatch(resteData());
	}, [dispatch]);

	return [download, fetchState, dispatchResetData, hasData] as [
		typeof download,
		typeof fetchState,
		typeof dispatchResetData,
		typeof hasData
	];
};
