import React from "react";
import { useDisplayCountry } from "../../../data/listing/countries/hooks/useDisplayCountry";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { Country } from "../../../data/listing/countries/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayCountryMainAttributes } from "./DisplayCountryMainAttributes";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid } from "@material-ui/core";

export interface DisplayCountriesProps {
	id?: string;
}

export const DisplayCountry: React.FunctionComponent<DisplayCountriesProps> = ({ id }) => {
	const country = useDisplayCountry(id);
	const { getString } = React.useContext(LocalizationContext);

	return !country ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel expanded>
				<ExpansionPanelSummary>
					<Typography>{getString("tableBasciInfo")}</Typography>
				</ExpansionPanelSummary>
				<DisplayCountryMainAttributes country={country} />
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewCountry = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(country: Country) => showWithDrawer(<DisplayCountry id={country.cntId} />),
		[showWithDrawer]
	);
};
