import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { GuaranteeFilter } from "../../../data/clearing/guarantees/types";
import { LocalizationContext } from "../../../core/localization/Localization";

import { useGuaranteeSideOptionsTranslator } from "../../../data/clearing/guarantees/hooks/useGuaranteeSideOptions";
import { useGuaranteeDedicationOptionsTranslator } from "../../../data/clearing/guarantees/hooks/useGuaranteeDedicationOptions";

export const GuaranteesFilterChipsComponent: React.FunctionComponent<GuaranteeFilterChipsProps> = ({
  filter,
  removeFilter,
}) => {
  const { getDateString, getString } = React.useContext(LocalizationContext);
  const sideTranslator = useGuaranteeSideOptionsTranslator();
  const dedicatedTranslator = useGuaranteeDedicationOptionsTranslator();
  return (
    <Grid container spacing={1}>
      {filter.inout && (
        <Grid item>
          <Chip
            label={sideTranslator(filter.inout)}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("inout")}
          />
        </Grid>
      )}
      {filter.nondedicated !== undefined && (
        <Grid item>
          <Chip
            label={dedicatedTranslator(filter.nondedicated)}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("nondedicated")}
          />
        </Grid>
      )}
      {filter.datefrom && (
        <Grid item>
          <Chip
            label={`${getString("filterFrom")}: ${getDateString(
              new Date(filter.datefrom)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("datefrom")}
          />
        </Grid>
      )}
      {filter.dateto && (
        <Grid item>
          <Chip
            label={`${getString("filterTo")}: ${getDateString(
              new Date(filter.dateto)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("dateto")}
          />
        </Grid>
      )}
      {filter.client && (
        <Grid item>
          <Chip
            label={filter.client.cliName}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("client")}
          />
        </Grid>
      )}
      {filter.status && filter.status.ftDescr !== "" && (
        <Grid item>
          <Chip
            label={filter.status.ftDescr}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("status")}
          />
        </Grid>
      )}
    </Grid>
  );
};

interface GuaranteeFilterChipsProps {
  filter: GuaranteeFilter;
  removeFilter: (type: keyof GuaranteeFilter) => void;
}
