import React from "react";
import { useDisplayCommission } from "../../../data/listing/commissions/hooks/useDisplayCommission";
import { Commission } from "../../../data/listing/commissions/types";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayProductBasicInfo } from "./DisplayCommissionBasicInfo";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useToggleStateDictionary } from "../../../core/util/hooks";
import { DisplayCommissionDetailes } from "./details/DisplayCommissionDetailes";

enum AccordionNames {
	BasicInfo = "basicInfo",
	Detailes = "detailes",
}

const accordionNames = Object.values(AccordionNames);

export interface DisplayEmployeeProps {
	id?: string;
}

export const DisplayCommission: React.FunctionComponent<DisplayEmployeeProps> = ({ id }) => {
	const commission = useDisplayCommission(id);
	const { getString } = React.useContext(LocalizationContext);

	const [open, toggleOpen] = useToggleStateDictionary(accordionNames, [AccordionNames.BasicInfo]);

	return !commission || !commission.commId ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel
				expanded={open[AccordionNames.BasicInfo]}
				onChange={toggleOpen[AccordionNames.BasicInfo]}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{getString("tableBasciInfo")}</Typography>
				</ExpansionPanelSummary>
				<DisplayProductBasicInfo commission={commission} />
			</ExpansionPanel>
			<ExpansionPanel
				expanded={open[AccordionNames.Detailes]}
				onChange={toggleOpen[AccordionNames.Detailes]}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{getString("commDetailsTitle")}</Typography>
				</ExpansionPanelSummary>
				<DisplayCommissionDetailes commissionId={commission.commId} />
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewCommission = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(commission: Commission) => showWithDrawer(<DisplayCommission id={commission.commId} />),
		[showWithDrawer]
	);
};
