import React from "react";
import { useEditUserState } from "../../../data/listing/users/hooks/useEditUserState";
import { useUserValidator } from "../../../data/listing/users/hooks/useUserValidator";
import { useUserUpsert } from "../../../data/listing/users/hooks/useUserUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { DateInput } from "../../components/common/DateInput";
import { StringInput } from "../../components/common/StringInput";
import {
	UserData,
	UserEmployee,
	UserChangePass,
	UserActive,
	UserSubscribtionUnlimited,
} from "../../../data/listing/users/types";
import { EmployeeSelect } from "../../components/listing/EmployeeSelect";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { ClientPersonSelect } from "../../components/listing/ClientPersonSelect";
import { RoleSelect } from "../../components/listing/RoleSelect";
import { BoolInput } from "../../components/common/BoolInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { usersLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";

export interface EditUserInfo {
	userId?: string;
}

export interface EditUserProps extends RouteComponentProps<EditUserInfo> {}

export const EditUserPage: React.FunctionComponent<EditUserProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [user, editUser, changed] = useEditUserState(props.match.params.userId);
	const [validationState, validate] = useUserValidator();
	const [fetchState, upsert] = useUserUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editUser(event.target.name as any, event.target.value);
		},
		[editUser]
	);

	const handleSubscriptionFromFromChange = React.useCallback(
		(date: Date | null) => {
			editUser("subscribed_Until", date);
		},
		[editUser]
	);

	const handleSubscriptionUnlimitedChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (event.target.checked) {
				editUser("subscrUnlimited", UserSubscribtionUnlimited.True);
				editUser("subscribed_Until", undefined);
			} else {
				editUser("subscrUnlimited", UserSubscribtionUnlimited.False);
			}
		},
		[editUser]
	);

	const weakPasswordMessage = React.useMemo(
		() =>
			fetchState.type === "error" && fetchState.errorCode && fetchState.errorCode.startsWith("BADPASS")
				? getString("messageWeakPassword")
				: undefined,
		[fetchState, getString]
	);

	const handleIsEmployeeChange = React.useCallback(
		(newValue: number) => {
			if (newValue === UserEmployee.True) {
				editUser("isEmployee", UserEmployee.True);
				editUser("clientId", undefined);
				editUser("cliPrsId", undefined);
			} else {
				editUser("isEmployee", UserEmployee.False);
				editUser("empId", undefined);
			}
		},
		[editUser]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(usersLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(user);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [user, validate, fetchState, upsert, history]);

	const pageTitle = user && user.userId ? "userEditTitle" : "userCreateTitle";

	if (!user) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={usersLocation} />} />
					<Divider />
					<CardContent>
						<Grid container direction="column" justify="center" alignItems="center" spacing={4}>
							<Grid
								container
								item
								direction="row"
								justify="flex-start"
								alignItems="center"
								spacing={3}
							>
								<Grid item xs={3}>
									<StringInput<UserData>
										property="userName"
										item={user}
										validationState={validationState}
										onChange={handleChange}
										label={getString("userUserName")}
									/>
								</Grid>
								<Grid item xs={3}>
									<StringInput<UserData>
										property="loginUser"
										item={user}
										validationState={validationState}
										onChange={handleChange}
										label={getString("userLoginUser")}
									/>
								</Grid>
								<Grid item xs={3}>
									<RoleSelect
										label={getString("userRoleId")}
										predicate={role => role.roleId === user.roleId}
										onChangeItem={role => {
											editUser("roleId", role ? role.roleId : undefined);
											handleIsEmployeeChange(role && role.isEmployee !== undefined ? role.isEmployee : 0);
										}}
										required={validationState.roleId !== undefined}
										error={
											validationState.roleId !== undefined && !validationState.roleId
										}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								item
								direction="row"
								justify="flex-start"
								alignItems="center"
								spacing={3}
							>
								<Grid item xs={3}>
									<BoolInput<UserData>
										property="changePass"
										item={user}
										onChange={handleChange}
										falsyValue={UserChangePass.False}
										thrutyValue={UserChangePass.True}
										label={getString("userChangePass")}
										disabled={!user.userId}
									/>
								</Grid>
								{(!user.userId || user.changePass === UserChangePass.True) && (
									<Grid item xs={3}>
										<StringInput<UserData>
											property="loginPass"
											item={user}
											validationState={validationState}
											onChange={handleChange}
											label={getString("userLoginPass")}
											errorMessage={weakPasswordMessage}
										/>
									</Grid>
								)}
							</Grid>
							<Grid
								container
								item
								direction="row"
								justify="flex-start"
								alignItems="center"
								spacing={3}
							>
								<Grid item xs={3}>
									<BoolInput<UserData>
										property="isEmployee"
										item={user}
										onChange={console.log}
										falsyValue={UserEmployee.False}
										thrutyValue={UserEmployee.True}
										label={getString("userIsEmployee")}
										disabled
									/>
								</Grid>
								{user.isEmployee === UserEmployee.True && (
									<Grid item xs={3}>
										<EmployeeSelect
											label={getString("userEmpId")}
											predicate={empl => empl.empId === user.empId}
											onChangeItem={empl =>
												editUser("empId", empl ? empl.empId : undefined)
											}
											required={validationState.empId !== undefined}
											error={
												validationState.empId !== undefined && !validationState.empId
											}
										/>
									</Grid>
								)}
								{user.isEmployee === UserEmployee.False && (
									<Grid item xs={3}>
										<ClientSelect
											label={getString("userClientId")}
											predicate={client => client.cliId === user.clientId}
											onChangeItem={client =>
												editUser("clientId", client ? client.cliId : undefined)
											}
											required={validationState.clientId !== undefined}
											error={
												validationState.clientId !== undefined &&
												!validationState.clientId
											}
										/>
									</Grid>
								)}
								{user.clientId && user.clientId !== null && (
									<Grid item xs={3}>
										<ClientPersonSelect
											label={getString("userCliPrsId")}
											clientId={user.clientId}
											predicate={person => person.cliPrsId === user.cliPrsId}
											onChangeItem={person =>
												editUser("cliPrsId", person ? person.cliPrsId : undefined)
											}
											required={validationState.cliPrsId !== undefined}
											error={
												validationState.cliPrsId !== undefined &&
												!validationState.cliPrsId
											}
										/>
									</Grid>
								)}
							</Grid>
							<Grid
								container
								item
								direction="row"
								justify="flex-start"
								alignItems="center"
								spacing={3}
							>
								<Grid item xs={3}>
									<BoolInput<UserData>
										property="subscrUnlimited"
										item={user}
										onChange={handleSubscriptionUnlimitedChange}
										falsyValue={UserSubscribtionUnlimited.False}
										thrutyValue={UserSubscribtionUnlimited.True}
										label={getString("userSubscrUnlimited")}
									/>
								</Grid>
								<Grid item xs={3}>
									<DateInput
										label={getString("userSubscribedUntil")}
										value={user.subscribed_Until || null}
										onChange={handleSubscriptionFromFromChange}
										disabled={user.subscrUnlimited === UserSubscribtionUnlimited.True}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								item
								direction="row"
								justify="flex-start"
								alignItems="center"
								spacing={3}
							>
								<Grid item xs={3}>
									<BoolInput<UserData>
										property="isActive"
										item={user}
										onChange={handleChange}
										falsyValue={UserActive.False}
										thrutyValue={UserActive.True}
										label={getString("userIsActive")}
									/>
								</Grid>
							</Grid>
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
