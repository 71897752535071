import React from "react";
import { useAttributeValuesData } from "../../../data/listing/attributes/hooks/attributeValues/useAttributeValuesData";
import { AttributeValue } from "../../../data/listing/attributes/types";
import { AutocompleteSelect, AutocompleteSelectType } from "../common/AutocompleteSelect";

type ProductAttributeSelectPropsType = Omit<AutocompleteSelectType<AttributeValue>, "data" | "getLabel"> & {
	attributeId: string;
};

export const AttributeValueSelect: React.FunctionComponent<ProductAttributeSelectPropsType> = ({
	attributeId,
	...otherProps
}) => {
	const [values, loadValues] = useAttributeValuesData(attributeId);

	React.useEffect(() => {
		loadValues();
	}, [loadValues]);

	return (
		<AutocompleteSelect<AttributeValue>
			{...(otherProps as AutocompleteSelectType<AttributeValue>)}
			data={values?.filter(att => att.attributeId === attributeId && att.status===1)}
			getLabel={renderAttributeLabel}
		/>
	);
};

const renderAttributeLabel = (attr: AttributeValue) => attr.name || attr.nameen;
