import { ThunkType } from "../../../../core/store/reducers";
import { setDisplayed } from "../ordersDataSlice";
import { getOrder } from "../../orders/thunks/getOrder";

export const loadMBOrder = (ordId: string): ThunkType => async (
  dispatch
) => {
  dispatch(setDisplayed());

  const mborder = await dispatch(getOrder(ordId));

  dispatch(setDisplayed(mborder));
};


