import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { PaymentFilter, PayGroup } from "../types";
import { getParamsDateString } from "../../../../core/util/functions";

export const getPaymentsReport = (user: User, filter: PaymentFilter) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getguaranteesreport",
    params: {
      userid: user.userId,
      paygroup: PayGroup.Payments,
      inout: filter.inout,
      cliid: filter?.client?.cliId,
      nondedicated: filter.nondedicated,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      },
  });
};

export default getPaymentsReport;
