import React from "react";
import { useDisplayRegion } from "./useDisplayRegion";
import { Region } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status, RegType } from "../../../models";

export const createDefaultRegion = (type?: RegType): Region => ({
	regName: "",
	regType: type || 20,
	status: Status.Active,
});

export const useEditRegion = (type?: RegType, regId?: string) => {
	const displayRegion = useDisplayRegion(regId);

	return React.useMemo(() => {
		if (regId !== undefined && displayRegion === undefined) {
			return undefined;
		}

		if (regId === undefined) {
			const defaultRegion = createDefaultRegion(type);
			return { ...defaultRegion };
		}

		return displayRegion ? { ...displayRegion } : undefined;
	}, [regId, displayRegion, type]);
};

export const useEditRegionState = (type?: RegType, regId?: string) => {
	const region = useEditRegion(type, regId);
	return useCopiedState(region);
};
