import { webApi } from "../../../../core/http/httpThunks";
import { StockFee } from "../types";

export const getStockFeeApi = (userId: string, id: number) =>
  webApi<StockFee>({
    method: "GET",
    url: "clearing/getstockfee",
    params: {
      userId,
      id,
    },
  });

export default getStockFeeApi;
