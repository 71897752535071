import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthorizationState } from "./types";
import { LoginResponse, FetchStateType, getFetchStateChange } from "../models";
import { ErrorData } from "../../core/http/types";

interface IAuthState {
	current: AuthorizationState;
	token?: string;
	fetchState: FetchStateType;
}

const initialState: IAuthState = {
	current: { status: "init" },
	fetchState: { type: "not-started" },
};

const name = "auth";

const reportsSlice = createSlice({
	name,
	initialState,
	reducers: {
		successfulLogin: (state, action: PayloadAction<LoginResponse>) => ({
			...state,
			current: {
				status: "successful",
				user: action.payload.uData,
			},
		}),
		failedLogin: (state, action: PayloadAction<ErrorData>) => ({
			...state,
			current: {
				status: "failed",
				error: action.payload,
			},
		}),
		resetLoginState: state => ({
			...state,
			current: {
				status: "init",
			},
		}),
		setPendingLoginState: state => ({
			...state,
			current: {
				status: "pending",
			},
		}),
		setToken: (state, action: PayloadAction<string | undefined>) => ({
			...state,
			token: action.payload
		}),
		setPushToken: (state, action: PayloadAction<string | undefined>) => ({
			...state,
			token: action.payload
		}),
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			fetchState: getFetchStateChange(state.fetchState, action.payload),
		}),
	},
});

export const { successfulLogin, failedLogin, resetLoginState, setPendingLoginState, setToken, setPushToken, setFetchState } = reportsSlice.actions;

export default reportsSlice.reducer;
