import { webApiWithResponse } from "../../../../../core/http/httpThunks";
import { ClientPerson } from "../../types";

export const createClientPersonApi = (userId: string, person: ClientPerson) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/newcliprs",
		params: {
			userId
		},
		data: person
	});

export default createClientPersonApi;
