import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../../layout/DisplayDrawerContext";
import { useClientOverviewFilter, 	useCurrentClientOverviewFilter, } from "../../../../data/reports/exchbusiness/clientOverview/hooks/useClientOverviewFilter";
import {
	ClientOverviewFilterEditor,
	ClientOverviewFilterChips,
} from "../clientOverview/ClientOverviewFilterEditor";
import { CustomTooltip } from "../../../components/common/CustomTooltip";
import { ClientOverviewParts } from "./Locations";

export const ClientOverviewFilterButton: React.FunctionComponent<{tab?: ClientOverviewParts}> = ({ tab }) => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<ClientsOverviewFilterEditor tab={tab} />), [showWithDrawer, tab]);

	const [filter, removeFilter] = useCurrentClientOverviewFilter();
	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<ClientOverviewFilterChips filter={filter} removeFilter={removeFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip">
					<IconButton color="secondary" component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const ClientsOverviewFilterEditor: React.FunctionComponent<{tab?: ClientOverviewParts}> = ({ tab }) => {
	const [filter, editFilter, save, , rest] = useClientOverviewFilter();
	return (
		<ClientOverviewFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
			type={tab}
			reset={rest}
		/>
	);
};
