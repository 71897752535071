import React from "react";
import { NewsData } from "../../../data/reports/news/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayNewsMainAttributesProps {
  news: NewsData;
}

export const DisplayNewsMainAttributes: React.FunctionComponent<DisplayNewsMainAttributesProps> = ({
  news,
}) => {
  const style = useDisplaySingleEmementStyles();
  const {
    getString,
    getDateString,
    getFormatedNumberString,
  } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("newsTime")}</span>
        <span className={style.value}>{news.newsTime}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("newsTitle")}</span>
        <span className={style.value}>{news.title}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("newsTitleen")}</span>
        <span className={style.value}>{news.titleEn}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("stockFeeStatus")}</span>
        <span className={style.value}>
          <DisplayBoolNumber
            value={news.status}
            thrutyValue={Status.Active}
            className={style.icon}
          />
        </span>
      </div>
    </div>
  );
};
