import { ClientBroker } from "../../types";
import { ValidationState } from "../../../../../core/util/types";
import { createUseValidator } from "../../../../../core/util/hooks";

export const BrokerValidation: ValidationState<ClientBroker> = {
	brokerId: true,
};

export const useClientBrokerValidator = createUseValidator<ClientBroker, ValidationState<ClientBroker>>(
	BrokerValidation
);
