import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { DeliveryData } from "../types";

export const createDeliveryApi = (userId: string, delivery: DeliveryData) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "clearing/newdlvrgoods",
    params: {
      userId,
    },
    data: delivery,
  });

export default createDeliveryApi;
