import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuarterlySecurityReportFilter } from "./types";
import { PageIndex } from "../../../models";

type TradesReportState = {
  filter: QuarterlySecurityReportFilter;
  index: PageIndex;
};

const now = new Date();
export const initialState: TradesReportState = {
  filter: {
    year: now.getFullYear(),
  //  qtnumber: Math.floor((now.getMonth() + 1) / 3) + 1,
    qtnumber: Math.floor((now.getMonth() -1) / 3) + 1,
  },
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
};

const name = "quarterlySecurity";

const quarterlySecuritySlice = createSlice({
  name,
  initialState,
  reducers: {
    setIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    setReportYear: (state, action: PayloadAction<number>) => ({
      ...state,
      filter: {
        ...state.filter,
        year: action.payload,
      },
    }),

    setReportQuarter: (state, action: PayloadAction<number>) => ({
      ...state,
      filter: {
        ...state.filter,
        qtnumber: action.payload,
      },
    }),
  },
});

export const {
  setReportYear,
  setReportQuarter,
  setIndex,
} = quarterlySecuritySlice.actions;

export default quarterlySecuritySlice.reducer;
