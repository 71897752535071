import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { City } from "../types";

export const updateCityApi = (userId: string, city: City) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "listing/updcity",
    params: {
      userId,
    },
    data: city,
  });

export default updateCityApi;
