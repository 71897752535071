export const monthlyTradesReportLocation = "/reports/monthlyreports";

export const getReportLocation = (
  basePath: string,
  year: number,
  month: number
) => {
  return `${basePath}/${year}/${month}`;
};

export const monthlyProductLocationBase = "/reports/monthlyreports/products";
export const monthlyClientLocationBase = "/reports/monthlyreports/client";
export const monthlyDateLocationBase = "/reports/monthlyreports/date";

export const monthlyProductLocation = `${monthlyProductLocationBase}/:year/:month`;
export const monthlyClientLocation = `${monthlyClientLocationBase}/:year/:month`;
export const monthlyDateLocation = `${monthlyDateLocationBase}/:year/:month`;

export const getProductLocation = (year: number, month: number) =>
  getReportLocation(monthlyProductLocationBase, year, month);
export const getClientLocation = (year: number, month: number) =>
  getReportLocation(monthlyClientLocationBase, year, month);
export const getDateLocation = (year: number, month: number) =>
  getReportLocation(monthlyDateLocationBase, year, month);
