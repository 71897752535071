import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageData, PageDataStatus, FetchStateType, getFetchStateChange } from "../../models";
import { InvoiceData, InvoiceListData } from "./types";
import { setFetchState as createInvoiceFetchState } from "../createInvoices/createInvoicesDataSlice";

type InvoicesData = {
	all?: InvoiceData[];
	displayData?: InvoiceData[];
	currentPage?: PageData<InvoiceListData>;
	displayedInvoice?: InvoiceData;
	fetchState: FetchStateType;
	cancelFetchState: FetchStateType;
	printInvoiceFetchState: FetchStateType;
	xmlInvoiceFetchState: FetchStateType;
};

const initialState: InvoicesData = {
	cancelFetchState: { type: "not-started" },
	fetchState: { type: "not-started" },
	printInvoiceFetchState: { type: "not-started" },
	xmlInvoiceFetchState: { type: "not-started" },
};

const name = "invoices";

const invoicesSlice = createSlice({
	name,
	initialState,
	reducers: {
		setInvoices: (state, action: PayloadAction<PageData<InvoiceListData>>) => ({
			...state,
			currentPage: action.payload,
		}),

		setDisplayed: (state, action: PayloadAction<InvoiceData | undefined>) => ({
			...state,
			displayedInvoice: action.payload,
		}),

		invalidateInvoices: state => {
			if (!state.currentPage) return state;

			return {
				...state,
				currentPage: {
					...state.currentPage,
					status: PageDataStatus.DataInvalidated,
				},
			};
		},
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayedInvoice: action.payload.type === "successful" ? undefined : state.displayedInvoice,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			fetchState: getFetchStateChange(state.fetchState, action.payload),
		}),
		setCancelInvoiceFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			cancelFetchState: action.payload,
		}),
		setPrintInvoiceFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			printOrderFetchState: action.payload,
		}),
		setXmlInvoiceFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			xmlOrderFetchState: action.payload,
		}),
	},
	extraReducers: builder => {
		builder.addCase(createInvoiceFetchState, (state, action) =>
			action.payload.type !== "successful" || !state.currentPage
				? state
				: {
						...state,
						currentPage: {
							...state.currentPage,
							status: PageDataStatus.DataInvalidated,
						},
				  }
		);
	},
});

export const {
	setInvoices,
	setDisplayed,
	invalidateInvoices,
	setFetchState,
	setCancelInvoiceFetchState,
	setPrintInvoiceFetchState,
	setXmlInvoiceFetchState,
} = invoicesSlice.actions;

export default invoicesSlice.reducer;
