import React from "react";
import { useEditPayTypeState } from "../../../data/listing/payTypes/hooks/useEditPayTypeState";
import { usePayTypeValidator } from "../../../data/listing/payTypes/hooks/usePayTypeValidator";
import { usePayTypeUpsert } from "../../../data/listing/payTypes/hooks/usePayTypeUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { PayType } from "../../../data/listing/payTypes/types";
import { StringInput } from "../../components/common/StringInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import {  Status,  } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { payTypesLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";

export interface EditPayTypeInfo {
	paytypeId?: string;
}

export interface EditPayTypeProps extends RouteComponentProps<EditPayTypeInfo> {}

export const EditPayTypePage: React.FunctionComponent<EditPayTypeProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [payType, editPayType, changed] = useEditPayTypeState(props.match.params.paytypeId);
	const [validationState, validate] = usePayTypeValidator();
	const [fetchState, upsert] = usePayTypeUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editPayType(event.target.name as any, event.target.value);
		},
		[editPayType]
	);


	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(payTypesLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(payType);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [payType, validate, fetchState, history, upsert]);

	const pageTitle = payType && payType.payTypeId ? "payTypeEditTitle" : "payTypeCreateTitle";

	if (!payType) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={payTypesLocation}/>} />
					<Divider />
					<CardContent>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={4}>
								<StringInput<PayType>
									property="payTypeDescr"
									item={payType}
									validationState={validationState}
									onChange={handleChange}
									label={getString("payTypeDesc")}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={4}>
								<StringInput<PayType>
									property="payTypeDescrEn"
									item={payType}
									validationState={validationState}
									onChange={handleChange}
									label={getString("payTypeDescEn")}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={1}>
								<BoolInput<PayType>
									property="inPonder"
									item={payType}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("payTypePonder")}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={1}>
								<BoolInput<PayType>
									property="status"
									item={payType}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("payTypeStatus")}
								/>
							</Grid>
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
