import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useWTRFilter } from "../../../data/reports/weeklyTrade/hooks/useWTRFilter";
import {
  WTRFilterEditor,
  WTRFilterEditorType,
} from "../weeklyTrades/WTRFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const WTRFilterButton: React.FunctionComponent = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const showFilter = React.useCallback(
    () => showWithDrawer(<WTRsFilterEditor />),
    [showWithDrawer]
  );

  return (
    <CustomTooltip titleKeyword="filterTooltip">
      <IconButton color="secondary" component="span" onClick={showFilter}>
        <FilterIcon />
      </IconButton>
    </CustomTooltip>
  );
};

const WTRsFilterEditor: React.FunctionComponent = () => {
  const [filter, editFilter, save, , reset] = useWTRFilter();
  return (
    <>
      {filter && (
        <WTRFilterEditor
          filter={filter}
          editFilter={editFilter}
          save={save}
          type={WTRFilterEditorType.Basic}
          reset={reset}
        />
      )}
    </>
  );
};
