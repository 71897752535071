import React from "react";
import { useContractTypes } from "../../../data/initialData/hooks/useContractTypes";
import { ContractType } from "../../../data/models";
import { AutocompleteSelectType, AutocompleteSelect } from "./AutocompleteSelect";


type ContractTypeeSelectPropsType = Omit<AutocompleteSelectType<ContractType>, "data" | "getLabel">;

export const ContractTypeSelect: React.FunctionComponent<ContractTypeeSelectPropsType> = props => {
	const [contractType, renderLabel] = useContractTypes();

	return (
		<AutocompleteSelect<ContractType>
			data={contractType}
			getLabel={renderLabel}
			{...(props as any)}
		/>
	);
};

