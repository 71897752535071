import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";
import { QuarterlySecurityReportFilter } from "../types";

export const getQuarterlySecurityReport = (user: User, filter: QuarterlySecurityReportFilter) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getsecquartertradingreport",
		params: {
			userid: user.userId,
			year: filter.year,
			qtnumber: filter.qtnumber,
		},
	});
};

export default getQuarterlySecurityReport;
