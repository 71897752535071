import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteCity } from "../thunks/deleteCity";
import { setFetchState } from "../citiesDataReducer";

export const useCityDelete = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.listing.cities.fetchState
  );

  const dispatch = useDispatch();
  const dispatchDeleteCity = React.useCallback(
    (id: string) => dispatch(deleteCity(id)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchDeleteCity] as [
    typeof fetchState,
    typeof dispatchDeleteCity
  ];
};
