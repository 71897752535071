import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType, RegType } from "../../../models";
import { deleteRegion } from "../thunks/deleteRegion";
import { setFetchState } from "../regionsDataReducer";

export const useRegionDelete = (type?: RegType) => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.regions.fetchState[type || RegType.Region]
	);

	const dispatch = useDispatch();
	const dispatchDeleteRegion = React.useCallback(
		(id: string) => {
			if (type) {
				dispatch(deleteRegion(id, type));
			}
		},
		[dispatch, type]
	);

	React.useEffect(() => {
		if (type) {
			dispatch(setFetchState({ type, data: { type: "not-started" } }));
		}
	}, [dispatch, type]);

	return [fetchState, dispatchDeleteRegion] as [typeof fetchState, typeof dispatchDeleteRegion];
};
