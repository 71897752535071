import { ThunkType } from "../../../../../core/store/reducers";
import getAttributeApi from "../../api/attributes/getAttribute";
import { ProductAttribute } from "../../types";

export const getAttribute = (
	attributeId: string
): ThunkType<Promise<ProductAttribute | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getAttributeApi(userId, attributeId));
};
