import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useGuaranteeFilter, useCurrentGuaranteeFilter } from "../../../data/clearing/guarantees/hooks/useGuaranteeFilter";
import { GuaranteeFilterEditor, GuaranteeFilterEditorType, GuaranteesFilterChips } from "../guarantees/GuaranteeFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const GuaranteeFilterButton: React.FunctionComponent = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<GuaranteesFilterEditor />), [showWithDrawer]);

	const [filter, removeFilter] = useCurrentGuaranteeFilter();
	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<GuaranteesFilterChips filter={filter} removeFilter={removeFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip">
					<IconButton color="secondary" component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const GuaranteesFilterEditor: React.FunctionComponent = () => {
	const [filter, editFilter, save, , reset] = useGuaranteeFilter();
	return (
		<GuaranteeFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
			type={GuaranteeFilterEditorType.Extended}
			reset={reset}
		/>
	);
};
