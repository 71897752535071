import React, { useMemo } from "react";
import { PageData, PageIndex, PageDataStatus } from "../../../data/models";
import { ControledTable, ControledTablePropsType } from "./ControledTable";
import { Options } from "material-table";
import { AccessRights } from "../../../core/navigation/types";

export interface PaginationDataProps<TData extends object> {
	pageData?: PageData<TData>;
	pageIndex: PageIndex;
	userAccessRights?: AccessRights;
	onPageIndexChange: (newState: PageIndex) => void;
}

type PagginationTableProps<TData extends object> = PaginationDataProps<TData> &
	Omit<ControledTablePropsType<TData>, "immutableData" | "data" | "isLoading">;

export function PaginationTable<TData extends object>(props: PagginationTableProps<TData>) {
	const { pageData, options, ...tableProps } = props;

	const tableOptions = useMemo(
		(): Options | undefined =>
			pageData?.status === PageDataStatus.DataInvalidated
				? {
						...options,
						rowStyle: {
							...options?.rowStyle,
							color: "grey",
						},
				  }
				: options,
		[options, pageData]
	);

	return (
		<ControledTable<TData>
			{...tableProps}
			immutableData={pageData?.currentPageData}
			total={pageData?.totalCount}
			options={tableOptions}
		/>
	);
}
