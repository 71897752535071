import { webApi } from "../../../../core/http/httpThunks";
import { Client } from "../types";

export const getClientApi = (userId: string, cliid: string) =>
	webApi<Client>({
		method: "GET",
		url: "listing/getclient",
		params: {
			userId,
			cliid
		},
	});

export default getClientApi;
