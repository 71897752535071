import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { loadReport } from "../thunks/loadReportData";
import { TurnoverByBrokersReportData } from "../types";

export const useTradeByBrokersReportData = () => {
  const data = useSelector<
    StoreType,
    TurnoverByBrokersReportData[] | undefined
  >((s) => s.data.reports.exchbusiness.tradeByBrokers.data);

  const dispatch = useDispatch();
  const dispatchLoadData = React.useCallback(() => dispatch(loadReport()), [
    dispatch,
  ]);

  return [data, dispatchLoadData] as [typeof data, typeof dispatchLoadData];
};
