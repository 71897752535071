import React from "react";
import { useCommReviewColumns } from "./useCommReviewReportColumns";
import { AccessRights } from "../../../../core/navigation/types";
import { CommReviewReportData } from "../../../../data/reports/exchbusiness/commReview/types";
import { ControledTable } from "../../../components/common/ControledTable";
import { useCommReviewReportData } from "../../../../data/reports/exchbusiness/commReview/hooks/useCommReviewReportData";
import { useCommReviewReportIndex } from "../../../../data/reports/exchbusiness/commReview/hooks/useCommReviewReportIndex";

export interface CommReviewTableProps {
  userAccessRights: AccessRights;
}

type CommReviewTablePropsType = CommReviewTableProps;

export const CommReviewReportTable: React.FunctionComponent<CommReviewTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useCommReviewColumns();
  const [data, load] = useCommReviewReportData();
  const [index, setIndex] = useCommReviewReportIndex();

  return (
    <ControledTable<CommReviewReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default CommReviewReportTable;
