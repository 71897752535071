import React from "react";
import { setDisplayCurrencyId } from "../currencyRatesDataReducer";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";

export const useCurrencyRateFilter = () => {
	const currencyId = useSelector<StoreType, string | undefined>(
		store => store.data.listing.currencyRates.displayCurrencyId
	);
	const dispatch = useDispatch();
	const setDisplayCurrency = React.useCallback(
		(currencyId?: string) => {
			dispatch(setDisplayCurrencyId(currencyId));
		},
		[dispatch]
	);

	return [currencyId, setDisplayCurrency] as [typeof currencyId, typeof setDisplayCurrency];
};
