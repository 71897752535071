import { ThunkType } from "../../../../core/store/reducers";
import { downloadNews } from "../api/downloadNews";
import fileDownload from "js-file-download";
import { setDownloadNewsFetchState } from "../newsDataReducer";
import { NewsData } from "../types";

export const downloadNewsThunk = (news?: NewsData): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (
    store.data.auth.current.status !== "successful" ||
    !news ||
    !news.newsId
  ) {
    return undefined;
  }

  //	const filename = `TradingOrder${order.side.trim().toUpperCase()}_${lan.trim().toUpperCase()}_${order.ordNo}.pdf`

  const lan = store.ux.localization.language;
  const filename = `News_${lan.trim().toUpperCase()}_${
    news.newsId
  }.pdf`;
  const user = store.data.auth.current.user;
  dispatch(setDownloadNewsFetchState({ type: "started" }));
  const newsReport = await dispatch(downloadNews(user, news.newsId));
  if (newsReport) {
    fileDownload(
      new Blob([newsReport as any], { type: "application/pdf" }),
      filename
    );
  }
  dispatch(setDownloadNewsFetchState({ type: "not-started" }));
};
