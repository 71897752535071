import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { Guarantees } from "../../models";

const renderLabel = (item: Guarantees) => `${item.ftDescr} `;

export const useGuaranteesTypes = () => {
  const guarantees = useSelector<StoreType, Guarantees[]>(
    (store) => store.data.initialData.guarantees
  );

  return [guarantees, renderLabel] as [typeof guarantees, typeof renderLabel];
};
