import { ThunkType } from "../../../../core/store/reducers";
import createInvoiceApi from "../api/createInvoice";
import updateInvoiceApi from "../api/updateInvoice";
import { InvoiceData } from "../types";
import { setFetchState, invalidateInvoices } from "../invoicesDataSlice";

export const upsertInvoice = (invoice: InvoiceData): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = invoice.invId
    ? await dispatch(updateInvoiceApi(userId, invoice))
    : await dispatch(createInvoiceApi(userId, invoice));

  if (result && result.resCode === "OK") {
    dispatch(setFetchState({ type: "successful", result: result.resDesc }));
    dispatch(invalidateInvoices());
    return result.resDesc;
  } else {
    dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
  }
};
