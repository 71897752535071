import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadReport } from "../thunks/loadReportData";
import { DailyTradingReportData } from "../types";

export const useTradesReportData = () => {
  const data = useSelector<StoreType, DailyTradingReportData[] | undefined>(
    (s) => s.data.reports.dailyTrade.data
  );

  const dispatch = useDispatch();
  const dispatchLoadData = React.useCallback(
    () => dispatch(loadReport()),
    [dispatch]
  );

  return [data, dispatchLoadData] as [
    typeof data,
    typeof dispatchLoadData,
  ];
};
