import { ThunkType } from "../../../../core/store/reducers";
import { printDispute } from "../api/printDispute";
import fileDownload from "js-file-download";
import { setDownloadFetchState } from "../disputeDataSlice";
import { DisputeData } from "../types";

export const printDisputeThunk = (dispute?: DisputeData): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful" || !dispute || !dispute.dispSitId) {
		return undefined;
	}
	const lan = store.ux.localization.language
	const filename = `DisputeConf_${dispute.dispSitId}.pdf`
	const user = store.data.auth.current.user;
	dispatch(setDownloadFetchState({ type: "started" }));
	const disputeReport = await dispatch(printDispute(user, dispute.dispSitId));
	if (disputeReport) {
		fileDownload(new Blob([disputeReport as any], { type: "application/pdf" }), filename);
	}
	dispatch(setDownloadFetchState({ type: "not-started" }));
};
