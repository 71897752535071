import { useCopiedState } from "../../../../core/util/hooks";
import { setFilter, removeFilter, initialState } from "../invoicesUXSlice";
import { invalidateInvoices } from "../invoicesDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { InvoiceFilter } from "../types";

export const useInvoicesFilter = () => {
  const [storeFilter] = useCurrentInvoicesFilter();
  const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<
    InvoiceFilter
  >(storeFilter);

  const dispatch = useDispatch();
  const dispatchSetFilter = React.useCallback(() => {
    dispatch(setFilter(filter!));
    dispatch(invalidateInvoices());
  }, [dispatch, filter]);

  const dispatchResetFilter = React.useCallback(() => {
    setCurrentFilter(initialState.filter);
  }, [setCurrentFilter]);

  return [
    filter,
    editFilter,
    dispatchSetFilter,
    changeState,
    dispatchResetFilter,
  ] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter,
    typeof changeState,
    typeof dispatchResetFilter
  ];
};

export const useCurrentInvoicesFilter = () => {
  const filter = useSelector<StoreType, InvoiceFilter>(
    (store) => store.ux.accounting.invoices.filter
  );
  const dispatch = useDispatch();
  const dispatchRemoveFilter = React.useCallback(
    (type: keyof InvoiceFilter) => {
      dispatch(removeFilter(type));
      dispatch(invalidateInvoices());
    },
    [dispatch]
  );

  return [filter, dispatchRemoveFilter] as [
    typeof filter,
    typeof dispatchRemoveFilter
  ];
};
