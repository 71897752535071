import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";
import { AnnualSecurityReportFilter } from "../types";

export const getAnnualSecurityReport = (user: User, filter: AnnualSecurityReportFilter) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getsecannualtradingreport",
		params: {
			userid: user.userId,
			year: filter.year,
		},
	});
};

export default getAnnualSecurityReport;
