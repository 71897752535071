import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { City } from "../types";
import { loadCity } from "../thunks/loadCity";

export const useDisplayCity = (cityId?: string) => {
  const storeCity = useSelector<StoreType, City | undefined>(
    (store) => store.data.listing.cities.displayedCity
  );

  const displayedCity =
    storeCity && storeCity.cityId === cityId ? storeCity : undefined;

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (cityId) {
      dispatch(loadCity(cityId));
    }
  }, [dispatch, cityId]);

  return displayedCity;
};
