import React from "react";
import { Drawer, useTheme, useMediaQuery } from "@material-ui/core";
import { NavList } from "./NavList";
import { useNavDrawerStyles } from "./NavDrawerStyles";

interface NavDrawerProps {
  open: boolean;
  toggleOpen: () => void;
  setToogle: (flag: boolean) => void;
}

export const NavDrawer: React.FunctionComponent<NavDrawerProps> = (props) => {
  const { open, toggleOpen, setToogle } = props;
  const styles = useNavDrawerStyles();
  const theme = useTheme();
  const permanentDrawer = useMediaQuery(theme.navDrawer.permanentBreakpoint);

  window.addEventListener("popstate", function (event) {
    setToogle(false);
    return true;
  });

  return (
    <nav className={styles.drawer}>
      <Drawer
        id="drawer"
        variant={permanentDrawer ? "permanent" : "temporary"}
        open={permanentDrawer || open}
        onClose={toggleOpen}
        classes={{
          paper: styles.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <NavList />
      </Drawer>
    </nav>
  );
};
