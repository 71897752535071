import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import RemoveIcon from '@material-ui/icons/Remove';

export interface DisplayBoolNumberProps {
	value?: number;
    thrutyValue: number;
    className?: string;
}

export const DisplayBoolNumber: React.FunctionComponent<DisplayBoolNumberProps> = ({ value, thrutyValue, className }) =>
	value === thrutyValue ? <CheckIcon className={className} /> : <RemoveIcon className={className} />;
