import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteRole } from "../thunks/deleteRole";
import { setFetchState } from "../rolesDataReducer";

export const useRoleDelete = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.roles.fetchState
	);

	const dispatch = useDispatch();
	const dispatchDeleteRole = React.useCallback(
		(id: string) => dispatch(deleteRole(id)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchState({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchDeleteRole] as [typeof fetchState, typeof dispatchDeleteRole]
};
