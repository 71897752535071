import React from "react";
import { PaymentListData } from "../../../data/clearing/payments/types";
import {
  PaginationTable,
  PaginationDataProps,
} from "../../components/common/PagginationTable";
import {
  usePaymentsActions,
  useDeletePaymentAction,
} from "./usePaymentsActions";
import { usePaymentsColumns } from "./usePaymentsColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface PaymentsTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}

type PaymentsTablePropsType = PaymentsTableProps &
  PaginationDataProps<PaymentListData>;

const PaymentsTable: React.FunctionComponent<PaymentsTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = usePaymentsActions();
  const columns = usePaymentsColumns();
  const onDeletePayment = useDeletePaymentAction();

  return (
    <PaginationTable<PaymentListData>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
      editable={{
        onRowDelete: onDeletePayment,
      }}
    />
  );
};

export default PaymentsTable;
