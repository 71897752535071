export const weeklyTradesReportLocation = "/reports/weeklyreports";

export const getReportLocation = (
  basePath: string,
  year: number,
  week: number
) => {
  return `${basePath}/${year}/${week}`;
};

export const weeklyProductLocationBase = "/reports/weeklyreports/products";
export const weeklyClientLocationBase = "/reports/weeklyreports/client";
export const weeklyDateLocationBase = "/reports/weeklyreports/date";

export const weeklyProductLocation = `${weeklyProductLocationBase}/:year/:week`;
export const weeklyClientLocation = `${weeklyClientLocationBase}/:year/:week`;
export const weeklyDateLocation = `${weeklyDateLocationBase}/:year/:week`;

export const getProductLocation = (year: number, week: number) =>
  getReportLocation(weeklyProductLocationBase, year, week);
export const getClientLocation = (year: number, week: number) =>
  getReportLocation(weeklyClientLocationBase, year, week);
export const getDateLocation = (year: number, week: number) =>
  getReportLocation(weeklyDateLocationBase, year, week);
