import React from "react";
import { useMTReportDateColumns } from "./useMTReportDateColumns";
import { AccessRights } from "../../../core/navigation/types";
import { TradeList } from "../../../data/trading/trades/types";
import { Table, TableDataProps } from "../../components/common/Table";
import { MonthlyTradingReportData } from "../../../data/reports/monthlyTrade/types";
import { useReportDateData } from "../../../data/reports/monthlyTrade/hooks/useReportDateData";
import { useMonthlyActions } from "./useMonthlyActions";

export interface MonthlyTradesTableProps {
  userAccessRights: AccessRights;
}

type MonthlyTradesTablePropsType = MonthlyTradesTableProps &
  TableDataProps<TradeList>;

const MTRDateTable: React.FunctionComponent<MonthlyTradesTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useMTReportDateColumns();
  const [data, load, reset] = useReportDateData();
  React.useEffect(() => reset, [reset]);

  return (
    <Table<MonthlyTradingReportData>
      {...tableProps}
      getData={load}
      immutableData={data}
      title=""
      columns={columns}
      //      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default MTRDateTable;
