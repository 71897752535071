import { NavigationItem } from "../../../core/navigation/types";
import NewsPage from "./NewsPage";
import { EditNewsPage } from "./EditNewsPage";
import { newsLocation, editRouter  } from "./Locations";

export const newsComponents: NavigationItem[] = [
  {
    Component: NewsPage,
    isMenuItem: true,
    path: newsLocation,
  },
  {
    Component: EditNewsPage,
    isMenuItem: false,
    path: editRouter,
  },
];
