import { webApi } from "../../../../../core/http/httpThunks";
import { ClientBroker } from "../../types";

export const getClientBrokerApi = (userId: string, clibrokid: string) =>
	webApi<ClientBroker>({
		method: "GET",
		url: "listing/getclibrok",
		params: {
			userId,
			clibrokid
		},
	});

export default getClientBrokerApi;
