import { CalculationParams, ShouldCalc } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";
import { ValidationState } from "../../../../core/util/types";

export const validationState: ValidationState<CalculationParams> = {
	distance: true,
	tradeid: true,
};

export const useCalculationParamsValidator = createUseValidator<CalculationParams>(
	validationState,
	(item, validation) => ({
		...validation,
		distance:
			item.shouldCalcDistance === ShouldCalc.False ||
			(item.distance !== undefined && item.distance > 0),
	})
);
