import React from "react";
import { useDisplayGuarantee } from "./useDisplayGuarantees";
import { GuaranteeData, PayGroup } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defaultGuarantee: GuaranteeData = {
  payId: "",
  payType: 1,
  hasOrdTran: 0,
  clientId: "",
  status: Status.Active,
  currId: "00000001",
};

export const useEditGuarantee = (payId?: string): GuaranteeData | undefined => {
  const displayGuarantee = useDisplayGuarantee(payId);

  return React.useMemo(() => {
    if (payId !== undefined && displayGuarantee === undefined) {
      return undefined;
    }

    if (payId === undefined) {
      return { ...defaultGuarantee };
    }

    return displayGuarantee ? { ...displayGuarantee } : undefined;
  }, [payId, displayGuarantee]);
};

export const useEditGuaranteeState = (payId?: string) => {
  const guarantee = useEditGuarantee(payId);
  return useCopiedState(guarantee);
};
