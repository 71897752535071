import { webApi } from "../../../../core/http/httpThunks";
import { Country } from "../types";

export const getAllCountyApi = (userId: string) =>
  webApi<Country[]>({
    method: "GET",
    url: "listing/getcntlist",
    params: {
      userId,
    },
  });

export default getAllCountyApi;
