import React from "react";
import {
  Grid,
  CardContent,
  CardHeader,
  Card,
  Divider,
} from "@material-ui/core";
import MTRClientTable from "./MTRClientTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import {
  DownloadButtonClient,
  DownloadExcelButtonClient,
} from "./DownloadButton";
import { monthlyTradesReportLocation } from "./Locations";
import { RouteComponentProps } from "react-router-dom";
import { useCurrentTradesReportFilter } from "../../../data/reports/monthlyTrade/hooks/useTradesReportFilter";

export interface MTRClientPageInfo {
  year: string;
  month: string;
}

export interface MTRClientPageProps
  extends RouteComponentProps<MTRClientPageInfo> {
  userAccessRights: AccessRights;
}

const MTRClientPage: React.FunctionComponent<MTRClientPageProps> = (props) => {
  const pageStyles = usePageStyles();

  // ovde su parametri za izvestaj
  // zbog toga stho se uzimaju iz URL-a moraju biti tipa string, ali ih konvertujem u number zbog toga sto to trazi API
  // dalje ih treba proslediti u odgovarajuci hook da bi se sacuvali u filteru u redux store-u
  const yearNumber = Number(props.match.params.year);
  const monthNumber = Number(props.match.params.month);
  console.log("Report params:", yearNumber, monthNumber);

  const [
    filter,
    setReportDate,
    setReportMonth,
    setReporthYear,
  ] = useCurrentTradesReportFilter();

  React.useEffect(() => setReportMonth(monthNumber), [monthNumber]);
  React.useEffect(() => setReporthYear(yearNumber), [yearNumber]);

  return (
    <>
      <Grid container className={pageStyles.root}>
        <Grid item xs={12} className={pageStyles.gridItem}>
          <Card>
            <CardHeader
              title={
                <PageTitle
                  title="mTReportClientTitle"
                  backLocation={monthlyTradesReportLocation}
                />
              }
              action={<MonthActions />}
            />
            <Divider />
            <CardContent>
              <MTRClientTable userAccessRights={props.userAccessRights} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export const MonthActions: React.FunctionComponent = () => (
  <Grid container>
    <Grid item>
      <DownloadButtonClient />
    </Grid>
    <Grid item>
      <DownloadExcelButtonClient />
    </Grid>
  </Grid>
);

export default MTRClientPage;
