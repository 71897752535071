import { webApiWithResponse } from "../../../../../core/http/httpThunks";
import { CommissionDetail } from "../../types";

export const createCommissionDetailApi = (userId: string, detail: CommissionDetail) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/newcommdet",
		params: {
			userId
		},
		data: detail
	});

export default createCommissionDetailApi;
