import { ThunkType } from "../../../../../core/store/reducers";
import { invalidateData } from "../clientOverviewDataSlice";
import { setClientOverviewIndex } from "../clientOverviewUXSlice";
import { PageIndex } from "../../../../models";
import { TableType } from "../types";

export const setCOIndex = (type: TableType, index: PageIndex): ThunkType => dispatch => {
	dispatch(setClientOverviewIndex({ type, index }));
	dispatch(invalidateData());
};
