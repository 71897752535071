import React, { useState, useContext, useEffect } from "react";
import { useLocalizationStore } from "../../data/localization/hooks";
import { useAuth, useLastLoginUsername } from "../../data/auth/hooks";
import { LocalizationContext } from "../../core/localization/Localization";
import { LocalizationLanguage } from "../../core/localization/types";
import { TextField, Grid, Button } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { useLoginStyles } from "./useLoginStyles";
import { LoginError } from "./LoginError";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const handleTBChange =
  (setter: (value: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setter(event.target.value);

const onEnter =
  (action: () => void) => (e: React.KeyboardEvent<HTMLDivElement>) =>
    e.key === "Enter" && action();


export const LoginForm: React.FunctionComponent = (props) => {
  const styles = useLoginStyles();
  const { getString } = useContext(LocalizationContext);
  // const lastUsername = useLastLoginUsername();
  const [local, setLocal] = useLocalizationStore();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [authState, sendLogin] = useAuth();
  const [checked, setChecked] = useState(false);

  const handleChangeChk = (event: any) => {
    setChecked(event.target.checked);
  };

  const remembered = localStorage.getItem("username") // da li ima zapamćen username
  const upis = remembered !== null && remembered !== undefined && remembered.length > 0 ? 1 : 0;

  const theme = useTheme();
  const source = useMediaQuery(theme.breakpoints.only("xs")) ? "M" : "";
  const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

  const loading = authState.status === "pending";
  const error = authState.status === "failed";

  const login = () => {
    if (upis === 0 && checked === true) {
      localStorage.setItem("username", username);
      localStorage.setItem("password", password);
    }  // ne postoji upisan username u lokalnoj memoriji i hoćemo za da zapamtimo

    if (upis === 1 && checked === true) {
      delete localStorage.username;
      delete localStorage.password;
    }  // postoji upisan username u lokalnoj memoriji i hoćemo da obrišemo

    sendLogin(username, password, local, source);
  };

  //	const donations = require(`url(/donation.jpg)`)


  useEffect(() => {
    const cookieUsername = localStorage.getItem("username");
    const cookiePassword = localStorage.getItem("password");

    if (
      cookieUsername !== null &&
      cookieUsername !== undefined &&
      cookieUsername.length > 0
    ) {
      setUsername(cookieUsername);
      console.log("USLO1");
    }

    if (
      cookiePassword !== null &&
      cookiePassword !== undefined &&
      cookiePassword.length > 0
    ) {
      setPassword(cookiePassword);
      console.log("USLO2");
    }
    console.log(cookieUsername)
    console.log(cookiePassword)

  }, []);

  return (
    <Grid
      item
      container
      className={styles.formContainer}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item className={styles.localeSelection}>
        <ToggleButtonGroup
          value={local}
          exclusive
          onChange={(e, v) => setLocal(v)}
          size="small"
        >
          <ToggleButton value={LocalizationLanguage.EN}>EN</ToggleButton>
          <ToggleButton value={LocalizationLanguage.SR}>SR</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item className={styles.formItem}>
        <TextField
          className={styles.textField}
          label={getString("username")}
          value={username}
          onChange={handleTBChange(setUsername)}
          error={error}
          onKeyDown={onEnter(login)}
          disabled={loading}
        />
      </Grid>
      <Grid item className={styles.formItem}>
        <TextField
          className={styles.textField}
          label={getString("password")}
          type="password"
          value={password}
          onChange={handleTBChange(setPassword)}
          error={error}
          onKeyDown={onEnter(login)}
          disabled={loading}
        />
      </Grid>
      {/*screenExtraSmall && (<FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChangeChk} />}
        label={upis === 1 ? getString("loginForget") : getString("loginRemember")}
      />)*/}

      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChangeChk} />}
        label={upis === 1 ? getString("loginForget") : getString("loginRemember")}
      />

      <Grid item className={styles.formItem}>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={login}
        >
          {getString("login")}
        </Button>
      </Grid>
      <LoginError className={styles.alert} auth={authState} />
    </Grid>
  );
};
