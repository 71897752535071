import React from "react";
import { useDetail } from "./useDetail";
import { CommissionDetail, CommissionAmountType } from "../../types";
import { useCopiedState } from "../../../../../core/util/hooks";

export const createDefaultAttribute = (commissionId: string): CommissionDetail => ({
	commId: commissionId,
	jobType: 0,
	clientType: 0,
	clientId: undefined,
	percAmtBuy: CommissionAmountType.Percent,
	percAmtSell: CommissionAmountType.Percent,
});

export const useEditDetailS = (commissionId: string, detailId?: string) => {
	const [displayDetail] = useDetail(detailId);

	return React.useMemo(() => {
		if (detailId !== undefined && displayDetail === undefined) {
			return undefined;
		}

		if (detailId === undefined) {
			const defaultDetail = createDefaultAttribute(commissionId);
			return { ...defaultDetail };
		}

		return displayDetail ? { ...displayDetail } : undefined;
	}, [commissionId, detailId, displayDetail]);
};

export const useEditDetailState = (productId: string, attributeId?: string) => {
	const detail = useEditDetailS(productId, attributeId);
	return useCopiedState(detail);
};
