import { webApi } from "../../../../core/http/httpThunks";

export const deleteCountryApi = (userId: string, cntid: string) =>
	webApi<string>({
		method: "DELETE",
		url: "listing/delcnt",
		params: {
			userId,
			cntid
		},
	});

export default deleteCountryApi;
