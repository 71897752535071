import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { loadOrderBook } from "../thunks/loadAllOrderBook";
import { PageData } from "../../../../models";
import { ViewOrder } from "../../../../trading/orders/types";

export const useOrderBookData = () => {
	const pageData = useSelector<StoreType, PageData<ViewOrder> | undefined>(
		s => s.data.reports.exchbusiness.clientOverview.currentPageOrder
	);

	const dispatch = useDispatch();
	const dispatchLoadOrderBookPage = React.useCallback(() => dispatch(loadOrderBook()), [dispatch]);

	return [pageData, dispatchLoadOrderBookPage] as [
		typeof pageData,
		typeof dispatchLoadOrderBookPage
	];
};
