import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import { NewsDataFilter } from "./types";

type NewsPageState = {
  index: PageIndex;
  filter: NewsDataFilter;
};

const now = new Date();
export const initialState: NewsPageState = {
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
  filter: {},
};

const name = "news";

const newsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    resetNewsIdices: (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }),
    setFilter: (state, action: PayloadAction<NewsDataFilter>) => ({
      ...state,
      filter: action.payload,
    }),
    removeFilter: (
      state,
      action: PayloadAction<keyof NewsDataFilter>
    ) => ({
      ...state,
      filter: {
        ...state.filter,
        [action.payload]: undefined,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(successfulLogin, (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }));
  },
});

export const {
  setIndex,
  resetNewsIdices,
  setFilter,
  removeFilter,
} = newsSlice.actions;

export default newsSlice.reducer;
