import React from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { LocalizationContext } from "../../../core/localization/Localization";
import { AnnualTradingReportFilter } from "../../../data/reports/annualTrade/types";
import { useAuth } from "../../../data/auth/hooks";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { NumberInput } from "../../components/common/NumberInput";

export enum ATRFilterEditorType {
  Basic,
  Extended,
}

interface ATRFilterEditorProps {
  filter: AnnualTradingReportFilter;
  editFilter: (prop: keyof AnnualTradingReportFilter, value: any) => void;
  save: (numofweeks: number) => void;
  reset: () => void;
  type: ATRFilterEditorType;
}

export const ATRFilterEditor: React.FunctionComponent<ATRFilterEditorProps> = ({
  filter,
  editFilter,
  save,
  type,
  reset,
}) => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const { getString } = React.useContext(LocalizationContext);
  const [auth] = useAuth();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      editFilter(event.target.name as any, event.target.value);
    },
    [editFilter]
  );

  return (
    <Paper
      style={{
        paddingLeft: "70px",
        paddingRight: "70px",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Grid container direction="column" spacing={6}>
        <Grid item container direction="row" spacing={2}>
          <NumberInput<AnnualTradingReportFilter>
            property="numofyears"
            item={filter}
            onChange={handleChange}
            label={getString("annualNumofyears")}
          />
        </Grid>

        <Grid item container direction="row-reverse" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                if (filter.numofyears) {
                  save(filter.numofyears);
                }
                showWithDrawer(undefined);
              }}
            >
              {getString("orderFilterConfirm")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
