import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent, Tabs, Tab } from "@material-ui/core";
import { EditProductBasicInfo } from "./EditProductBasicInfoPage";
import { AttributeTable } from "./attributes/AttributeTable";
import { useEditProductState } from "../../../data/listing/products/hooks/useEditPRoductState";
import { PageTitle } from "../../components/common/PageTitle";
import { productsLocation, ProductEditParts, getProductEditLocation } from "./Locations";

export interface EditProductInfo {
	editPart?: string;
	productId?: string;
}

export interface EditProductProps extends RouteComponentProps<EditProductInfo> {}

export const EditProductPage: React.FunctionComponent<EditProductProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();
	const { editPart, productId } = props.match.params;

	const history = useHistory();
	const [product, editProduct, changed] = useEditProductState(productId);

	const handleSideChange = React.useCallback(
		(event: React.ChangeEvent<{}>, newValue: any) => {
			history.push(getProductEditLocation(newValue, productId));
		},
		[history, productId]
	);

	const pageTitle = productId ? "productEditTitle" : "productCreateTitle";
	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={productsLocation} />} />
					<Divider />
					<CardContent>
						<Tabs
							value={editPart}
							onChange={handleSideChange}
							indicatorColor="secondary"
							textColor="secondary"
							variant="fullWidth"
						>
							<Tab label={getString("tableBasciInfo")} value={ProductEditParts.BasicInfo} />
							<Tab
								label={getString("productAttributeTitle")}
								value={ProductEditParts.Attributes}
								disabled={!product || !product.prodId}
							/>
						</Tabs>
						<div style={{ marginTop: "20px" }}>
							{editPart === ProductEditParts.BasicInfo && product && (
								<EditProductBasicInfo
									product={product}
									editProduct={editProduct}
									changed={changed}
								/>
							)}
							{editPart === ProductEditParts.Attributes && product && product.prodId && (
								<AttributeTable productId={product.prodId} />
							)}
						</div>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
