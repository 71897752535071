import { TradingData } from "../../../data/clearing/tradingData/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";

export const useTradingDataColumns = () => {
  const { getString, getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);

  return React.useMemo(
    () => [
      {
        title: getString("tradingDataDataTime"),
        render: (tradingData) => getDateString(tradingData.tradingDate, "DD.MM.YYYY"),
        sorting: false,
      },
      {
        title: getString("tradingDataProductName"),
        field: "productName",
        sorting: false,
      },
      {
        title: getString("tradingDataRefPrice"),
        render: (tradingData) => getFormatedNumberString(tradingData.refPrice, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("tradingDataHighPrice"),
        render: (tradingData) => getFormatedNumberString(tradingData.highPrice, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("tradingDataLowPrice"),
        render: (tradingData) => getFormatedNumberString(tradingData.lowPrice, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("tradingDataVolume"),
        render: (tradingData) => getFormatedNumberString(tradingData.volume, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("tradingDataAmount"),
        render: (tradingData) => getFormatedNumberString(tradingData.amount, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("tradingDataWeightedPrice"),
        render: (tradingData) => getFormatedNumberString(tradingData.weightedPrice, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("tradingDataMidPrice"),
        render: (tradingData) => getFormatedNumberString(tradingData.midPrice, true),
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
  ],
    [getString, getFormatedNumberString, getDateString]
  ) as Column<TradingData>[];
};
