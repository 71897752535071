import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { BankGuaranteeData } from "../types";

export const updateGuaranteeApi = (userId: string, bankguarantee: BankGuaranteeData) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "clearing/updbankgrnt",
    params: {
      userId,
    },
    data: bankguarantee,
  });

export default updateGuaranteeApi;
