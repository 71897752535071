import React from "react";
import { useDisplayClient } from "./useDisplayClient";
import { Client, ClientActive, ClientHeadquaters } from "../types";
import { Status } from "../../../../data/models";
import { useCopiedState } from "../../../../core/util/hooks";

export const defaultClient: Client = {
	active: ClientActive.HasAccess,
	address: "",
	cliIdNo: "",
	cliName: "",
	cliType: 10,
	comment: "",
	email: "",
	fax: "",
	foreignCli: ClientHeadquaters.Domestic,
	fullName: "",
	mailAddress: "",
	mobile: "",
	phone: "",
	rpgCode: "",
	status: Status.Active,
	taxCode: "",
	commission: 0,
};

export const useEditClient = (cilId?: string) => {
	const displayClient = useDisplayClient(cilId);

	return React.useMemo(() => {
		if (cilId !== undefined && displayClient === undefined) {
			return undefined;
        }

		if (cilId === undefined) {
			return { ...defaultClient };
        }

		return displayClient ? { ...displayClient } : undefined;
	}, [cilId, displayClient]);
};

export const useEditClientState = (empId?: string) => {
	const client = useEditClient(empId);
	return useCopiedState(client)
};
