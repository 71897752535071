import { Attribute, AttributeType, AttributeValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const attributeValidationState: AttributeValidationState = {
	attrName: true,
	attrNameen: true,
	attrType: true,
//	unit: true,
	comparator: true,
};

export const useAttributeValidator = createUseValidator<Attribute, AttributeValidationState>(attributeValidationState, (item, validation) => ({
	...validation,
//	unit: item.attrType === AttributeType.Text ? validation.unit : true,
	comparator: item.attrType === AttributeType.Text ? validation.comparator : true,
}));
