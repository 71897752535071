import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import srLocale from "date-fns/locale/sr-Latn";
import enLocale from "date-fns/locale/en-US";
import { KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardDatePickerProps } from "@material-ui/pickers";
import { LocalizationContext } from "../../../core/localization/Localization";
import { LocalizationLanguage } from "../../../core/localization/types";

export interface DateInputProps extends KeyboardDatePickerProps {
	onChange: (newValue: Date | null) => void;
	errorMessage?: string;
	clearable?: boolean;
}

export const DateInput: React.FunctionComponent<DateInputProps> = ({
	onChange,
	error,
	errorMessage,
	clearable,
	...otherProps
}) => {
	const { getString, language } = React.useContext(LocalizationContext);
	const locale = React.useMemo(() => (language() === LocalizationLanguage.SR ? srLocale : enLocale), [
		language,
	]);
	const helperText = React.useMemo(
		() => (error ? errorMessage || getString("requiredMessage") : undefined),
		[getString, error, errorMessage]
	);

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
			<KeyboardDatePicker
				fullWidth
				// variant="inline"
				clearable={clearable !== undefined ? clearable : true}
				format="dd.MM.yyyy"
				onChange={onChange}
				invalidDateMessage={getString("invalidDateMessage")}
				maxDateMessage={getString("maxDateMessage")}
				minDateMessage={getString("minDateMessage")}
				cancelLabel={getString("calendarCancel")}
				clearLabel={getString("calendarClear")}
				okLabel={getString("calendarOK")}
				error={error}
				helperText={helperText}
				{...otherProps}
			/>
		</MuiPickersUtilsProvider>
	);
};
