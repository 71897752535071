import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import { AlarmFilter } from "./types";

type AlarmPageState = {
  index: PageIndex;
  filter: AlarmFilter;
};

export const initialState: AlarmPageState = {
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
  filter: {},
};

const name = "alarms";

const alarmsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setAlarmIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    resetAlarmIdices: (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }),

    setFilter: (state, action: PayloadAction<AlarmFilter>) => ({
      ...state,
      filter: action.payload,
    }),

    removeFilter: (state, action: PayloadAction<keyof AlarmFilter>) => ({
      ...state,
      filter: {
        ...state.filter,
        [action.payload]: undefined,
      },
    }),

  },
  extraReducers: (builder) => {
    builder.addCase(successfulLogin, (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }));
  },
});

export const {
  setAlarmIndex,
  resetAlarmIdices,
  setFilter,
  removeFilter,
} = alarmsSlice.actions;

export default alarmsSlice.reducer;
