import { DisputeListData } from "../../../data/clearing/dispute/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { renderSituation, TurnocateText } from "./columRenderers";

export const useDisputeColumns = () => {
  const { getString, getDateString } = React.useContext(LocalizationContext);

  return React.useMemo(
    () => [
      {
        title: getString("disDispSitDate"),
        render: (dispute) => getDateString(dispute.dispSitDate, "DD.MM.YYYY"),
        sorting: false,
      },
      {
        title: getString("disTradeNo"),
        field: "tradeNo",
        sorting: false,
      },
      {
        title: getString("dissellerName"),
        field: "sellerName",
        sorting: false,
      },
      {
        title: getString("disbuyerName"),
        field: "buyerName",
        sorting: false,
      },
      {
        title: getString("disproductName"),
        field: "productName",
        sorting: false,
      },
      {
        title: getString("disDisputeDescr"),
        render: renderSituation,
        sorting: false,
      }, 
      {
        title: getString("productStatus"),
        field: "statusDescr",
        sorting: false,
      },
    ],
    [getString]
  ) as Column<DisputeListData>[];
};
