import { NewsData, NewsValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const newsValidationState: NewsValidationState = {
  title: true,
};

export const useNewsValidator = createUseValidator<
NewsData,
  NewsValidationState
>(newsValidationState);
