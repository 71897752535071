import React from "react";
import { useCapacitorAppState } from "../util/useCapacitorAppState";
import { useMobileApp } from "../util/useMoblieApp";
import { ConnextionManager } from "./ConnectionManager";

export const NotificationContext = React.createContext<ConnextionManager>({} as any);

export interface NotificationContextProviderProps {
	hubUrl: string;
	token: string;
	connect: boolean;
}

export const NotificationContextProvider: React.FunctionComponent<NotificationContextProviderProps> = ({
	hubUrl,
	token,
	children,
	connect,
}) => {
	const [connectionManager, setConnectionManager] = React.useState<ConnextionManager | undefined>();
	const { isVisiable } = useCapacitorAppState();
	const isMobileApp = useMobileApp();
	const shouldReconnect = isVisiable || !isMobileApp;
	React.useEffect(() => {
		const manager = shouldReconnect ? new ConnextionManager(hubUrl, token) : undefined;
		(async () => {
			if (connect && manager) {
				await manager.start();
			}
			setConnectionManager(manager);
		})();
		return () => {
			if (manager) {
				manager.stop();
			}
		};
	}, [hubUrl, token, connect, shouldReconnect]);

	return connectionManager ? (
		<NotificationContext.Provider value={connectionManager}>{children}</NotificationContext.Provider>
	) : null;
};
