import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";
import { upsertCommissionDetail } from "../../thunks/detailes/upsertCommissionDetail";
import { setFetchStateAttribute } from "../../commissionsDataReducer";
import { CommissionDetail } from "../../types";

export const useDetailUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.commissions.fetchStateDetail
	);

	const dispatch = useDispatch();
	const dispatchUpsertDetail = React.useCallback(
		(detail: CommissionDetail) => dispatch(upsertCommissionDetail(detail)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateAttribute({ type: "not-started" }));
	}, [dispatch]);

	return [fetchState, dispatchUpsertDetail] as [typeof fetchState, typeof dispatchUpsertDetail];
};
