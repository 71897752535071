import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../models";
import { DailyTradingReportData } from "./types";

type TradesReportData = {
	data?: DailyTradingReportData[];
	downloadReportFetchState: FetchStateType;
};

const initialState: TradesReportData = {
	downloadReportFetchState: { type: "not-started" },
};

const name = "dailyTrade";

const dailyTradeSlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<DailyTradingReportData[] | undefined>) => ({
			...state,
			data: action.payload,
		}),
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			downloadReportFetchState: action.payload,
		}),
	},
});

export const { setData, setFetchState } = dailyTradeSlice.actions;

export default dailyTradeSlice.reducer;
