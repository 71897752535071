import React from "react";
import { useCities } from "../../../data/initialData/hooks/useCities";
import { City } from "../../../data/models";
import { AutocompleteSelectType, AutocompleteSelect } from "./AutocompleteSelect";

type CitySelectPropsType = Omit<AutocompleteSelectType<City>, "data" | "getLabel">;

export const CitySelect: React.FunctionComponent<CitySelectPropsType> = props => {
	const [cities, renderCityLabel] = useCities();

	const citiesWithoutRegions: City[] = React.useMemo(
		() =>
			cities.map(c => ({
				...c,
				region: undefined,
			})),
		[cities]
	);

	return (
		<AutocompleteSelect<City>
			data={citiesWithoutRegions}
			getLabel={renderCityLabel}
			{...props}
		/>
	);
};
