import React from "react";
import { BankGuaranteeData } from "../../../data/clearing/bankguarantees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayBankGuaranteeMainAttributesProps {
  bankguarantee: BankGuaranteeData;
}

export const DisplayBankGuaranteeMainAttributes: React.FunctionComponent<DisplayBankGuaranteeMainAttributesProps> = ({
  bankguarantee,
}) => {
  const style = useDisplaySingleEmementStyles();
  const {
    getString,
    getDateString,
    getFormatedNumberString,
  } = React.useContext(LocalizationContext);


  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("grntNo")}</span>
        <span className={style.value}>{bankguarantee.grntNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("grntDate")}</span>
        <span className={style.value}>{getDateString(bankguarantee.grntDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("grntBankCode")}</span>
        <span className={style.value}>{bankguarantee.bankCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("grntBankName")}</span>
        <span className={style.value}>{bankguarantee.bankName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("grntClientCode")}</span>
        <span className={style.value}>{bankguarantee.clientIdNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("grntClientName")}</span>
        <span className={style.value}>{bankguarantee.clientName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("grntAmount")}</span>
        <span className={style.value}>
          {getFormatedNumberString(bankguarantee.amt, true)}
        </span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("grntCurrCode")}</span>
        <span className={style.value}>{bankguarantee.currCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("grntExpiryDate")}</span>
        <span className={style.value}>{getDateString(bankguarantee.expiryDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("grntStatus")}</span>
        <span className={style.value}>
          <DisplayBoolNumber
            value={bankguarantee.status}
            thrutyValue={Status.Active}
            className={style.icon}
          />
        </span>
      </div>
    </div>
  );
};
