import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllCommissions } from "../thunks/loadAllCommissions";
import { Commission } from "../types";

export const useCommissionData = () => {
	const data = useSelector<StoreType, Commission[] | undefined>(s => s.data.listing.commissions.displayData);
	const allData = useSelector<StoreType, Commission[] | undefined>(s => s.data.listing.commissions.all);

	const dispatch = useDispatch();
	const dispatchLoadAllProducts = React.useCallback(() => dispatch(loadAllCommissions()), [dispatch]);

	return [data, dispatchLoadAllProducts, allData] as [
		typeof data,
		typeof dispatchLoadAllProducts,
		typeof allData
	];
};
