import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useAttributeComparatorType } from "../../../data/listing/attributes/hooks/attributes/useAttributeComparator";
import { TextField, MenuItem } from "@material-ui/core";
import { AttrCompType } from "../../../data/listing/attributes/types";
import { Status } from "../../../data/models";

interface AttrCompTypeTypeSelectProps {
	comparator: AttrCompType;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	status: boolean;
}

export const AttrCompTypeSelect: React.FunctionComponent<AttrCompTypeTypeSelectProps> = ({
	comparator,
	handleChange,
	status,
}) => {
	const { getString } = React.useContext(LocalizationContext);
	const options = useAttributeComparatorType();
	
console.log(options)

	return (
		<TextField
			name="comparator"
			value={comparator}
			disabled={status}
			onChange={handleChange}
			fullWidth
			select
			label={getString("attrCompType")}
		>
			{options.map((option) => (
				<MenuItem key={option.value} value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</TextField>
	);
};
