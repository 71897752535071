import { AlarmData } from "../../../data/surveillance/alarmParameters/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";
import { useAlarmTypeTranslator } from "../../../data/surveillance/alarmParameters/hooks/useAlarmType";

export const useAlarmsColumns = () => {
  const { getString } = React.useContext(LocalizationContext);
  const translator = useAlarmTypeTranslator();

  return React.useMemo(
    () => [
      {
        title: getString("alarmAlmType"),
        sorting: false,
        render: alarm => translator(alarm.almType as any),
      },
      {
        title: getString("alarmClient"),
        field: "clientName",
        sorting: false,
      },
      {
        title: getString("alarmProductCode"),
        field: "productCode",
        sorting: false,
      },
      {
        title: getString("alarmProduct"),
        field: "productName",
        sorting: false,
      },
    ],
    [getString, translator]
  ) as Column<AlarmData>[];
};
