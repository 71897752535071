import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType, RegType } from "../../../models";
import { upsertRegion } from "../thunks/upsertRegion";
import { setFetchState } from "../regionsDataReducer";
import { Region } from "../types";

export const useRegionUpsert = (type?: RegType) => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.regions.fetchState[type || RegType.Region]
	);

	const dispatch = useDispatch();
	const dispatchUpsertRegion = React.useCallback((region: Region) => dispatch(upsertRegion(region)), [
		dispatch,
	]);

	React.useEffect(() => {
		if (type) {
			dispatch(setFetchState({ type, data: { type: "not-started" } }));
		}
	}, [dispatch, type]);

	return [fetchState, dispatchUpsertRegion] as [typeof fetchState, typeof dispatchUpsertRegion];
};
