import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertCountry } from "../thunks/upsertCountry";
import { setFetchState } from "../countriesDataReducer";
import { Country } from "../types";

export const useCountryUpsert = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.listing.countries.fetchState
  );

  const dispatch = useDispatch();
  const dispatchUpsertCountry = React.useCallback(
    (country: Country) => dispatch(upsertCountry(country)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchUpsertCountry] as [
    typeof fetchState,
    typeof dispatchUpsertCountry
  ];
};
