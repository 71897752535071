import React from "react";
import { Table, TableDataProps } from "../../../components/common/Table";
import { useAttributeValuesData } from "../../../../data/listing/attributes/hooks/attributeValues/useAttributeValuesData";
import { useAttributeValueDelete } from "../../../../data/listing/attributes/hooks/attributeValues/useAttributeValueDelete";
import { AttributeValue } from "../../../../data/listing/attributes/types";
import { Column, Action } from "material-table";
import { DisplayBoolNumber } from "../../../components/common/DisplayBoolNumber";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import EditIcon from "@material-ui/icons/EditOutlined";
import { AttributeEditParts, getAttributeEditLocation } from "../Locations";
import { useHistory } from "react-router-dom";
import { Fab } from "@material-ui/core";
import { usePageStyles } from "../../../pageStyles";
import AddIcon from "@material-ui/icons/Add";
import { Status } from "../../../../data/models";

export interface AttributeValuesTableProps {
	attributeId: string;
}

type AttributeTablePropsType = AttributeValuesTableProps & TableDataProps<AttributeValue>;

export const AttributeValuesTable: React.FunctionComponent<AttributeTablePropsType> = ({
	attributeId,
	...tableProps
}) => {
	const pageStyles = usePageStyles();
	const history = useHistory();

	const [data, reload] = useAttributeValuesData(attributeId);

	const columns = useAttributeColumns();
	const actions = useValueActions();
	const onDeleteAttribute = useDeleteValueAction();

	return (
		<>
			<Table<AttributeValue>
				{...tableProps}
				getData={() => {}}
				immutableData={data}
				title=""
				columns={columns}
				actions={actions}
				options={{
					toolbar: false,
					pageSize: 5,
					actionsColumnIndex: -1,
				}}
				editable={{
					onRowDelete: async broker => {
						await onDeleteAttribute(broker);
						reload();
					},
				}}
			/>
			<Fab
				color="secondary"
				aria-label="add"
				className={pageStyles.mainFab}
				onClick={() =>
					history.push(getAttributeEditLocation(AttributeEditParts.Values, attributeId, ""))
				}
			>
				<AddIcon />
			</Fab>
		</>
	);
};

export default AttributeValuesTable;

const useAttributeColumns = () => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);
	return React.useMemo(
		() => [
			{
				title: getString("attributeValueName"),
				field: "name",
			},
			{
				title: getString("attributeValueValue"),
				field: "value",
			},
			{
				title: getString("attributeValueStatus"),
				render: value => (
					<DisplayBoolNumber
						value={value.status}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				),
			},
		],
		[getString, style.icon]
	) as Column<AttributeValue>[];
};

const useDeleteValueAction = () => {
	const [, deleteValue] = useAttributeValueDelete();
	return React.useCallback(
		async (value: AttributeValue) => {
			if (value.attrValueId) {
				await deleteValue(value.attrValueId);
			}
		},
		[deleteValue]
	);
};

export const useValueActions = () => {
	const { getString } = React.useContext(LocalizationContext);
	const onEditAttribute = useEditValueAction();

	return React.useMemo(() => {
		const result: Action<AttributeValue>[] = [
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditAttribute(rowData as AttributeValue),
			},
		];

		return result;
	}, [getString, onEditAttribute]);
};

export const useEditValueAction = () => {
	const history = useHistory();
	return React.useCallback(
		(value: AttributeValue) =>
			history.push(
				getAttributeEditLocation(AttributeEditParts.Values, value.attributeId, value.attrValueId)
			),
		[history]
	);
};
