import React, { useContext, useMemo } from "react";
import { WeeklyTradingReportData } from "../../../data/reports/weeklyTrade/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
//import { useViewBank } from "./DisplayBank";
import {
  getProductLocation,
  getClientLocation,
  getDateLocation,
} from "./Locations";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import { useAuth } from "../../../data/auth/hooks";

export const useWeeklyActions = () => {
  const { getString } = useContext(LocalizationContext);

  const history = useHistory();

  const [auth] = useAuth();

  return useMemo(() => {
    const result: Action<WeeklyTradingReportData>[] = [
      {
        icon: () => <LocalShippingOutlinedIcon />,
        tooltip: getString("wTableProduct"),
        onClick: (event, rowData) => {
          const reportData = rowData as WeeklyTradingReportData;
          history.push(
            getProductLocation(reportData.year, reportData.yearPart)
          );
        },
      },
      {
        icon: () => <PeopleOutlineOutlinedIcon />,
        tooltip: getString("wTableClient"),
        onClick: (event, rowData) => {
          const reportData = rowData as WeeklyTradingReportData;
          history.push(getClientLocation(reportData.year, reportData.yearPart));
        },
        hidden:  auth.status === "successful" && (auth.user.roleCode === "MEMTRADER" || auth.user.roleCode === "MEMMANAGER"  ||  auth.user.roleCode === "MEMCONTACT" )  
      },
      {
        icon: () => <EventOutlinedIcon />,
        tooltip: getString("wTableDate"),
        onClick: (event, rowData) => {
          const reportData = rowData as WeeklyTradingReportData;
          history.push(getDateLocation(reportData.year, reportData.yearPart));
        },
      },
    ];

    return result;
  }, [, , getString]);
};
