import React from "react";
import { useEditDetailState } from "../../../../data/listing/commissions/hooks/detailes/useEditDetailState";
import { useDetailValidator } from "../../../../data/listing/commissions/hooks/detailes/useDetailValidator";
import { useDetailUpsert } from "../../../../data/listing/commissions/hooks/detailes/useDetailUpsert";
import { CommissionDetail, CommissionAmountType } from "../../../../data/listing/commissions/types";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../../pageStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent, InputAdornment } from "@material-ui/core";
import { FabSave } from "../../../components/common/FabSave";
import { getCommissionEditLocation, CommissionEditParts } from "../Locations";
import { LeavePrompt } from "../../../components/common/LeavePrompt";
import { PageTitle } from "../../../components/common/PageTitle";
import { DateInput } from "../../../components/common/DateInput";
import { ProductGroupSelect } from "../../../components/common/ProductGroupSelect";
import { ProductSelect } from "../../../components/listing/ProductSelect";
import { CurrencySelect } from "../../../components/common/CurrencySelect";
import { CommissionAmountTypeSelect } from "./CommissionAmountTypeSelect";
import { NumberInput } from "../../../components/common/NumberInput";

export interface EditCommissionDetailInfo {
	detaileId?: string;
	commissionId: string;
}

export interface EditCommissionDetailProps extends RouteComponentProps<EditCommissionDetailInfo> {}

export const EditCommissionDetail: React.FunctionComponent<EditCommissionDetailProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const { commissionId, detaileId } = props.match.params;
	const [detail, editDetail, changed] = useEditDetailState(commissionId, detaileId);
	const [validationState, validate] = useDetailValidator();
	const [fetchState, upsert] = useDetailUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editDetail(event.target.name as any, event.target.value);
		},
		[editDetail]
	);

	const backLocation = React.useMemo(
		() => getCommissionEditLocation(CommissionEditParts.Details, commissionId),
		[commissionId]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(backLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(detail);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [detail, validate, fetchState, history, upsert, backLocation]);

	const pageTitle = detail && detail.commDetId ? "commDetailsEditTitle" : "commDetailsCreateTitle";

	if (!detail) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={backLocation} />} />
					<Divider />
					<CardContent>
						<Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
							<Grid item xs={3}>
								<DateInput
									label={getString("commDetailsDate")}
									value={detail.fromDate || null}
									onChange={date => editDetail("fromDate", date)}
									required
									error={
										validationState.fromDate !== undefined && !validationState.fromDate
									}
								/>
							</Grid>
						</Grid>
						<Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
							<Grid item xs={3}>
								<ProductGroupSelect
									label={getString("commDetailsProductGroup")}
									predicate={group => group.prodGroupId === detail.prodGroupId}
									onChangeItem={group => {
										editDetail("prodGroupId", group?.prodGroupId);
										editDetail("prodId", undefined);
									}}
									required={validationState.prodGroupId !== undefined}
									error={
										validationState.prodGroupId !== undefined &&
										!validationState.prodGroupId
									}
								/>
							</Grid>
						</Grid>
						<Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
							<Grid item xs={3}>
								<ProductSelect
									label={getString("commDetailsProduct")}
									predicate={product => product.prodId === detail.prodId}
									onChangeItem={product => {
										editDetail("prodId", product?.prodId);
										editDetail("prodGroupId", undefined);
									}}
									required={validationState.prodId !== undefined}
									error={validationState.prodId !== undefined && !validationState.prodId}
								/>
							</Grid>
						</Grid>
						<Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
							{detail.percAmtBuy === CommissionAmountType.Percent && (
								<Grid item xs={3}>
									<NumberInput<CommissionDetail>
										property="percBuy"
										item={detail}
										validationState={validationState}
										onChange={handleChange}
										label={getString("commDetailsBuyPerc")}
										InputProps={{
											endAdornment: <InputAdornment position="end">%</InputAdornment>,
										}}
										money
									/>
								</Grid>
							)}
							{detail.percAmtBuy === CommissionAmountType.Amount && (
								<Grid item xs={3}>
									<NumberInput<CommissionDetail>
										property="maxAmtBuy"
										item={detail}
										validationState={validationState}
										onChange={handleChange}
										label={getString("commDetailsBuyMax")}
										money
									/>
								</Grid>
							)}
						</Grid>
						<Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
							{detail.percAmtSell === CommissionAmountType.Percent && (
								<Grid item xs={3}>
									<NumberInput<CommissionDetail>
										property="percSell"
										item={detail}
										validationState={validationState}
										onChange={handleChange}
										label={getString("commDetailsSellPerc")}
										InputProps={{
											endAdornment: <InputAdornment position="end">%</InputAdornment>,
										}}
										money
									/>
								</Grid>
							)}
							{detail.percAmtSell === CommissionAmountType.Amount && (
								<Grid item xs={3}>
									<NumberInput<CommissionDetail>
										property="minAmtSell"
										item={detail}
										validationState={validationState}
										onChange={handleChange}
										label={getString("commDetailsSellMin")}
										money
									/>
								</Grid>
							)}
							{detail.percAmtSell === CommissionAmountType.Amount && (
								<Grid item xs={3}>
									<NumberInput<CommissionDetail>
										property="maxAmtSell"
										item={detail}
										validationState={validationState}
										onChange={handleChange}
										label={getString("commDetailsSellMax")}
										money
									/>
								</Grid>
							)}
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
