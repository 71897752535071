import { ThunkType } from "../../../../core/store/reducers";
import { printPayment } from "../api/printPayment";
import fileDownload from "js-file-download";
import { setPrintPaymentFetchState } from "../paymentsDataSlice";
import { PaymentData } from "../types";

export const printPaymentThunk = (payment?: PaymentData): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful" || !payment || !payment.payId) {
		return undefined;
	}
	const lan = store.ux.localization.language
	const filename = `PaymentConfirmation_${payment.payId}.pdf`
	const user = store.data.auth.current.user;
	dispatch(setPrintPaymentFetchState({ type: "started" }));
	const paymentsReport = await dispatch(printPayment(user, payment.payId));
	if (paymentsReport) {
		fileDownload(new Blob([paymentsReport as any], { type: "application/pdf" }), filename);
	}
	dispatch(setPrintPaymentFetchState({ type: "not-started" }));
};
