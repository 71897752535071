import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PageData,
  PageDataStatus,
  FetchStateType,
  getFetchStateChange,
} from "../../models";
import { TradingData } from "./types";

type TradingsData = {
  all?: TradingData[];
  displayData?: TradingData[];
  currentPage?: PageData<TradingData>;
  displayedTradingData?: TradingData;
  fetchState: FetchStateType;
  cancelFetchState: FetchStateType;
  downloadReportFetchState: FetchStateType;
};

const initialState: TradingsData = {
  cancelFetchState: { type: "not-started" },
  fetchState: { type: "not-started" },
  downloadReportFetchState: { type: "not-started" },
};

const name = "tradingData";

const tradingDataSlice = createSlice({
  name,
  initialState,
  reducers: {
    setTradingsData: (state, action: PayloadAction<PageData<TradingData>>) => ({
      ...state,
      currentPage: action.payload,
    }),

    setDisplayed: (state, action: PayloadAction<TradingData | undefined>) => ({
      ...state,
      displayedTradingData: action.payload,
    }),

    invalidateTradingsData: (state) => {
      if (!state.currentPage) return state;

      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          status: PageDataStatus.DataInvalidated,
        },
      };
    },
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      displayedTradingData:
        action.payload.type === "successful"
          ? undefined
          : state.displayedTradingData,
      all: action.payload.type === "successful" ? undefined : state.all,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: getFetchStateChange(state.fetchState, action.payload),
    }),
    setCancelTradingDataFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      cancelFetchState: action.payload,
    }),
    setDownloadFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      downloadReportFetchState: action.payload,
    }),
    resetData: (state) => ({
      ...state,
      displayedTradingData: initialState.displayedTradingData,
      downloadReportFetchState: initialState.downloadReportFetchState,
      fetchState: initialState.fetchState
    }),

  },
});

export const {
  setTradingsData,
  setDisplayed,
  invalidateTradingsData,
  setFetchState,
  setCancelTradingDataFetchState,
  setDownloadFetchState,
  resetData,
} = tradingDataSlice.actions;

export default tradingDataSlice.reducer;
