import { webApi } from "../../../../core/http/httpThunks";
import { TransportAndStorageCosts } from "../types";

export const updateTASCosts = (userid: string, TASCost: TransportAndStorageCosts) =>
	webApi<string>({
		method: "PUT",
		url: "trade/updtransportandstoragecosts",
		params: {
			userid,
		},
		data: TASCost,
	});
