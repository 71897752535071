import React from "react";
import { useQuarterlyTradesColumns } from "./useQuarterlyTradingReportColumns";
import { AccessRights } from "../../../../core/navigation/types";
import { QuarterlySecurityReportData } from "../../../../data/reports/securityCommission/securityQuarterly/types";
import { ControledTable } from "../../../components/common/ControledTable";
import { useQuarterlySecurityReportData } from "../../../../data/reports/securityCommission/securityQuarterly/hooks/useSecurityReportData";
import { useSecurityReportIndex } from "../../../../data/reports/securityCommission/securityQuarterly/hooks/useSecurityReportIndex";

export interface OrderTableProps {
  userAccessRights: AccessRights;
}

type OrderTablePropsType = OrderTableProps;

const QuarterlyTradesReportTable: React.FunctionComponent<OrderTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useQuarterlyTradesColumns();
  const [data, load] = useQuarterlySecurityReportData();
  const [index, setIndex] = useSecurityReportIndex();

  return (
    <ControledTable<QuarterlySecurityReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default QuarterlyTradesReportTable;
