import { webApi } from "../../../../core/http/httpThunks";
import { Country } from "../types";

export const getCountryApi = (userId: string, cntid: string) =>
	webApi<Country>({
		method: "GET",
		url: "listing/getcnt",
		params: {
			userId,
			cntid
		},
	});

export default getCountryApi;
