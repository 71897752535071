import React from "react";
import { useDispatch } from "react-redux";
import { ClientContract } from "../../types";
import { getClientContract } from "../../thunks/clientContract/loadClientContract";

export const useDisplayContract = (clientContractId?: string) => {
	const displatch = useDispatch();
	const [contract, setContract] = React.useState<ClientContract | undefined>();

	React.useEffect(() => {
		const loadClient = async () => {
			if (clientContractId) {
				const loadedBroker = (await displatch(getClientContract(clientContractId))) as any;
				setContract(loadedBroker);
			}
		};
		loadClient();
	}, [displatch, clientContractId, setContract]);

	return contract;
};
