import { NavigationItem } from "../../../core/navigation/types";
import ProductPage from "./ProductPage";
import { EditAttribute } from "./attributes/EditAttribute";
import { EditProductPage } from "./EditProductPage";
import { productsLocation, atributesEdit, baseEdit } from "./Locations";
export const productsComponents: NavigationItem[] = [
	{
		Component: ProductPage,
		isMenuItem: true,
		path: productsLocation,
	},
	{
		Component: EditAttribute,
		isMenuItem: false,
		path: atributesEdit,
	},
	{
		Component: EditProductPage,
		isMenuItem: false,
		path: baseEdit,
	},
];
