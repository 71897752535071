import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { Employee } from "../../../data/listing/employees/types";
import React from "react";
import { Status } from "../../../data/models";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";

export function useEmployeeColumns() {
	const { getString } = useContext(LocalizationContext);
	return useMemo(
		() => [
			{
				title: getString("empFirstName"),
				field: "firstName",
			},
			{
				title: getString("empLastName"),
				field: "lastName",
			},
			{
				title: getString("empIdNumber"),
				field: "empIdNumber",
			},
			{
				title: getString("empOfficePhone"),
				field: "phoneOffice",
			},
			{
				title: getString("empEmail"),
				field: "email",
			},
			{
				title: getString("empStatus"),
				render: employee => <DisplayBoolNumber value={employee.status} thrutyValue={Status.Active} />,
				customSort: (a, b) => a.status - b.status,
			},
		],
		[getString]
	) as Column<Employee>[];
}
