import { ThunkType } from "../../../../core/store/reducers";
import getAllStockFeesAPI from "../api/getAllStockFees";
import { setStockFee } from "../stockFeesDataReducer";

export const loadAllStockFees = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setStockFee());

  const { userId } = store.data.auth.current.user;
  const stfee = await dispatch(getAllStockFeesAPI(userId));

  dispatch(setStockFee(stfee || []));
};
