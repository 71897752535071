import { webApi } from "../../../../core/http/httpThunks";
import { TradeList } from "../types";

export const getTradeApi = (userId: string, tradeid: string) =>
  webApi<TradeList>({
    method: "GET",
    url: "trade/gettrade",
    params: {
      userId,
      tradeid,
    },
  });

export default getTradeApi;
