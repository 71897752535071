import { ThunkType } from "../../../../../core/store/reducers";
import getAttributeValueApi from "../../api/attributeValues/getAttributeValue";
import { AttributeValue } from "../../types";

export const getAttributeValue = (
	attributeId: string
): ThunkType<Promise<AttributeValue | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getAttributeValueApi(userId, attributeId));
};
