import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllTransportFees } from "../thunks/loadAllTransportFees";
import { TransportFee } from "../types";

export const useTransportFeeData = () => {
  const data = useSelector<StoreType, TransportFee[] | undefined>(
    (s) => s.data.clearing.transportfees.displayData
  );
  const allData = useSelector<StoreType, TransportFee[] | undefined>(
    (s) => s.data.clearing.transportfees.all
  );

  const dispatch = useDispatch();
  const dispatchLoadAllTransportFees = React.useCallback(
    () => dispatch(loadAllTransportFees()),
    [dispatch]
  );

  return [data, dispatchLoadAllTransportFees, allData] as [
    typeof data,
    typeof dispatchLoadAllTransportFees,
    typeof allData
  ];
};

export const useTransportFee = (id?: number) => {
  const allData = useSelector<StoreType, TransportFee[] | undefined>(
    (s) => s.data.clearing.transportfees.all
  );

  const dispatch = useDispatch();
  const dispatchLoadAllTransportFees = React.useCallback(
    () => dispatch(loadAllTransportFees()),
    [dispatch]
  );

  React.useEffect(() => {
    if (!allData && id) {
      dispatchLoadAllTransportFees();
    }
  }, [allData, id, dispatchLoadAllTransportFees]);

  const stfee = React.useMemo(() => {
    if (!allData) {
      return undefined;
    } else {
      return allData?.find((e) => e.id === id);
    }
  }, [id, allData]);

  return [stfee] as [typeof stfee];
};
