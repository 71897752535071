import { setReportData } from "../annualTradeUXSlice";
import { setData } from "../annualTradeDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { AnnualTradingReportFilter } from "../types";
import { setReportYear } from "../annualTradeUXSlice";

export const useCurrentTradesReportFilter = () => {
  const filter = useSelector<StoreType, AnnualTradingReportFilter>(
    (store) => store.ux.reports.annualTrade.filter
  );
  const dispatch = useDispatch();
  const dispatchSetReportDate = React.useCallback(
    (reportDate: number) => {
      dispatch(setReportData(reportDate));
      dispatch(setData());
    },
    [dispatch]
  );
  const dispatchSetReportYear = React.useCallback(
    (year: number) => {
      dispatch(setReportYear(year));
      dispatch(setData());
    },
    [dispatch]
  );

  return [filter, dispatchSetReportDate, dispatchSetReportYear] as [
    typeof filter,
    typeof dispatchSetReportDate,
    typeof dispatchSetReportYear
  ];
};
