import { webApi } from "../../../../../core/http/httpThunks";
import { ClientAccount } from "../../types";

export const getClientAccountApi = (userId: string, climonaccid: string) =>
	webApi<ClientAccount>({
		method: "GET",
		url: "listing/getclimonacc",
		params: {
			userId,
			climonaccid
		},
	});

export default getClientAccountApi;
