import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllInvoices } from "../thunks/loadAllInvoices";
import { InvoiceListData } from "../types";
import { PageData } from "../../../models";

export const useInvoicesData = () => {
  const pageData = useSelector<
    StoreType,
    PageData<InvoiceListData> | undefined
  >((s) => s.data.accounting.invoices.currentPage);

  const dispatch = useDispatch();
  const dispatchLoadInvoicesPage = React.useCallback(
    () => dispatch(loadAllInvoices()),
    [dispatch]
  );

  return [pageData, dispatchLoadInvoicesPage] as [
    typeof pageData,
    typeof dispatchLoadInvoicesPage
  ];
};
