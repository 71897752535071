import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { InvoiceData } from "../types";
import { loadInvoice } from "../thunks/loadInvoice";

export const useDisplayInvoice = (invId?: string) => {
  const storeInvoice = useSelector<StoreType, InvoiceData | undefined>(
    (store) => store.data.accounting.invoices.displayedInvoice
  );

  const displayedInvoice =
    storeInvoice && storeInvoice.invId === invId ? storeInvoice : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (invId) {
      displatch(loadInvoice(invId));
    }
  }, [displatch, invId]);

  return displayedInvoice;
};
