import { webApi } from "../../../../core/http/httpThunks";
import { Order } from "../types";

export const delOrderApi = (userId: string, ordid: string) =>
  webApi<Order>({
    method: "DELETE",
    url: "order/delorder",
    params: {
      userId,
      ordid,
    },
  });

export default delOrderApi;
