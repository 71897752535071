import { ThunkType } from "../../../../core/store/reducers";
import { getChartData } from "../api/getChartData";
import { setGraphData } from "../tickerDataSlice";
import { ChartPeriod } from "../types";

export const loadGraphData = (graphId: string, prodid: string, period: ChartPeriod): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful" || !prodid) {
		return;
	}

	const { user } = store.data.auth.current;
	const graphData = await dispatch(getChartData(user, prodid, period));

	dispatch(
		setGraphData({
			graphId: graphId,
			data: graphData || [],
		})
	);
};
