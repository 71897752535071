import { ThunkType } from "../../../../core/store/reducers";
import getMarginsDataButton from "../api/getMarginsDataButton";
import { setFetchState, invalidateMarginssData } from "../marginsDataSlice";

export const downloadMarginsDataButton =
  (): ThunkType => async (dispatch, getStore) => {
    const store = getStore();

    if (store.data.auth.current.status !== "successful") {
      return undefined;
    }

    const { filter } = store.ux.clearing.marginsData;
    const user = store.data.auth.current.user;
    dispatch(setFetchState({ type: "started" }));

    const result = await dispatch(getMarginsDataButton(user, filter));

    if (result && result.resCode === "OK") {
      dispatch(setFetchState({ type: "successful" }));
      dispatch(invalidateMarginssData());
    } else {
      console.log(result);
      dispatch(
        setFetchState({ type: "successful", result: !result || result.resDesc })
      );
      dispatch(invalidateMarginssData());
    }
  };
