import { ThunkType } from "../../../../core/store/reducers";
import { setControl, invalidateControl } from "../ctrlGoodsDataSlice";
import { setControlIndex } from "../ctrlGoodsUXSlice";
import getCtrlGoods from "../api/getCtrlGoods";
import { PageIndex, PageDataStatus } from "../../../models";

export const loadAllCtrlGoods = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const { pageNumber, itemsPerPage } = store.ux.clearing.controls.index;
  const { filter } = store.ux.clearing.controls;
  const user = store.data.auth.current.user;

  const ctrlGoodsResponse = await dispatch(
    getCtrlGoods(user, pageNumber, itemsPerPage, filter)
  );

  if (!ctrlGoodsResponse || ctrlGoodsResponse.resCode !== "OK") {
    return;
  }

  const ctrlGoods = {
    currentPageData: ctrlGoodsResponse.obj,
    totalCount: Number(ctrlGoodsResponse.resDesc) || 0,
    status: PageDataStatus.DataCurrent,
  };

  dispatch(setControl(ctrlGoods));
};

export const setCtrlGoodsIndexThunk = (index: PageIndex): ThunkType => (
  dispatch
) => {
  dispatch(setControlIndex(index));
  dispatch(invalidateControl());
};
