import React from "react";
import { ProductGroupData } from "../../../data/listing/productGroups/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayProductGroupBasicInfoProps {
	group: ProductGroupData;
}

export const DisplayProductGroupBasicInfo: React.FunctionComponent<DisplayProductGroupBasicInfoProps> = ({
	group,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);

	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("productGroupNameSR")}</span>
				<span className={style.value}>{group.prodGroupName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("productGroupNameEN")}</span>
				<span className={style.value}>{group.prodGroupNameEn}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("productGroupStatus")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={group.status}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				</span>
			</div>
		</div>
	);
};
