import { CurrencyRate } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";

interface ICurrencyRateData {
	all?: CurrencyRate[];
	filterDate: Date | null;
	displayCurrencyId?: string;
	displayData?: CurrencyRate[];
	displayed?: CurrencyRate;
	fetchState: FetchStateType;
}

const initialState: ICurrencyRateData = {
	filterDate: null,
	fetchState: { type: "not-started" },
};

const name = "currencyRates";

const currencyRatesSlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<CurrencyRate[] | undefined>) => ({
			...state,
			all: action.payload,
			displayData: filterData(action.payload, state.filterDate),
		}),
		setFilterDate: (state, action: PayloadAction<Date | null>) => ({
			...state,
			filterDate: action.payload,
			displayData: filterData(state.all, action.payload),
		}),
		setDisplayed: (state, action: PayloadAction<CurrencyRate | undefined>) => ({
			...state,
			displayed: action.payload,
		}),
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayed: action.payload.type === "successful" ? undefined : state.displayed,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			fetchState: getFetchStateChange(state.fetchState, action.payload),
		}),
		setDisplayCurrencyId: (state, action: PayloadAction<string | undefined>) => ({
			...state,
			all: undefined,
			displayData: undefined,
			displayCurrencyId: action.payload,
		}),
	},
});

const filterData = (data?: CurrencyRate[], filterDate?: Date | null) =>
	filterDate === undefined || filterDate === null || filterData === undefined
		? data
		: data?.filter(
				e =>
					e.date.getDay() === filterDate.getDay() &&
					e.date.getMonth() === filterDate.getMonth() &&
					e.date.getFullYear() === filterDate.getFullYear()
		  );

export const {
	setData,
	setFilterDate: setSearchTerm,
	setDisplayed,
	setFetchState,
	setDisplayCurrencyId,
} = currencyRatesSlice.actions;

export default currencyRatesSlice.reducer;
