import React from "react";
import { useTradesReportDownloader } from "../../../data/reports/tradesReportClientAll/hooks/useTradesReportDownloader";
import { useTradesExcelDownloader } from "../../../data/reports/tradesReportClientAll/hooks/useTradesExcelDownloader";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import ExcelIcon from "@material-ui/icons/TableChartOutlined";
import { LocalizationContext } from "../../../core/localization/Localization";
import { CustomDialog } from "../../components/common/CustomDialog";

export const DownloadButton: React.FunctionComponent = () => {
	const [downloader, fetchState] = useTradesReportDownloader();
	const { getString } = React.useContext(LocalizationContext);
	return (
		<>
		<CustomTooltip titleKeyword="exportPDFTooltip">
			<IconButton
				color="secondary"
				component="span"
				onClick={downloader}
				disabled={fetchState.type !== "not-started"}
			>
				<DownloadIcon />
			</IconButton>
		</CustomTooltip>
		{fetchState.type !== "not-started"  ? getString("pleaseWait") : null}

		{/*fetchState.type === "started" && <CustomDialog
				open={fetchState.type === "started"}
				title={getString("invoicingMessageTitle")}
				message={getString("invoicingMessage")}
				closeLabel={getString("invoicingMessageConfim")}
				onClose={downloader}
	/>*/}

		</>
	);
};

export const DownloadExcelButton: React.FunctionComponent = () => {
	const [downloader, fetchState] = useTradesExcelDownloader();
	return (
		<CustomTooltip titleKeyword="exportXLSXTooltip">
			<IconButton
				color="secondary"
				component="span"
				onClick={downloader}
				disabled={fetchState.type !== "not-started"}
			>
				<ExcelIcon />
			</IconButton>
		</CustomTooltip>
	);
};
