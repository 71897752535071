import React from "react";
import { useCommissionTypes } from "../../../data/initialData/hooks/useCommissionTypes";
import { CommissionType } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType } from "./AutocompleteSelect";

type ProductGroupSelectPropsType = Omit<AutocompleteSelectType<CommissionType>, "data" | "getLabel">;

export const CommissionTypeSelect: React.FunctionComponent<ProductGroupSelectPropsType> = props => {
	const [types, renderTypeLabel] = useCommissionTypes();

	return (
		<AutocompleteSelect<CommissionType>
			{...(props as AutocompleteSelectType<CommissionType>)}
			data={types}
			getLabel={renderTypeLabel}
		/>
	);
};
