import React from "react";
import { useDisplayNews } from "./useDisplayNews";
import { NewsData } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

const now = new Date();
export const defaultNews: NewsData = {
  title: '',
  status: 1,
};

export const useEditNews = (newsid?: string): NewsData | undefined => {
  const displayNews = useDisplayNews(newsid);

  return React.useMemo(() => {
    if (newsid !== undefined && displayNews === undefined) {
      return undefined;
    }

    if (newsid === undefined) {
      return { ...defaultNews };
    }

    return displayNews ? { ...displayNews } : undefined;
  }, [newsid, displayNews]);
};

export const useEditNewsState = (newsid?: string) => {
  const news = useEditNews(newsid);
  return useCopiedState(news);
};
