import { useDispatch, useSelector } from "react-redux";
import { downloadBusinessActivitiesExcel } from "../thunks/downloadReportExcel";
import React from "react";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";

export const useBusinessActivitiesExcelDownloader = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (s) =>
      s.data.reports.exchbusiness.businessActivities.downloadReportFetchState
  );
  const dispatch = useDispatch();
  const download = React.useCallback(
    () => dispatch(downloadBusinessActivitiesExcel()),
    [dispatch]
  );

  return [download, fetchState] as [typeof download, typeof fetchState];
};
