import { ThunkType } from "../../../../core/store/reducers";
import getAllCitiesAPI from "../api/getAllCities";
import { setData } from "../citiesDataReducer";

export const loadAllCities = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setData());

	const { userId } = store.data.auth.current.user;
	const cities = await dispatch(getAllCitiesAPI(userId));

	dispatch(setData(cities || []));
};
