import React, { useContext, useMemo } from "react";
import CalculateIcon from '@material-ui/icons/MonetizationOnOutlined';
import { TradeList } from "../../../data/trading/trades/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useShowClaculateTASCosts } from "./ClaculateTASCostsDrawer";
import { useAuth } from "../../../data/auth/hooks";

export function useTTASCostsActions() {
	const { getString } = useContext(LocalizationContext);
	const onCancelTrade = useShowClaculateTASCosts();
	const [auth] = useAuth();

	return useMemo<MaterialTableProps<TradeList>["actions"]>(
		() => [
			trans => ({
				icon: () => <CalculateIcon />,
				tooltip: getString("TASTooltip"),
				onClick: (event, rowData) => onCancelTrade(rowData as TradeList),
				hidden: auth.status !== "successful" || auth.user.userIsEmployee !== 1 || trans.status === 40,
			}),
		],
		[getString, onCancelTrade, auth]
	);
}
