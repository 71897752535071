import React from "react";
import { useDispatch } from "react-redux";
import { getTradesActiveThunk } from "../thunks/getTradesActiveThunk";
import { TradesSimpleListData, TradeFilter } from "../types";

export const useTradesActiveData = () => {
	const dispatch = useDispatch();
	const [trades, setTrades] = React.useState<TradesSimpleListData[] | undefined>();

	React.useEffect(() => {
		const loadTrades = async () => {
			const loaded = ((await dispatch(
				getTradesActiveThunk()
			)) as any) as TradesSimpleListData[];
			setTrades(loaded);
		};
			loadTrades();
	}, [ dispatch]);  

	const loadTrades = React.useCallback(
		() => dispatch(getTradesActiveThunk()),
		[dispatch]
	  );
	  
	return [trades] as [typeof trades];
};



