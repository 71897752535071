import React from "react";
import { useEditCurrencyState } from "../../../data/listing/currencies/hooks/useEditCurrencyState";
import { useCurrencyValidator } from "../../../data/listing/currencies/hooks/useCurrencyValidator";
import { useCurrencyUpsert } from "../../../data/listing/currencies/hooks/useCurrencyUpsert";
import { Currency } from "../../../data/listing/currencies/types";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { StringInput } from "../../components/common/StringInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { PageTitle } from "../../components/common/PageTitle";
import { currencyLocation } from "./Locations";

export interface EditCurrencyPageInfo {
	currencyId?: string;
}

export interface EditCurrencyProps extends RouteComponentProps<EditCurrencyPageInfo> {}

export const EditCurrencyPage: React.FunctionComponent<EditCurrencyProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [currency, editCurrency, changed] = useEditCurrencyState(props.match.params.currencyId);
	const [validationState, validate] = useCurrencyValidator();
	const [fetchState, upsert] = useCurrencyUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editCurrency(event.target.name as any, event.target.value);
		},
		[editCurrency]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(currencyLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(currency);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [currency, validate, fetchState, history, upsert]);

	const pageTitle = currency && currency.currId ? "currenciesEditTitle" : "currenciesCreateTitle";

	if (!currency) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={currencyLocation} />} />
					<Divider />
					<CardContent>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="flex-start"
							spacing={4}
						>
							<Grid item xs={3}>
								<StringInput<Currency>
									property="currName"
									item={currency}
									validationState={validationState}
									onChange={handleChange}
									label={getString("currenciesName")}
								/>
							</Grid>
							<Grid item xs={3}>
								<StringInput<Currency>
									property="currCode"
									item={currency}
									validationState={validationState}
									onChange={handleChange}
									label={getString("currenciesCode")}
								/>
							</Grid>
							<Grid item xs={3}>
								<StringInput<Currency>
									property="numCode"
									item={currency}
									validationState={validationState}
									onChange={handleChange}
									label={getString("currenciesNumericCode")}
								/>
							</Grid>
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
