import { getEditLocation } from "../../../core/util/functions";
import { OrdersEditAction } from "../../../data/trading/orders/types";

export const chartsmobLocation = "/trading/charts"

//export enum OrdersEditParts {
//	BasicInfo = "basicinfo",
//	Attributes = "attributes",
//}

//export const getOrderEditLocation = (
//	action: OrdersEditAction = OrdersEditAction.New,
//	part: OrdersEditParts = OrdersEditParts.BasicInfo,
//	productId?: string,
//	attributeId?: string
//) => getEditLocation(chartsmobLocation, part, productId, attributeId, action);

//export const baseEdit = getOrderEditLocation(":action" as any, ":editPart" as any, ":orderId?");
//export const atributesEdit = getOrderEditLocation(
//	":action" as any,
//	OrdersEditParts.Attributes,
//	":orderId?",
//	":attributeId?"
//);