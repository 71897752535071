import { ThunkType } from "../../../../core/store/reducers";
import { printDelivery } from "../api/printDelivery";
import fileDownload from "js-file-download";
import { setPrintDeliveryFetchState } from "../deliveryDataSlice";
import { DeliveryData } from "../types";


export const printDeliveryThunk = (delivery?: DeliveryData): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful" || !delivery || !delivery.deliveryId) {
		return undefined;
	}
	const lan = store.ux.localization.language
	const filename = `DeliveryConfirmation_${delivery.deliveryId}.pdf`
	const user = store.data.auth.current.user;
	dispatch(setPrintDeliveryFetchState({ type: "started" }));
	const deliveryReport = await dispatch(printDelivery(user, delivery.deliveryId));
	if (deliveryReport) {
		fileDownload(new Blob([deliveryReport as any], { type: "application/pdf" }), filename);
	}
	dispatch(setPrintDeliveryFetchState({ type: "not-started" }));
};
