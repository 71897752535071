import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import { InvoiceFilter } from "./types";

type AccountingOrderPageState = {
  index: PageIndex;
  filter: InvoiceFilter;
};

const now = new Date();
export const initialState: AccountingOrderPageState = {
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
  filter: {
    dateto: now.toISOString(),
    datefrom: now.toISOString(),
  },
};

const name = "accountingOrder";

const accountingOrderSlice = createSlice({
  name,
  initialState,
  reducers: {
    setIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    resetIdices: (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }),
    setFilter: (state, action: PayloadAction<InvoiceFilter>) => ({
      ...state,
      filter: action.payload,
    }),
    removeFilter: (state, action: PayloadAction<keyof InvoiceFilter>) => ({
      ...state,
      filter: {
        ...state.filter,
        [action.payload]: undefined,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(successfulLogin, (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }));
  },
});

export const {
  setIndex,
  resetIdices,
  setFilter,
  removeFilter,
} = accountingOrderSlice.actions;

export default accountingOrderSlice.reducer;
