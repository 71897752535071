import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageData, PageDataStatus, FetchStateType } from "../../models";
import { ViewOrder, Order } from "../../trading/orders/types";

type OrdersData = {
  currentPage?: PageData<ViewOrder>;
  displayedMBOrder?: Order;
  downloadReportFetchState: FetchStateType;
};

const initialState: OrdersData = {
  downloadReportFetchState: { type: "not-started" },
};

const name = "ordersReport";

const ordersSlice = createSlice({
  name,
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<PageData<ViewOrder>>) => ({
      ...state,
      currentPage: action.payload,
    }),
    invalidateOrders: (state) => {
      if (!state.currentPage) return state;

      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          status: PageDataStatus.DataInvalidated,
        },
      };
    },
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			downloadReportFetchState: action.payload,
		}),
  },
});

export const {
  setOrders,
  invalidateOrders,
  setFetchState,
} = ordersSlice.actions;

export default ordersSlice.reducer;
