import DailyTradesReport from "./DailyTradesReport";
import { NavigationItem } from "../../../core/navigation/types";
import { dailyTradesReportLocation, } from "./Locations";

export const dailyTradesReportComponents: NavigationItem[] = [
	{
		Component: DailyTradesReport,
		isMenuItem: true,
		path: dailyTradesReportLocation,
	},
];
