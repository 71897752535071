import { combineReducers, AnyAction } from "@reduxjs/toolkit";
import ordersReport from "./ordersReport/ordersReportDataSlice";
import tradesReport from "./tradesReport/tradesReportDataSlice";
import tradesReportAll  from "./tradesReportClientAll/tradesReportDataSliceAll";
import dailyTrade from "./dailyTrade/dailyTradeDataSlice";
import weeklyTrade from "./weeklyTrade/weeklyTradeDataSlice";
import monthlyTrade from "./monthlyTrade/monthlyTradeDataSlice";
import annualTrade from "./annualTrade/annualTradeDataSlice";
import securityCommission from "./securityCommission/securityCommissionReportDataReducer";
import antiLaundering from "./amlaundering/amlaunderingDataSlice";
import exchangeMember from "./exmember/exchangeMemberDataSlice";
import exchbusiness from "./exchbusiness/exchBusinessDataReducer";
import news from "./news/newsDataReducer";
import pushnotifications from "./notifications/notificationDataSlice";
import { resetLoginState } from "../auth/authDataSlice";

const dataReportsReducer = combineReducers({
	ordersReport,
	tradesReport,
	tradesReportAll,
	dailyTrade,
	weeklyTrade,
	monthlyTrade,
	annualTrade,
	securityCommission,
	antiLaundering,
	exchangeMember,
	exchbusiness,
	news,
	pushnotifications,
});

export default (state: any, action: AnyAction) => {
	if (action.type === resetLoginState.type) {
		return dataReportsReducer(undefined, action);
	} else {
		return dataReportsReducer(state, action);
	}
};
