import WeightedPricesReport from "./WeightedPricesReport";
import { NavigationItem } from "../../../../core/navigation/types";
import { weightedPricesLocation } from "./Locations";

export const weightedPricesReportComponents: NavigationItem[] = [
  {
    Component: WeightedPricesReport,
    isMenuItem: true,
    path: weightedPricesLocation,
  },
];
