import { NavigationItem } from "../../../core/navigation/types";
import InvoicesPage from "./InvoicesPage";
import { EditInvoicePage } from "./EditInvoicePage";
import { invoicesLocation, editRouter } from "./Locations";
export const invoicesComponents: NavigationItem[] = [
  {
    Component: InvoicesPage,
    isMenuItem: true,
    path: invoicesLocation,
  },
  {
    Component: EditInvoicePage,
    isMenuItem: false,
    path: editRouter,
  },
];
