import React from "react";
import { useDisplayWhs } from "./useDisplayWhs";
import { Warehouse } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defaultWhs: Warehouse = {
  whsCode: "",
  whsName: "",
  cityId: "",
  address: "",
  status: Status.Active,
};

export const useEditWhs = (whsId?: string) => {
  const displayWhs = useDisplayWhs(whsId);

  return React.useMemo(() => {
    if (whsId !== undefined && displayWhs === undefined) {
      return undefined;
    }

    if (whsId === undefined) {
      return { ...defaultWhs };
    }

    return displayWhs ? { ...displayWhs } : undefined;
  }, [whsId, displayWhs]);
};

export const useEditWhsState = (whsId?: string) => {
  const whs = useEditWhs(whsId);
  return useCopiedState(whs);
};
