import { ViewOrder } from "../../../data/trading/orders/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { renderOrderPrice, renderClient, TurnocateText } from "../orders/columRenderers";
import { useOrderMarketOptionsTranslator } from "../../../data/trading/orders/hooks/useOrderMarketOptions";

export const useOrderBookColumns = () => {
  const { getString, getFormatedNumberString } = React.useContext(LocalizationContext);
  const marketTranslator = useOrderMarketOptionsTranslator()

	return React.useMemo(
		() => [
			{
				title: getString("orderClmOrdNo"),
				field: "ordNo",
        sorting: false,
        width: "100px",
        cellStyle: {
          maxWidth: "100px",
          minWidth: "100px",
          width: "100px",
        },
        headerStyle: {
          maxWidth: "100px",
          minWidth: "100px",
          width: "100px",
        },
			},
			{
				title: getString("orderTimeReceived"),
				render: order => order.timeReceived,
				sorting: false,
        width: "180px",
        cellStyle: {
          maxWidth: "180px",
          minWidth: "180px",
          width: "180px",
        },
        headerStyle: {
          maxWidth: "180px",
          minWidth: "180px",
          width: "180px",
        },
			},
			{
				title: getString("orderClientName"),
				render: renderClient,
				sorting: false,
        width: "200px",
        cellStyle: {
          maxWidth: "200px",
          minWidth: "200px",
          width: "200px",
          whiteSpace: "nowrap"
        },
        headerStyle: {
          maxWidth: "200px",
          minWidth: "200px",
          width: "200px",
        },
			},
			{
				title: getString("orderSide"),
				field: "side",
				sorting: false,
        width: "80px",
        cellStyle: {
          maxWidth: "80px",
          minWidth: "80px",
          width: "80px",
        },
        headerStyle: {
          maxWidth: "80px",
          minWidth: "80px",
          width: "80px",
        },
			},
			{
				title: getString("orderPrice"),
				render: renderOrderPrice,
				sorting: false,
        headerStyle: {
          whiteSpace: "nowrap",
          textAlign: "end"
        },
        cellStyle: {
					textAlign: "end"
     		},
			},
      {
				title: getString("orderQty"),
				render: order => `${getFormatedNumberString(order.qty)} ${order.unit}`,
				sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end"
        },
        headerStyle: {
          whiteSpace: "nowrap",
          textAlign: "end"
        },
			},
			{
				title: getString("orderProduct"),
				field: "product",
				sorting: false,
        cellStyle: {
          whiteSpace: "nowrap"
        },
			},
			{
				title: getString("orderMarket"),
        render: order => marketTranslator(order.market),
				sorting: false,
        width: "70px",
        cellStyle: {
          maxWidth: "70px",
          minWidth: "70px",
          width: "70px",
        },
        headerStyle: {
          maxWidth: "70px",
          minWidth: "70px",
          width: "70px",
        },
			},
			{
				title: getString("orderStatus"),
        render: order => (<TurnocateText text={order.status} maxChars={18} />),
				sorting: false,
        cellStyle: {
          whiteSpace: "nowrap"
        },
			},
		],
		[getString, getFormatedNumberString, marketTranslator]
	) as Column<ViewOrder>[];
};
