import { combineReducers } from "@reduxjs/toolkit";
import createInvoices from "./createInvoices/createInvoicesUXSlice";
import invoices from "./invoices/invoicesUXSlice";
import accountingOrder from "./accountingOrder/accountingOrderUXSlice";

const uxAccountingReducer = combineReducers({
	createInvoices,
	invoices,
	accountingOrder,
});

export default uxAccountingReducer;
