import React from "react";
import { useTradeByBrokersColumns } from "./useTradeByBrokersReportColumns";
import { AccessRights } from "../../../../core/navigation/types";
import { TurnoverByBrokersReportData } from "../../../../data/reports/exchbusiness/tradeByBrokers/types";
import { ControledTable } from "../../../components/common/ControledTable";
import { useTradeByBrokersReportData } from "../../../../data/reports/exchbusiness/tradeByBrokers/hooks/useTradeByBrokersReportData";
import { useTradeByBrokersReportIndex } from "../../../../data/reports/exchbusiness/tradeByBrokers/hooks/useTradeByBrokersReportIndex";

export interface TradeByBrokersTableProps {
  userAccessRights: AccessRights;
}

type TradeByBrokersTablePropsType = TradeByBrokersTableProps;

export const TradeByBrokersReportTable: React.FunctionComponent<TradeByBrokersTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useTradeByBrokersColumns();
  const [data, load] = useTradeByBrokersReportData();
  const [index, setIndex] = useTradeByBrokersReportIndex();

  return (
    <ControledTable<TurnoverByBrokersReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default TradeByBrokersReportTable;
