import { webApi } from "../../../../core/http/httpThunks";

export const deleteDeliveryApi = (userId: string, dlvrid: string) =>
  webApi<string>({
    method: "DELETE",
    url: "clearing/deldlvrgoods",
    params: {
      userId,
      dlvrid,
    },
  });

export default deleteDeliveryApi;
