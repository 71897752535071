import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadReport } from "../thunks/loadCreateInvoicesData";
import { CreateInvoicesTranData } from "../types";

export const useCreateInvoicesData = () => {
  const data = useSelector<StoreType, CreateInvoicesTranData[] | undefined>(
    (s) => s.data.accounting.createInvoices.data
  );

  const dispatch = useDispatch();
  const dispatchLoadData = React.useCallback(() => dispatch(loadReport()), [
    dispatch,
  ]);

  return [data, dispatchLoadData] as [typeof data, typeof dispatchLoadData];
};
