import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { PaymentData } from "../types";
import { loadPayment } from "../thunks/loadPayment";

export const useDisplayPayment = (payId?: string) => {
  const storePayment = useSelector<StoreType, PaymentData | undefined>(
    (store) => store.data.clearing.payments.displayedPayment
  );

  const displayedPayment =
    storePayment && storePayment.payId === payId ? storePayment : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (payId) {
      displatch(loadPayment(payId));
    }
  }, [displatch, payId]);

  return displayedPayment;
};
