import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageData, PageDataStatus, FetchStateType } from "../../../models";
import { CommReviewReportData } from "./types";

type TradesReportData = {
	data?: CommReviewReportData[];
	downloadReportFetchState: FetchStateType;
};

const initialState: TradesReportData = {
	downloadReportFetchState: { type: "not-started" },
};

const name = "commReview";

const commReviewSlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<CommReviewReportData[] | undefined>) => ({
			...state,
			data: action.payload,
		}),
		invalidateCommReview: state => {
			return {
				...state,
				data: undefined,
			};
		},
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			downloadReportFetchState: action.payload,
		}),
	},
});

export const { setData, invalidateCommReview, setFetchState } = commReviewSlice.actions;

export default commReviewSlice.reducer;
