import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllWhs } from "../thunks/loadAllWhs";
import { Warehouse } from "../types";

export const useWhsData = () => {
  const data = useSelector<StoreType, Warehouse[] | undefined>(
    (s) => s.data.listing.warehouses.displayData
  );
  const allData = useSelector<StoreType, Warehouse[] | undefined>(
    (s) => s.data.listing.warehouses.all
  );

  const dispatch = useDispatch();
  const dispatchLoadAllWhs = React.useCallback(() => dispatch(loadAllWhs()), [
    dispatch,
  ]);

  return [data, dispatchLoadAllWhs, allData] as [
    typeof data,
    typeof dispatchLoadAllWhs,
    typeof allData
  ];
};

export const useWhs = (id?: string) => {
  const allData = useSelector<StoreType, Warehouse[] | undefined>(
    (s) => s.data.listing.warehouses.all
  );

  const dispatch = useDispatch();
  const dispatchLoadAllWhs = React.useCallback(() => dispatch(loadAllWhs()), [
    dispatch,
  ]);

  React.useEffect(() => {
    if (!allData && id) {
      dispatchLoadAllWhs();
    }
  }, [allData, id, dispatchLoadAllWhs]);

  const whs = React.useMemo(() => {
    if (!allData) {
      return undefined;
    } else {
      return allData?.find((e) => e.whsId === id);
    }
  }, [id, allData]);

  return [whs] as [typeof whs];
};
