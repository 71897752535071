import { ThunkType } from "../../../../../core/store/reducers";
import getAllClientBrokers from "../../api/clientBroker/getAllClientBrokers";
import { ClientBroker } from "../../types";

export const getClientBrokers = (clientId: string): ThunkType<Promise<ClientBroker[] | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getAllClientBrokers(userId, clientId));
}
