import { NavigationItem } from "../../../core/navigation/types";
import RegionsPage from "./RegionsPage";
import TopRegionsPage from "./TopRegionsPage";
import { EditRegionPage } from "./EditRegionPage";
import { regionsLocation, topRegionsLocation, editRouter } from "./Locations";
export const regionsComponents: NavigationItem[] = [
	{
		Component: RegionsPage,
		isMenuItem: true,
		path: regionsLocation,
	},
	{
		Component: TopRegionsPage,
		isMenuItem: true,
		path: topRegionsLocation,
	},
	{
		Component: EditRegionPage,
		isMenuItem: false,
		path: editRouter,
	},
];
