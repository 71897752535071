import { webApi } from "../../../../core/http/httpThunks";
import { MarginsData } from "../types";

export const getMarginsDataApi = (userId: string, mrgid: string) =>
  webApi<MarginsData>({
    method: "GET",
    url: "clearing/getmarginsdata",
    params: {
      userId,
      mrgid,
    },
  });

export default getMarginsDataApi;
