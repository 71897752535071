import React from "react";
import { useProductAttributes } from "../../../data/initialData/hooks/useProductAttributes";
import { ProductAttribute } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType } from "./AutocompleteSelect";

type ProductAttributeSelectPropsType = Omit<AutocompleteSelectType<ProductAttribute>, "data" | "getLabel">;

export const ProductAttributeSelect: React.FunctionComponent<ProductAttributeSelectPropsType> = props => {
	const [attributes, renderAttributeLabel] = useProductAttributes();

	return (
		<AutocompleteSelect<ProductAttribute>
			{...(props as AutocompleteSelectType<ProductAttribute>)}
			data={attributes}
			getLabel={renderAttributeLabel}
		/>
	);
};
