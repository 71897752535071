import { ThunkType } from "../../../../core/store/reducers";
import { cancelTradeApi } from "../api/cancelTrade";
import { setCancelTradeFetchState, invalidateTrades } from "../tradesDataSlice";

export const cancelTrade = (tradeId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setCancelTradeFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(cancelTradeApi(userId, tradeId));

	if (result !== undefined)
	{
		dispatch(invalidateTrades());
		dispatch(setCancelTradeFetchState({ type: "successful" }));
	} else {
		dispatch(setCancelTradeFetchState({ type: "error"}));
	}
};
