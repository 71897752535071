import { ThunkType } from "../../../../../core/store/reducers";
import deleteClientPersonApi from "../../api/clientPerson/deleteClientPerson";
import { setFetchStatePerson } from "../../clientsDataReducer";

export const deleteClientPerson = (personId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchStatePerson({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteClientPersonApi(userId, personId));

	dispatch(setFetchStatePerson(result !== undefined ? { type: "successful" } : { type: "error"}));
};
