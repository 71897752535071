import React from "react";
import { useDisplayUser } from "./useDisplayUser";
import { UserData, UserActive, UserChangePass, UserSubscribtionUnlimited, UserEmployee } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";

export const defauiltRole: UserData = {
	changePass: UserChangePass.True,
	isActive: UserActive.True,
	isEmployee: UserEmployee.True,
	loginUser: "",
	userName: "",
	subscrUnlimited: UserSubscribtionUnlimited.True,
}

export const useEditUser = (userId?: string) => {
	const displayUser = useDisplayUser(userId);

	return React.useMemo(() => {
		if (userId !== undefined && displayUser === undefined) {
			return undefined;
        }

		if (userId === undefined) {
			return { ...defauiltRole };
        }

		return displayUser ? { ...displayUser } : undefined;
	}, [userId, displayUser]);
};

export const useEditUserState = (userID?: string) => {
	const user = useEditUser(userID);
	return useCopiedState(user)
};
