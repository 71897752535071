import { ThunkType } from "../../../../core/store/reducers";
import deleteControlApi from "../api/deleteCtrlGoods";
import { setFetchState, invalidateControl } from "../ctrlGoodsDataSlice";

export const deleteCtrlGoods = (controlId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = await dispatch(deleteControlApi(userId, controlId));

  dispatch(
    setFetchState(
      result !== undefined ? { type: "successful" } : { type: "error" }
    )
  );
  dispatch(invalidateControl())
};
