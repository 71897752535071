import { ValidationState } from "../../../core/util/types";

export interface Commission {
	commId?: string; // 		PK
	commCode: string; // 		sifra klase provizije
	commName: string; // 		naziv klase
	commNameEn?: string; // 		naziv EN
	commType?: number; // 		tip provizije (def 0 = berza)
	commTypeName?: string; // 	naziv tipa
	isStandard: CommissionStandard; //
}

export enum CommissionStandard {
	True = 1,
	False = 0,
}

export interface CommissionDetail {
	commDetId?: string; // 		PK
	commId: string; // 		ID klase provizije
	fromDate?: Date; // 		vazi od datuma
	jobType: 0; // 		vrsta posla (FFU - za sada samo 0)
	clientType: 0; // 		tip klijenta (FFU - 0)
	clientId: undefined; // 		ID klijenta (FFU - null)
	prodGroupId?: string; // 		ID grupe proizvoda za koju vazi provizija
	prodGroupName?: string; // 	naziv grupe
	prodId?: string; // 		ID proizvoda za koji vazi provizija
	prodName?: string; // 		naziv proizvoda
	// - grupa i proizvod se unose jedan ili nijedan
	currId?: string; // 	ID valute za koju vazi provizija i u kojoj se iskazuje
	currCode?: string; // 		sifra valute
	amtCurrId?: string; // 		ID valute iznosa
	percAmtBuy: CommissionAmountType; // 		provizija kupovina %/iznos (0/1 def 0)
	percBuy?: number; // 		% provizije za kupovinu
	minAmtBuy?: number; // 		min iznos provizije za kupovinu ili iznos provizije ako je PercAmtBuy=1. Iznos je u valuti AmtCurrId
	maxAmtBuy?: number; // 		max iznos provizije
	percAmtSell: CommissionAmountType; // 		isto sve, samo za prodaju
	percSell?: number; // 		-
	minAmtSell?: number; // 		-
	maxAmtSell?: number; // 		-
}

export enum CommissionAmountType {
	Percent = 0,
	Amount = 1,
}

export type ProductValidationState = ValidationState<Commission>;
