import { WeightedPricesReportData, WeightedPricesReportFilter } from "../types";
import { User } from "../../../../auth/types";
import { webApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

const getWeightedPriceData = (
  user: User,
  filter: WeightedPricesReportFilter
) => {
  return webApi<WeightedPricesReportData[]>({
    method: "GET",
    url: "reporting/getweightedpricelist",
    params: {
      userid: user.userId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      productid: filter.productid,
    },
  });
};

export default getWeightedPriceData;
