import { webApiWithResponse } from "../../../core/http/httpThunks";

export const storepushnotiftokenApi = (userId: string, pushToken: string) =>{

   return webApiWithResponse<string>({
    method: "POST",
    url: "user/storepushnotiftoken",
    params: {
      userId,
      pushToken,
    },
  });
}
export default storepushnotiftokenApi;
