import { listingComponents } from "../listing/listingNavigationItems";
import { tradingComponents } from "../trading/tradingNavigationItems";
import { clearingComponents } from "../clearing/clearingNavigationItems";
import { reportsComponents } from "../reports/reportsNavigationItems";
import { accountingComponents } from "../accounting/accountingNavigationItems";
import { surveillanceComponents } from "../surveillance/surveillanceNavigationItems";


export const appComponents = [
    ...listingComponents,
    ...tradingComponents,
    ...clearingComponents,
    ...reportsComponents,
    ...accountingComponents,
    ...surveillanceComponents,
]