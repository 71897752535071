import React from "react";
import {
  Grid,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Fab,
} from "@material-ui/core";
import BankGuaranteesTable from "./BankGuaranteesTable";
import AddIcon from "@material-ui/icons/Add";
import { usePageStyles } from "../../pageStyles";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { useBankGuaranteeData } from "../../../data/clearing/bankguarantees/hooks/useBankGuaranteeData";
import { useBankGuaranteeIndex } from "../../../data/clearing/bankguarantees/hooks/useBankGuaranteeIndex";
import { useHistory } from "react-router-dom";
import { getBankGuaranteeEditLocation } from "../bankguarantees/Locations";
import { PageDataStatus } from "../../../data/models";
import { BankGuaranteeFilterButton } from "./BankGuaranteeFilter";
import { useAuth } from "../../../data/auth/hooks";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";

const BankGuaranteesPage: PageComponent = ({ userAccessRights }) => {
  const pageStyles = usePageStyles();

  const [pageData, dispatchLoadBankGuarantee, invalidate] = useBankGuaranteeData();
  const [pageIndex, dispatchSetIndex] = useBankGuaranteeIndex();

  React.useEffect(() => {
    if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
      dispatchLoadBankGuarantee();
    }
  }, [dispatchLoadBankGuarantee, pageData]);

//  React.useEffect(() => () => { invalidate() }, [])

  const history = useHistory();

  const [auth] = useAuth();

  if (auth.status !== "successful") {
    return null;
  }

  return (
    <>
      <Grid container className={pageStyles.root}>
        <Grid item xs={12} className={pageStyles.gridItem}>
          <Card>
            <CardHeader
              title={<PageTitle title="grntTitle" />}
              action={<BankGuaranteesActions />}
            />
            <Divider />
            <CardContent>
              <BankGuaranteesTable
                pageIndex={pageIndex}
                pageData={pageData}
                onPageIndexChange={dispatchSetIndex}
                userAccessRights={userAccessRights}
                getData={dispatchLoadBankGuarantee}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {userAccessRights === AccessRights.All && (
          <Fab
            color="secondary"
            aria-label="add"
            className={pageStyles.mainFab}
            onClick={() => history.push(getBankGuaranteeEditLocation())}
          >
            <AddIcon />
          </Fab>
        )}
    </>
  );
};

export const BankGuaranteesActions: React.FunctionComponent = () => (
  <Grid container>
    <Grid item>
      <DownloadButton />
    </Grid>
    <Grid item>
      <DownloadExcelButton />
    </Grid>
    <Grid item>
      <BankGuaranteeFilterButton />
    </Grid>
  </Grid>
);

export default BankGuaranteesPage;
