import React, { useMemo } from "react";
import { PageIndex } from "../../../data/models";
import { TablePagination } from "@material-ui/core";
import { Table, TablePropsType } from "./Table";
import { Options } from "material-table";

interface ControledTableProps {
	pageIndex: PageIndex;
	onPageIndexChange: (newState: PageIndex) => void;
	total?: number;
}

export type ControledTablePropsType<TData extends object> = ControledTableProps & TablePropsType<TData>;

export function ControledTable<TData extends object>(props: ControledTablePropsType<TData>) {
	const { pageIndex, onPageIndexChange, options, immutableData, total, ...tableProps } = props;

	const tableOptions = useMemo((): Options => {
		return {
			...options,
			pageSize: pageIndex.itemsPerPage,
			initialPage: pageIndex.pageNumber,
		};
	}, [options, pageIndex]);

	const count = total || immutableData?.length || 0;

	return (
		<Table<TData>
			page={pageIndex.pageNumber}
			{...tableProps}
			immutableData={immutableData}
			components={{
				Pagination: paginationProps => (
					<TablePagination
						{...paginationProps}
						count={count}
						rowsPerPage={pageIndex.itemsPerPage}
						page={pageIndex.pageNumber}
						onChangePage={(evt, pageNumber) => {
							paginationProps.onChangePage(evt, pageNumber)
							onPageIndexChange({
								...pageIndex,
								pageNumber,
							});
						}}
						onChangeRowsPerPage={evt => {
							paginationProps.onChangeRowsPerPage(evt);
							onPageIndexChange({
								pageNumber: 0,
								itemsPerPage: (evt.target.value as any) as number,
							});
						}}
					/>
				),
			}}
			options={tableOptions}
		/>
	);
}
