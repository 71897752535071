import React from "react";
import { useCommissions } from "../../../data/initialData/hooks/useCommissions";
import { Commissions } from "../../../data/models";
import {
  AutocompleteSelect,
  AutocompleteSelectType,
} from "./AutocompleteSelect";

type CommissionsSelectPropsType = Omit<
  AutocompleteSelectType<Commissions>,
  "data" | "getLabel"
>;

export const CommissionSelect: React.FunctionComponent<CommissionsSelectPropsType> = (
  props
) => {
  const [commissions, renderCommissionsLabel] = useCommissions();

  return (
    <AutocompleteSelect<Commissions>
      data={commissions.filter((e) => e.commType === 1)}
      getLabel={renderCommissionsLabel}
      {...(props as any)}
    />
  );
};
