import { webApi } from "../../../../core/http/httpThunks";
import { PaymentData } from "../types";

export const getPaymentApi = (userId: string, payid: string) =>
  webApi<PaymentData>({
    method: "GET",
    url: "clearing/getpayment",
    params: {
      userId,
      payid,
    },
  });

export default getPaymentApi;
