import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Region } from "../types";
import { loadRegion } from "../thunks/loadRegion";

export const useDisplayRegion = (regId?: string) => {
	const storeRegion = useSelector<StoreType, Region | undefined>(
		store => store.data.listing.regions.displayedRegion
	);

	const displayedRegion = storeRegion && storeRegion.regId === regId ? storeRegion : undefined;

	const displatch = useDispatch();

	React.useEffect(() => {
		if (regId) {
			displatch(loadRegion(regId));
		}
	}, [displatch, regId]);

	return displayedRegion;
};
