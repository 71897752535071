import React from "react";
import { useCheckDoneByList } from "../../../data/initialData/hooks/useCheckDoneByList";
import { CheckDoneByList } from "../../../data/models";
import {
  AutocompleteSelect,
  AutocompleteSelectType,
} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<CheckDoneByList>,
  "data" | "getLabel"
>;

export const CheckDoneBySelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [checkDoneByList, renderLabel] = useCheckDoneByList();

  return (
    <AutocompleteSelect<CheckDoneByList>
      data={checkDoneByList}
      getLabel={renderLabel}
      {...(props as any)}
    />
  );
};
