import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { NewsData } from "../types";
import { loadNews } from "../thunks/loadNews";

export const useDisplayNews = (newsid?: string) => {
  const storeNews = useSelector<StoreType, NewsData | undefined>(
    (store) => store.data.reports.news.displayedNews
  );

  const displayedNews =
  storeNews && storeNews.newsId === newsid ? storeNews : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (newsid) {
      displatch(loadNews(newsid));
    }
  }, [displatch, newsid]);

  return displayedNews;
};
