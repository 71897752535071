import { ThunkType } from "../../../../core/store/reducers";
import { delOrderApi } from "../api/delOrder";
import { setDeleteOrderFetchState, invalidateOrders } from "../../mainBoard/ordersDataSlice";

export const delOrder = (orderId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setDeleteOrderFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(delOrderApi(userId, orderId));

	if (result !== undefined)
	{
		dispatch(invalidateOrders());
		dispatch(setDeleteOrderFetchState({ type: "successful" }));
	} else {
		dispatch(setDeleteOrderFetchState({ type: "error"}));
	}
};
