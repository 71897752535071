import { AlarmFilter } from "../types";
import { AlarmData, GetAlarmParams } from "../types";
import { User } from "../../../auth/types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";

export const getAlarmsWebApi = (
  user: User,
  page: number,
  itemsPerPage: number,
  filter: AlarmFilter
) => {
  const params: GetAlarmParams = {
    userid: user.userId,
    usrid: filter.user?.userId,
    clientid: filter.client?.cliId,
    prodid: filter.product?.prodId,
    fromrow: page * itemsPerPage,
    numofrows: itemsPerPage,
    active: 1
  };

  return webApiWithResponse<AlarmData[]>({
    method: "GET",
    url: "surveillance/getalarmdeflist",
    params,
  });
};

export default getAlarmsWebApi;
