import React from "react";
import { useDisplayStockFee } from "../../../data/clearing/stockfees/hooks/useDisplayStockFee";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { StockFee } from "../../../data/clearing/stockfees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayStockFeeMainAttributes } from "./DisplayStockFeeMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,
} from "@material-ui/core";

export interface DisplayStockFeeProps {
  id?: number;
}

export const DisplayStockFee: React.FunctionComponent<DisplayStockFeeProps> = ({
  id,
}) => {
  const stfee = useDisplayStockFee(id);
  const { getString } = React.useContext(LocalizationContext);

  return !stfee ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Typography>{getString("tableBasciInfo")}</Typography>
        </ExpansionPanelSummary>
        <DisplayStockFeeMainAttributes stfee={stfee} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewStockFee = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (stfee: StockFee) => showWithDrawer(<DisplayStockFee id={stfee.id} />),
    [showWithDrawer]
  );
};
