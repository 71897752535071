import { ThunkType } from "../../../../core/store/reducers";
import { getTickerData } from "../api/getTickerData";
import { setTicketData } from "../tickerDataSlice";

export const loadTickerData = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	const { user } = store.data.auth.current;
	const graphData = await dispatch(getTickerData(user));

	dispatch(setTicketData(graphData || []));
};
