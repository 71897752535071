import React from "react";
import { AuthorizationState } from "../../data/auth/types";
import { LocalizationContext } from "../../core/localization/Localization";
import { Alert } from "@material-ui/lab";

export interface LoginErrorProps {
	auth: AuthorizationState;
	className?: string;
}

export const LoginError: React.FunctionComponent<LoginErrorProps> = ({ auth, className }) => {
    const { getErrorString } = React.useContext(LocalizationContext);

	if (auth.status !== "failed") {
		return null;
	}

	return (
		<Alert severity="error" className={className}>
			{getErrorString(auth.error)}
		</Alert>
	);
};
