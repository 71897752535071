import { webApi } from "../../../../core/http/httpThunks";
import { TradingData } from "../types";

export const getTradingDataApi = (userId: string, ptdid: string) =>
  webApi<TradingData>({
    method: "GET",
    url: "clearing/getprodtradingdata",
    params: {
      userId,
      ptdid,
    },
  });

export default getTradingDataApi;
