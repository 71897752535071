import { setFilter, removeFilter } from "../accountingOrderUXSlice";
import { setData } from "../accountingOrderDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { InvoiceFilter } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { invalidateAccOrder } from "../accountingOrderDataSlice";

export const useAccountingOrderFilter = () => {
  const storeFilter = useSelector<StoreType, InvoiceFilter>(
    (store) => store.ux.accounting.accountingOrder.filter
  );
  const [filter, editFilter] = useCopiedState<InvoiceFilter>(storeFilter);

  const dispatch = useDispatch();
  const dispatchSetFilter = React.useCallback(() => {
    dispatch(setFilter(filter!));
    dispatch(setData());
  }, [dispatch, filter]);

  return [filter, editFilter, dispatchSetFilter] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter
  ];
};

export const useCurrentAccountingOrderFilter = () => {
  const filter = useSelector<StoreType, InvoiceFilter>(
    (store) => store.ux.accounting.accountingOrder.filter
  );
  const dispatch = useDispatch();
  const dispatchRemoveFilter = React.useCallback(
    (type: keyof InvoiceFilter) => {
      dispatch(removeFilter(type));
      dispatch(invalidateAccOrder());
    },
    [dispatch]
  );

  return [filter, dispatchRemoveFilter] as [
    typeof filter,
    typeof dispatchRemoveFilter
  ];
};
