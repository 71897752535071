import React from "react";
import { useCommissionValidator } from "../../../data/listing/commissions/hooks/useCommissionValidator";
import { useCommissionUpsert } from "../../../data/listing/commissions/hooks/useCommissionUpsert";
import { Commission, CommissionStandard } from "../../../data/listing/commissions/types";
import { useHistory } from "react-router-dom";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Grid } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { StringInput } from "../../components/common/StringInput";
import { CommissionTypeSelect } from "../../components/common/CommissionTypeSelect";
import { BoolInput } from "../../components/common/BoolInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { commissionLocation } from "./Locations";

export interface EditCommissionBasicInfoProps {
	commission: Commission;
	editCommission: (field: keyof Commission, value: any) => void;
	changed: boolean;
}

export const EditCommissionBasicInfo: React.FunctionComponent<EditCommissionBasicInfoProps> = props => {
	const { getString } = React.useContext(LocalizationContext);

	const { commission, editCommission, changed } = props;
	const [validationState, validate] = useCommissionValidator();
	const [fetchState, upsert] = useCommissionUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editCommission(event.target.name as any, event.target.value);
		},
		[editCommission]
	);

	const save = React.useCallback(() => {
		const save = async () => {
			const normalized = validate(commission);
			if (normalized) {
				const newId = await upsert(normalized);
				if (newId) {
					editCommission("commId", newId);
				}
			}
		};
		if (fetchState.type === "successful") {
			history.push(commissionLocation);
		} else if (fetchState.type !== "started") {
			save();
		}
	}, [commission, validate, fetchState, editCommission, history, upsert]);

	if (!commission) {
		return null;
	}

	return (
		<>
			<Grid container direction="column" justify="center" alignItems="center" spacing={4}>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={6}>
					<Grid item xs={3}>
						<StringInput<Commission>
							property="commName"
							item={commission}
							validationState={validationState}
							onChange={handleChange}
							label={getString("commissionName")}
						/>
					</Grid>
					<Grid item xs={3}>
						<StringInput<Commission>
							property="commNameEn"
							item={commission}
							validationState={validationState}
							onChange={handleChange}
							label={getString("commissionNameEN")}
						/>
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={6}>
					<Grid item xs={3}>
						<CommissionTypeSelect
							label={getString("commissionType")}
							predicate={type => type.ftValue === commission.commType}
							onChangeItem={type => editCommission("commType", type?.ftValue)}
							required={validationState.commType !== undefined}
							error={
								validationState.commType !== undefined &&
								!validationState.commType
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<StringInput<Commission>
							property="commCode"
							item={commission}
							validationState={validationState}
							onChange={handleChange}
							label={getString("commissionCode")}
						/>
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={6}>
					<Grid item xs={3}>
						<BoolInput<Commission>
							property="isStandard"
							item={commission}
							onChange={handleChange}
							falsyValue={CommissionStandard.False}
							thrutyValue={CommissionStandard.True}
							label={getString("commissionStandard")}
						/>
					</Grid>
				</Grid>
			</Grid>
			<FabSave fetchState={fetchState} onClick={save} />
			<LeavePrompt shouldShow={unsavedChanges} />
		</>
	);
};
