import { AntiLaunderingReportFilter } from "../types";
import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

export const getAntiLaunderingExcel = (
  user: User,
  filter: AntiLaunderingReportFilter
) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getantimnylndcommxls",
    params: {
      userid: user.userId,
      datefrom: filter.datefrom,
      dateto: filter.dateto,
      limit: filter.limit,
    },
  });
};

export default getAntiLaunderingExcel;
