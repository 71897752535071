import { ThunkType } from "../../../../core/store/reducers";
import getAllWhsAPI from "../api/getAllWhs";
import { setData } from "../warehousesDataReducer";

export const loadAllWhs = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setData());

  const { userId } = store.data.auth.current.user;
  const whss = await dispatch(getAllWhsAPI(userId));

  dispatch(setData(whss || []));
};
