import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllTradingData } from "../thunks/loadAllTradingData";
import { TradingData } from "../types";
import { PageData } from "../../../models";

export const useTradingDataData = () => {
  const pageData = useSelector<
    StoreType,
    PageData<TradingData> | undefined
  >((s) => s.data.clearing.tradingData.currentPage);

  const dispatch = useDispatch();
  const dispatchLoadTradingDataPage = React.useCallback(
    () => dispatch(loadAllTradingData()),
    [dispatch]
  );

  return [pageData, dispatchLoadTradingDataPage] as [
    typeof pageData,
    typeof dispatchLoadTradingDataPage
  ];
};
