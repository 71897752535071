import React from "react";
import { useUnits } from "../../../data/initialData/hooks/useUnits";
import { Unit } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType } from "./AutocompleteSelect";

type UnitSelectPropsType = Omit<AutocompleteSelectType<Unit>, "data" | "getLabel">;

export const UnitSelect: React.FunctionComponent<UnitSelectPropsType> = props => {
	const [units, renderUnitLabel] = useUnits();

	return (
		<AutocompleteSelect<Unit>
			{...(props as AutocompleteSelectType<Unit>)}
			data={units}
			getLabel={renderUnitLabel}
		/>
	);
};
