import { WeeklyTradingReportFilter } from "../types";
import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

export const getWeeklyClientExcel = (
  user: User,
  filter: WeeklyTradingReportFilter
) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getweeklytradingxls",
    params: {
      userid: user.userId,
      option: "C",
      year: filter.year,
      week: filter.yearPart,
    },
  });
};

export default getWeeklyClientExcel;
