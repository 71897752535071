import { webApi } from "../../../../core/http/httpThunks";
import { DisputeData } from "../types";

export const getDisputeApi = (userId: string, dispid: string) =>
  webApi<DisputeData>({
    method: "GET",
    url: "clearing/getdispsit",
    params: {
      userId,
      dispid,
    },
  });

export default getDisputeApi;
