import { ThunkType } from "../../../../core/store/reducers";
import getDisputeApi from "../api/getDispute";
import { setDisplayed } from "../disputeDataSlice";
import { DisputeData } from "../types";

export const loadDispute = (disputeId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const dispute = await dispatch(getDispute(disputeId));

  dispatch(setDisplayed(dispute));
};

export const getDispute = (
  disputeId: string
): ThunkType<Promise<DisputeData | undefined>> => async (
  dispatch,
  getStore
) => {
  const store = getStore();
  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getDisputeApi(userId, disputeId));
};
