import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { DisplayDrawerContext } from "../../../../layout/DisplayDrawerContext";
import {
  useCommReviewReportFilter,
  useCurrentCommReviewFilter,
} from "../../../../data/reports/exchbusiness/commReview/hooks/useCommReviewReportFilter";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { CustomTooltip } from "../../../components/common/CustomTooltip";
import { CommReviewFilterEditor } from "./CommReviewFilterEditor";
import { CommReviewFilterChips } from "./CommReviewFilterChips";

export const CommReviewReportFilter: React.FunctionComponent = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const showFilter = React.useCallback(
    () => showWithDrawer(<CommReviewsFiltEditor />),
    [showWithDrawer]
  );

  //const [filter, editfilter, save] = useCommReviewReportFilter();
  //const { getString } = React.useContext(LocalizationContext);
  const [filter, removeFilter] = useCurrentCommReviewFilter();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <CommReviewFilterChips filter={filter} removefilter={removeFilter} />
      </Grid>
      <Grid item>
        <CustomTooltip titleKeyword="filterTooltip">
          <IconButton color="secondary" component="span" onClick={showFilter}>
            <FilterIcon />
          </IconButton>
        </CustomTooltip>
      </Grid>
    </Grid>
  );
};

const CommReviewsFiltEditor: React.FunctionComponent = () => {
  const [filter, editFilter, save] = useCommReviewReportFilter();
  return (
    <CommReviewFilterEditor
      filter={filter}
      editFilter={editFilter}
      save={save}
    />
  );
};
