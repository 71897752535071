import { ThunkType } from "../../../../core/store/reducers";
import getOrderApi from "../api/getOrder";
import { Order } from "../types";

export const getOrder = (
  ordId: string
): ThunkType<Promise<Order | undefined>> => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getOrderApi(userId, ordId));
};
