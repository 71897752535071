//import { webApi } from "../../../../core/http/httpThunks";
import { webApiWithResponse } from "../../../../core/http/httpThunks";

export const deleteClientApi = (userId: string, cliid: string, errorExceptions: string[]) =>
	webApiWithResponse<string>({
		method: "DELETE",
		url: "listing/delclient",
		params: {
			userId,
			cliid
		},
	}, undefined, errorExceptions);


export default deleteClientApi;
