import { ThunkType } from "../../../../core/store/reducers";
import getAllTransportFeesAPI from "../api/getAllTransportFees";
import { setTransportFee } from "../transportFeesDataReducer";

export const loadAllTransportFees = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setTransportFee());

  const { userId } = store.data.auth.current.user;
  const stfee = await dispatch(getAllTransportFeesAPI(userId));

  dispatch(setTransportFee(stfee || []));
};
