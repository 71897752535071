import { AnnualTradingReportData, AnnualTradingReportFilter } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getAnnualClientData = (user: User, filter: AnnualTradingReportFilter) => {
  return webApi<AnnualTradingReportData[]>({
    method: "GET",
    url: "reporting/getannualtradinglist",
    params: {
      userid: user.userId,
      option: "C",
      year: filter.year,
    },
  });
};

export default getAnnualClientData;
