import React from "react";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { useClientAccountSideOptions } from "../../../../data/listing/clients/hooks/clientAccount/useClientAccountSide";
import { TextField, MenuItem } from "@material-ui/core";
import { AccountOrderSide } from "../../../../data/listing/clients/types";

interface AccountOrderSideSelectProps {
	side: AccountOrderSide;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AccountOrderSideSelect: React.FunctionComponent<AccountOrderSideSelectProps> = ({
	side,
	handleChange,
}) => {
	const { getString } = React.useContext(LocalizationContext);
    const options = useClientAccountSideOptions();

	return (
		<TextField
			name="forBuySell"
			value={side}
			onChange={handleChange}
			fullWidth
			select
			label={getString("accountForBuySell")}
		>
			{options.map(option => (
				<MenuItem key={option.value} value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</TextField>
	);
};
