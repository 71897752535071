import React from "react"
import { pushInfo, pushwarning } from "../snackbarDataSlice"
import { useDispatch } from "react-redux"
import { v4 } from "uuid";

export const useSnackbarMessageSender = () => {
    const dispatch = useDispatch()
    const dispatchPushInfo = React.useCallback((title: string, message: string[]) => {
            dispatch(pushInfo({
                id: v4(),
                message,
                title 
            }))
    }, [dispatch])

    const dispatchPushWarning = React.useCallback((title: string, message: string[]) => {
        dispatch(pushwarning({
            id: v4(),
            message,
            title 
        }))
}, [dispatch])

return [dispatchPushInfo,dispatchPushWarning] as [typeof dispatchPushInfo,typeof dispatchPushWarning]
}