import { ThunkType } from "../../../../../core/store/reducers";
import { setCODeliveriesData } from "../clientOverviewDataSlice";
import getDeliveries from "../api/getDeliveries";
import { PageDataStatus } from "../../../../models";
import { TableType } from "../types";

export const loadDeliveries = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	const { pageNumber, itemsPerPage } = store.ux.reports.exchbusiness.clientOverview.index[
		TableType.Deliveries
	];
	const { filter } = store.ux.reports.exchbusiness.clientOverview;
	const user = store.data.auth.current.user;

	const deliveriesResponse = await dispatch(getDeliveries(user, pageNumber, itemsPerPage, filter));

	if (!deliveriesResponse || deliveriesResponse.resCode !== "OK") {
		return;
	}

	const delivery = {
		currentPageData: deliveriesResponse.obj,
		totalCount: Number(deliveriesResponse.resDesc) || 0,
		status: PageDataStatus.DataCurrent,
	};

	dispatch(setCODeliveriesData(delivery));
};

