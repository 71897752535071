import { useDispatch, useSelector } from "react-redux";
import { downloadSecurityReport } from "../thunks/downloadReport";
import React from "react";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";

export const useSecurityReportDownloader = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.reports.securityCommission.dailySecurity.downloadReportFetchState
	);
	const dispatch = useDispatch();
	const download = React.useCallback(() => dispatch(downloadSecurityReport()), [dispatch]);

	return [download, fetchState] as [typeof download, typeof fetchState]
};
