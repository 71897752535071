import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Client } from "../types";

export const updateClientApi = (userId: string, client: Client) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/updclient",
		params: {
			userId
		},
		data: client
	});

export default updateClientApi;
