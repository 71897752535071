import React from "react";
import { Grid, CardContent, CardHeader, Divider, Card, Fab } from "@material-ui/core";
import ProductGroupsTable from "./ProductGroupsTable";
import { usePageStyles } from "../../pageStyles";
import { SearchBox } from "../../components/common/SearchBox";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import AddIcon from "@material-ui/icons/Add";
import { useRoleSearch } from "../../../data/listing/roles/hooks/useRoleSearch";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../../components/common/PageTitle";
import { getProductGroupEditLocation } from "./Locations";

const ProductGroupPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [search, setSearch] = useRoleSearch();
	const history = useHistory();

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader
						title={<PageTitle title="productGroupTitle" />}
						action={<SearchBox search={search} setSearch={setSearch} />}
					/>
					<Divider />
					<CardContent>
						<ProductGroupsTable userAccessRights={userAccessRights} />
					</CardContent>
				</Card>
				{userAccessRights === AccessRights.All && (
					<Fab
						color="secondary"
						aria-label="add"
						className={pageStyles.mainFab}
						onClick={() => history.push(getProductGroupEditLocation())}
					>
						<AddIcon />
					</Fab>
				)}
			</Grid>
		</Grid>
	);
};

export default ProductGroupPage;
