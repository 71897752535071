import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { EditOrder } from "../types";

export const oppositeOrderApi = (order: EditOrder) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "order/oppositeorder",
    data: order,
    params: {
      userid: order.userId
    }
  });

export default oppositeOrderApi;
