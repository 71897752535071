import React from "react";
import { useClientPerson } from "./useClientPerson";
import { ClientPerson } from "../../types";
import { Status } from "../../../../../data/models";
import { useCopiedState } from "../../../../../core/util/hooks";

export const createDefaultPerson = (clientId: string): ClientPerson => ({
	cliPrsIdNo: "",
	cliPrsName: "",
	clientId: clientId,
	status: Status.Active,
});

export const useEditClientPerson = (clientId: string, clientPersonId?: string) => {
	const [displayClient] = useClientPerson(clientPersonId);

	return React.useMemo(() => {
		if (clientPersonId !== undefined && displayClient === undefined) {
			return undefined;
        }

		if (clientPersonId === undefined) {
			const defaultPerson = createDefaultPerson(clientId);
			return { ...defaultPerson };
        }

		return displayClient ? { ...displayClient } : undefined;
	}, [clientId, clientPersonId, displayClient]);
};

export const useEditClientPersonState = (clientId: string, clientPersonId?: string) => {
	const person = useEditClientPerson(clientId, clientPersonId);
	return useCopiedState(person)
};
