import { Region, RegionValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";
import { RegType } from "../../../models";

export const regionValidationState: RegionValidationState = {
	regName: true,
	regType: true,
	country: true,
	parentRegId: true,
};

export const useRegionValidator = createUseValidator<Region, RegionValidationState>(
	regionValidationState,
	(newState, validation) => ({
		...validation,
		parentRegId: validation.parentRegId || newState.regType === RegType.TopRegion,
		country: validation.country || newState.regType !== RegType.TopRegion,
	})
);
