import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { delOrder } from "../thunks/delOrderThunk";
import { setDeleteOrderFetchState } from "../../mainBoard/ordersDataSlice";

export const useDeleteOrder = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.trading.mainBoard.deleteOrderFetchState
	);

	const dispatch = useDispatch();
	const dispatchDelOrder = React.useCallback(
		(id: string) => dispatch(delOrder(id)),
		[dispatch]
	);

	React.useEffect(() => {
		return () => {
			dispatch(setDeleteOrderFetchState({ type: "not-started" }))
		}
	}, [dispatch])

	return [fetchState, dispatchDelOrder] as [typeof fetchState, typeof dispatchDelOrder]
};
