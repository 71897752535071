import React from "react";
import { useDisplayCommission } from "./useDisplayCommission";
import { Commission, CommissionStandard } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";

export const defauiltCommission: Commission = {
	commCode: "",
	commName: "",
	isStandard: CommissionStandard.True,
};

export const useEditCommission = (commId?: string) => {
	const displayed = useDisplayCommission(commId);

	return React.useMemo(() => {
		if (commId !== undefined && displayed === undefined) {
			return undefined;
		}

		if (commId === undefined) {
			return { ...defauiltCommission };
		}

		return displayed ? { ...displayed } : undefined;
	}, [commId, displayed]);
};

export const useEditCommissionState = (empId?: string) => {
	const role = useEditCommission(empId);
	return useCopiedState(role);
};
