import { GuaranteeFilter, PayGroup } from "../types";
import { GuaranteeListData, GetGuaranteeParams } from "../types";
import { User } from "../../../auth/types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getGuaranteeWebApi = (
  user: User,
  page: number,
  itemsPerPage: number,
  filter: GuaranteeFilter
) => {
  const params: GetGuaranteeParams = {
    userid: user.userId,
    inout: filter.inout,
    fromrow: page * itemsPerPage,
    numofrows: itemsPerPage,
    cliid: filter?.client?.cliId,
    nondedicated: filter.nondedicated,
    datefrom: getParamsDateString(filter.datefrom),
    dateto: getParamsDateString(filter.dateto),
    paygroup: PayGroup.Guarantees,
  };

  return webApiWithResponse<GuaranteeListData[]>({
    method: "GET",
    url: "clearing/getpaymentlist",
    params,
  });
};

export default getGuaranteeWebApi;
