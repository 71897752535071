import React from "react";
import { useDisplayTransportFee } from "./useDisplayTransportFee";
import { TransportFee } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defaultTransportFee: TransportFee = {
  status: Status.Active,
};

export const useEditTransportFee = (trfeeId?: number) => {
  const displayTransportFee = useDisplayTransportFee(trfeeId);

  return React.useMemo(() => {
    if (trfeeId !== undefined && displayTransportFee === undefined) {
      return undefined;
    }

    if (trfeeId === undefined) {
      return { ...defaultTransportFee };
    }

    return displayTransportFee ? { ...displayTransportFee } : undefined;
  }, [trfeeId, displayTransportFee]);
};

export const useEditTransportFeeState = (trfeeId?: string) => {
  const id = Number(trfeeId);
  const trfee = useEditTransportFee(!!id ? id : undefined);
  return useCopiedState(trfee);
};
