import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { Currency } from "../../../data/listing/currencies/types";

export function useCurrenciesColumns() {
	const { getString } = useContext(LocalizationContext);
	return useMemo(
		() => [
			{
				title: getString("currenciesName"),
				field: "currName",
			},
			{
				title: getString("currenciesCode"),
				field: "currCode",
			},
			{
				title: getString("currenciesNumericCode"),
				field: "numCode",
			},
		],
		[getString]
	) as Column<Currency>[];
}
