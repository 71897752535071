import React from "react";
import { useAccountingOrderButton } from "../../../data/accounting/accountingOrder/hooks/useAccountingOrderButton";
import ExcelIcon from "@material-ui/icons/TableChartOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { LocalizationContext } from "../../../core/localization/Localization";

export const AccountingOrderButton: React.FunctionComponent = () => {
  const [
    downloader,
    fetchState,
    resetData,
    hasData,
  ] = useAccountingOrderButton();
  const { getString } = React.useContext(LocalizationContext);
  return (
    <>
      <CustomTooltip titleKeyword="accountingOrderTooltip">
        <IconButton
          color="secondary"
          component="span"
          onClick={downloader}
          disabled={fetchState.type !== "not-started" || !hasData}
        >
          <ExcelIcon />
        </IconButton>
      </CustomTooltip>
    </>
  );
};
