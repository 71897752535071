import React from "react";
import { Table, TableDataProps } from "../../../components/common/Table";
import { useDetails } from "../../../../data/listing/commissions/hooks/detailes/useDetails";
import { useDetailDelete } from "../../../../data/listing/commissions/hooks/detailes/useDetailDelete";
import { CommissionDetail } from "../../../../data/listing/commissions/types";
import { Column, Action } from "material-table";
import { LocalizationContext } from "../../../../core/localization/Localization";
import EditIcon from "@material-ui/icons/EditOutlined";
import { CommissionEditParts, getCommissionEditLocation } from "../Locations";
import { useHistory } from "react-router-dom";
import { Fab } from "@material-ui/core";
import { usePageStyles } from "../../../pageStyles";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";

export interface CommissionTableProps {
	commissionId: string;
}

type CommissionTablePropsType = CommissionTableProps & TableDataProps<CommissionDetail>;

export const CommissionTable: React.FunctionComponent<CommissionTablePropsType> = ({
	commissionId,
	...tableProps
}) => {
	const pageStyles = usePageStyles();
	const history = useHistory();

	const [data, reload] = useDetails(commissionId);

	const columns = useCommissionColumns();
	const actions = useAttributeActions();
	const onDeleteAttribute = useDeleteCommissionAction();

	return (
		<>
			<Table<CommissionDetail>
				{...tableProps}
				getData={() => {}}
				immutableData={data}
				title=""
				columns={columns}
				actions={actions}
				options={{
					toolbar: false,
					pageSize: 5,
					actionsColumnIndex: -1,
				}}
				editable={{
					onRowDelete: async broker => {
						await onDeleteAttribute(broker);
						reload();
					},
				}}
			/>
			<Fab
				color="secondary"
				aria-label="add"
				className={pageStyles.mainFab}
				onClick={() => history.push(getCommissionEditLocation(CommissionEditParts.Details, commissionId, ""))}
			>
				<AddIcon />
			</Fab>
		</>
	);
};

export default CommissionTable;

const useCommissionColumns = () => {
	const { getString, getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);
	return React.useMemo(
		() => [
			{
				title: getString("commDetailsDate"),
				render: detail => getDateString(detail.fromDate),
				customSort: (a, b) => moment(a.fromDate).diff(b.fromDate),
			},
			{
				title: getString("commDetailsProductGroup"),
				field: "prodGroupName",
			},
			{
				title: getString("commDetailsProduct"),
				field: "prodName",
			},
/*			{
				title: getString("commDetailsCurrency"),
				field: "currCode",
			},   */
			{
				title: getString("commDetailsSellPerc"),
				render: detail => getFormatedNumberString(detail.percSell, true),
				customSort: (a, b) => a.percSell && b.percSell ? a.percSell - b.percSell : 0,
			},
			{
				title: getString("commDetailsBuyPerc"),
				render: detail => getFormatedNumberString(detail.percBuy, true),
				customSort: (a, b) => a.percBuy && b.percBuy ? a.percBuy - b.percBuy : 0,
			},
		],
		[getString, getDateString, getFormatedNumberString]
	) as Column<CommissionDetail>[];
};

const useDeleteCommissionAction = () => {
	const [, deleteDetail] = useDetailDelete();
	return React.useCallback(
		async (attribute: CommissionDetail) => {
			if (attribute.commDetId) {
				await deleteDetail(attribute.commDetId);
			}
		},
		[deleteDetail]
	);
};

export const useAttributeActions = () => {
	const { getString } = React.useContext(LocalizationContext);
	const onEditAttribute = useEditDetailAction();

	return React.useMemo(() => {
		const result: Action<CommissionDetail>[] = [
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditAttribute(rowData as CommissionDetail),
			},
		];

		return result;
	}, [getString, onEditAttribute]);
};

export const useEditDetailAction = () => {
	const history = useHistory();
	return React.useCallback(
		(detail: CommissionDetail) =>
			history.push(
				getCommissionEditLocation(CommissionEditParts.Details, detail.commId, detail.commDetId)
			),
		[history]
	);
};
