import { ThunkType } from "../../../../core/store/reducers";
import { getTradesActive } from "../api/getTradesActive";
import {  TradesSimpleListData } from "../types";

export const getTradesActiveThunk = (): ThunkType<Promise<TradesSimpleListData[] | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const user = store.data.auth.current.user;
	const ordersResponse = await dispatch(getTradesActive(user.userId));


	if (!ordersResponse || ordersResponse.resCode !== "OK") {
		return undefined;
	}
	
	return ordersResponse.obj;
};
