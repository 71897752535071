import { Product, ProductList, ProductSelectData } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";

interface IProductData {
	all?: ProductList[];
	searchTerm: string;
	displayData?: ProductList[];
	displayed?: Product;
	fetchState: FetchStateType;
	fetchStateAttribute: FetchStateType;
	productsForSelect?: ProductSelectData[] | "loading";
	deleteFetchState: FetchStateType;
}

const initialState: IProductData = {
	searchTerm: "",
	fetchState: { type: "not-started" },
	fetchStateAttribute: { type: "not-started" },
	deleteFetchState: { type: "not-started" },
};

const name = "products";

const roleSlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<ProductList[] | undefined>) => ({
			...state,
			all: action.payload,
			displayData: filterData(action.payload, state.searchTerm),
		}),
		setSearchTerm: (state, action: PayloadAction<string>) => ({
			...state,
			searchTerm: action.payload.toLocaleLowerCase(),
			displayData: filterData(state.all, action.payload),
		}),
		setDisplayed: (state, action: PayloadAction<Product | undefined>) => ({
			...state,
			displayed: action.payload,
		}),
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayed: action.payload.type === "successful" ? undefined : state.displayed,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			fetchState: getFetchStateChange(state.fetchState, action.payload),
		}),
		setFetchStateAttribute: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			fetchStateAttribute: getFetchStateChange(state.fetchStateAttribute, action.payload),
		}),
		setProductsForSelect: (state, action: PayloadAction<ProductSelectData[]>) => ({
			...state,
			productsForSelect: action.payload,
		}),
		setProductsForSelectLoading: (state) => ({
			...state,
			productsForSelect: "loading",
		}),
		setDeleteFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayed: action.payload.type === "successful" ? undefined : state.displayed,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			deleteFetchState: getFetchStateChange(state.deleteFetchState, action.payload),
		}),
	},
});

const filterData = (data?: ProductList[], searchTerm?: string) =>
	searchTerm === undefined || searchTerm === ""
		? data
		: data?.filter(
				e =>
					(e.prodName &&
						e.prodName
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
					(e.prodGroupName &&
						e.prodGroupName
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
					(e.vat &&
						e.vat
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
					(e.unit &&
						e.unit
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase()))
		  );

export const {
	setData,
	setSearchTerm,
	setDisplayed,
	setFetchState,
	setFetchStateAttribute,
	setDeleteFetchState,
	setProductsForSelect,
} = roleSlice.actions;

export default roleSlice.reducer;
