import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertInvoice } from "../thunks/upsertInvoice";
import { setFetchState } from "../invoicesDataSlice";
import { InvoiceData } from "../types";

export const useInvoiceUpsert = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.accounting.invoices.fetchState
  );

  const dispatch = useDispatch();
  const dispatchUpsertInvoice = React.useCallback(
    (invoice: InvoiceData) => dispatch(upsertInvoice(invoice)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchUpsertInvoice] as [
    typeof fetchState,
    typeof dispatchUpsertInvoice
  ];
};
