import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { ApiErrorResponse } from "../../../../core/http/types";
import { AlarmData } from "../types";

export const updateAlarmApi = (
	userId: string,
	alarm: AlarmData,
	getError: (error: ApiErrorResponse) => string
) =>
	webApiWithResponse<string>(
		{
			method: "PUT",
			url: "surveillance/updalarmdef",
			params: {
				userId,
			},
			data: alarm,
		},
		getError
	);

export default updateAlarmApi;
