import React from "react";
import { TradingData } from "../../../data/clearing/tradingData/types";
import {
  PaginationTable,
  PaginationDataProps,
} from "../../components/common/PagginationTable";
import {
  useTradingDataActions,
} from "./useTradingDataActions";
import { useTradingDataColumns } from "./useTradingDataColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface TradingDataTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}

type TradingDataTablePropsType = TradingDataTableProps &
  PaginationDataProps<TradingData>;

const TradingDataTable: React.FunctionComponent<TradingDataTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = useTradingDataActions();
  const columns = useTradingDataColumns();
  
  return (
    <PaginationTable<TradingData>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default TradingDataTable;
