import { CtrlGoodsData, CtrlGoodsValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const ctrlGoodsValidationState: CtrlGoodsValidationState = {
  controlDate: true,
  tradeId: true,
};

export const useCtrlGoodsValidator = createUseValidator<
  CtrlGoodsData,
  CtrlGoodsValidationState
>(ctrlGoodsValidationState);
