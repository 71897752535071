import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { usePageStyles } from "../../pageStyles";
import { PageComponent } from "../../../core/navigation/types";
import { ProductGraph } from "./ProductGraph";
import { LocalizationContext } from "../../../core/localization/Localization";

const ChartMob: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();
	const { getString } = useContext(LocalizationContext);

	return (
		<Grid container className={pageStyles.rootSpacing} spacing={2}>
			<Grid item md={4} xs={12}>
				<ProductGraph graphId="0001" />
			</Grid>
		</Grid>
	);
};

export default ChartMob;
