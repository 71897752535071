import { ThunkType } from "../../../../core/store/reducers";
import { printInvoice } from "../api/printInvoice";
import fileDownload from "js-file-download";
import { setPrintInvoiceFetchState } from "../invoicesDataSlice";
import { InvoiceData } from "../types";

export const printInvoiceThunk = (invoice?: InvoiceData): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (
    store.data.auth.current.status !== "successful" ||
    !invoice ||
    !invoice.invId
  ) {
    return undefined;
  }

  //	const filename = `TradingOrder${order.side.trim().toUpperCase()}_${lan.trim().toUpperCase()}_${order.ordNo}.pdf`

  const lan = store.ux.localization.language;
  const filename = `CommInvoice_${lan.trim().toUpperCase()}_${
    invoice.invNo
  }.pdf`;
  const user = store.data.auth.current.user;
  dispatch(setPrintInvoiceFetchState({ type: "started" }));
  const invoicesReport = await dispatch(printInvoice(user, invoice.invId));
  if (invoicesReport) {
    fileDownload(
      new Blob([invoicesReport as any], { type: "application/pdf" }),
      filename
    );
  }
  dispatch(setPrintInvoiceFetchState({ type: "not-started" }));
};
