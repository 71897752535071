import React from "react";
import { useDisplayAlarm } from "../../../data/surveillance/alarmParameters/hooks/useDisplayAlarm";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { AlarmData } from "../../../data/surveillance/alarmParameters/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayAlarmMainAttributes } from "./DisplayAlarmMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,
} from "@material-ui/core";

export interface DisplayAlarmGoodsProps {
  id?: string;
}

export const DisplayAlarm: React.FunctionComponent<DisplayAlarmGoodsProps> = ({
  id,
}) => {
  const alarm = useDisplayAlarm(id);

  const { getString } = React.useContext(LocalizationContext);

  return !alarm ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Typography>{getString("tableBasciInfo")}</Typography>
        </ExpansionPanelSummary>
        <DisplayAlarmMainAttributes alarm={alarm} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewAlarm = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (alarm: AlarmData) =>
      showWithDrawer(<DisplayAlarm id={alarm.almId} />),
    [showWithDrawer]
  );
};
