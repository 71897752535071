import React from "react";
import { GuaranteeData } from "../../../data/clearing/guarantees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayGuaranteeMainAttributesProps {
  guarantee: GuaranteeData;
}

export const DisplayGuaranteeMainAttributes: React.FunctionComponent<DisplayGuaranteeMainAttributesProps> = ({
  guarantee,
}) => {
  const style = useDisplaySingleEmementStyles();
  const {
    getString,
    getDateString,
    getFormatedNumberString,
  } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("paymentDataTime")}</span>
        <span className={style.value}>{getDateString(guarantee.payDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("paymentClientCode")}</span>
        <span className={style.value}>{guarantee.clientIdNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("paymentClientName")}</span>
        <span className={style.value}>{guarantee.clientName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("paymentPayType")}</span>
        <span className={style.value}>{guarantee.payTypeDescr}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("paymentClmOrdNo")}</span>
        <span className={style.value}>{guarantee.orderNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("paymentTranNo")}</span>
        <span className={style.value}>{guarantee.tranNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("paymentBankGuarantee")}</span>
        <span className={style.value}>{guarantee.grntNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("paymentAmount")}</span>
        <span className={style.value}>
          {getFormatedNumberString(guarantee.amt, true)}
        </span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("paymentCurrCode")}</span>
        <span className={style.value}>{guarantee.currCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("paymentAmtBal")}</span>
        <span className={style.value}>{getFormatedNumberString(guarantee.amtBal, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("paymentAmtBalNonAlloc")}</span>
        <span className={style.value}>{getFormatedNumberString(guarantee.amtBal, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("paymentStatus")}</span>
        <span className={style.value}>
          <DisplayBoolNumber
            value={guarantee.status}
            thrutyValue={Status.Active}
            className={style.icon}
          />
        </span>
      </div>
    </div>
  );
};
