import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../../models";
import { QuarterlySecurityReportData } from "./types";

type TradesReportData = {
	data?: QuarterlySecurityReportData[];
	downloadReportFetchState: FetchStateType;
};

const initialState: TradesReportData = {
	downloadReportFetchState: { type: "not-started" },
};

const name = "quarterlySecurity";

const quarterlySecuritySlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<QuarterlySecurityReportData[] | undefined>) => ({
			...state,
			data: action.payload,
		}),
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			downloadReportFetchState: action.payload,
		}),
	},
});

export const { setData, setFetchState } = quarterlySecuritySlice.actions;

export default quarterlySecuritySlice.reducer;
