import React from "react";
import { useWeeklyTradesColumns } from "./useWeeklyTradingReportColumns";
import { AccessRights } from "../../../core/navigation/types";
import { ControledTable } from "../../components/common/ControledTable";
import { WeeklyTradingReportData } from "../../../data/reports/weeklyTrade/types";
import { useTradesReportData } from "../../../data/reports/weeklyTrade/hooks/useTradesReportData";
import { useWeeklyTradesReportIndex } from "../../../data/reports/weeklyTrade/hooks/useWeeklyTradesReportIndex";
import { useWeeklyActions } from "./useWeeklyActions";

export interface WeeklyTradesTableProps {
  userAccessRights: AccessRights;
}

type WeeklyTradesTablePropsType = WeeklyTradesTableProps;

const WeeklyTradesReportTable: React.FunctionComponent<WeeklyTradesTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const actions = useWeeklyActions();
  const columns = useWeeklyTradesColumns();

  const [data, load] = useTradesReportData();
  const [index, setIndex] = useWeeklyTradesReportIndex();

  return (
    <ControledTable<WeeklyTradingReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default WeeklyTradesReportTable;
