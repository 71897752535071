import { webApi } from "../../../../core/http/httpThunks";
import { BankGuaranteeData } from "../types";

export const getBankGuaranteeApi = (userId: string, grntid: string) =>
  webApi<BankGuaranteeData>({
    method: "GET",
    url: "clearing/getbankgrnt",
    params: {
      userId,
      grntid,
    },
  });

export default getBankGuaranteeApi;
