import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { ApiErrorResponse } from "../../../../core/http/types";
import { AlarmData } from "../types";

export const createAlarmApi = (
	userId: string,
	alarm: AlarmData,
	getError: (error: ApiErrorResponse) => string
) =>
	webApiWithResponse<string>(
		{
			method: "PUT",
			url: "surveillance/newalarmdef",
			params: {
				userId,
			},
			data: {
				...alarm,
				userId,
			},
		},
		getError
	);

export default createAlarmApi;
