import { AttributeValue } from "../../types";
import { ValidationState } from "../../../../../core/util/types";
import { createUseValidator } from "../../../../../core/util/hooks";

export const AttributeValueValidation: ValidationState<AttributeValue> = {
	name: true,
	nameen: true,
	value: true,
	valueen: true,
};

export const useAttributeValueValidator = createUseValidator<AttributeValue, ValidationState<AttributeValue>>(
	AttributeValueValidation
);
