import { TradingDataFilter  } from "../types";
import { TradingData, GetTradingDataParams } from "../types";
import { User } from "../../../auth/types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getTradingDataWebApi = (
  user: User,
  page: number,
  itemsPerPage: number,
  filter: TradingDataFilter
) => {
  const params: GetTradingDataParams = {
    userid: user.userId,
    fromrow: page * itemsPerPage,
    numofrows: itemsPerPage,
    prodid: filter?.product?.prodId ,
    datefrom: getParamsDateString(filter.datefrom),
    dateto: getParamsDateString(filter.dateto),
  };

  return webApiWithResponse<TradingData[]>({
    method: "GET",
    url: "clearing/getprodtradingdatalist",
    params,
  });
};

export default getTradingDataWebApi;
