import { TradeByProductsReportFilter } from "../types";
import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

export const getTradeByProductsExcel = (
  user: User,
  filter: TradeByProductsReportFilter
) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/gettradebyprodxls",
    params: {
      userid: user.userId,
      datefrom: filter.datefrom,
      dateto: filter.dateto,
    },
  });
};

export default getTradeByProductsExcel;
