import { useDispatch, useSelector } from "react-redux";
import { thunkPSTM } from "../thunks/thunkPSTM";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";

export const useOrderPSTM = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.trading.orderBook.orderPSTM
	);
	const dispatch = useDispatch();
	const setPSTM = React.useCallback(() => dispatch(thunkPSTM()), [dispatch]);

	return [setPSTM, fetchState] as [typeof setPSTM, typeof fetchState]
};
