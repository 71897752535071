import { useCopiedState } from "../../../../../core/util/hooks";
import { setFilter, removeFilter, initialState } from "../clientOverviewUXSlice";
import { invalidateData } from "../clientOverviewDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import React from "react";
import { ClientOverviewFilter } from "../types";

export const useClientOverviewFilter = () => {
	const [storeFilter] = useCurrentClientOverviewFilter();
	const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<ClientOverviewFilter>(
		storeFilter
	);

	const dispatch = useDispatch();
	const dispatchSetFilter = React.useCallback(() => {
		dispatch(setFilter(filter!));
		dispatch(invalidateData());
	}, [dispatch, filter]);

	const dispatchResetFilter = React.useCallback(() => {
		setCurrentFilter(initialState.filter);
	}, [setCurrentFilter]);

	return [filter, editFilter, dispatchSetFilter, changeState, dispatchResetFilter] as [
		typeof filter,
		typeof editFilter,
		typeof dispatchSetFilter,
		typeof changeState,
		typeof dispatchResetFilter
	];
};

export const useCurrentClientOverviewFilter = () => {
	const filter = useSelector<StoreType, ClientOverviewFilter>(
		store => store.ux.reports.exchbusiness.clientOverview.filter
	);
	const dispatch = useDispatch();
	const dispatchRemoveFilter = React.useCallback(
		(type: keyof ClientOverviewFilter) => {
			dispatch(removeFilter(type));
			dispatch(invalidateData());
		},
		[dispatch]
	);

	return [filter, dispatchRemoveFilter] as [typeof filter, typeof dispatchRemoveFilter];
};
