import React from "react";
import { PageComponent } from "../../core/navigation/types";

export const UnderConstruction: PageComponent = ({ userAccessRights }) => {
	return (
		<>
			<div>{`Under construction`}</div>
			<div>{`User access rights: ${userAccessRights}`}</div>
		</>
	);
};
