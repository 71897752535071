import { ClientOverviewFilter, GetClientOverviewParams } from "../types";
import { TradeList } from "../../../../trading/trades/types";
import { User } from "../../../../auth/types";
import { webApiWithResponse } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

const getTradesWebApi = (
  user: User,
  page: number,
  itemsPerPage: number,
  filter: ClientOverviewFilter
) => {
  const params: GetClientOverviewParams = {
    userid: user.userId,
    fromrow: page * itemsPerPage,
    numofrows: itemsPerPage,
    cliid: filter.client?.cliId,
    datefrom: getParamsDateString(filter.datefrom),
    dateto: getParamsDateString(filter.dateto),
    status: filter.tradeStatus?.ftValue,
  };


  return webApiWithResponse<TradeList[]>({
    method: "GET",
    url: "trade/gettradelist",
    params,
  });
};
export default getTradesWebApi;
