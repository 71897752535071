import CreateInvoicesPage from "./CreateInvoicesPage";
import { NavigationItem } from "../../../core/navigation/types";
import { createInvoicesLocation } from "./Locations";

export const createInvoicesComponents: NavigationItem[] = [
  {
    Component: CreateInvoicesPage,
    isMenuItem: true,
    path: createInvoicesLocation,
  },
];
