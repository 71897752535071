import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { City } from "../../../data/listing/cities/types";
import React from "react";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export function useCityColumns() {
  const { getString } = useContext(LocalizationContext);
  return useMemo(
    () => [
      {
        title: getString("cityName"),
        field: "cityName",
      },
      {
        title: getString("cityZipCode"),
        field: "zipCode",
      },
      {
        title: getString("cityRegion"),
        field: "regionName",
      },
      {
				title: getString("cityIsPort"),
				render: city => <DisplayBoolNumber value={city.isPort} thrutyValue={Status.Active} />,
				customSort: (a, b) => a.isPort - b.isPort,
				width: "80px",
			},
    ],
    [getString]
  ) as Column<City>[];
}
