import { ClientContract } from "../../types";
import { ValidationState } from "../../../../../core/util/types";
import { createUseValidator } from "../../../../../core/util/hooks";

export const ContractValidation: ValidationState<ClientContract> = {
	contrNo: true,
	contrDate: true,
	contrType: true,
};

/*export const useClientContractValidator = createUseValidator<ClientContract, ValidationState<ClientContract>>(
	ContractValidation
);  */


export const useClientContractValidator = createUseValidator<ClientContract, ValidationState<ClientContract>>(
	ContractValidation,
	(item, validation) => ({
		...validation,
		linkedContrNo:	 item.contrType!==15 || (item.contrType===15 &&  item.linkedContrId !== undefined &&  item.linkedContrId !== ""),
	})
);  
