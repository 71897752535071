import { ThunkType } from "../../../../core/store/reducers";
import { cancelOrderApi } from "../api/cancelOrder";
import { setCancelOrderFetchState, invalidateOrders } from "../../mainBoard/ordersDataSlice";

export const cancelOrder = (orderId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setCancelOrderFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(cancelOrderApi(userId, orderId));

	if (result !== undefined)
	{
		dispatch(invalidateOrders());
		dispatch(setCancelOrderFetchState({ type: "successful" }));
	} else {
		dispatch(setCancelOrderFetchState({ type: "error"}));
	}
};
