import { ThunkType } from "../../../../core/store/reducers";
import deleteGuaranteeApi from "../api/deleteGuarantees";
import { setFetchState, invalidateGuarantee } from "../guaranteesDataSlice";

export const deleteGuarantee = (payId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = await dispatch(deleteGuaranteeApi(userId, payId));

  dispatch(
    setFetchState(
      result !== undefined ? { type: "successful" } : { type: "error" }
    )
  );
  dispatch(invalidateGuarantee());
};
