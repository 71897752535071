import TradeByProductsReport from "./TradeByProductsReport";
import { NavigationItem } from "../../../../core/navigation/types";
import { tradeByProductsLocation } from "./Locations";

export const tradeByProductsReportComponents: NavigationItem[] = [
  {
    Component: TradeByProductsReport,
    isMenuItem: true,
    path: tradeByProductsLocation,
  },
];
