import { ThunkType } from "../../../../core/store/reducers";
import getAllRolesAPI from "../api/getAllRoles";
import { setData } from "../rolesDataReducer";

export const loadAllRoles = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setData());

	const { userId } = store.data.auth.current.user;
	const roles = await dispatch(getAllRolesAPI(userId));

	dispatch(setData(roles || []));
};
