import React from "react";
import { useDisplayCurrency } from "../../../data/listing/currencies/hooks/useDisplayCurrency";
import { Currency } from "../../../data/listing/currencies/types";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayCurrencyBasicInfo } from "./DisplayCurrencyBasicInfo";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid } from "@material-ui/core";

export interface DisplayCurrencyProps {
	id?: string;
}

export const DisplayCurrency: React.FunctionComponent<DisplayCurrencyProps> = ({ id }) => {
	const currency = useDisplayCurrency(id);
	const { getString } = React.useContext(LocalizationContext);

	return !currency ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel expanded>
				<ExpansionPanelSummary>
					<Typography>{getString("tableBasciInfo")}</Typography>
				</ExpansionPanelSummary>
				<DisplayCurrencyBasicInfo currency={currency} />
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewCurrency = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(currency: Currency) => showWithDrawer(<DisplayCurrency id={currency.currId} />),
		[showWithDrawer]
	);
};
