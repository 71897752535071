import { NavigationItem } from "../../../core/navigation/types";
import PaymentsPage from "./PaymentsPage";
import { paymentLocation  } from "./Locations";

export const paymentClientComponents: NavigationItem[] = [
  {
    Component: PaymentsPage,
    isMenuItem: true,
    path: paymentLocation,
  },
];
