import { TradesSimpleListData } from "../types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";

export const getTradesActive = (userId: string) => {
	return webApiWithResponse<TradesSimpleListData[]>({
		method: "GET",
		url: "trade/gettradesimplelist",
		params: {
			userid: userId,
		},
	});
};
