import { combineReducers, AnyAction } from "@reduxjs/toolkit";
import alarms from "./alarmParameters/alarmDataSlice";
import { resetLoginState } from "../auth/authDataSlice";

const surveillanceDataReducer = combineReducers({
	alarms,
});

export default (state: any, action: AnyAction) => {
	if (action.type === resetLoginState.type) {
		return surveillanceDataReducer(undefined, action);
	} else {
		return surveillanceDataReducer(state, action);
	}
};
