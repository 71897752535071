import React from "react";
import { CurrencyRate } from "../../../data/listing/curencyRates/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { useCurrency } from "../../../data/initialData/hooks/useCurrencies";

export interface DisplayCurrencyBasicInfoProps {
	rate: CurrencyRate;
}

export const DisplayCurrencyBasicInfo: React.FunctionComponent<DisplayCurrencyBasicInfoProps> = ({
	rate,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString, getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);
	const [currency] = useCurrency(rate.currId)
	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("curRateCurrency")}</span>
				<span className={style.value}>{currency?.currName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("curRateDate")}</span>
				<span className={style.value}>{getDateString(rate.date)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("curRateMid")}</span>
				<span className={style.value}>{getFormatedNumberString(rate.midRate, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("curRateBuy")}</span>
				<span className={style.value}>{getFormatedNumberString(rate.buyRate, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("curRateSell")}</span>
				<span className={style.value}>{getFormatedNumberString(rate.sellRate, true)}</span>
			</div>
		</div>
	);
};
