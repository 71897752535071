import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertCommission } from "../thunks/upsertCommission";
import { setFetchState } from "../commissionsDataReducer";
import { Commission } from "../types";

export const useCommissionUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.commissions.fetchState
	);

	const dispatch = useDispatch();
	const dispatchUpsertRole = React.useCallback(
		(commision: Commission) => dispatch(upsertCommission(commision)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchState({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchUpsertRole] as [typeof fetchState, typeof dispatchUpsertRole]
};
