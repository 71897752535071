import { ClientOverviewFilter, GetClientOverviewParams } from "../types";
import { ViewOrder } from "../../../../trading/orders/types";
import { User } from "../../../../auth/types";
import { webApiWithResponse } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

const getOrdersWebApi = (
  user: User,
  page: number,
  itemsPerPage: number,
  filter: ClientOverviewFilter,
) => {
  const params: GetClientOverviewParams = {
    userid: user.userId,
    fromrow: page * itemsPerPage,
    numofrows: itemsPerPage,
    cliid: filter.client?.cliId,
    datefrom: getParamsDateString(filter.datefrom),
    dateto: getParamsDateString(filter.dateto),
    status: filter.orderStatus?.ftValue
  };

  return webApiWithResponse<ViewOrder[]>({
    method: "GET",
    url: "order/getorderlist",
    params,
  });
};

export default getOrdersWebApi;
