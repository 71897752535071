import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import { DeliveryListData } from "../../../../../data/clearing/delivery/types";
import { LocalizationContext } from "../../../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewDelivery } from "../../../../clearing/delivery/DisplayDelivery";

export function useDeliveriesActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowDelivery = useViewDelivery();

  return useMemo(() => {
    const result: MaterialTableProps<DeliveryListData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) =>
          onShowDelivery(rowData as DeliveryListData),
      },
    ];
    return result;
  }, [onShowDelivery, getString]);
}
