import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { InvTypes } from "../../models";

const getTypeLabel = (type?: InvTypes) => (type ? type.ftDescr : "");

export const useInvoiceType = () => {
  const types = useSelector<StoreType, InvTypes[]>(
    (store) => store.data.initialData.invTypes
  );

  return [types, getTypeLabel] as [typeof types, typeof getTypeLabel];
};
