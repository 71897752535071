import { TransSimpleData, GetSimpleTransParams, SimpleTransFilter } from "../types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";

export const getSimpleTrades = (userid: string, filter: SimpleTransFilter) => {
	const params: GetSimpleTransParams = {
		userid,
		...filter,
	};

	return webApiWithResponse<TransSimpleData[]>({
		method: "GET",
		url: "trade/gettransimplelist",
		params,
	});
};
