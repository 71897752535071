import { setFilter, removeFilter } from "../createInvoicesUXSlice";
import { setData } from "../createInvoicesDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { CreateInvoicesTranFilter } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { invalidateCreateInvoice } from "../createInvoicesDataSlice";

export const useCreateInvoicesFilter = () => {
  const storeFilter = useSelector<StoreType, CreateInvoicesTranFilter>(
    (store) => store.ux.accounting.createInvoices.filter
  );
  const [filter, editFilter] = useCopiedState<CreateInvoicesTranFilter>(
    storeFilter
  );

  const dispatch = useDispatch();
  const dispatchSetFilter = React.useCallback(() => {
    dispatch(setFilter(filter!));
    dispatch(setData());
  }, [dispatch, filter]);

  return [filter, editFilter, dispatchSetFilter] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter
  ];
};

export const useCurrentCreateInvoicesFilter = () => {
  const filter = useSelector<StoreType, CreateInvoicesTranFilter>(
    (store) => store.ux.accounting.createInvoices.filter
  );
  const dispatch = useDispatch();
  const dispatchRemoveFilter = React.useCallback(
    (type: keyof CreateInvoicesTranFilter) => {
      dispatch(removeFilter(type));
      dispatch(invalidateCreateInvoice());
    },
    [dispatch]
  );

  return [filter, dispatchRemoveFilter] as [
    typeof filter,
    typeof dispatchRemoveFilter
  ];
};
