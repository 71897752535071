import { webApi } from "../../../../core/http/httpThunks";
import { UserListData } from "../types";

export const getAllUsersApi = (userId: string) =>
	webApi<UserListData[]>({
		method: "GET",
		url: "listing/getuserlist",
		params: {
			userId,
		},
	});

export default getAllUsersApi;
