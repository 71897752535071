import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllAttributes } from "../thunks/loadAllAttributes";
import { Attribute } from "../types";

export const useAttributeData = () => {
	const data = useSelector<StoreType, Attribute[] | undefined>(s => s.data.listing.attributes.displayData);
	const allData = useSelector<StoreType, Attribute[] | undefined>(s => s.data.listing.attributes.all);

	const dispatch = useDispatch();
	const dispatchLoadAll = React.useCallback(() => dispatch(loadAllAttributes()), [dispatch]);

	return [data, dispatchLoadAll, allData] as [
		typeof data,
		typeof dispatchLoadAll,
		typeof allData
	];
};
