import React from "react";
import { useMTReportClientColumns } from "./useMTReportClientColumns";
import { AccessRights } from "../../../core/navigation/types";
import { TradeList } from "../../../data/trading/trades/types";
import { Table, TableDataProps } from "../../components/common/Table";
import { MonthlyTradingReportData } from "../../../data/reports/monthlyTrade/types";
import { useReportClientData } from "../../../data/reports/monthlyTrade/hooks/useReportClientData";
import { useMonthlyActions } from "./useMonthlyActions";

export interface MonthlyTradesTableProps {
  userAccessRights: AccessRights;
}

type MonthlyTradesTablePropsType = MonthlyTradesTableProps &
  TableDataProps<TradeList>;

const MTRClientTable: React.FunctionComponent<MonthlyTradesTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useMTReportClientColumns();
  const [data, load, reset] = useReportClientData();
  React.useEffect(() => reset, [reset]);

  return (
    <Table<MonthlyTradingReportData>
      {...tableProps}
      getData={load}
      immutableData={data}
      title=""
      columns={columns}
      //      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default MTRClientTable;
