import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";

export const downloadNews = (user: User, newsid: string) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "news/getnewsreport",
    params: {
      userid: user.userId,
      newsid,
    },
  });
};

export default downloadNews;
