import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteDispute } from "../thunks/deleteDispute";
import { setFetchState } from "../disputeDataSlice";

export const useDisputeDelete = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.clearing.disputes.cancelFetchState
  );

  const dispatch = useDispatch();
  const dispatchDeleteDispute = React.useCallback(
    (id: string) => dispatch(deleteDispute(id)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchDeleteDispute] as [
    typeof fetchState,
    typeof dispatchDeleteDispute
  ];
};
