import WeeklyTradesReport from "./WeeklyTradesReport";
import { NavigationItem } from "../../../core/navigation/types";
import {
  weeklyTradesReportLocation,
  weeklyProductLocation,
  weeklyClientLocation,
  weeklyDateLocation,
} from "./Locations";
import WTRProductPage from "./WTRProductPage";
import WTRClientPage from "./WTRClientPage";
import WTRDatePage from "./WTRDatePage";

export const weeklyTradesReportComponents: NavigationItem[] = [
  {
    Component: WeeklyTradesReport,
    isMenuItem: true,
    path: weeklyTradesReportLocation,
  },
  {
    Component: WTRProductPage,
    isMenuItem: false,
    path: weeklyProductLocation,
  },
  {
    Component: WTRClientPage,
    isMenuItem: false,
    path: weeklyClientLocation,
  },
  {
    Component: WTRDatePage,
    isMenuItem: false,
    path: weeklyDateLocation,
  },
];
