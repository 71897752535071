import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertCurrencyRate } from "../thunks/upsertCurrencyRate";
import { setFetchState } from "../currencyRatesDataReducer";
import { CurrencyRate } from "../types";

export const useCurrencyRateUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.currencyRates.fetchState
	);

	const dispatch = useDispatch();
	const dispatchUpsert = React.useCallback(
		(product: CurrencyRate) => dispatch(upsertCurrencyRate(product)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchState({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchUpsert] as [typeof fetchState, typeof dispatchUpsert]
};
