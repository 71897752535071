import React from "react";
import { useEditInvoiceState } from "../../../data/accounting/invoices/hooks/useEditInvoiceState";
import { useInvoiceValidator } from "../../../data/accounting/invoices/hooks/useInvoiceValidator";
import { useInvoiceUpsert } from "../../../data/accounting/invoices/hooks/useInvoicesUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import {
  Card,
  Grid,
  CardHeader,
  Divider,
  CardContent,
} from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { InvoiceData } from "../../../data/accounting/invoices/types";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { Status, OrderFilter } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { invoicesLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { NumberInput } from "../../components/common/NumberInput";
import { DateInput } from "../../components/common/DateInput";
import { CurrencySelect } from "../../components/common/CurrencySelect";
import { SimpleTransFilter } from "../../../data/trading/transactions/types";
import { StringInput } from "../../components/common/StringInput";
import { FiltredTransactionsSelect } from "../../components/trading/FiltredTransactionsSelect";
import { InvoiceTypeSelect } from "../../components/common/InvoiceTypeSelect";

export interface EditInvoiceInfo {
  invId?: string;
}

export interface EditInvoiceProps
  extends RouteComponentProps<EditInvoiceInfo> {}

export const EditInvoicePage: React.FunctionComponent<EditInvoiceProps> = (
  props
) => {
  const { getString } = React.useContext(LocalizationContext);
  const pageStyles = usePageStyles();

  const [invoice, editInvoice, changed] = useEditInvoiceState(
    props.match.params.invId
  );
  const [validationState, validate] = useInvoiceValidator();
  const [fetchState, upsert] = useInvoiceUpsert();
  const history = useHistory();
  const [tradesFilter, setTransactionFilter] = React.useState<
    SimpleTransFilter | undefined
  >(createInvoiceFilter(invoice));

  React.useEffect(() => {
    setTransactionFilter(createInvoiceFilter(invoice));
  }, [invoice, setTransactionFilter]);

  const unsavedChanges = fetchState.type === "not-started" && changed;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      editInvoice(event.target.name as any, event.target.value);
    },
    [editInvoice]
  );

  const save = React.useCallback(() => {
    if (fetchState.type === "successful") {
      history.push(invoicesLocation);
    } else if (fetchState.type !== "started") {
      const normalized = validate(invoice);

      if (normalized) {
        upsert(normalized);
      }
    }
  }, [invoice, validate, fetchState, history, upsert]);

  const pageTitle = invoice && invoice.invId ? "invoiceEdit" : "invoiceCreate";

  if (!invoice) {
    return null;
  }

  return (
    <Grid container className={pageStyles.root}>
      <Grid item xs={12} className={pageStyles.gridItem}>
        <Card>
          <CardHeader
            title={
              <PageTitle title={pageTitle} backLocation={invoicesLocation} />
            }
          />
          <Divider />
          <CardContent>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={3}>
                <DateInput
                  label={getString("invoiceInvDate")}
                  value={invoice.invDate || null}
                  onChange={(date) => editInvoice("invDate", date)}
                  error={
                    validationState.invDate !== undefined &&
                    !validationState.invDate
                  }
                  required={validationState.invDate !== undefined}
                />
              </Grid>
              <Grid item xs={3}>
                <DateInput
                  label={getString("invoiceTranDate")}
                  value={invoice.tranDate || null}
                  onChange={(date) => editInvoice("tranDate", date)}
                  error={
                    validationState.tranDate !== undefined &&
                    !validationState.tranDate
                  }
                  required={validationState.tranDate !== undefined}
                />
              </Grid>
              <Grid item xs={3}>
                <DateInput
                  label={getString("invoiceDueDate")}
                  value={invoice.dueDate || null}
                  onChange={(date) => editInvoice("dueDate", date)}
                  error={
                    validationState.dueDate !== undefined &&
                    !validationState.dueDate
                  }
                  required={validationState.dueDate !== undefined}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={2}>
                <StringInput<InvoiceData>
                  property="invNo"
                  item={invoice}
                  validationState={validationState}
                  onChange={handleChange}
                  label={getString("invoiceInvNo")}
                />
              </Grid>
              <Grid item xs={3}>
                <InvoiceTypeSelect
                  label={getString("commissionType")}
                  predicate={(type) => type.ftValue === invoice.invType}
                  onChangeItem={(type) => editInvoice("invType", type?.ftValue)}
                  required={validationState.invType !== undefined}
                  error={
                    validationState.invType !== undefined &&
                    !validationState.invType
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <StringInput<InvoiceData>
                  property="descr"
                  item={invoice}
                  validationState={validationState}
                  onChange={handleChange}
                  label={getString("invoiceDescr")}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={6}>
                <ClientSelect
                  label={getString("invoiceClientName")}
                  predicate={(client) => client.cliId === invoice.clientId}
                  onChangeItem={(client) => {
                    editInvoice("clientId", client ? client.cliId : undefined);
                  }}
                  required={validationState.clientId !== undefined}
                  error={
                    validationState.clientId !== undefined &&
                    !validationState.clientId
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <FiltredTransactionsSelect
                  label={getString("invoiceTranNo")}
                  filter={tradesFilter}
                  predicate={(trans) => trans.ordId === invoice.tranId}
                  onChangeItem={(trans) => {
                    editInvoice("tranId", trans ? trans.ordId : undefined);
                  }}
                  required={validationState.tranId !== undefined}
                  error={
                    validationState.tranId !== undefined &&
                    !validationState.tranId
                  }
                />
              </Grid>

              <Grid
                container
                item
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={3}>
                  <NumberInput<InvoiceData>
                    property="tradeAmtWithVat"
                    item={invoice}
                    validationState={validationState}
                    onChange={handleChange}
                    label={getString("invoiceTradeAmtWithVat")}
                    money
                  />
                </Grid>
{/*                <Grid item xs={3}>
                  <NumberInput<InvoiceData>
                    property="tradeAmt"
                    item={invoice}
                    validationState={validationState}
                    onChange={handleChange}
                    label={getString("invoiceTradeAmt")}
                    money
                  />
                </Grid>  */}
                <Grid item xs={3}>
                  <CurrencySelect
                    label={getString("paymentCurrCode")}
                    predicate={(currency) => currency.currId === invoice.currId}
                    onChangeItem={(currency) =>
                      editInvoice("currId", currency?.currId)
                    }
                    required={validationState.currId !== undefined}
                    error={
                      validationState.currId !== undefined &&
                      !validationState.currId
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={2}>
                  <NumberInput<InvoiceData>
                    property="commPerc"
                    item={invoice}
                    validationState={validationState}
                    onChange={handleChange}
                    label={getString("invoiceCommPerc")}
                    money
                  />
                </Grid>
                <Grid item xs={2}>
                  <NumberInput<InvoiceData>
                    property="amt"
                    item={invoice}
                    validationState={validationState}
                    onChange={handleChange}
                    label={getString("invoiceAmt")}
                    money
                  />
                </Grid>
                <Grid item xs={1}>
                  <NumberInput<InvoiceData>
                    property="vatPerc"
                    item={invoice}
                    validationState={validationState}
                    onChange={handleChange}
                    label={getString("invoiceVatPerc")}
                    money
                  />
                </Grid>
                <Grid item xs={2}>
                  <NumberInput<InvoiceData>
                    property="vatAmt"
                    item={invoice}
                    validationState={validationState}
                    onChange={handleChange}
                    label={getString("invoiceVatAmt")}
                    money
                  />
                </Grid>
                <Grid item xs={2}>
                  <NumberInput<InvoiceData>
                    property="invAmt"
                    item={invoice}
                    validationState={validationState}
                    onChange={handleChange}
                    label={getString("invoiceInvAmt")}
                    money
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={3}>
                  <StringInput<InvoiceData>
                    property="cmpAccount"
                    item={invoice}
                    validationState={validationState}
                    onChange={handleChange}
                    label={getString("invoiceCmpAccount")}
                  />
                </Grid>
                <Grid item xs={1}>
                  <StringInput<InvoiceData>
                    property="model"
                    item={invoice}
                    validationState={validationState}
                    onChange={handleChange}
                    label={getString("invoiceModel")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <StringInput<InvoiceData>
                    property="reffNo"
                    item={invoice}
                    validationState={validationState}
                    onChange={handleChange}
                    label={getString("invoiceReffNo")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <DateInput
                    label={getString("invoiceBookDate")}
                    value={invoice.bookDate || null}
                    onChange={(date) => editInvoice("bookDate", date)}
                    error={
                      validationState.bookDate !== undefined &&
                      !validationState.bookDate
                    }
                    required={validationState.bookDate !== undefined}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={1}>
                  <BoolInput<InvoiceData>
                    property="status"
                    item={invoice}
                    onChange={handleChange}
                    falsyValue={Status.Inactive}
                    thrutyValue={Status.Active}
                    label={getString("paymentStatus")}
                  />
                </Grid>
              </Grid>
            </Grid>

            <FabSave fetchState={fetchState} onClick={save} />
            <LeavePrompt shouldShow={unsavedChanges} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const createInvoiceFilter = (invoice?: InvoiceData) => {
  return invoice && invoice.clientId
    ? {
        cliid: invoice.clientId,
      }
    : undefined;
};
