import React from "react";
import { usePersonRoles } from "../../../data/initialData/hooks/usePersonRoles";
import { PersonRole } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType } from "./AutocompleteSelect";

type ClientPersonRoleSelectProps = Omit<AutocompleteSelectType<PersonRole>, "data" | "getLabel">;

export const ClientPersonRoleSelect: React.FunctionComponent<ClientPersonRoleSelectProps> = props => {
	const [roles, renderRoleLabel] = usePersonRoles();

	return (
		<AutocompleteSelect<PersonRole>
			{...(props as AutocompleteSelectType<PersonRole>)}
			data={roles}
			getLabel={renderRoleLabel}
		/>
	);
};
