import React from "react";
import { Warehouse } from "../../../data/listing/warehouses/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayWhsMainAttributesProps {
  whs: Warehouse;
}

export const DisplayWhsMainAttributes: React.FunctionComponent<DisplayWhsMainAttributesProps> = ({
  whs,
}) => {
  const style = useDisplaySingleEmementStyles();
  const { getString, getFormatedNumberString } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("whsCode")}</span>
        <span className={style.value}>{whs.whsCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("whsName")}</span>
        <span className={style.value}>{whs.whsName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("cliCity")}</span>
        <span className={style.value}>{whs.city}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("cliAddress")}</span>
        <span className={style.value}>{whs.address}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("whsPort")}</span>
        <span className={style.value}>{whs.portName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("whsDistanceToPort")}</span>
        <span className={style.value}>
          {getFormatedNumberString(whs.distanceToPort, true)}
        </span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("cliStatus")}</span>
        <span className={style.value}>
          <DisplayBoolNumber
            value={whs.status}
            thrutyValue={Status.Active}
            className={style.icon}
          />
        </span>
      </div>
    </div>
  );
};
