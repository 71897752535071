import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { Role } from "../../../data/listing/roles/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewRole } from "./DisplayRole";
import { useRoleDelete } from "../../../data/listing/roles/hooks/useRoleDelete";
import { getRoleEditLocation } from "./Locations";
import { AccessRights } from "../../../core/navigation/types";

export const useRoleActions = (userAccessRights: AccessRights) => {
	const { getString } = useContext(LocalizationContext);
	const onShowRole = useViewRole();
	const onEditRole = useEditRoleAction();

	return useMemo(() => {
		const result: Action<Role>[] = [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tableShow"),
				onClick: (event, rowData) => onShowRole(rowData as Role),
			},
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditRole(rowData as Role),
				hidden: userAccessRights===AccessRights.ReadOnly
			},
		];

		return result;
	}, [onShowRole, onEditRole, getString]);
};

export const useDeleteRoleAction = () => {
	const [, deleteEmployee] = useRoleDelete();
	return React.useCallback(
		async (role: Role) => {
			if (role.roleId) {
				await deleteEmployee(role.roleId);
			}
		},
		[deleteEmployee]
	);
};

export const useEditRoleAction = () => {
	const history = useHistory();
	return React.useCallback(
		(role: Role) => history.push(getRoleEditLocation(role.roleId)),
		[history]
	);
};
