import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllBankGuarantees } from "../thunks/loadAllBankGuarantees";
import { BankGuaranteeData } from "../types";
import { PageData } from "../../../models";
import { invalidateBankGuarantee } from "../bankguaranteesDataSlice";

export const useBankGuaranteeData = () => {
  const pageData = useSelector<
    StoreType,
    PageData<BankGuaranteeData> | undefined
  >((s) => s.data.clearing.bankguarantees.currentPage);

  const dispatch = useDispatch();
  const dispatchLoadBankGuaranteePage = React.useCallback(
    () => dispatch(loadAllBankGuarantees()),
    [dispatch]
  );

  const invalidate = React.useCallback(() => dispatch(invalidateBankGuarantee()), [dispatch]);

  return [pageData, dispatchLoadBankGuaranteePage, invalidate] as [
    typeof pageData,
    typeof dispatchLoadBankGuaranteePage,
    typeof invalidate,
  ];
};
