import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewProduct } from "./DisplayProduct";
import { useProductDelete } from "../../../data/listing/products/hooks/useProductDelete";
import { ProductList } from "../../../data/listing/products/types";
import { ProductEditParts, getProductEditLocation } from "./Locations";
import { AccessRights } from "../../../core/navigation/types";

export const useProductActions = (userAccessRights: AccessRights) => {
	const { getString } = useContext(LocalizationContext);
	const onShowClient = useViewProduct();
	const onEditClient = useEditClientAction();

	return useMemo(() => {
		const result: Action<ProductList>[] = [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tableShow"),
				onClick: (event, rowData) => onShowClient(rowData as ProductList),
			},
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditClient(rowData as ProductList),
				hidden: userAccessRights===AccessRights.ReadOnly
			},
		];

		return result;
	}, [onShowClient, getString, onEditClient]);
};

export const useDeleteClientAction = () => {
	const [fetchState, deleteProduct, reset] = useProductDelete();
	const action = React.useCallback(
		async (product: ProductList) => {
			if (product.prodId) {
				await deleteProduct(product.prodId);
			}
		},
		[deleteProduct]
	);
	return [fetchState, action, reset] as [typeof fetchState, typeof action, typeof reset];
};

export const useEditClientAction = () => {
	const history = useHistory();
	return React.useCallback(
		(product: ProductList) =>
			history.push(getProductEditLocation(ProductEditParts.BasicInfo, product.prodId)),
		[history]
	);
};
