import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { UserData } from "../types";

export const updateUserApi = (userId: string, user: UserData) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/upduser",
		params: {
			userId
		},
		data: user
	});

export default updateUserApi;
