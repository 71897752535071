import React from "react";
import { useEditTransportFeeState } from "../../../data/clearing/transportfees/hooks/useEditTransportFeeState";
import { useTransportFeeValidator } from "../../../data/clearing/transportfees/hooks/useTransportFeeValidator";
import { useTransportFeeUpsert } from "../../../data/clearing/transportfees/hooks/useTransportFeeUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import {
  Card,
  Grid,
  CardHeader,
  Divider,
  CardContent,
} from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { TransportFee } from "../../../data/clearing/transportfees/types";
import { StringInput } from "../../components/common/StringInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { Status } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { transportFeeLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { DateInput } from "../../components/common/DateInput";
import { NumberInput } from "../../components/common/NumberInput";

export interface EditTransportFeeInfo {
  id?: string;
}

export interface EditTransportFeeProps
  extends RouteComponentProps<EditTransportFeeInfo> {}

export const EditTransportFeePage: React.FunctionComponent<EditTransportFeeProps> = (
  props
) => {
  const { getString } = React.useContext(LocalizationContext);
  const pageStyles = usePageStyles();

  const [transportFee, editTransportFee, changed] = useEditTransportFeeState(
    props.match.params.id
  );
  const [validationState, validate] = useTransportFeeValidator();
  const [fetchState, upsert] = useTransportFeeUpsert();
  const history = useHistory();

  const unsavedChanges = fetchState.type === "not-started" && changed;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      editTransportFee(event.target.name as any, event.target.value);
    },
    [editTransportFee]
  );

  const save = React.useCallback(() => {
    if (fetchState.type === "successful") {
      history.push(transportFeeLocation);
    } else if (fetchState.type !== "started") {
      const normalized = validate(transportFee);
      if (normalized) {
        upsert(normalized);
      }
    }
  }, [transportFee, validate, fetchState, history, upsert]);

  const pageTitle =
    transportFee && transportFee.id ? "transFeeEdit" : "transFeeCreate";

  if (!transportFee) {
    return null;
  }

  return (
    <Grid container className={pageStyles.root}>
      <Grid item xs={12} className={pageStyles.gridItem}>
        <Card>
          <CardHeader
            title={
              <PageTitle
                title={pageTitle}
                backLocation={transportFeeLocation}
              />
            }
          />
          <Divider />
          <CardContent>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={2}>
                <DateInput
                  label={getString("transFeeStartDate")}
                  value={transportFee.startDate || null}
                  onChange={(date) => editTransportFee("startDate", date)}
                  error={
                    validationState.startDate !== undefined &&
                    !validationState.startDate
                  }
                  required={validationState.startDate !== undefined}
                />
              </Grid>
              <Grid item xs={3}>
                <NumberInput<TransportFee>
                  property="distance"
                  item={transportFee}
                  validationState={validationState}
                  onChange={handleChange}
                  label={getString("transFeeDistance")}
                  money
                />
              </Grid>
              <Grid item xs={3}>
                <NumberInput<TransportFee>
                  property="price"
                  item={transportFee}
                  validationState={validationState}
                  onChange={handleChange}
                  label={getString("transFeePice")}
                  money
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={1}>
                <BoolInput<TransportFee>
                  property="status"
                  item={transportFee}
                  onChange={handleChange}
                  falsyValue={Status.Inactive}
                  thrutyValue={Status.Active}
                  label={getString("transFeeStatus")}
                />
              </Grid>
            </Grid>

            <FabSave fetchState={fetchState} onClick={save} />
            <LeavePrompt shouldShow={unsavedChanges} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
