import { setReportData } from "../weeklyTradeUXSlice";
import { setData } from "../weeklyTradeDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { WeeklyTradingReportFilter } from "../types";
import { setReportWeek, setReportYear } from "../weeklyTradeUXSlice";

export const useCurrentTradesReportFilter = () => {
  const filter = useSelector<StoreType, WeeklyTradingReportFilter>(
    (store) => store.ux.reports.weeklyTrade.filter
  );
  const dispatch = useDispatch();
  const dispatchSetReportDate = React.useCallback(
    (reportDate: number) => {
      dispatch(setReportData(reportDate));
      dispatch(setData());
    },
    [dispatch]
  );
  const dispatchSetReportWeek = React.useCallback(
    (week: number) => {
      dispatch(setReportWeek(week));
      dispatch(setData());
    },
    [dispatch]
  );
  const dispatchSetReportYear = React.useCallback(
    (year: number) => {
      dispatch(setReportYear(year));
      dispatch(setData());
    },
    [dispatch]
  );

  return [
    filter,
    dispatchSetReportDate,
    dispatchSetReportWeek,
    dispatchSetReportYear,
  ] as [
    typeof filter,
    typeof dispatchSetReportDate,
    typeof dispatchSetReportWeek,
    typeof dispatchSetReportYear
  ];
};
