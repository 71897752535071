import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { OrderFilter } from "../../../data/models";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useOrderSideOptionsTranslator } from "../../../data/trading/orders/hooks/useOrderSideOptions";
import { useOrderMarketOptionsTranslator } from "../../../data/trading/orders/hooks/useOrderMarketOptions";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const OrderFilterChipsComponent: React.FunctionComponent<OrderFilterChipsProps> = ({
	filter,
	remnoveFilter,
}) => {
	const { getDateString, getString } = React.useContext(LocalizationContext);
	const sideTranslator = useOrderSideOptionsTranslator();
	const marketTranslator = useOrderMarketOptionsTranslator();

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	return (
		<Grid container spacing={1}>
			{filter.side && !screenExtraSmall &&  (
				<Grid item>
					<Chip
						label={sideTranslator(filter.side)}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("side")}
					/>
				</Grid>
			)}
			{ filter.market !== undefined &&  typeof(filter.market) == 'number' && !screenExtraSmall &&   (
				<Grid item>
					<Chip
						label={marketTranslator(filter.market)}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("market")}
					/>
				</Grid>
			)} 
			{filter.fromDate && !screenExtraSmall &&  (
				<Grid item>
					<Chip
						label={`${getString("filterFrom")}: ${getDateString(new Date(filter.fromDate))}`}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("fromDate")}
					/>
				</Grid>
			)}
			{filter.toDate && !screenExtraSmall &&  (
				<Grid item>
					<Chip
						label={`${getString("filterTo")}: ${getDateString(new Date(filter.toDate))}`}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("toDate")}
					/>
				</Grid>
			)}
			{filter.product && !screenExtraSmall &&  (
				<Grid item>
					<Chip
						label={filter.product.prodName}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("product")}
					/>
				</Grid>
			)}
			{filter.client && !screenExtraSmall &&  (
				<Grid item>
					<Chip
						label={filter.client.cliName}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("client")}
					/>
				</Grid>
			)}
			{filter.orderStatus && filter.orderStatus.ftDescr !== "" && !screenExtraSmall &&  (
				<Grid item>
					<Chip
						label={filter.orderStatus.ftDescr}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("orderStatus")}
					/>
				</Grid>
			)}
			{filter.employee && !screenExtraSmall &&  (
				<Grid item>
					<Chip
						label={`${filter.employee.firstName} ${filter.employee.lastName}`}
						color="secondary"
						variant="outlined"
						onDelete={() => remnoveFilter("employee")}
					/>
				</Grid>
			)}
		</Grid>
	);
};

interface OrderFilterChipsProps {
	filter: OrderFilter;
	remnoveFilter: (type: keyof OrderFilter) => void;
}
