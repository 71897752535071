import React from "react";
import { TradeList } from "../../../data/trading/trades/types";
import {
  PaginationTable,
  PaginationDataProps,
} from "../../components/common/PagginationTable";
import { useTTASCostsActions } from "./useTTASCostsActions";
import { useTradeColumns } from "../../trading/trades/useTradeColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface TradeTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}

type TradeTablePropsType = TradeTableProps & PaginationDataProps<TradeList>;

const TradesTable: React.FunctionComponent<TradeTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = useTTASCostsActions();
  const columns = useTradeColumns();

  return (
    <PaginationTable<TradeList>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default TradesTable;
