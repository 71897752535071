import React from "react";
import {
  Grid,
  CardContent,
  CardHeader,
  Divider,
  Card,
  Fab,
} from "@material-ui/core";
import RegionsTable from "./RegionsTable";
import { usePageStyles } from "../../pageStyles";
import { SearchBox } from "../../components/common/SearchBox";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import AddIcon from "@material-ui/icons/Add";
import { useRegionSearch } from "../../../data/listing/regions/hooks/useRegionSearch";
import { useHistory } from "react-router-dom";
import { useRegionType } from "../../../data/listing/regions/hooks/useRegionType";
import { getRegionsEditLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { RegType } from "../../../data/models";

const RegionsPage: PageComponent = ({ userAccessRights }) => {
  const pageStyles = usePageStyles();

  useRegionType(RegType.Region);

  const [search, setSearch] = useRegionSearch(RegType.Region);
  const history = useHistory();

  return (
    <Grid container className={pageStyles.root}>
      <Grid item xs={12} className={pageStyles.gridItem}>
        <Card>
          <CardHeader
            title={<PageTitle title={"navRegion"} /> }
            action={<SearchBox search={search} setSearch={setSearch} />}
          />
          <Divider />
          <CardContent>
            <RegionsTable userAccessRights={userAccessRights} />
          </CardContent>
        </Card>
        {userAccessRights === AccessRights.All && (
          <Fab
            color="secondary"
            aria-label="add"
            className={pageStyles.mainFab}
            onClick={() => history.push(getRegionsEditLocation())}
          >
            <AddIcon />
          </Fab>
        )}
      </Grid>
    </Grid>
  );
};

export default RegionsPage;
