import { ThunkType } from "../../../../core/store/reducers";
import { updateTASCosts } from "../api/updateTASCosts";
import { TransportAndStorageCosts } from "../types";
import { setSaveCalcFetchState } from "../TASDataSlice";

export const saveTASCostsThunk = (cost: TransportAndStorageCosts): ThunkType => async (
	dispatch,
	getStore
) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setSaveCalcFetchState({ type: "started" }));

	const user = store.data.auth.current.user;
	await dispatch(updateTASCosts(user.userId, cost));

	dispatch(setSaveCalcFetchState({ type: "successful" }));
};
