import { ThunkType } from "../../../../core/store/reducers";
import getPaymentApi from "../api/getPayment";
import { setDisplayed } from "../paymentsDataSlice";
import { PaymentData } from "../types";

export const loadPayment = (payId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const payment = await dispatch(getPayment(payId));

  dispatch(setDisplayed(payment));
};

export const getPayment = (
  payId: string
): ThunkType<Promise<PaymentData | undefined>> => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getPaymentApi(userId, payId));
};
