import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { UserData } from "../types";
import { loadRole } from "../thunks/loadUser";

export const useDisplayUser = (userId?: string) => {
	const storeUser = useSelector<StoreType, UserData | undefined>(
		store => store.data.listing.users.displayedUser
    );

    const displayedUser = storeUser && storeUser.userId === userId ? storeUser : undefined;

    const displatch = useDispatch();

	React.useEffect(() => {
		if (userId) {
			displatch(loadRole(userId));
		}
    }, [displatch, userId]);

	return displayedUser;
};
