import React from "react";
import { useDisplayEmployee } from "./useDisplayEmployee";
import { Employee } from "../types";
import { Gender } from "../../../models";
import { Status } from "../../../../data/models";
import { useCopiedState } from "../../../../core/util/hooks";

export const defauiltEmployee: Employee = {
	address: "",
	branch: "",
	city: "",
	email: "",
	empIdNumber: "",
	fax: "",
	firstName: "",
	midName: "",
	lastName: "",
	gender: Gender.Male,
	phone: "",
	phoneOffice: "",
	position: "",
	zipCode: "",
	status: Status.Active,
};

export const useEditEmployee = (empId?: string) => {
	const displayEmployee = useDisplayEmployee(empId);

	return React.useMemo(() => {
		if (empId !== undefined && displayEmployee === undefined) {
			return undefined;
        }

		if (empId === undefined) {
			return { ...defauiltEmployee };
        }

		return displayEmployee ? { ...displayEmployee } : undefined;
	}, [empId, displayEmployee]);
};

export const useEditEmployeeState = (empId?: string) => {
	const employee = useEditEmployee(empId);
	return useCopiedState(employee)
};
