import React from "react";
import { useDisplayBank } from "./useDisplayBank";
import { Bank } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defaultBank: Bank = {
  bankCode: "",
  bankName: "",
  idNumber: "",
  status: Status.Active,
};

export const useEditBank = (bankId?: string) => {
  const displayBank = useDisplayBank(bankId);

  return React.useMemo(() => {
    if (bankId !== undefined && displayBank === undefined) {
      return undefined;
    }

    if (bankId === undefined) {
      return { ...defaultBank };
    }

    return displayBank ? { ...displayBank } : undefined;
  }, [bankId, displayBank]);
};

export const useEditBankState = (bankId?: string) => {
  const bank = useEditBank(bankId);
  return useCopiedState(bank);
};
