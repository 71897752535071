import { ThunkType } from "../../../../../core/store/reducers";
import deleteAttributeValueApi from "../../api/attributeValues/deleteAttributeValue";
import { setFetchStateValue } from "../../attributeDataReducer";

export const deleteAttributeValue = (attributeId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchStateValue({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteAttributeValueApi(userId, attributeId));

	dispatch(setFetchStateValue(result !== undefined ? { type: "successful" } : { type: "error"}));
};
