import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllNews } from "../thunks/loadAllNews";
import { NewsData } from "../types";
import { PageData } from "../../../models";

export const useNewsData = () => {
  const pageData = useSelector<
    StoreType,
    PageData<NewsData> | undefined
  >((s) => s.data.reports.news.currentPage);

  const dispatch = useDispatch();
  const dispatchLoadNewsPage = React.useCallback(
    () => dispatch(loadAllNews()),
    [dispatch]
  );

  return [pageData, dispatchLoadNewsPage] as [
    typeof pageData,
    typeof dispatchLoadNewsPage
  ];
};
