import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PageData,
  PageDataStatus,
  FetchStateType,
  getFetchStateChange,
} from "../../models";
import { DeliveryListData, DeliveryData } from "./types";

type DeliveriesData = {
  all?: DeliveryData[];
  displayData?: DeliveryData[];
  currentPage?: PageData<DeliveryListData>;
  displayedDelivery?: DeliveryData;
  fetchState: FetchStateType;
  cancelFetchState: FetchStateType;
  downloadReportFetchState: FetchStateType;
  printReportFetchState: FetchStateType;
};

const initialState: DeliveriesData = {
  cancelFetchState: { type: "not-started" },
  fetchState: { type: "not-started" },
  downloadReportFetchState: { type: "not-started" },
  printReportFetchState:{ type: "not-started" },
};

const name = "deliveries";

const deliveriesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setDelivery: (
      state,
      action: PayloadAction<PageData<DeliveryListData>>
    ) => ({
      ...state,
      currentPage: action.payload,
    }),

    setDisplayed: (state, action: PayloadAction<DeliveryData | undefined>) => ({
      ...state,
      displayedDelivery: action.payload,
    }),

    invalidateDelivery: (state) => {
      if (!state.currentPage) return state;

      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          status: PageDataStatus.DataInvalidated,
        },
      };
    },

    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      displayedDelivery:
        action.payload.type === "successful"
          ? undefined
          : state.displayedDelivery,
      all: action.payload.type === "successful" ? undefined : state.all,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: getFetchStateChange(state.fetchState, action.payload),
      downloadReportFetchState: action.payload,
    }),
    setCancelDeliveryFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      cancelFetchState: action.payload,
    }),
    setPrintDeliveryFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      printReportFetchState: action.payload,
    }),
  },
});

export const {
  setDelivery,
  setDisplayed,
  invalidateDelivery,
  setFetchState,
  setCancelDeliveryFetchState,
  setPrintDeliveryFetchState,
} = deliveriesSlice.actions;

export default deliveriesSlice.reducer;
