import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertRole } from "../thunks/upsertRole";
import { setFetchState } from "../rolesDataReducer";
import { Role } from "../types";

export const useRoleUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.roles.fetchState
	);

	const dispatch = useDispatch();
	const dispatchUpsertRole = React.useCallback(
		(product: Role) => dispatch(upsertRole(product)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchState({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchUpsertRole] as [typeof fetchState, typeof dispatchUpsertRole]
};
