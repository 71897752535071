import React from "react";
import { useDispatch } from "react-redux";
import { AttributeValue } from "../../types";
import { loadAttributeValues } from "../../thunks/attributeValues/loadAttributeValues";

export const useAttributeValuesData = (attributeId?: string) => {
	const displatch = useDispatch();
	const [values, setValues] = React.useState<AttributeValue[] | undefined>();

	const loadAttributes = React.useCallback(async () => {
		if (attributeId) {
			const loadedValues = (await displatch(loadAttributeValues(attributeId))) as any;
			setValues(loadedValues || []);
		}
	}, [displatch, attributeId])

	React.useEffect(() => {
		loadAttributes();
	}, [loadAttributes]);

	return [values, loadAttributes] as [typeof values, typeof loadAttributes]
};
