import { ThunkType } from "../../../../core/store/reducers";
import createRoleApi from "../api/createRole";
import updateRoleApi from "../api/updateRole";
import { setFetchState } from "../rolesDataReducer";
import { Role } from "../types";

export const upsertRole = (role: Role): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = role.roleId
		? await dispatch(updateRoleApi(userId, role))
		: await dispatch(createRoleApi(userId, role));

	if (result && result.resCode === "OK") {
		dispatch(setFetchState({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
	}
};
