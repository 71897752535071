import { Employee, EmployeeValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const employeeValidation: EmployeeValidationState = {
	email: true,
	empIdNumber: true,
	firstName: true,
	gender: true,
	lastName: true,
}

export const useEmployeeValidator = createUseValidator<Employee, EmployeeValidationState>(employeeValidation);
