import { ThunkType } from "../../../../../core/store/reducers";
import deleteAttributeApi from "../../api/attributes/deleteAttribute";
import { setFetchStateAttribute } from "../../productsDataReducer";

export const deleteAttribute = (attributeId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchStateAttribute({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteAttributeApi(userId, attributeId));

	dispatch(setFetchStateAttribute(result !== undefined ? { type: "successful" } : { type: "error"}));
};
