import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useMTRFilter } from "../../../data/reports/monthlyTrade/hooks/useMTRFilter";
import {
  MTRFilterEditor,
  MTRFilterEditorType,
} from "../monthlyTrades/MTRFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const MTRFilterButton: React.FunctionComponent = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const showFilter = React.useCallback(
    () => showWithDrawer(<MTRsFilterEditor />),
    [showWithDrawer]
  );

  return (
    <CustomTooltip titleKeyword="filterTooltip">
      <IconButton color="secondary" component="span" onClick={showFilter}>
        <FilterIcon />
      </IconButton>
    </CustomTooltip>
  );
};

const MTRsFilterEditor: React.FunctionComponent = () => {
  const [filter, editFilter, save, , reset] = useMTRFilter();
  return (
    <>
      {filter && (
        <MTRFilterEditor
          filter={filter}
          editFilter={editFilter}
          save={save}
          type={MTRFilterEditorType.Basic}
          reset={reset}
        />
      )}
    </>
  );
};
