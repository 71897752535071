import React from "react";
import { useCountries } from "../../../data/initialData/hooks/useCountries";
import {
  AutocompleteSelect,
  AutocompleteSelectType,
} from "./AutocompleteSelect";
import { Country } from "../../../data/listing/countries/types";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<Country>,
  "data" | "getLabel"
>;

export const CountriesSelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [countries, renderCountryLabel] = useCountries();

  return (
    <AutocompleteSelect<Country>
      data={countries}
      getLabel={renderCountryLabel}
      {...(props as any)}
    />
  );
};
