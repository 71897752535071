import React from "react";
import { useDispatch } from "react-redux";
import { ClientPerson } from "../../types";
import { getClientPersons } from "../../thunks/clientPerson/loadClientPersons";

export const useClientPersons = (clientId?: string) => {
	const displatch = useDispatch();
	const [clientPersons, setClientPersons] = React.useState<ClientPerson[] | undefined>();

	const loadClientPersons = React.useCallback(async () => {
		if (clientId) {
			const loadedClientBroker = (await displatch(getClientPersons(clientId))) as any;
			setClientPersons(loadedClientBroker || []);
		}
	}, [displatch, clientId]);

	React.useEffect(() => {
		loadClientPersons();
	}, [loadClientPersons]);

	return [clientPersons, loadClientPersons] as [typeof clientPersons, typeof loadClientPersons];
};
