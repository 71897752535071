import { ThunkType } from "../../../../core/store/reducers";
import getCurrencyApi from "../api/getCurrency";
import { setDisplayed } from "../currenciesDataReducer";
import { Currency } from "../types";

export const loadCurrency = (roleId: string): ThunkType => async (dispatch, getStore) => {
	dispatch(setDisplayed());

	const role = await dispatch(getCurrency(roleId));

	dispatch(setDisplayed(role));
};

export const getCurrency = (roleId: string): ThunkType<Promise<Currency | undefined>> => async (
	dispatch,
	getStore
) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getCurrencyApi(userId, roleId));
};
