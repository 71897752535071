import { useDispatch, useSelector } from "react-redux";
import { downloadDeliveryReportExcel } from "../thunks/downloadDeliveryReportExcel";
import React from "react";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";

export const useDeliveryExcelReportDownloader = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.reports.exchbusiness.clientOverview.fetchState
	);
	const dispatch = useDispatch();
	const download = React.useCallback(() => dispatch(downloadDeliveryReportExcel()), [dispatch]);

	return [download, fetchState] as [typeof download, typeof fetchState];
};
