import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { DisputeData } from "../types";
import { loadDispute } from "../thunks/loadDispute";

export const useDisplayDispute = (dispId?: string) => {
  const storeDispute = useSelector<StoreType, DisputeData | undefined>(
    (store) => store.data.clearing.disputes.displayedDispute
  );

  const displayedDispute =
    storeDispute && storeDispute.dispSitId === dispId
      ? storeDispute
      : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (dispId) {
      displatch(loadDispute(dispId));
    }
  }, [displatch, dispId]);

  return displayedDispute;
};
