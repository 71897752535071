import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { VatRate } from "../../models";

const getUnitLabel = (vat?: VatRate) => (vat ? vat.ftDescr : "");

export const useVatRates = () => {
	const vatRates = useSelector<StoreType, VatRate[]>(store => store.data.initialData.vatRates);

	return [vatRates, getUnitLabel] as [typeof vatRates, typeof getUnitLabel];
};
