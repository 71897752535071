import { ThunkType } from "../../../../core/store/reducers";
import getRoleApi from "../api/getRole";
import { setDisplayed } from "../rolesDataReducer";
import { Role } from "../types";

export const loadRole = (roleId: string): ThunkType => async (dispatch, getStore) => {
	dispatch(setDisplayed());

	const role = await dispatch(getRole(roleId));

	dispatch(setDisplayed(role));
};

export const getRole = (roleId: string): ThunkType<Promise<Role | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getRoleApi(userId, roleId));
}
