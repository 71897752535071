import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { TransportFee } from "../types";

export const updateTransportFeeApi = (userId: string, trfee: TransportFee) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "clearing/updtransfee",
    params: {
      userId,
    },
    data: trfee,
  });

export default updateTransportFeeApi;
