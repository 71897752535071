import React from "react";
import { Grid, CardContent, CardHeader, Divider, Card, Fab } from "@material-ui/core";
import UserTable from "./UserTable";
import { usePageStyles } from "../../pageStyles";
import { SearchBox } from "../../components/common/SearchBox";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import AddIcon from "@material-ui/icons/Add";
import { useUserSearch } from "../../../data/listing/users/hooks/useUserSearch";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../../components/common/PageTitle";
import { getUserEditLocation } from "./Locations";

const UserPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [search, setSearch] = useUserSearch();
	const history = useHistory();

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader
						title={<PageTitle title="navUsers"/>}
						action={<SearchBox search={search} setSearch={setSearch} />}
					/>
					<Divider />
					<CardContent>
						<UserTable userAccessRights={userAccessRights} />
					</CardContent>
				</Card>
				{userAccessRights === AccessRights.All && (
					<Fab color="secondary" aria-label="add" className={pageStyles.mainFab} onClick={() => history.push(getUserEditLocation())}>
						<AddIcon />
					</Fab>
				)}
			</Grid>
		</Grid>
	);
};

export default UserPage;
