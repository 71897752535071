import { webApiWithResponse } from "../../../../../core/http/httpThunks";
import { ClientBroker } from "../../types";

export const createClientBrokerApi = (userId: string, broker: ClientBroker) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/newclibrok",
		params: {
			userId
		},
		data: broker
	});

export default createClientBrokerApi;
