export const annualTradesReportLocation = "/reports/annualreports";

export const getReportLocation = (basePath: string, year: number) => {
  return `${basePath}/${year}`;
};

export const annualProductLocationBase = "/reports/annualreports/products";
export const annualClientLocationBase = "/reports/annualreports/client";
export const annualDateLocationBase = "/reports/annualreports/date";

export const annualProductLocation = `${annualProductLocationBase}/:year`;
export const annualClientLocation = `${annualClientLocationBase}/:year`;
export const annualDateLocation = `${annualDateLocationBase}/:year`;

export const getProductLocation = (year: number) =>
  getReportLocation(annualProductLocationBase, year);
export const getClientLocation = (year: number) =>
  getReportLocation(annualClientLocationBase, year);
export const getDateLocation = (year: number) =>
  getReportLocation(annualDateLocationBase, year);
