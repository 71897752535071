import { TradeByClientsReportData } from "../../../../data/reports/exchbusiness/tradeByClients/types";
import React from "react";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Column } from "material-table";

export const useTradeByClientsColumns = () => {
  const { getString, getFormatedNumberString } = React.useContext(
    LocalizationContext
  );

  return React.useMemo(
    () => [
      {
        title: getString("exchClient"),
        field: "client",
        sorting: false,
      },
      {
        title: getString("exchTurnoverValue"),
        render: (row) => `${getFormatedNumberString(row.turnoverValue, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchCommissionValue"),
        render: (row) =>
          `${getFormatedNumberString(row.commissionValue, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchTurnoverParticipation"),
        render: (row) =>
          `${getFormatedNumberString(row.turnoverParticipations, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchCommissionParticipation"),
        render: (row) =>
          `${getFormatedNumberString(row.commissionParticipations, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
    ],
    [getString, getFormatedNumberString]
  ) as Column<TradeByClientsReportData>[];
};
