import { webApiWithResponse } from "../../../../../core/http/httpThunks";
import { ProductAttribute } from "../../types";

export const updateClientBrokerApi = (userId: string, attribute: ProductAttribute) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/updprodattr",
		params: {
			userId
		},
		data: attribute
	});

export default updateClientBrokerApi;
