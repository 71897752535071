import { Theme, makeStyles, createStyles } from "@material-ui/core";

export const useDisplaySingleEmementStyles = makeStyles((theme: Theme) => {
	return createStyles({
		root: {
			width: "100%",
			// paddingTop: "2rem",
			paddingBottom: "2rem",
			// height: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
		row: {
			display: "flex",
			justifyContent: "space-between",
//			width: "80%",

			width: "70%",
			padding: "5px",
		},
		rowBorder: {
			borderBottom: `1px ${theme.palette.divider} solid`,
		},
		label: {
			color: theme.palette.action.active,
		},
		value: {
			marginLeft: "10px",
		},
		icon: {
			height: "1rem",
		},
	});
});
