import { setFilter } from "../businessActivitiesUXSlice";
import { setData } from "../businessActivitiesDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import React from "react";
import { BusinessActivitiesReportFilter } from "../types";
import { useCopiedState } from "../../../../../core/util/hooks";

export const useBusinessActivitiesReportFilter = () => {
  const storeFilter = useSelector<StoreType, BusinessActivitiesReportFilter>(
    (store) => store.ux.reports.exchbusiness.businessActivities.filter
  );
  const [filter, editFilter] = useCopiedState<BusinessActivitiesReportFilter>(
    storeFilter
  );

  const dispatch = useDispatch();
  const dispatchSetFilter = React.useCallback(() => {
    dispatch(setFilter(filter!));
    dispatch(setData());
  }, [dispatch, filter]);

  return [filter, editFilter, dispatchSetFilter] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter
  ];
};
