import React from "react";
import { Paper, Grid, Button } from "@material-ui/core";
import { DateInput } from "../../components/common/DateInput";
import { ProductSelect } from "../../components/listing/ProductSelect";
import { LocalizationContext } from "../../../core/localization/Localization";
import { TradeStatusSelect } from "../../components/common/TradeStatusSelect";
import { isValidDate } from "../../../core/util/functions";
import { TradeFilter } from "../../../data/trading/trades/types";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { OrderMarketSelect } from "../../trading/orders/OrderMarketSelect";
import { useAuth } from "../../../data/auth/hooks";

export interface TradesFilterEditorProps {
	filter?: TradeFilter;
	editFilter: (prop: keyof TradeFilter, value: any) => void;
	save: () => void;
	reset: () => void;
}

export const TradesFilterEditor: React.FunctionComponent<TradesFilterEditorProps> = ({
	filter,
	editFilter,
	save,
	reset,
}) => {
	const { getString } = React.useContext(LocalizationContext);
	const showWithDrawer = React.useContext(DisplayDrawerContext);

	const [auth] = useAuth();

	if (auth.status !== "successful") {
		return null;
	}

	return (
		<Paper
			style={{
				paddingLeft: "70px",
				paddingRight: "70px",
				paddingTop: "15px",
				paddingBottom: "15px",
			}}
		>
			<Grid container direction="column" spacing={6}>
				<Grid item container direction="row" spacing={2}>
					<Grid item xs={12} sm={6}>
						<DateInput
							label={getString("tradeFilterFrom")}
							value={filter?.datefrom || null}
							onChange={date =>
								editFilter("datefrom", isValidDate(date) ? date.toISOString() : undefined)
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<DateInput
							label={getString("tradeFilterTo")}
							value={filter?.dateto || null}
							onChange={date =>
								editFilter("dateto", isValidDate(date) ? date.toISOString() : undefined)
							}
						/>
					</Grid>
				</Grid>
				<Grid item>
					<ProductSelect
						label={getString("orderProduct")}
						predicate={prod => prod.prodId === filter?.product?.prodId}
						onChangeItem={prod => {
							editFilter("product", prod);
						}}
					/>
				</Grid>
				<Grid item>
					<OrderMarketSelect
						handleChange={e => editFilter("market", e.target.value)}
						market={filter?.market}
						nullable
					/>
				</Grid>

				<Grid item>
					<TradeStatusSelect
						label={getString("orderStatus")}
						predicate={status => status.ftValue === filter?.tradeStatus?.ftValue}
						onChangeItem={status => {
							editFilter("tradeStatus", status);
						}}
					/>
				</Grid>
				<Grid item container direction="row-reverse" spacing={2}>
					<Grid item>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								save();
								showWithDrawer(undefined);
							}}
						>
							{getString("orderFilterConfirm")}
						</Button>
					</Grid>
					<Grid item>
						<Button variant="text" onClick={reset}>
							{getString("filterReset")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Paper >
	);
};
