import { MonthlyTradingReportData } from "../../../../data/reports/securityCommission/monthlyTrade/types";
import React from "react";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Column } from "material-table";

export const useDailyTradesColumns = () => {
  const { getString, getFormatedNumberString } = React.useContext(
    LocalizationContext
  );

  return React.useMemo(
    () => [
      {
        title: getString("monthlyTradeReportReportDate"),
        field: "date",
        sorting: false,
      },
      {
        title: getString("dailyTradeReportProduct"),
        field: "prodName",
        sorting: false,
      },
      {
        title: getString("monthlyTradeReportReportQty"),
        render: (row) => `${getFormatedNumberString(row.qty)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("dailyTradeReportTurnoverRSD"),
        render: (row) => `${getFormatedNumberString(row.turnoverRsd)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("dailyTradeReportTurnoverEUR"),
        render: (row) => `${getFormatedNumberString(row.turnoverEur)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("dailyTradeReportCountTransaction"),
        field: "tranCount",
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("monthlyTradeReportReportWeightedRSD"),
        render: (row) => `${getFormatedNumberString(row.weightedPrice, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("monthlyTradeReportReportWeightedEUR"),
        render: (row) =>
          `${getFormatedNumberString(row.weightedPriceEur, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
    ],
    [getString, getFormatedNumberString]
  ) as Column<MonthlyTradingReportData>[];
};
