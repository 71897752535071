import React from "react";
import { useDisplayTradingData } from "../../../data/clearing/tradingData/hooks/useDisplayTradingData";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { TradingData } from "../../../data/clearing/tradingData/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayTradingDataMainAttributes } from "./DisplayTradingDataMainAttributes";
import { ExpansionPanel,ExpansionPanelSummary,Typography,Grid,IconButton} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { useTradingDataPrinter } from "../../../data/clearing/tradingData/hooks/useTradingDataPrinter";

export interface DisplayTradingDataProps {
  id?: string;
}

export const DisplayTradingData: React.FunctionComponent<DisplayTradingDataProps> = ({
  id,
}) => {
  const tradingData = useDisplayTradingData(id);
  const { getString } = React.useContext(LocalizationContext);
	const [download, fetchState, setfetch] = useTradingDataPrinter(tradingData);

  return !tradingData ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
        <Grid container justify="space-between">
            <Grid item>
              <Typography>{getString("tableBasciInfo")}</Typography>
            </Grid>
							<Grid item>
								<CustomTooltip titleKeyword="exportPDFTooltip">
									<IconButton
										color="secondary"
										component="span"
										onClick={download}
										disabled={fetchState.type !== "not-started"}
									>
										<DownloadIcon />
									</IconButton>
								</CustomTooltip>
                { fetchState.type !== "not-started"  ? getString("pleaseWait") : null }
							</Grid>
          </Grid>
        </ExpansionPanelSummary>
        <DisplayTradingDataMainAttributes tradingData={tradingData} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewTradingData = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (tradingData: TradingData) =>
      showWithDrawer(<DisplayTradingData id={tradingData.ptdId} />),
    [showWithDrawer]
  );
};
