import {
  TurnoverByBrokersReportData,
  TurnoverByBrokersReportFilter,
} from "../types";
import { User } from "../../../../auth/types";
import { webApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

const getTradeByBrokersData = (
  user: User,
  filter: TurnoverByBrokersReportFilter
) => {
  return webApi<TurnoverByBrokersReportData[]>({
    method: "GET",
    url: "reporting/getturnoverbybroklist",
    params: {
      userid: user.userId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      brokerId: filter?.brokerId,
      cliStatus: filter?.cliStatus,    
    },
  });
};

export default getTradeByBrokersData;
