import { webApi } from "../../../../core/http/httpThunks";
import { Bank } from "../types";

export const getAllBanksApi = (userId: string) =>
  webApi<Bank[]>({
    method: "GET",
    url: "listing/getbanklist",
    params: {
      userId,
    },
  });

export default getAllBanksApi;
