import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";
import { deleteClientBroker } from "../../thunks/clientBroker/deleteClientBroker";
import { setFetchStateBroker } from "../../clientsDataReducer";

export const useClientBrokerDelete = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.clients.fetchStateBroker
	);

	const dispatch = useDispatch();
	const dispatchDeleteBroker = React.useCallback(
		(id: string) => dispatch(deleteClientBroker(id)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateBroker({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchDeleteBroker] as [typeof fetchState, typeof dispatchDeleteBroker]
};
