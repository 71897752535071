import React from "react";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../../components/common/DisplayBoolNumber";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { useAttributes } from "../../../../data/listing/products/hooks/attributes/useAttributes";
import { ProductAttribute } from "../../../../data/listing/products/types";
import {
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	TableBody,
	Typography,
	Grid,
} from "@material-ui/core";
import { Status } from "../../../../data/models";

export interface DisplayAttributesProps {
	productId: string;
}

export const DisplayAttributes: React.FunctionComponent<DisplayAttributesProps> = ({ productId }) => {
	const style = useDisplaySingleEmementStyles();
	const [attributes] = useAttributes(productId);

	return (
		<div className={style.root}>
			<div className={style.row}>
				{attributes ? <AttributesDisplayTable attributes={attributes} /> : "loading placeholder"}
			</div>
		</div>
	);
};

interface AttributesDisplayTableProps {
	attributes: ProductAttribute[];
}

const AttributesDisplayTable: React.FunctionComponent<AttributesDisplayTableProps> = ({ attributes }) => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);

	if (attributes.length === 0) {
		return (
			<Grid container justify="center">
				<Typography>{getString("productAttributeEmpty")}</Typography>
			</Grid>
		);
	}

	return (
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>{getString("productAttributeName")}</TableCell>
						<TableCell>{getString("productAttributeType")}</TableCell>
						<TableCell>{getString("productAttributeComparator")}</TableCell>
						<TableCell>{getString("productAttributeStatus")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{attributes.map(attribute => (
						<TableRow key={attribute.prodAttrId}>
							<TableCell>{attribute.prodAttrName}</TableCell>
							<TableCell>{attribute.type}</TableCell>
							<TableCell>{attribute.comparator}</TableCell>
							<TableCell>
								<DisplayBoolNumber
									value={attribute.status}
									thrutyValue={Status.Active}
									className={style.icon}
								></DisplayBoolNumber>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
