import { webApi } from "../../../../core/http/httpThunks";
import { City } from "../types";

export const getCityApi = (userId: string, cityId: string) =>
  webApi<City>({
    method: "GET",
    url: "listing/getcity",
    params: {
      userId,
      cityId,
    },
  });

export default getCityApi;
