import React from "react";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import ExcelIcon from "@material-ui/icons/TableChartOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { useAnnualReportDownloaderProduct } from "../../../data/reports/annualTrade/hooks/useATReportDownloaderProduct";
import { useAnnualProductExcelDownloader } from "../../../data/reports/annualTrade/hooks/useATExcelDownloaderProducts";
import { useAnnualReportDownloaderClient } from "../../../data/reports/annualTrade/hooks/useATReportDownloaderClients";
import { useAnnualClientExcelDownloader } from "../../../data/reports/annualTrade/hooks/useATExcelDownloaderClients";
import { useAnnualReportDownloaderDate } from "../../../data/reports/annualTrade/hooks/useATReportDownloaderDate";
import { useAnnualDateExcelDownloader } from "../../../data/reports/annualTrade/hooks/useATExcelDownloaderDate";
import { useAnnualReportDownloader } from "../../../data/reports/annualTrade/hooks/useATReportDownloader";
import { useAnnualExcelDownloader } from "../../../data/reports/annualTrade/hooks/useATExcelDownloader";

export const DownloadButton: React.FunctionComponent = () => {
  const [downloader, fetchState] = useAnnualReportDownloader();
  return (
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadExcelButton: React.FunctionComponent = () => {
  const [downloader, fetchState] = useAnnualExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadButtonProduct: React.FunctionComponent = () => {
  const [downloader, fetchState] = useAnnualReportDownloaderProduct();
  return (
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadExcelButtonProduct: React.FunctionComponent = () => {
  const [downloader, fetchState] = useAnnualProductExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadButtonClient: React.FunctionComponent = () => {
  const [downloader, fetchState] = useAnnualReportDownloaderClient();
  return (
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadExcelButtonClient: React.FunctionComponent = () => {
  const [downloader, fetchState] = useAnnualClientExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadButtonDate: React.FunctionComponent = () => {
  const [downloader, fetchState] = useAnnualReportDownloaderDate();
  return (
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadExcelButtonDate: React.FunctionComponent = () => {
  const [downloader, fetchState] = useAnnualDateExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};
