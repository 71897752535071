import React from "react";
import { useDisplayBankGuarantee } from "../../../data/clearing/bankguarantees/hooks/useDisplayBankGuarantees";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { BankGuaranteeData } from "../../../data/clearing/bankguarantees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayBankGuaranteeMainAttributes } from "./DisplayBankGuaranteeMainAttributes";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid, IconButton} from "@material-ui/core";
import { useBankGuaranteesPrinter } from "../../../data/clearing/bankguarantees/hooks/useBankGuaranteesPrinter";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";

export interface DisplayBankGuaranteeProps {
  id?: string;
}

export const DisplayBankGuarantee: React.FunctionComponent<DisplayBankGuaranteeProps> = ({
  id,
}) => {
  const bankguarantee = useDisplayBankGuarantee(id);
  const { getString } = React.useContext(LocalizationContext);
	const [download, fetchState, setfetch] = useBankGuaranteesPrinter(bankguarantee);

  return !bankguarantee ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Grid container justify="space-between">
            <Grid item>
            <Typography>{getString("tableBasciInfo")}</Typography>
            </Grid>
							<Grid item>
								<CustomTooltip titleKeyword="exportPDFTooltip">
									<IconButton
										color="secondary"
										component="span"
										onClick={download}
										disabled={fetchState.type !== "not-started"}
									>
										<DownloadIcon />
									</IconButton>
								</CustomTooltip>
                { fetchState.type !== "not-started"  ? getString("pleaseWait") : null }
							</Grid>
          </Grid>
        </ExpansionPanelSummary>
        <DisplayBankGuaranteeMainAttributes bankguarantee={bankguarantee} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewBankGuarantee = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (bankguarantee: BankGuaranteeData) =>
      showWithDrawer(<DisplayBankGuarantee id={bankguarantee.grntId} />),
    [showWithDrawer]
  );
};
