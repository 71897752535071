import { ThunkType } from "../../../../../core/store/reducers";
import { setCOOrdersData } from "../clientOverviewDataSlice";
import { getOrdersThunk } from "./getOrdersThunk";
import { TableType } from "../types";

export const loadOrderBook = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	const { pageNumber, itemsPerPage } = store.ux.reports.exchbusiness.clientOverview.index[TableType.Orders];
	const { filter } = store.ux.reports.exchbusiness.clientOverview;

	const pagedOrders = await dispatch(getOrdersThunk(pageNumber, itemsPerPage, filter));

	if (pagedOrders) {
		dispatch(setCOOrdersData(pagedOrders));
	}
};
