import React from "react";
import { useDispatch } from "react-redux";
import { invalidateData } from "../clientOverviewDataSlice";

export const useInvlidateData = () => {
	const dispatch = useDispatch();
	const invalidate = React.useCallback(() => dispatch(invalidateData()), [dispatch]);

	return [invalidate] as [typeof invalidate];
};
