import AnnualTradesReport from "./AnnualTradesReport";
import { NavigationItem } from "../../../../core/navigation/types";
import { annualTradesReportLocation, } from "./Locations";

export const annualSecurityReportComponents: NavigationItem[] = [
	{
		Component: AnnualTradesReport,
		isMenuItem: true,
		path: annualTradesReportLocation,
	},
];
