import React from "react";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import ExcelIcon from "@material-ui/icons/TableChartOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../../../components/common/CustomTooltip";
import { useTradesReportDownloader } from "../../../../../data/reports/exchbusiness/clientOverview/hooks/useTradesReportDownloader";
import { useTradesExcelReportDownloader } from "../../../../../data/reports/exchbusiness/clientOverview/hooks/useTradesExcelReportDownloader";

export const DownloadTradesButton: React.FunctionComponent = () => {
	const [downloader, fetchState] = useTradesReportDownloader();
	return (
		<CustomTooltip titleKeyword="exportPDFTooltip">
			<IconButton
				color="secondary"
				component="span"
				onClick={downloader}
				disabled={fetchState.type !== "not-started"}
			>
				<DownloadIcon />
			</IconButton>
		</CustomTooltip>
	);
};

export const DownloadTradesButtonExcel: React.FunctionComponent = () => {
	const [downloader, fetchState] = useTradesExcelReportDownloader();
	return (
		<CustomTooltip titleKeyword="exportXLSXTooltip">
			<IconButton
				color="secondary"
				component="span"
				onClick={downloader}
				disabled={fetchState.type !== "not-started"}
			>
				<ExcelIcon />
			</IconButton>
		</CustomTooltip>
	);
};
