import ChartMob from "./ChartMob";
import { NavigationItem } from "../../../core/navigation/types";
import { chartsmobLocation  } from "./Locations";

export const chartsmobComponents: NavigationItem[] = [
	{
		Component: ChartMob,
		isMenuItem: true,
		path: chartsmobLocation,
		notifications: true,
	},
];
