import React from "react";
import { TextField, MenuItem } from "@material-ui/core";

interface YearSelectProps {
  selected?: number;
  yearChange: (year: number) => void;
  label: string;
}

export const YearSelect: React.FunctionComponent<YearSelectProps> = ({
  selected,
  yearChange,
  label,
}) => {
  const options = React.useMemo(() => {
    const currentYear = new Date().getFullYear();
    const result: (number | undefined)[] = [undefined];
    for (let i = currentYear; i > currentYear - 10; i--) {
      result.push(i);
    }
    return result;
  }, []);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      yearChange(Number(event.target.value));
    },
    [yearChange]
  );

  return (
    <TextField
      value={selected}
      onChange={handleChange}
      fullWidth
      select
      label={label}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};

interface MonthSelectProps {
  selected?: number;
  monthChange: (year: number) => void;
  label: string;
}

export const MonthSelect: React.FunctionComponent<MonthSelectProps> = ({
  selected,
  monthChange,
  label,
}) => {
  const options = React.useMemo(() => {
    const result: (number | undefined)[] = [undefined];
    for (let i = 1; i <= 12; i++) {
      result.push(i);
    }
    return result;
  }, []);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      monthChange(Number(event.target.value));
    },
    [monthChange]
  );

  return (
    <TextField
      value={selected}
      onChange={handleChange}
      fullWidth
      select
      label={label}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};

interface QuarterSelectProps {
  selected?: number;
  quarterChange: (quarter: number) => void;
  label: string;
}
export const QuarterSelect: React.FunctionComponent<QuarterSelectProps> = ({
  selected,
  quarterChange,
  label,
}) => {
  const options = React.useMemo(() => {
    const result: (number | undefined)[] = [undefined];
    for (let i = 1; i <= 4; i++) {
      result.push(i);
    }
    return result;
  }, []);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      quarterChange(Number(event.target.value));
    },
    [quarterChange]
  );

  return (
    <TextField
      value={selected}
      onChange={handleChange}
      fullWidth
      select
      label={label}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};
