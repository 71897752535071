import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewClient } from "./DisplayClient";
import { useClientDelete } from "../../../data/listing/clients/hooks/useClientDelete";
import { ClientListData } from "../../../data/listing/clients/types";
import { ClientEditParts, getClientsEditLocation } from "./Locations"
import { AccessRights } from "../../../core/navigation/types";

export const useClientActions = (userAccessRights: AccessRights) => {
	const { getString } = useContext(LocalizationContext);
	const onShowClient = useViewClient();
	const onEditClient = useEditClientAction();

	return useMemo(() => {
		const result: Action<ClientListData>[] = [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tableShow"),
				onClick: (event, rowData) => onShowClient(rowData as ClientListData),
			},
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditClient(rowData as ClientListData),
//				hidden: userAccessRights===AccessRights.ReadOnly
			},
		];

		return result;
	}, [onShowClient, getString, onEditClient]);
};

export const useDeleteClientAction = () => {
	const [fetchState, deleteClient, reset] = useClientDelete();
	const action =  React.useCallback(
		async (client: ClientListData) => {
			if (client.cliId) {
				await deleteClient(client.cliId);
			}
		},
		[deleteClient]
	);
	return [fetchState, action, reset] as [typeof fetchState, typeof action, typeof reset];
};


export const useEditClientAction = () => {
	const history = useHistory();
	return React.useCallback(
		(employee: ClientListData) => history.push(getClientsEditLocation(ClientEditParts.BasicInfo, employee.cliId)),
		[history]
	);
};
