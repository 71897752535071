import React from "react";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import {TableContainer,	Table,	TableHead,	TableCell,	TableRow,	Paper,	TableBody,	Typography,	Grid,} from "@material-ui/core";
import { TradeLogData } from "../../../data/trading/trades/types";
import { useAuth } from "../../../data/auth/hooks";

export interface DisplayTradeLogDataProps {
	tradelog?: TradeLogData[];
}

export const DisplayTradeLogData: React.FunctionComponent<DisplayTradeLogDataProps> = ({ tradelog }) => {
	const style = useDisplaySingleEmementStyles();

	return (
		<div className={style.root}>
			<div className={style.row}>
				 <TradeLogDisplayTable tradelog={tradelog} />
			</div>
		</div>
	);
};

interface TradeLogDisplayTableProps {
	tradelog?: TradeLogData[];
}

export const TradeLogDisplayTable: React.FunctionComponent<TradeLogDisplayTableProps> = ({
	tradelog,
}) => {
	const { getString, getNumberString } = React.useContext(LocalizationContext);
//	const [values, displayValue] = useAttributeValues();

	const [auth] = useAuth();

	if (auth.status !== "successful") {
		return null;
	}

	if (!tradelog || tradelog.length === 0) {
		return (
			<Grid container justify="center">
					{ auth.user.roleCode !== "MEMTRADER" && auth.user.roleCode !== "MEMMANAGER"  &&  auth.user.roleCode !== "MEMCONTACT"  &&  ( 
				<Typography>{getString("orderLogDataEmpty")}</Typography> )}
			</Grid>
		);
	}

	return (
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell><b>{getString("orderLogDataTable")}</b></TableCell> 
					</TableRow>
				</TableHead>
				<TableBody>
					{tradelog &&
						tradelog.map(tradelog => (
							<TableRow >
{/*								<TableCell>{attribute.attrName}</TableCell>  */}
								<TableCell>
									{tradelog.changeTime}
								</TableCell>
								<TableCell size="small">
									{tradelog.userName}
								</TableCell>
								<TableCell>
									{tradelog.activity}
								</TableCell>
{/*								<TableCell>
									{tradelog.status}
								</TableCell>
								*/}
							</TableRow>  
						))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
