import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { EditOrderBasicInfoPage } from "./EditOrderBasicInfoPage";
import { useEditOrderState } from "../../../data/trading/orders/hooks/useEditOrderState";
import { PageTitle } from "../../components/common/PageTitle";
import { mainBoardLocation } from "../mainBoard/Locations";
import { mainBoardMLocation } from "../mainpanelm/Locations";
import { OrdersEditAction } from "../../../data/trading/orders/types";
import { orderBookLocation } from "../orderBook/Locations";
import { OrderSide } from "../../../data/models";
import { useOrderSideStyles } from "./useOrderRowColor";

export interface EditOrderPageInfo {
	action: OrdersEditAction;
	editPart?: string;
	orderId?: string;
}

export interface EditOrderPageProps extends RouteComponentProps<EditOrderPageInfo> {}

export const EditOrderPage: React.FunctionComponent<EditOrderPageProps> = props => {
	const pageStyles = usePageStyles();
	const { action, orderId } = props.match.params;
	const sideStyle = useOrderSideStyles();

	const [order, editOrder, changed] = useEditOrderState(action, orderId);

	const backLocation = action === OrdersEditAction.Replay ?  mainBoardLocation : orderBookLocation;

	console.log(backLocation)

	const pageTitle = orderId ? (action === OrdersEditAction.Verify && order && order.ordStatus===2 ? "orderConfirm": 
								(action === OrdersEditAction.Verify && order && order.ordStatus!==2 ? "orderVerify" : 
								(action === OrdersEditAction.Replay ? "orderReplay" : "orderModify")))
		: "orderCreateTitle";

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card >
					<CardHeader
						title={
							<PageTitle
								title={pageTitle}
								subtitle={order?.ordNo}
								backLocation={backLocation}
							/>
						}
						className={order && order.side === OrderSide.Sell ? sideStyle.sell : sideStyle.buy}
					/>
					<Divider />
					<CardContent>
						<div style={{ marginTop: "20px" }}>
							{order && (
								<EditOrderBasicInfoPage
									order={order}
									editOrder={editOrder}
									changed={changed}
									action={action}
								/>
							)}
						</div>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
