import React from "react";
import { useAttribute } from "./useAttribute";
import { ProductAttribute, ProducAttributeManditory, ProducAttributeHasStandard } from "../../types";
import { Status } from "../../../../models";
import { useCopiedState } from "../../../../../core/util/hooks";

export const createDefaultAttribute = (productId: string): ProductAttribute => ({
	productId: productId,
	comparator: "",
	mandatory: ProducAttributeManditory.True,
	hasStandard: ProducAttributeHasStandard.True,
	prodAttrName: "",
	type: "",
	status: Status.Active,
});

export const useEditAttribute = (productId: string, attributeId?: string) => {
	const [displayAttribute] = useAttribute(attributeId);

	return React.useMemo(() => {
		if (attributeId !== undefined && displayAttribute === undefined) {
			return undefined;
		}

		if (attributeId === undefined) {
			const defaultAttribute = createDefaultAttribute(productId);
			return { ...defaultAttribute };
		}

		return displayAttribute ? { ...displayAttribute } : undefined;
	}, [productId, attributeId, displayAttribute]);
};

export const useEditAttributeState = (productId: string, attributeId?: string) => {
	const attribute = useEditAttribute(productId, attributeId);
	return useCopiedState(attribute);
};
