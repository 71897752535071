import { webApi } from "../../../../core/http/httpThunks";
import { TransportFee } from "../types";

export const getAllTransportFeesApi = (userId: string) =>
  webApi<TransportFee[]>({
    method: "GET",
    url: "clearing/gettransfeeslist",
    params: {
      userId,
    },
  });

export default getAllTransportFeesApi;
