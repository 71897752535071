import { webApi } from "../../../../core/http/httpThunks";

export const deleteInvoiceApi = (userId: string, invid: string) =>
  webApi<string>({
    method: "DELETE",
    url: "accounting/delinvoice",
    params: {
      userId,
      invid,
    },
  });

export default deleteInvoiceApi;
