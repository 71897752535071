import { ThunkType } from "../../../../core/store/reducers";
import getAllEmployeesAPI from "../api/getAllEmployees";
import { setEmployees } from "../employeesDataReducer";

export const loadAllEmployees = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setEmployees());

	const { userId } = store.data.auth.current.user;
	const employees = await dispatch(getAllEmployeesAPI(userId));

	dispatch(setEmployees(employees || []));
};
