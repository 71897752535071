import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { Country } from "../../../data/listing/countries/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewCountry } from "./DisplayCountry";
import { useCountryDelete } from "../../../data/listing/countries/hooks/useCountryDelete";
import { getCountryEditLocation } from "./Locations";
import { AccessRights } from "../../../core/navigation/types";

export const useCountryActions = (userAccessRights: AccessRights) => {
  const { getString } = useContext(LocalizationContext);
  const onShowCountry = useViewCountry();
  const onEditCountry = useEditCountryAction();

  return useMemo(() => {
    const result: Action<Country>[] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("tableShow"),
        onClick: (event, rowData) => onShowCountry(rowData as Country),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) => onEditCountry(rowData as Country),
        hidden: userAccessRights===AccessRights.ReadOnly
      },
    ];

    return result;
  }, [onShowCountry, onEditCountry, getString]);
};

export const useDeleteCountryAction = () => {
  const [, deleteCountry] = useCountryDelete();
  return React.useCallback(
    async (country: Country) => {
      if (country.cntId) {
        await deleteCountry(country.cntId);
      }
    },
    [deleteCountry]
  );
};

export const useEditCountryAction = () => {
  const history = useHistory();
  return React.useCallback(
    (country: Country) =>
      history.push(getCountryEditLocation(country.cntId)),
    [history]
  );
};
