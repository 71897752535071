import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { TradingData } from "../../../data/clearing/tradingData/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewTradingData } from "./DisplayTradingData";
import { useHistory } from "react-router-dom";
import { getTradingDataEditLocation } from "../tradingData/Locations";

export function useTradingDataActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowTradingData = useViewTradingData();
  const onEditTradingData = useEditTradingDataAction();

  return useMemo(() => {
    const result: MaterialTableProps<TradingData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) => onShowTradingData(rowData as TradingData),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) => onEditTradingData(rowData as TradingData),
      },
    ];
    return result;
  }, [onShowTradingData, onEditTradingData, getString]);
}

export const useEditTradingDataAction = () => {
  const history = useHistory();
  return React.useCallback(
    (tradingData: TradingData) =>
      history.push(getTradingDataEditLocation(tradingData.ptdId)),
    [history]
  );
};
