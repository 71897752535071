import React from "react";
import { useDispatch } from "react-redux";
import { Role } from "../types";
import { getRole } from "../thunks/loadRole";

export const useRole = (roleId?: string) => {
	const displatch = useDispatch();
	const [role, setRole] = React.useState<Role | undefined>();

	React.useEffect(() => {
		const loadRole = async () => {
			if (roleId) {
                const loadedRole = (await displatch(getRole(roleId))) as any;
				setRole(loadedRole);
			}
		};
		loadRole();
	}, [displatch, roleId, setRole]);

	return [role];
};
