import { webApi } from "../../../../../core/http/httpThunks";
import { CommissionDetail } from "../../types";

export const getAllCommissionDetailsApi = (userId: string, commid: string) =>
	webApi<CommissionDetail[]>({
		method: "GET",
		url: "listing/getcommdetlist",
		params: {
			userId,
			commid
		},
	});

export default getAllCommissionDetailsApi;
