import { NavigationItem } from "../../../core/navigation/types";
import BankPage from "./BanksPage";
import { EditBankPage } from "./EditBankPage";
import { banksLocation, editRouter } from "./Locations";
export const bankComponents: NavigationItem[] = [
	{
		Component: BankPage,
		isMenuItem: true,
		path: banksLocation,
	},
	{
		Component: EditBankPage,
		isMenuItem: false,
		path: editRouter,
	},
];
