import { Bank, BankValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const bankValidationState: BankValidationState = {
  bankCode: true,
  bankName: true,
  idNumber: true,
  cityId: true,
};

export const useBankValidator = createUseValidator<Bank, BankValidationState>(
  bankValidationState
);
