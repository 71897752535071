import { TradingData, TradingDataValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const tradingDataValidationState: TradingDataValidationState = {
	ptdId: true,
	productId: true,
};

export const useTradingDataValidator = createUseValidator<TradingData, TradingDataValidationState>(
	tradingDataValidationState
);
