import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useUsersData } from "../../../data/listing/users/hooks/useUserData";
import { useUserColumns } from "./useUserColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useUserActions, useDeleteUserAction } from "./useUserActions";
import { UserListData } from "../../../data/listing/users/types";

export interface UserTableProps {
	userAccessRights: AccessRights;
}

type UserTablePropsType = UserTableProps & TableDataProps<UserListData>;

export const UserTable: React.FunctionComponent<UserTablePropsType> = ({
	userAccessRights,
	...tableProps
}) => {
	const [data, loadAll] = useUsersData();

	const columns = useUserColumns();
	const actions = useUserActions(userAccessRights);
	const onDeleteClient = useDeleteUserAction();

	return (
		<Table<UserListData>
			{...tableProps}
			getData={loadAll}
			immutableData={data}
			title=""
			columns={columns}
			actions={actions}
			options={{
				toolbar: false,
				pageSize: 20,
				actionsColumnIndex: -1,
			}}
			editable={userAccessRights !== AccessRights.ReadOnly ? {
				onRowDelete:  onDeleteClient,
			  }: {}}
		/>
	);
};

export default UserTable;
