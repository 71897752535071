import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { PaymentFilter } from "../../../data/clearing/payments/types";
import { LocalizationContext } from "../../../core/localization/Localization";

import { usePaymentSideOptionsTranslator } from "../../../data/clearing/payments/hooks/usePaymentSideOptions";
import { usePaymentDedicationOptionsTranslator } from "../../../data/clearing/payments/hooks/usePaymentDedicationOptions";

export const PaymentFilterChipsComponent: React.FunctionComponent<PaymentFilterChipsProps> = ({
  filter,
  removeFilter,
}) => {
  const { getDateString, getString } = React.useContext(LocalizationContext);
  const sideTranslator = usePaymentSideOptionsTranslator();
  const dedicatedTranslator = usePaymentDedicationOptionsTranslator();
  return (
    <Grid container spacing={1}>
      {filter.inout && (
        <Grid item>
          <Chip
            label={sideTranslator(filter.inout)}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("inout")}
          />
        </Grid>
      )}
      {filter.nondedicated !== undefined && (
        <Grid item>
          <Chip
            label={dedicatedTranslator(filter.nondedicated)}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("nondedicated")}
          />
        </Grid>
      )}
      {filter.datefrom && (
        <Grid item>
          <Chip
            label={`${getString("filterFrom")}: ${getDateString(
              new Date(filter.datefrom)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("datefrom")}
          />
        </Grid>
      )}
      {filter.dateto && (
        <Grid item>
          <Chip
            label={`${getString("filterTo")}: ${getDateString(
              new Date(filter.dateto)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("dateto")}
          />
        </Grid>
      )}
      {filter.client && (
        <Grid item>
          <Chip
            label={filter.client.cliName}
            color="secondary"
            variant="outlined"
            onDelete={() => {
              removeFilter("client");
              removeFilter("tranId");
            }}
          />
        </Grid>
      )}
      {filter.tranId && (
        <Grid item>
          <Chip
            label={filter.tranDesc}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("tranId")}
          />
        </Grid>
      )}
      {filter.status && filter.status.ftDescr !== "" && (
        <Grid item>
          <Chip
            label={filter.status.ftDescr}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("status")}
          />
        </Grid>
      )}
    </Grid>
  );
};

interface PaymentFilterChipsProps {
  filter: PaymentFilter;
  removeFilter: (type: keyof PaymentFilter) => void;
}
