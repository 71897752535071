import React from "react";
import { useDisplayPayment } from "./useDisplayPayments";
import { PaymentData } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defaultPayment: PaymentData = {
  payId: "",
  payType: 1,
  hasOrdTran: 0,
  clientId: "",
  status: Status.Active,
  currId: "00000001",
};

export const useEditPayment = (payId?: string): PaymentData | undefined => {
  const displayPayment = useDisplayPayment(payId);

  return React.useMemo(() => {
    if (payId !== undefined && displayPayment === undefined) {
      return undefined;
    }

    if (payId === undefined) {
      return { ...defaultPayment };
    }

    return displayPayment ? { ...displayPayment } : undefined;
  }, [payId, displayPayment]);
};

export const useEditPaymentState = (payId?: string) => {
  const payment = useEditPayment(payId);
  return useCopiedState(payment);
};
