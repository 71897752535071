import { NavigationItem } from "../../../core/navigation/types";
import WhsPage from "./WhsPage";
import { EditWhsPage } from "./EditWhsPage";
import { whsLocation, editRouter } from "./Locations";
export const warehouseComponents: NavigationItem[] = [
	{
		Component: WhsPage,
		isMenuItem: true,
		path: whsLocation,
	},
	{
		Component: EditWhsPage,
		isMenuItem: false,
		path: editRouter,
	},
];
