import { ThunkType } from "../../../../core/store/reducers";
import getRegApi from "../api/getRegion";
import { setDisplayed } from "../regionsDataReducer";
import { Region } from "../types";

export const loadRegion = (regId: string): ThunkType => async (dispatch, getStore) => {
	dispatch(setDisplayed());

	const region = await dispatch(getRegion(regId));
	dispatch(setDisplayed(region));
};

export const getRegion = (regId: string): ThunkType<Promise<Region | undefined>> => async (
	dispatch,
	getStore
) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getRegApi(userId, regId));
};
