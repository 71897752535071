import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertWhs } from "../thunks/upsertWhs";
import { setFetchState } from "../warehousesDataReducer";
import { Warehouse } from "../types";

export const useWhsUpsert = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.listing.warehouses.fetchState
  );

  const dispatch = useDispatch();
  const dispatchUpsertWhs = React.useCallback(
    (whs: Warehouse) => dispatch(upsertWhs(whs)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchUpsertWhs] as [
    typeof fetchState,
    typeof dispatchUpsertWhs
  ];
};
