import React from "react";
import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { PayType } from "../../../data/listing/payTypes/types";
import { Status } from "../../../data/models";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";

export function usePayTypeColumns() {
  const { getString } = useContext(LocalizationContext);
  return useMemo(
    () => [
      {
        title: getString("payTypeTitle"),
        field: "payTypeDescr",
      },
			{
				title: getString("payTypePonder"),
				render: payType => <DisplayBoolNumber value={payType.inPonder} thrutyValue={Status.Active} />,
				customSort: (a, b) => a.status - b.status,
			},			{
				title: getString("payTypeStatus"),
				render: payType => <DisplayBoolNumber value={payType.status} thrutyValue={Status.Active} />,
				customSort: (a, b) => a.status - b.status,
			},
    ],
    [getString]
  ) as Column<PayType>[];
}
