import React from "react";
import { useProductValidator } from "../../../data/listing/products/hooks/useProductValidator";
import { useProductUpsert } from "../../../data/listing/products/hooks/useProductUpsert";
import { Product } from "../../../data/listing/products/types";
import { useHistory } from "react-router-dom";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Grid } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { StringInput } from "../../components/common/StringInput";
import { Status } from "../../../data/models";
import { UnitSelect } from "../../components/common/UnitSelect";
import { VatRateSelect } from "../../components/common/VatRateSelect";
import { ProductGroupSelect } from "../../components/common/ProductGroupSelect";
import { BoolInput } from "../../components/common/BoolInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { productsLocation } from "./Locations";

export interface EditProductBasicProps {
	product: Product;
	editProduct: (field: keyof Product, value: any) => void;
	changed: boolean;
}

export const EditProductBasicInfo: React.FunctionComponent<EditProductBasicProps> = props => {
	const { getString } = React.useContext(LocalizationContext);

	const { product, editProduct, changed } = props;
	const [validationState, validate] = useProductValidator();
	const [fetchState, upsert] = useProductUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editProduct(event.target.name as any, event.target.value);
		},
		[editProduct]
	);

	const save = React.useCallback(() => {
		const save = async () => {
			const normalized = validate(product);
			if (normalized) {
				const newId = await upsert(normalized);
				if (newId) {
					editProduct("prodId", newId);
				}
			}
		};
		if (fetchState.type === "successful") {
			history.push(productsLocation);
		} else if (fetchState.type !== "started") {
			save();
		}

	}, [product, validate, fetchState, editProduct, history, upsert]);

	if (!product) {
		return null;
	}

	return (
		<>
			<Grid container direction="column" justify="center" alignItems="center" spacing={4}>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={6}>
					<Grid item xs={3}>
						<StringInput<Product>
							property="prodName"
							item={product}
							validationState={validationState}
							onChange={handleChange}
							label={getString("productName")}
						/>
					</Grid>
					<Grid item xs={3}>
						<StringInput<Product>
							property="prodNameen"
							item={product}
							validationState={validationState}
							onChange={handleChange}
							label={getString("productNameEN")}
						/>
					</Grid>
					<Grid item xs={3}>
						<StringInput<Product>
							property="prodCode"
							item={product}
							validationState={validationState}
							onChange={handleChange}
							label={getString("productCode")}
						/>
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={6}>
					<Grid item xs={3}>
						<ProductGroupSelect
							label={getString("productGroupName")}
							predicate={group => group.prodGroupId === product.productGroupId}
							onChangeItem={group => editProduct("productGroupId", group?.prodGroupId)}
							required={validationState.productGroupId !== undefined}
							error={
								validationState.productGroupId !== undefined &&
								!validationState.productGroupId
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<VatRateSelect
							label={getString("productVat")}
							predicate={vat => vat.ftValue === product.vat}
							onChangeItem={vat => editProduct("vat", vat?.ftValue)}
							required={validationState.vat !== undefined}
							error={validationState.vat !== undefined && !validationState.vat}
						/>
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={6}>
					<Grid item xs={3}>
						<UnitSelect
							label={getString("productUnit")}
							predicate={unit => unit.ftValue === product.unit}
							onChangeItem={unit => editProduct("unit", unit?.ftValue)}
							required={validationState.unit !== undefined}
							error={validationState.unit !== undefined && !validationState.unit}
						/>
					</Grid>
				</Grid>
				<Grid container item direction="row" justify="flex-start" alignItems="center" spacing={6}>
					<Grid item xs={1}>
						<BoolInput<Product>
							property="status"
							item={product}
							onChange={handleChange}
							falsyValue={Status.Inactive}
							thrutyValue={Status.Active}
							label={getString("cliStatus")}
						/>
					</Grid>
				</Grid>
			</Grid>
			<FabSave fetchState={fetchState} onClick={save} />
			<LeavePrompt shouldShow={unsavedChanges} />
		</>
	);
};
