import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllUsers } from "../thunks/loadAllUsers";
import { UserListData } from "../types";

export const useUsersData = () => {
	const data = useSelector<StoreType, UserListData[] | undefined>(s => s.data.listing.users.displayData);
	const allData = useSelector<StoreType, UserListData[] | undefined>(s => s.data.listing.users.all);

	const dispatch = useDispatch();
	const dispatchLoadAllUsers = React.useCallback(() => dispatch(loadAllUsers()), [dispatch]);

	return [data, dispatchLoadAllUsers, allData] as [
		typeof data,
		typeof dispatchLoadAllUsers,
		typeof allData
	];
};

export const useUser = (id?: string) => {
	const allData = useSelector<StoreType, UserListData[] | undefined>(s => s.data.listing.users.all);

	const dispatch = useDispatch();
	const dispatchLoadAllUsers = React.useCallback(() => dispatch(loadAllUsers()), [dispatch]);

	React.useEffect(() => {
		if (!allData && id) {
			dispatchLoadAllUsers();
		}
	}, [allData, id, dispatchLoadAllUsers]);

	const role = React.useMemo(() => {
		if (!allData) {
			return undefined;
		} else {
			return allData?.find(e => e.userId === id);
		}
	}, [id, allData]);

	return [role] as [typeof role];
};
