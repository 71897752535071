import { ThunkType } from "../../../../core/store/reducers";
import { setOrders, invalidateSideOrders } from "../ordersDataSlice";
import { setOrdersIndex } from "../ordersUXSlice";
import { OrderFilter, OrderSide, PageIndex } from "../../../models";
import { getOrdersThunk } from "../../orders/thunks/getOrdersThunk";

export const loadMainBoard = (side: OrderSide): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	const { pageNumber, itemsPerPage } = store.ux.trading.mainBoard.index[side];
	const { filter } = store.ux.trading.mainBoard;

	const sideFilter: OrderFilter = {
		...filter,
		side
	}
	const pagedOrders = await dispatch(getOrdersThunk(pageNumber, itemsPerPage, sideFilter, -1));

	if (pagedOrders) {
		dispatch(setOrders({
			side,
			value: pagedOrders
		}));
	}
};

export const setOrdersIndexThunk = (side: OrderSide, value: PageIndex): ThunkType => dispatch => {
	dispatch(setOrdersIndex({
		side,
		value,
	}));
	dispatch(invalidateSideOrders(side));
};
