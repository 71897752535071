import { PaymentListData } from "../../../../../data/clearing/payments/types";
import React from "react";
import { LocalizationContext } from "../../../../../core/localization/Localization";
import { Column } from "material-table";

export const usePaymentsColumns = () => {
  const { getString, getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);

  return React.useMemo(
    () => [
      {
        title: getString("paymentDataTime"),
        render: (payment) => getDateString(payment.payDate, "DD.MM.YYYY"),
		sorting: false,
		width: "140px",
		cellStyle: {
			maxWidth: "140px",
			minWidth: "140px",
			width: "140px",
			whiteSpace: "nowrap",
			textAlign: "center",
			paddingRight: "10px",
		},
		headerStyle: {
			maxWidth: "140px",
			minWidth: "140px",
			width: "140px",
//					whiteSpace: "nowrap",
			textAlign: "center",
			paddingRight: "10px",
		},

      },
      {
        title: getString("paymentPayType"),
        field: "payTypeDescr",
        sorting: false,
      },
			{
				title: getString("paymentAmount"),
				render: payment => getFormatedNumberString(payment.amtPay, true),
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
      {
        title: getString("paymentAmtBal"),
        render: (payment) => getFormatedNumberString(payment.amtBal, true),
        sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
      },
      {
        title: getString("paymentAmtBalNonAlloc"),
        render: (payment) => getFormatedNumberString(payment.amtNonAllocBal, true),
        sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
      },
      {
        title: getString("paymentAmtOrdersBal"),
        render: (payment) => getFormatedNumberString(payment.amtOrdersBal, true),
        sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
      },
      {
        title: getString("paymentAmtTradesBal"),
        render: (payment) => getFormatedNumberString(payment.amtTransBal, true),
        sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
      },
    ],
    [getString, getFormatedNumberString, getDateString]
  ) as Column<PaymentListData>[];
};
