import BusinessActivitiesReport from "./BusinessActivitiesReport";
import { NavigationItem } from "../../../../core/navigation/types";
import { businessActivitiesLocation } from "./Locations";

export const businessActivitiesReportComponents: NavigationItem[] = [
  {
    Component: BusinessActivitiesReport,
    isMenuItem: true,
    path: businessActivitiesLocation,
  },
];
