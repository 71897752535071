import React from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { DateInput } from "../../components/common/DateInput";
import { LocalizationContext } from "../../../core/localization/Localization";
import { CtrlGoodsFilter } from "../../../data/clearing/ctrlgoods/types";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { useAuth } from "../../../data/auth/hooks";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { CtrlGoodsFilterChipsComponent } from "./CtrlGoodsFilterChips";
import { ProductSelect } from "../../components/listing/ProductSelect";

export enum CtrlGoodsFilterEditorType {
  Basic,
  Extended,
}

interface CtrlGoodsFilterEditorProps {
  filter?: CtrlGoodsFilter;
  editFilter: (prop: keyof CtrlGoodsFilter, value: any) => void;
  save: () => void;
  type: CtrlGoodsFilterEditorType;
  reset?: () => void;
} 

export const CtrlGoodsFilterEditor: React.FunctionComponent<CtrlGoodsFilterEditorProps> = ({
  filter,
  editFilter,
  save,
  type,
  reset,
}) => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const { getString } = React.useContext(LocalizationContext);
  const [auth] = useAuth();

  return (
    <Paper
      style={{
        paddingLeft: "70px",
        paddingRight: "70px",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Grid container direction="column" spacing={6}>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={6}>
            <DateInput
              label={getString("ctrlFilterFrom")}
              value={filter?.datefrom || null}
              onChange={(date) => editFilter("datefrom", date?.toISOString())}
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              label={getString("ctrlFilterTo")}
              value={filter?.dateto || null}
              onChange={(date) => editFilter("dateto", date?.toISOString())}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
            <ProductSelect
              label={getString("ctrlproductName")}
              predicate={(prod) => prod.prodId === filter?.product?.prodId}
              onChangeItem={(prod) => {
                editFilter("product", prod);
              }}
            />
        </Grid>
        
        <Grid item xs={12}>
           <ClientSelect
              label={getString("paymentClientName")}
              predicate={(cli) => cli.cliId === filter?.client?.cliId}
              onChangeItem={(cli) => editFilter("client", cli)}
                />
        </Grid>

        <Grid item xs={12}>
           <ClientSelect
              label={getString("ctrlbuyerName")}
              predicate={(cli) => cli.cliId === filter?.buyer?.cliId}
              onChangeItem={(cli) => editFilter("buyer", cli)}
                />
         </Grid>

         <Grid item xs={12}>
           <ClientSelect
              label={getString("ctrlsellerName")}
              predicate={(cli) => cli.cliId === filter?.seller?.cliId}
              onChangeItem={(cli) => editFilter("seller", cli)}
                />
         </Grid>

         <Grid item container direction="row-reverse" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                save();
                showWithDrawer(undefined);
              }}
            >
              {getString("orderFilterConfirm")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text" onClick={reset}>
              {getString("filterReset")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const CtrlGoodsFilterChips = CtrlGoodsFilterChipsComponent;
