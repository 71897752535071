import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Currency } from "../types";
import { loadCurrency } from "../thunks/loadCurrency";

export const useDisplayCurrency = (currId?: string) => {
	const storeCurrency = useSelector<StoreType, Currency | undefined>(
		store => store.data.listing.currencies.displayed
    );

    const displayedCurrency = storeCurrency && storeCurrency.currId === currId ? storeCurrency : undefined;

    const displatch = useDispatch();

	React.useEffect(() => {
		if (currId) {
			displatch(loadCurrency(currId));
		}
    }, [displatch, currId]);

	return displayedCurrency;
};
