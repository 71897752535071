import { ThunkType } from "../../../../../core/store/reducers";
import deleteCommissionDetailApi from "../../api/commissionDetails/deleteCommissionDetail";
import { setFetchStateAttribute } from "../../commissionsDataReducer";

export const deleteCommissionDetail = (detailId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchStateAttribute({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteCommissionDetailApi(userId, detailId));

	dispatch(setFetchStateAttribute(result !== undefined ? { type: "successful" } : { type: "error" }));
};
