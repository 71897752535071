import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllProductGroups } from "../thunks/loadAllProductGroups";
import { ProductGroupData } from "../types";

export const useProductGroupData = () => {
	const data = useSelector<StoreType, ProductGroupData[] | undefined>(
		s => s.data.listing.productGroups.displayData
	);
	const allData = useSelector<StoreType, ProductGroupData[] | undefined>(
		s => s.data.listing.productGroups.all
	);

	const dispatch = useDispatch();
	const dispatchLoadAllGroups = React.useCallback(() => dispatch(loadAllProductGroups()), [dispatch]);

	return [data, dispatchLoadAllGroups, allData] as [
		typeof data,
		typeof dispatchLoadAllGroups,
		typeof allData
	];
};
