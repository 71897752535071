import React from "react";
import { Grid, CardContent, CardHeader, Divider, Card, Fab } from "@material-ui/core";
import EmployeesTable from "./EmployeesTable";
import { usePageStyles } from "../../pageStyles";
import { SearchBox } from "../../components/common/SearchBox";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import AddIcon from "@material-ui/icons/Add";
import { useEmployeeSearch } from "../../../data/listing/employees/hooks/useEmployeeSearch";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../../components/common/PageTitle";
import { getEmployeeEditLocation } from "./Locations";

const EmployeesPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [search, setSearch] = useEmployeeSearch();
	const history = useHistory();

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader
						title={<PageTitle title="navEmployees" />}
						action={<SearchBox search={search} setSearch={setSearch} />}
					/>
					<Divider />
					<CardContent>
						<EmployeesTable userAccessRights={userAccessRights} />
					</CardContent>
				</Card>
				{userAccessRights === AccessRights.All && (
					<Fab
						color="secondary"
						aria-label="add"
						className={pageStyles.mainFab}
						onClick={() => history.push(getEmployeeEditLocation())}
					>
						<AddIcon />
					</Fab>
				)}
			</Grid>
		</Grid>
	);
};

export default EmployeesPage;
