import { WeeklyTradingReportData, WeeklyTradingReportFilter } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getWeeklyDateData = (user: User, filter: WeeklyTradingReportFilter) => {
  return webApi<WeeklyTradingReportData[]>({
    method: "GET",
    url: "reporting/getweeklytradinglist",
    params: {
      userid: user.userId,
      option: "D",
      year: filter.year,
      week: filter.yearPart,
    },
  });
};

export default getWeeklyDateData;
