import React, { useMemo, useEffect } from "react";
import MaterialTable, { MaterialTableProps, Options } from "material-table";
import { useMaterialTableLocalization } from "./useMaterialTableLocalization";

export interface TableDataProps<TData extends object> {
	immutableData?: TData[];
	getData?: () => void;
}

export type TablePropsType<TData extends object> = TableDataProps<TData> &
	Omit<MaterialTableProps<TData>, "data" | "isLoading">;

export function Table<TData extends object>(props: TablePropsType<TData>) {
	const { immutableData, getData, options, ...materialTableProps } = props;
	const tableData = immutableData || [];
	const isLoading = immutableData === undefined;
	const tableDataMutable = useMemo(() => tableData.map(d => ({ ...d })), [tableData]);

	const tableOptions: Options = useMemo(() => {
		const propOptions = options || {};
		return {
			padding: "dense",
			...propOptions,
			headerStyle: {
				fontWeight: "bold",
				...propOptions.headerStyle,
			},
		};
	}, [options]);

	const localization = useMaterialTableLocalization();

	useEffect(() => {
		if (!immutableData && getData) getData();
	}, [immutableData, getData]);

	return (
		<MaterialTable<TData>
			{...materialTableProps}
			title=""
			data={tableDataMutable}
			isLoading={isLoading}
			localization={localization}
			options={tableOptions}
		/>
	);
}
