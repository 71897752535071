import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useDeliveryFilter, useCurrentDeliveryilter } from "../../../data/clearing/delivery/hooks/useDeliveryFilter";
import { DeliveryFilterEditor, DeliveryFilterEditorType, DeliveryFilterChips,} from "./DeliveryFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const DeliveryFilterButton: React.FunctionComponent = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const showFilter = React.useCallback(
    () => showWithDrawer(<DeliveriesFilterEditor />),
    [showWithDrawer]
  );

  const [filter, removeFilter] = useCurrentDeliveryilter();

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<DeliveryFilterChips filter={filter} removeFilter={removeFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip">
					<IconButton color="secondary" component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const DeliveriesFilterEditor: React.FunctionComponent = () => {
  const [filter, editFilter, save, , reset] = useDeliveryFilter();
  return (
    <DeliveryFilterEditor
      filter={filter}
      editFilter={editFilter}
      save={save}
      type={DeliveryFilterEditorType.Extended}
			reset={reset}
    />
  );
};
