import React from "react";
import { useEditRoleState } from "../../../data/listing/roles/hooks/useEditRoleState";
import { useRoleValidator } from "../../../data/listing/roles/hooks/useRoleValidator";
import { useRoleUpsert } from "../../../data/listing/roles/hooks/useRoleUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { Role } from "../../../data/listing/roles/types";
import { StringInput } from "../../components/common/StringInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { PageTitle } from "../../components/common/PageTitle";
import { rolesLocation } from "./Locations";
import { BoolInput } from "../../components/common/BoolInput";

export interface EditRoleInfo {
	roleId?: string;
}

export interface EditRoleProps extends RouteComponentProps<EditRoleInfo> {}

export const EditRolePage: React.FunctionComponent<EditRoleProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [role, editRole, changed] = useEditRoleState(props.match.params.roleId);
	const [validationState, validate] = useRoleValidator();
	const [fetchState, upsert] = useRoleUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editRole(event.target.name as any, event.target.value);
		},
		[editRole]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(rolesLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(role);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [role, validate, fetchState, history, upsert]);

	const pageTitle = role && role.roleId ? "roleEdit" : "roleCreate";

	if (!role) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={rolesLocation} />} />
					<Divider />
					<CardContent>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="flex-start"
							spacing={4}
						>
							<Grid item xs={3}>
								<StringInput<Role>
									property="roleCode"
									item={role}
									validationState={validationState}
									onChange={handleChange}
									label={getString("roleCode")}
								/>
							</Grid>
							<Grid item xs={2}>
								<StringInput<Role>
									property="roleName"
									item={role}
									validationState={validationState}
									onChange={handleChange}
									label={getString("roleName")}
								/>
							</Grid>
							<Grid item xs={3}>
								<StringInput<Role>
									property="roleNameEn"
									item={role}
									validationState={validationState}
									onChange={handleChange}
									label={getString("roleNameEn")}
								/>
							</Grid>
							<Grid item xs={3}>
								<BoolInput<Role>
									property="isEmployee"
									item={role}
									onChange={handleChange}
									falsyValue={0}
									thrutyValue={1}
									label={getString("roleIsEmployee")}
								/>
							</Grid>
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
