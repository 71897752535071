import { webApiWithResponse } from "../../../../../core/http/httpThunks";
import { ClientContract } from "../../types";

export const updateClientContractApi = (userId: string, contract: ClientContract) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/updclicontr",
		params: {
			userId
		},
		data: contract
	});

export default updateClientContractApi;
