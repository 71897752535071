import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { MarginsDataFilter } from "../../../data/clearing/marginsData/types";
import { LocalizationContext } from "../../../core/localization/Localization";

export const MarginsDataFilterChipsComponent: React.FunctionComponent<MarginsDataFilterChipsProps> = ({
  filter,
  removeFilter,
}) => {
  const { getDateString, getString } = React.useContext(LocalizationContext);
  
  return (
    <Grid container spacing={1}>
      {filter.datefrom && (
        <Grid item>
          <Chip
            label={`${getString("filterFrom")}: ${getDateString(
              new Date(filter.datefrom)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("datefrom")}
          />
        </Grid>
      )}
      {filter.dateto && (
        <Grid item>
          <Chip
            label={`${getString("filterTo")}: ${getDateString(
              new Date(filter.dateto)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("dateto")}
          />
        </Grid>
      )}
      {filter.trade && (
        <Grid item>
          <Chip
            label={filter.trade.tradeData}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("trade")}
          />
        </Grid>
      )}
      {filter.client && (
        <Grid item>
          <Chip
            label={filter.client.cliName}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("client")}
          />
        </Grid>
      )}
    </Grid>
  );
};

interface MarginsDataFilterChipsProps {
  filter: MarginsDataFilter;
  removeFilter: (type: keyof MarginsDataFilter) => void;
}
