import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Bank } from "../types";

export const updateBankApi = (userId: string, bank: Bank) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "listing/updbank",
    params: {
      userId,
    },
    data: bank,
  });

export default updateBankApi;
