import React from "react";
import { Grid, CardContent, CardHeader, Card, Divider } from "@material-ui/core";
import DailyTradesReportTable from "./MonthlyTradesReportTable";
import { usePageStyles } from "../../../pageStyles";
import { PageComponent } from "../../../../core/navigation/types";
import { PageTitle } from "../../../components/common/PageTitle";
import { MonthlyTradesReportFilter } from "./MonthlyTradesReportFilter";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";

const MonthlyTradesReport: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	return (
		<>
			<Grid container className={pageStyles.root}>
				<Grid item xs={12} className={pageStyles.gridItem}>
					<Card>
						<CardHeader
							title={<PageTitle title="monthlyTradeReportReportTitle" />}
							action={<MonthlyTradesReportActions />}
						/>
						<Divider />
						<CardContent>
							<DailyTradesReportTable userAccessRights={userAccessRights} />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

export const MonthlyTradesReportActions: React.FunctionComponent = () => (
	<Grid container>
		<Grid item>
			<MonthlyTradesReportFilter />
		</Grid>
		<Grid item>
			<DownloadButton />
		</Grid>
		<Grid item>
      <DownloadExcelButton />
    </Grid>

	</Grid>
);

export default MonthlyTradesReport;
