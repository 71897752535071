import { ClientOverviewFilter, GetClientOverviewParams } from "../types";
import { DeliveryListData  } from "../../../../clearing/delivery/types";
import { User } from "../../../../auth/types";
import { webApiWithResponse } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

const getDeliveriesWebApi = (
  user: User,
  page: number,
  itemsPerPage: number,
  filter: ClientOverviewFilter
) => {
  const params: GetClientOverviewParams = {
    userid: user.userId,
    fromrow: page * itemsPerPage,
    numofrows: itemsPerPage,
    cliid: filter.client?.cliId,
    datefrom: getParamsDateString(filter.datefrom),
    dateto: getParamsDateString(filter.dateto),
  };

  return webApiWithResponse<DeliveryListData[]>({
    method: "GET",
    url: "clearing/getdlvrgoodslist",
    params,
  });
};

export default getDeliveriesWebApi;
