import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../models";
import { ExchangeMemebersFilter } from "./types";

type TradesReportState = {
	index: PageIndex;
	filter: ExchangeMemebersFilter;
};

export const initialState: TradesReportState = {
	index: {
		itemsPerPage: 20,
		pageNumber: 0,
	},
	filter: {
		brokerId: "",
	  },
};

const name = "exchangeMembers";

const annualTrade = createSlice({
	name,
	initialState,
	reducers: {
		setIndex: (state, action: PayloadAction<PageIndex>) => ({
			...state,
			index: action.payload,
		}),
		setFilter: (state, action: PayloadAction<ExchangeMemebersFilter>) => ({
			...state,
			filter: action.payload,
		  }),
		  removeFilter: (
			state,
			action: PayloadAction<keyof ExchangeMemebersFilter>
		  ) => ({
			...state,
			filter: {
			  ...state.filter,
			  [action.payload]: undefined,
			},
		  }),
  
	},
});


export const {
	setIndex,
	setFilter,
	removeFilter,
  } = annualTrade.actions;
  

export default annualTrade.reducer;
