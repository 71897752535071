import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { NewsData } from "../types";

export const updateNewsApi = (userId: string, news: NewsData) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "news/updnews",
    params: {
      userId,
    },
    data: news,
  });

export default updateNewsApi;
