import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadOrdersReport } from "../thunks/loadOrdersReport";
import { ViewOrder } from "../../../trading/orders/types";
import { PageData } from "../../../models";

export const useOrdersReportData = () => {
  const pageData = useSelector<StoreType, PageData<ViewOrder> | undefined>(
    (s) => s.data.reports.ordersReport.currentPage
  );

  const dispatch = useDispatch();
  const dispatchLoadAMBPage = React.useCallback(
    () => dispatch(loadOrdersReport()),
    [dispatch]
  );

  return [pageData, dispatchLoadAMBPage] as [
    typeof pageData,
    typeof dispatchLoadAMBPage,
  ];
};
