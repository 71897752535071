import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { Pay_Type } from "../../models";

const getPayTypeLabel = (payType?: Pay_Type) => (payType ? payType.payTypeDescr : "");

export const usePayTypes = () => {
	const payTypes = useSelector<StoreType, Pay_Type[]>(store => store.data.initialData.pay_Types);

	return [payTypes, getPayTypeLabel] as [typeof payTypes, typeof getPayTypeLabel];
};
