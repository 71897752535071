import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { City } from "../types";

export const createCityApi = (userId: string, city: City) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "listing/newcity",
    params: {
      userId,
    },
    data: city,
  });

export default createCityApi;
