import { ThunkType } from "../../../../core/store/reducers";
import createProductGroupApi from "../api/createProductGroup";
import updateProductGroupApi from "../api/updateProductGroup";
import { setFetchState } from "../productGroupDataReducer";
import { ProductGroupData } from "../types";

export const upsertProductGroup = (group: ProductGroupData): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = group.prodGroupID
		? await dispatch(updateProductGroupApi(userId, group))
		: await dispatch(createProductGroupApi(userId, group));

	if (result && result.resCode === "OK") {
		dispatch(setFetchState({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
	}
};
