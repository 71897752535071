import { useDispatch, useSelector } from "react-redux";
import { printMarginsThunk } from "../thunks/printMarginsThunk";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { MarginsData } from "../types";
import { setPrintMarginsFetchState } from "../marginsDataSlice";

export const useMarginsPrinter = (payment?: MarginsData) => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.clearing.marginsData.printReportFetchState
	);
	const dispatch = useDispatch();

	const download = React.useCallback(() => {
			dispatch(printMarginsThunk(payment));
	}, [dispatch,payment ]);

	const dispatchSetFetch = React.useCallback(() => {                   //  Ovo da prvo izađe customDialog box
		dispatch(setPrintMarginsFetchState({ type: "started" }))
	},	[dispatch] );
	
	return [download, fetchState, dispatchSetFetch] as [typeof download, typeof fetchState, typeof dispatchSetFetch];
};
