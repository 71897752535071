import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadOrderBook } from "../thunks/loadAllOrderBook";
import { ViewOrder } from "../../orders/types";
import { PageData } from "../../../models";
import { invalidateOrders } from "../orderBookDataSlice";

export const useOrderBookData = () => {
  const pageData = useSelector<StoreType, PageData<ViewOrder> | undefined>(
    (s) => s.data.trading.orderBook.currentPage
  );

  const dispatch = useDispatch();
  const dispatchLoadOrderBookPage = React.useCallback(
    () => dispatch(loadOrderBook()),
    [dispatch]
  );

  const dispatchInvalidateOrders = React.useCallback(
    () => dispatch(invalidateOrders()),
    [dispatch]
  );

  return [pageData, dispatchLoadOrderBookPage, dispatchInvalidateOrders] as [
    typeof pageData,
    typeof dispatchLoadOrderBookPage,
    typeof invalidateOrders,
  ];
};
