import { setReportData } from "../dailySecurityUXSlice";
import { setData } from "../dailySecurityDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import React from "react";
import { DailySecurityReportFilter } from "../types";

export const useCurrentSecurityReportFilter = () => {
	const filter = useSelector<StoreType, DailySecurityReportFilter>(
		store => store.ux.reports.securityCommission.dailySecurity.filter
	);
	const dispatch = useDispatch();
	const dispatchSetReportDate = React.useCallback(
		(reportDate: string) => {
			dispatch(setReportData(reportDate));
			dispatch(setData());
		},
		[dispatch]
	);

	return [filter, dispatchSetReportDate] as [typeof filter, typeof dispatchSetReportDate];
};
