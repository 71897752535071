import { webApi } from "../../../../../core/http/httpThunks";

export const deleteAttributeApi = (userId: string, prodattrid: string) =>
	webApi<string>({
		method: "DELETE",
		url: "listing/delprodattr",
		params: {
			userId,
			prodattrid
		},
	});

export default deleteAttributeApi;
