import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertPayment } from "../thunks/upsertPayment";
import { setFetchState } from "../paymentsDataSlice";
import { PaymentData } from "../types";

export const usePaymentUpsert = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.clearing.payments.fetchState
  );

  const dispatch = useDispatch();
  const dispatchUpsertPayment = React.useCallback(
    (payment: PaymentData) => dispatch(upsertPayment(payment)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchUpsertPayment] as [
    typeof fetchState,
    typeof dispatchUpsertPayment
  ];
};
