import React from "react";
import { useEditAlarmState } from "../../../data/surveillance/alarmParameters/hooks/useEditAlarmState";
import { useAlarmValidator } from "../../../data/surveillance/alarmParameters/hooks/useAlarmValidator";
import { useAlarmUpsert } from "../../../data/surveillance/alarmParameters/hooks/useAlarmUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { AlarmType } from "../../../data/models";
import { alarmsLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { ProductSelect } from "../../components/listing/ProductSelect";
import { AlarmTypeSelect } from "./AlarmTypeSelect";

export interface EditAlarmyInfo {
	almId?: string;
}

export interface EditAlarmProps extends RouteComponentProps<EditAlarmyInfo> {}

export const EditAlarmsPage: React.FunctionComponent<EditAlarmProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [alarm, editAlarm, changed] = useEditAlarmState(props.match.params.almId);
	const [validationState, validate] = useAlarmValidator();
	const [fetchState, upsert] = useAlarmUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editAlarm(event.target.name as any, event.target.value);
		},
		[editAlarm]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(alarmsLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(alarm);

			if (normalized) {
				upsert(normalized);
			}
		}
	}, [alarm, validate, fetchState, history, upsert]);

	const pageTitle = alarm && alarm.almId ? "alarmEdit" : "alarmCreate";

	if (!alarm) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={alarmsLocation} />} />
					<Divider />
					<CardContent>
						<Grid container direction="column" spacing={6}>
							<Grid item xs={4}>
								<AlarmTypeSelect alarmType={alarm.almType} handleChange={handleChange} />
							</Grid>
							{(alarm.almType === AlarmType.Client ||
								alarm.almType === AlarmType.ProductClient) && (
								<Grid item xs={4}>
									<ClientSelect
										label={getString("alarmClient")}
										predicate={client => client.cliId === alarm.clientId}
										onChangeItem={client =>
											editAlarm("clientId", client ? client.cliId : undefined)
										}
										required={validationState.clientId !== undefined}
										error={
											validationState.clientId !== undefined &&
											!validationState.clientId
										}
									/>
								</Grid>
							)}
							{(alarm.almType === AlarmType.Product ||
								alarm.almType === AlarmType.ProductClient) && (
								<Grid item xs={3}>
									<ProductSelect
										label={getString("alarmProduct")}
										predicate={prod => prod.prodId === alarm.productId}
										onChangeItem={prod => {
											editAlarm("productId", prod ? prod.prodId : undefined);
										}}
										required={validationState.productId !== undefined}
										error={
											validationState.productId !== undefined &&
											!validationState.productId
										}
									/>
								</Grid>
							)}
						</Grid>

						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
