import TradeByBrokersReport from "./TradeByBrokersReport";
import { NavigationItem } from "../../../../core/navigation/types";
import { tradeByBrokersLocation } from "./Locations";

export const tradeByBrokersReportComponents: NavigationItem[] = [
  {
    Component: TradeByBrokersReport,
    isMenuItem: true,
    path: tradeByBrokersLocation,
  },
];
