import React from "react";
import { useWeightedPricesColumns } from "./useWeightedPricesReportColumns";
import { AccessRights } from "../../../../core/navigation/types";
import { WeightedPricesReportData } from "../../../../data/reports/exchbusiness/weightedPrices/types";
import { ControledTable } from "../../../components/common/ControledTable";
import { useWeightedPriceReportData } from "../../../../data/reports/exchbusiness/weightedPrices/hooks/useWeightedPriceReportData";
import { useWeightedPriceReportIndex } from "../../../../data/reports/exchbusiness/weightedPrices/hooks/useWeightedPriceReportIndex";

export interface WeightedPricesTableProps {
  userAccessRights: AccessRights;
}

type WeightedPricesTablePropsType = WeightedPricesTableProps;

export const WeightedPricesReportTable: React.FunctionComponent<WeightedPricesTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useWeightedPricesColumns();
  const [data, load] = useWeightedPriceReportData();
  const [index, setIndex] = useWeightedPriceReportIndex();

  return (
    <ControledTable<WeightedPricesReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default WeightedPricesReportTable;
