import React from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { DateInput } from "../../../components/common/DateInput";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { ComReviewReportFilter } from "../../../../data/reports/exchbusiness/commReview/types";
import { useAuth } from "../../../../data/auth/hooks";
import { DisplayDrawerContext } from "../../../../layout/DisplayDrawerContext";
import { NumberInput } from "../../../components/common/NumberInput";
import { ProductSelect } from "../../../components/listing/ProductSelect";
import { OrderSideSelect } from "../../../trading/orders/OrderSideSelect";
import { ClientSelect } from "../../../components/listing/ClientSelect";
import { EmployeeSelect } from "../../../components/listing/EmployeeSelect";

interface CommReviewFilterEditorProps {
  filter?: ComReviewReportFilter;
  editFilter: (prop: keyof ComReviewReportFilter, value: any) => void;
  save: () => void;
}

export const CommReviewFilterEditor: React.FunctionComponent<CommReviewFilterEditorProps> = ({
  filter,
  editFilter,
  save,
}) => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const { getString } = React.useContext(LocalizationContext);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      editFilter(event.target.name as any, event.target.value);
    },
    [editFilter]
  );

  if (!filter) {
    return null;
  }

  return (
    <Paper
      style={{
        paddingLeft: "70px",
        paddingRight: "70px",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Grid container direction="column" spacing={6}>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={6}>
            <DateInput
              label={getString("exchDateFrom")}
              value={filter?.datefrom || null}
              onChange={(date) => editFilter("datefrom", date?.toISOString())}
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              label={getString("exchDateTo")}
              value={filter?.dateto || null}
              onChange={(date) => editFilter("dateto", date?.toISOString())}
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12}>
            <ProductSelect
              label={getString("exchProduct")}
              predicate={(prod) => prod.prodId === filter.product?.prodId}
              onChangeItem={(prod) => {
                editFilter("product", prod);
              }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <ClientSelect
            label={getString("orderClientName")}
            predicate={(client) => client.cliId === filter.client?.cliId}
            onChangeItem={(client) => editFilter("client", client)}
          />
        </Grid>
        <Grid item>
          <EmployeeSelect
            label={getString("orderbrokName")}
            predicate={(employee) => employee.empId === filter.broker}
            onChangeItem={(employee) => {
              editFilter("broker", employee);
            }}
          />
        </Grid>

        <Grid item>
          <OrderSideSelect
            handleChange={(e) => editFilter("side", e.target.value)}
            side={filter?.side}
            nullable
          />
        </Grid>

        <Grid item container direction="row-reverse" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                save();
                showWithDrawer(undefined);
              }}
            >
              {getString("orderFilterConfirm")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
