import React from "react";
import { useEditGuaranteeState } from "../../../data/clearing/guarantees/hooks/useEditGuaranteeState";
import { useGuaranteeValidator } from "../../../data/clearing/guarantees/hooks/useGuaranteeValidator";
import { useGuaranteeUpsert } from "../../../data/clearing/guarantees/hooks/useGuaranteeUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent, Paper, TextField, Typography,} from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { GuaranteeData } from "../../../data/clearing/guarantees/types";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { Status } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { guaranteeLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { NumberInput } from "../../components/common/NumberInput";
import { DateInput } from "../../components/common/DateInput";
import { CurrencySelect } from "../../components/common/CurrencySelect";
import { PaymentTypeGuarSelect } from "../../components/common/PaymentTypeGuarSelect";
import { FiltredOrdersSelect } from "../../components/trading/FiltredOrdersSelect";
import { OrderSimpleData, SimpleOrderFilter } from "../../../data/trading/orders/types";
import { useSimpleOrdersData } from "../../../data/trading/orders/hooks/useSimpleOrdersData";
import { BankGuaranteeSelect } from "../../components/clearing/BankGuaranteeSelect";
import { BankGuaranteeFilter, SimplBankGuaranteeFilter } from "../../../data/clearing/bankguarantees/types";

export interface EditGuaranteeInfo {
	payId?: string;
}

export interface EditGuaranteeProps extends RouteComponentProps<EditGuaranteeInfo> {}

export const EditGuaranteePage: React.FunctionComponent<EditGuaranteeProps> = props => {
	const { getString, getFormatedNumberString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [guarantee, editGuarantee, changed] = useEditGuaranteeState(props.match.params.payId);

	const [validationState, validate] = useGuaranteeValidator();
	const [fetchState, upsert] = useGuaranteeUpsert();
	const history = useHistory();

	const [orderFilter, setOrderFilter] = React.useState<SimpleOrderFilter | undefined>(
		createOrderFilter(guarantee)
	);

	const [bnkGrntFilter, setbnkGrntFilter] = React.useState<SimplBankGuaranteeFilter | undefined>(
		createBnkGrntFilter(guarantee)
	);

//	const [ord] = useSimpleOrdersData(guarantee.orderId);
	
	const [selectedOrder, setSelectedOrder] = React.useState<OrderSimpleData | null>(null);

	React.useEffect(() => {
		setOrderFilter(createOrderFilter(guarantee));
	}, [guarantee, setOrderFilter]);

	React.useEffect(() => {
		setbnkGrntFilter(createBnkGrntFilter(guarantee));
	}, [guarantee, setbnkGrntFilter]);


	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editGuarantee(event.target.name as any, event.target.value);
		},
		[editGuarantee]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(guaranteeLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(guarantee);

			if (normalized) {
				upsert(normalized);
			}
		}
	}, [guarantee, validate, fetchState, history, upsert]);

	const pageTitle = guarantee && guarantee.payId ? "guarEdit" : "guarCreate";

	if (!guarantee) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={guaranteeLocation} />} />
					<Divider />
					<CardContent>
						<Grid item container direction="row" justify="flex-start" spacing={3}>
							<Grid item container direction="row" justify="flex-start" spacing={3} xs={6}>
								<Grid item xs={7}>
									<DateInput
										label={getString("paymentDataTime")}
										value={guarantee.payDate || null}
										onChange={date => editGuarantee("payDate", date)}
										error={
											validationState.payDate !== undefined && !validationState.payDate
										}
										required={validationState.payDate !== undefined}
									/>
								</Grid>
								<Grid item xs={7}>
									<PaymentTypeGuarSelect
										label={getString("paymentPayType")}
										predicate={payTypes => payTypes.ftValue === guarantee.payType}
										onChangeItem={guarantee =>{
											editGuarantee("payType",guarantee ? guarantee.ftValue : undefined)
											editGuarantee("hasOrdTran", guarantee ? guarantee.hasOrdTran : undefined)
											setSelectedOrder(null)
											editGuarantee("orderId", undefined)
											editGuarantee("grntId", undefined)}
//											editGuarantee("amt",  undefined)}
										}
										error={
											validationState.payType !== undefined && !validationState.payType
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<ClientSelect
										label={getString("paymentClientName")}
										predicate={client => client.cliId === guarantee.clientId}
										onChangeItem={client => {
											setSelectedOrder(null)
											editGuarantee("clientId", client ? client.cliId : undefined);
										}}
										required={validationState.clientId !== undefined}
										error={
											validationState.clientId !== undefined &&
											!validationState.clientId
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<BankGuaranteeSelect
										label={getString("paymentBankGuarantee")}
										filter={bnkGrntFilter}
										predicate={bnkgrnt => bnkgrnt.grntId === guarantee.grntId}
										onChangeItem={bnkgrnt => {
											editGuarantee("grntId", bnkgrnt ? bnkgrnt.grntId : undefined);
											editGuarantee("amt", bnkgrnt ? bnkgrnt.amt : undefined);
										}}
										required={ guarantee.hasOrdTran===3 }
										error={
											(validationState.grntId !== undefined && !validationState.grntId) &&  guarantee.hasOrdTran===3
										}
										disabled={ guarantee.hasOrdTran!==3  }
									/>
								</Grid>
								<Grid item xs={12}>
									<FiltredOrdersSelect
										label={getString("paymentClientOrders")}
										filter={orderFilter}
										predicate={order => order.ordId === guarantee.orderId}
										onChangeItem={order => {
											setSelectedOrder(order);
											editGuarantee("orderId", order ? order.ordId : undefined);
										}}
										required={  guarantee.hasOrdTran===1 }
										error={
											(validationState.orderId !== undefined && !validationState.orderId) && guarantee.hasOrdTran===1
										}
										disabled={ guarantee.hasOrdTran!==1 }
									/>
								
								</Grid>
								<Grid
									container
									item
									direction="row"
									justify="flex-start"
									alignItems="center"
									spacing={3}
								>
									<Grid item xs={7}>
										<NumberInput<GuaranteeData>
											property="amt"
											item={guarantee}
											validationState={validationState}
											onChange={handleChange}
											label={getString("paymentAmount")}
											money
										/>
									</Grid>
									<Grid item xs={7}>
										<CurrencySelect
											label={getString("paymentCurrCode")}
											predicate={currency => currency.currId === guarantee.currId}
											onChangeItem={currency =>
												editGuarantee("currId", currency?.currId)
											}
											required={validationState.currId !== undefined}
											error={
												validationState.currId !== undefined &&
												!validationState.currId
											}
											fullWidth
										/>
									</Grid>
								</Grid>
								<Grid item xs={1}>
									<BoolInput<GuaranteeData>
										property="status"
										item={guarantee}
										onChange={handleChange}
										falsyValue={Status.Inactive}
										thrutyValue={Status.Active}
										label={getString("paymentStatus")}
									/>
								</Grid>
							</Grid>
							<Grid item container direction="column" justify="flex-start" spacing={3} xs={6}>
								{selectedOrder && (
									<Paper
										style={{ height: "100%", paddingLeft: "10px", paddingTop: "30px" }}
									>
										<Grid container direction="column" justify="flex-start" spacing={3}>
											<Grid item xs={6}>
												<Typography variant="h5">
													{getString("orderParameters")}
												</Typography>
											</Grid>
											<Grid item container direction="row" spacing={3}>
												<Grid item xs={6}>
													<TextField
														label={getString("orderPrice")}
														value={getFormatedNumberString(
															selectedOrder.price,
															true
														)}
														InputProps={{
															readOnly: true,
														}}
														fullWidth
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														label={getString("orderQty")}
														value={getFormatedNumberString(
															selectedOrder.qty
														)}
														InputProps={{
															readOnly: true,
														}}
														fullWidth
													/>
												</Grid>
											</Grid>
											<Grid item container direction="row" spacing={3}>
												<Grid item xs={6}>
													<TextField
														label={getString("orderDepositeAmount")}
														value={getFormatedNumberString(
															selectedOrder.depAmt || 0,
															true
														)}
														InputProps={{
															readOnly: true,
														}}
														fullWidth
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														label={getString("orderDepositeAmountPayed")}
														value={getFormatedNumberString(
															selectedOrder.depAmtPayed || 0,
															true
														)}
														InputProps={{
															readOnly: true,
														}}
														fullWidth
													/>
												</Grid>
											</Grid>
										</Grid>
									</Paper>
								)}
							</Grid>
						</Grid>

						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

const createOrderFilter = (guarantee?: GuaranteeData): SimpleOrderFilter | undefined => {
	return guarantee && guarantee.clientId
		? {
				cliid: guarantee.clientId,
				status: -2,
		  }
		: undefined;
};

const createBnkGrntFilter = (guarantee?: GuaranteeData): SimplBankGuaranteeFilter | undefined => {
	return guarantee && guarantee.clientId 	? {	cliid: guarantee.clientId, } : undefined;
};
