import { ThunkType } from "../../../../core/store/reducers";
import { printGuarantee } from "../api/printGuarantee";
import fileDownload from "js-file-download";
import { setPrintGuaranteeFetchState } from "../guaranteesDataSlice";
import { GuaranteeData } from "../types";

export const printGuaranteeThunk = (guarantee?: GuaranteeData): ThunkType => async (dispatch, getStore) => {
	const store = getStore();
	
	if (store.data.auth.current.status !== "successful" || !guarantee || !guarantee.payId) {
		return undefined;
	}

	const filename = `GuaranteeConfirmation_${guarantee.payId}.pdf`
	const user = store.data.auth.current.user;
	dispatch(setPrintGuaranteeFetchState({ type: "started" }));
	const guaranteesReport = await dispatch(printGuarantee(user, guarantee.payId));
	if (guaranteesReport) {
		fileDownload(new Blob([guaranteesReport as any], { type: "application/pdf" }), filename);
	}
	dispatch(setPrintGuaranteeFetchState({ type: "not-started" }));
};
