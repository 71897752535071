import { ThunkType } from "../../../../core/store/reducers";
import { printMargins } from "../api/printMargins";
import fileDownload from "js-file-download";
import { setPrintMarginsFetchState } from "../marginsDataSlice";
import { MarginsData } from "../types";


export const printMarginsThunk = (margins?: MarginsData): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful" || !margins || !margins.mrgId) {
		return undefined;
	}
	const lan = store.ux.localization.language
	const filename = `MarginsData_${margins.mrgId}.pdf`
	const user = store.data.auth.current.user;
	dispatch(setPrintMarginsFetchState({ type: "started" }));
	const marginReport = await dispatch(printMargins(user, margins.mrgId));
	if (marginReport) {
		fileDownload(new Blob([marginReport as any], { type: "application/pdf" }), filename);
	}
	dispatch(setPrintMarginsFetchState({ type: "not-started" }));
};
