import { ThunkType } from "../../../../core/store/reducers";
import { getWeeklyClientReport } from "../api/getWeeklyClientReport";
import fileDownload from "js-file-download";
import { setFetchState } from "../weeklyTradeDataSlice";
import moment from "moment";

export const downloadReport = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.reports.weeklyTrade;
  const user = store.data.auth.current.user;
  dispatch(setFetchState({ type: "started" }));
  const weeklyReport = await dispatch(getWeeklyClientReport(user, filter));
  if (weeklyReport) {
    fileDownload(
      new Blob([weeklyReport as any], { type: "application/pdf" }),
      "WeeklyCTrading_" + moment().format("YYYYMMDDhhmmss") + ".pdf"
    );
  }
  dispatch(setFetchState({ type: "not-started" }));
};
