import { InvoiceData, InvoiceValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const invoiceValidationState: InvoiceValidationState = {
  invDate: true,
  clientId: true,
  invNo: true,
  invType: true,
  tranDate: true,
  dueDate: true,
  tranId: true,
  tradeAmt: true,
  currId: true,
  commPerc: true,
  amt: true,
  vatPerc: true,
  vatAmt: true,
  invAmt: true,
  status: true,
};

export const useInvoiceValidator = createUseValidator<
  InvoiceData,
  InvoiceValidationState
>(invoiceValidationState);
