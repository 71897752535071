import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { AlarmData } from "../types";
import { loadAlarm } from "../thunks/loadAlarm";

export const useDisplayAlarm = (almID?: string) => {
  const storeAlarm = useSelector<StoreType, AlarmData | undefined>(
    (store) => store.data.surveillance.alarms.displayedAlarm
  );

  const displayedAlarm =
  storeAlarm && storeAlarm.almId === almID
      ? storeAlarm
      : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (almID) {
      displatch(loadAlarm(almID));
    }
  }, [displatch, almID]);

  return displayedAlarm;
};
