import React from "react";
import { useEditNewsState } from "../../../data/reports/news/hooks/useEditNewsState";
import { useNewsValidator } from "../../../data/reports/news/hooks/useNewsValidator";
import { useNewsUpsert } from "../../../data/reports/news/hooks/useNewsUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent, FormControl, Button} from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { NewsData } from "../../../data/reports/news/types";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { Status } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { newsLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { StringInput } from "../../components/common/StringInput";
import { DateInput } from "../../components/common/DateInput";

export interface EditNewsInfo {
  newsId?: string;
}

export interface EditNewsProps
  extends RouteComponentProps<EditNewsInfo> {}

export const EditNewsPage: React.FunctionComponent<EditNewsProps> = (
  props
) => {
  const { getString } = React.useContext(LocalizationContext);
  const pageStyles = usePageStyles();

  const [news, editNews, changed] = useEditNewsState(
    props.match.params.newsId
  );
  const [validationState, validate] = useNewsValidator();
  const [fetchState, upsert] = useNewsUpsert();
  const history = useHistory();

  const unsavedChanges = fetchState.type === "not-started" && changed;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      editNews(event.target.name as any, event.target.value);
    },
    [editNews]
  );

  const save = React.useCallback(() => {
    if (fetchState.type === "successful") {
      history.push(newsLocation);
    } else if (fetchState.type !== "started") {
      const normalized = validate(news);
      if (normalized) {
        upsert(normalized);
      }
    }
  }, [news, validate, fetchState, history, upsert]);

  const pageTitle = news && news.newsId ? "newsEditTitle" : "newsCreateTitle";

  if (!news) {
    return null;
  }

  return (
    <Grid container className={pageStyles.root}>
      <Grid item xs={12} className={pageStyles.gridItem}>
        <Card>
          <CardHeader
            title={
              <PageTitle title={pageTitle} backLocation={newsLocation} />
            }
          />
          <Divider />
          <CardContent>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={2}>
								<DateInput
									label={getString("newsTime")}
									value={news.newsTime || null}
									onChange={newsTime => editNews("newsTime", newsTime)}
									error={
										validationState.newsTime !== undefined &&
										!validationState.newsTime
									}
									required={validationState.newsTime !== undefined}
								/>
							</Grid>
						</Grid>

             <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
              <Grid item xs={6}>
                <StringInput<NewsData>
                  property="title"
                  item={news}
                  validationState={validationState}
                  onChange={handleChange}
                  label={getString("newsTitle")}
                />
              </Grid>
              { !news.newsId && (<Grid item xs={6}>
                <FormControl>
                    <input type='file' name='fileName1' accept="application/pdf" onChange={e=>{if(e.target.files !== null )  editNews("fileName1",e.target!.files[0]!)}} />
                </FormControl>
              </Grid>  )}
             </Grid>

             <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
              <Grid item xs={6}>
                <StringInput<NewsData>
                  property="titleEn"
                  item={news}
                  validationState={validationState}
                  onChange={handleChange}
                  label={getString("newsTitleen")}
                />
              </Grid>
              { !news.newsId && ( <Grid item xs={6}>
                <FormControl>
                  <input type='file' name='fileName2'  accept="application/pdf" onChange={e=>{if(e.target.files !== null) editNews("fileName2",e.target!.files[0]!)}}/>
                </FormControl>
              </Grid> )}
            </Grid>

            <Grid
                container
                item
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={1}>
                  <BoolInput<NewsData>
                    property="status"
                    item={news}
                    onChange={handleChange}
                    falsyValue={Status.Inactive}
                    thrutyValue={Status.Active}
                    label={getString("stockFeeStatus")}
                  />
                </Grid>
            </Grid>

            <FabSave fetchState={fetchState} onClick={save} />
            <LeavePrompt shouldShow={unsavedChanges} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

