import React from "react";
import { PaginationTable, PaginationDataProps } from "../../components/common/PagginationTable";
import { useTradeColumns } from "./useTradesReportColumns";
import { AccessRights } from "../../../core/navigation/types";
import { TradeList } from "../../../data/trading/trades/types";

export interface OrderTableProps {
	userAccessRights: AccessRights;
	getData: () => void;
}

type OrderTablePropsType = OrderTableProps & PaginationDataProps<TradeList>;

const OrdersTable: React.FunctionComponent<OrderTablePropsType> = ({
	userAccessRights,
	getData,
	...tableProps
}) => {
	const columns = useTradeColumns();

	return (
		<PaginationTable<TradeList>
			{...tableProps}
			getData={getData}
			title=""
			columns={columns}
			options={{
				actionsColumnIndex: -1,
				search: false,
				toolbar: false,
			}}
		/>
	);
};

export default OrdersTable;
