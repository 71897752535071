import React from "react";
import { SnackbarMessage } from "../types";
import { dismiss, setShownId } from "../snackbarDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../core/store/reducers";

export const useNextMessage = (max: number) => {
	const messages = useSelector<StoreType, SnackbarMessage[]>(store => store.data.snackbar.messages);

	const shownMessage = React.useMemo(() => {
		for (let i = 0; i < messages.length && i < max; i++) {
			if (!messages[i].shownId) {
				return messages[i];
			}
        }
        return undefined;
	}, [messages, max]);

	return [shownMessage] as [typeof shownMessage];
};

export const useSnackbarMessage = (id: string) => {
	const messages = useSelector<StoreType, SnackbarMessage[]>(store => store.data.snackbar.messages);
	const message = messages.find(m => m.id === id);

	const dispatch = useDispatch();
	const dismissMessage = React.useCallback(() => {
		if (message) {
			dispatch(dismiss(message.id));
		}
    }, [message, dispatch]);

	const dispatchSetShownId = React.useCallback((shownId: string) => {
		if (message) {
			dispatch(setShownId({
                messageId: message.id,
                shownId: shownId
            }));
		}
	}, [message, dispatch]);

	return [message, dismissMessage, dispatchSetShownId] as [typeof message, typeof dismissMessage, typeof dispatchSetShownId];
};
