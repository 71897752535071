import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { Currency } from "../../../data/listing/currencies/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewCurrency } from "./DisplayCurrency";
import { getCurrencyEditLocation } from "./Locations";
import { AccessRights } from "../../../core/navigation/types";

export const useCurrenciesActions = (userAccessRights: AccessRights) => {
	const { getString } = useContext(LocalizationContext);
	const onShowRole = useViewCurrency();
	const onEditRole = useEditCurrencyAction();

	return useMemo(() => {
		const result: Action<Currency>[] = [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tableShow"),
				onClick: (event, rowData) => onShowRole(rowData as Currency),
			},
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditRole(rowData as Currency),
				hidden: userAccessRights===AccessRights.ReadOnly
			},
		];

		return result;
	}, [onShowRole, onEditRole, getString]);
};

export const useEditCurrencyAction = () => {
	const history = useHistory();
	return React.useCallback((currency: Currency) => history.push(getCurrencyEditLocation(currency.currId)), [
		history,
	]);
};
