import { paymentComponents } from "./payments/navigationComponents";
import { ctrlGoodsComponents } from "./ctrlgoods/navigationComponents";
import { deliveryComponents } from "./delivery/navigationComponents";
import { disputeComponents } from "./dispute/navigationComponents";
import { guaranteeComponents } from "./guarantees/navigationComponents";
import { stockFeesComponents } from "./stockFees/navigationComponents";
import { transportFeesComponents } from "./transportFees/navigationComponents";
import { transportAndStorageComponents } from "./transportAndStorage/navigationComponents";
import { tradingDataComponents } from "./tradingData/navigationComponents";
import { marginsDataComponents } from "./marginsData/navigationComponents";
import { bankguaranteeComponents } from "./bankguarantees/navigationComponents";

export const clearingComponents = [
	...paymentComponents,
	...ctrlGoodsComponents,
	...deliveryComponents,
	...disputeComponents,
	...guaranteeComponents,
	...stockFeesComponents,
	...transportFeesComponents,
	...transportAndStorageComponents,
	...tradingDataComponents,
	...marginsDataComponents,
	...bankguaranteeComponents,
];
