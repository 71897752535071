import { useDispatch, useSelector } from "react-redux";
import { printContractThunk } from "../../thunks/clientContract/printContractThunk";
import React from "react";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";
import { ClientContract } from "../../types";
import { setPrintContractFetchState } from "../../clientsDataReducer";

export const useContractPrinter = (contract?: ClientContract) => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.listing.clients.downloadContractFetchState
	);
	const dispatch = useDispatch();

	const download = React.useCallback(() => {
			dispatch(printContractThunk(contract));
	}, [dispatch,contract]);

	const dispatchSetFetch = React.useCallback(() => {                   //  Ovo da prvo izađe customDialog box
		dispatch(setPrintContractFetchState({ type: "started" }))
	},	[dispatch] );
	
	return [download, fetchState, dispatchSetFetch] as [typeof download, typeof fetchState, typeof dispatchSetFetch];
};
