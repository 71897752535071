import React from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { DateInput } from "../../components/common/DateInput";
import { LocalizationContext } from "../../../core/localization/Localization";
import { AntiLaunderingReportFilter } from "../../../data/reports/amlaundering/types";
import { useAuth } from "../../../data/auth/hooks";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { NumberInput } from "../../components/common/NumberInput";

interface AntiLaunderingFilterEditorProps {
  filter?: AntiLaunderingReportFilter;
  editFilter: (prop: keyof AntiLaunderingReportFilter, value: any) => void;
  save: () => void;
}

export const AntiMnyLndCommFilterEditor: React.FunctionComponent<AntiLaunderingFilterEditorProps> = ({
  filter,
  editFilter,
  save,
}) => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const { getString } = React.useContext(LocalizationContext);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      editFilter(event.target.name as any, event.target.value);
    },
    [editFilter]
  );

  if (!filter) {
    return null;
  }

  return (
    <Paper
      style={{
        paddingLeft: "70px",
        paddingRight: "70px",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Grid container direction="column" spacing={6}>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={6}>
            <DateInput
              label={getString("orderFilterFrom")}
              value={filter?.datefrom || null}
              onChange={(date) => editFilter("datefrom", date?.toISOString())}
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              label={getString("orderFilterTo")}
              value={filter?.dateto || null}
              onChange={(date) => editFilter("dateto", date?.toISOString())}
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12}>
            <NumberInput<AntiLaunderingReportFilter>
              property="limit"
              item={filter}
              onChange={handleChange}
              label={getString("amtimnylndcommLimit")}
            />
          </Grid>
        </Grid>

        <Grid item container direction="row-reverse" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                save();
                showWithDrawer(undefined);
              }}
            >
              {getString("orderFilterConfirm")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
