import { useDispatch, useSelector } from "react-redux";
import { downloadTradesReportExcel } from "../thunks/downloadReportExcel";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";

export const useTradesExcelDownloader = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.reports.tradesReport.downloadReportFetchState
	);
	const dispatch = useDispatch();
	const download = React.useCallback(() => dispatch(downloadTradesReportExcel()), [dispatch]);

	return [download, fetchState] as [typeof download, typeof fetchState];
};
