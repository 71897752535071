import { ThunkType } from "../../../../core/store/reducers";
import { getCtrlGoodsExcel } from "../api/getCtrlGoodsExcel";
import fileDownload from "js-file-download";
import { setDownloadFetchState } from "../ctrlGoodsDataSlice";

export const downloadCtrlGoodsExcel = (): ThunkType => async (
  dispatch,
  getStore
) => {

  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.clearing.controls;
  const user = store.data.auth.current.user;

  dispatch(setDownloadFetchState({ type: "started" }));
  const dailyTrading = await dispatch(getCtrlGoodsExcel(user, filter));
  if (dailyTrading) {
    fileDownload(
      new Blob([dailyTrading as any], {
        type: "application/vnd.openxmlformat",
      }),
      "CtrlGoods.xlsx"
    );
  }
  dispatch(setDownloadFetchState({ type: "not-started" }));
};
