import { combineReducers } from "@reduxjs/toolkit";
import monthlyTrade from "./monthlyTrade/monthlyTradeDataSlice";
import dailySecurity from "./securityDaily/dailySecurityDataSlice";
import annualSecurity from "./securityAnnual/annualSecurityDataSlice";
import quarterlylSecurity from "./securityQuarterly/quarterlySecurityDataSlice";

const securityCommissionReportDataReduer = combineReducers({
	monthlyTrade,
	dailySecurity,
	annualSecurity,
	quarterlylSecurity,
});

export default securityCommissionReportDataReduer;
