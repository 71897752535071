import { Commission } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";

interface IProductData {
	all?: Commission[];
	searchTerm: string;
	displayData?: Commission[];
	displayed?: Commission;
	fetchState: FetchStateType;
	fetchStateDetail: FetchStateType;
}

const initialState: IProductData = {
	searchTerm: "",
	fetchState: { type: "not-started" },
	fetchStateDetail: { type: "not-started" },
};

const name = "commissions";

const commissionsSlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<Commission[] | undefined>) => ({
			...state,
			all: action.payload,
			displayData: filterData(action.payload, state.searchTerm),
		}),
		setSearchTerm: (state, action: PayloadAction<string>) => ({
			...state,
			searchTerm: action.payload.toLocaleLowerCase(),
			displayData: filterData(state.all, action.payload),
		}),
		setDisplayed: (state, action: PayloadAction<Commission | undefined>) => ({
			...state,
			displayed: action.payload,
		}),
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayed: action.payload.type === "successful" ? undefined : state.displayed,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			fetchState: getFetchStateChange(state.fetchState, action.payload),
		}),
		setFetchStateAttribute: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			fetchStateDetail: getFetchStateChange(state.fetchStateDetail, action.payload),
		}),
	},
});

const filterData = (data?: Commission[], searchTerm?: string) =>
	searchTerm === undefined || searchTerm === ""
		? data
		: data?.filter(
				e =>
					(e.commName &&
						e.commName
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase())) ||
					(e.commCode &&
						e.commCode
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase())) ||
					(e.commTypeName &&
						e.commTypeName
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase()))
		  );

export const { setData, setSearchTerm, setDisplayed, setFetchState, setFetchStateAttribute } = commissionsSlice.actions;

export default commissionsSlice.reducer;
