import { ThunkType } from "../../../../core/store/reducers";
import { setAlarm, invalidateAlarm } from "../alarmDataSlice";
import { setAlarmIndex } from "../alarmUXSlice";
import { getAlarmsWebApi } from "../api/getAlarms";
import { PageIndex, PageDataStatus } from "../../../models";

export const loadAlarms = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const { pageNumber, itemsPerPage } = store.ux.surveillance.alarms.index;
  const { filter } = store.ux.surveillance.alarms;
  const user = store.data.auth.current.user;

  const alarmResponse = await dispatch(
    getAlarmsWebApi(user, pageNumber, itemsPerPage, filter)
  );

  if (!alarmResponse || alarmResponse.resCode !== "OK") {
    return;
  }

  const alarm = {
    currentPageData: alarmResponse.obj,
    totalCount: Number(alarmResponse.resDesc) || 0,
    status: PageDataStatus.DataCurrent,
  };

  dispatch(setAlarm(alarm));
};

export const setAlarmIndexThunk = (index: PageIndex): ThunkType => (
  dispatch
) => {
  dispatch(setAlarmIndex(index));
  dispatch(invalidateAlarm());
};
