import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";
import { deleteAttribute } from "../../thunks/attributes/deleteAttribute";
import { setFetchStateAttribute } from "../../productsDataReducer";

export const useAttributeDelete = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.products.fetchStateAttribute
	);

	const dispatch = useDispatch();
	const dispatchDeleteAttribute = React.useCallback(
		(id: string) => dispatch(deleteAttribute(id)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateAttribute({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchDeleteAttribute] as [typeof fetchState, typeof dispatchDeleteAttribute]
};
