import React from "react";
import { TransportFee } from "../../../data/clearing/transportfees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayTransportFeeMainAttributesProps {
  trfee: TransportFee;
}

export const DisplayTransportFeeMainAttributes: React.FunctionComponent<DisplayTransportFeeMainAttributesProps> = ({
  trfee,
}) => {
  const style = useDisplaySingleEmementStyles();
  const {
    getString,
    getDateString,
    getFormatedNumberString,
  } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("transFeeStartDate")}</span>
        <span className={style.value}>{getDateString(trfee.startDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("transFeeDistance")}</span>
        <span className={style.value}>
          {getFormatedNumberString(trfee.distance, true)}
        </span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("transFeePice")}</span>
        <span className={style.value}>
          {getFormatedNumberString(trfee.price, true)}
        </span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("transFeeStatus")}</span>
        <span className={style.value}>
          <DisplayBoolNumber
            value={trfee.status}
            thrutyValue={Status.Active}
            className={style.icon}
          />
        </span>
      </div>
    </div>
  );
};
