import React from "react";
import { ViewOrder } from "../../../data/trading/orders/types";
import { OrderSide } from "../../../data/models";
import { useOrderSideOptionsTranslator } from "../../../data/trading/orders/hooks/useOrderSideOptions";
import { makeStyles, createStyles } from "@material-ui/core";

export const useOrderRowColor = () => {
  const sideTranslator = useOrderSideOptionsTranslator();
  return React.useCallback((order: ViewOrder) => ({
    backgroundColor: order.side === sideTranslator(OrderSide.Sell) ? getSideColor(OrderSide.Sell) : getSideColor(OrderSide.Buy)
  }), [sideTranslator]);
};

export const getSideColor = (side: OrderSide) => side === OrderSide.Sell ? "#ffebee" : "#e1f5fe"

export const useOrderSideStyles = makeStyles(() => {
	return createStyles({
		sell: {
			background: getSideColor(OrderSide.Sell),
		},
		buy: {
			background: getSideColor(OrderSide.Buy),
		},
	});
});

