import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { CurrencyRate } from "../../../data/listing/curencyRates/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewCurrency } from "./DisplayCurrencyRate";
import { getCurrencyRateEditLocation } from "./Locations";

export const useCurrenciesActions = () => {
	const { getString } = useContext(LocalizationContext);
	const onShowRole = useViewCurrency();
	const onEditRole = useEditCurrencyAction();

	return useMemo(() => {
		const result: Action<CurrencyRate>[] = [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tableShow"),
				onClick: (event, rowData) => onShowRole(rowData as CurrencyRate),
			},
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditRole(rowData as CurrencyRate),
			},
		];

		return result;
	}, [onShowRole, onEditRole, getString]);
};

export const useEditCurrencyAction = () => {
	const history = useHistory();
	return React.useCallback((rate: CurrencyRate) => history.push(getCurrencyRateEditLocation(rate.currRateId)), [
		history,
	]);
};
