import { CreateInvoicesTranData } from "../../../data/accounting/createInvoices/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";

export const useCreateInvoicesColumns = () => {
  const {
    getString,
    getDateString,
    getFormatedNumberString,
  } = React.useContext(LocalizationContext);

  return React.useMemo(
    () => [
      {
        title: getString("invoiceTranDate"),
        render: (invoices) => getDateString(invoices.tradeTime, "DD.MM.YYYY"),
        sorting: false,
      },

      {
        title: getString("invoiceTranNo"),
        field: "tranNo",
        sorting: false,
      },
      {
        title: getString("invoiceOrderNo"),
        field: "orderNo",
        sorting: false,
      },
      {
        title: getString("invoiceClientName"),
        field: "clientName",
        sorting: false,
      },
      {
        title: getString("invoiceProductName"),
        field: "productName",
        sorting: false,
      },
      {
        title: getString("invoiceQuantity"),
        render: (row) => `${getFormatedNumberString(row.qty)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("invoiceTradeAmt"),
        render: (row) => `${getFormatedNumberString(row.amt, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
    ],
    [getString, getFormatedNumberString]
  ) as Column<CreateInvoicesTranData>[];
};
