import { TradePricesChartData, ChartPeriod } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";

export const getChartData = (user: User, prodid: string, numofdays: ChartPeriod) => {
	return webApi<TradePricesChartData[]>({
		method: "GET",
		url: "reporting/gettradepriceschartdata",
		params: {
			userid: user.userId,
			prodid,
			numofdays,
		},
	});
};
