import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { Bank } from "../../../data/listing/banks/types";

export function useBankColumns() {
  const { getString } = useContext(LocalizationContext);
  return useMemo(
    () => [
      {
        title: getString("bankCode"),
        field: "bankCode",
      },
      {
        title: getString("bankName"),
        field: "bankName",
      },
      {
        title: getString("bankIdNumber"),
        field: "idNumber",
      },
    ],
    [getString]
  ) as Column<Bank>[];
}
