import React from "react";
import { DisputeListData } from "../../../data/clearing/dispute/types";
import { Typography } from "@material-ui/core";
import { useOrderTableStyles } from "./styles";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

export interface RenderDisputeType {
	(dispute: DisputeListData): React.ReactNode;
}

const createRenderer = (Component: DisputeDisplayComponentType): RenderDisputeType => dispute => (
	<Component dispute={dispute} />
);

export const TooltipColumn: React.FunctionComponent<{
	displayText: React.ReactNode;
	tooltip?: React.ReactNode;
	typography?: boolean;
	hideIcon?: boolean;
}> = ({ displayText, tooltip, typography, hideIcon }) => {
	const styles = useOrderTableStyles();
	const tooltipElement =
		typography && tooltip ? <Typography variant="subtitle1">{tooltip}</Typography> : tooltip;

	return tooltip ? (
		<CustomTooltip title={tooltipElement}>
			<span className={styles.cellHasTooltip}>
				{hideIcon !== true && <InfoOutlinedIcon fontSize="small" />}
				<span>{displayText}</span>
			</span>
		</CustomTooltip>
	) : (
		<>{displayText}</>
	);
};

export const TooltipElises: React.FunctionComponent<{ text: string }> = ({ text }) => {
	const tooltipElement = <Typography variant="subtitle1">{text}</Typography>;

	return (
		<CustomTooltip title={tooltipElement}>
			<span
				style={{
					overflowX: "hidden",
					textOverflow: "ellipsis",
				}}
			>
				{text}
			</span>
		</CustomTooltip>
	);
};

interface DisputeDisplayComponentProps {
	dispute: DisputeListData;
}

type DisputeDisplayComponentType = React.FunctionComponent<DisputeDisplayComponentProps>;

export const DisputeSituationDisplay: DisputeDisplayComponentType = ({ dispute }) => (
	<TurnocateText text={dispute.situationDescr} maxChars={18} />
);

const tunrocateString = (text: string, maxChars: number) => {
	const turnocate = text && text.length > maxChars;    // pada program ako je upisano null
	return {
		turnocate,
		text: turnocate ? `${text.substring(0, maxChars - 3)}...` : text,
	};
};

export interface TurnocateTextProps {
	text: string;
	maxChars: number;
}

export const TurnocateText: React.FunctionComponent<TurnocateTextProps> = ({ text, maxChars }) => {
	const turnocatedName = tunrocateString(text, maxChars);

	return turnocatedName.turnocate ? (
		<TooltipColumn displayText={turnocatedName.text} tooltip={text} typography hideIcon />
	) : (
		<>{text}</>
	);
};

export const renderSituation = createRenderer(DisputeSituationDisplay);
