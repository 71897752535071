import { ThunkType } from "../../../../core/store/reducers";
import { setTrades } from "../TASDataSlice";
import { setTradesIndex } from "../TASUXSlice";
import { PageIndex } from "../../../models";
import { getTradesThunk } from "./getTradesThunk";

export const loadTrades = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	const { pageNumber, itemsPerPage } = store.ux.clearing.transportAndStorage.index;
	const { filter } = store.ux.clearing.transportAndStorage;

	const tradesData = await dispatch(getTradesThunk(pageNumber, itemsPerPage, filter));

	if (tradesData) {
		dispatch(setTrades(tradesData));
	}
};

export const setTradesIndexThunk = (index: PageIndex): ThunkType => dispatch => {
	dispatch(setTradesIndex(index));
	dispatch(loadTrades());
};
