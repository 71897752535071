import { ThunkType } from "../../../../../core/store/reducers";
import getCommissionDetailApi from "../../api/commissionDetails/getCommissionDetail";
import { CommissionDetail } from "../../types";

export const loadCommissionDetail = (
	detailId: string
): ThunkType<Promise<CommissionDetail | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getCommissionDetailApi(userId, detailId));
};
