import { ThunkType } from "../../../../core/store/reducers";
import createEmployee from "../api/createEmployee";
import updateEmployee from "../api/updateEmployee";
import { setFetchStateEmployee } from "../employeesDataReducer";
import { Employee } from "../types";

export const upsertEmployee = (employee: Employee): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchStateEmployee({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = employee.empId
		? await dispatch(updateEmployee(userId, employee))
		: await dispatch(createEmployee(userId, employee));

	if (result && result.resCode === "OK") {
		dispatch(setFetchStateEmployee({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchStateEmployee({ type: "error", errorCode: result?.resCode }));
	}
};
