import { webApi } from "../../../../core/http/httpThunks";
import { Currency } from "../types";

export const getCurrencyApi = (userId: string, currid: string) =>
	webApi<Currency>({
		method: "GET",
		url: "listing/getcurr",
		params: {
			userId,
			currid
		},
	});

export default getCurrencyApi;
