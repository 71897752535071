import React from "react";
import {Commission, CommissionStandard } from "../../../data/listing/commissions/types";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { LocalizationContext } from "../../../core/localization/Localization";

export interface DisplayProductBasicInfoProps {
	commission: Commission;
}

export const DisplayProductBasicInfo: React.FunctionComponent<DisplayProductBasicInfoProps> = ({
	commission,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);

	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("commissionName")}</span>
				<span className={style.value}>{commission.commName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("commissionNameEN")}</span>
				<span className={style.value}>{commission.commNameEn}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("commissionCode")}</span>
				<span className={style.value}>{commission.commCode}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("commissionType")}</span>
				<span className={style.value}>{commission.commTypeName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("commissionStandard")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={commission.isStandard}
						thrutyValue={CommissionStandard.True}
						className={style.icon}
					/>
				</span>
			</div>
		</div>
	);
};
