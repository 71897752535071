import { ThunkType } from "../../core/store/reducers";
import storepushnotiftokenApi from "./api/storetoken";
import { setFetchState  } from "./authDataSlice";

export const storepushnotiftokenThunks = (pushToken: string ): ThunkType => async ( dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;

  const result =  await dispatch(storepushnotiftokenApi(userId, pushToken))

    console.log(result)

  if (result &&  result.resCode === "OK") {
    dispatch(setFetchState({ type: "successful", result: result.resDesc }));
    return result.resDesc;
  } else {
    dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
  }
};
