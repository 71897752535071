import React from "react";
import { ViewOrder } from "../../../data/trading/orders/types";
import { Typography } from "@material-ui/core";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useOrderTableStyles } from "./styles";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

export interface RenderOrderType {
	(order: ViewOrder): React.ReactNode;
}

const createRenderer = (Component: OrderDisplayComponentType): RenderOrderType => order => (
	<Component order={order} />
);

export const TooltipColumn: React.FunctionComponent<{
	displayText: React.ReactNode;
	tooltip?: React.ReactNode;
	typography?: boolean;
	hideIcon?: boolean;
}> = ({ displayText, tooltip, typography, hideIcon }) => {
	const styles = useOrderTableStyles();
	const tooltipElement =
		typography && tooltip ? <Typography variant="subtitle1">{tooltip}</Typography> : tooltip;

	return tooltip ? (
		<CustomTooltip title={tooltipElement}>
				<span>{displayText}</span>
		</CustomTooltip>
	) : (
		<>{displayText}</>
	);
};

export const TooltipElises: React.FunctionComponent<{ text: string }> = ({ text }) => {
	const tooltipElement = <Typography variant="subtitle1">{text}</Typography>;

	return (
		<CustomTooltip title={tooltipElement}>
			<span
				style={{
					overflowX: "hidden",
					textOverflow: "ellipsis",
				}}
			>
				{text}
			</span>
		</CustomTooltip>
	);
};

interface OrderDisplayComponentProps {
	order: ViewOrder;
}

type OrderDisplayComponentType = React.FunctionComponent<OrderDisplayComponentProps>;


export const OrderPriceDisplay: OrderDisplayComponentType = ({ order }) => {
	const { getString, getFormatedNumberString, getFormatedNumberString4 } = React.useContext(LocalizationContext);
	const styles = useOrderTableStyles();
	const tooltip = (
		<>
			<div className={styles.valueLabel}>
				<span>{getString("orderPrice")}</span>
				<span>{getFormatedNumberString(order.price, true)}</span>
			</div>
			<div className={styles.valueLabel}>
				<span>{getString("orderPriceEur")}</span>
				<span>{getFormatedNumberString4(order.priceEur, true)}</span>
			</div>
			<div className={styles.valueLabel}>
				<span>{getString("orderPriceWithVat")}</span>
				<span>{getFormatedNumberString(order.priceWithVat, true)}</span>
			</div>
		</>
	);
	return (
		<TooltipColumn
			displayText={getFormatedNumberString(order.price, true).trim()}
			tooltip={tooltip}
			typography
//			hideIcon= {true}
		/>
	);
};

const maxQualityDisplay = 3;

export const OrderQualityDisplay: OrderDisplayComponentType = ({ order }) => {
	const qualityLines = order.quality ? order.quality.split("\r\n") : [];
	const styles = useOrderTableStyles();
	const qualityRender = qualityLines.map(mapQualityLine(styles));
	if (qualityLines.length <= maxQualityDisplay) {
		return <>{qualityRender}</>;
	}

	const firstTwoRows = <span>{qualityLines.splice(0, maxQualityDisplay).map(mapQualityLine(styles))}</span>;

	return <TooltipColumn displayText={firstTwoRows} tooltip={qualityRender} typography />;
};

const mapQualityLine = (styles: any) => (qualityLine: string, index: number) => (
	<div className={styles.longCell} key={index}>
		{qualityLine}
	</div>
);

export const OrderClientDisplay: OrderDisplayComponentType = ({ order }) => (
	<TurnocateText text={order.clientName} maxChars={18} />
);

const tunrocateString = (text: string, maxChars: number) => {
	const turnocate = text.length > maxChars;
	return {
		turnocate,
		text: turnocate ? `${text.substring(0, maxChars - 3)}...` : text,
	};
};

export interface TurnocateTextProps {
	text: string;
	maxChars: number;
}

export const TurnocateText: React.FunctionComponent<TurnocateTextProps> = ({ text, maxChars }) => {
	const turnocatedName = tunrocateString(text, maxChars);

	return turnocatedName.turnocate ? (
		<TooltipColumn displayText={turnocatedName.text} tooltip={text} typography hideIcon />
	) : (
		<>{text}</>
	);
};

export const renderOrderPrice = createRenderer(OrderPriceDisplay);
export const renderQuality = createRenderer(OrderQualityDisplay);
export const renderClient = createRenderer(OrderClientDisplay);
