import React from "react";
import { useTradeByBrokersReportDownloader } from "../../../../data/reports/exchbusiness/tradeByBrokers/hooks/useTradeByBrokersReportDownloader";
import { useTradeByBrokersExcelDownloader } from "../../../../data/reports/exchbusiness/tradeByBrokers/hooks/useTradeByBrokersExcelDownloader";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import ExcelIcon from "@material-ui/icons/TableChartOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../../components/common/CustomTooltip";
import { LocalizationContext } from "../../../../core/localization/Localization";

export const DownloadButton: React.FunctionComponent = () => {
  const [downloader, fetchState] = useTradeByBrokersReportDownloader();
	const { getString } = React.useContext(LocalizationContext);

  return (
		<>
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
		{fetchState.type !== "not-started"  ? getString("pleaseWait") : null}
		</>
    );
};

export const DownloadExcelButton: React.FunctionComponent = () => {
  const [downloader, fetchState] = useTradeByBrokersExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};
