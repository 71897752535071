import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";
import { deleteCommissionDetail } from "../../thunks/detailes/deleteCommissionDetail";
import { setFetchStateAttribute } from "../../commissionsDataReducer";

export const useDetailDelete = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.commissions.fetchStateDetail
	);

	const dispatch = useDispatch();
	const dispatchDeleteAttribute = React.useCallback((id: string) => dispatch(deleteCommissionDetail(id)), [
		dispatch,
	]);

	React.useEffect(() => {
		dispatch(setFetchStateAttribute({ type: "not-started" }));
	}, [dispatch]);

	return [fetchState, dispatchDeleteAttribute] as [typeof fetchState, typeof dispatchDeleteAttribute];
};
