import React from "react";
import { useDisplayDispute } from "../../../data/clearing/dispute/hooks/useDisplayDispute";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import {
  DisputeData,
  DisputeListData,
} from "../../../data/clearing/dispute/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayDisputeMainAttributes } from "./DisplayDisputeMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid, IconButton,
} from "@material-ui/core";
import { useDisputePrinter } from "../../../data/clearing/dispute/hooks/useDisputePrinter";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";

export interface DisplayDisputeProps {
  id?: string;
}

export const DisplayDispute: React.FunctionComponent<DisplayDisputeProps> = ({
  id,
}) => {
  const dispute = useDisplayDispute(id);
  const { getString } = React.useContext(LocalizationContext);
	const [download, fetchState, setfetch] = useDisputePrinter(dispute);

  return !dispute ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Grid container justify="space-between">
            <Grid item>
              <Typography>{getString("tableBasciInfo")}</Typography>
            </Grid>
							<Grid item>
								<CustomTooltip titleKeyword="exportPDFTooltip">
									<IconButton
										color="secondary"
										component="span"
										onClick={download}
										disabled={fetchState.type !== "not-started"}
									>
										<DownloadIcon />
									</IconButton>
								</CustomTooltip>
                { fetchState.type !== "not-started"  ? getString("pleaseWait") : null }
							</Grid>
          </Grid>
        </ExpansionPanelSummary>
        <DisplayDisputeMainAttributes dispute={dispute} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewDispute = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (dispute: DisputeListData) =>
      showWithDrawer(<DisplayDispute id={dispute.dispSitId} />),
    [showWithDrawer]
  );
};
