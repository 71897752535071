import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadMainBoard } from "../thunks/loadMainBoard";
import { ViewOrder } from "../../orders/types";
import { OrderSide, PageData } from "../../../../data/models";
import { invalidateOrders } from "../ordersDataSlice";

export const useMainBoardData = (side: OrderSide) => {
	const pageData = useSelector<StoreType, PageData<ViewOrder> | undefined>(
		s => s.data.trading.mainBoard.currentPage[side]
	);

	const dispatch = useDispatch();
	const dispatchLoadAMBPage = React.useCallback(() => dispatch(loadMainBoard(side)), [dispatch, side]);

	const dispatchInvalidateOrders = React.useCallback(() => dispatch(invalidateOrders()), [dispatch]);

	return [pageData, dispatchLoadAMBPage, dispatchInvalidateOrders] as [typeof pageData, typeof dispatchLoadAMBPage, typeof dispatchInvalidateOrders];
};

export const useInvalidateMainBoard = () => {
	const dispatch = useDispatch();
	const dispatchInvalidateOrders = React.useCallback(() => dispatch(invalidateOrders()), [dispatch]);

	return [dispatchInvalidateOrders] as [typeof dispatchInvalidateOrders];
}
