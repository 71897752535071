import { TradeByClientsReportData, TradeByClientsReportFilter } from "../types";
import { User } from "../../../../auth/types";
import { webApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

const getTradeByClinetsData = (
  user: User,
  filter: TradeByClientsReportFilter
) => {
  return webApi<TradeByClientsReportData[]>({
    method: "GET",
    url: "reporting/getturnoverbyclilist",
    params: {
      userid: user.userId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      clientId: filter?.clientId,
      cliStatus: filter?.cliStatus,
    },
  });
};

export default getTradeByClinetsData;
