import React from "react";
import { Grid, CardContent, CardHeader, Card, Divider, Fab } from "@material-ui/core";
import InvoicesTable from "./InvoicesTable";
import AddIcon from "@material-ui/icons/Add";
import { usePageStyles } from "../../pageStyles";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { useInvoicesData } from "../../../data/accounting/invoices/hooks/useInvoicesData";
import { useInvoiceIndex } from "../../../data/accounting/invoices/hooks/useInvoicesIndex";
import { useHistory } from "react-router-dom";
import { getInvoicesEditLocation } from "./Locations";
import { PageDataStatus } from "../../../data/models";
import { InvoiceFilterButton } from "./InvoiceFilter";
import { useAuth } from "../../../data/auth/hooks";

const InvoicesPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [pageData, dispatchLoadInvoices] = useInvoicesData();
	const [pageIndex, dispatchSetIndex] = useInvoiceIndex();

	React.useEffect(() => {
		if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
			dispatchLoadInvoices();
		}
	}, [dispatchLoadInvoices, pageData]);

	const history = useHistory();

	const [auth] = useAuth();

	if (auth.status !== "successful") {
		return null;
	}

	return (
		<>
			<Grid container className={pageStyles.root}>
				<Grid item xs={12} className={pageStyles.gridItem}>
					<Card>
						<CardHeader
							title={<PageTitle title="invoiceTitle" />}
							action={<InvoiceFilterButton />}
						/>
						<Divider />
						<CardContent>
							<InvoicesTable
								pageIndex={pageIndex}
								pageData={pageData}
								onPageIndexChange={dispatchSetIndex}
								userAccessRights={userAccessRights}
								getData={dispatchLoadInvoices}
							/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			{userAccessRights === AccessRights.All && (
					<Fab
						color="secondary"
						aria-label="add"
						className={pageStyles.mainFab}
						onClick={() => history.push(getInvoicesEditLocation())}
					>
						<AddIcon />
					</Fab>
				)}
		</>
	);
};

export default InvoicesPage;
