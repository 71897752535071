import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import {
  useAccountingOrderFilter,
  useCurrentAccountingOrderFilter,
} from "../../../data/accounting/accountingOrder/hooks/useAccountingOrderFilter";
import { LocalizationContext } from "../../../core/localization/Localization";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { AccountingOrderFilterEditor } from "./AccountingOrderEditor";
import { AccountingOrderFilterChips } from "./AccountingOrderFilterChips";

export const AccountingOrderFilter: React.FunctionComponent = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const showFilter = React.useCallback(
    () => showWithDrawer(<AcountingOrdersFiltersEditor />),
    [showWithDrawer]
  );

  //  const [filter, editfilter, save] = useCurrentAccountingOrderFilter();
  const [filter, removefilter] = useCurrentAccountingOrderFilter();

  const { getString } = React.useContext(LocalizationContext);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <AccountingOrderFilterChips
          filter={filter}
          removefilter={removefilter}
        />
      </Grid>
      <Grid item>
        <CustomTooltip titleKeyword="filterTooltip">
          <IconButton color="secondary" component="span" onClick={showFilter}>
            <FilterIcon />
          </IconButton>
        </CustomTooltip>
      </Grid>
    </Grid>
  );
};

const AcountingOrdersFiltersEditor: React.FunctionComponent = () => {
  const [filter, editFilter, save] = useAccountingOrderFilter();
  return (
    <AccountingOrderFilterEditor
      filter={filter}
      editFilter={editFilter}
      save={save}
    />
  );
};
