import React from "react";
import { Product } from "../../../data/listing/products/types";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Status } from "../../../data/models";

export interface DisplayProductBasicInfoProps {
	product: Product;
}

export const DisplayProductBasicInfo: React.FunctionComponent<DisplayProductBasicInfoProps> = ({
	product,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);

	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("productName")}</span>
				<span className={style.value}>{product.prodName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("productNameEN")}</span>
				<span className={style.value}>{product.prodNameen}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("productCode")}</span>
				<span className={style.value}>{product.prodCode}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("productGroupName")}</span>
				<span className={style.value}>{product.productGroupName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("productUnit")}</span>
				<span className={style.value}>{product.unitCode}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("productVat")}</span>
				<span className={style.value}>{product.vatRate}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliStatus")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={product.status}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				</span>
			</div>
		</div>
	);
};
