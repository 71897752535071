import React from "react";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import ExcelIcon from "@material-ui/icons/TableChartOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { useMonthlyReportDownloaderProduct } from "../../../data/reports/monthlyTrade/hooks/useMTReportDownloaderProduct";
import { useMonthlyProductExcelDownloader } from "../../../data/reports/monthlyTrade/hooks/useMTExcelDownloaderProducts";
import { useMonthlyReportDownloaderClient } from "../../../data/reports/monthlyTrade/hooks/useMTReportDownloaderClients";
import { useMonthlyClientExcelDownloader } from "../../../data/reports/monthlyTrade/hooks/useMTExcelDownloaderClients";
import { useMonthlyReportDownloaderDate } from "../../../data/reports/monthlyTrade/hooks/useMTReportDownloaderDate";
import { useMonthlyDateExcelDownloader } from "../../../data/reports/monthlyTrade/hooks/useMTExcelDownloaderDate";
import { useMonthlyReportDownloader } from "../../../data/reports/monthlyTrade/hooks/useMTReportDownloader";
import { useMonthlyExcelDownloader } from "../../../data/reports/monthlyTrade/hooks/useMTExcelDownloader";

export const DownloadButton: React.FunctionComponent = () => {
  const [downloader, fetchState] = useMonthlyReportDownloader();
  return (
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadExcelButton: React.FunctionComponent = () => {
  const [downloader, fetchState] = useMonthlyExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadButtonProduct: React.FunctionComponent = () => {
  const [downloader, fetchState] = useMonthlyReportDownloaderProduct();
  return (
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadExcelButtonProduct: React.FunctionComponent = () => {
  const [downloader, fetchState] = useMonthlyProductExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadButtonClient: React.FunctionComponent = () => {
  const [downloader, fetchState] = useMonthlyReportDownloaderClient();
  return (
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadExcelButtonClient: React.FunctionComponent = () => {
  const [downloader, fetchState] = useMonthlyClientExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadButtonDate: React.FunctionComponent = () => {
  const [downloader, fetchState] = useMonthlyReportDownloaderDate();
  return (
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadExcelButtonDate: React.FunctionComponent = () => {
  const [downloader, fetchState] = useMonthlyDateExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};
