import { ProductGroupData } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";

interface IRoleData {
	all?: ProductGroupData[];
	searchTerm: string;
	displayData?: ProductGroupData[];
	displayed?: ProductGroupData;
	fetchState: FetchStateType;
}

const initialState: IRoleData = {
	searchTerm: "",
	fetchState: { type: "not-started" },
};

const name = "productGroup";

const productGroupDataSlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<ProductGroupData[] | undefined>) => ({
			...state,
			all: action.payload,
			displayData: filterData(action.payload, state.searchTerm),
		}),
		setSearchTerm: (state, action: PayloadAction<string>) => ({
			...state,
			searchTerm: action.payload.toLocaleLowerCase(),
			displayData: filterData(state.all, action.payload),
		}),
		setDisplayed: (state, action: PayloadAction<ProductGroupData | undefined>) => ({
			...state,
			displayed: action.payload,
		}),
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayed: action.payload.type === "successful" ? undefined : state.displayed,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			fetchState: getFetchStateChange(state.fetchState, action.payload),
		}),
	},
});

const filterData = (data?: ProductGroupData[], searchTerm?: string) =>
	searchTerm === undefined || searchTerm === ""
		? data
		: data?.filter(
				e =>
					(e.prodGroupName &&
						e.prodGroupName
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
					(e.prodGroupNameEn &&
						e.prodGroupNameEn
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase()))
		  );

export const { setData, setSearchTerm, setDisplayed, setFetchState } = productGroupDataSlice.actions;

export default productGroupDataSlice.reducer;
