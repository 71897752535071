import { webApi } from "../../../../core/http/httpThunks";

export const deletePaymentApi = (userId: string, payid: string) =>
  webApi<string>({
    method: "DELETE",
    url: "clearing/delpayment",
    params: {
      userId,
      payid,
    },
  });

export default deletePaymentApi;
