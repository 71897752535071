import { ThunkType } from "../../../../core/store/reducers";
import { printTradingData } from "../api/printTradingData";
import fileDownload from "js-file-download";
import { setDownloadFetchState } from "../tradingDataSlice";
import { TradingData } from "../types";

export const printTradingDataThunk = (tradingdata?: TradingData): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful" || !tradingdata || !tradingdata.ptdId) {
		return undefined;
	}
	const lan = store.ux.localization.language
	const filename = `TradingDataCalculation_${tradingdata.ptdId}.pdf`
	const user = store.data.auth.current.user;
	dispatch(setDownloadFetchState({ type: "started" }));
	const tradingDataCalcReport = await dispatch(printTradingData(user, tradingdata.ptdId));
	if (tradingDataCalcReport) {
		fileDownload(new Blob([tradingDataCalcReport as any], { type: "application/pdf" }), filename);
	}
	dispatch(setDownloadFetchState({ type: "not-started" }));
};
