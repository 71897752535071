import { ValidationState } from "../../../core/util/types";

export enum UserEmployee {
	True = 1,
	False = 0
}

export enum UserActive {
	True = 1,
	False = 0
}

export enum UserSubscribtionUnlimited {
	True = 1,
	False = 0
}

export enum UserChangePass {
	True = 1,
	False = 0
}

export interface UserData {
	userId?: string; //		PK (auto)
	userName: string; //		ime korisnika
	loginUser: string; //		ime za logovanje
	loginPass?: string; //		lozinka koju dodeljuje administrator
	changePass: UserChangePass; //		u ovom slucaju 1
	roleId?: string; //		ID role korisnika
	isEmployee: UserEmployee; //		1=korisnik je zaposleni, inace 0
	empId?: string; //			ID zaposlenog ako je prethodno polje 1, inace null
	clientId?: string; //		ID klijenta, ako nije zaposleni, inace null
	cliPrsId?: string; //		ID lica klijenta, ako nije zaposleni, inace null
						// lice mora da pripada klijentu iz prethodnog polja
					// ako klijent ima samo jedno lice, popuniti odmah
	subscribed_Until?: Date; //	pristup aplikaciji do datuma
	subscrUnlimited: number; //		pristup neogranicen
	isActive: number; //	korisnik aktivan (deaktivira se ako 3 puta pogresno unese lozinku)
	phone?: string;
	email?: string;
}

export interface UserListData {
    userId: string; //	PK
	userName: string; //	ime korisnika
	loginUser: string; //	ime za logovanje
	role: string; //		rola (opis)
	emp?: string; //	zaposleni (ime), pojavljuje se alternativno zaposleni ili klijent + lice klijenta
	client?: string; //	klijent (ime)
	cliPrs?: string; //	lice klijenta (ime)
}

export type UserValidationState = ValidationState<UserData>;
