import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteTransportFee } from "../thunks/deleteTransportFee";
import { setFetchState } from "../transportFeesDataReducer";

export const useTransportFeeDelete = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.clearing.transportfees.fetchState
  );

  const dispatch = useDispatch();
  const dispatchDeleteTransportFee = React.useCallback(
    (id: number) => dispatch(deleteTransportFee(id)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchDeleteTransportFee] as [
    typeof fetchState,
    typeof dispatchDeleteTransportFee
  ];
};
