import { combineReducers } from "@reduxjs/toolkit";
import memberContracts from "./memberContracts/memberContractsDataSlice";
import tradeReview from "./tradeReview/tradeReviewDataSlice";
import tradeByProducts from "./tradeByProducts/tradeByProductsDataSlice";
import tradeByClients from "./tradeByClients/tradeByClientsDataSlice";
import tradeByBrokers from "./tradeByBrokers/tradeByBrokersDataSlice";
import weightedPrice from "./weightedPrices/weightedPriceDataSlice";
import businessActivities from "./businessActivities/businessActivitiesDataSlice";
import commReview from "./commReview/commReviewDataSlice";
import clientOverview from "./clientOverview/clientOverviewDataSlice";

const exchBusinessDataReducer = combineReducers({
  memberContracts,
  tradeReview,
  tradeByProducts,
  tradeByClients,
  tradeByBrokers,
  weightedPrice,
  businessActivities,
  commReview,
  clientOverview,
});

export default exchBusinessDataReducer;
