import { webApi } from "../../../../../core/http/httpThunks";
import { CommissionDetail } from "../../types";

export const getCommissionDetailApi = (userId: string, commdetid: string) =>
	webApi<CommissionDetail>({
		method: "GET",
		url: "listing/getcommdet",
		params: {
			userId,
			commdetid
		},
	});

export default getCommissionDetailApi;
