import React from "react";
import { AlarmType } from "../../../../data/models";
import { LocalizationContext } from "../../../../core/localization/Localization";

export const useAlarmTypeTranslator = () => {
	const { getString } = React.useContext(LocalizationContext);
	return React.useCallback(
		(type: AlarmType) => {
			switch (type.toUpperCase()) {
				case AlarmType.Client.toUpperCase():
					return getString("alarmTypeUser");
				case AlarmType.Product.toUpperCase():
					return getString("alarmTypeProduct");
				case AlarmType.ProductClient.toUpperCase():
					return getString("alarmTypeUserProduct");
			}
		},
		[getString]
	);
};

interface AlarmTypeOption {
	label: string;
	value: AlarmType;
}

export const useAlarmType = (): AlarmTypeOption[] => {
	const translator = useAlarmTypeTranslator();
	return React.useMemo(
		() => [
			{
				label: translator(AlarmType.Client) as string,
				value: AlarmType.Client,
			},
			{
				label: translator(AlarmType.Product) as string,
				value: AlarmType.Product,
			},
			{
				label: translator(AlarmType.ProductClient) as string,
				value: AlarmType.ProductClient,
			},
		],
		[translator]
	);
};
