import { appComponents } from "../appNavigationItems";
import React from "react";
import { MenuItemType, SubmenuMenuItemType, LinkMenuItemType } from "../../../core/navigation/types";
import { useUserMenuItems } from "../../../data/initialData/hooks/useUserMenuItems";
import { UnderConstruction } from "../../underConstruction/UnderConstruction";
import { iconDictionary } from "../menuIcons";
import { AuthorizationState } from "../../../data/auth/types";

export const useNavigationItems = (onMenu?: boolean) =>
	React.useMemo(() => [...appComponents.filter(c => onMenu === undefined || c.isMenuItem === onMenu)], [
		onMenu,
	]);

export const useMenuItems = (): MenuItemType[] => {
	const [userMenuItems] = useUserMenuItems();
	const navigationItems = useNavigationItems(true);

	const sortedUserItems = React.useMemo(() => {
		const sorted = [...userMenuItems];
		sorted.sort((a, b) => a.level - b.level);
		return sorted;
	}, [userMenuItems]);

	return React.useMemo(
		() =>
			sortedUserItems.reduce<MenuItemType[]>((acc, item) => {
				const menuItem: MenuItemType = {
					key: item.miId,
					title: item.miName,
					accessRights: item.readOnly,
					path: item.path,
					Component:
						navigationItems.find(i => i.path === item.path)?.Component || UnderConstruction,
					items: [],
					type: item.path ? "Link" : "Menu",
					icon: iconDictionary[item.miId],
				};

				const parrent = findMenuItem(acc, i => i.key === item.parentId);
				if (parrent && parrent.type === "Menu") {
					parrent.items.push(menuItem);
				} else {
					acc.push(menuItem);
				}

				return acc;
			}, []),
		[sortedUserItems, navigationItems]
	);
};

export const useLinkMenuItems = (): LinkMenuItemType[] => {
	const menuItems = useMenuItems();
	return React.useMemo(() => filterdMenuItem(menuItems, item => item.type === "Link"), [
		menuItems,
	]) as LinkMenuItemType[];
};

const findMenuItem = (
	items: MenuItemType[],
	predicate: (item: MenuItemType) => boolean
): MenuItemType | undefined => {
	const submenues = items.filter(i => i.type === "Menu") as SubmenuMenuItemType[];
	let found = submenues.find(predicate) as MenuItemType | undefined;

	if (found !== undefined) {
		return found;
	}

	for (let submenu of submenues) {
		found = findMenuItem(submenu.items, predicate);
		if (found) {
			return found;
		}
	}

	return undefined;
};

const filterdMenuItem = (
	items: MenuItemType[],
	predicate: (item: MenuItemType) => boolean,
	start: MenuItemType[] = []
) => {
	let result = start.concat(items.filter(predicate));
	const submenues = items.filter(i => i.type === "Menu") as SubmenuMenuItemType[];

	for (let submenu of submenues) {
		result = filterdMenuItem(submenu.items, predicate, result);
	}

	return result;
};

export const useShouldConnectNotifications = () => {
	const navigationItems = useNavigationItems();
	const notificationItems = React.useMemo(() => navigationItems.filter(n => n.notifications), [
		navigationItems,
	]);
	const [menuItems] = useUserMenuItems();
	return React.useMemo(() => menuItems.some(mi => notificationItems.some(n => n.path === mi.path)), [
		menuItems,
		notificationItems,
	]);
};
