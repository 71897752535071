import TradesReport from "./TradesReport";
import { NavigationItem } from "../../../core/navigation/types";
import { tradesReportAllLocation, } from "./Locations";

export const tradesReportAllComponents: NavigationItem[] = [
	{
		Component: TradesReport,
		isMenuItem: true,
		path: tradesReportAllLocation,
	},
];
