import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { Region } from "../../models";

const getRegionLabel = (region?: Region) => (region ? region.regName : "");

export const useRegions = () => {
  const regions = useSelector<StoreType, Region[]>(
    (store) => store.data.initialData.regions
  );


  return [regions, getRegionLabel] as [typeof regions, typeof getRegionLabel];
};

export const useRegion = (regId?: string) => {
  const [regions] = useRegions();

  const region = React.useMemo(() => regions.find((c) => c.regId === regId), [
    regions,
    regId,
  ]);


  const regionLabel = React.useMemo(() => getRegionLabel(region), [region]);

  return [region, regionLabel] as [typeof region, typeof regionLabel];
};
