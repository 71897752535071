import { WeeklyTradingReportData } from "../../../data/reports/weeklyTrade/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const useWeeklyTradesColumns = () => {
  const { getString, getFormatedNumberString } = React.useContext(
    LocalizationContext
  );

  const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

  return React.useMemo(
    () => [
      {
        title: getString("weeklyTradeWeek"),
        field: "displayVal",
        sorting: false,
      },
      {
        title: getString("dailyTradeReportTurnoverRSD"),
        render: (row) => `${getFormatedNumberString(row.turnoverRsd, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("dailyTradeReportTurnoverEUR"),
        render: (row) => `${getFormatedNumberString(row.turnoverEur, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
				hidden: screenExtraSmall,
      },
      {
        title: getString("dailyTradeReportCountTransaction"),
        field: "tranCount",
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
    ],
    [getString, getFormatedNumberString, screenExtraSmall]
  ) as Column<WeeklyTradingReportData>[];
};
