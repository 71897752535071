import { DisputeData, DisputeValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const disputeValidationState: DisputeValidationState = {
  dispSitDate: true,
  tradeId: true,
};

export const useDisputeValidator = createUseValidator<
  DisputeData,
  DisputeValidationState
>(disputeValidationState);
