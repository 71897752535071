import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { QualityCtrlList } from "../../models";

const getGroupLabel = (attribute?: QualityCtrlList) => (attribute ? attribute.ftDescr : "");

export const useQualityCtrlList = () => {
	const groups = useSelector<StoreType, QualityCtrlList[]>(store => store.data.initialData.qualityCtrlList);

	return [groups, getGroupLabel] as [typeof groups, typeof getGroupLabel];
};
