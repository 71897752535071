import { webApi } from "../../../../core/http/httpThunks";
import { CtrlGoodsData } from "../types";

export const getControlApi = (userId: string, ctrlid: string) =>
  webApi<CtrlGoodsData>({
    method: "GET",
    url: "clearing/getctrlgoods",
    params: {
      userId,
      ctrlid,
    },
  });

export default getControlApi;
