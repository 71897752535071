import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { CheckPoint } from "../../models";

const renderLabel = (item: CheckPoint) => item.ftDescr

export const useCheckPoints = () => {
	const checkPoints = useSelector<StoreType, CheckPoint[]>(store => store.data.initialData.checkPoints);

	return [checkPoints, renderLabel] as [typeof checkPoints, typeof renderLabel];
};
