import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllBankGuaranteesSelected } from "../../bankguarantees/thunks/loadAllBankGuaranteesSelected";
import { BankGuaranteeData, SimplBankGuaranteeFilter } from "../../bankguarantees/types";

export const useBankGuaranteeDataSelect = (filter?: SimplBankGuaranteeFilter) => {
	const dispatch = useDispatch();
	const [bnkgrnt, setBnkGrnt] = React.useState<BankGuaranteeData[] | undefined>();

	React.useEffect(() => {
		const loadOrders = async () => {
			if (filter) {
				const loaded = ((await dispatch(loadAllBankGuaranteesSelected(filter))) as any) as BankGuaranteeData[];
				setBnkGrnt(loaded);
			}
		};
		if (!filter) {
			setBnkGrnt(undefined);
		} else {
			loadOrders();
		}
	}, [filter, dispatch]);

	
	return [bnkgrnt] as [typeof bnkgrnt];

};

/* import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllBankGuarantees } from "../../bankguarantees/thunks/loadAllBankGuarantees";
import { BankGuaranteeData } from "../../bankguarantees/types";

export const useBankGuaranteeDataSelect = () => {
	const data = useSelector<StoreType, BankGuaranteeData[] | undefined>(s => s.data.clearing.bankguarantees.displayData);
	const allData = useSelector<StoreType, BankGuaranteeData[] | undefined>(s => s.data.clearing.bankguarantees.currentPage?.currentPageData);

console.log(data)
console.log(allData)

	const dispatch = useDispatch();
	const dispatchLoadBankGuaranteePage = React.useCallback(() => dispatch(loadAllBankGuarantees()), [dispatch]);

	return [data, dispatchLoadBankGuaranteePage, allData] as [
		typeof data,
		typeof dispatchLoadBankGuaranteePage,
		typeof allData
	];
};  */
