import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import ReplyIcon from "@material-ui/icons/ReplyOutlined";
import { ViewOrder } from "../../../data/trading/orders/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewOrder } from "../orders/DisplayOrder";
import { useHistory } from "react-router-dom";
import { getOrderEditLocation, OrdersEditParts } from "./Locations";
import { OrdersEditAction } from "../../../data/trading/orders/types";
import { DiplayOrderMod } from "../orders/DisplayOrderMainAttributes";

export function useOrderActions() {
	//  onShowOrder?: (order: ViewOrder) => void,
	//  onReplayToOrder?: (order: ViewOrder) => void
	const { getString } = useContext(LocalizationContext);
	const onShowMBOrder = useViewOrder(DiplayOrderMod.PublicData);
	const onReplayToOrder = useReplyMBOrder();

	return useMemo(() => {
		const result: MaterialTableProps<ViewOrder>["actions"] = [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("orderActView"),
				onClick: (event, rowData) => onShowMBOrder(rowData as ViewOrder),
			},
			row => ({
				icon: () => <ReplyIcon />,
				tooltip: getString("orderActReply"),
				onClick: (event, rowData) => onReplayToOrder(rowData as ViewOrder),
				hidden: row.canReplace !== 1,
			}),
		];
		return result;
	}, [onShowMBOrder, onReplayToOrder, getString]);
}

export const useReplyMBOrder = () => {
	const history = useHistory();
	return React.useCallback(
		(order: ViewOrder) =>
			history.push(
				getOrderEditLocation(OrdersEditAction.Replay, OrdersEditParts.BasicInfo, order.ordId)
			),
		[history]
	);
};
