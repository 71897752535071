import React from "react";
import { AutocompleteSelect, AutocompleteSelectType } from "../common/AutocompleteSelect";
import { useSimpleOrdersData } from "../../../data/trading/orders/hooks/useSimpleOrdersData"
import { SimpleOrderFilter, OrderSimpleData } from "../../../data/trading/orders/types"

type FiltredOrdersSelectPropsType = Omit<AutocompleteSelectType<OrderSimpleData>, "data" | "getLabel"> & {
	filter?: SimpleOrderFilter;
	disabled?: boolean;
};

export const FiltredOrdersSelect: React.FunctionComponent<FiltredOrdersSelectPropsType> = ({
	filter,
	disabled,
	...otherProps
}) => {
	const [orders] = useSimpleOrdersData(filter);

	return (
		<AutocompleteSelect<OrderSimpleData>
			{...(otherProps as AutocompleteSelectType<OrderSimpleData>)}
			data={orders || []}
			getLabel={renderAttributeLabel}
			disabled={filter === undefined || disabled}
		/>
	);
};

const renderAttributeLabel = (order: OrderSimpleData) => order.ordData;
