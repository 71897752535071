import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewAttribute } from "./DisplayAttribute";
import { useAttributeDelete } from "../../../data/listing/attributes/hooks/useAttributeDelete";
import { Attribute } from "../../../data/listing/attributes/types";
import { AttributeEditParts, getAttributeEditLocation } from "./Locations";
import { AccessRights } from "../../../core/navigation/types";

export const useAttributeActions = (userAccessRights: AccessRights) => {
	const { getString } = useContext(LocalizationContext);
	const onShowClient = useViewAttribute();
	const onEditClient = useEditAttributeAction();

	return useMemo(() => {
		const result: Action<Attribute>[] = [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tableShow"),
				onClick: (event, rowData) => onShowClient(rowData as Attribute),
			},
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditClient(rowData as Attribute),
				hidden: userAccessRights===AccessRights.ReadOnly
			},
		];

		return result;
	}, [onShowClient, getString, onEditClient]);
};

export const useDeleteAttributeAction = () => {
	const [fetchState, deleteAttribute, reset] = useAttributeDelete();
	const action = React.useCallback(
		async (attr: Attribute) => {
			if (attr.attrId) {
				await deleteAttribute(attr.attrId);
			}
		},
		[deleteAttribute]
	);
	return [fetchState, action, reset] as [typeof fetchState, typeof action, typeof reset];
};

export const useEditAttributeAction = () => {
	const history = useHistory();
	return React.useCallback(
		(atribute: Attribute) =>
			history.push(getAttributeEditLocation(AttributeEditParts.BasicInfo, atribute.attrId)),
		[history]
	);
};
