import { ThunkType } from "../../../../core/store/reducers";
import { setOrderPSTM  } from "../../orderBook/orderBookDataSlice";

export const thunkPSTM = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const orderPSTM  = store.data.trading.orderBook.orderPSTM;

	const user = store.data.auth.current.user;

    if(orderPSTM.type === "not-started") { dispatch(setOrderPSTM({ type: "started" })) }
	if(orderPSTM.type === "started") {dispatch(setOrderPSTM({ type: "not-started" }))};

};
