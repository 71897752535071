import React from "react";
import { useDisplayStockFee } from "./useDisplayStockFee";
import { StockFee } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defaultStockFee: StockFee = {
  status: Status.Active,
};

export const useEditStockFee = (stfeeId?: number) => {
  const displayStockFee = useDisplayStockFee(stfeeId);

  return React.useMemo(() => {
    if (stfeeId !== undefined && displayStockFee === undefined) {
      return undefined;
    }

    if (stfeeId === undefined) {
      return { ...defaultStockFee };
    }

    return displayStockFee ? { ...displayStockFee } : undefined;
  }, [stfeeId, displayStockFee]);
};

export const useEditStockFeeState = (stfeeId?: string) => {
  const id = Number(stfeeId);
  const stfee = useEditStockFee(!!id ? id : undefined);
  return useCopiedState(stfee);
};
