import React from "react";
import {
  Grid,
  CardContent,
  CardHeader,
  Card,
  Divider,
} from "@material-ui/core";
import DailyTradesReportTable from "./DailyTradesReportTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { DailyTradesReportFilter } from "./DailyTradesReportFilter";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";

const DailyTradesReport: PageComponent = ({ userAccessRights }) => {
  const pageStyles = usePageStyles();

  return (
    <>
      <Grid container className={pageStyles.root}>
        <Grid item xs={12} className={pageStyles.gridItem}>
          <Card>
            <CardHeader
              title={<PageTitle title="dailyTradeReportTitle"  /> }
              action={<DailyTradesReportActions />}
            />
            <Divider />
            <CardContent>
              <DailyTradesReportTable userAccessRights={userAccessRights} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export const DailyTradesReportActions: React.FunctionComponent = () => (
  <Grid container sm={12} >
    <Grid item alignItems="center" sm={8} xs={6} >
      <DailyTradesReportFilter />
    </Grid>
    <Grid item alignItems="center" sm={2} xs={2} >
      <DownloadButton />
    </Grid>
    <Grid item alignItems="center" sm={2} xs={2} >
      <DownloadExcelButton />
    </Grid>
  </Grid>
);

export default DailyTradesReport;
