import { useDispatch, useSelector } from "react-redux";
import { downloadMarginsExcel } from "../thunks/downloadReportExcel";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";

export const useMarginsExcelDownloader = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (s) =>
      s.data.clearing.marginsData.downloadReportFetchState
  );
  const dispatch = useDispatch();
  const download = React.useCallback(
    () => dispatch(downloadMarginsExcel()),
    [dispatch]
  );

  return [download, fetchState] as [typeof download, typeof fetchState];
};
