import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useCityData } from "../../../data/listing/cities/hooks/useCityData";
import { City } from "../../../data/listing/cities/types";
import { useCityColumns } from "./useCityColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useCityActions, useDeleteCityAction } from "./useCityActions";

export interface CitiesTableProps {
	userAccessRights: AccessRights;
}

type CityTablePropsType = CitiesTableProps & TableDataProps<City>;

export const CitiesTable: React.FunctionComponent<CityTablePropsType> = ({
	userAccessRights,
	...tableProps
}) => {
	const [data, loadAll] = useCityData();

	const columns = useCityColumns();
	const actions = useCityActions(userAccessRights);
	const onDeleteCity = useDeleteCityAction();

	return (
		<Table<City>
			{...tableProps}
			getData={loadAll}
			immutableData={data}
			title=""
			columns={columns}
			actions={actions}
			options={{
				toolbar: false,
				pageSize: 10,
				actionsColumnIndex: -1,
			}}
			editable={userAccessRights !== AccessRights.ReadOnly ? {
				onRowDelete: onDeleteCity,
			}: {}}
		/>
	);
};

export default CitiesTable;
