import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { TradePricesChartData } from "../types";
import { loadTickerData } from "../thunks/loadTickerData";

const tickerLoaderInterval = 30000;

export const useTickerData = (shouldFetchData: boolean) => {
	const dispatch = useDispatch();
	const data = useSelector<StoreType, TradePricesChartData[] | undefined>(
		s => s.data.trading.ticker.ticketData
	);

	const startTickerFetcher = React.useCallback(
		createTickerFetcher(() => {
			console.log("Load ticker");
			dispatch(loadTickerData());
		}),
		[dispatch]
	);

	React.useEffect(() => {
		if (shouldFetchData) {
			return startTickerFetcher();
		}
	}, [startTickerFetcher, shouldFetchData]);

	return [data];
};

const createTickerFetcher = (loadTicker: () => void) => () => {
	loadTicker();
	const interval = setInterval(loadTicker, tickerLoaderInterval);
	return () => {
		clearInterval(interval);
	};
};
