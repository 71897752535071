import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { loadReport } from "../thunks/loadReportData";
import { AnnualSecurityReportData } from "../types";

export const useAnnualSecurityReportData = () => {
	const data = useSelector<StoreType, AnnualSecurityReportData[] | undefined>(
		s => s.data.reports.securityCommission.annualSecurity.data
	);

	const dispatch = useDispatch();
	const dispatchLoadData = React.useCallback(() => dispatch(loadReport()), [dispatch]);

	return [data, dispatchLoadData] as [typeof data, typeof dispatchLoadData];
};
