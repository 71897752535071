import { ThunkType } from "../../../../core/store/reducers";
import createProductApi from "../api/createProduct";
import updateProductApi from "../api/updateProduct";
import { setFetchState } from "../productsDataReducer";
import { Product } from "../types";

export const upsertProduct = (product: Product): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = product.prodId
		? await dispatch(updateProductApi(userId, product))
		: await dispatch(createProductApi(userId, product));

	if (result && result.resCode === "OK") {
		dispatch(setFetchState({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
	}
};
