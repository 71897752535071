import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllClients } from "../thunks/loadAllClients";
import { ClientListData } from "../types";

export const useClientData = () => {
	const data = useSelector<StoreType, ClientListData[] | undefined>(
		s => s.data.listing.clients.displayData
	);
	const allData = useSelector<StoreType, ClientListData[] | undefined>(s => s.data.listing.clients.all);

	const dispatch = useDispatch();
	const dispatchLoadAllClients = React.useCallback(() => dispatch(loadAllClients()), [dispatch]);

	return [data, dispatchLoadAllClients, allData] as [
		typeof data,
		typeof dispatchLoadAllClients,
		typeof allData
	];
};

export const useClientSelector = (selector: (client: ClientListData) => boolean) => {
	const [, loadAllClients, data] = useClientData()

	const [client, setClient] = React.useState<ClientListData | undefined>();

	React.useEffect(() => {
		if (!data) {
			loadAllClients()
		}
	}, [data, loadAllClients])

	React.useEffect(() => {
		setClient(data?.find(selector))
	}, [selector, data]);

	return [client];
};
