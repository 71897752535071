import { webApi } from "../../../../core/http/httpThunks";

export const deleteStockFeeApi = (userId: string, id: number) =>
  webApi<string>({
    method: "DELETE",
    url: "clearing/delstockfee",
    params: {
      userId,
      id,
    },
  });

export default deleteStockFeeApi;
