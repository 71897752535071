import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { StockFee } from "../types";

export const createStockFeeApi = (userId: string, stfee: StockFee) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "clearing/newstockfee",
    params: {
      userId,
    },
    data: stfee,
  });

export default createStockFeeApi;
