import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { loadTrades } from "../thunks/loadTrades";
import { PageData } from "../../../../../data/models";
import { TradeList } from "../../../../trading/trades/types";

export const useTradesData = () => {
	const pageData = useSelector<StoreType, PageData<TradeList> | undefined>(
		s => s.data.reports.exchbusiness.clientOverview.currentPageTrade
		//    (s) => s.data.trading.trades.currentPage
	);

	const dispatch = useDispatch();
	const dispatchLoadTradesPage = React.useCallback(() => dispatch(loadTrades()), [dispatch]);

	return [pageData, dispatchLoadTradesPage] as [typeof pageData, typeof dispatchLoadTradesPage];
};
