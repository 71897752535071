import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertEmployee } from "../thunks/upsertEmployee";
import { setFetchStateEmployee } from "../employeesDataReducer";
import { Employee } from "../types";

export const useEmployeeUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.employees.fetchState
	);

	const dispatch = useDispatch();
	const dispatchUpsertEmployee = React.useCallback(
		(employee: Employee) => dispatch(upsertEmployee(employee)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateEmployee({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchUpsertEmployee] as [typeof fetchState, typeof dispatchUpsertEmployee]
};
