import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { cancelTrade } from "../thunks/cancelTrade";
import { setCancelTradeFetchState } from "../tradesDataSlice";

export const useTradeCancel = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.trading.trades.cancelFetchState
	);

	const dispatch = useDispatch();
	const dispatchCancelOrder = React.useCallback(
		(id: string) => dispatch(cancelTrade(id)),
		[dispatch]
	);

	React.useEffect(() => {
		return () => {
			dispatch(setCancelTradeFetchState({ type: "not-started" }))
		}
	}, [dispatch])

	return [fetchState, dispatchCancelOrder] as [typeof fetchState, typeof dispatchCancelOrder]
};
