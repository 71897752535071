import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { City } from "../../models";

const getCityLabel = (city?: City) => (city ? `${city.cityName} (${city.zipCode})` : "");

export const useCities = () => {
	const cities = useSelector<StoreType, City[]>(store => store.data.initialData.cities);

	return [cities, getCityLabel] as [typeof cities, typeof getCityLabel];
};

export const useCity = (cityId?: string) => {
	const [cities] = useCities();

	const city = React.useMemo(() => cities.find(c => c.cityId === cityId), [cities, cityId]);
	const cityLabel = React.useMemo(() => getCityLabel(city), [city]);

	return [city, cityLabel] as [typeof city, typeof cityLabel];
};
