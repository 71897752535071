import React from "react";
import { ClientAccountList } from "../../../../data/listing/clients/types";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../../components/common/DisplayBoolNumber";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { useClientAccounts } from "../../../../data/listing/clients/hooks/clientAccount/useClientAccounts";
import { useClientAccountSideTranslator } from "../../../../data/listing/clients/hooks/clientAccount/useClientAccountSide";
import {
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	TableBody,
	Typography,
	Grid,
} from "@material-ui/core";
import { Status } from "../../../../data/models";

export interface DisplayClientBrokersProps {
	clientId: string;
}

export const DisplayClientAccounts: React.FunctionComponent<DisplayClientBrokersProps> = ({ clientId }) => {
	const style = useDisplaySingleEmementStyles();
	const [accounts] = useClientAccounts(clientId);

	return (
		<div className={style.root}>
			<div className={style.row}>
				{accounts ? <ClientAccountsDisplayTable accounts={accounts} /> : "loading placeholder"}
			</div>
		</div>
	);
};

interface ClientAccountsDisplayTableProps {
	accounts: ClientAccountList[];
}

const ClientAccountsDisplayTable: React.FunctionComponent<ClientAccountsDisplayTableProps> = ({
	accounts,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);
	const sideTranslator = useClientAccountSideTranslator();

	if (accounts.length === 0) {
		return (
			<Grid container justify="center">
				<Typography>{getString("accountEmpty")}</Typography>
			</Grid>
		);
	}

	return (
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>{getString("accountNumber")}</TableCell>
						<TableCell>{getString("accountCurrency")}</TableCell>
						<TableCell>{getString("accountForBuySell")}</TableCell>
						<TableCell>{getString("accountStatus")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{accounts.map(account => (
						<TableRow key={account.monAccId}>
							<TableCell>{account.account}</TableCell>
							<TableCell>{account.currCode}</TableCell>
							<TableCell>{sideTranslator(account.forBuySell)}</TableCell>
							<TableCell>
								<DisplayBoolNumber
									value={account.status}
									thrutyValue={Status.Active}
									className={style.icon}
								></DisplayBoolNumber>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
