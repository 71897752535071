import { ThunkType } from "../../../../core/store/reducers";
import getTransportFeeApi from "../api/getTransportFee";
import { setDisplayed } from "../transportFeesDataReducer";
import { TransportFee } from "../types";

export const loadTransportFee = (id: number): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const transportfee = await dispatch(getTransport_Fee(id));

  dispatch(setDisplayed(transportfee));
};

export const getTransport_Fee = (
  trfeeid: number
): ThunkType<Promise<TransportFee | undefined>> => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getTransportFeeApi(userId, trfeeid));
};
