import { ThunkType } from "../../../../core/store/reducers";
import { setTradingsData, invalidateTradingsData } from "../tradingDataSlice";
import { setTradingDateIndex } from "../tradingUXSlice";
import getTradingDataWebApi from "../api/getAllTradingData";
import { PageIndex, PageDataStatus } from "../../../models";

export const loadAllTradingData = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const { pageNumber, itemsPerPage } = store.ux.clearing.tradingData.index;
  const { filter } = store.ux.clearing.tradingData;
  const user = store.data.auth.current.user;

  const tradingDataResponse = await dispatch(
    getTradingDataWebApi(user, pageNumber, itemsPerPage, filter)
  );

  if (!tradingDataResponse || tradingDataResponse.resCode !== "OK") {
    return;
  }

  const tradingData = {
    currentPageData: tradingDataResponse.obj,
    totalCount: Number(tradingDataResponse.resDesc) || 0,
    status: PageDataStatus.DataCurrent,
  };

  dispatch(setTradingsData(tradingData));
};

export const setTradingDataIndexThunk = (index: PageIndex): ThunkType => (
  dispatch
) => {
  dispatch(setTradingDateIndex(index));
  dispatch(invalidateTradingsData());
};
