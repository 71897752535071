import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Currency } from "../types";

export const updateCurrencyApi = (userId: string, currency: Currency) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/updcurr",
		params: {
			userId
		},
		data: currency
	});

export default updateCurrencyApi;
