import { Theme, makeStyles, createStyles } from "@material-ui/core";

export const useMainBarStyles = makeStyles((theme: Theme) => {
	const { permanentBreakpoint, fullWidth } = theme.navDrawer;
	return createStyles({
		appBar: {
			// [permanentBreakpoint]: {
			// 	width: `calc(100% - ${hiddenWidth}px)`,
			// 	marginLeft: hiddenWidth,
			// },
			[permanentBreakpoint]: {
				width: `calc(100% - ${fullWidth}px)`,
				marginLeft: fullWidth,
			},
		},
		appBaraContent: {
//			alignItems: "baseline",               // Kad sam izbacio ovo poravnao sam logo sa loginom
			justifyContent: "space-between",
		},
		titleArea: {
			display: "flex",
			justifyContent: "center",
//			alignItems: "baseline",   // Kad sam izbacio ovo poravnao sam logo sa loginom
		},
		logo: {
			width: 80,
			verticalAlign: "sub",
		  },
		menuButton: {
			[permanentBreakpoint]: {
				display: "none",
			},
			marginRight: theme.spacing(2),
		},
		title: {
			fontFamily: "Montserrat",
			fontWeight: 700,
		}
	});
});
