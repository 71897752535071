import { PayType } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";

interface IPayTypeData {
  all?: PayType[];
  searchTerm: string;
  displayData?: PayType[];
  displayedPayType?: PayType;
  fetchState: FetchStateType;
}

const initialState: IPayTypeData = {
  searchTerm: "",
  fetchState: { type: "not-started" },
};

const name = "payType";

const payTypeSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<PayType[] | undefined>) => ({
      ...state,
      all: action.payload,
      displayData: filterData(action.payload, state.searchTerm),
    }),
    setSearchTerm: (state, action: PayloadAction<string>) => ({
      ...state,
      searchTerm: action.payload.toLocaleLowerCase(),
      displayData: filterData(state.all, action.payload),
    }),
    setDisplayed: (state, action: PayloadAction<PayType | undefined>) => ({
      ...state,
      displayedPayType: action.payload,
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      displayedPayType:
        action.payload.type === "successful" ? undefined : state.displayedPayType,
      all: action.payload.type === "successful" ? undefined : state.all,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: getFetchStateChange(state.fetchState, action.payload),
    }),
  },
});

const filterData = (data?: PayType[], searchTerm?: string) =>
  searchTerm === undefined || searchTerm === ""
    ? data
    : data?.filter(
        (e) =>
          (e.payTypeDescr &&
            e.payTypeDescr
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
          (e.payTypeDescrEn &&
            e.payTypeDescrEn
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) 
      );

export const {
  setData,
  setSearchTerm,
  setDisplayed,
  setFetchState,
} = payTypeSlice.actions;

export default payTypeSlice.reducer;
