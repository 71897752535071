import React from "react";
import { Role } from "../../../data/listing/roles/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";

export interface DisplayEmployeeMainAttributesProps {
	role: Role;
}

export const DisplayEmployeeMainAttributes: React.FunctionComponent<DisplayEmployeeMainAttributesProps> = ({
	role,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);

	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("roleCode")}</span>
				<span className={style.value}>{role.roleCode}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("roleName")}</span>
				<span className={style.value}>{role.roleName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("roleNameEn")}</span>
				<span className={style.value}>{role.roleNameEn}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("roleIsEmployee")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={role.isEmployee}
						thrutyValue={1}
						className={style.icon}
					/>
				</span>
			</div>
		</div>
	);
};
