import { ThunkType } from "../../../../core/store/reducers";
import getGuaranteeApi from "../api/getGuarantee";
import { setDisplayed } from "../guaranteesDataSlice";
import { GuaranteeData } from "../types";

export const loadGuarantee = (payId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const guarantee = await dispatch(getGuarantee(payId));

  dispatch(setDisplayed(guarantee));
};

export const getGuarantee = (
  payId: string
): ThunkType<Promise<GuaranteeData | undefined>> => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getGuaranteeApi(userId, payId));
};
