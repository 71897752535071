import { useCopiedState } from "../../../../core/util/hooks";
import { setFilter, removeFilter, initialState } from "../ctrlGoodsUXSlice";
import { invalidateControl } from "../ctrlGoodsDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { CtrlGoodsFilter } from "../types";

export const useCtrlGoodsFilter = () => {
  const [storeFilter] = useCurrentCtrlGoodsFilter();


  const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<CtrlGoodsFilter>(
    storeFilter
  );


  const dispatch = useDispatch();
  const dispatchSetFilter = React.useCallback(() => {
    dispatch(setFilter(filter!));
    dispatch(invalidateControl());
  }, [dispatch, filter]);

  const dispatchResetFilter = React.useCallback(() => {
    setCurrentFilter(initialState.filter);
  }, [setCurrentFilter]);

  return [filter, editFilter, dispatchSetFilter, changeState, dispatchResetFilter] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter,
    typeof changeState,
    typeof dispatchResetFilter
  ];
};

export const useCurrentCtrlGoodsFilter = () => {
  const filter = useSelector<StoreType, CtrlGoodsFilter>(
    (store) => store.ux.clearing.controls.filter
  );
  const dispatch = useDispatch();
  const dispatchRemoveFilter = React.useCallback(
    (type: keyof CtrlGoodsFilter) => {
      dispatch(removeFilter(type));
      dispatch(invalidateControl());
    },
    [dispatch]
  );

  return [filter, dispatchRemoveFilter] as [
    typeof filter,
    typeof dispatchRemoveFilter
  ];
};
