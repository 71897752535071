import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SnackbarMessage } from "./types";

interface ISnackbarState {
	messages: SnackbarMessage[];
}

interface IShownArgs {
	messageId: string,
	shownId: string,
}

interface IToast {
	id: string,
	title: string,
    message: string[],
}

const initialState: ISnackbarState = {
	messages: [],
};


const name = "snackbar";

const snackbarSlice = createSlice({
	name,
	initialState,
	reducers: {
		pushError: (state, action: PayloadAction<IToast>) => ({
			...state,
			messages: [
				...state.messages,
				{
					id: action.payload.id,
					message: action.payload.message,
					title: action.payload.title,
					type: "error",
				},
			],
		}),
		pushInfo: (state, action: PayloadAction<IToast>) => ({
			...state,
			messages: [
				...state.messages,
				{
					id: action.payload.id,
					message: action.payload.message,
					title: action.payload.title,
					type: "info",
				},
			],
		}),
		pushwarning: (state, action: PayloadAction<IToast>) => ({
			...state,
			messages: [
				...state.messages,
				{
					id: action.payload.id,
					message: action.payload.message,
					title: action.payload.title,
					type: "warning",
				},
			],
		}),
		dismiss: (state, action: PayloadAction<string>) => ({
			...state,
			messages: [
				...state.messages.filter(m => m.id !== action.payload),
			],
		}),
		setShownId: (state, action: PayloadAction<IShownArgs>) => ({
			...state,
			messages: [
				...state.messages.map(m => m.id !== action.payload.messageId ? m : {
					...m,
					shownId: action.payload.shownId
				}),
			],
		}),
	},
});

export const { pushError, dismiss, pushInfo, pushwarning, setShownId } = snackbarSlice.actions;

export default snackbarSlice.reducer;
