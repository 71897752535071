import { TradesSimpleData, TradeSimpleFilter } from "../types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";

export const getTradesSimpleData = (userId: string, filter: TradeSimpleFilter) => {
	return webApiWithResponse<TradesSimpleData[]>({
		method: "GET",
		url: "trade/gettradesimplelist",
		params: {
			userid: userId,
			market: filter.market,
			prodid: filter.prodId,
		},
	});
};
