import React from "react";
import { CtrlGoodsData } from "../../../data/clearing/ctrlgoods/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";

export interface DisplayCtrlGoodsMainAttributesProps {
  ctrlGood: CtrlGoodsData;
}

export const DisplayCtrlGoodMainAttributes: React.FunctionComponent<DisplayCtrlGoodsMainAttributesProps> = ({
  ctrlGood,
}) => {
  const style = useDisplaySingleEmementStyles();
  const { getString, getDateString } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("ctrlcontrolDate")}</span>
        <span className={style.value}>{getDateString(ctrlGood.controlDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("ctrltradeNo")}</span>
        <span className={style.value}>{ctrlGood.tradeNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("ctrlsellerCode")}</span>
        <span className={style.value}>{ctrlGood.sellerCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("ctrlsellerName")}</span>
        <span className={style.value}>{ctrlGood.sellerName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("ctrlbuyerCode")}</span>
        <span className={style.value}>{ctrlGood.buyerCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("ctrlbuyerName")}</span>
        <span className={style.value}>{ctrlGood.buyerName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("ctrlproductCode")}</span>
        <span className={style.value}>{ctrlGood.productCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("ctrlproductName")}</span>
        <span className={style.value}>{ctrlGood.productName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("ctrlcheckPointDescr")}</span>
        <span className={style.value}>{ctrlGood.checkPointDescr}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("ctrlcheckDoneByDescr")}</span>
        <span className={style.value}>{ctrlGood.checkDoneByDescr}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("ctrlcontrolHouse")}</span>
        <span className={style.value}>{ctrlGood.controlHouse}</span>
      </div>

      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("ctrlcontrolReportText")}</span>
        <span className={style.value}>{ctrlGood.controlReportText}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("ctrlstatus")}</span>
        <span className={style.value}>{ctrlGood.statusDescr}</span>
      </div>
    </div>
  );
};
