import { BankGuaranteeData, BankGuaranteeValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const bankguaranteeValidationState: BankGuaranteeValidationState = {
  grntDate: true,
  clientId: true,
  bankId: true,
  amt: true,
};

export const useBankGuaranteeValidator = createUseValidator<
BankGuaranteeData,
BankGuaranteeValidationState
>(bankguaranteeValidationState);
