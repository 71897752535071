import { ThunkType } from "../../../../core/store/reducers";
import getAccountingOrderButton from "../api/getAccountingOrderButton";
import { setFetchState } from "../accountingOrderDataSlice";
import fileDownload from "js-file-download";
import moment from "moment";

export const downloadAccountingOrderButton = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.accounting.accountingOrder;
  const user = store.data.auth.current.user;
  dispatch(setFetchState({ type: "started" }));
  const accountingOrdersReport = await dispatch(
    getAccountingOrderButton(user, filter)
  );

  if (accountingOrdersReport) {
    fileDownload(
      new Blob([accountingOrdersReport as any], {
        type: "application/vnd.openxmlformat",
      }),
      "AccountingOrder_" + moment().format("YYYYMMDDhhmmss") + ".xlsx"
    );
  }
  dispatch(setFetchState({ type: "successful" }));
};
