import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useRoleData } from "../../../data/listing/roles/hooks/useRoleData";
import { Role } from "../../../data/listing/roles/types";
import { useRoleColumns } from "./useRoleColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useRoleActions, useDeleteRoleAction } from "./useRoleActions";

export interface CompaniesTableProps {
	userAccessRights: AccessRights;
}

type RolesTablePropsType = CompaniesTableProps & TableDataProps<Role>;

export const RolesTable: React.FunctionComponent<RolesTablePropsType> = ({
	userAccessRights,
	...tableProps
}) => {
	const [data, loadAll] = useRoleData();

	const columns = useRoleColumns();
	const actions = useRoleActions(userAccessRights);
	const onDeleteEmployee = useDeleteRoleAction();

	return (
		<Table<Role>
			{...tableProps}
			getData={loadAll}
			immutableData={data}
			title=""
			columns={columns}
			actions={actions}
			options={{
				toolbar: false,
				pageSize: 10,
				actionsColumnIndex: -1,
			}}
			editable={userAccessRights !== AccessRights.ReadOnly ? {
				onRowDelete:  onDeleteEmployee,
			  }: {}}
		/>
	);
};

export default RolesTable;
