import { ThunkType } from "../../../../../core/store/reducers";
import getAllAttributes from "../../api/attributes/getAllAttributes";
import { ProductAttribute } from "../../types";

export const getAttributes = (productId: string): ThunkType<Promise<ProductAttribute[] | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getAllAttributes(userId, productId));
}
