import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { Bank } from "../../../data/listing/banks/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewBank } from "./DisplayBank";
import { useBankDelete } from "../../../data/listing/banks/hooks/useBankDelete";
import { getBankEditLocation } from "./Locations";
import { AccessRights } from "../../../core/navigation/types";


export const useBankActions = (userAccessRights: AccessRights) => {
  const { getString } = useContext(LocalizationContext);
  const onShowBank = useViewBank();
  const onEditBank = useEditBankAction();

  return useMemo(() => {
    const result: Action<Bank>[] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("tableShow"),
        onClick: (event, rowData) => onShowBank(rowData as Bank),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) => onEditBank(rowData as Bank),
        hidden: userAccessRights===AccessRights.ReadOnly
      },
    ];

    return result;
  }, [onShowBank, onEditBank, getString]);
};

export const useDeleteBankAction = () => {
  const [, deleteBank] = useBankDelete();
  return React.useCallback(
    async (bank: Bank) => {
      if (bank.bankId ) {
        await deleteBank(bank.bankId);
      }
    },
    [deleteBank]
  );
};

export const useEditBankAction = () => {
  const history = useHistory();
  return React.useCallback(
    (bank: Bank) => history.push(getBankEditLocation(bank.bankId)),
    [history]
  );
};
