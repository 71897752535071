import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { resetIndex } from "../tradesReportUXSlice";
import { PageIndex } from "../../../models";
import { setIndexThunk } from "../thunks/loadTradesReport";

export const useTradesReportIndex = () => {
	const pageIndex = useSelector<StoreType, PageIndex>(s => s.ux.reports.tradesReport.index);

	const dispatch = useDispatch();
	const dispatchSetIndex = React.useCallback((index: PageIndex) => dispatch(setIndexThunk(index)), [
		dispatch,
	]);
	const dispatchResetIndex = React.useCallback(() => dispatch(resetIndex()), [dispatch]);

	return [pageIndex, dispatchSetIndex, dispatchResetIndex] as [
		typeof pageIndex,
		typeof dispatchSetIndex,
		typeof dispatchResetIndex
	];
};
