import React from "react";
import { AttributeType } from "../types";

interface AttributeTypeOption {
	label: string;
	value: AttributeType;
}

export const useAttributeTypeOptions = (): AttributeTypeOption[] => {
	return React.useMemo(
		() => [
			{
				label: AttributeType.Text,
				value: AttributeType.Text,
			},
			{
				label: AttributeType.Select,
				value: AttributeType.Select,
			},
		],
		[]
	);
};
