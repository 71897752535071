import React from "react";
import { useDisplayUser } from "../../../data/listing/users/hooks/useDisplayUser";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { LocalizationContext } from "../../../core/localization/Localization";
import { UserListData } from "../../../data/listing/users/types";
import { DisplayUserMainAttributes } from "./DisplayUserMainAttributes";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export interface DisplayEmployeeProps {
	id?: string;
}

export const DisplayClient: React.FunctionComponent<DisplayEmployeeProps> = ({ id }) => {
	const user = useDisplayUser(id);
	const { getString } = React.useContext(LocalizationContext);

	return !user ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel expanded>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{getString("tableBasciInfo")}</Typography>
				</ExpansionPanelSummary>
				<DisplayUserMainAttributes user={user} />
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewUser = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(user: UserListData) => showWithDrawer(<DisplayClient id={user.userId} />),
		[showWithDrawer]
	);
};
