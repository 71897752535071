import React from "react";
import { useAnnualTradesColumns } from "./useAnnualTradingReportColumns";
import { AccessRights } from "../../../../core/navigation/types";
import { AnnualSecurityReportData } from "../../../../data/reports/securityCommission/securityAnnual/types";
import { ControledTable } from "../../../components/common/ControledTable";
import { useAnnualSecurityReportData } from "../../../../data/reports/securityCommission/securityAnnual/hooks/useSecurityReportData";
import { useSecurityReportIndex } from "../../../../data/reports/securityCommission/securityAnnual/hooks/useSecurityReportIndex";

export interface AnnualSecurityTableProps {
  userAccessRights: AccessRights;
}

type OrderTablePropsType = AnnualSecurityTableProps;

const DailyTradesReportTable: React.FunctionComponent<OrderTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useAnnualTradesColumns();
  const [data, load] = useAnnualSecurityReportData();
  const [index, setIndex] = useSecurityReportIndex();

  return (
    <ControledTable<AnnualSecurityReportData>
      {...tableProps}
      onPageIndexChange={setIndex}
      pageIndex={index}
      getData={load}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default DailyTradesReportTable;
