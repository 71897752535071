import React from "react";
import { useTradeStatuses } from "../../../data/initialData/hooks/useTradeStatuses";
import { TradeStatus } from "../../../data/models";
import {
  AutocompleteSelect,
  AutocompleteSelectType,
} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<TradeStatus>,
  "data" | "getLabel"
>;


export const TradeStatusSelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [statuses, renderStatus] = useTradeStatuses();

  return (
    <AutocompleteSelect<TradeStatus>
      data={statuses}
      getLabel={renderStatus}
      {...(props as any)}
    />
  );
};
