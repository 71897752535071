import React from "react";
import { useDisplayProductGroup } from "../../../data/listing/productGroups/hooks/useDisplayProductGroup";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { ProductGroupData } from "../../../data/listing/productGroups/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayProductGroupBasicInfo } from "./DisplayProductGroupBasicInfo";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid } from "@material-ui/core";

export interface DisplayEmployeeProps {
	id?: string;
}

export const DisplayEmployee: React.FunctionComponent<DisplayEmployeeProps> = ({ id }) => {
	const group = useDisplayProductGroup(id);
	const { getString } = React.useContext(LocalizationContext);

	return !group ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel expanded>
				<ExpansionPanelSummary>
					<Typography>{getString("tableBasciInfo")}</Typography>
				</ExpansionPanelSummary>
				<DisplayProductGroupBasicInfo group={group} />
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewProductGroup = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(group: ProductGroupData) => showWithDrawer(<DisplayEmployee id={group.prodGroupID} />),
		[showWithDrawer]
	);
};
