import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PageData,
  PageDataStatus,
  FetchStateType,
  getFetchStateChange,
} from "../../models";
import { MarginsData } from "./types";

type MarginData = {
  all?: MarginsData[];
  displayData?: MarginsData[];
  currentPage?: PageData<MarginsData>;
  displayedMarginsgData?: MarginsData;
  fetchState: FetchStateType;
  cancelFetchState: FetchStateType;
  downloadReportFetchState: FetchStateType;
  printReportFetchState: FetchStateType;
};

const initialState: MarginData = {
  cancelFetchState: { type: "not-started" },
  fetchState: { type: "not-started" },
  downloadReportFetchState: { type: "not-started" },
  printReportFetchState:{ type: "not-started" },
};

const name = "marginsData";

const marginsDataSlice = createSlice({
  name,
  initialState,
  reducers: {
    setMarginsData: (state, action: PayloadAction<PageData<MarginsData>>) => ({
      ...state,
      currentPage: action.payload,
    }),

    setDisplayed: (state, action: PayloadAction<MarginsData | undefined>) => ({
      ...state,
      displayedMarginsgData: action.payload,
    }),

    invalidateMarginssData: (state) => {
      if (!state.currentPage) return state;

      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          status: PageDataStatus.DataInvalidated,
        },
      };
    },
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      displayedMarginsgData:
        action.payload.type === "successful"
          ? undefined
          : state.displayedMarginsgData,
      all: action.payload.type === "successful" ? undefined : state.all,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: getFetchStateChange(state.fetchState, action.payload),
      downloadReportFetchState: action.payload,
    }),
    setCancelMarginsDataFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      cancelFetchState: action.payload,
    }),
    resetData: (state) => ({
      ...state,
      displayedMarginsgData: initialState.displayedMarginsgData,
      downloadReportFetchState: initialState.downloadReportFetchState,
      fetchState: initialState.fetchState,
    }),
    setPrintMarginsFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      printReportFetchState: action.payload,
    }),

  },
});

export const {
  setMarginsData,
  setDisplayed,
  invalidateMarginssData,
  setFetchState,
  setCancelMarginsDataFetchState,
  resetData,
  setPrintMarginsFetchState,
} = marginsDataSlice.actions;

export default marginsDataSlice.reducer;
