import React from "react";
import { Table, TableDataProps } from "../../../components/common/Table";
import { useAttributes } from "../../../../data/listing/products/hooks/attributes/useAttributes";
import { useAttributeDelete } from "../../../../data/listing/products/hooks/attributes/useAttributeDelete";
import { ProductAttribute, ProducAttributeManditory } from "../../../../data/listing/products/types";
import { Column, Action } from "material-table";
import { DisplayBoolNumber } from "../../../components/common/DisplayBoolNumber";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import EditIcon from "@material-ui/icons/EditOutlined";
import { ProductEditParts, getProductEditLocation } from "../Locations";
import { useHistory } from "react-router-dom";
import { Fab } from "@material-ui/core";
import { usePageStyles } from "../../../pageStyles";
import AddIcon from "@material-ui/icons/Add";
import { Status } from "../../../../data/models";

export interface AttributeTableProps {
	productId: string;
}

type AttributeTablePropsType = AttributeTableProps & TableDataProps<ProductAttribute>;

export const AttributeTable: React.FunctionComponent<AttributeTablePropsType> = ({
	productId,
	...tableProps
}) => {
	const pageStyles = usePageStyles();
	const history = useHistory();

	const [data, reload] = useAttributes(productId);

	const columns = useAttributeColumns();
	const actions = useAttributeActions();
	const onDeleteAttribute = useDeleteAttributeAction();

	return (
		<>
			<Table<ProductAttribute>
				{...tableProps}
				getData={() => {}}
				immutableData={data}
				title=""
				columns={columns}
				actions={actions}
				options={{
					toolbar: false,
					pageSize: 5,
					actionsColumnIndex: -1,
				}}
				editable={{
					onRowDelete: async broker => {
						await onDeleteAttribute(broker);
						reload();
					},
				}}
			/>
			<Fab
				color="secondary"
				aria-label="add"
				className={pageStyles.mainFab}
				onClick={() => history.push(getProductEditLocation(ProductEditParts.Attributes, productId, ""))}
			>
				<AddIcon />
			</Fab>
		</>
	);
};

export default AttributeTable;

const useAttributeColumns = () => {
	const style = useDisplaySingleEmementStyles();
	const { getString,  getFormatedNumberString, getFormatedNumberStringBlanko } = React.useContext(LocalizationContext);
	return React.useMemo(
		() => [
			{
				title: getString("productAttributeName"),
				field: "prodAttrName",
			},
			{
				title: getString("productAttributeType"),
				field: "type",
			},
			{
				title: getString("productAttributeComparator"),
				field: "comparator",
			},
			{
				title: getString("productAttributeStandardValue"),
				render: (attribute) => getFormatedNumberStringBlanko(attribute.standardValue, true),
				sorting: false,
				cellStyle: {
				  whiteSpace: "nowrap",
				  textAlign: "end",
				},
				headerStyle: {
				  textAlign: "end",
				},
			  },
		
			{
				title: getString("attributeOrder"),
				render: (attribute) => getFormatedNumberString(attribute.orderNo),
				sorting: false,
				cellStyle: {
				  whiteSpace: "nowrap",
				  textAlign: "end",
				},
				headerStyle: {
				  textAlign: "end",
				},
			  },

			  {
				title: getString("productAttributeStatus"),
				render: attribute => (
					<DisplayBoolNumber
						value={attribute.status}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				),
				customSort: (a, b) => a.status - b.status,
			},

			{
				title: getString("productAttributeManditory"),
				render: attribute => (
					<DisplayBoolNumber
						value={attribute.mandatory}
						thrutyValue={ProducAttributeManditory.True}
						className={style.icon}
					/>
				),
				customSort: (a, b) => a.mandatory - b.mandatory,
			},
		],
		[getString, style.icon]
	) as Column<ProductAttribute>[];
};

const useDeleteAttributeAction = () => {
	const [, deleteAttribute] = useAttributeDelete();
	return React.useCallback(
		async (attribute: ProductAttribute) => {
			if (attribute.prodAttrId) {
				await deleteAttribute(attribute.prodAttrId);
			}
		},
		[deleteAttribute]
	);
};

export const useAttributeActions = () => {
	const { getString } = React.useContext(LocalizationContext);
	const onEditAttribute = useEditAttributeAction();

	return React.useMemo(() => {
		const result: Action<ProductAttribute>[] = [
			{
				icon: () => <EditIcon />,
				tooltip: getString("tableEdit"),
				onClick: (event, rowData) => onEditAttribute(rowData as ProductAttribute),
			},
		];

		return result;
	}, [getString, onEditAttribute]);
};

export const useEditAttributeAction = () => {
	const history = useHistory();
	return React.useCallback(
		(attribute: ProductAttribute) =>
			history.push(
				getProductEditLocation(ProductEditParts.Attributes, attribute.productId, attribute.prodAttrId)
			),
		[history]
	);
};
