import React from "react";
import { useDisplayAlarm } from "./useDisplayAlarm";
import { AlarmData } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { AlarmType } from "../../../../data/models";
import { Status } from "../../../models";

export const defaultAlarm: AlarmData = {
   userId: "",
   almType: AlarmType.Product,
   active: Status.Active,
};

export const useEditAlarm = (almID?: string) => {
  const displayAlarm = useDisplayAlarm(almID);

  return React.useMemo(() => {
    if (almID !== undefined && displayAlarm === undefined) {
      return undefined;
    }

    if (almID === undefined) {
      return { ...defaultAlarm };
    }

    return displayAlarm ? { ...displayAlarm } : undefined;
  }, [almID, displayAlarm]);
};

export const useEditAlarmState = (almID?: string) => {
  const alarm = useEditAlarm(almID);
  return useCopiedState(alarm);
};
