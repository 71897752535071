import { ThunkType } from "../../../../core/store/reducers";
import createCommisionApi from "../api/createCommision";
import updateCommisionApi from "../api/updateCommision";
import { setFetchState } from "../commissionsDataReducer";
import { Commission } from "../types";

export const upsertCommission = (commission: Commission): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = commission.commId
		? await dispatch(updateCommisionApi(userId, commission))
		: await dispatch(createCommisionApi(userId, commission));

	if (result && result.resCode === "OK") {
		dispatch(setFetchState({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
	}
};
