import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadDisputes } from "../thunks/loadDisputes";
import { DisputeListData } from "../types";
import { PageData } from "../../../models";

export const useDisputeData = () => {
  const pageData = useSelector<
    StoreType,
    PageData<DisputeListData> | undefined
  >((s) => s.data.clearing.disputes.currentPage);

  const dispatch = useDispatch();
  const dispatchLoadDisputePage = React.useCallback(
    () => dispatch(loadDisputes()),
    [dispatch]
  );

  return [pageData, dispatchLoadDisputePage] as [
    typeof pageData,
    typeof dispatchLoadDisputePage
  ];
};
