import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useAlarmType } from "../../../data/surveillance/alarmParameters/hooks/useAlarmType";
import { TextField, MenuItem } from "@material-ui/core";
import { AlarmType } from "../../../data/models";

interface AlarmTypeSelectProps {
	alarmType: AlarmType;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}


export const AlarmTypeSelect: React.FunctionComponent<AlarmTypeSelectProps> = ({
	alarmType,
	handleChange,
}) => {
	const { getString } = React.useContext(LocalizationContext);
    const options = useAlarmType();

	return (
		<TextField
			name="almType"
			value={alarmType}
			onChange={handleChange}
			fullWidth
			select
			label={getString("alarmAlmType")}
		>
			{options.map(option => (
				<MenuItem key={option.value} value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</TextField>
	);
};
