import { webApi } from "../../../../core/http/httpThunks";
import { DeliveryData } from "../types";

export const getDeliveryApi = (userId: string, dlvrid: string) =>
  webApi<DeliveryData>({
    method: "GET",
    url: "clearing/getdlvrgoods",
    params: {
      userId,
      dlvrid,
    },
  });

export default getDeliveryApi;
