import { ThunkType } from "../../../../../core/store/reducers";
import getAllClientAccounts from "../../api/clientAccount/getAllClientAccounts";
import { ClientAccountList } from "../../types";

export const getClientAccounts = (clientId: string): ThunkType<Promise<ClientAccountList[] | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getAllClientAccounts(userId, clientId));
}
