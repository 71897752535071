import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { CurrencyRate } from "../types";
import { loadCurrencyRate } from "../thunks/loadCurrencyRate";

export const useDisplayCurrencyRate = (currRateId?: number) => {
	const storeCurrencyRate = useSelector<StoreType, CurrencyRate | undefined>(
		store => store.data.listing.currencyRates.displayed
    );

    const displayedCurrencyRate = storeCurrencyRate && storeCurrencyRate.currRateId === currRateId ? storeCurrencyRate : undefined;

    const displatch = useDispatch();

	React.useEffect(() => {
		if (currRateId) {
			displatch(loadCurrencyRate(currRateId));
		}
    }, [displatch, currRateId]);

	return displayedCurrencyRate;
};
