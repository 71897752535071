import { ExchangeMembersReportData } from "../../../data/reports/exmember/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";

export const useExchangeMemberColumns = () => {
  const { getString, getFormatedNumberString } = React.useContext(
    LocalizationContext
  );

  return React.useMemo(
    () => [
      {
        title: getString("exmemMemberName"),
        field: "memberName",
        sorting: false,
      },
      {
        title: getString("exmemMemberId"),
        field: "memberId",
        sorting: false,
      },
      {
        title: getString("exmemTaxCode"),
        field: "taxCode",
        sorting: false,
      },
      {
        title: getString("exmemAdress"),
        field: "address",
        sorting: false,
      },
      {
        title: getString("exmemPhone"),
        field: "phone",
        sorting: false,
      },
      {
        title: getString("exmemManager"),
        field: "manager",
        sorting: false,
      },
      {
        title: getString("exmemTrader"),
        field: "trader",
        sorting: false,
      },
      {
        title: getString("exmemContactPerson"),
        field: "contactPerson",
        sorting: false,
      },
    ],
    [getString, getFormatedNumberString]
  ) as Column<ExchangeMembersReportData>[];
};
