import React from "react";
import { Grid, CardContent, CardHeader, Card, Divider } from "@material-ui/core";
import TradingDataTable from "./TradingDataTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { useTradingDataData } from "../../../data/clearing/tradingData/hooks/useTradingDataData";
import { useTradingDataIndex } from "../../../data/clearing/tradingData/hooks/useTradingDataIndex";
import { PageDataStatus } from "../../../data/models";
import { TradingDataFilterButton } from "./TradingDataFilter";
import { RefPriceButton } from "./RefPriceButton";
import { TradingDateInput } from "./TradingDateInput";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";

const TradingDataPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [pageData, dispatchLoadTradingData] = useTradingDataData();
	const [pageIndex, dispatchSetIndex] = useTradingDataIndex();

	React.useEffect(() => {
		if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
			dispatchLoadTradingData();
		}
	}, [dispatchLoadTradingData, pageData]);

	return (
		<>
			<Grid container className={pageStyles.root}>
				<Grid item xs={12} className={pageStyles.gridItem}>
					<Card>
						<CardHeader
							title={<PageTitle title="tradingDataTitle" />}
							action={<TradingDataActions />}
						/>
						<Divider />
						<CardContent>
							<TradingDataTable
								pageIndex={pageIndex}
								pageData={pageData}
								onPageIndexChange={dispatchSetIndex}
								userAccessRights={userAccessRights}
								getData={dispatchLoadTradingData}
							/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			<RefPriceButton />
		</>
	);
};

export const TradingDataActions: React.FunctionComponent = () => (
	<Grid  container  spacing={4}>
		<Grid item>
			<TradingDateInput />
		</Grid>
		<Grid item>
 		    <DownloadButton />
    	</Grid>
    	<Grid item>
      		<DownloadExcelButton />
    	</Grid>
		<Grid item>
			<TradingDataFilterButton />
		</Grid>
	</Grid>
);

export default TradingDataPage;
