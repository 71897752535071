export function makeAutoIncrement(startPosition = 0) {
	let val = startPosition;
	return {
		get value() {
			return val;
		},
		increment() {
			return ++val;
		},
	};
}