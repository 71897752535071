import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Warehouse } from "../types";
import { loadWhs } from "../thunks/loadWhs";

export const useDisplayWhs = (whsId?: string) => {
  const storeWhs = useSelector<StoreType, Warehouse | undefined>(
    (store) => store.data.listing.warehouses.displayedWarehouse
  );

  const displayedWhs =
    storeWhs && storeWhs.whsId === whsId ? storeWhs : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (whsId) {
      displatch(loadWhs(whsId));
    }
  }, [displatch, whsId]);

  return displayedWhs;
};
