import { useDispatch, useSelector } from "react-redux";
import { downloadPaymentsReport } from "../thunks/downloadPaymentsReport";
import React from "react";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";

export const usePaymentsReportDownloader = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.reports.exchbusiness.clientOverview.fetchState
	);
	const dispatch = useDispatch();
	const download = React.useCallback(() => dispatch(downloadPaymentsReport()), [dispatch]);

	return [download, fetchState] as [typeof download, typeof fetchState]
};
