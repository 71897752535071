import React from "react";
import { PaymentDedication } from "../types";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { exhaustiveCheck } from "../../../../core/util/functions";

export const usePaymentDedicationOptionsTranslator = () => {
  const { getString } = React.useContext(LocalizationContext);
  return React.useCallback(
    (side: PaymentDedication) => {
      switch (side) {
        case PaymentDedication.yes:
          return getString("paymentSideDedicated");
        case PaymentDedication.no:
          return getString("paymentSideNonDedicated");
        default:
          exhaustiveCheck(side);
      }
    },
    [getString]
  );
};

interface AccountPaymentSideOption {
  label: string;
  value: PaymentDedication;
}

export const usePaymentDedicationOptions = (): AccountPaymentSideOption[] => {
  const translator = usePaymentDedicationOptionsTranslator();
  return React.useMemo(
    () => [
      {
        label: translator(PaymentDedication.yes) as string,
        value: PaymentDedication.yes,
      },
      {
        label: translator(PaymentDedication.no) as string,
        value: PaymentDedication.no,
      },
    ],
    [translator]
  );
};
