import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Attribute } from "../types";

export const updateAttributeApi = (userId: string, product: Attribute) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/updattr",
		params: {
			userId
		},
		data: product
	});

export default updateAttributeApi;
