import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MenuItem } from "@material-ui/core";

export const useGenderValues = () => {
	const { getString } = React.useContext(LocalizationContext);
	return React.useMemo(() => {
		return [
			{
				label: getString("genderMale"),
				value: 1,
			},
			{
				label: getString("genderFemale"),
				value: 2,
			},
		];
	}, [getString]);
};

export const GenderOptions: React.FunctionComponent = props => {
	const genderValues = useGenderValues();
	return (
		<>
			{genderValues.map(option => (
				<MenuItem key={option.value} value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</>
	);
};
