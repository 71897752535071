import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Region } from "../types";

export const createRegionApi = (userId: string, region: Region) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "listing/newreg",
    params: {
      userId,
    },
    data: region,
  });

export default createRegionApi;
