import React from "react";
import { useWTReportClientColumns } from "./useWTReportClientColumns";
import { AccessRights } from "../../../core/navigation/types";
import { TradeList } from "../../../data/trading/trades/types";
import { Table, TableDataProps } from "../../components/common/Table";
import { WeeklyTradingReportData } from "../../../data/reports/weeklyTrade/types";
import { useReportClientData } from "../../../data/reports/weeklyTrade/hooks/useReportClientData";
import { useWeeklyActions } from "./useWeeklyActions";

export interface WeeklyTradesTableProps {
  userAccessRights: AccessRights;
}

type WeeklyTradesTablePropsType = WeeklyTradesTableProps &
  TableDataProps<TradeList>;

const WTRClientTable: React.FunctionComponent<WeeklyTradesTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useWTReportClientColumns();
  const [data, load, reset] = useReportClientData();
  React.useEffect(() => reset, [reset]);

  return (
    <Table<WeeklyTradingReportData>
      {...tableProps}
      getData={load}
      immutableData={data}
      title=""
      columns={columns}
      //      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default WTRClientTable;
