import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { CtrlGoodsListData } from "../../../data/clearing/ctrlgoods/types";
import { CtrlGoodsData } from "../../../data/clearing/ctrlgoods/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewCtrlGood } from "./DisplayCtrlGood";
import { useHistory } from "react-router-dom";
import { getCtrlGoodsEditLocation } from "../ctrlgoods/Locations";
import { DisplayCtrlGoodMainAttributes } from "../ctrlgoods/DisplayCtrlGoodMainAttributes";
import { useCtrlGoodDelete } from "../../../data/clearing/ctrlgoods/hooks/useCtrlGoodDelete";

export function useCtrlGoodsActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowCtrlGood = useViewCtrlGood();
  const onEditCtrlGood = useEditCtrlGoodsAction();

  return useMemo(() => {
    const result: MaterialTableProps<CtrlGoodsListData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) =>
          onShowCtrlGood(rowData as CtrlGoodsListData),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) =>
          onEditCtrlGood(rowData as CtrlGoodsListData),
      },
    ];
    return result;
  }, [onShowCtrlGood, onEditCtrlGood, getString]);
}

export const useDeleteCtrlGoodsAction = () => {
  const [, deleteCtrlGood] = useCtrlGoodDelete();
  return React.useCallback(
    async (ctrlgoods: CtrlGoodsListData) => {
      if (ctrlgoods.controlId) {
        await deleteCtrlGood(ctrlgoods.controlId);
      }
    },
    [deleteCtrlGood]
  );
};

export const useEditCtrlGoodsAction = () => {
  const history = useHistory();
  return React.useCallback(
    (ctrlgoods: CtrlGoodsListData) =>
      history.push(getCtrlGoodsEditLocation(ctrlgoods.controlId)),
    [history]
  );
};
