import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { resetInvoiceIdices } from "../invoicesUXSlice";
import { PageIndex } from "../../../models";
import { setInvoicesIndexThunk } from "../thunks/loadAllInvoices";

export const useInvoiceIndex = () => {
  const pageIndex = useSelector<StoreType, PageIndex>(
    (s) => s.ux.accounting.invoices.index
  );

  const dispatch = useDispatch();
  const dispatchSetIndex = React.useCallback(
    (index: PageIndex) => dispatch(setInvoicesIndexThunk(index)),
    [dispatch]
  );
  const dispatchResetIndex = React.useCallback(
    () => dispatch(resetInvoiceIdices()),
    [dispatch]
  );

  return [pageIndex, dispatchSetIndex, dispatchResetIndex] as [
    typeof pageIndex,
    typeof dispatchSetIndex,
    typeof dispatchResetIndex
  ];
};
