import { CtrlGoodsListData } from "../../../data/clearing/ctrlgoods/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";

export const useCtrlGoodsColumns = () => {
  const { getString, getDateString } = React.useContext(LocalizationContext);

  return React.useMemo(
    () => [
      {
        title: getString("ctrlcontrolDate"),
        render: (pctrlGoods) =>
          getDateString(pctrlGoods.controlDate, "DD.MM.YYYY"),
        sorting: false,
      },
      {
        title: getString("ctrltradeNo"),
        field: "tradeNo",
        sorting: false,
      },
      {
        title: getString("ctrlsellerName"),
        field: "sellerName",
        sorting: false,
      },
      {
        title: getString("ctrlbuyerName"),
        field: "buyerName",
        sorting: false,
      },
      {
        title: getString("ctrlproductName"),
        field: "productName",
        sorting: false,
      },
      {
        title: getString("ctrlcheckPointDescr"),
        field: "checkPointDescr",
        sorting: false,
      },
      {
        title: getString("ctrlstatus"),
        field: "statusDescr",
        sorting: false,
      },
    ],
    [getString, getDateString]
  ) as Column<CtrlGoodsListData>[];
};
