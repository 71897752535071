import { BankGuaranteeData } from "../../../data/clearing/bankguarantees/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";

export const useBankGuaranteeColumns = () => {
  const {
    getString,
    getDateString,
    getFormatedNumberString,
  } = React.useContext(LocalizationContext);


  return React.useMemo(
    () => [
      {
        title: getString("grntNo"),
        field: "grntNo",
        sorting: false,
      },
      {
        title: getString("grntDate"),
        render: (bankguarantee) => getDateString(bankguarantee.grntDate, "DD.MM.YYYY"),
        sorting: false,
      },
      {
        title: getString("grntClientCode"),
        field: "clientIdNo",
        sorting: false,
      },
      {
        title: getString("grntClientName"),
        field: "clientName",
        sorting: false,
      },
      {
        title: getString("grntBankCode"),
        field: "bankCode",
        sorting: false,
      },
      {
        title: getString("grntBankName"),
        field: "bankName",
        sorting: false,
      },
      {
        title: getString("grntExpiryDate"),
        render: (bankguarantee) => getDateString(bankguarantee.expiryDate, "DD.MM.YYYY"),
        sorting: false,
      },
      {
        title: getString("grntAmount"),
        render: (bankguarantee) => getFormatedNumberString(bankguarantee.amt, true),
        sorting: false,
				width: "140px",
				cellStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
					textAlign: "end",
					paddingRight: "10px",
				},
      },
      {
        title: getString("grntCurrCode"),
        field: "currCode",
        sorting: false,
      },
    ],
    [getString, getFormatedNumberString, getDateString]
  ) as Column<BankGuaranteeData>[];
};
