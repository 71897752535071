import React from "react";
import { useEditBankState } from "../../../data/listing/banks/hooks/useEditBankState";
import { useBankValidator } from "../../../data/listing/banks/hooks/useBankValidator";
import { useBankUpsert } from "../../../data/listing/banks/hooks/useBankUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { Bank } from "../../../data/listing/banks/types";
import { StringInput } from "../../components/common/StringInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { CitySelect } from "../../components/common/CitySelect";
import { City, Status, Commissions } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { CommissionSelect } from "../../components/common/CommissionSelect";
import { banksLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";

export interface EditBankInfo {
	bankId?: string;
}

export interface EditBankProps extends RouteComponentProps<EditBankInfo> {}

export const EditBankPage: React.FunctionComponent<EditBankProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [bank, editBank, changed] = useEditBankState(props.match.params.bankId);
	const [validationState, validate] = useBankValidator();
	const [fetchState, upsert] = useBankUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editBank(event.target.name as any, event.target.value);
		},
		[editBank]
	);

	const changeCity = React.useCallback(
		(city: City | null) => {
			editBank("cityId", city ? city.cityId : "");
		},
		[editBank]
	);

	const changeCommission = React.useCallback(
		(commission: Commissions | null) => {
			editBank("commid", commission ? commission.commId : "");
		},
		[editBank]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(banksLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(bank);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [bank, validate, fetchState, history, upsert]);

	const pageTitle = bank && bank.bankId ? "bankEdit" : "bankCreate";

	if (!bank) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={banksLocation}/>} />
					<Divider />
					<CardContent>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={2}>
								<StringInput<Bank>
									property="bankCode"
									item={bank}
									validationState={validationState}
									onChange={handleChange}
									label={getString("bankCode")}
								/>
							</Grid>
							<Grid item xs={4}>
								<StringInput<Bank>
									property="bankName"
									item={bank}
									validationState={validationState}
									onChange={handleChange}
									label={getString("bankName")}
								/>
							</Grid>
							<Grid item xs={2}>
								<StringInput<Bank>
									property="idNumber"
									item={bank}
									validationState={validationState}
									onChange={handleChange}
									label={getString("bankIdNumber")}
								/>
							</Grid>
							<Grid item xs={2}>
								<StringInput<Bank>
									property="regNumber"
									item={bank}
									validationState={validationState}
									onChange={handleChange}
									label={getString("bankRegNumber")}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={3}>
								<CitySelect
									label={getString("cliCity")}
									predicate={city => city.cityId === bank.cityId}
									onChangeItem={changeCity}
								/>
							</Grid>

							<Grid item xs={3}>
								<StringInput<Bank>
									property="address"
									item={bank}
									validationState={validationState}
									onChange={handleChange}
									label={getString("cliAddress")}
								/>
							</Grid>
							<Grid item xs={2}>
								<StringInput<Bank>
									property="email"
									item={bank}
									validationState={validationState}
									onChange={handleChange}
									label={getString("cliEmail")}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={2}>
								<StringInput<Bank>
									property="account"
									item={bank}
									validationState={validationState}
									onChange={handleChange}
									label={getString("accountNumber")}
								/>
							</Grid>
							<Grid item xs={3}>
								<CommissionSelect
									label={getString("commission")}
									predicate={commission => commission.commId === bank.commid}
									onChangeItem={changeCommission}
								/>
							</Grid>
							<Grid item xs={1}>
								<BoolInput<Bank>
									property="status"
									item={bank}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("cliStatus")}
								/>
							</Grid>
						</Grid>

						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
