import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteAttribute } from "../thunks/deleteAttribute";
import { setDeleteFetchState } from "../attributeDataReducer";

export const useAttributeDelete = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.attributes.deleteFetchState
	);

	const dispatch = useDispatch();
	const dispatchDelete = React.useCallback((id: string) => dispatch(deleteAttribute(id)), [dispatch]);

	React.useEffect(() => {
		dispatch(setDeleteFetchState({ type: "not-started" }));
	}, [dispatch]);

	const resetState = React.useCallback(() => dispatch(setDeleteFetchState({ type: "not-started" })), [
		dispatch,
	]);

	return [fetchState, dispatchDelete, resetState] as [
		typeof fetchState,
		typeof dispatchDelete,
		typeof resetState
	];
};
