import { webApi } from "../../../../core/http/httpThunks";
import { Employee } from "../types";

export const getEmployee = (userId: string, empid: string) =>
	webApi<Employee>({
		method: "GET",
		url: "listing/getemployee",
		params: {
			userId,
			empid
		},
	});

export default getEmployee;
