import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";
import { deleteAttributeValue } from "../../thunks/attributeValues/deleteAttributeValue";
import { setFetchStateValue } from "../../attributeDataReducer";

export const useAttributeValueDelete = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.attributes.fetchStateAttribute
	);

	const dispatch = useDispatch();
	const dispatchDelete = React.useCallback(
		(id: string) => dispatch(deleteAttributeValue(id)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateValue({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchDelete] as [typeof fetchState, typeof dispatchDelete]
};
