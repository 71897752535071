import { combineReducers, } from "@reduxjs/toolkit";
import mainBoard from "./mainBoard/ordersUXSlice";
import trades from "./trades/tradesUXSlice";
import orderBook from "./orderBook/orderBookUXSlice";
import ticker from "./ticker/tickerUXSlice";

const uxTradingReducer = combineReducers({
  mainBoard,
  trades,
  orderBook,
  ticker,
});

export default uxTradingReducer