import React from "react";
import { useQualityCtrlList } from "../../../data/initialData/hooks/useQualityCtrlList";
import { QualityCtrlList } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType } from "./AutocompleteSelect";

type QualityCtrlListSelectPropsType = Omit<AutocompleteSelectType<QualityCtrlList>, "data" | "getLabel">;

export const QualityCtrlListSelect: React.FunctionComponent<QualityCtrlListSelectPropsType> = props => {
	const [groups, renderGroupLabel] = useQualityCtrlList();

	return (
		<AutocompleteSelect<QualityCtrlList>
			{...(props as AutocompleteSelectType<QualityCtrlList>)}
			data={groups}
			getLabel={renderGroupLabel}
		/>
	);
};
