import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useGenderValues } from "../../components/common/GenderOptions";
import { TextField, MenuItem } from "@material-ui/core";
import { Gender } from "../../../data/models";

interface EmployeeGenderSelectProps {
	gender: Gender;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	error: boolean;
}

export const EmployeeGenderSelect: React.FunctionComponent<EmployeeGenderSelectProps> = ({
	gender,
	handleChange,
	error,
}) => {
	const { getString } = React.useContext(LocalizationContext);
    const genderValues = useGenderValues();

	return (
		<TextField
			name={"gender"}
			value={gender}
			onChange={handleChange}
			fullWidth
			select
			label={getString("empGender")}
			required
			error={error}
		>
			{genderValues.map(option => (
				<MenuItem key={option.value} value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</TextField>
	);
};
