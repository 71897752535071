import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import { MarginsDataFilter } from "./types";

type MarginsDataPageState = {
  index: PageIndex;
  filter: MarginsDataFilter;
};

export const initialState: MarginsDataPageState = {
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
  filter: {
    datefrom: new Date().toISOString(),
    dateto: new Date().toISOString(),
    reportDate: new Date().toISOString(),
  },
};

const name = "marginsData";

const marginsDataSlice = createSlice({
  name,
  initialState,
  reducers: {
    setMarginsDateIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    resetMarginsDateIdices: (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }),
    setFilter: (state, action: PayloadAction<MarginsDataFilter>) => ({
      ...state,
      filter: action.payload,
    }),
    removeFilter: (state, action: PayloadAction<keyof MarginsDataFilter>) => ({
      ...state,
      filter: {
        ...state.filter,
        [action.payload]: undefined,
      },
    }),
    setReportData: (state, action: PayloadAction<string>) => ({
      ...state,
      filter: {...state.filter,
        reportDate: action.payload,
        datefrom: action.payload,
        dateto: action.payload,
          },
    }),

  },
  extraReducers: (builder) => {
    builder.addCase(successfulLogin, (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }));
  },
});

export const {
  setMarginsDateIndex,
  resetMarginsDateIdices,
  setFilter,
  removeFilter,
  setReportData,
} = marginsDataSlice.actions;

export default marginsDataSlice.reducer;
