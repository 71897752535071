import { combineReducers, AnyAction } from "@reduxjs/toolkit";
import payments from "./payments/paymentsDataSlice";
import controls from "./ctrlgoods/ctrlGoodsDataSlice";
import deliveries from "./delivery/deliveryDataSlice";
import disputes from "./dispute/disputeDataSlice";
import guarantees from "./guarantees/guaranteesDataSlice";
import stockfees from "./stockfees/stockFeesDataReducer";
import transportfees from "./transportfees/transportFeesDataReducer";
import transportAndStorage from "./transportAndStorage/TASDataSlice";
import tradingData from "./tradingData/tradingDataSlice";
import marginsData from "./marginsData/marginsDataSlice";
import { resetLoginState } from "../auth/authDataSlice";
import bankguarantees from "./bankguarantees/bankguaranteesDataSlice";

const clearingDataReducer = combineReducers({
	payments,
	controls,
	deliveries,
	disputes,
	guarantees,
	stockfees,
	transportfees,
	transportAndStorage,
	tradingData,
	marginsData,
	bankguarantees,
});

export default (state: any, action: AnyAction) => {
	if (action.type === resetLoginState.type) {
		return clearingDataReducer(undefined, action);
	} else {
		return clearingDataReducer(state, action);
	}
};
