import { ThunkType } from "../../../../../core/store/reducers";
import deleteClientBrokerApi from "../../api/clientBroker/deleteClientBroker";
import { setFetchStateBroker } from "../../clientsDataReducer";

export const deleteClientBroker = (brokerId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchStateBroker({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteClientBrokerApi(userId, brokerId));

	dispatch(setFetchStateBroker(result !== undefined ? { type: "successful" } : { type: "error"}));
};
