import { Attribute } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";

interface IProductData {
	all?: Attribute[];
	searchTerm: string;
	displayData?: Attribute[];
	displayed?: Attribute;
	fetchState: FetchStateType;
	fetchStateAttribute: FetchStateType;
	deleteFetchState: FetchStateType;
}

const initialState: IProductData = {
	searchTerm: "",
	fetchState: { type: "not-started" },
	fetchStateAttribute: { type: "not-started" },
	deleteFetchState: { type: "not-started" },
};

const name = "attributes";

const roleSlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<Attribute[] | undefined>) => ({
			...state,
			all: action.payload,
			displayData: filterData(action.payload, state.searchTerm),
		}),
		setSearchTerm: (state, action: PayloadAction<string>) => ({
			...state,
			searchTerm: action.payload.toLocaleLowerCase(),
			displayData: filterData(state.all, action.payload),
		}),
		setDisplayed: (state, action: PayloadAction<Attribute | undefined>) => ({
			...state,
			displayed: action.payload,
		}),
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayed: action.payload.type === "successful" ? undefined : state.displayed,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			fetchState: getFetchStateChange(state.fetchState, action.payload),
		}),
		setFetchStateValue: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			fetchStateAttribute: getFetchStateChange(state.fetchStateAttribute, action.payload),
		}),
		setDeleteFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayed: action.payload.type === "successful" ? undefined : state.displayed,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			deleteFetchState: getFetchStateChange(state.deleteFetchState, action.payload),
		}),
	},
});

const filterData = (data?: Attribute[], searchTerm?: string) =>
	searchTerm === undefined || searchTerm === ""
		? data
		: data?.filter(
				e =>
					(e.attrName &&
						e.attrName
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
					(e.attrType &&
						e.attrType
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
					(e.comparator &&
						e.comparator
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase()))
		  );

export const {
	setData,
	setSearchTerm,
	setDisplayed,
	setFetchState,
	setFetchStateValue,
	setDeleteFetchState,
} = roleSlice.actions;

export default roleSlice.reducer;
