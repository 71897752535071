import React from "react";
import { useAnnualTradesColumns } from "./useAnnualTradingReportColumns";
import { AccessRights } from "../../../core/navigation/types";
import { ControledTable } from "../../components/common/ControledTable";
import { AnnualTradingReportData } from "../../../data/reports/annualTrade/types";
import { useTradesReportData } from "../../../data/reports/annualTrade/hooks/useTradesReportData";
import { useAnnualTradesReportIndex } from "../../../data/reports/annualTrade/hooks/useAnnualTradesReportIndex";
import { useAnnualActions } from "./useAnnualActions";

export interface AnnualTradesTableProps {
  userAccessRights: AccessRights;
}

type AnnualTradesTablePropsType = AnnualTradesTableProps

const AnnualTradesReportTable: React.FunctionComponent<AnnualTradesTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const actions = useAnnualActions();
  const columns = useAnnualTradesColumns();

  const [data, load] = useTradesReportData();
  const [index, setIndex] = useAnnualTradesReportIndex();

  return (
    <ControledTable<AnnualTradingReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default AnnualTradesReportTable;
