import { webApi } from "../../../../../core/http/httpThunks";
import { AttributeValue } from "../../types";

export const getAllAttributesValuesApi = (userId: string, attrid: string) =>
	webApi<AttributeValue[]>({
		method: "GET",
		url: "listing/getattrvallist",
		params: {
			userId,
			attrid
		},
	});

export default getAllAttributesValuesApi;
