import { ViewOrder } from "../../../data/trading/orders/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { renderOrderPrice, TurnocateText, renderClient } from "../../trading/orders/columRenderers";
import { useOrderMarketOptionsTranslator } from "../../../data/trading/orders/hooks/useOrderMarketOptions";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const useOrderReportsColumns = () => {
	const { getString, getFormatedNumberString } = React.useContext(LocalizationContext);
	const marketTranslator = useOrderMarketOptionsTranslator();

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	return React.useMemo(
		() => [
			{
				title: getString("orderClmOrdNo"),
				field: "ordNo",
				sorting: false,
				width: "100px",
				cellStyle: (data, order) => ({
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
				}),
				headerStyle: {
					maxWidth: "100px",
					minWidth: "100px",
					width: "100px",
				},
				hidden: screenExtraSmall,
			},
			{
				title: screenExtraSmall ?  getString("newsTime") :getString("orderTimeReceived")  ,
				render: order => screenExtraSmall ? order.timeReceived.substring(0,10): order.timeReceived,
				sorting: false,
				width: screenExtraSmall ? "100px" :"180px",
				cellStyle: {
					maxWidth: screenExtraSmall ? "100px" :"180px",
					minWidth: screenExtraSmall ? "100px" :"180px",
					width: screenExtraSmall ? "100px" :"180px",
				},
				headerStyle: {
					maxWidth: screenExtraSmall ? "100px" :"180px",
					minWidth: screenExtraSmall ? "100px" :"180px",
					width: screenExtraSmall ? "100px" :"180px",
				},
				// hidden: screenExtraSmall,
			},
			{
				title: getString("orderClientName"),
				render: renderClient,
				sorting: false,
				width: "200px",
				cellStyle: {
					maxWidth: "200px",
					minWidth: "200px",
					width: "200px",
					whiteSpace: "nowrap",
				},
				headerStyle: {
					maxWidth: "200px",
					minWidth: "200px",
					width: "200px",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("orderProduct"),
				field: "product",
				sorting: false,
			},
			{
				title: getString("orderSide"),
				field: "side",
				sorting: false,
		        width: "80px",
        		cellStyle: {
	    	    maxWidth: "80px",
    	        minWidth: "80px",
		        width: "80px",
        		},
        		headerStyle: {
          			maxWidth: "80px",
          			minWidth: "80px",
			        width: "80px",
		        },
			},
			{
				title: getString("orderQty"),
				render: order => `${getFormatedNumberString(order.qty)} ${order.unit}`,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
			},
			{
				title: getString("orderPrice"),
				render: renderOrderPrice,
				sorting: false,
		        width: "100px",
	        		cellStyle: {
					textAlign: "end",
					maxWidth: "100px",
    		        minWidth: "100px",
		    	    width: "100px",
        		},
        		headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "left",
          			maxWidth: "100px",
          			minWidth: "100px",
			        width: "100px",
		        },
			},
			{
				title: getString("orderStatus"),
				render: order => <TurnocateText text={order.status} maxChars={18} />,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
				},
				hidden: screenExtraSmall,
			},
		],
		[getString,  getFormatedNumberString,screenExtraSmall]
	) as Column<ViewOrder>[];
};
