import { webApi } from "../../../../core/http/httpThunks";
import { TransportFee } from "../types";

export const getTransportFeeApi = (userId: string, id: number) =>
  webApi<TransportFee>({
    method: "GET",
    url: "clearing/gettransfee",
    params: {
      userId,
      id,
    },
  });

export default getTransportFeeApi;
