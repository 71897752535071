import React from "react";
import { PaymentSide } from "../types";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { exhaustiveCheck } from "../../../../core/util/functions";

export const usePaymentSideOptionsTranslator = () => {
  const { getString } = React.useContext(LocalizationContext);
  return React.useCallback(
    (side: PaymentSide) => {
      switch (side) {
        case PaymentSide.in:
          return getString("paymentSideIn");
        case PaymentSide.out:
          return getString("paymentSideOut");
        default:
          exhaustiveCheck(side);
      }
    },
    [getString]
  );
};

interface AccountPaymentSideOption {
  label: string;
  value: PaymentSide;
}

export const usePaymentSideOptions = (): AccountPaymentSideOption[] => {
  const translator = usePaymentSideOptionsTranslator();
  return React.useMemo(
    () => [
      {
        label: translator(PaymentSide.in) as string,
        value: PaymentSide.in,
      },
      {
        label: translator(PaymentSide.out) as string,
        value: PaymentSide.out,
      },
    ],
    [translator]
  );
};
