import React from "react";
import { useDisplayBankGuarantee } from "./useDisplayBankGuarantees";
import { BankGuaranteeData } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defaultGuarantee: BankGuaranteeData = {
  grntId: "",
  clientId: "",
  bankId: "",
  status: Status.Active,
  currId: "00000001",
};

export const useEditGuarantee = (payId?: string): BankGuaranteeData | undefined => {
  const displayGuarantee = useDisplayBankGuarantee(payId);

  return React.useMemo(() => {
    if (payId !== undefined && displayGuarantee === undefined) {
      return undefined;
    }

    if (payId === undefined) {
      return { ...defaultGuarantee };
    }

    return displayGuarantee ? { ...displayGuarantee } : undefined;
  }, [payId, displayGuarantee]);
};

export const useEditBankGuaranteeState = (payId?: string) => {
  const guarantee = useEditGuarantee(payId);
  return useCopiedState(guarantee);
};
