import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import {
  useInvoicesFilter,
  useCurrentInvoicesFilter,
} from "../../../data/accounting/invoices/hooks/useInvoicesFilter";
import {
  InvoiceFilterEditor,
  InvoiceFilterEditorType,
  InvoiceFilterChips,
} from "./InvoiceFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const InvoiceFilterButton: React.FunctionComponent = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const showFilter = React.useCallback(
    () => showWithDrawer(<InvoicesFilterEditor />),
    [showWithDrawer]
  );

  const [filter, removeFilter] = useCurrentInvoicesFilter();
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <InvoiceFilterChips filter={filter} removeFilter={removeFilter} />
      </Grid>
      <Grid item>
        <CustomTooltip titleKeyword="filterTooltip">
          <IconButton color="secondary" component="span" onClick={showFilter}>
            <FilterIcon />
          </IconButton>
        </CustomTooltip>
      </Grid>
    </Grid>
  );
};

const InvoicesFilterEditor: React.FunctionComponent = () => {
  const [filter, editFilter, save, , rest] = useInvoicesFilter();
  return (
    <InvoiceFilterEditor
      filter={filter}
      editFilter={editFilter}
      save={save}
      type={InvoiceFilterEditorType.Extended}
      reset={rest}
    />
  );
};
