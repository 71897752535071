import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { loadReport } from "../thunks/loadReportData";
import { DailySecurityReportData } from "../types";

export const useSecurityReportData = () => {
  const data = useSelector<StoreType, DailySecurityReportData[] | undefined>(
    (s) => s.data.reports.securityCommission.dailySecurity.data
  );

  const dispatch = useDispatch();
  const dispatchLoadData = React.useCallback(
    () => dispatch(loadReport()),
    [dispatch]
  );

  return [data, dispatchLoadData] as [
    typeof data,
    typeof dispatchLoadData,
  ];
};
