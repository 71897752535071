import { webApi } from "../../../../core/http/httpThunks";

export const deleteWhsApi = (userId: string, whsid: string) =>
  webApi<string>({
    method: "DELETE",
    url: "listing/delwhs",
    params: {
      userId,
      whsid,
    },
  });

export default deleteWhsApi;
