import { ThunkType } from "../../../../core/store/reducers";
import { getSimpleTrades } from "../api/getSimpleTrades";
import { TransSimpleData, SimpleTransFilter } from "../types";

export const getSimpleTradesThunk = (
	filter: SimpleTransFilter
): ThunkType<Promise<TransSimpleData[] | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const user = store.data.auth.current.user;
	const ordersResponse = await dispatch(getSimpleTrades(user.userId, filter));

	if (!ordersResponse || ordersResponse.resCode !== "OK") {
		return undefined;
	}

	return ordersResponse.obj;
};
