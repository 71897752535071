import { MemberContractsReportData } from "../../../../data/reports/exchbusiness/memberContracts/types";
import React from "react";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Column } from "material-table";

export const useMemberContractsColumns = () => {
  const { getString, getFormatedNumberString } = React.useContext(
    LocalizationContext
  );

  return React.useMemo(
    () => [
      {
        title: getString("memcontMember"),
        field: "member",
        sorting: false,
      },
      {
        title: getString("memcontContractDate"),
        field: "contractDate",
        sorting: false,
      },
      {
        title: getString("memcontExpireDate"),
        field: "expireDate",
        sorting: false,
      },
      {
        title: getString("memcontStatus"),
        field: "status",
        sorting: false,
      },
    ],
    [getString, getFormatedNumberString]
  ) as Column<MemberContractsReportData>[];
};
