import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { Bank } from "../../models";

const getBankLabel = (bank?: Bank) => (bank ? bank.bankName : "");

export const useBanks = () => {
	const banks = useSelector<StoreType, Bank[]>(store => store.data.initialData.banks);

	return [banks, getBankLabel] as [typeof banks, typeof getBankLabel];
};

export const useBank = (bankId?: string) => {
	const [banks] = useBanks();

	const bank = React.useMemo(() => banks.find(c => c.bankId === bankId), [banks, bankId]);
	const bankLabel = React.useMemo(() => getBankLabel(bank), [bank]);

	return [bank, bankLabel] as [typeof bank, typeof bankLabel];
};
