import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadDeliveries } from "../thunks/loadDeliveries";
import { DeliveryListData } from "../types";
import { PageData } from "../../../models";

export const useDeliveryData = () => {
  const pageData = useSelector<
    StoreType,
    PageData<DeliveryListData> | undefined
  >((s) => s.data.clearing.deliveries.currentPage);

  const dispatch = useDispatch();
  const dispatchLoadDeliveryPage = React.useCallback(
    () => dispatch(loadDeliveries()),
    [dispatch]
  );

  return [pageData, dispatchLoadDeliveryPage] as [
    typeof pageData,
    typeof dispatchLoadDeliveryPage
  ];
};
