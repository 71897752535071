import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent, Tabs, Tab } from "@material-ui/core";
import { EditAttributeBasicInfoPage } from "./EditAttributeBasicInfoPage";
import { AttributeValuesTable } from "./attributeValues/AttributeValuesTable";
import { useEditAttributeState } from "../../../data/listing/attributes/hooks/useEditAttributeState";
import { AttributeType } from "../../../data/listing/attributes/types";
import { PageTitle } from "../../components/common/PageTitle";
import { attributesLocation, AttributeEditParts, getAttributeEditLocation } from "./Locations";
import { useAttributeUpsert } from "../../../data/listing/attributes/hooks/useAttributeUpsert";

export interface EditAttributeInfo {
	editPart?: string;
	attributeId?: string;
}

export interface EditAttributePageProps extends RouteComponentProps<EditAttributeInfo> { }

export const EditAttributePage: React.FunctionComponent<EditAttributePageProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();
	const { editPart, attributeId } = props.match.params;

	const history = useHistory();
	const [fetchState] = useAttributeUpsert();
	const [attribute, editAttribute, changed] = useEditAttributeState(attributeId);

	const handleSideChange = React.useCallback(
		(event: React.ChangeEvent<{}>, newValue: any) => {
			history.push(getAttributeEditLocation(newValue, attributeId));
		},
		[history, attributeId]
	);

	const hasSelectValues =
		(!changed || fetchState.type === "successful") &&
		attribute &&
		attribute.attrType === AttributeType.Select;

	const pageTitle = attributeId ? "attributesEditTitle" : "attributesCreateTitle";
	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={attributesLocation} />} />
					<Divider />
					<CardContent>
						<Tabs
							value={editPart}
							onChange={handleSideChange}
							indicatorColor="secondary"
							textColor="secondary"
							variant="fullWidth"
						>
							<Tab label={getString("tableBasciInfo")} value={AttributeEditParts.BasicInfo} />
							<Tab
								label={getString("attributeValuesTitle")}
								value={AttributeEditParts.Values}
								disabled={!attribute || !attribute.attrId || !hasSelectValues}
							/>
						</Tabs>
						<div style={{ marginTop: "20px" }}>
							{editPart === AttributeEditParts.BasicInfo && attribute && (
								<EditAttributeBasicInfoPage
									attribute={attribute}
									editAttribute={editAttribute}
									changed={changed}
								/>
							)}
							{editPart === AttributeEditParts.Values && attribute && attribute.attrId && (
								<AttributeValuesTable attributeId={attribute.attrId} />
							)}
						</div>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
