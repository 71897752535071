import React from "react";
import { AttributeType, AttributeManditory } from "../../../../data/listing/attributes/types";
import { useAttribute } from "../../../../data/listing/attributes/hooks/useAttribute";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { AttributeValueSelect } from "../../../components/listing/ProductAttributeValueSelect";
import { NumberInput } from "../../../components/common/NumberInput";
import { OrdersEditAction } from "../../../../data/trading/orders/types";
//import { useOrderPSTM } from "../../../../data/trading/orders/hooks/useOrderPSTM";

export interface EditAttributeValueProps {
	attrId?: string;
	attrValue?: number;
	attrType?: AttributeType;
	attrValId?: string;
	manditory?: AttributeManditory;
	comparator?: string;
	attrName?: string;
	valueChange: (value?: any) => void;
	valueIdChange: (id?: string) => void;
	error?: boolean;
	action: OrdersEditAction;
}

export const EditAttributeValue: React.FunctionComponent<EditAttributeValueProps> = ({
	attrId,
	attrValue,
	attrType,
	attrValId,
	manditory,
	valueChange,
	valueIdChange,
	attrName,
	comparator,
	error,
	action
}) => {
	const { getString } = React.useContext(LocalizationContext);

	const [selectedAttribute] = useAttribute(
		attrType === undefined || manditory === undefined || comparator === undefined ? attrId : undefined
	);

//	const manditoryFinal = (manditory || selectedAttribute?.mandatory) === AttributeManditory.True;

	const manditoryFinal = manditory ===1 ? true : false;

//	const [setPSTM, pstm] = useOrderPSTM();

	const label = `${attrName}${getComaprator(comparator || selectedAttribute?.comparator)}`;
	return (
		<>
			{(attrType || selectedAttribute?.attrType)?.trim() === AttributeType.Text.trim() && (
				<NumberInput<{value: any} >
					item={{value: attrValue}}
					property="value"
					onChange={(e: any) => {
						valueChange(e.target.value);
					}}
					fullWidth
					label={label}
					required={manditoryFinal}
					error={manditoryFinal && error && attrValue === undefined}
					helperText={manditoryFinal && error && attrValue === undefined && getString("requiredMessage")}
					decimalCount={1}
					disabled={ action === OrdersEditAction.Verify }
//					disabled={ action === OrdersEditAction.Verify || pstm.type === "not-started" }
					/>
			)}
			{(attrType || selectedAttribute?.attrType)?.trim() === AttributeType.Select.trim() && attrId && (
				<AttributeValueSelect
					attributeId={attrId}
					label={label}
					predicate={attrValue => attrValue.attrValueId === attrValId}
					onChangeItem={attrValue => {
						valueIdChange(attrValue ? attrValue.attrValueId : undefined);
					}}
					required={manditoryFinal}
					error={manditoryFinal && error && attrValId === undefined}
					helperText={manditoryFinal && error && attrValId === undefined && getString("requiredMessage")}
					disabled={ action === OrdersEditAction.Verify }
//					disabled={ action === OrdersEditAction.Verify || pstm.type === "not-started"  }
				/>
			)}
		</>
	);
};

const getComaprator = (comp?: string) => (comp ? ` (${comp})` : "");
