import React from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import { DateInput } from "../../components/common/DateInput";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MarginsDataFilter } from "../../../data/clearing/marginsData/types";
import { useAuth } from "../../../data/auth/hooks";
import { MarginsDataFilterChipsComponent } from "./MarginsDataFilterChips";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { isValidDate } from "../../../core/util/functions";
import { FiltredSimpleTradesSelect } from "../../components/trading/FiltredSimpleOrdersSelect";
import { MarketFilter } from "../../../data/trading/trades/types";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { FiltredTransactionsSelect } from "../../components/trading/FiltredTransactionsSelect";

export enum MarginsDataFilterEditorType {
	Basic,
	Extended,
}

interface MarginsDataFilterEditorProps {
	filter?: MarginsDataFilter;
	editFilter: (prop: keyof MarginsDataFilter, value: any) => void;
	save: () => void;
	type: MarginsDataFilterEditorType;
	reset?: () => void;
}

export const MarginsDataFilterEditor: React.FunctionComponent<MarginsDataFilterEditorProps> = ({
	filter,
	editFilter,
	save,
	type,
	reset,
}) => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const { getString } = React.useContext(LocalizationContext);
	const [auth] = useAuth();

	if (!filter) {
		return null;
	}
	return (
		<Paper
			style={{
				paddingLeft: "70px",
				paddingRight: "70px",
				paddingTop: "15px",
				paddingBottom: "15px",
			}}
		>
			<Grid container direction="column" spacing={6}>
				<Grid item container direction="row" spacing={2}>
					<Grid item xs={6}>
						<DateInput
							label={getString("marginsDatadatefrom")}
							value={filter?.datefrom || null}
							onChange={date =>
								editFilter("datefrom", isValidDate(date) ? date.toISOString() : undefined)
							}
						/>
					</Grid>
					<Grid item xs={6}>
						<DateInput
							label={getString("marginsDatadateto")}
							value={filter?.dateto || null}
							onChange={date =>
								editFilter("dateto", isValidDate(date) ? date.toISOString() : undefined)
							}
						/>
					</Grid>
				</Grid>

				<Grid item xs={12}>
	                <ClientSelect
    	              label={getString("orderClientName")}
        	          predicate={(client) => client.cliId === filter?.client?.cliId}
            	      onChangeItem={(client) =>
                	    editFilter("client", client)
                 	 	}
                	/>
      	        </Grid>

				<Grid item xs={12}>
					<FiltredSimpleTradesSelect
						label={getString("ctrltradeNo")}
						filter={{
							market: MarketFilter.Forward,
						}}  
						predicate={trade => trade.tradeId === filter.trade?.tradeId}
						onChangeItem={trade => {
							editFilter("trade", trade);
						}}
					/>
				</Grid>    

				<Grid item container direction="row-reverse" spacing={2}>
					<Grid item>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								save();
								showWithDrawer(undefined);
							}}
						>
							{getString("orderFilterConfirm")}
						</Button>
					</Grid>
					<Grid item>
						<Button variant="text" onClick={reset}>
							{getString("filterReset")}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export const MarginsDataFilterChips = MarginsDataFilterChipsComponent;
