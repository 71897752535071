import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { loadDeliveries } from "../thunks/loadDeliveries";
import { PageData } from "../../../../models";
import { DeliveryListData } from "../../../../clearing/delivery/types";

export const useDeliveryData = () => {
	const pageData = useSelector<StoreType, PageData<DeliveryListData> | undefined>(
		s => s.data.reports.exchbusiness.clientOverview.currentPageDelivery
	);

	const dispatch = useDispatch();
	const dispatchLoadDeliveryPage = React.useCallback(() => dispatch(loadDeliveries()), [dispatch]);

	return [pageData, dispatchLoadDeliveryPage] as [typeof pageData, typeof dispatchLoadDeliveryPage];
};
