import { getEditLocation } from "../../../core/util/functions";

export enum ClientEditParts {
	BasicInfo = "basicinfo",
	Persons = "persons",
	Brokers = "brokers",
	Contracts = "contracts",
	Accounts = "accounts",
}

export const clientsLocation = "/listing/members";

export const getClientsEditLocation = (
	part: ClientEditParts = ClientEditParts.BasicInfo,
	productId?: string,
	attributeId?: string
) => getEditLocation(clientsLocation, part, productId, attributeId);

export const basicInfoEdit = getClientsEditLocation(":editPart" as any, ":clientId?");
export const personsEdit = getClientsEditLocation(ClientEditParts.Persons, ":clientId", ":personId?");
export const brokersEdit = getClientsEditLocation(ClientEditParts.Brokers, ":clientId", ":brokerId?");
export const contractsEdit = getClientsEditLocation(ClientEditParts.Contracts, ":clientId", ":contactId?");
export const accountEdit = getClientsEditLocation(ClientEditParts.Accounts, ":clientId", ":accountId?");
