import React from "react";
import { useDisplayAttribute } from "../../../data/listing/attributes/hooks/useDisplayAttribute";
import { Attribute, AttributeType } from "../../../data/listing/attributes/types";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayAttributeBasicInfo } from "./DisplayAttributeBasicInfo";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useToggleStateDictionary } from "../../../core/util/hooks";
import { DisplayAttributeValues } from "./attributeValues/DisplayAttributeValues";

enum AccordionNames {
	BasicInfo = "basicInfo",
	Values = "values",
}

const accordionNames = Object.values(AccordionNames);

export interface DisplayAttributeProps {
	id?: string;
}

export const DisplayAttribute: React.FunctionComponent<DisplayAttributeProps> = ({ id }) => {
	const attribute = useDisplayAttribute(id);
	const { getString } = React.useContext(LocalizationContext);

	const [open, toggleOpen] = useToggleStateDictionary(accordionNames, [AccordionNames.BasicInfo]);

	return !attribute || !attribute.attrId ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel
				expanded={open[AccordionNames.BasicInfo]}
				onChange={toggleOpen[AccordionNames.BasicInfo]}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{getString("tableBasciInfo")}</Typography>
				</ExpansionPanelSummary>
				<DisplayAttributeBasicInfo attribute={attribute} />
			</ExpansionPanel>
			<ExpansionPanel
				expanded={open[AccordionNames.Values]}
				onChange={toggleOpen[AccordionNames.Values]}
				disabled={attribute.attrType !== AttributeType.Select}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{getString("attributeValuesTitle")}</Typography>
				</ExpansionPanelSummary>
				<DisplayAttributeValues attributeId={attribute.attrId} />  
			</ExpansionPanel> 
		</Grid>
	);
};

export const useViewAttribute = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(attribute: Attribute) => showWithDrawer(<DisplayAttribute id={attribute.attrId} />),
		[showWithDrawer]
	);
};
