import { ThunkType } from "../../../../../core/store/reducers";
import { getMonthlyTradingExcel } from "../api/getMonthlyTradingExcel";
import fileDownload from "js-file-download";
import { setFetchState } from "../monthlyTradeDataSlice";

export const downloadMonthlyTradeingExcel = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.reports.securityCommission.monthlyTrade;
  const user = store.data.auth.current.user;
  dispatch(setFetchState({ type: "started" }));
  const monthlyTrading = await dispatch(getMonthlyTradingExcel(user, filter));
  if (monthlyTrading) {
    fileDownload(
      new Blob([monthlyTrading as any], {
        type: "application/vnd.openxmlformat",
      }),
      "MonthlySecurityTrading.xlsx"
    );
  }
  dispatch(setFetchState({ type: "not-started" }));

};
