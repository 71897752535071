import { ThunkType } from "../../../../core/store/reducers";
import deleteUserApi from "../api/deleteUser";
import { setFetchState } from "../usersDataReducer";

export const deleteUser = (usrid: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteUserApi(userId, usrid));

	dispatch(setFetchState(result !== undefined ? { type: "successful" } : { type: "error"}));
};
