import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { StockFee } from "../../../data/clearing/stockfees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewStockFee } from "./DisplayStockFee";
import { useStockFeeDelete } from "../../../data/clearing/stockfees/hooks/useStockFeeDelete";
import { getStockFeesEditLocation } from "./Locations";

export const useStockFeeActions = () => {
  const { getString } = useContext(LocalizationContext);
  const onShowstockFee = useViewStockFee();
  const onEditStockFee = useEditStockFeeAction();

  return useMemo(() => {
    const result: Action<StockFee>[] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("tableShow"),
        onClick: (event, rowData) => onShowstockFee(rowData as StockFee),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) => onEditStockFee(rowData as StockFee),
      },
    ];

    return result;
  }, [onShowstockFee, onEditStockFee, getString]);
};

export const useDeleteStockFeeAction = () => {
  const [, deleteStockFee] = useStockFeeDelete();
  return React.useCallback(
    async (stfee: StockFee) => {
      if (stfee.id) {
        await deleteStockFee(stfee.id);
      }
    },
    [deleteStockFee]
  );
};

export const useEditStockFeeAction = () => {
  const history = useHistory();
  return React.useCallback(
    (stfee: StockFee) =>
      history.push(getStockFeesEditLocation(stfee.id?.toString())),
    [history]
  );
};
