import { ValidationState } from "../../../core/util/types";
import { ClientListData } from "../../../data/listing/clients/types";

export enum GuaranteeSide {
	in = "IN",
	out = "OUT",
}

export enum GuaranteeDedication {
	yes = "Y",
	no = "N",
}

export interface GuaranteeListData {
	payId: string;
	payDate: Date;
	clientIdNo: string;
	clientName: string;
	payTypeDescr: string;
	orderNo: string;
	tranNo: string;
	amtPay:number; 
	amtIn: number;
	amtOut: number;
	amtBal: number;
	amtNonAllocBal: number;
	amtOrdersBal: number;
	amtTransBal: number;
	currCode: string;
	status: number;
	grntId: string;
}

export interface GetGuaranteeParams {
	userid: string;
	inout?: GuaranteeSide;
	nondedicated?: GuaranteeDedication;
	//currid?: string;
	cliid?: string;
	//  paytype?: string;
	datefrom?: string;
	dateto?: string;
	fromrow: number;
  numofrows: number;
  paygroup: PayGroup.Guarantees;
}

export interface GuaranteeData {
	payId: string;
	payDate?: Date;
	clientId: string;
	clientIdNo?: string;
	clientName?: string;
	payType: number;
	payTypeDescr?: string;
	hasOrdTran?: number;
	orderId?: string;
	orderNo?: string;
	tranId?: string;
	tranNo?: string;
	sign?: number;
	amt?: number;
	amtBal?: number;
	amtNonAllocBal?: number;
	currId?: string;
	currCode?: string;
	currRate?: number;
	status?: number;
	amtTransBal?: number;
	amtOrdersBal?: number;
	grntId?: string;
	grntNo?: string;
  }

export interface GuaranteeStatus {
	ftValue: number;
	ftDescr: string;
  }
  
export interface GuaranteeFilter {
	status?: Partial<GuaranteeStatus>;
	datefrom?: string;
	dateto?: string;
	client?: Partial<ClientListData>;
	inout?: GuaranteeSide;
	nondedicated?: GuaranteeDedication;
}

export enum PayGroup {
	Guarantees = "G",
	Payments = "P",
}

export type GuaranteeValidationState = ValidationState<GuaranteeData>;
