import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { ProductGroupData } from "../types";
import { loadProductGroup } from "../thunks/loadProductGroup";

export const useDisplayProductGroup = (groupId?: string) => {
	const storeGroup = useSelector<StoreType, ProductGroupData | undefined>(
		store => store.data.listing.productGroups.displayed
	);

	const displayedGroup = storeGroup && storeGroup.prodGroupID === groupId ? storeGroup : undefined;

	const displatch = useDispatch();

	React.useEffect(() => {
		if (groupId) {
			displatch(loadProductGroup(groupId));
		}
	}, [displatch, groupId]);

	return displayedGroup;
};
