import { TradeList } from "../../../data/trading/trades/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { useOrderMarketOptionsTranslator } from "../../../data/trading/orders/hooks/useOrderMarketOptions";
import { TurnocateText } from "../../trading/orders/columRenderers";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
export const useTradeColumns = () => {
	const { getString, getDateString, getDateTimeString, getFormatedNumberString } = React.useContext(LocalizationContext);
	const marketTranslator = useOrderMarketOptionsTranslator();

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	return React.useMemo(
		() => [
			{
				title: screenExtraSmall ? getString("annualTradeReportDate") : getString("tradeTime"),
				render: (trade) => getDateString(trade.tradeTime, "DD.MM.YYYY"),
				sorting: false,
				width: screenExtraSmall ? "120px" : "160px",
				cellStyle: {
					maxWidth: screenExtraSmall ? "120px" : "160px",
					minWidth: screenExtraSmall ? "120px" : "160px",
					width: screenExtraSmall ? "120px" : "160px",
				},
				headerStyle: {
					maxWidth: screenExtraSmall ? "120px" : "160px",
					minWidth: screenExtraSmall ? "120px" : "160px",
					width: screenExtraSmall ? "120px" : "160px",
				},
			},
			{
				title: getString("tradeProdName"),
				field: "prodName",
				sorting: false,
				width: screenExtraSmall ? "120px" : "160px",
				cellStyle: {
					maxWidth: screenExtraSmall ? "120px" : "160px",
					minWidth: screenExtraSmall ? "120px" : "160px",
					width: screenExtraSmall ? "120px" : "160px",
				},
				headerStyle: {
					maxWidth: screenExtraSmall ? "120px" : "160px",
					minWidth: screenExtraSmall ? "120px" : "160px",
					width: screenExtraSmall ? "120px" : "160px",
				},
			},
			{
				title: getString("tradeQty"),
				render: trade => `${getFormatedNumberString(trade.qty)} ${trade.unitDescr}`,
				sorting: false,
				width: "130px",
				cellStyle: {
					minWidth: "130px",
					maxWidth: "130px",
					width: "130px",
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					minWidth: "130px",
					maxWidth: "130px",
					width: "130px",
					textAlign: "end",
				},
			},
			{
				title: getString("orderPrice"),
				render: trade => getFormatedNumberString(trade.price, true),
				sorting: false,
				width: "130px",
				cellStyle: {
					minWidth: "130px",
					maxWidth: "130px",
					width: "130px",
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					minWidth: "130px",
					maxWidth: "130px",
					width: "130px",
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				//hidden: screenExtraSmall,
			},
			{
				title: getString("tradePriceWithVat"),
				render: trade => getFormatedNumberString(trade.priceWithVat, true),
				sorting: false,
				width: "130px",
				cellStyle: {
					minWidth: "130px",
					maxWidth: "130px",
					width: "130px",
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					minWidth: "130px",
					maxWidth: "130px",
					width: "130px",
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				hidden: screenExtraSmall,
			},
			//			{
			//				title: getString("orderMarket"),
			//				render: trade => marketTranslator(trade.market),
			//				sorting: false,
			//				hidden: screenExtraSmall,
			//			},

			{
				title: getString("orderLocDistrict"),
				field: "districtName",
				sorting: false,
				width: "150px",
				//				hidden: screenExtraSmall,
				cellStyle: {
										minWidth: "150px",
										maxWidth: "150px",
										width: "150px",
					//					whiteSpace: "nowrap",
					textAlign: "left",
				},
				headerStyle: {
										minWidth: "150px",
										maxWidth: "150px",
										width: "150px",
					//					whiteSpace: "nowrap",
					textAlign: "left",
				},
			},
			{
				title: getString("orderFranco"),
				field: "francoDescr",
				sorting: false,
				width: "100px",
				//				hidden: screenExtraSmall,
				cellStyle: {
										minWidth: "100px",
										maxWidth: "100px",
										width: "100px",
					//					whiteSpace: "nowrap",
					textAlign: "left",
				},
				headerStyle: {
										minWidth: "100px",
										maxWidth: "100px",
										width: "100px",
					//					whiteSpace: "nowrap",
					textAlign: "left",
				},
			},
			{
				title: getString("orderAttrTitle"),
				field: "orderAttributes",
				sorting: false,
				width: "200px",
				//				hidden: screenExtraSmall,
				cellStyle: {
										minWidth: "400px",
										maxWidth: "400px",
										width: "400px",
					//					whiteSpace: "nowrap",
					textAlign: "left",
				},
				headerStyle: {
										minWidth: "400px",
										maxWidth: "400px",
										width: "400px",
					//					whiteSpace: "nowrap",
					textAlign: "left",
				},
			},
		],
		[getString, getDateTimeString, marketTranslator, screenExtraSmall]
	) as Column<TradeList>[];
};
