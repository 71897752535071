import { GuaranteeData, GuaranteeValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const guaranteeValidationState: GuaranteeValidationState = {
  payDate: true,
  payType: true,
  clientId: true,
  amt: true,
};

export const useGuaranteeValidator = createUseValidator<GuaranteeData, GuaranteeValidationState>
(guaranteeValidationState,
  (item, validation) => ({
    ...validation,
    orderId: (item.orderId && item.hasOrdTran===1) || (!item.orderId && item.hasOrdTran!==1),
    grntId: (item.grntId && item.hasOrdTran===3) || (!item.grntId && item.hasOrdTran!==3)
  })
  );
