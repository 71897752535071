import { combineReducers } from "redux";
import dataReducer from "../../data/dataReducer";
import uxReducer from "../../data/uxReducer";
import { ThunkAction, Action } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
	data: dataReducer,
	ux: uxReducer,
});

export type StoreType = ReturnType<typeof rootReducer>;
export type ThunkType<TReturn = void> = ThunkAction<TReturn, StoreType, {}, Action<string>>;

export default rootReducer;
