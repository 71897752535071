import { TradeList } from "../../../data/trading/trades/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { useOrderMarketOptionsTranslator } from "../../../data/trading/orders/hooks/useOrderMarketOptions";
import { TurnocateText } from "../orders/columRenderers";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const useTradeColumns = () => {
	const { getString, getDateTimeString, getFormatedNumberString } = React.useContext(LocalizationContext);
	const marketTranslator = useOrderMarketOptionsTranslator();

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	return React.useMemo(
		() => [
			{
				title: getString("tradeNo"),
				field: "tradeNo",
				sorting: false,
				width: "70px",
				cellStyle: {
					maxWidth: "70px",
				},
				headerStyle: {
					maxWidth: "70px",
				},
				hidden: screenExtraSmall,
			},
			{
				title:  getString("tradeTime"),
				render: trade => getDateTimeString(trade.tradeTime),
				sorting: false,
				width: "180px",
				cellStyle: {
					maxWidth: "180px",
					minWidth: "180px",
					width: "180px",
				},
				headerStyle: {
					maxWidth: "180px",
					minWidth: "180px",
					width: "180px",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("clientNameSell"),
				render: trade => <TurnocateText text={trade.clientNameSell} maxChars={18} />,
				sorting: false,
				width: "180px",
				cellStyle: {
					maxWidth: "180px",
					width: "180px",
					whiteSpace: "nowrap",
				},
				headerStyle: {
					maxWidth: "180px",
					width: "180px",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("clientNameBuy"),
				render: trade => <TurnocateText text={trade.clientNameBuy} maxChars={18} />,
				sorting: false,
				width: "180px",
				cellStyle: {
					maxWidth: "180px",
					width: "180px",
					whiteSpace: "nowrap",
				},
				headerStyle: {
					maxWidth: "180px",
					width: "180px",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("tradeProdName"),
				field: "prodName",
				sorting: false,
			},
			{
				title: getString("tradeQty"),
				render: trade => `${getFormatedNumberString(trade.qty)} ${trade.unitDescr}`,
				sorting: false,
				cellStyle: {
					minWidth: "130px",
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					minWidth: "130px",
					textAlign: "end",
				},
			},
			{
				title: getString("orderPrice"),
				render: trade => getFormatedNumberString(trade.price, true),
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
			},
			{
				title: getString("orderMarket"),
				render: trade => marketTranslator(trade.market),
				sorting: false,
				hidden: screenExtraSmall,
			},
			{
				title: getString("tradeStatusDescr"),
				field: "statusDescr",
				sorting: false,
				hidden: screenExtraSmall,
			},
		],
		[getString, getDateTimeString, getFormatedNumberString, marketTranslator,screenExtraSmall]
	) as Column<TradeList>[];
};
