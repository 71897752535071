import React from "react";
import { ViewOrder } from "../../../data/trading/orders/types";
import {
  PaginationTable,
  PaginationDataProps,
} from "../../components/common/PagginationTable";
import { useOrderReportsColumns } from "./useOrderReportsColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface OrderTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}

type OrderTablePropsType = OrderTableProps & PaginationDataProps<ViewOrder>;

const OrdersTable: React.FunctionComponent<OrderTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const columns = useOrderReportsColumns();

  return (
    <PaginationTable<ViewOrder>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default OrdersTable;
