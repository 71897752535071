import React from "react";
import { useEditDisputeState } from "../../../data/clearing/dispute/hooks/useEditDisputeState";
import { useDisputeValidator } from "../../../data/clearing/dispute/hooks/useDisputeValidator";
import { useDisputeUpsert } from "../../../data/clearing/dispute/hooks/useDisputeUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { DisputeData } from "../../../data/clearing/dispute/types";
import { StringInput } from "../../components/common/StringInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { disputesLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { DateInput } from "../../components/common/DateInput";
import { ProductSelect } from "../../components/listing/ProductSelect";
import { TradesSelect } from "../../components/trading/TradesSelect";
import { DispSitListSelect } from "../../components/common/DispSitListSelect";

export interface EditDisputeInfo {
	dispId?: string;
}

export interface EditDisputeProps extends RouteComponentProps<EditDisputeInfo> {}

export const EditDisputePage: React.FunctionComponent<EditDisputeProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [dispute, editDispute, changed] = useEditDisputeState(props.match.params.dispId);
	const [validationState, validate] = useDisputeValidator();
	const [fetchState, upsert] = useDisputeUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editDispute(event.target.name as any, event.target.value);
		},
		[editDispute]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(disputesLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(dispute);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [dispute, validate, fetchState, history, upsert]);

	const pageTitle = dispute && dispute.dispSitId ? "disEdit" : "disCreate";

	if (!dispute) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={disputesLocation} />} />
					<Divider />
					<CardContent>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={2}>
								<DateInput
									label={getString("disDispSitDate")}
									value={dispute.dispSitDate || null}
									onChange={date => editDispute("dispSitDate", date)}
									error={
										validationState.dispSitDate !== undefined &&
										!validationState.dispSitDate
									}
									required={validationState.dispSitDate !== undefined}
								/>
							</Grid>
						</Grid>

						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={4}>
								<TradesSelect
								label={getString("ctrltradeNo")}
									predicate={trade => trade.tradeId === dispute.tradeId}
									onChangeItem={trade => {
										editDispute("tradeId", trade?.tradeId);
										editDispute("productId", trade?.productId);
									}}
									onSellerChange={seller =>
										seller?.cliId !== dispute.sellerId &&
										editDispute("sellerId", seller?.cliId)
									}
									onBuyerChange={buyer =>
										buyer?.cliId !== dispute.buyerId &&
										editDispute("buyerId", buyer?.cliId)
									}  
									required={validationState.tradeId !== undefined}
									error={validationState.tradeId !== undefined && !validationState.tradeId}
//									filter={{}}
								/>
							</Grid>
						</Grid>

						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={4}>
								<ClientSelect
									label={getString("dissellerName")}
									predicate={client => client.cliId === dispute.sellerId}
									onChangeItem={client =>
										editDispute("sellerId", client ? client.cliId : undefined)
									}
									required={validationState.sellerId !== undefined}
									error={
										validationState.sellerId !== undefined && !validationState.sellerId
                  }
                  disabled
								/>
							</Grid>
							<Grid item xs={4}>
								<ClientSelect
									label={getString("disbuyerName")}
									predicate={client => client.cliId === dispute.buyerId}
									onChangeItem={client =>
										editDispute("buyerId", client ? client.cliId : undefined)
									}
									required={validationState.buyerId !== undefined}
                  error={validationState.buyerId !== undefined && !validationState.buyerId}
                  disabled
								/>
							</Grid>
							<Grid item xs={3}>
								<ProductSelect
									label={getString("orderProduct")}
									predicate={prod => prod.prodId === dispute.productId}
									onChangeItem={prod => {
										editDispute("productId", prod ? prod.prodId : undefined);
									}}
									required={validationState.productId !== undefined}
									error={
										validationState.productId !== undefined && !validationState.productId
                  }
                  disabled
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={4}>
								<StringInput<DisputeData>
									property="situationDescr"
									item={dispute}
									validationState={validationState}
									onChange={handleChange}
									label={getString("disDisputeDescr")}
								/>
							</Grid>
						</Grid>

						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={3}>
								<DispSitListSelect
                 			 		label={getString("disstatus")}
                  					predicate={(type) => type.ftValue === dispute.status}
                  					onChangeItem={(type) => editDispute("status", type?.ftValue)}
                  					required={validationState.status !== undefined}
                  					error={validationState.status !== undefined && !validationState.status}
                				/>
							</Grid>
						</Grid>

						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
