import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { DeliveryListData } from "../../../data/clearing/delivery/types";
import { DeliveryData } from "../../../data/clearing/delivery/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewDelivery } from "./DisplayDelivery";
import { useHistory } from "react-router-dom";
import { getDeliveriesEditLocation } from "../delivery/Locations";
import { DisplayDeliveryMainAttributes } from "../delivery/DisplayDeliveryMainAttributes";
import { useDeliveryDelete } from "../../../data/clearing/delivery/hooks/useDeliveryDelete";

export function useDeliveriesActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowDelivery = useViewDelivery();
  const onEditDelivery = useEditDeliveryAction();

  return useMemo(() => {
    const result: MaterialTableProps<DeliveryListData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) =>
          onShowDelivery(rowData as DeliveryListData),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) =>
          onEditDelivery(rowData as DeliveryListData),
      },
    ];
    return result;
  }, [onShowDelivery, onEditDelivery, getString]);
}

export const useDeleteDeliveryAction = () => {
  const [, deleteDelivery] = useDeliveryDelete();
  return React.useCallback(
    async (delivery: DeliveryListData) => {
      if (delivery.deliveryId) {
        await deleteDelivery(delivery.deliveryId);
      }
    },
    [deleteDelivery]
  );
};

export const useEditDeliveryAction = () => {
  const history = useHistory();
  return React.useCallback(
    (deliveries: DeliveryListData) =>
      history.push(getDeliveriesEditLocation(deliveries.deliveryId)),
    [history]
  );
};
