import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { Commissions } from "../../models";

const getCommissionsLabel = (commissions?: Commissions) =>
  commissions ? commissions.commName : "";

export const useCommissions = () => {
  const commissions = useSelector<StoreType, Commissions[]>(
    (store) => store.data.initialData.commissions
  );

  return [commissions, getCommissionsLabel] as [
    typeof commissions,
    typeof getCommissionsLabel
  ];
};

export const useCommission = (commId?: string) => {
  const [commissions] = useCommissions();

  const commission = React.useMemo(
    () => commissions.find((c) => c.commId === commId),
    [commissions, commId]
  );
  const commissionkLabel = React.useMemo(
    () => getCommissionsLabel(commission),
    [commission]
  );

  return [commission, commissionkLabel] as [
    typeof commission,
    typeof commissionkLabel
  ];
};
