import { useCopiedState } from "../../../../core/util/hooks";
import { setFilter, removeFilter, initialState } from "../alarmUXSlice";
import { invalidateAlarm } from "../alarmDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { AlarmFilter } from "../types";

export const useAlarmFilter = () => {
  const [storeFilter] = useCurrentAlarmFilter();
  const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<
  AlarmFilter
  >(storeFilter);


  const dispatch = useDispatch();
  const dispatchSetFilter = React.useCallback(() => {
    dispatch(setFilter(filter!));
    dispatch(invalidateAlarm());
  }, [dispatch, filter]);

  const dispatchResetFilter = React.useCallback(() => {
    setCurrentFilter(initialState.filter);
  }, [setCurrentFilter]);


  return [filter, editFilter, dispatchSetFilter, changeState, dispatchResetFilter ] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter,
    typeof changeState,
    typeof dispatchResetFilter ];
};

export const useCurrentAlarmFilter = () => {
  const filter = useSelector<StoreType, AlarmFilter>(
    (store) => store.ux.surveillance.alarms.filter
  );
  const dispatch = useDispatch();
  const dispatchRemoveFilter = React.useCallback(
    (type: keyof AlarmFilter) => {
      dispatch(removeFilter(type));
      dispatch(invalidateAlarm());
    },
    [dispatch]
  );

  return [filter, dispatchRemoveFilter ] as [
    typeof filter,
    typeof dispatchRemoveFilter,
  ];
};
