import { StoreType } from "../store/reducers";
import { useSelector, useDispatch } from "react-redux";
import React from "react";
import { useDebounceState } from "./hooks";

export const useSearch = (
	searchSelector: (store: StoreType) => string,
	setSearch: (search: string) => void
) => {
    const search = useSelector(searchSelector);

	const dispatch = useDispatch();
	const setSearchCallback = React.useCallback((search: string) => dispatch(setSearch(search)), [
		dispatch,
		setSearch,
    ]);

	const [deleyedSearch, setDeleyedSearch] = useDebounceState(search);
    const [displaySearch, setDisplaySearch] = React.useState(search);

	React.useEffect(() => {
		setDeleyedSearch(displaySearch);
    }, [displaySearch, setDeleyedSearch]);

	React.useEffect(() => {
		setSearchCallback(deleyedSearch);
    }, [deleyedSearch, setSearchCallback]);

	return [displaySearch, setDisplaySearch] as [string, typeof setDeleyedSearch];
};
