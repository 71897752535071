import React from "react";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { GuaranteeSideSelect } from "./GuaranteeSideSelect";
import { GuaranteeDedicationSelect } from "./GuaranteeDedicationSelect";
import { DateInput } from "../../components/common/DateInput";
import { LocalizationContext } from "../../../core/localization/Localization";
import { GuaranteeFilter } from "../../../data/clearing/guarantees/types";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { useAuth } from "../../../data/auth/hooks";
import { GuaranteesFilterChipsComponent } from "./GuaranteesFilterChips";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";

export enum GuaranteeFilterEditorType {
  Basic,
  Extended,
}

interface GuaranteeFilterEditorProps {
  filter?: GuaranteeFilter;
  editFilter: (prop: keyof GuaranteeFilter, value: any) => void;
  save: () => void;
  reset: () => void;
  type: GuaranteeFilterEditorType;
}

export const GuaranteeFilterEditor: React.FunctionComponent<GuaranteeFilterEditorProps> = ({
  filter,
  editFilter,
  save,
  type,
  reset
}) => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const { getString } = React.useContext(LocalizationContext);
  const [auth] = useAuth();

  return (
    <Paper
      style={{
        paddingLeft: "70px",
        paddingRight: "70px",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Grid container direction="column" spacing={6}>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={6}>
            <DateInput
              label={getString("orderFilterFrom")}
              value={filter?.datefrom || null}
              onChange={(date) => editFilter("datefrom", date?.toISOString())}
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              label={getString("orderFilterTo")}
              value={filter?.dateto || null}
              onChange={(date) => editFilter("dateto", date?.toISOString())}
            />
          </Grid>

{/*           <Grid item xs={6}>
            <GuaranteeSideSelect
              handleChange={(e) => editFilter("inout", e.target.value)}
              side={filter?.inout}
              nullable
            />
          </Grid>
          <Grid item xs={6}>
            <GuaranteeDedicationSelect
              handleChange={(e) => editFilter("nondedicated", e.target.value)}
              sidededication={filter?.nondedicated}
              nullable
            />
          </Grid>   */}

          {type === GuaranteeFilterEditorType.Extended &&
            auth.status === "successful" &&
            auth.user.userIsEmployee === 1 && (
              <Grid item xs={12}>
                <ClientSelect
                  label={getString("orderClientName")}
                  predicate={(client) => client.cliId === filter?.client?.cliId}
                  onChangeItem={(client) =>
                    editFilter("client", client)
                  }
                />
              </Grid>
            )}
        </Grid>

        <Grid item container direction="row-reverse" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                save();
                showWithDrawer(undefined);
              }}
            >
              {getString("orderFilterConfirm")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text" onClick={reset}>
              {getString("filterReset")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const GuaranteesFilterChips = GuaranteesFilterChipsComponent;
