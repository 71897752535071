import OrdersReport from "./OrdersReport";
import { NavigationItem } from "../../../core/navigation/types";
import { ordersReportLocation, } from "./Locations";

export const ordersReportComponents: NavigationItem[] = [
	{
		Component: OrdersReport,
		isMenuItem: true,
		path: ordersReportLocation,
	},
];
