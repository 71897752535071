import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { AttributesValue } from "../../models";

const getAttributeLabel = (role?: AttributesValue) => (role ? role.attrValName : "");

export const useAttributeValues = () => {
	const attributes = useSelector<StoreType, AttributesValue[]>(
		store => store.data.initialData.attributeValues
	);

	return [attributes, getAttributeLabel] as [typeof attributes, typeof getAttributeLabel];
};
