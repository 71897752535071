import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllCurrencyRates } from "../thunks/loadAllCurrencyRates";
import { CurrencyRate } from "../types";

export const useCurrencyRateData = () => {
	const data = useSelector<StoreType, CurrencyRate[] | undefined>(s => s.data.listing.currencyRates.displayData);
	const allData = useSelector<StoreType, CurrencyRate[] | undefined>(s => s.data.listing.currencyRates.all);

	const dispatch = useDispatch();
	const dispatchLoadAll = React.useCallback(() => dispatch(loadAllCurrencyRates()), [dispatch]);

	return [data, dispatchLoadAll, allData] as [
		typeof data,
		typeof dispatchLoadAll,
		typeof allData
	];
};
