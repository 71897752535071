import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import { TradeList } from "../../../data/trading/trades/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewTrade } from "./DisplayTrade";
import { useShowCancelTrade } from "./CancelTrade";
import { useAuth } from "../../../data/auth/hooks";

export function useTradeActions() {
	const { getString } = useContext(LocalizationContext);
	const onShowTrade = useViewTrade();
	const onCancelTrade = useShowCancelTrade();
	const [auth] = useAuth();

	return useMemo<MaterialTableProps<TradeList>["actions"]>(
		() => [
			{
				icon: () => <ViewIcon />,
				tooltip: getString("tradeViewAction"),
				onClick: (event, rowData) => onShowTrade(rowData as TradeList),
			},
			trans => ({
				icon: () => <CancelIcon />,
				tooltip: getString("tradeCancelAction"),
				onClick: (event, rowData) => onCancelTrade(rowData as TradeList),
				hidden: auth.status !== "successful"  || auth.user.userIsEmployee !== 1 || auth.user.roleCode !== "CONTROL" || trans.status === 40  ,
			}),
		],
		[onShowTrade, getString, onCancelTrade, auth]
	);
}
