import React from "react";
import { useDisplayDelivery } from "../../../data/clearing/delivery/hooks/useDisplayDelivery";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import {
  DeliveryData,
  DeliveryListData,
} from "../../../data/clearing/delivery/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayDeliveryMainAttributes } from "./DisplayDeliveryMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,IconButton,
} from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { useDeliveryPrinter } from "../../../data/clearing/delivery/hooks/useDeliveryPrinter";

export interface DisplayDeliveryGoodsProps {
  id?: string;
}

export const DisplayDelivery: React.FunctionComponent<DisplayDeliveryGoodsProps> = ({
  id,
}) => {

  const delivery = useDisplayDelivery(id);
  const { getString } = React.useContext(LocalizationContext);
	const [download, fetchState, setfetch] = useDeliveryPrinter(delivery);

  return !delivery ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
        <Grid container justify="space-between">
            <Grid item>
              <Typography>{getString("tableBasciInfo")}</Typography>
            </Grid>
							<Grid item>
								<CustomTooltip titleKeyword="exportPDFTooltip">
									<IconButton
										color="secondary"
										component="span"
										onClick={download}
										disabled={fetchState.type !== "not-started"}
									>
										<DownloadIcon />
									</IconButton>
								</CustomTooltip>
                { fetchState.type !== "not-started"  ? getString("pleaseWait") : null }
							</Grid>
          </Grid>
        </ExpansionPanelSummary>
        <DisplayDeliveryMainAttributes delivery={delivery} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewDelivery = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (delivery: DeliveryListData) =>
      showWithDrawer(<DisplayDelivery id={delivery.deliveryId} />),
    [showWithDrawer]
  );
};
