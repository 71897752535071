import { DailySecurityReportFilter } from "../types";
import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

export const getDailySecurityExcel = (
  user: User,
  filter: DailySecurityReportFilter
) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getsecdailytradingxls",
    params: {
      userid: user.userId,
      repdate: getParamsDateString(filter.reportDate),
    },
  });
};

export default getDailySecurityExcel;
