import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import {
	useTradingDataFilter,
	useCurrentTradingDataFilter,
} from "../../../data/clearing/tradingData/hooks/useTradingDataFilter";
import {
	TradingDataFilterEditor,
	TradingDataFilterEditorType,
	TradingDataFilterChips,
} from "../tradingData/TradingDataFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const TradingDataFilterButton: React.FunctionComponent = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<TradingsDataFilterEditor />), [showWithDrawer]);

	const [filter, removeFilter] = useCurrentTradingDataFilter();
	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<TradingDataFilterChips filter={filter} removeFilter={removeFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip">
					<IconButton color="secondary" component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const TradingsDataFilterEditor: React.FunctionComponent = () => {
	const [filter, editFilter, save, , rest] = useTradingDataFilter();
	return (
		<TradingDataFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
			type={TradingDataFilterEditorType.Extended}
			reset={rest}
		/>
	);
};
