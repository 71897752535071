import { CreateInvoicesTranData, CreateInvoicesTranFilter } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getCreateInvoicesData = (
  user: User,
  filter: CreateInvoicesTranFilter
) => {
  return webApi<CreateInvoicesTranData[]>({
    method: "GET",
    url: "trade/gettranlist",
    params: {
      userid: user.userId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      status: 1,
    },
  });
};

export default getCreateInvoicesData;
