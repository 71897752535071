import { ThunkType } from "../../../../core/store/reducers";
import deleteCommisionApi from "../api/deleteCommision";
import { setFetchState } from "../commissionsDataReducer";

export const deleteCommission = (commId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteCommisionApi(userId, commId));

	dispatch(setFetchState(result !== undefined ? { type: "successful" } : { type: "error"}));
};
