import { ThunkType } from "../../../../core/store/reducers";
import getTrades from "../../../trading/trades/api/getTrades";
import { PageDataStatus, PageData } from "../../../models";
import { TradeFilter, TradeList } from "../../../trading/trades/types";

export const getTradesThunk = (
	pageNumber?: number,
	itemsPerPage?: number,
	filter?: TradeFilter
): ThunkType<Promise<PageData<TradeList> | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const user = store.data.auth.current.user;

	const tradesData = await dispatch(getTrades(user, pageNumber, itemsPerPage, filter));

	if (!tradesData || tradesData.resCode !== "OK") {
		return undefined;
	}

	return {
		currentPageData: tradesData.obj,
		totalCount: Number(tradesData.resDesc) || 0,
		status: PageDataStatus.DataCurrent,
	};
};
