import { ThunkType } from "../../../../../core/store/reducers";
import deleteClientAcountApi from "../../api/clientAccount/deleteClientAccount";
import { setFetchStateAccount } from "../../clientsDataReducer";

export const deleteClientAccount = (contractId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchStateAccount({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteClientAcountApi(userId, contractId));

	dispatch(setFetchStateAccount(result !== undefined ? { type: "successful" } : { type: "error"}));
};
