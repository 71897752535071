import { TradeByProductsReportData } from "../../../../data/reports/exchbusiness/tradeByProducts/types";
import React from "react";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Column } from "material-table";

export const useTradeByProductsColumns = () => {
  const { getString, getFormatedNumberString } = React.useContext(
    LocalizationContext
  );

  return React.useMemo(
    () => [
      {
        title: getString("exchTransactionDate"),
        field: "transactionDate",
        sorting: false,
      },
      {
        title: getString("exchTransactionNumber"),
        field: "transactionNumber",
        sorting: false,
      },
      {
        title: getString("exchProduct"),
        field: "product",
        sorting: false,
      },
      {
        title: getString("exchQuantity"),
        render: (row) => `${getFormatedNumberString(row.quantity)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchPrice"),
        render: (row) => `${getFormatedNumberString(row.price, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchAmount"),
        render: (row) => `${getFormatedNumberString(row.amount, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
    ],
    [getString, getFormatedNumberString]
  ) as Column<TradeByProductsReportData>[];
};
