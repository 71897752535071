import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useAttributeTypeOptions } from "../../../data/listing/attributes/hooks/useAttributeTypeOptions";
import { TextField, MenuItem } from "@material-ui/core";
import { AttributeType } from "../../../data/listing/attributes/types";

interface AccountOrderSideSelectProps {
	type: AttributeType;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AttributeTypeSelect: React.FunctionComponent<AccountOrderSideSelectProps> = ({
	type,
	handleChange,
}) => {
	const { getString } = React.useContext(LocalizationContext);
    const options = useAttributeTypeOptions();

	return (
		<TextField
			name="attrType"
			value={type}
			onChange={handleChange}
			fullWidth
			select
			label={getString("productAttributeType")}
		>
			{options.map(option => (
				<MenuItem key={option.value} value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</TextField>
	);
};
