import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { City } from "../../../data/listing/cities/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Action } from "material-table";
import { useHistory } from "react-router-dom";
import { useViewCity } from "./DisplayCity";
import { useCityDelete } from "../../../data/listing/cities/hooks/useCityDelete";
import { getCityEditLocation } from "./Locations";
import { AccessRights } from "../../../core/navigation/types";

export const useCityActions = (userAccessRights: AccessRights) => {
  const { getString } = useContext(LocalizationContext);
  const onShowCity = useViewCity();
  const onEditCity = useEditCityAction();

  return useMemo(() => {
    const result: Action<City>[] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("tableShow"),
        onClick: (event, rowData) => onShowCity(rowData as City),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) => onEditCity(rowData as City),
        hidden: userAccessRights===AccessRights.ReadOnly
      },
    ];

    return result;
  }, [onShowCity, onEditCity, getString]);
};

export const useDeleteCityAction = () => {
  const [, deleteCity] = useCityDelete();
  return React.useCallback(
    async (city: City) => {
      if (city.cityId) {
        await deleteCity(city.cityId);
      }
    },
    [deleteCity]
  );
};

export const useEditCityAction = () => {
  const history = useHistory();
  return React.useCallback(
    (city: City) =>
      history.push(getCityEditLocation(city.cityId)),
    [history]
  );
};
