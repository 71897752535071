import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { resetTradeIdices } from "../TASUXSlice";
import { PageIndex } from "../../../models";
import { setTradesIndexThunk } from "../thunks/loadTrades";

export const useTradeIndex = () => {
  const pageIndex = useSelector<StoreType, PageIndex>((s) => s.ux.clearing.transportAndStorage.index);

  const dispatch = useDispatch();
  const dispatchSetIndex = React.useCallback(
    (index: PageIndex) => dispatch(setTradesIndexThunk(index)),
    [dispatch]
  );
  const dispatchResetIndex = React.useCallback(
    () => dispatch(resetTradeIdices()),
    [dispatch]
  );

  return [pageIndex, dispatchSetIndex, dispatchResetIndex] as [
    typeof pageIndex,
    typeof dispatchSetIndex,
    typeof dispatchResetIndex
  ];
};
