import React from "react";
import { Country } from "../../../data/listing/countries/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";

export interface DisplayCountryMainAttributesProps {
  country: Country;
}

export const DisplayCountryMainAttributes: React.FunctionComponent<DisplayCountryMainAttributesProps> = ({
  country,
}) => {
  const style = useDisplaySingleEmementStyles();
  const { getString } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("countryName")}</span>
        <span className={style.value}>{country.cntName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("countryNameEN")}</span>
        <span className={style.value}>{country.cntNameen}</span>
      </div>
    </div>
  );
};
