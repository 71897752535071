import React from "react";
import { useInvoiceType } from "../../../data/initialData/hooks/useInvoiceType";
import { InvTypes } from "../../../data/models";
import {
  AutocompleteSelect,
  AutocompleteSelectType,
} from "./AutocompleteSelect";

type InvoiceTypeSelectPropsType = Omit<
  AutocompleteSelectType<InvTypes>,
  "data" | "getLabel"
>;

export const InvoiceTypeSelect: React.FunctionComponent<InvoiceTypeSelectPropsType> = (
  props
) => {
  const [invtypes, renderInvTypeLabel] = useInvoiceType();

  return (
    <AutocompleteSelect<InvTypes>
      data={invtypes}
      getLabel={renderInvTypeLabel}
      {...(props as any)}
    />
  );
};
