import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { OrderRecievedMethod } from "../../models";

const renderLabel = (item: OrderRecievedMethod) => `${item.ftDescr} (${item.descrAbr})`

export const useRecieveMethods = () => {
	const orderRecieveMethods = useSelector<StoreType, OrderRecievedMethod[]>(store => store.data.initialData.orderRecieveMethods);

	return [orderRecieveMethods, renderLabel] as [typeof orderRecieveMethods, typeof renderLabel];
};
