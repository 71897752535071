import React from "react";
import { Order, OrderAllOrNothing } from "../../../data/trading/orders/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { useOrderSideOptionsTranslator } from "../../../data/trading/orders/hooks/useOrderSideOptions";
import { useOrderMarketOptionsTranslator } from "../../../data/trading/orders/hooks/useOrderMarketOptions";
import { Status } from "../../../data/models";

export enum DiplayOrderMod {
	Full,
	PublicData,
}

export interface DisplayOrderMainAttributesProps {
	order: Order;
	mod: DiplayOrderMod;
}

export const DisplayOrderMainAttributes: React.FunctionComponent<DisplayOrderMainAttributesProps> = ({
	order,
	mod,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString, getDateString, getDateTimeString, getFormatedNumberString, getFormatedNumberString4 } = React.useContext(
		LocalizationContext
	);

	const sideTranslator = useOrderSideOptionsTranslator();
	const marketTranslator = useOrderMarketOptionsTranslator();

	return (
		<div className={style.root}>
			{mod === DiplayOrderMod.Full && (
				<div className={`${style.row} ${style.rowBorder}`}>
					<span className={style.label}>{getString("orderClmOrdNo")}</span>
					<span className={style.value}>{order.ordNo}</span>
				</div>
			)}
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderTimeReceived")}</span>
				<span className={style.value}>{getDateTimeString(order.timeReceived)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderSide")}</span>
				<span className={style.value}>{sideTranslator(order.side)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderMarket")}</span>
				<span className={style.value}>{marketTranslator(order.market)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderOrdType")}</span>
				<span className={style.value}>{order.ordTypeDescr}</span>
			</div>
			{mod === DiplayOrderMod.Full && (
				<>
					<div className={`${style.row} ${style.rowBorder}`}>
						<span className={style.label}>{getString("orderRefOrd")}</span>
						<span className={style.value}>{order.refOrdNo}</span>
					</div>
					<div className={`${style.row} ${style.rowBorder}`}>
						<span className={style.label}>{getString("orderReceiveMethod")}</span>
						<span className={style.value}>{order.receiveMethodDescr}</span>
					</div>
					<div className={`${style.row} ${style.rowBorder}`}>
						<span className={style.label}>{getString("orderClientName")}</span>
						<span className={style.value}>{order.clientName}</span>
					</div>
					<div className={`${style.row} ${style.rowBorder}`}>
						<span className={style.label}>{getString("orderCliPrsName")}</span>
						<span className={style.value}>{order.cliPrsName}</span>
					</div>
					<div className={`${style.row} ${style.rowBorder}`}>
						<span className={style.label}>{getString("orderbrokName")}</span>
						<span className={style.value}>{order.brokName}</span>
					</div>
					<div className={`${style.row} ${style.rowBorder}`}>
						<span className={style.label}>{getString("orderUserName")}</span>
						<span className={style.value}>{order.userName}</span>
					</div>
				</>
			)}
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderProduct")}</span>
				<span className={style.value}>{`${order.prodName} (${order.prodCode})`}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderUnit")}</span>
				<span className={style.value}>{order.unitDescr}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderQty")}</span>
				<span className={style.value}>{getFormatedNumberString(order.qty)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderQtyLeft")}</span>
				<span className={style.value}>{getFormatedNumberString(order.qtyLeft)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderPrice")}</span>
				<span className={style.value}>{getFormatedNumberString(order.price, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderPriceEur")}</span>
				<span className={style.value}>{getFormatedNumberString4(order.priceEur, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderPriceVat")}</span>
				<span className={style.value}>{`${order.percVat} %`}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderPriceWithVat")}</span>
				<span className={style.value}>{getFormatedNumberString(order.priceWithVat, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderAllOrNothing")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={order.allOrNothing}
						thrutyValue={OrderAllOrNothing.True}
						className={style.icon}
					/>
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderMinQty")}</span>
				<span className={style.value}>{getFormatedNumberString(order.minQty)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderExpiryDate")}</span>
				<span className={style.value}>{getDateString(order.expiryDate)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderPayType")}</span>
				<span className={style.value}>{order.payTypeDescr}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderPayDays")}</span>
				<span className={style.value}>{getFormatedNumberString(order.paymentDays)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("ordergoodsRedyFrom")}</span>
				<span className={style.value}>{getDateString(order.goodsReadyFrom)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("ordergoodsRedyTo")}</span>
				<span className={style.value}>{getDateString(order.goodsReadyTo)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderFranco")}</span>
				<span className={style.value}>{order.francoDescr}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderexportReady")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={order.exportReady}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				</span>
			</div>
			{mod === DiplayOrderMod.Full && (<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderDeliveryLocation")}</span>
				<span className={style.value}>{getDeliveryLocation(order)}</span>
			</div>)}
			{mod !== DiplayOrderMod.Full && (<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderDeliveryLocation")}</span>
				<span className={style.value}>{getDeliveryLocationShort(order)}</span>
			</div>)}
			{/* <div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderCheckPoint")}</span>
				<span className={style.value}>{order.checkPointDescr}</span>
			</div> */}
			{/*<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderCheckPays")}</span>
				<span className={style.value}>{order.paysCheckDescr}</span>
		</div> */}

			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderBankGuarantee")}</span>
				<span className={style.value}>{order.guaranteeDescr}</span>
			</div>


			{/*<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderBankGuarantee")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={order.bankGuarantee}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				</span>
	</div>*/}

			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderComment")}</span>
				<span className={style.value}>{order.comment}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderCommission")}</span>
				<span className={style.value}>{getFormatedNumberString(order.commission, true)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderCancelReplTime")}</span>
				<span className={style.value}>{getDateTimeString(order.cancReplTime)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("orderStatusDescr")}</span>
				<span className={style.value}>{order.ordStatusDescr}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("attributeSRPS")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={order.srps}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				</span>
			</div>
		</div>
	);
};

const getDeliveryLocation = (order?: Order) => {
	if (!order || !order.locCountryName) return "";
	let deliveryLocation = order.locCountryName;

	if (!order.locRegionName) return deliveryLocation;
	deliveryLocation += ` -> ${order.locRegionName}`;

	if (!order.locDistrictName) return deliveryLocation;
	deliveryLocation += ` -> ${order.locDistrictName}`;

	if (!order.locCityName) return deliveryLocation;
	deliveryLocation += ` -> ${order.locCityName}`;

	if (!order.locWarehouseName) return deliveryLocation;

	return deliveryLocation + ` -> ${order.locWarehouseName}`;
};

const getDeliveryLocationShort = (order?: Order) => {
	if (!order || !order.locCountryName) return "";
	let deliveryLocation = order.locCountryName;

	if (!order.locRegionName) return deliveryLocation;
	deliveryLocation += ` -> ${order.locRegionName}`;

	if (!order.locDistrictName) return deliveryLocation;
	deliveryLocation += ` -> ${order.locDistrictName}`;

	return deliveryLocation;
};
