import React from "react";
import { InvoiceData } from "../../../data/accounting/invoices/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayInvoiceMainAttributesProps {
  invoice: InvoiceData;
}

export const DisplayInvoiceMainAttributes: React.FunctionComponent<DisplayInvoiceMainAttributesProps> = ({
  invoice,
}) => {
  const style = useDisplaySingleEmementStyles();
  const {
    getString,
    getDateString,
    getFormatedNumberString,
  } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceInvNo")}</span>
        <span className={style.value}>{invoice.invNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceInvDate")}</span>
        <span className={style.value}>{getDateString(invoice.invDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceInvTypeDescr")}</span>
        <span className={style.value}>{invoice.invTypeDescr}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceTranDate")}</span>
        <span className={style.value}>{getDateString(invoice.tranDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceDueDate")}</span>
        <span className={style.value}>{getDateString(invoice.dueDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceDescr")}</span>
        <span className={style.value}>{invoice.descr}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceTranNo")}</span>
        <span className={style.value}>{invoice.tranNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceClientIdNo")}</span>
        <span className={style.value}>{invoice.clientIdNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceClientName")}</span>
        <span className={style.value}>{invoice.clientName}</span>
      </div>
{/*      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceTradeAmt")}</span>
        <span className={style.value}>
          {getFormatedNumberString(invoice.tradeAmt, true)}
        </span>
  </div> */}
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceTradeAmtWithVat")}</span>
        <span className={style.value}>
          {getFormatedNumberString(invoice.tradeAmtWithVat, true)}
        </span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceCommPerc")}</span>
        <span className={style.value}>
          {getFormatedNumberString(invoice.commPerc, true)}
        </span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceAmt")}</span>
        <span className={style.value}>
          {getFormatedNumberString(invoice.amt, true)}
        </span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceVatPerc")}</span>
        <span className={style.value}>
          {getFormatedNumberString(invoice.vatPerc, true)}
        </span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceVatAmt")}</span>
        <span className={style.value}>
          {getFormatedNumberString(invoice.vatAmt, true)}
        </span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceInvAmt")}</span>
        <span className={style.value}>
          {getFormatedNumberString(invoice.invAmt, true)}
        </span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceCurrCode")}</span>
        <span className={style.value}>{invoice.currCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceBookDate")}</span>
        <span className={style.value}>{getDateString(invoice.bookDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceCmpAccount")}</span>
        <span className={style.value}>{invoice.cmpAccount}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceModel")}</span>
        <span className={style.value}>{invoice.model}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceReffNo")}</span>
        <span className={style.value}>{invoice.reffNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("invoiceStatus")}</span>
        <span className={style.value}>
          <DisplayBoolNumber
            value={invoice.status}
            thrutyValue={Status.Active}
            className={style.icon}
          />
        </span>
      </div>
    </div>
  );
};
