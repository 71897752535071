import { ThunkType } from "../../../../core/store/reducers";
import { getExchangeMembersReport } from "../api/getExchangeMembersReport";
import fileDownload from "js-file-download";
import { setFetchState } from "../exchangeMemberDataSlice";

export const downloadExchangeMembersReport = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.reports.exchmembers;
  const user = store.data.auth.current.user;
  dispatch(setFetchState({ type: "started" }));
  const ordersReport = await dispatch(getExchangeMembersReport(user,filter));
  if (ordersReport) {
    fileDownload(
      new Blob([ordersReport as any], { type: "application/pdf" }),
      "ExchangeMembers.pdf"
    );
  }
  dispatch(setFetchState({ type: "not-started" }));
};
