import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { StockFee } from "../types";
import { loadStockFee } from "../thunks/loadStockFee";

export const useDisplayStockFee = (stfeeId?: number) => {
  const storeStockFee = useSelector<StoreType, StockFee | undefined>(
    (store) => store.data.clearing.stockfees.displayedStockFee
  );

  const displayedStockFee =
    storeStockFee && storeStockFee.id === stfeeId ? storeStockFee : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (stfeeId) {
      displatch(loadStockFee(stfeeId));
    }
  }, [displatch, stfeeId]);

  return displayedStockFee;
};
