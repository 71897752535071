import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { Commission } from "../types";
import { loadCommission } from "../thunks/loadCommission";

export const useDisplayCommission = (commId?: string) => {
	const storeCommission = useSelector<StoreType, Commission | undefined>(
		store => store.data.listing.commissions.displayed
    );

    const displayedCommission = storeCommission && storeCommission.commId === commId ? storeCommission : undefined;

    const displatch = useDispatch();

	React.useEffect(() => {
		if (commId) {
			displatch(loadCommission(commId));
		}
    }, [displatch, commId]);

	return displayedCommission;
};
