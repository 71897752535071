import { webApi } from "../../../../core/http/httpThunks";
import { NewsData } from "../types";

export const getNewsApi = (userId: string, newsid: string) =>
  webApi<NewsData>({
    method: "GET",
    url: "news/getnews",
    params: {
      userId,
      newsid,
    },
  });

export default getNewsApi;
