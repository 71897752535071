import { ThunkType } from "../../../../core/store/reducers";
import deleteClientApi from "../api/deleteClient";
import { setFetchStateClient, setDeleteFetchState } from "../clientsDataReducer";
import { ErrorCodeExceptions } from "../types";

export const deleteClient = (empId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

//	dispatch(setFetchStateClient({ type: "started" }));
	dispatch(setDeleteFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteClientApi(userId, empId,[ErrorCodeExceptions.ReferenceErrorCode]));

//	dispatch(setFetchStateClient(result !== undefined ? { type: "successful" } : { type: "error"}));

	dispatch(
		setDeleteFetchState(
			result !== undefined && result.resCode === "OK"
				? { type: "successful" }
				: { type: "error", errorCode: result && result.resCode }
		)
	);


};
