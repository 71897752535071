import React from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import { DateInput } from "../../components/common/DateInput";
import { LocalizationContext } from "../../../core/localization/Localization";
import { InvoiceFilter } from "../../../data/accounting/invoices/types";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { useAuth } from "../../../data/auth/hooks";
import { InvoiceFilterChipsComponent } from "./InvoiceFilterChips";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { isValidDate } from "../../../core/util/functions";

export enum InvoiceFilterEditorType {
  Basic,
  Extended,
}

interface InvoiceFilterEditorProps {
  filter?: InvoiceFilter;
  editFilter: (prop: keyof InvoiceFilter, value: any) => void;
  save: () => void;
  type: InvoiceFilterEditorType;
  reset?: () => void;
}

export const InvoiceFilterEditor: React.FunctionComponent<InvoiceFilterEditorProps> = ({
  filter,
  editFilter,
  save,
  type,
  reset,
}) => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const { getString } = React.useContext(LocalizationContext);
  const [auth] = useAuth();

  return (
    <Paper
      style={{
        paddingLeft: "70px",
        paddingRight: "70px",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Grid container direction="column" spacing={6}>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={6}>
            <DateInput
              label={getString("exchDateFrom")}
              value={filter?.datefrom || null}
              onChange={(date) => editFilter("datefrom", isValidDate(date) ? date.toISOString() : undefined)}
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              label={getString("exchDateTo")}
              value={filter?.dateto || null}
              onChange={(date) => editFilter("dateto", isValidDate(date) ? date.toISOString() : undefined)}
            />
          </Grid>

          {type === InvoiceFilterEditorType.Extended &&
            auth.status === "successful" &&
            auth.user.userIsEmployee === 1 && (
              <Grid item xs={12}>
                <ClientSelect
                  label={getString("orderClientName")}
                  predicate={(cli) => cli.cliId === filter?.client?.cliId}
                  onChangeItem={(cli) => editFilter("client", cli)}
                />
              </Grid>
            )}
        </Grid>

        <Grid item container direction="row-reverse" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                save();
                showWithDrawer(undefined);
              }}
            >
              {getString("orderFilterConfirm")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text" onClick={reset}>
              {getString("filterReset")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const InvoiceFilterChips = InvoiceFilterChipsComponent;
