import React from "react";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import ExcelIcon from "@material-ui/icons/TableChartOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { useWeeklyReportDownloaderProduct } from "../../../data/reports/weeklyTrade/hooks/useWTReportDownloaderProduct";
import { useWeeklyProductExcelDownloader } from "../../../data/reports/weeklyTrade/hooks/useWTExcelDownloaderProducts";
import { useWeeklyReportDownloaderClient } from "../../../data/reports/weeklyTrade/hooks/useWTReportDownloaderClients";
import { useWeeklyClientExcelDownloader } from "../../../data/reports/weeklyTrade/hooks/useWTExcelDownloaderClients";
import { useWeeklyReportDownloaderDate } from "../../../data/reports/weeklyTrade/hooks/useWTReportDownloaderDate";
import { useWeeklyDateExcelDownloader } from "../../../data/reports/weeklyTrade/hooks/useWTExcelDownloaderDate";
import { useWeeklyReportDownloader } from "../../../data/reports/weeklyTrade/hooks/useWTReportDownloader";
import { useWeeklyExcelDownloader } from "../../../data/reports/weeklyTrade/hooks/useWTExcelDownloader";

export const DownloadButton: React.FunctionComponent = () => {
  const [downloader, fetchState] = useWeeklyReportDownloader();
  return (
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadExcelButton: React.FunctionComponent = () => {
  const [downloader, fetchState] = useWeeklyExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadButtonProduct: React.FunctionComponent = () => {
  const [downloader, fetchState] = useWeeklyReportDownloaderProduct();
  return (
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadExcelButtonProduct: React.FunctionComponent = () => {
  const [downloader, fetchState] = useWeeklyProductExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadButtonClient: React.FunctionComponent = () => {
  const [downloader, fetchState] = useWeeklyReportDownloaderClient();
  return (
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadExcelButtonClient: React.FunctionComponent = () => {
  const [downloader, fetchState] = useWeeklyClientExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadButtonDate: React.FunctionComponent = () => {
  const [downloader, fetchState] = useWeeklyReportDownloaderDate();
  return (
    <CustomTooltip titleKeyword="exportPDFTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <DownloadIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export const DownloadExcelButtonDate: React.FunctionComponent = () => {
  const [downloader, fetchState] = useWeeklyDateExcelDownloader();
  return (
    <CustomTooltip titleKeyword="exportXLSXTooltip">
      <IconButton
        color="secondary"
        component="span"
        onClick={downloader}
        disabled={fetchState.type !== "not-started"}
      >
        <ExcelIcon />
      </IconButton>
    </CustomTooltip>
  );
};
