import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteCountry } from "../thunks/deleteCountry";
import { setFetchState } from "../countriesDataReducer";

export const useCountryDelete = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.listing.countries.fetchState
  );

  const dispatch = useDispatch();
  const dispatchDeleteCountry = React.useCallback(
    (id: string) => dispatch(deleteCountry(id)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchDeleteCountry] as [
    typeof fetchState,
    typeof dispatchDeleteCountry
  ];
};
