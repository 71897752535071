import { ThunkType } from "../../../../core/store/reducers";
import { getOrderListReport } from "../api/getOrderListReport";
import fileDownload from "js-file-download";
import { setFetchState } from "../ordersReportDataSlice"

export const downloadOrdersReport = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { filter } = store.ux.reports.ordersReport;
	const user = store.data.auth.current.user;
	dispatch(setFetchState({ type: "started"}))
	const ordersReport = await dispatch(getOrderListReport(user, filter));
	if (ordersReport) {
		fileDownload(new Blob([ordersReport as any], { type: "application/pdf" }), "OrderBook.pdf");
	}
	dispatch(setFetchState({ type: "not-started"}))
};
