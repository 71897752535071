import React, { useContext } from "react";
import { Grid, CardContent, CardHeader, Card, Divider, Typography, Tabs, Tab } from "@material-ui/core";
import { usePageStyles } from "../../../pageStyles";
import { PageComponent } from "../../../../core/navigation/types";
import { PageTitle } from "../../../components/common/PageTitle";
import { useMainBoardData } from "../../../../data/trading/mainBoard/hooks/useMainBoardData";
import { OrderSide, PageDataStatus } from "../../../../data/models";
import { ProductGraph } from "./ProductGraph";
import { LocalizationContext } from "../../../../core/localization/Localization";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const HistoricalPricesPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();
	const { getString } = useContext(LocalizationContext);

	const [tab, setTab] = React.useState(ProductSelectHistory.Kukuruz);

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	const handleSideChange = React.useCallback(
		(event: React.ChangeEvent<{}>, newValue: any) => {
			setTab(newValue);
		},
		[setTab]
	);

	return (
		<>
	{	screenExtraSmall===true && (<Grid container className={pageStyles.rootSpacing} spacing={2}>
			<Grid item xs={12}>
			<Tabs 
							value={tab}
							onChange={handleSideChange}
							indicatorColor="secondary"
							textColor="secondary"
							variant="fullWidth"
						>
							<Tab
								label={getString("historyProd1")}
								value={ProductSelectHistory.Kukuruz}
							/>
							<Tab
								label={getString("historyProd2")}
								value={ProductSelectHistory.Psenica}
							/>
							<Tab
								label={getString("historyProd3")}
								value={ProductSelectHistory.Soja}
							/>
						</Tabs>
						<div style={{ marginTop: "20px" }}>
							{tab === ProductSelectHistory.Kukuruz &&  (
								<Grid item md={4} xs={12}>
									<ProductGraph graphId="0001" />
								</Grid>
					)}
							{tab === ProductSelectHistory.Psenica  && (
							<Grid item md={4} xs={12}>
								<ProductGraph graphId="0002" />
							</Grid>
					)}
							{tab === ProductSelectHistory.Soja  && (
							<Grid item md={4} xs={12}>
								<ProductGraph graphId="0003" />
							</Grid>
					)}
						</div>
			</Grid>  

		</Grid>  ) }
		{	screenExtraSmall!==true && (<Grid container className={pageStyles.rootSpacing} spacing={2}>
			<Grid item xs={12}>
			</Grid>
			<Grid item md={4} xs={12}>
				<ProductGraph graphId="0001" />
			</Grid>
			<Grid item md={4} xs={12}>
				<ProductGraph graphId="0002" />
			</Grid>
			<Grid item md={4} xs={12}>
				<ProductGraph graphId="0003" />
			</Grid>
		</Grid>  ) }
		</>
);

};

export default HistoricalPricesPage;

export enum ProductSelectHistory {
	Kukuruz = "Kukuruz",
	Psenica = "Psenica",
	Soja = "Soja nije GMO",
}
