import { ThunkType } from "../../../../core/store/reducers";
import createAlarmApi from "../api/createAlarm";
import updateAlarmApi from "../api/updateAlarm";
import { AlarmData } from "../types";
import { setFetchState, invalidateAlarm } from "../alarmDataSlice";
import { ApiErrorResponse } from "../../../../core/http/types";

export const upsertAlarm = (alarm: AlarmData, getError: (error: ApiErrorResponse) => string): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = alarm.almId
    ? await dispatch(updateAlarmApi(userId, alarm, getError))
    : await dispatch(createAlarmApi(userId, alarm, getError));

  if (result && result.resCode === "OK") {
    dispatch(setFetchState({ type: "successful", result: result.resDesc }));
    dispatch(invalidateAlarm());
    return result.resDesc;
  } else {
    dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
  }
};
