import { ThunkType } from "../../../../../core/store/reducers";
import { getCommReviewExcel } from "../api/getCommReviewExcel";
import fileDownload from "js-file-download";
import { setFetchState } from "../commReviewDataSlice";

export const downloadCommReviewExcel = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.reports.exchbusiness.commReview;
  const user = store.data.auth.current.user;
  dispatch(setFetchState({ type: "started" }));
  const dailyTrading = await dispatch(getCommReviewExcel(user, filter));
  if (dailyTrading) {
    fileDownload(
      new Blob([dailyTrading as any], {
        type: "application/vnd.openxmlformat",
      }),
      "CommReview.xlsx"
    );
  }
  dispatch(setFetchState({ type: "not-started" }));
};
