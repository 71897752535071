import React from "react";
import { Currency } from "../../../data/listing/currencies/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";

export interface DisplayCurrencyBasicInfoProps {
	currency: Currency;
}

export const DisplayCurrencyBasicInfo: React.FunctionComponent<DisplayCurrencyBasicInfoProps> = ({
	currency,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);

	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("currenciesName")}</span>
				<span className={style.value}>{currency.currName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("currenciesCode")}</span>
				<span className={style.value}>{currency.currCode}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("currenciesNumericCode")}</span>
				<span className={style.value}>{currency.numCode}</span>
			</div>
		</div>
	);
};
