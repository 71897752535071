import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton,Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useDisputeFilter, useCurrentDisputeFilter } from "../../../data/clearing/dispute/hooks/useDisputeFilter";
import {
  DisputeFilterEditor,
  DisputeFilterEditorType,
  DisputeFilterChips,
} from "../dispute/DisputeFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const DisputeFilterButton: React.FunctionComponent = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const showFilter = React.useCallback(
    () => showWithDrawer(<DisputesFilterEditor />),
    [showWithDrawer]
  );

  const [filter, removeFilter] = useCurrentDisputeFilter();

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<DisputeFilterChips filter={filter} removeFilter={removeFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip">
					<IconButton color="secondary" component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const DisputesFilterEditor: React.FunctionComponent = () => {
  const [filter, editFilter, save, ,reset] = useDisputeFilter();
  return (
    <DisputeFilterEditor
      filter={filter}
      editFilter={editFilter}
      save={save}
      type={DisputeFilterEditorType.Extended}
			reset={reset}
    />
  );
};

