import { ThunkType } from "../../../../core/store/reducers";
import createCurrencyRateApi from "../api/createCurrencyRate";
import updateCurrencyRateApi from "../api/updateCurrencyRate";
import { setFetchState } from "../currencyRatesDataReducer";
import { CurrencyRate } from "../types";

export const upsertCurrencyRate = (currency: CurrencyRate): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = currency.currRateId
		? await dispatch(updateCurrencyRateApi(userId, currency))
		: await dispatch(createCurrencyRateApi(userId, currency));

	if (result && result.resCode === "OK") {
		dispatch(setFetchState({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
	}
};
