export interface TradePricesChartData {
    tradeTime: Date,
    price: number,
    prodName: string,
}

export enum ChartPeriod { 
    Month = 30,
    ThreeMonts = 90,
    HalfYear = 130,
    Year = 365,
    FromYearBeggining = -1,
    All = -2,
}