import { NavigationItem } from "../../../core/navigation/types";
import DeliveriesPage from "./DeliveriesPage";
import { deliveriesLocation, editRouter } from "./Locations";
export const deliveryClientComponents: NavigationItem[] = [
  {
    Component: DeliveriesPage,
    isMenuItem: true,
    path: deliveriesLocation,
  },
];
