import { ThunkType } from "../../../../core/store/reducers";
import { getCtrlGoodsReport } from "../api/getCtrlGoodsReport";
import fileDownload from "js-file-download";
import { setDownloadFetchState } from "../ctrlGoodsDataSlice";

export const downloadCtrlGoodsReport = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.clearing.controls;
  const user = store.data.auth.current.user;

  dispatch(setDownloadFetchState({ type: "started" }));
  const ordersReport = await dispatch(getCtrlGoodsReport(user, filter));
  if (ordersReport) {
    fileDownload(
      new Blob([ordersReport as any], { type: "application/pdf" }),
      "CtrlGoods.pdf"
    );
  }
  dispatch(setDownloadFetchState({ type: "not-started" }));
};

