import React from "react";
import { useBankGuaranteeDataSelect } from "../../../data/clearing/bankguarantees/hooks/useBankGuaranteeDataSelect";
import { BankGuaranteeData, SimplBankGuaranteeFilter } from "../../../data/clearing/bankguarantees/types";
import { AutocompleteSelectType, AutocompleteSelect } from "../common/AutocompleteSelect";

export type BankGuaranteePropsType = Omit<
	AutocompleteSelectType<BankGuaranteeData>,
	"data" | "getLabel"> & {
		filter?: SimplBankGuaranteeFilter;
		disabled?: boolean;
	};


export const BankGuaranteeSelect: React.FunctionComponent<BankGuaranteePropsType> = ({
	filter,
	disabled,
	...otherProps
}) => {

	const [bnkgrnt] = useBankGuaranteeDataSelect(filter);

	return (
		<AutocompleteSelect<BankGuaranteeData>
		{...(otherProps as AutocompleteSelectType<BankGuaranteeData>)}
		data={bnkgrnt || []}
		getLabel={renderEmployeeLabel}
		disabled={filter === undefined || disabled}
	/>

	);
};

const renderEmployeeLabel = (bnkgrnt: BankGuaranteeData | null) =>
bnkgrnt ? `${bnkgrnt.grntNo} - ${bnkgrnt.clientName} - ${bnkgrnt.bankName}` : "";

