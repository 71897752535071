import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useOrderSideOptions } from "../../../data/trading/orders/hooks/useOrderSideOptions";
import { TextField, MenuItem, TextFieldProps } from "@material-ui/core";
import { OrderSide } from "../../../data/models";

interface OrderSideSelectProps {
	side?: OrderSide;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	nullable?: boolean;
	classes?: TextFieldProps['classes']
}

export const OrderSideSelect: React.FunctionComponent<OrderSideSelectProps> = ({
	side,
	handleChange,
	disabled,
	nullable,
	classes,
}) => {
	const { getString } = React.useContext(LocalizationContext);
	const options = useOrderSideOptions();
	const enchanchedOptions = React.useMemo(() => {
		return !nullable ? options : [
			{
				label: "--",
				value: "",
			},
			...options
		]
	}, [nullable, options])

	return (
		<TextField
			name="side"
			value={side?.toUpperCase() || ""}
			onChange={handleChange}
			fullWidth
			select
			label={getString("orderSide")}
			disabled={disabled}
			classes={classes}
		>
			{enchanchedOptions.map(option => (
				<MenuItem key={option.value} value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</TextField>
	);
};
