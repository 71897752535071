import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertClient } from "../thunks/upsertClient";
import { setFetchStateClient } from "../clientsDataReducer";
import { Client } from "../types";

export const useClientUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.clients.fetchState
	);

	const dispatch = useDispatch();
	const dispatchUpsertEmployee = React.useCallback(
		(client: Client) => dispatch(upsertClient(client)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateClient({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchUpsertEmployee] as [typeof fetchState, typeof dispatchUpsertEmployee]
};
