import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import { TradeFilter } from "./types";

type TradePageState = {
	index: PageIndex;
	filter: TradeFilter;
};

export const initialState: TradePageState = {
	index: {
		itemsPerPage: 20,
		pageNumber: 0,
	},
	filter: {},
};

const name = "trades";

const tradesSlice = createSlice({
	name,
	initialState,
	reducers: {
		setTradesIndex: (state, action: PayloadAction<PageIndex>) => ({
			...state,
			index: action.payload,
		}),
		resetTradeIdices: state => ({
			...state,
			index: {
				itemsPerPage: state.index.itemsPerPage,
				pageNumber: initialState.index.pageNumber,
			},
		}),
		setFilter: (state, action: PayloadAction<TradeFilter>) => ({
			...state,
			filter: action.payload,
		}),
		removeFilter: (state, action: PayloadAction<keyof TradeFilter>) => ({
			...state,
			filter: {
				...state.filter,
				[action.payload]: undefined,
			},
		}),
	},
	extraReducers: builder => {
		builder.addCase(successfulLogin, state => ({
			...state,
			index: {
				itemsPerPage: state.index.itemsPerPage,
				pageNumber: initialState.index.pageNumber,
			},
		}));
	},
});

export const { setTradesIndex, resetTradeIdices, setFilter, removeFilter } = tradesSlice.actions;

export default tradesSlice.reducer;
