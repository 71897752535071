import React from "react";
import { useDisplayDelivery } from "./useDisplayDelivery";
import { DeliveryData } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";

export const defaultDelivery: DeliveryData = {
  deliveryId: "",
  tradeId: "",
  unitDescr: "",
  //  payTypeDescr: "",
  //  status: Status.Active,
  status: 1,
};

export const useEditDelivery = (deliveryId?: string) => {
  const displayDelivery = useDisplayDelivery(deliveryId);

  return React.useMemo(() => {
    if (deliveryId !== undefined && displayDelivery === undefined) {
      return undefined;
    }

    if (deliveryId === undefined) {
      return { ...defaultDelivery };
    }

    return displayDelivery ? { ...displayDelivery } : undefined;
  }, [deliveryId, displayDelivery]);
};

export const useEditDeliveryState = (deliveryId?: string) => {
  const deliveries = useEditDelivery(deliveryId);
  return useCopiedState(deliveries);
};
