import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllCities } from "../thunks/loadAllCities";
import { City } from "../types";

export const useCityData = () => {
	const data = useSelector<StoreType, City[] | undefined>(s => s.data.listing.cities.displayData);
	const allData = useSelector<StoreType, City[] | undefined>(s => s.data.listing.cities.all);

	const dispatch = useDispatch();
	const dispatchLoadAllCities = React.useCallback(() => dispatch(loadAllCities()), [dispatch]);

	return [data, dispatchLoadAllCities, allData] as [
		typeof data,
		typeof dispatchLoadAllCities,
		typeof allData
	];
};

export const useCity = (id?: string) => {
	const allData = useSelector<StoreType, City[] | undefined>(s => s.data.listing.cities.all);

	const dispatch = useDispatch();
	const dispatchLoadAllCities = React.useCallback(() => dispatch(loadAllCities()), [dispatch]);

	React.useEffect(() => {
		if (!allData && id) {
			dispatchLoadAllCities();
		}
	}, [allData, id, dispatchLoadAllCities]);

	const city = React.useMemo(() => {
		if (!allData) {
			return undefined;
		} else {
			return allData?.find(e => e.cityId === id);
		}
	}, [id, allData]);

	return [city] as [typeof city];
};
