import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { setIndex } from "../annualSecurityUXSlice";
import { PageIndex } from "../../../../models";

export const useSecurityReportIndex = () => {
  const pageIndex = useSelector<StoreType, PageIndex>(
    (s) => s.ux.reports.securityCommission.annualSecurity.index
  );

  const dispatch = useDispatch();
  const dispatchSetIndex = React.useCallback(
    (index: PageIndex) => dispatch(setIndex(index)),
    [dispatch]
  );

  return [pageIndex, dispatchSetIndex] as [
    typeof pageIndex,
    typeof dispatchSetIndex
  ];
};
