import {  setReportYear, setReportQuarter } from "../quarterlySecurityUXSlice";
import { setData } from "../quarterlySecurityDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import React from "react";
import { QuarterlySecurityReportFilter } from "../types";

export const useCurrentTradesReportFilter = () => {
	const filter = useSelector<StoreType, QuarterlySecurityReportFilter>(
		store => store.ux.reports.securityCommission.quarterlylSecurity.filter
	);
	const dispatch = useDispatch();

	const dispatchSetReportYear = React.useCallback(
		(year: number) => {
			dispatch(setReportYear(year));
			dispatch(setData());
		},
		[dispatch]
	);

	const dispatchSetReportQuarter = React.useCallback(
		(qtnumber: number) => {
			dispatch(setReportQuarter(qtnumber));
			dispatch(setData());
		},
		[dispatch]
	);

	return [filter,  dispatchSetReportYear, dispatchSetReportQuarter] as [
		typeof filter,
		typeof dispatchSetReportYear,
		typeof dispatchSetReportQuarter
	];
};
