import { ThunkType } from "../../../../core/store/reducers";
import deletePayTypeApi from "../api/deletePayType";
import { setFetchState } from "../payTypesDataReducer";

export const deletePayType = (paytypeId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = await dispatch(deletePayTypeApi(userId, paytypeId));

  dispatch(setFetchState(result !== undefined ? { type: "successful" } : { type: "error"}));
};
