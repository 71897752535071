import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TradeFilter } from "../../trading/trades/types";
import { AnnualTradingReportFilter } from "./types";
import { PageIndex } from "../../models";

type TradesReportState = {
  filter: AnnualTradingReportFilter;
  index: PageIndex;
};

export const initialState: TradesReportState = {
  filter: {
    numofyears: 10,
  },
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  }
};

const name = "annualTrade";

const annualTrade = createSlice({
  name,
  initialState,
  reducers: {
    setIndex: (state, action: PayloadAction<PageIndex>) => ({
			...state,
			index: action.payload,
		}),
    setReportData: (state, action: PayloadAction<number>) => ({
      ...state,
      filter: {
        numofyears: action.payload,
      },
    }),
    setReportYear: (state, action: PayloadAction<number>) => ({
      ...state,
      filter: {
        ...state.filter,
        year: action.payload,
      },
    }),
    setFilter: (state, action: PayloadAction<number>) => ({
      ...state,
      filter: {
        ...state.filter,
        numofyears: action.payload,
      },
    }),
    removeFilter: (state, action: PayloadAction<keyof TradeFilter>) => ({
      ...state,
      filter: {
        ...state.filter,
        [action.payload]: undefined,
      },
    }),
  },
});

export const {
  setReportData,
  setReportYear,
  setFilter,
  removeFilter,
  setIndex,
} = annualTrade.actions;

export default annualTrade.reducer;
