import React from "react";
import { DeliveryListData } from "../../../../../data/clearing/delivery/types";
import { PaginationTable } from "../../../../components/common/PagginationTable";
import { useDeliveriesColumns } from "./useDeliveriesColumns";
import { useClientOverviewIndex } from "../../../../../data/reports/exchbusiness/clientOverview/hooks/useClientOverviewIndex";
import { useDeliveryData } from "../../../../../data/reports/exchbusiness/clientOverview/hooks/useDeliveryData";
import { TableType } from "../../../../../data/reports/exchbusiness/clientOverview/types";
import { PageDataStatus } from "../../../../../data/models";
import { useDeliveriesActions } from "./useDeliveriesActions";

const ClientOverviewDeliveriesTable: React.FunctionComponent = () => {
	const columns = useDeliveriesColumns();
	const actions = useDeliveriesActions()

	const [data, loadData] = useDeliveryData();
	const [index, setIndex] = useClientOverviewIndex(TableType.Deliveries);

	React.useEffect(() => {
		if (data && data.status === PageDataStatus.DataInvalidated) {
			loadData();
		}
	}, [loadData, data]);


	return (
		<PaginationTable<DeliveryListData>
			getData={loadData}
			pageData={data}
			onPageIndexChange={setIndex}
			pageIndex={index}
			title=""
			columns={columns}
			actions={actions}
			options={{
				actionsColumnIndex: -1,
				search: false,
				toolbar: false,
			}}
		/>
	);
};

export default ClientOverviewDeliveriesTable;
