import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";
import { GetTradesParams, TradeFilter } from "../../../trading/trades/types";

export const getTradeBookReport = (
	user: User,
	filter?: TradeFilter,
) => {
	const params: GetTradesParams = {
		userid: user.userId,
		prodid: filter?.product?.prodId,
		status: filter?.tradeStatus?.ftValue,
		datefrom: getParamsDateString(filter?.datefrom),
		dateto: getParamsDateString(filter?.dateto),
		market: filter?.market,
		allTrades: "Y",
		};

	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/gettradebookreport",
		params,
	});
};

export default getTradeBookReport;
