import React from "react";
import { Grid, CardContent, CardHeader, Divider, Card, Fab } from "@material-ui/core";
import ProductTable from "./ProductTable";
import { usePageStyles } from "../../pageStyles";
import { SearchBox } from "../../components/common/SearchBox";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import AddIcon from "@material-ui/icons/Add";
import { useProductSearch } from "../../../data/listing/products/hooks/useProductSearch";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../../components/common/PageTitle";
import { getProductEditLocation } from "./Locations"

const ProductPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [search, setSearch] = useProductSearch();
	const history = useHistory();

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader
						title={<PageTitle title="productTitle" />}
						action={<SearchBox search={search} setSearch={setSearch} />}
					/>
					<Divider />
					<CardContent>
						<ProductTable userAccessRights={userAccessRights} />
					</CardContent>
				</Card>
				{userAccessRights === AccessRights.All && (
					<Fab
						color="secondary"
						aria-label="add"
						className={pageStyles.mainFab}
						onClick={() => history.push(getProductEditLocation())}
					>
						<AddIcon />
					</Fab>
				)}
			</Grid>
		</Grid>
	);
};

export default ProductPage;
