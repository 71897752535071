import { webApi } from "../../../../../core/http/httpThunks";
import { ClientAccountList } from "../../types";

export const getClientAccountsApi = (userId: string, cliid: string) =>
	webApi<ClientAccountList[]>({
		method: "GET",
		url: "listing/getclimonacclist",
		params: {
			userId,
			cliid
		},
	});

export default getClientAccountsApi;
