import React from "react";
import { useEditCtrlGoodState } from "../../../data/clearing/ctrlgoods/hooks/useEditCtrlGoodsState";
import { useCtrlGoodsValidator } from "../../../data/clearing/ctrlgoods/hooks/useCtrlGoodsValidator";
import { useCtrlGoodUpsert } from "../../../data/clearing/ctrlgoods/hooks/useCtrlGoodUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { CtrlGoodsData } from "../../../data/clearing/ctrlgoods/types";
import { StringInput } from "../../components/common/StringInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { Status } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { ctrlGoodsLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { DateInput } from "../../components/common/DateInput";
import { ProductSelect } from "../../components/listing/ProductSelect";
import { CheckPointSelect } from "../../components/common/CheckPointSelect";
import { CheckPointPayerSelect } from "../../components/common/CheckPointPayerSelect";
import { CheckDoneBySelect } from "../../components/common/CheckDoneBySelect";
import { TradesSelect } from "../../components/trading/TradesSelect";
import { QualityCtrlListSelect } from "../../components/common/QualityCtrlListSelect";

export interface EditCtrlGoodsInfo {
	ctrlId?: string;
}

export interface EditCtrlGoodsProps extends RouteComponentProps<EditCtrlGoodsInfo> {}

export const EditCtrlGoodsPage: React.FunctionComponent<EditCtrlGoodsProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [ctrlGoods, editCtrlGoods, changed] = useEditCtrlGoodState(props.match.params.ctrlId);
	const [validationState, validate] = useCtrlGoodsValidator();
	const [fetchState, upsert] = useCtrlGoodUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editCtrlGoods(event.target.name as any, event.target.value);
		},
		[editCtrlGoods]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(ctrlGoodsLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(ctrlGoods);

			if (normalized) {
				upsert(normalized);
			}
		}
	}, [ctrlGoods, validate, fetchState, history, upsert]);

	const pageTitle = ctrlGoods && ctrlGoods.controlId ? "crtlGoodsEdit" : "ctrlGoodsCreate";

	if (!ctrlGoods) {
		return null;
	}
	
	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={ctrlGoodsLocation} />} />
					<Divider />
					<CardContent>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={3}>
								<DateInput
									label={getString("ctrlcontrolDate")}
									value={ctrlGoods.controlDate || null}
									onChange={date => editCtrlGoods("controlDate", date)}
									error={
										validationState.controlDate !== undefined &&
										!validationState.controlDate
									}
									required={validationState.controlDate !== undefined}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={4}>
								<TradesSelect
									label={getString("ctrltradeNo")}
									predicate={trade => trade.tradeId === ctrlGoods.tradeId}
									onChangeItem={trade => {
										editCtrlGoods("tradeId", trade?.tradeId);
										editCtrlGoods("productId", trade?.productId);
									}}
									onSellerChange={seller =>
										seller?.cliId !== ctrlGoods.sellerId &&
										editCtrlGoods("sellerId", seller?.cliId)
									}
									onBuyerChange={buyer =>
										buyer?.cliId !== ctrlGoods.buyerId &&
										editCtrlGoods("buyerId", buyer?.cliId)
									}
									required={validationState.tradeId !== undefined}
									error={validationState.tradeId !== undefined && !validationState.tradeId}
//									filter={{}}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={4}>
								<ClientSelect
									label={getString("ctrlsellerName")}
									predicate={client => client.cliId === ctrlGoods.sellerId}
									onChangeItem={client =>
										editCtrlGoods("sellerId", client ? client.cliId : undefined)
									}
									required={validationState.sellerId !== undefined}
									error={
										validationState.sellerId !== undefined && !validationState.sellerId
									}
									disabled
								/>
							</Grid>
							<Grid item xs={4}>
								<ClientSelect
									label={getString("ctrlbuyerName")}
									predicate={client => client.cliId === ctrlGoods.buyerId}
									onChangeItem={client =>
										editCtrlGoods("buyerId", client ? client.cliId : undefined)
									}
									required={validationState.buyerId !== undefined}
									error={validationState.buyerId !== undefined && !validationState.buyerId}
									disabled
								/>
							</Grid>
							<Grid item xs={3}>
								<ProductSelect
									label={getString("orderProduct")}
									predicate={prod => prod.prodId === ctrlGoods.productId}
									onChangeItem={prod => {
										editCtrlGoods("productId", prod ? prod.prodId : undefined);
									}}
									required={validationState.productId !== undefined}
									error={
										validationState.productId !== undefined && !validationState.productId
									}
									disabled
								/>
							</Grid>
						</Grid>

						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={3}>
								<CheckPointSelect
									label={getString("orderCheckPoint")}
									predicate={checkPoint => checkPoint.ftValue === ctrlGoods.checkPoint}
									onChangeItem={checkPoint => {
										editCtrlGoods(
											"checkPoint",
											checkPoint ? checkPoint.ftValue : undefined
										);
									}}
									required={validationState.checkPoint !== undefined}
									error={
										validationState.checkPoint !== undefined &&
										!validationState.checkPoint
									}
								/>
							</Grid>

							<Grid item xs={3}>
								<CheckPointPayerSelect
									label={getString("ctrlcheckPaysDescr")}
									predicate={checkPoint => checkPoint.ftValue === ctrlGoods.checkPays}
									onChangeItem={checkPoint => {
										editCtrlGoods(
											"checkPays",
											checkPoint ? checkPoint.ftValue : undefined
										);
									}}
									required={validationState.checkPoint !== undefined}
									error={
										validationState.checkPoint !== undefined &&
										!validationState.checkPoint
									}
								/>
							</Grid>
							<Grid item xs={3}>
								<CheckDoneBySelect
									label={getString("ctrlcheckDoneByDescr")}
									predicate={checkDoneByList =>
										checkDoneByList.ftValue === ctrlGoods.checkDoneBy
									}
									onChangeItem={checkDoneByList => {
										editCtrlGoods(
											"checkDoneBy",
											checkDoneByList ? checkDoneByList.ftValue : undefined
										);
									}}
									required={validationState.checkDoneBy !== undefined}
									error={
										validationState.checkDoneBy !== undefined &&
										!validationState.checkDoneBy
									}
								/>
							</Grid>
						</Grid>

						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={4}>
								<StringInput<CtrlGoodsData>
									property="controlHouse"
									item={ctrlGoods}
									validationState={validationState}
									onChange={handleChange}
									label={getString("ctrlcontrolHouse")}
								/>
							</Grid>
						</Grid>

						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={2}>
								<BoolInput<CtrlGoodsData>
									property="controlReport"
									item={ctrlGoods}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("ctrlcontrolReport")}
								/>
							</Grid>

							<Grid item xs={4}>
								<StringInput<CtrlGoodsData>
									property="controlReportText"
									item={ctrlGoods}
									validationState={validationState}
									onChange={handleChange}
									label={getString("ctrlcontrolReportText")}
								/>
							</Grid>
						</Grid>

						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
						<Grid item xs={3}>
								<QualityCtrlListSelect
                 			 		label={getString("ctrlstatus")}
                  					predicate={(type) => type.ftValue === ctrlGoods.status}
                  					onChangeItem={(type) => editCtrlGoods("status", type?.ftValue)}
                  					required={validationState.status !== undefined}
                  					error={validationState.status !== undefined && !validationState.status}
                				/>
							</Grid>
						</Grid>

						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
