import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { TradingDataFilter  } from "../types";
import { getParamsDateString } from "../../../../core/util/functions";

export const getRefPriceReport = (user: User, filter: TradingDataFilter) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "reporting/getprodtradingdatareport",
    params: {
      userid: user.userId,
      prodid: filter.product?.prodId ,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      },
  });
};

export default getRefPriceReport;
