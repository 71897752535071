import React from "react";
import { Typography, Grid, IconButton } from "@material-ui/core";
import { LocalizationContext } from "../../../core/localization/Localization";
import { LocalizationKey } from "../../../core/localization/types";
import BackIcon from "@material-ui/icons/ArrowBackOutlined";
import { useHistory } from "react-router-dom";

export interface PageTitleProps {
	title: LocalizationKey;
	backLocation?: string;
	subtitle?: string;
}

export const PageTitle: React.FunctionComponent<PageTitleProps> = ({ title, backLocation, subtitle }) => {
	const { getString } = React.useContext(LocalizationContext);
	const history = useHistory();

	const goBack = React.useCallback(() => {
		if (backLocation) {
			history.push(backLocation);
		}
	}, [backLocation, history]);

	const mainTitle = getString(title)
	const pageTitle = subtitle ? `${mainTitle}: ${subtitle}` : mainTitle
	return (
		<Grid container direction="row" alignItems="baseline" spacing={1}>
			{backLocation && (
				<Grid item>
					<IconButton size="small" onClick={goBack}>
						<BackIcon />
					</IconButton>
				</Grid>
			)}
			<Grid item>
				<Typography variant="h5">{pageTitle}</Typography>
			</Grid>
		</Grid>
	);
};
