import { webApi } from "../../../../core/http/httpThunks";
import { UserData } from "../types";

export const getUserApi = (userId: string, usrid: string) =>
	webApi<UserData>({
		method: "GET",
		url: "listing/getuser",
		params: {
			userId,
			usrid
		},
	});

export default getUserApi;
