import { NavigationItem } from "../../../core/navigation/types";
import { EditCountryPage } from "./EditCountryPage";
import CountriesPage from "./CountriesPage";
import { countriesLocation, editRouter } from "./Locations";
export const countriesComponents: NavigationItem[] = [
	{
		Component: CountriesPage,
		isMenuItem: true,
		path: countriesLocation,
	},
	{
		Component: EditCountryPage,
		isMenuItem: false,
		path: editRouter,
	},
];
