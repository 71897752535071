import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { CommissionType } from "../../models";

const getTypeLabel = (type?: CommissionType) => (type ? type.ftDescr : "");

export const useCommissionTypes = () => {
	const types = useSelector<StoreType, CommissionType[]>(store => store.data.initialData.commisionTypes);

	return [types, getTypeLabel] as [typeof types, typeof getTypeLabel];
};
