import React from "react";
import { useExchangeMemberColumns } from "./useExchangeMembersReportColumns";
import { AccessRights } from "../../../core/navigation/types";
import { ExchangeMembersReportData } from "../../../data/reports/exmember/types";
import { ControledTable } from "../../components/common/ControledTable";
import { useExchangeMembersReportData } from "../../../data/reports/exmember/hooks/useExchangeMembersReportData";
import { useExchangeMembersIndex } from "../../../data/reports/exmember/hooks/useExchangeMembersIndex";

export interface ExchangeMembersTableProps {
  userAccessRights: AccessRights;
}

type ExchangeMembersTablePropsType = ExchangeMembersTableProps

const ExchangeMembersReportTable: React.FunctionComponent<ExchangeMembersTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = useExchangeMemberColumns();
  const [data, load] = useExchangeMembersReportData();
  const [index, setIndex] = useExchangeMembersIndex()

  return (
    <ControledTable<ExchangeMembersReportData>
      {...tableProps}
      getData={load}
      pageIndex={index}
      onPageIndexChange={setIndex}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default ExchangeMembersReportTable;
