import React from "react";
import { DeliveryData } from "../../../data/clearing/delivery/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayDeliveryMainAttributesProps {
  delivery: DeliveryData;
}

export const DisplayDeliveryMainAttributes: React.FunctionComponent<DisplayDeliveryMainAttributesProps> = ({
  delivery,
}) => {
  const style = useDisplaySingleEmementStyles();
  const { getString, getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dlvrDeliveryDate")}</span>
        <span className={style.value}>{getDateString(delivery.deliveryDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dlvrTradeNo")}</span>
        <span className={style.value}>{delivery.tradeNo}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dlvrsellerCode")}</span>
        <span className={style.value}>{delivery.sellerCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dlvrsellerName")}</span>
        <span className={style.value}>{delivery.sellerName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dlvrbuyerCode")}</span>
        <span className={style.value}>{delivery.buyerCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dlvrbuyerName")}</span>
        <span className={style.value}>{delivery.buyerName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dlvrproductCode")}</span>
        <span className={style.value}>{delivery.productCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dlvrproductName")}</span>
        <span className={style.value}>{delivery.productName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dlvrWarehouseCode")}</span>
        <span className={style.value}>{delivery.warehouseCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dlvrWarehouseName")}</span>
        <span className={style.value}>{delivery.warehouseName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("deliveryTradeQty")}</span>
        <span className={style.value}>{getFormatedNumberString(delivery.tradeQty)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dlvrDeliverQty")}</span>
        <span className={style.value}>{getFormatedNumberString(delivery.deliveryQty)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("deliveryLeftQty")}</span>
        <span className={style.value}>{getFormatedNumberString(delivery.leftQty)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradePriceWithVat")}</span>
{/*        <span className={style.label}>{getString("orderPrice")}</span> */}
        <span className={style.value}>{getFormatedNumberString(delivery.price, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dlvrValue")}</span>
        <span className={style.value}>{getFormatedNumberString(delivery.deliveryAmt, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("dlvrstatus")}</span>
        <span className={style.value}>
          <DisplayBoolNumber
            value={delivery.status}
            thrutyValue={Status.Active}
            className={style.icon}
          />
        </span>
      </div>
    </div>
  );
};
