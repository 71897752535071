import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllStockFees } from "../thunks/loadAllStockFees";
import { StockFee } from "../types";

export const useStockFeeData = () => {
  const data = useSelector<StoreType, StockFee[] | undefined>(
    (s) => s.data.clearing.stockfees.displayData
  );
  const allData = useSelector<StoreType, StockFee[] | undefined>(
    (s) => s.data.clearing.stockfees.all
  );

  const dispatch = useDispatch();
  const dispatchLoadAllStockFees = React.useCallback(
    () => dispatch(loadAllStockFees()),
    [dispatch]
  );

  return [data, dispatchLoadAllStockFees, allData] as [
    typeof data,
    typeof dispatchLoadAllStockFees,
    typeof allData
  ];
};

export const useStockFee = (id?: number) => {
  const allData = useSelector<StoreType, StockFee[] | undefined>(
    (s) => s.data.clearing.stockfees.all
  );

  const dispatch = useDispatch();
  const dispatchLoadAllStockFees = React.useCallback(
    () => dispatch(loadAllStockFees()),
    [dispatch]
  );

  React.useEffect(() => {
    if (!allData && id) {
      dispatchLoadAllStockFees();
    }
  }, [allData, id, dispatchLoadAllStockFees]);

  const stfee = React.useMemo(() => {
    if (!allData) {
      return undefined;
    } else {
      return allData?.find((e) => e.id === id);
    }
  }, [id, allData]);

  return [stfee] as [typeof stfee];
};
