import React from "react";
import { usePageStyles } from "../../../pageStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent, Tabs, Tab } from "@material-ui/core";
import ClientOverviewDeliveriesTable from "./clientOverviewDeliveries/ClientOverviewDeliveriesTable";
import ClientOverviewOrdersTable from "./clientOverviewOrders/ClientOverviewOrdersTable";
import ClientOverviewPaymentsTable from "./clientOverviewPayments/ClientOverviewPaymentsTable";
import ClientOverviewTradesTable from "./clientOverviewTrades/ClientOverviewTradesTable";
import { PageTitle } from "../../../components/common/PageTitle";
import { ClientOverviewParts } from "./Locations";
import { ClientOverviewFilterButton } from "./ClientOverviewFilter";
import { PageComponent } from "../../../../core/navigation/types";
import { useCurrentClientOverviewFilter } from "../../../../data/reports/exchbusiness/clientOverview/hooks/useClientOverviewFilter";
import { useInvlidateData } from "../../../../data/reports/exchbusiness/clientOverview/hooks/useInvlidateData";
import { DownloadOrderButton, DownloadOrderButtonExcel } from "./clientOverviewOrders/DownloadOrderButton";
import { DownloadTradesButton, DownloadTradesButtonExcel } from "./clientOverviewTrades/DownloadTradesButton";
import { DownloadPaymentsButton, DownloadPaymentsButtonExcel } from "./clientOverviewPayments/DownloadPaymentsButton";
import { DownloadDeliveryButton, DownloadDeliveryButtonExcel } from "./clientOverviewDeliveries/DownloadDeliveryButton";

export const ClientOverviewPage: PageComponent = () => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();
	const [tab, setTab] = React.useState(ClientOverviewParts.Orders);

	const [filter] = useCurrentClientOverviewFilter();
	const client = filter?.client;

	const handleSideChange = React.useCallback(
		(event: React.ChangeEvent<{}>, newValue: any) => {
			setTab(newValue);
		},
		[setTab]
	);

	const [invalidate] = useInvlidateData();
	React.useEffect(
		() => () => {
			invalidate();
		},
		[]
	);

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title="clientCardTitle" />} action={<ClientOverviewActions tab={client ? tab : undefined} />} />
					<Divider />
					<CardContent>
						<Tabs
							value={tab}
							onChange={handleSideChange}
							indicatorColor="secondary"
							textColor="secondary"
							variant="fullWidth"
						>
							<Tab
								label={getString("listingOrdersTitle")}
								value={ClientOverviewParts.Orders}
								disabled={!client || !client.cliId}
							/>
							<Tab
								label={getString("navTransactions")}
								value={ClientOverviewParts.Trades}
								disabled={!client || !client.cliId}
							/>
							<Tab
								label={getString("paymentTitle")}
								value={ClientOverviewParts.Payments}
								disabled={!client || !client.cliId}
							/>
							<Tab
								label={getString("dlvrDiliveryTitle")}
								value={ClientOverviewParts.Deliveries}
								disabled={!client || !client.cliId}
							/>
						</Tabs>
						<div style={{ marginTop: "20px" }}>
							{tab === ClientOverviewParts.Orders && client && client.cliId && (
								<ClientOverviewOrdersTable />
							)}
							{tab === ClientOverviewParts.Trades && client && client.cliId && (
								<ClientOverviewTradesTable />
							)}
							{tab === ClientOverviewParts.Payments && client && client.cliId && (
								<ClientOverviewPaymentsTable />
							)}
							{tab === ClientOverviewParts.Deliveries && client && client.cliId && (
								<ClientOverviewDeliveriesTable />
							)}
						</div>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export const ClientOverviewActions: React.FunctionComponent<{tab?: ClientOverviewParts}> = ({ tab }) => (
	<Grid container spacing={4}>
		{tab === ClientOverviewParts.Orders && (
			<Grid item>
				<DownloadOrderButton />
				<DownloadOrderButtonExcel />
			</Grid>
		)}
		{tab === ClientOverviewParts.Trades && (
			<Grid item>
				<DownloadTradesButton />
				<DownloadTradesButtonExcel />
			</Grid>
		)}
		{tab === ClientOverviewParts.Payments && (
			<Grid item>
				<DownloadPaymentsButton />
				<DownloadPaymentsButtonExcel />
			</Grid>
		)}
		{tab === ClientOverviewParts.Deliveries && (
			<Grid item>
				<DownloadDeliveryButton />
				<DownloadDeliveryButtonExcel />
			</Grid>
		)}
		<Grid item>
			<ClientOverviewFilterButton tab={tab} />
		</Grid>
	</Grid>
);
