import { ThunkType } from "../../../../core/store/reducers";
import getAllRegionApi from "../api/getAllRegion";
import { setData } from "../regionsDataReducer";
import { RegType } from "../../../models";

export const loadAllRegions = (type: RegType): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(
		setData({
			type,
			data: undefined,
		})
	);

	const { userId } = store.data.auth.current.user;
	const regions = await dispatch(getAllRegionApi(userId, type));

	dispatch(
		setData({
			type,
			data: regions || [],
		})
	);
};
