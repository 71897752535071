import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { AlarmData } from "../../../data/surveillance/alarmParameters/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewAlarm } from "./DisplayAlarm";
import { useHistory } from "react-router-dom";
import { getAlarmsEditLocation } from "./Locations";
import { DisplayAlarmMainAttributes } from "./DisplayAlarmMainAttributes";
import { useAlarmDelete } from "../../../data/surveillance/alarmParameters/hooks/useAlarmDelete";

export function useAlarmsActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowAlarm = useViewAlarm();

  return useMemo(() => {
    const result: MaterialTableProps<AlarmData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) =>
        onShowAlarm(rowData as AlarmData),
      },
    ];
    return result;
  }, [onShowAlarm, getString]);
}

export const useDeleteAlarmAction = () => {
  const [, deleteAlarm] = useAlarmDelete();
  return React.useCallback(
    async (alarm: AlarmData) => {
      if (alarm.almId) {
        await deleteAlarm(alarm.almId);
      }
    },
    [deleteAlarm]
  );
};

export const useEditAlarmsAction = () => {
  const history = useHistory();
  return React.useCallback(
    (alarm: AlarmData) =>
      history.push(getAlarmsEditLocation(alarm.almId)),
    [history]
  );
};
