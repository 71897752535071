import { Warehouse } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../models";

interface IWarehouseData {
  all?: Warehouse[];
  searchTerm: string;
  displayData?: Warehouse[];
  displayedWarehouse?: Warehouse;
  fetchState: FetchStateType;
}

const initialState: IWarehouseData = {
  searchTerm: "",
  fetchState: { type: "not-started" },
};

const name = "warehouses";

const warehousesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Warehouse[] | undefined>) => ({
      ...state,
      all: action.payload,
      displayData: filterData(action.payload, state.searchTerm),
    }),
    setSearchTerm: (state, action: PayloadAction<string>) => ({
      ...state,
      searchTerm: action.payload.toLocaleLowerCase(),
      displayData: filterData(state.all, action.payload),
    }),
    setDisplayed: (state, action: PayloadAction<Warehouse | undefined>) => ({
      ...state,
      displayedWarehouse: action.payload,
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      displayedWarehouse:
        action.payload.type === "successful" ? undefined : state.displayedWarehouse,
      all: action.payload.type === "successful" ? undefined : state.all,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: action.payload,
    }),
  },
});

const filterData = (data?: Warehouse[], searchTerm?: string) =>
  searchTerm === undefined || searchTerm === ""
    ? data
    : data?.filter(
        (e) =>
          (e.whsCode &&
            e.whsCode
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
          (e.whsName &&
            e.whsName
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase()))
      );

export const {
  setData,
  setSearchTerm,
  setDisplayed,
  setFetchState,
} = warehousesSlice.actions;

export default warehousesSlice.reducer;
