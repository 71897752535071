import { ThunkType } from "../../../../core/store/reducers";
import { getTradesSimpleData } from "../api/getTradesSimpleData";
import { TradeSimpleFilter, TradesSimpleData } from "../types";

export const getSimpleTradesThunk = (
	filter: TradeSimpleFilter
): ThunkType<Promise<TradesSimpleData[] | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const user = store.data.auth.current.user;
	const ordersResponse = await dispatch(getTradesSimpleData(user.userId, filter));

	if (!ordersResponse || ordersResponse.resCode !== "OK") {
		return undefined;
	}

	return ordersResponse.obj;
};
