import React from "react";
import { ClientPerson } from "../../../../data/listing/clients/types";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../../components/common/DisplayBoolNumber";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { useClientPersons } from "../../../../data/listing/clients/hooks/clientPerson/useClientPersons";
import {
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	TableBody,
	Typography,
	Grid,
} from "@material-ui/core";
import { Status } from "../../../../data/models";

export interface DisplayClientPersonsProps {
	clientId: string;
}

export const DisplayPersons: React.FunctionComponent<DisplayClientPersonsProps> = ({ clientId }) => {
	const style = useDisplaySingleEmementStyles();
	const [persons] = useClientPersons(clientId);

	return (
		<div className={style.root}>
			<div className={style.row}>
				{persons ? <ClientPersonDisplayTable persons={persons} /> : "loading placeholder"}
			</div>
		</div>
	);
};

interface ClientPersonDisplayTableProps {
	persons: ClientPerson[];
}

const ClientPersonDisplayTable: React.FunctionComponent<ClientPersonDisplayTableProps> = ({ persons }) => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);

	if (persons.length === 0) {
		return (
			<Grid container justify="center">
				<Typography>{getString("clientPersonEmpty")}</Typography>
			</Grid>
		);
	}

	return (
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>{getString("clientPersonName")}</TableCell>
{/*						<TableCell>{getString("clientPersonIDNumber")}</TableCell>   */}
						<TableCell>{getString("clientPersonRole")}</TableCell>
						<TableCell>{getString("clientPersonPhone")}</TableCell>
{/*						<TableCell>{getString("clientPersonEmail")}</TableCell>  */}
						<TableCell>{getString("clientPersonStatus")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{persons.map(person => (
						<TableRow key={person.cliPrsId}>
							<TableCell>{person.cliPrsName}</TableCell>
{/*							<TableCell>{person.cliPrsIdNo}</TableCell>   */}
							<TableCell>{person.cliPrsRoleName}</TableCell>
							<TableCell>{person.phone}</TableCell>
{/*							<TableCell>{person.email}</TableCell>   */}
							<TableCell>
								<DisplayBoolNumber
									value={person.status}
									thrutyValue={Status.Active}
									className={style.icon}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
