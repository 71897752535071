import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";
import { ClientOverviewFilter } from "../types";

export const getTradeBookExcel = (user: User, filter?: ClientOverviewFilter) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/gettradebookxls",
		params: {
			userid: user.userId,
			clientid: filter?.client?.cliId,
			status: filter?.tradeStatus?.ftValue,
			datefrom: getParamsDateString(filter?.datefrom),
			dateto: getParamsDateString(filter?.dateto),
		},
	});
};

export default getTradeBookExcel;
