import { BusinessActivitiesReportData } from "../../../../data/reports/exchbusiness/businessActivities/types";
import React from "react";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Column } from "material-table";

export const useBusinessActivitiesColumns = () => {
  const { getString, getFormatedNumberString } = React.useContext(
    LocalizationContext
  );

  return React.useMemo(
    () => [
      {
        title: getString("exchDate"),
        field: "date",
        sorting: false,
      },
      {
        title: getString("exchTradeNumber"),
        field: "tradeNumber",
        sorting: false,
      },
      {
        title: getString("exchTurnoverValue"),
        render: (row) => `${getFormatedNumberString(row.turnoverValue)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchBuyersCommission"),
        render: (row) =>
          `${getFormatedNumberString(row.buyersCommission, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchSellersCommission"),
        render: (row) =>
          `${getFormatedNumberString(row.sellersCommission, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchBuyerBroker"),
        field: "buyersBroker",
        sorting: false,
      },
      {
        title: getString("exchSellerBroker"),
        field: "sellersBroker",
        sorting: false,
      },
    ],
    [getString, getFormatedNumberString]
  ) as Column<BusinessActivitiesReportData>[];
};
