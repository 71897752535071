import { NavigationItem } from "../../../core/navigation/types";
import PaymentsPage from "./PaymentsPage";
import { EditPaymentPage } from "./EditPaymentsPage";
import { paymentLocation, editRouter } from "./Locations";
export const paymentComponents: NavigationItem[] = [
  {
    Component: PaymentsPage,
    isMenuItem: true,
    path: paymentLocation,
  },
  {
    Component: EditPaymentPage,
    isMenuItem: false,
    path: editRouter,
  },
];
