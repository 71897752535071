import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { PersonRole } from "../../models";

const getRoleLabel = (role?: PersonRole) => (role ? role.ftDescr : "");

export const usePersonRoles = () => {
	const attributes = useSelector<StoreType, PersonRole[]>(
		store => store.data.initialData.personRoles
	);

	return [attributes, getRoleLabel] as [typeof attributes, typeof getRoleLabel];
};
