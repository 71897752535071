import React from "react";
import { CtrlGoodsListData } from "../../../data/clearing/ctrlgoods/types";
import {
  PaginationTable,
  PaginationDataProps,
} from "../../components/common/PagginationTable";
import {
  useCtrlGoodsActions,
  useDeleteCtrlGoodsAction,
} from "./useCtrlGoodsActions";
import { useCtrlGoodsColumns } from "./useCtrlGoodsColumns";
import { AccessRights } from "../../../core/navigation/types";

export interface CtrlGoodsTableProps {
  userAccessRights: AccessRights;
  getData: () => void;
}

type CtrlGoodsTablePropsType = CtrlGoodsTableProps &
  PaginationDataProps<CtrlGoodsListData>;

const CtrlGoodsTable: React.FunctionComponent<CtrlGoodsTablePropsType> = ({
  userAccessRights,
  getData,
  ...tableProps
}) => {
  const actions = useCtrlGoodsActions();
  const columns = useCtrlGoodsColumns();
  const onDeleteCtrlGoods = useDeleteCtrlGoodsAction();

  return (
    <PaginationTable<CtrlGoodsListData>
      {...tableProps}
      getData={getData}
      //      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
      editable={{
        onRowDelete: onDeleteCtrlGoods,
      }}
    />
  );
};

export default CtrlGoodsTable;
