import { ThunkType } from "../../../../core/store/reducers";
import createBankApi from "../api/createBank";
import updateBankApi from "../api/updateBank";
import { setFetchState } from "../banksDataReducer";
import { Bank } from "../types";

export const upsertBank = (bank: Bank): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = bank.bankId
		? await dispatch(updateBankApi(userId, bank))
		: await dispatch(createBankApi(userId, bank));

	if (result && result.resCode === "OK") {
		dispatch(setFetchState({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
	}
};
