import { City, CityValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const cityValidationState: CityValidationState = {
	cityName: true,
	zipCode: true,
	regionId: true,
};

export const useCityValidator = createUseValidator<City, CityValidationState>(cityValidationState);
