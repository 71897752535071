import { NavigationItem } from "../../../core/navigation/types";
import CtrlGoodsPage from "./CtrlGoodsPage";
import { EditCtrlGoodsPage } from "./EditCtrlGoodsPage";
import { ctrlGoodsLocation, editRouter } from "./Locations";
export const ctrlGoodsComponents: NavigationItem[] = [
  {
    Component: CtrlGoodsPage,
    isMenuItem: true,
    path: ctrlGoodsLocation,
  },
  {
    Component: EditCtrlGoodsPage,
    isMenuItem: false,
    path: editRouter,
  },
];
