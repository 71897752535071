import { NavigationItem } from "../../../core/navigation/types";
import AlarmsPage from "./AlarmsPage";
import { EditAlarmsPage } from "./EditAlarmsPage";
import { alarmsLocation, editRouter } from "./Locations";
export const alarmsComponents: NavigationItem[] = [
  {
    Component: AlarmsPage,
    isMenuItem: true,
    path: alarmsLocation,
  },
  {
    Component: EditAlarmsPage,
    isMenuItem: false,
    path: editRouter,
  },
];
