import MemberContractsReport from "./MemberContractsReport";
import { NavigationItem } from "../../../../core/navigation/types";
import { memberContractsReportLocation } from "./Locations";

export const memberContractsReportComponents: NavigationItem[] = [
  {
    Component: MemberContractsReport,
    isMenuItem: true,
    path: memberContractsReportLocation,
  },
];
