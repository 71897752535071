import DailySecurityReport from "./DailySecurityReport";
import { NavigationItem } from "../../../../core/navigation/types";
import { dailySecurityReportLocation, } from "./Locations";

export const dailySecurityReportComponents: NavigationItem[] = [
	{
		Component: DailySecurityReport,
		isMenuItem: true,
		path: dailySecurityReportLocation,
	},
];
