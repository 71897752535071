import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex, OrderFilter, OrderSide } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import { OrderSideDictionary, OrderSideArgs } from "../orders/types";

type OrderPageState = {
	index: OrderSideDictionary<PageIndex>;
	filter: OrderFilter;
};

export const initialState: OrderPageState = {
	index: {
		[OrderSide.Buy]: {
			itemsPerPage: 10,
			pageNumber: 0,
		},
		[OrderSide.Sell]: {
			itemsPerPage: 10,
			pageNumber: 0,
		},
	},
	filter: {
		orderStatus: {
			ftValue: -1,
			ftDescr: "",
		}
	},
};

const name = "orders";

const ordersSlice = createSlice({
	name,
	initialState,
	reducers: {
		setOrdersIndex: (state, action: PayloadAction<OrderSideArgs<PageIndex>>) => ({
			...state,
			index: {
				...state.index,
				[action.payload.side]: action.payload.value,
			},
		}),
		resetOrderIdices: (state, action: PayloadAction<OrderSide>) => ({
			...state,
			index: {
				...state.index,
				[action.payload]: {
					itemsPerPage: state.index[action.payload].itemsPerPage,
					pageNumber: initialState.index[action.payload].pageNumber,
				},
			},
		}),
		setFilter: (state, action: PayloadAction<OrderFilter>) => ({
			...state,
			filter: action.payload,
		}),
		removeFilter: (state, action: PayloadAction<keyof OrderFilter>) => ({
			...state,
			filter: {
				...state.filter,
				[action.payload]: undefined,
			},
		}),
	},
	extraReducers: builder => {
		builder.addCase(successfulLogin, state => ({
			...state,
			index: {
				[OrderSide.Buy]: {
					itemsPerPage: state.index[OrderSide.Buy].itemsPerPage,
					pageNumber: initialState.index[OrderSide.Buy].pageNumber,
				},
				[OrderSide.Sell]: {
					itemsPerPage: state.index[OrderSide.Sell].itemsPerPage,
					pageNumber: initialState.index[OrderSide.Sell].pageNumber,
				},
			},
		}));
	},
});

export const { setOrdersIndex, resetOrderIdices, setFilter, removeFilter } = ordersSlice.actions;

export default ordersSlice.reducer;
