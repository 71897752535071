import React from "react";
import { useEditEmployeeState } from "../../../data/listing/employees/hooks/useEditEmployeeState";
import { useEmployeeValidator } from "../../../data/listing/employees/hooks/useEmployeeValidator";
import { useEmployeeUpsert } from "../../../data/listing/employees/hooks/useEmployeeUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { EmployeeGenderSelect } from "./EmployeeGenderSelect";
import { EmployeeCitySelect } from "./EmployeeCitySelect";
import { FabSave } from "../../components/common/FabSave";
import { Employee } from "../../../data/listing/employees/types";
import { DateInput } from "../../components/common/DateInput";
import { StringInput } from "../../components/common/StringInput";
import { BoolInput } from "../../components/common/BoolInput";
import { Status } from "../../../data/models";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { employeeLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";

export interface EditEmployeeInfo {
	employeeId?: string;
}

export interface EditEmployeeProps extends RouteComponentProps<EditEmployeeInfo> {}

export const EditEmployeePage: React.FunctionComponent<EditEmployeeProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [employee, editEmployee, changed] = useEditEmployeeState(props.match.params.employeeId);
	const [validationState, validate] = useEmployeeValidator();
	const [fetchState, upsert] = useEmployeeUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editEmployee(event.target.name as any, event.target.value);
		},
		[editEmployee]
	);

	const handleDateChange = React.useCallback(
		(date: Date | null) => {
			editEmployee("dateOfBirth", date);
		},
		[editEmployee]
	);

	const changeCity = React.useCallback(
		(city: string, zipCode: string) => {
			editEmployee("city", city);
			editEmployee("zipCode", zipCode);
		},
		[editEmployee]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(employeeLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(employee);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [employee, validate, fetchState, history, upsert]);

	const pageTitle = employee && employee.empId ? "empEditTitle" : "empCreateTitle";

	if (!employee) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={employeeLocation} />} />
					<Divider />
					<CardContent>
						<Grid container direction="column" justify="center" alignItems="center" spacing={4}>
							<Grid
								container
								item
								direction="row"
								justify="flex-start"
								alignItems="center"
								spacing={3}
							>
								<Grid item xs={3}>
									<StringInput<Employee>
										property="firstName"
										item={employee}
										validationState={validationState}
										onChange={handleChange}
										label={getString("empFirstName")}
									/>
								</Grid>
								<Grid item xs={2}>
									<StringInput<Employee>
										property="midName"
										item={employee}
										validationState={validationState}
										onChange={handleChange}
										label={getString("empMidName")}
									/>
								</Grid>
								<Grid item xs={3}>
									<StringInput<Employee>
										property="lastName"
										item={employee}
										validationState={validationState}
										onChange={handleChange}
										label={getString("empLastName")}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								item
								direction="row"
								justify="flex-start"
								alignItems="center"
								spacing={3}
							>
								<Grid item xs={3}>
									<StringInput<Employee>
										property="empIdNumber"
										item={employee}
										validationState={validationState}
										onChange={handleChange}
										label={getString("empIdNumber")}
									/>
								</Grid>
								<Grid item xs={2}>
									<EmployeeGenderSelect
										gender={employee.gender}
										handleChange={handleChange}
										error={!validationState.gender}
									/>
								</Grid>
								<Grid item xs={3}>
									<StringInput<Employee>
										property="email"
										item={employee}
										validationState={validationState}
										onChange={handleChange}
										label={getString("empEmail")}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								item
								direction="row"
								justify="flex-start"
								alignItems="center"
								spacing={3}
							>
								<Grid item xs={3}>
									<StringInput<Employee>
										property="address"
										item={employee}
										validationState={validationState}
										onChange={handleChange}
										label={getString("empAddress")}
									/>
								</Grid>
								<Grid item xs={2}>
									<EmployeeCitySelect employee={employee} changeCity={changeCity} />
								</Grid>
							</Grid>
							<Grid
								container
								item
								direction="row"
								justify="flex-start"
								alignItems="center"
								spacing={3}
							>
								<Grid item xs={2}>
									<DateInput
										label={getString("empDateOfBirth")}
										value={employee.dateOfBirth || null}
										onChange={handleDateChange}
										maxDate={new Date()}
									/>
								</Grid>
								<Grid item xs={3}>
									<StringInput<Employee>
										property="position"
										item={employee}
										validationState={validationState}
										onChange={handleChange}
										label={getString("empPosition")}
									/>
								</Grid>
								<Grid item xs={3}>
									<StringInput<Employee>
										property="branch"
										item={employee}
										validationState={validationState}
										onChange={handleChange}
										label={getString("empBranch")}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								item
								direction="row"
								justify="flex-start"
								alignItems="center"
								spacing={3}
							>
								<Grid item xs={3}>
									<StringInput<Employee>
										property="phone"
										item={employee}
										validationState={validationState}
										onChange={handleChange}
										label={getString("empPhone")}
									/>
								</Grid>
								<Grid item xs={2}>
									<StringInput<Employee>
										property="fax"
										item={employee}
										validationState={validationState}
										onChange={handleChange}
										label={getString("empFax")}
									/>
								</Grid>
								<Grid item xs={3}>
									<StringInput<Employee>
										property="phoneOffice"
										item={employee}
										validationState={validationState}
										onChange={handleChange}
										label={getString("empOfficePhone")}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								item
								direction="column"
								justify="center"
								alignItems="flex-start"
								spacing={3}
							>
								<Grid item xs={3}>
									<BoolInput<Employee>
										property="status"
										item={employee}
										onChange={handleChange}
										falsyValue={Status.Inactive}
										thrutyValue={Status.Active}
										label={getString("empStatus")}
									/>
								</Grid>
							</Grid>
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
