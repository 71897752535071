import {
  TradeByProductsReportData,
  TradeByProductsReportFilter,
} from "../types";
import { User } from "../../../../auth/types";
import { webApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

const getTradeByProductsData = (
  user: User,
  filter: TradeByProductsReportFilter
) => {
  return webApi<TradeByProductsReportData[]>({
    method: "GET",
    url: "reporting/gettradebyprodlist",
    params: {
      userid: user.userId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
    },
  });
};

export default getTradeByProductsData;
