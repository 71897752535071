import { getEditLocation } from "../../../../core/util/functions";

export enum ClientOverviewParts {
	Orders = "orders",
	Trades = "trades",
	Payments = "payments",
	Deliveries = "deliveries",
}

export const clientsOverviewLocation = "/reports/exchbusiness/clientoverview";

export const getClientsOverviewLocation = (
	part: ClientOverviewParts = ClientOverviewParts.Orders,
	clientId?: string,
	attributeId?: string
) => getEditLocation(clientsOverviewLocation, part, clientId, attributeId);

export const ordersView = getClientsOverviewLocation(ClientOverviewParts.Orders, ":clientId", ":orderId?");
export const tradesView = getClientsOverviewLocation(ClientOverviewParts.Trades, ":clientId", ":tradeId?");
export const paymentsView = getClientsOverviewLocation(ClientOverviewParts.Payments, ":clientId", ":paymentId?");
export const deliveriesView = getClientsOverviewLocation(ClientOverviewParts.Deliveries, ":clientId", ":deliveryId?");
