import { LocalizationLanguage } from "./types";

export const enTranslations = {
	language: LocalizationLanguage.EN as LocalizationLanguage,
	decimalSeparator: ".",
	momentLocale: "en",
	mainTitle: "NSCOMEX",
	tableNextPage: "Next page",
	tablePreviousPage: "Previous page",
	tableFirsPage: "First page",
	tableLastPage: "Last page",
	tableDisplayedRows: "{from}-{to} from {count}",
	tabledDisplayedPerPage: "rows",
	orderActView: "Show order",
	orderActReply: "Reply to order",
	orderWaitingProducts: "Please wait...",
	orderSideBuy: "BUY",
	orderSideSell: "SELL",
	listingOrdersTitle: "Orders",
	userOrdersTitle: "My orders",
	archiveOrdersTitle: "Archive",
	search: "Search",
	companyClmName: "Name",
	companyClmHeadquorters: "Headquaters",
	companyClmMemberFrom: "Member from",
	companyClmProvision: "Commission ",
	reporClmtName: "Name",
	reporTitle: "Reports",
	reporDownloadTtl: "Download",
	username: "Username",
	password: "Password",
	login: "Login",
	logout: "Logout",
	orderChooseProduct: "Choose product",
	noDataText: "No data",
	orderClmOrdNo: "Number",
	orderTimeReceived: "Time Recieved",
	orderOrdType: "Type",
	orderRefOrd: "Ref order",
	orderClientIdNo: "Client number",
	orderClientName: "Client name",
	orderProduct: "Product",
	orderYear: "Year",
	orderTradingPlace: "Trading place",
	orderFranco: "Franco",
	orderQty: "Quantity",
	orderAllOrNothing: "All or nothing",
	orderPrice: "Price",
	orderPriceEur: "Price (EUR)",
	orderPriceWithoutVat: "Price without VAT",
	orderPriceWithVat: "Price (VAT)",
	orderPayType: "Pay type",
	orderPayDays: "Payment days",
	orderFreeStorage: "Free storage",
	orderFreeStorageByDelivery: "By delivery",
	orderFreeStorageByDate: "By date:",
	orderCheckPoint: "Check point",
	orderCheckPays: "Pays check point",
	orderExpiryDate: "Expiry date",
	orderStatus: "Status",
	orderQuality: "Quality",
	numberOfDays: "Number of days",
	navListing: "Listing",
	navMyOrders: "My orders",
	navArchive: "Archive",
	navCompanies: "Companies",
	navMembers: "Members",
	navProducts: "Products",
	navBrokers: "Brokers",
	navEmployees: "Employees",
	navUsers: "Users",
	navRoles: "Roles",
	navCodebook: "Codebook",
	navCountries: "Countries",
	navTopRegion: "Region",
	navRegion: "District",
	navSubRegion: "SubRegion",
	navCities: "Cities",
	navCurrencies: "Currencies",
	navWarehouses: "Warehouses",
	navTrading: "Trading",
	navMainTable: "Main table",
	navOrders: "Orders",
	navTransactions: "Transactions",
	navMarketDepth: "Market Depth",
	navCliring: "Cliring",
	navDeposits: "Deposits",
	navMarginCalculations: "Margin calculations",
	navSurveillance: "Surveillance",
	navAlarmSetup: "Alarm setup",
	navProductReport: "Product report",
	navMemberReport: "Member report",
	navReports: "Reports",
	navDailyReports: "Daily reports",
	navWeeklyReports: "Weekly reports",
	navMonthlyReports: "Monthly reports",
	navQuaterlyReports: "Quaterly reports",
	navAnnualReports: "Annual reports",
	navSECReports: "SEC reports",
	navMembersReports: "Members reports",
	navOrdersReports: "Orders and trades",
	navAccounting: "Accounting",
	navTaxes: "Taxes and commissions",
	navInvoice: "Invoice generation",
	navAccountingExport: "Export for accounting",
	navMembersDeposit: "Members deposit",
	empFirstName: "First name",
	empLastName: "Last name",
	empIdNumber: "ID number",
	empOfficePhone: "Office phone",
	empEmail: "Email",
	empStatus: "Status",
	empMidName: "Middle name",
	empGender: "Gender",
	empAddress: "Address",
	empZipCode: "ZipCode",
	empCity: "City",
	empPhone: "Phone",
	empDateOfBirth: "Date of Birth",
	empPosition: "Position",
	empBranch: "Branch",
	empFax: "Fax",
	empShowTooltip: "Show",
	genderMale: "Male",
	genderFemale: "Female",
	statusActive: "Active",
	statusInactive: "Inactive",
	empEdit: "Edit",
	empDelete: "Delete",
	empEditTitle: "Edit broker",
	empCreateTitle: "Create broker",
	cliName: "Short name", //	naziv klijenta
	cliIdNo: "ID number", //	maticni broj klijenta
	cliTaxCode: "Tax code", //	PIB broj
	clijbkjs: "JBKJS",
	cliType: "Type",
	cliForeign: "Foreign",
	cliCity: "City",
	cliPhone: "Phone",
	cliBroker: "Broker",
	cliActive: "Membership",
	cliStatus: "Status",
	clilFullName: "Name",
	cliAddress: "Adress",
	cliMailCity: "Mail city",
	cliMailAddress: "Mail address",
	cliMobile: "Moblie phone",
	cliFax: "Fax",
	cliEmail: "Email",
	cliHasContract: "Has contract",
	cliActiveFrom: "Active from",
	cliActiveTo: "Acrive to",
	cliCommission: "Commission",
	cliCurrentTurnover: "Turnover",
	cliComment: "Comment",
	cilEditTitle: "Edit client",
	cilCreateTitle: "Create client",
	cilTitle: "Clients",
	tableConfirmationDelete: "Are you sure?",
	tableDelete: "Delete",
	tableAdd: "Add",
	tableEdit: "Edit",
	tableCancel: "Cancel",
	tableSave: "Save",
	roleCode: "Code",
	roleName: "Name SR",
	roleNameEn: "Name EN",
	tableShow: "Show",
	roleEdit: "Edit role",
	roleCreate: "Create role",
	roleNameColumn: "Name",
	roleIsEmployee: "Employee",
	userUserName: "User name",
	userLoginUser: "Login user",
	userLoginPass: "Password",
	userChangePass: "Change password",
	userRoleId: "Role",
	userIsEmployee: "Is employee",
	userEmpId: "Employee",
	userClientId: "Client",
	userCliPrsId: "Client person",
	userSubscribedUntil: "Subscribed until",
	userSubscrUnlimited: "Unlimited Subscription",
	userIsActive: "Active",
	userEditTitle: "Edit user",
	userCreateTitle: "Create user",
	tableBasciInfo: "Basic info",
	clientPersonTitle: "Persons",
	clientPersonName: "Name",
	clientPersonIDNumber: "ID Number",
	clientPersonStatus: "Status",
	clientPersonEmpty: "There are no persons",
	clientBrokerTitle: "Brokers",
	clientBrokerName: "Name",
	clientBrokerIDNumber: "ID Number",
	clientBrokerStatus: "Status",
	clientBrokerEmpty: "There are no brokers",
	contractTitle: "Contracts",
	contractNumber: "Number",
	contractDate: "Date",
	contractStatus: "Status",
	contractEmpty: "There are no contracts",
	contractLincedContrNo: "Linked contract",
	contractTypeDescription: "Type",
	contractDescription: "Description",
	contractPhoneOrder: "Phone number",
	contractWebUser: "Web user",
	contractWebUserBlocked: "Web user blocked",
	contractTitleEdit: "Edit Contract",
	contractTitleCreate: "Create Contract",
	accountTitle: "Accounts",
	accountTitleEdit: "Edit Accounts",
	accountTitleCreate: "Create Accounts",
	accountNumber: "Account number",
	accountBank: "Bank",
	accountDefault: "Default account",
	accountClientBankCode: "Client bank code",
	accountCurrency: "Currency",
	accountForBuySell: "Buy/Sell",
	accountStatus: "Status",
	accountSideBuy: "Buy",
	accountSideSell: "Sell",
	accountSideBoth: "Both",
	accountEmpty: "There are no accounts",
	invalidDateMessage: "Invalid Date Format",
	maxDateMessage: "Date should not be after maximal date",
	minDateMessage: "Date should not be before minimal date",
	clientPersonEditTitle: "Edit Person",
	clientPersonCreateTitle: "Create Person",
	clientBrokerEditTitle: "Edit Broker",
	clientBrokerCreateTitle: "Create Broker",
	leavePageTitle: "Leave this page?",
	leavePageMessage: "If you leave this page all changes will be discarded.",
	leavePageConfirm: "Leave page",
	leavePageCancel: "Stay",
	calendarOK: "OK",
	calendarCancel: "Cancel",
	calendarClear: "Clear",
	productName: "Name",
	productNameEN: "Name (EN)",
	productGroupName: "Group Name",
	productUnit: "Unit",
	productVat: "VAT",
	productHasYieldYear: "Yield year",
	productStatus: "Status",
	productTitle: "Products",
	productEditTitle: "Edit Product",
	productCreateTitle: "Create Product",
	productAttributeTitle: "Product Attributes",
	productAttributeEditTitle: "Edit Product Attribute",
	productAttributeCreateTitle: "Create Product Attribute",
	productAttributeName: "Name",
	productAttributeType: "Type",
	productAttributeManditory: "Manditory",
	productAttributeComparator: "Comparator",
	productAttributeStatus: "Status",
	productAttributeEmpty: "There are no attributes",
	productGroupNameSR: "Name (SR)",
	productGroupNameEN: "Name (EN)",
	productGroupStatus: "Status",
	productGroupTitle: "Product Groups",
	productGroupEditTitle: "Edit Product Group",
	productGroupCreateTitle: "Create Product Group",
	bankName: "Bank Name",
	bankCode: "Bank Code",
	bankIdNumber: "Bank Id",
	navBanks: "Banks",
	bankEdit: "Edit Bank",
	bankCreate: "Create Bank",
	bankRegNumber: "Register number",

	whsName: "Warehouse name",
	whsCode: "Warehouse code",
	navWhs: "Warehouses",
	whsEdit: "Edit Warehouse",
	whsCreate: "Create Warehouse",
	whsPort: "Port",
	whsDistanceToPort: "Distance to Port (km)",

	commission: "Commission",
	requiredMessage: "Field is required",
	messageWeakPassword: "Password isn't strong enough",
	attributesTitle: "Attributes",
	attributesCreateTitle: "Create Attribute",
	attributesEditTitle: "Edit Attribute",
	attributeName: "Name",
	attributeNameEN: "Name (EN)",
	attributeOrder: "Display order",
	attributeUnit: "Unit",
	attributeValuesTitle: "Attribute Values",
	attributeValuesCreateTitle: "Create Attribute Value",
	attributeValuesEditTitle: "Edit Attribute Value",
	attributeValuesEmpty: "There are no attribute values",
	attributeValueName: "Name",
	attributeValueNameEN: "Name (EN)",
	attributeValueValue: "Value",
	attributeValueValueEN: "Value (EN)",
	attributeValueOrder: "Display order",
	attributeValueStatus: "Status",
	currenciesTitle: "Currencies",
	currenciesCreateTitle: "Create Currency",
	currenciesEditTitle: "Edit Currency",
	currenciesName: "Name",
	currenciesCode: "Designation",
	currenciesNumericCode: "Code",
	regName: "Region",
	regNameDistrict: "District",
	parentRegName: "Region",
	countryReg: "Country",
	regEdit: "Edit Region",
	regEditDistrict: "Edit District",
	regCreate: "Create Region",
	regCreateDistrict: "Create District",
	curRateDate: "Currency rate date",
	curRateBuy: "Buy rate",
	curRateSell: "Sell rate",
	curRateMid: "Mid rate",
	curRateCurrency: "Currency",
	curRateTitle: "Currency Rates",
	curRateEditTitle: "Edit Currency Rates",
	curRateCreateTitle: "Create Currency Rates",
	countryEdit: "Edit Country",
	countryCreate: "Create Country",
	countryName: "Name",
	countryNameEN: "Name (EN)",
	cityEdit: "Edit City",
	cityCreate: "Create City",
	cityName: "Name",
	cityZipCode: "Zip Code",
	cityRegion: "District",
	citySubRegion: "Subregion",
	cityIsPort: "Port",

	commissionTitle: "Commissions",
	commissionCreateTitle: "Create Commission",
	commissionEditTitle: "Edit Commission",
	commissionCode: "Code",
	commissionName: "Name",
	commissionNameEN: "Name (EN)",
	commissionType: "Type",
	commissionStandard: "Standard",
	commDetailsTitle: "Commission Details",
	commDetailsCreateTitle: "Create Commission Details",
	commDetailsEditTitle: "Edit Commission Details",
	commDetailsDate: "Date from",
	commDetailsProductGroup: "Product Group",
	commDetailsProduct: "Product",
	commDetailsCurrency: "Currency",
	commDetailsAmtCurrency: "Amount Curency",
	commDetailsAmtTypePercent: "Percent",
	commDetailsAmtTypeAmount: "Amount",
	commDetailsBuyAmtType: "Buy Amount Type",
	commDetailsBuyPerc: "Buy Percent",
	commDetailsBuyMin: "Buy Min Amount",
	commDetailsBuyMax: "Buy Max Amount",
	commDetailsSellAmtType: "Sell Amount Type",
	commDetailsSellPerc: "Sell Percent",
	commDetailsSellMin: "Sell Min Amount",
	commDetailsSellMax: "Sell Max Amount",
	productCode: "Code",
	clientPersonRole: "Role",
	clientPersonDateCertified: "Date certified",
	clientPersonPhone: "Phone",
	clientPersonEmail: "Email",
	orderRecieveMethod: "Recieve Method",
	orderEditTitle: "Edit order",
	orderCreateTitle: "Create order",
	orderGoodsReadyFrom: "Goods ready from",
	orderGoodsReadyTo: "Goods ready to",
	orderAttrName: "Name",
	orderAttrValue: "Value",
	orderAttrTitle: "Quality paraneters",
	orderAttrCreateTitle: "Crete attribute",
	orderAttrEditAttribute: "Edit attribute",
	orderReceiveMethod: "Order Receive Method",
	orderbrokName: "Broker Name",
	orderUserName: "User Name",
	orderCliPrsName: "Client Person Name",
	orderProdCode: "Product Code",
	orderUnit: "Unit",
	orderQtyLeft: "Quantity Left",
	orderPriceVat: "Vat",
	orderMinQty: "Minimum Quantity",
	orderComment: "Comment",
	orderCommission: "Commission",
	ordergoodsRedyFrom: "Goods Ready From",
	ordergoodsRedyTo: "Goods Ready To",
	orderStatusDescr: "Status",
	orderCancelReplTime: "Cancel Replacement Time",
	orderPayData: "Pay Data",
	orderSide: "Side",
	orderCurrCode: "Cuurency",
	orderexportReady: "Export Ready",
	orderBankGuarantee: "Bank Guarantee",
	orderActive: "Membership not paid",

	tradeTitle: "Trades",
	tradeNo: "Trade Number",
	tradeTime: "Trade Time",
	tranNoSell: "Trade Sell Number",
	orderNoSell: "Trade Sell Order",
	clientNameSell: "Seller",
	tranNoBuy: "Trade Buy Number",
	orderNoBuy: "Trade Buy Order",
	clientNameBuy: "Buyer",
	tradeProdCode: "Product Code",
	tradeProdName: "Product Name",
	tradeQty: "Quantity",
	tradeStatusDescr: "Status",
	tradeDeliveryDescr: "Delivery Status",
	tradePrice: "Price",
	listingOrdersBookTitle: "Order Book",
	orderCancel: "Cancel order",
	orderCancelQuestion: "Cancel order?",
	orderCancelQuestionYes: "Yes",
	orderCancelQuestionNo: "No",
	orderConfirmQuestion: "Confirm order?",
	orderModify: "Modify order",
	orderVerify: "Verify order",
	orderConfirm: "Confirm nalog",
	orderReplay: "Reply order",
	orderDelete: "Delete order",
	tradeVatPerc: "Vat %",
	tradePriceWithVat: "Price with Vat",
	tradeAmt: "Amount",
	tradeAmtWithVat: "Amount with Vat",
	tradeTransCostSell: "Transport Costs Seller",
	tradeStorCostSell: "Storage Costs Seller",
	tradeTransCostBuy: "Transport Costs Buyer",
	tradeStorCostBuy: "Storage Costs Buyer",
	tradeCommPercSell: "Commission Seller %",
	tradeCommAmtSell: "Commission Seller ",
	tradeCommPercBuy: "Commission Buyer %", 
	tradeCommAmtBuy: "Commission Buyer",
	tradeDeliveryWhsName: "Warehouse",
	tradePayTypeDescr: "Pay Type",

	orderFilterConfirm: "Filter",
	orderFilterCancel: "Cancel",
	orderFilterFrom: "Order from",
	orderFilterTo: "Order to",
	tradeFilterFrom: "Trade from",
	tradeFilterTo: "Trade to",
	tradeViewAction: "View trade",
	tradeCancelAction: "Cancel trade",
	orderTradeQuestion: "Cancel trade?",
	filterFrom: "from",
	filterTo: "to",
	paymentTitle: "Payment",
	paymentDataTime: "Date",
	paymentClientCode: "Client Code",
	paymentClientName: "Client Name",
	paymentPayType: "Pay Type",
	paymentClmOrdNo: "Order No.",
	paymentTranNo: "Transaction No.",
	paymentAmount: "Amount",
	paymentAmountIn: "Amount In",
	paymentAmountOut: "Amount Out",
	paymentCurrCode: "Currency",
	paymentStatus: "Status",
	datefrom: "From date",
	dateto: "To date",
	paymentEdit: "Edit Payment",
	paymentCreate: "Create Payment",
	paymentSideIn: "In",
	paymentSideOut: "Out",
	paymentSide: "In/Out",
	paymentSideNonDedicated: "Non Dedicated",
	paymentSideDedicated: "Dedicated",
	paymentSideDedication: "Dedication",
	paymentBankGuarantee: "Bank Guarantee",
    paymentMatchingQty: "Matching qty",

	ctrlGoodsTitle: "Control",
	ctrlcontrolDate: "Date",
	ctrltradeNo: "Trade No.",
	ctrlsellerCode: "Seller Code",
	ctrlsellerName: "Seller",
	ctrlbuyerCode: "Buyer Code",
	ctrlbuyerName: "Buyer",
	ctrlproductCode: "Product Code",
	ctrlproductName: "Product Name",
	ctrlcheckPointDescr: "Check Point",
	ctrlcheckDoneByDescr: "Check Done By",
	ctrlcheckPaysDescr: "Check Pays",
	ctrlcontrolHouse: "Control House",
	ctrlcontrolReport: "Control Report",
	ctrlcontrolReportText: "Control Report Text",
	ctrlcontrolReportFile: "Control Report File",
	ctrlstatus: "Status",
	crtlGoodsEdit: "Edit Control",
	ctrlGoodsCreate: "Create Control",
	ctrlFilterFrom: "From Date",
	ctrlFilterTo: "To Date",

	dlvrDiliveryTitle: "Delivery",
	dlvrDeliveryDate: "Date",
	dlvrTradeNo: "Trade No.",
	dlvrsellerCode: "Seller Code",
	dlvrsellerName: "Seller",
	dlvrbuyerCode: "Buyer Code",
	dlvrbuyerName: "Buyer",
	dlvrproductCode: "Product Code",
	dlvrproductName: "Product",
	dlvrDeliverQty: "Delivery Qty",
	dlvrLeftQty: "Left Qty",
	dlvrTradeQty: "Trade Qty",
	dlvrUnit: "Unit",
	dlvrCityCode: "City Code",
	dlvrCityName: "City",
	dlvrWarehouseCode: "Warehouse Code",
	dlvrWarehouseName: "Warehouse",
	dlvrstatus: "Status",
	dlvrEdit: "Edit Delivery",
	dlvrCreate: "Create Delivery",
	dlvrFilterFrom: "From Date",
	dlvrFilterTo: "To Date",
	dlvrValue: "Value of Delivery Qty",

	dlsDisputeTitle: "Dispute Situation",
	disDispSitDate: "Date",
	disTradeNo: "Trade No.",
	dissellerCode: "Seller Code",
	dissellerName: "Seller",
	disbuyerCode: "Buyer Code",
	disbuyerName: "Buyer",
	disproductCode: "Product Code",
	disproductName: "Product",
	disDisputeDescr: "Dispute Description",
	disstatus: "Status",
	disEdit: "Edit Dispute",
	disCreate: "Crate Dispute",
	disFilterFrom: "From Date",
	disFilterTo: "To Date",
	paymentClientOrders: "Client orders",
	paymentClientTransactions: "Client transactions",
	guarGuaranteeTitle: "Guarantees",
	guarEdit: "Edit Guarantee",
	guarCreate: "Create Guarantee",

	orderDeliveryLocation: "Delivery Location",
	filterReset: "Clear",
	orderMarket: "Market",
	orderMarketSpot: "Spot",
	orderMarketForward: "Forward",
	orderLocCountry: "Country",
	orderLocRegion: "Region",
	orderLocDistrict: "District",
	orderLocCity: "City",
	orderLocWhs: "Warehouse",
	tradeFulfillmentDate: "Fulfillment Date",
	startDeliveryDate: "Start Delivery Date",
	navOrdersReport: "Order book",

	stockFeeTitle: "Stock Fees",
	stockFeeEdit: "Edit Stock Fee",
	stockFeeCreate: "Create Stock Fee",
	stockFeeStartDate: "Start Date",
	stockFeeStockFee: "Stock Fee",
	stockFeeStatus: "Status",

	transFeeTitle: "Transport Fees",
	transFeeEdit: "Edit Transport Fee",
	transFeeCreate: "Create Transport Fee",
	transFeeStartDate: "Start Date",
	transFeePice: "Price",
	transFeeDistance: "Distance",
	transFeeStatus: "Status",

	navTradesReport: "Trade Book",
	navTradesReportAll: "Trade Book - All",


	dailyTradeReportTitle: "Daily Trades",
	dailyTradeReportProduct: "Product",
	dailyTradeReportTurnoverRSD: "Turnover RSD",
	dailyTradeReportTurnoverEUR: "Turnover EUR",
	dailyTradeReportCountTransaction: "Transaction Count",
	dailyTradeReportReportDate: "Report Date",
	dailyTradeReportReportWeightedRSD: "Average Price RSD",
	dailyTradeReportReportWeightedEUR: "Average Price EUR",
	dailyTradeReportQuantity: "Quantity",

	monthlyTradeReportReportTitle: "SEC Monthly Trades Report",
	monthlyTradeReportReportMonth: "Month",
	monthlyTradeReportReportYear: "Year",
	monthlyTradeReportReportDate: "Date",
	monthlyTradeReportReportQty: "Quantity",
	monthlyTradeReportReportWeightedRSD: "Average Price RSD",
	monthlyTradeReportReportWeightedEUR: "Average Price EUR",

	filterTooltip: "Filter",
	exportPDFTooltip: "Download PDF",
	exportXLSXTooltip: "Download Excel",
	exportXMLTooltip: "Dpwnload XML",
	printTooltip: "Print",

	weeklyTradeReportTitle: "Weekly Report",
	wTReportProductTitle: "Weekly Report - Product",
	wTReportClientTitle: "Weekly Report - Client",
	wTReportDateTitle: "Weekly Report - Date",
	weeklyTradeWeek: "Week",
	weeklyTradeReportProduct: "Product",
	weeklyTradeReportClient: "Client",
	weeklyTradeReportDate: "Date",
	weeklyTradeReportTurnoverRSD: "Turnover RSD",
	weeklyTradeReportTurnoverEUR: "Turnover EUR",
	weeklyTradeReportCountTransaction: "Transaction Count",
	weeklyTradeReportQty: "Quantity",
	wTReportWeightedPrice: "Average price",
	wTReportWeightedPriceeur: "Average price eur",
	wTableProduct: "Product",
	wTableClient: "Client",
	wTableDate: "Date",

	monthlyTradeReportTitle: "Monthly Report",
	mTReportProductTitle: "Monthly Report - Product",
	mTReportClientTitle: "Monthly Report - Client",
	mTReportDateTitle: "Monthly Report - Date",
	monthlyTradeMonth: "Month",
	monthlyTradeReportProduct: "Product",
	monthlyTradeReportClient: "Client",
	monthlyTradeReportDate: "Date",
	monthlyTradeReportTurnoverRSD: "Turnover RSD",
	monthlyTradeReportTurnoverEUR: "Turnover EUR",
	monthlyTradeReportCountTransaction: "Transaction Count",
	monthlyTradeReportQty: "Quantity",
	mTReportWeightedPrice: "Average price",
	mTReportWeightedPriceeur: "Average price eur",
	mTableProduct: "Product",
	mTableClient: "Client",
	mTableDate: "Date",

	annualTradeReportTitle: "Annual Report",
	aTReportProductTitle: "Annual Report - Product",
	aTReportClientTitle: "Annual Report - Client",
	aTReportDateTitle: "Annual Report - Date",
	annualTradeYear: "Year",
	annualTradeReportProduct: "Product",
	annualTradeReportClient: "Client",
	annualTradeReportDate: "Date",
	annualTradeReportTurnoverRSD: "Turnover RSD",
	annualTradeReportTurnoverEUR: "Turnover EUR",
	annualTradeReportCountTransaction: "Transaction Count",
	annualTradeReportQty: "Quantity",
	aTReportWeightedPrice: "Average price",
	aTReportWeightedPriceeur: "Average price eur",
	aTableProduct: "Product",
	aTableClient: "Client",
	aTableDate: "Date",

	orderFundCheckButton: "OK",
	orderFundCheckTitle: "Fund Check",

	weeklyNumofWeeks: "Number of Weeks",
	monthlyNumofmonths: "Number of Months",

	TASTitle: "Transport and storage costs",
	TASCalcStorage: "Calculate storage costs",
	TASCalTransport: "Calculate transport costs",
	TASCaclulate: "Calculate",
	TASConfirm: "Confirm",
	TASDistance: "Distance",
	TASStorageCosts: "Storage costs",
	TASTransportCosts: "Transport costs",
	TASTooltip: "Calculate transport and storage costs",
	TASCancel: "Cancel",
	TASWarning: "Costs are already calculated! New calculation will overrun the previous one!",

	secCommDailyTradeReportTitle: "SEC Daily Report",
	secCommQuarterlyTradeReportTitle: "SEC  Quarterly Report",
	secCommAnnualyTradeReportTitle: "SEC Annualy Report",
	secCommProduct: "Product",
	secCommTurnoverRSD: "Turnover RSD",
	secCommTurnoverEUR: "Turnover EUR",
	secCommCountTransaction: "Transaction number",
	secCommReportDate: "Report Date",
	secCommReportWeightedRSD: "Average price RSD",
	secCommReportWeightedEUR: "Average price EUR",
	secCommReportQty: "Quantity",
	secCommReportQuarter: "Quarter",

	amtimnylndcommTradeNumber: "Trade Number",
	amtimnylndcommTradeDate: "Trade Date",
	amtimnylndcommProduct: "Product",
	amtimnylndcommQuantity: "Quantity",
	amtimnylndcommPrice: "Price",
	amtimnylndcommAmount: "Amount",
	amtimnylndcommBuyer: "Buyer",
	amtimnylndcommSeller: "Seller",
	amtimnylndcommReportTitle: "Money Laundering Report",
	amtimnylndcommLimit: "Limit",

	exmemTitle: "Exchange Members",
	exmemMemberName: "Member Name",
	exmemMemberId: "Member Id",
	exmemTaxCode: "Tax Code",
	exmemAdress: "Address",
	exmemPhone: "Phone",
	exmemManager: "Manager",
	exmemTrader: "Trader",
	exmemContactPerson: "Contact Person",

	memcontTitle: "Member Contracts",
	memcontMember: "Member",
	memcontContractDate: "Contract Date",
	memcontExpireDate: "Expire Date",
	memcontStatus: "Status",

	exchDateFrom: "Date From",
	exchDateTo: "Date To",
	exchTradeReview: "Trade Review",
	exchCommissionReview: "Commission Review",
	exchTradeByProduct: "Trade By Product",
	exchBusinessActivities: "Business Activities",
	exchTurnoverByClient: "Turnover By Client",
	exchTurnoverByBrokers: "Turnover By Brokers",
	exchWeightedPrice: "Weighted Prices",
	exchDate: "Date",
	exchTradeNumber: "Trade Number",
	exchTradeDate: "Trade Date",
	exchProduct: "Product",
	exchPrice: "Price",
	exchQuantity: "Quantity",
	exchAmount: "Amount",
	exchClient: "Client",
	exchTransactionDate: "Transaction Date",
	exchTransactionNumber: "Transaction Number",
	exchOrderNumber: "Order Number",
	exchSide: "Side",
	exchCommission: "Commission",
	exchTurnoverValue: "Turnover Value",
	exchBuyersCommission: "Buyers Commission",
	exchSellersCommission: "Sellers Commission",
	exchBuyerBroker: "Buyers Broker",
	exchSellerBroker: "Sellers Broker",
	exchCommissionValue: "Commission Value",
	exchTurnoverParticipation: "Turnover Participation",
	exchCommissionParticipation: "Commission Participation",
	exchBuyer: "Buyer",
	exchSeller: "Seller",
	exchTurnoverRsd: "Turnover RSD",
	exchTurnoverEur: "Turnover EUR",
	exchWeightedPriceRsd: "Weighted Price RSD",
	exchWeightedPriceEur: "Weighted Price EUR",

	annualNumofyears: "Number of year",

	pleaseWait: "Please wait...",

	createInvoiceTitle: "Automatic Creating Invoices",
	invoiceCreate: "Create Invoice",
	invoiceEdit: "Edit Invoice",
	invoiceTitle: "Invoices",
	invoiceInvNo: "Invoice No",
	invoiceInvDate: "Invoice Date",
	invoiceInvTypeDescr: "Invoice Type",
	invoiceTranDate: "Transaction Date",
	invoiceDueDate: "Due Date",
	invoiceDescr: "Description",
	invoiceTranNo: "Transaction No",
	invoiceClientIdNo: "Client Id",
	invoiceClientName: "Client Name",
	invoiceTradeAmt: "Trade Amount",
	invoiceTradeAmtWithVat: "Commission Basis for calculation",
	invoiceCommPerc: "% Commission",
	invoiceAmt: "Commission Amount",
	invoiceVatPerc: "% VAT",
	invoiceVatAmt: "VAT Amount",
	invoiceInvAmt: "Pay Amount",
	invoiceCurrCode: "Cuurency",
	invoiceBookDate: "Book Date",
	invoiceCmpAccount: "Account",
	invoiceModel: "Model",
	invoiceReffNo: "Refference No",
	invoiceStatus: "Status",
	invoiceQuantity: "Quantity",
	invoicePrice: "Price",
	invoiceOrderNo: "Order No.",
	invoiceProductName: "Product",

	iInvoiceTooltip: "Create Invoice",

	invoicingMessage: "Invoicing successfully finished",
	invoicingMessageTitle: "Invoicing",
	invoicingMessageConfim: "OK",

	accountingOrderTooltip: "Creating Order",
	accountingOrderTitle: "Accounting Order",
	accountingOrderMessage: "Order Creating finished",
	accountingOrderMessageTitle: "Creating Order",
	accountingOrderConfim: "OK",

	tradingDataRefPriceTooltip: "Calculata Referent Price",
	tradingDataTitle: "Trading Data",
	tradingDataDataTime: "Trading Date",
	tradingDataProductCode: "Product Code",
	tradingDataProductName: "Product Name",
	tradingDataRefPrice: "Referent Price",
	tradingDataBestBid: "Best Bid",
	tradingDataBestBidQty: "Best Bid Quantity",
	tradingDataBestAsk: "Best Ask",
	tradingDataBestAskQty: "Best Ask Quatnity",
	tradingDataOpenPrice: "Open Price",
	tradingDataHighPrice: "High Price",
	tradingDataLowPrice: "Low Price",
	tradingDataClosePrice: "Close Price",
	tradingDataVolume: "Volume",
	tradingDataAmount: "Amount",
	tradingDataWeightedPrice: "Average Price",
	tradingDataMidPrice: "Mid Price",
	tradingDatadatefrom: "From Date",
	tradingDatadateto: "To Date",
	tradingDataEdit: "Edit Referent Price",

	marginsDataCalculationTooltip: "Margins Data Calculation",
	marginsDataTitle: "Margins Data",
	marginsDataCalcDate: "Date",
	marginsDataTradeNo: "Trade Number",
	marginsDataTradeDate: "Trade Date",
	marginsDataSide: "Side",
	marginsDataClientIdNo: "Client No",
	marginsDataClientName: "Client",
	marginsDataProductCode: "Product Code",
	marginsDataProductName: "Product Name",
	marginsDataContrPrice: "Contract Price",
	marginsDataContrValue: "Contract Value",
	marginsDataGainLoss: "Gain/Loss",
	marginsDataMarginReq: "Requested Margin",
	marginsDataMarginCall: "Margin Call",
	marginsDataMarginBal: "Margin Balans",
	marginsDataBalPlusGL: "Balans with G/L",
	marginsDataCoverPerc: "Cover Percent",
	marginsDataMaintTriggered: "Maintenance Triggered",
	marginsDatadatefrom: "From Data",
	marginsDatadateto: "To Data",

	noFutureDate: "Future dates not allowed.",

	orderQtyMatched: "Matched quantity",
	alarmTime: "Alarm time",
	alarmCrossName: "Cross alarm",
	alarmProductName: "Product alarm",
	alarmClientName: "Client alarm",
	notificationNewOrder: "New order",
	notificationNewTrade: "New trade",

	tradingDataMessage: "Price Calculation Over",
	tradingDataMessageTitle: "Referent Price Calculation",
	tradingDataConfim: "OK",
	tradingDataReportDate: "Calculation Date",

	marginsDataMessage: "Margins Data Calculation Over",
	marginsDataMessageNoData: "No active forward trades for date.",
	marginsDataMessageTitle: "Margins Data Calculation",
	marginsDataConfim: "OK",
	marginsDataReportDate: "Calculation Date",

	alarmAlmType: "Alarm Type",
	alarmUserName: "User Name",
	alarmClient: "Client",
	alarmProductCode: "Product Code",
	alarmProduct: "Product",
	alarmActive: "Status",
	alarmEdit: "Edit Alarm",
	alarmCreate: "Creat Alarm",
	alarmTitle: "Alarm",
	alarmTypeCros: "Cross order",
	alarmTypeProduct: "Product",
	alarmTypeUser: "Client",
	alarmUniqueMessage: "Alarm is already set",
	alarmTypeUserProduct: "Client and product",

	alarmProductClientName: "Alram for client and product",
	alarmBuyOrderNumber: "Buy order number",
	alarmSellOrderNumber: "Sell order number",
	allowSystemNotifications: "Turn on system notifications",

	attrCompTypeLessThan: "<=",
	attrCompTypeEqual: "=",
	attrCompTypeMoreThan: ">=",
	attrCompType: "Comparator",
	productAttributeHasStandard: "Has Standard Value",
	productAttributeStandardValue: "Standard Value",

	orderParameters: "Order parameters",
	tradeParameters: "Trade parameters",
	orderDepositeAmount: "Needed amount",
	orderDepositeAmountPayed: "Payed amount",
	paymentAmtBal: "Ammount ballance",
	paymentAmtBalNonAlloc: "Not allocated ballance",

	attributeSRPS: "According to PSTM",
	// "SRPS quality",

	paymentAmtOrdersBal: "Orders state",
	paymentAmtTradesBal: "Transactions state",

	deliveryTradeQty: "Agreed quantity",
	deliveryLeftQty: "Left for delivery",
	
	clientCardTitle: "Client Card",

	grntTitle: "Bank Guarantees",
	grntNo: "Garantee No.",
	grntDate: "Date",
	grntClientCode: "Client Code",
	grntClientName: "Client Name",
	grntBankCode: "Bank Code",
	grntBankName: "Bank Name",
	grntExpiryDate: "Expiry Date",
	grntAmount: "Amount",
	grntCurrCode: "Cuurency",
	grntStatus: "Status",
	grntEdit: "Edit Guarantee",
	grntCreate: "Create Guarantee",
	grntActView: "Show",

	mainTableBid: "Buy",
	mainTableAsk: "Sell",

	tradesReportMessage: "Preuzimanje uspešno završeno.",
	tradesReportTitle: "Preuzimanje",
	tradesReportMessageConfim: "Kupovina",

	errorTitle: "Error",
	errorConfirm: "OK",
	errorProductDelete: "You can't delete product that was in an order.",
	errorProductAttributeDelete: "You can't delete product attribute that was in an order.",
	errorClientDelete: "You can't delete client because of reference data conflict.",

	orderLogDataChangeTime: "Change Time",
	orderLogDataUserName: "Username",
	orderLogDataActivity: "Activity",
	orderLogDataStatus: "Status",
	orderLogDataRefOrder: "Ref Order",
	orderLogDataTable: "History",
	orderLogDataEmpty: "No history for order",

	newsTime: "Date",
	newsTitle: "Title",
	newsTitleen: "Serbian Title",
	newsLokacija: "File Name",
	newsTitleTitle: "News",
	newsEditTitle: "Edit News",
	newsCreateTitle: "Create News",
	newsShow: "Show news",

	payTypeDesc: "Pay Type (SR)",
	payTypeDescEn: "Pay Type (EN)",
	payTypeTitle: "Pay Type",
	payTypeEditTitle: "Edit Pay Type",
	payTypeCreateTitle: "Create Pay Type",
	payTypeStatus: "Status",
	payTypePonder: "Weight",

	historyProd1: "Corn",
	historyProd2: "Wheat",
	historyProd3: "Soybeans not GMO",

	AttrPsmtTitle: "Quality paraneters according to PSTM",

	loginRemember: "Remember me",
	loginForget: "Forget me",

	pushNotifications: "Notifications",
	pushNotification: "Notification",

	notifPacking: "Packing",
	notifOrigin: "Origin",
	notifDirectorate: "Directorate",

};
