import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadReport } from "../thunks/loadTradesReport";
import { TradeList } from "../../../trading/trades/types";
import { PageData } from "../../../models";

export const useTradesReportData = () => {
  const pageData = useSelector<StoreType, PageData<TradeList> | undefined>(
    (s) => s.data.reports.tradesReportAll.currentPage
  );
 
  const dispatch = useDispatch();
  const dispatchLoadPage = React.useCallback(
    () => dispatch(loadReport()),
    [dispatch]
  );

  return [pageData, dispatchLoadPage] as [
    typeof pageData,
    typeof dispatchLoadPage,
  ];
};
