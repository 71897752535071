import { ThunkType } from "../../../../core/store/reducers";
import { getBankGuaranteesReport } from "../api/getBankGuaranteesReport";
import fileDownload from "js-file-download";
import { setFetchState } from "../bankguaranteesDataSlice";

export const downloadBankGuaranteesReport = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.clearing.bankguarantees;
  const user = store.data.auth.current.user;

  dispatch(setFetchState({ type: "started" }));
  const ordersReport = await dispatch(getBankGuaranteesReport(user, filter));
  if (ordersReport) {
    fileDownload(
      new Blob([ordersReport as any], { type: "application/pdf" }),
      "BankGuarantees.pdf"
    );
  }
  dispatch(setFetchState({ type: "not-started" }));
};

