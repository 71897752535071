import React from "react";
import { useDisplayPayType } from "../../../data/listing/payTypes/hooks/useDisplayPayType";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { PayType } from "../../../data/listing/payTypes/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayPayTypeMainAttributes } from "./DisplayPayTypeMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,
} from "@material-ui/core";

export interface DisplayPayTypeProps {
  id?: string;
}

export const DisplayPayType: React.FunctionComponent<DisplayPayTypeProps> = ({
  id,
}) => {
  const payType = useDisplayPayType(id);
  const { getString } = React.useContext(LocalizationContext);

  return !payType ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Typography>{getString("tableBasciInfo")}</Typography>
        </ExpansionPanelSummary>
        <DisplayPayTypeMainAttributes payType={payType} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewPayType = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (payType: PayType) => showWithDrawer(<DisplayPayType id={payType.payTypeId} />),
    [showWithDrawer]
  );
};
