import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { calculateTASCostsThunk } from "../thunks/calculateTASCostsThunk";
import { setCalculationFetchState } from "../TASDataSlice";
import { TransportAndStorageCosts, CalculationParams } from "../types";

export const useTASCalculator = () => {
	const fetchState = useSelector<StoreType, FetchStateType<TransportAndStorageCosts>>(
		store => store.data.clearing.transportAndStorage.calculateFetchState
	);

	const dispatch = useDispatch();
	const dispatchCalculate = React.useCallback(
		(params: CalculationParams) => dispatch(calculateTASCostsThunk(params)),
		[dispatch]
	);

	const dispatchReset = React.useCallback(
		() => dispatch(setCalculationFetchState({ type: "not-started" })),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setCalculationFetchState({ type: "not-started" }));
	}, [dispatch]);

	return [fetchState, dispatchCalculate, dispatchReset] as [
		FetchStateType<TransportAndStorageCosts>,
		typeof dispatchCalculate,
		typeof dispatchReset
	];
};
