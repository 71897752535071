import { ThunkType } from "../../../../core/store/reducers";
import createPaymentApi from "../api/createPayment";
import updatePaymentApi from "../api/updatePayment";
import { PaymentData } from "../types";
import { setFetchState, invalidatePayment } from "../paymentsDataSlice";

export const upsertPayment = (payment: PaymentData): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = payment.payId
    ? await dispatch(updatePaymentApi(userId, payment))
    : await dispatch(createPaymentApi(userId, payment));

  if (result && result.resCode === "OK") {
    dispatch(setFetchState({ type: "successful", result: result.resDesc }));
    dispatch(invalidatePayment());
    return result.resDesc;
  } else {
    dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
  }
};
