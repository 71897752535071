import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllCurrencies } from "../thunks/loadAllCurrencies";
import { Currency } from "../types";

export const useCurrencyData = () => {
	const data = useSelector<StoreType, Currency[] | undefined>(s => s.data.listing.currencies.displayData);
	const allData = useSelector<StoreType, Currency[] | undefined>(s => s.data.listing.currencies.all);

	const dispatch = useDispatch();
	const dispatchLoadAll = React.useCallback(() => dispatch(loadAllCurrencies()), [dispatch]);

	return [data, dispatchLoadAll, allData] as [
		typeof data,
		typeof dispatchLoadAll,
		typeof allData
	];
};
