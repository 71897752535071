import { ThunkType } from "../../../../core/store/reducers";
import { printTrade } from "../../trades/api/printTrade";
import fileDownload from "js-file-download";
import { setPrintTradeFetchState } from "../tradesDataSlice";
import { TradeList } from "../types";

export const printTradeThunk = (trade?: TradeList): ThunkType => async (dispatch, getStore) => {
	const store = getStore();
	
	if (store.data.auth.current.status !== "successful" || !trade || !trade.tradeId) {
		return undefined;
	}
	const lan = store.ux.localization.language
	const filename = `TradeConfirmation_${trade.tradeNo}.pdf`
	const user = store.data.auth.current.user;
	dispatch(setPrintTradeFetchState({ type: "started" }));
	const tradesReport = await dispatch(printTrade(user, trade.tradeId));
	if (tradesReport) {
		fileDownload(new Blob([tradesReport as any], { type: "application/pdf" }), filename);
	}
	dispatch(setPrintTradeFetchState({ type: "not-started" }));
};
