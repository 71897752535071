import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useBankData } from "../../../data/listing/banks/hooks/useBankData";
import { Bank } from "../../../data/listing/banks/types";
import { useBankColumns } from "./useBankColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useBankActions, useDeleteBankAction } from "./useBankActions";

export interface BankTableProps {
  userAccessRights: AccessRights;
}

type BankTablePropsType = BankTableProps & TableDataProps<Bank>;

export const BanksTable: React.FunctionComponent<BankTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const [data, loadAll] = useBankData();

  const columns = useBankColumns();
  const actions = useBankActions(userAccessRights);
  const onDeleteBank = useDeleteBankAction();

  return (
    <Table<Bank>
      {...tableProps}
      getData={loadAll}
      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        toolbar: false,
        pageSize: 10,
        actionsColumnIndex: -1,
      }}
       editable={userAccessRights !== AccessRights.ReadOnly ? {
        onRowDelete:  onDeleteBank,
      }: {}}
    />
  );
};

export default BanksTable;
