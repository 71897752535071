import React from "react";
import { MenuList } from "../core/navigation/MenuItemComponents";
import { useMenuItems } from "../views/navigation/hooks/useNavigationItems";

export const NavList: React.FunctionComponent = () => {
  const menuItems = useMenuItems();
  return (
    <div>
      <MenuList menuItems={menuItems} />
    </div>
  );
};
