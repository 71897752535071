import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useRegionData } from "../../../data/listing/regions/hooks/useRegionData";
import { Region } from "../../../data/listing/regions/types";
import { useRegionsColumns } from "./useRegionsColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useRegionsActions, useDeleteRegionAction } from "./useRegionsActions";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { RegType } from "../../../data/models";

export interface RegionsTableProps {
  userAccessRights: AccessRights;
}

type RegionsTablePropsType = RegionsTableProps & TableDataProps<Region>;

export const RegionsTable: React.FunctionComponent<RegionsTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const regtype = useSelector<StoreType, RegType | undefined>(
    (s) => s.data.listing.regions.regType
  );

  const [data, loadAll] = useRegionData(regtype);

  const columns = useRegionsColumns(regtype);
  const actions = useRegionsActions(userAccessRights);
  const onDeleteRegion = useDeleteRegionAction(regtype);

  return (
    <Table<Region>
      {...tableProps}
      getData={loadAll}
      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        toolbar: false,
        pageSize: 10,
        actionsColumnIndex: -1,
      }}
      editable={userAccessRights !== AccessRights.ReadOnly ?{
        onRowDelete: onDeleteRegion,
      } : {}}
    />
  );
};

export default RegionsTable;
