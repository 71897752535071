import React from "react";
import { useDisplayCity } from "./useDisplayCity";
import { City } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defaultCity: City = {
	cityName: "",
	zipCode: "",
	regionId: "",
	isPort: Status.Inactive,
};

export const useEditCity = (cityId?: string) => {
	const displayCity = useDisplayCity(cityId);

	return React.useMemo(() => {
		if (cityId !== undefined && displayCity === undefined) {
			return undefined;
        }

		if (cityId === undefined) {
			return { ...defaultCity };
        }

		return displayCity ? { ...displayCity } : undefined;
	}, [cityId, displayCity]);
};

export const useEditCityState = (cityId?: string) => {
	const city = useEditCity(cityId);
	return useCopiedState(city)
};
