import React, { useContext } from "react";
import { Grid, CardContent, CardHeader, Card, Divider, Typography, Tabs, Tab } from "@material-ui/core";
import OrdersTable from "./OrdersTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { useMainBoardData } from "../../../data/trading/mainBoard/hooks/useMainBoardData";
import { useMainBoardIndex } from "../../../data/trading/mainBoard/hooks/useMainBoardIndex";
import { OrderSide, PageDataStatus } from "../../../data/models";
import { MainBoarFilterButton } from "./MainBoardFilter";
import { useNotificationHandler } from "../../../core/notifications/hooks";
import { PriceTicker } from "./Ticker";
import { LocalizationContext } from "../../../core/localization/Localization";
import { SideOverviewParts } from "./Locations";
import { ProductGraph } from "../mainBoard/ProductGraph";

const MainBoard: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();
	const { getString } = useContext(LocalizationContext);

	const [tab, setTab] = React.useState(SideOverviewParts.Sell);

	const [buyPageData, dispatchLoadBuyOrders, invalidateData] = useMainBoardData(OrderSide.Buy);
	const [buyPageIndex, dispatchSetBuyIndex] = useMainBoardIndex(OrderSide.Buy);

	React.useEffect(() => {
		if (buyPageData && buyPageData.status === PageDataStatus.DataInvalidated) {
			dispatchLoadBuyOrders();
		}
	}, [dispatchLoadBuyOrders, buyPageData]);

	const [sellPageData, dispatchLoadSellOrders] = useMainBoardData(OrderSide.Sell);
	const [sellPageIndex, dispatchSetSellIndex] = useMainBoardIndex(OrderSide.Sell);

	React.useEffect(() => {
		if (sellPageData && sellPageData.status === PageDataStatus.DataInvalidated) {
			dispatchLoadSellOrders();
		}
	}, [dispatchLoadSellOrders, sellPageData]);

	const handleSideChange = React.useCallback(
		(event: React.ChangeEvent<{}>, newValue: any) => {
			setTab(newValue);
		},
		[setTab]
	);

	React.useEffect(
		() => () => {
			invalidateData();
		},
		[]
	);

	useNotificationHandler<string>(
		"OrderChanged",
		e => {
			console.log("OrderChanged", e);
			invalidateData();
		},
		[]
	);

	return (
		<Grid container className={pageStyles.rootSpacing} spacing={2}>
			<Grid item xs={12}>
				<PriceTicker />
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader
						title={<PageTitle title="navMainTable" />}
						action={<MainBoarFilterButton />}
					/>
					<Divider />
					<CardContent>
					<Tabs
							value={tab}
							onChange={handleSideChange}
							indicatorColor="secondary"
							textColor="secondary"
							variant="fullWidth"
						>
							<Tab
								label={getString("mainTableBid")}
								value={SideOverviewParts.Buy}
							/>
							<Tab
								label={getString("mainTableAsk")}
								value={SideOverviewParts.Sell}
							/>
						</Tabs>
						<div style={{ marginTop: "20px" }}>
							{tab === SideOverviewParts.Buy &&  (
								<OrdersTable
								pageIndex={buyPageIndex}
								pageData={buyPageData}
								onPageIndexChange={dispatchSetBuyIndex}
								userAccessRights={userAccessRights}
								getData={dispatchLoadBuyOrders}
							/>
						)}
							{tab === SideOverviewParts.Sell  && (
								<OrdersTable
								pageIndex={sellPageIndex}
								pageData={sellPageData}
								onPageIndexChange={dispatchSetSellIndex}
								userAccessRights={userAccessRights}
								getData={dispatchLoadSellOrders}
							/>
						)}
						</div>
					</CardContent>
				</Card>
			</Grid>
			<Grid item md={4} xs={12}>
				<ProductGraph graphId="0001" />
			</Grid>

		</Grid>
	);
};

export default MainBoard;
