import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../../models";
import { TradeReviewReportData } from "./types";

type TradesReportData = {
  data?: TradeReviewReportData[];
  downloadReportFetchState: FetchStateType;
};

const initialState: TradesReportData = {
  downloadReportFetchState: { type: "not-started" },
};

const name = "tradeReview";

const tradeReviewSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<TradeReviewReportData[] | undefined>
    ) => ({
      ...state,
      data: action.payload,
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      downloadReportFetchState: action.payload,
    }),
  },
});

export const { setData, setFetchState } = tradeReviewSlice.actions;

export default tradeReviewSlice.reducer;
