import { NavigationItem } from "../../../core/navigation/types";
import DeliveriesPage from "./DeliveriesPage";
import { EditDeliveryPage } from "./EditDeliveryPage";
import { deliveriesLocation, editRouter } from "./Locations";
export const deliveryComponents: NavigationItem[] = [
  {
    Component: DeliveriesPage,
    isMenuItem: true,
    path: deliveriesLocation,
  },
  {
    Component: EditDeliveryPage,
    isMenuItem: false,
    path: editRouter,
  },
];
