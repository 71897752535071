import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { resetMarginsDateIdices } from "../marginsUXSlice";
import { PageIndex } from "../../../models";
import { setMarginsDataIndexThunk } from "../thunks/loadAllMarginsData";

export const useMarginsDataIndex = () => {
  const pageIndex = useSelector<StoreType, PageIndex>(
    (s) => s.ux.clearing.marginsData.index
  );

  const dispatch = useDispatch();
  const dispatchSetIndex = React.useCallback(
    (index: PageIndex) => dispatch(setMarginsDataIndexThunk(index)),
    [dispatch]
  );
  const dispatchResetIndex = React.useCallback(
    () => dispatch(resetMarginsDateIdices()),
    [dispatch]
  );

  return [pageIndex, dispatchSetIndex, dispatchResetIndex] as [
    typeof pageIndex,
    typeof dispatchSetIndex,
    typeof dispatchResetIndex
  ];
};
