import { webApi } from "../../../../core/http/httpThunks";

export const deleteBankGuaranteeApi = (userId: string, grntid: string) =>
  webApi<string>({
    method: "DELETE",
    url: "clearing/delbankgrnt",
    params: {
      userId,
      grntid,
    },
  });

export default deleteBankGuaranteeApi;
