import { ThunkType } from "../../../../../core/store/reducers";
import { getQuarterlySecurityReport } from "../api/getQuarterlySecurityReport";
import fileDownload from "js-file-download";
import { setFetchState } from "../quarterlySecurityDataSlice";

export const downloadOrdersReport = (): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { filter } = store.ux.reports.securityCommission.quarterlylSecurity;
  const user = store.data.auth.current.user;
  dispatch(setFetchState({ type: "started" }));
  const ordersReport = await dispatch(getQuarterlySecurityReport(user, filter));
  if (ordersReport) {
    fileDownload(
      new Blob([ordersReport as any], { type: "application/pdf" }),
      "QuarterlySecurityTrading.pdf"
    );
  }
  dispatch(setFetchState({ type: "not-started" }));
};
