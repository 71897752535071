import { useMemo } from "react";
import { MenuItemType, AccessRights, LinkMenuItemType, SubmenuMenuItemType } from "./types";

export const useVisiableNavigationItems = (items: MenuItemType[]): MenuItemType[] =>
	useMemo(() => items.filter(isItemVisiable), [items]);

const isItemVisiable = (item: MenuItemType): boolean => {
	if (item.type === "Link") {
		return isLinkVisiable(item);
	} else {
		return isSubmenuVisiable(item);
	}
};

const isSubmenuVisiable = (item: SubmenuMenuItemType): boolean =>
	item.hidden !== true &&
	item.items.length > 0 &&
	item.items.reduce((visiable, subItem) => visiable || isItemVisiable(subItem), false as boolean);

const isLinkVisiable = (item: LinkMenuItemType): boolean =>
	item.hidden !== true && item.accessRights !== AccessRights.None;

export const getAllVisiableLinkItems = (items: MenuItemType[], result: LinkMenuItemType[] = []) => {
	items.forEach(item => {
		if (item.type === "Link") {
			if (item.accessRights !== AccessRights.None) result.push(item);
		} else {
			getAllVisiableLinkItems(item.items, result);
		}
	});
	return result;
};
