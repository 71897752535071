import React from "react";
import { useDisplayPayType } from "./useDisplayPayType";
import { PayType } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defaultPayType: PayType = {
  payTypeDescr: "",
  payTypeDescrEn: "",
  status: Status.Active,
  inPonder: Status.Active,
};

export const useEditPayType = (paytypeId?: string) => {
  const displayPayType = useDisplayPayType(paytypeId);

  return React.useMemo(() => {
    if (paytypeId !== undefined && displayPayType === undefined) {
      return undefined;
    }

    if (paytypeId === undefined) {
      return { ...defaultPayType };
    }

    return displayPayType ? { ...displayPayType } : undefined;
  }, [paytypeId, displayPayType]);
};

export const useEditPayTypeState = (paytypeId?: string) => {
  const payType = useEditPayType(paytypeId);
  return useCopiedState(payType);
};
