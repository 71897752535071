import { webApi } from "../../../../core/http/httpThunks";

export const deleteRoleApi = (userId: string, roleid: string) =>
	webApi<string>({
		method: "DELETE",
		url: "listing/delrole",
		params: {
			userId,
			roleid
		},
	});

export default deleteRoleApi;
