import { ThunkType } from "../../../../core/store/reducers";
import getNewsApi from "../api/getNews";
import { setDisplayed } from "../newsDataReducer";
import { NewsData } from "../types";

export const loadNews = (newsid: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const news = await dispatch(getNews(newsid));

  dispatch(setDisplayed(news));
};

export const getNews = (
  newsid: string
): ThunkType<Promise<NewsData | undefined>> => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getNewsApi(userId, newsid));
};
