import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { Country } from "../../../data/listing/countries/types";

export function useCountryColumns() {
  const { getString } = useContext(LocalizationContext);
  return useMemo(
    () => [
      {
        title: getString("countryName"),
        field: "cntName",
      },
    ],
    [getString]
  ) as Column<Country>[];
}
