import { PayType, PayTypeValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const payTypeValidationState: PayTypeValidationState = {
  payTypeDescr: true,
  payTypeDescrEn: true,
};

export const usePayTypeValidator = createUseValidator<PayType, PayTypeValidationState>(
  payTypeValidationState
);
