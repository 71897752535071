import React from "react";
import { Employee } from "../../../data/listing/employees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { City } from "../../../data/models";
import { CitySelect } from "../../components/common/CitySelect";

interface EmployeeCitySelectProps {
	employee: Employee;
	changeCity: (city: string, zipCode: string) => void;
}

export const EmployeeCitySelect: React.FunctionComponent<EmployeeCitySelectProps> = ({
	employee,
	changeCity,
}) => {
	const { getString } = React.useContext(LocalizationContext);

	const findEmployeeCity = React.useCallback(
		(c: City) => c.cityName === employee.city || c.zipCode === employee.zipCode,
		[employee]
	);

	const handleCityChange = React.useCallback(
		(newValue: City | null) => {
			changeCity(newValue ? newValue.cityName : "", newValue ? newValue.zipCode : "");
		},
		[changeCity]
	);

	return <CitySelect predicate={findEmployeeCity} onChangeItem={handleCityChange} label={getString("empCity")} />;
};
