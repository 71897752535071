import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { TransportFee } from "../types";

export const createTransportFeeApi = (userId: string, trfee: TransportFee) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "clearing/newtransfee",
    params: {
      userId,
    },
    data: trfee,
  });

export default createTransportFeeApi;
