import { ThunkType } from "../../../../../core/store/reducers";
import createClientContractApi from "../../api/clientContract/createClientContract";
import updateClientContractApi from "../../api/clientContract/updateClientContract";
import { setFetchStateContract } from "../../clientsDataReducer";
import { ClientContract } from "../../types";

export const upsertClientContract = (contract: ClientContract): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchStateContract({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = contract.cliContrId
		? await dispatch(updateClientContractApi(userId, contract))
		: await dispatch(createClientContractApi(userId, contract));

	if (result && result.resCode === "OK") {
		dispatch(setFetchStateContract({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchStateContract({ type: "error", errorCode: result?.resCode }));
	}
};
