import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const usePageStyles = makeStyles((theme: Theme) =>
	createStyles({
		rootSpacing: {
  			width: "calc(100% + 8px)",
			padding: "1rem",
		},
		root: {
			padding: "1rem",
		},
		gridItem: {
			marginBottom: "2rem",
		},
		mainFab: {
			margin: 0,
			top: "auto",
			right: 20,
			bottom: 20,
			left: "auto",
			position: "fixed",
		},
		fabProgress: {
			color: theme.palette.secondary.dark,
			zIndex: 1,
			right: 14,
			bottom: 14,
			position: "fixed",
		},
		fabProgressWrapper: {
			margin: theme.spacing(1),
			position: "relative",
		},
		fabSuccess: {
			backgroundColor: theme.palette.success.main,
			"&:hover": {
				backgroundColor: theme.palette.success.dark,
			},
		},
	})
);
