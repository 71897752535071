import React from "react";
import { CalculationParams, ShouldCalc } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { TradeList } from "../../../trading/trades/types"
import { OrderMarkt } from "../../../trading/orders/types";

export const createDefaultState = (trade: TradeList): CalculationParams => ({
	shouldCalcDistance: trade.market === OrderMarkt.Forward ? ShouldCalc.False : ShouldCalc.True,
	shouldCalcStorage: ShouldCalc.False,
	tradeid: trade.tradeId,
});

export const useEditCalculationParamsState = (trade: TradeList) => {
	const state = React.useMemo(() => createDefaultState(trade), [trade]);
	return useCopiedState(state);
};
