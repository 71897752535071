import React from "react";
import { useEditClientContractState } from "../../../../data/listing/clients/hooks/clientContract/useEditClientContractState";
import { useClientContractValidator } from "../../../../data/listing/clients/hooks/clientContract/useClientContractValidator";
import { useClientContractUpsert } from "../../../../data/listing/clients/hooks/clientContract/useClientContractUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../../pageStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../../components/common/FabSave";
import {
	ClientContract,
	ClientContractList,
	ContractWebUser,
	ContractWebUserBlocked,
} from "../../../../data/listing/clients/types";
import { StringInput } from "../../../components/common/StringInput";
import { BoolInput } from "../../../components/common/BoolInput";
import { ClientEditParts, getClientsEditLocation } from "../Locations";
import { DateInput } from "../../../components/common/DateInput";
import { ClientContractSelect } from "../../../components/listing/ClientContractSelect";
import { Status } from "../../../../data/models";
import { LeavePrompt } from "../../../components/common/LeavePrompt";
import { PageTitle } from "../../../components/common/PageTitle";
import { ContractTypeSelect } from "../../../components/common/ContractTypeSelect";

export interface EditContractInfo {
	contactId?: string;
	clientId: string;
}

export interface EditContractProps extends RouteComponentProps<EditContractInfo> {}

export const EditContract: React.FunctionComponent<EditContractProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const { clientId, contactId } = props.match.params;
	const [contract, editContract, changed] = useEditClientContractState(clientId, contactId);
	const [validationState, validate] = useClientContractValidator();
	const [fetchState, upsert] = useClientContractUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editContract(event.target.name as any, event.target.value);
		},
		[editContract]
	);

	const changeLinkedContract = React.useCallback(
		(linkedContract: ClientContractList | null) => {
			editContract("linkedContrId", linkedContract ? linkedContract.cliContrId : "");
			editContract("linkedContrNo", linkedContract ? linkedContract.linkedContrNo : "");
		},
		[editContract]
	);

	const backLocation = React.useMemo(() => getClientsEditLocation(ClientEditParts.Contracts, clientId), [
		clientId,
	]);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(backLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(contract);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [contract, validate, fetchState, history, upsert, backLocation]);

	const pageTitle = contract && contract.cliContrId ? "contractTitleEdit" : "contractTitleCreate";

	if (!contract) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={backLocation} />} />
					<Divider />
					<CardContent>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={3}>
								<StringInput<ClientContract>
									property="contrNo"
									item={contract}
									validationState={validationState}
									onChange={handleChange}
									label={getString("contractNumber")}
								/>
							</Grid>
							<Grid item xs={3}>
									<ContractTypeSelect
										label={getString("paymentPayType")}
										predicate={contractTypes => contractTypes.ftValue === contract.contrType}
										onChangeItem={contract =>{
											editContract("contrType", contract ? contract.ftValue : undefined)
										}
									}
									error={validationState.contrType !== undefined && !validationState.contrType}
									/>
								</Grid>
							<Grid item xs={3}>
								<StringInput<ClientContract>
									property="contrDescr"
									item={contract}
									validationState={validationState}
									onChange={handleChange}
									label={getString("contractDescription")}
								/>
							</Grid>
							<Grid item xs={3}>
								<DateInput
									label={getString("contractDate")}
									value={contract.contrDate || null}
									onChange={date => editContract("contrDate", date)}
									required={validationState.contrDate !== undefined}
									error={
										validationState.contrDate !== undefined && !validationState.contrDate
									}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={3}>
								<ClientContractSelect
									label={getString("contractLincedContrNo")}
									clientId={clientId}
									predicate={linked => linked.cliContrId === contract.linkedContrId}
									onChangeItem={changeLinkedContract}
									error={
										validationState.linkedContrNo !== undefined && !validationState.linkedContrNo
									}
									disabled={contract.contrType === 10}
								/>
							</Grid>
							<Grid item xs={3}>
								<StringInput<ClientContract>
									property="phoneOrder"
									item={contract}
									validationState={validationState}
									onChange={handleChange}
									label={getString("contractPhoneOrder")}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="flex-start"
							spacing={4}
						>
							<Grid item xs={3}>
								<BoolInput<ClientContract>
									property="webUser"
									item={contract}
									onChange={handleChange}
									falsyValue={ContractWebUser.False}
									thrutyValue={ContractWebUser.True}
									label={getString("contractWebUser")}
								/>
							</Grid>
							<Grid item xs={3}>
								<BoolInput<ClientContract>
									property="webUserBlocked"
									item={contract}
									onChange={handleChange}
									falsyValue={ContractWebUserBlocked.False}
									thrutyValue={ContractWebUserBlocked.True}
									label={getString("contractWebUserBlocked")}
								/>
							</Grid>
							<Grid item xs={3}>
								<BoolInput<ClientContract>
									property="status"
									item={contract}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("contractStatus")}
								/>
							</Grid>
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
