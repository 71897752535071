import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../../models";
import { successfulLogin } from "../../../auth/authDataSlice";
import { ClientOverviewFilter, TableType } from "./types";

type ClientOverviewPageState = {
	index: {
		[k in TableType]: PageIndex;
	};
	filter: ClientOverviewFilter;
};

export interface IndexArgument {
	type: TableType;
	index: PageIndex;
}

export const initialState: ClientOverviewPageState = {
	index: {
		[TableType.Orders]: {
			itemsPerPage: 20,
			pageNumber: 0,
		},
		[TableType.Trades]: {
			itemsPerPage: 20,
			pageNumber: 0,
		},
		[TableType.Deliveries]: {
			itemsPerPage: 20,
			pageNumber: 0,
		},
		[TableType.Payments]: {
			itemsPerPage: 20,
			pageNumber: 0,
		},
	},
	filter: {
	},
};

const name = "clientOverview";

const clientOverviewSlice = createSlice({
	name,
	initialState,
	reducers: {
		setClientOverviewIndex: (state, action: PayloadAction<IndexArgument>) => ({
			...state,
			index: {
				...state.index,
				[action.payload.type]: action.payload.index,
			},
		}),
		resetClientOverviewIdices: (state, action: PayloadAction<TableType>) => ({
			...state,
			index: {
				...state.index,
				[action.payload]: {
					itemsPerPage: state.index[action.payload].itemsPerPage,
					pageNumber: initialState.index[action.payload].pageNumber,
				},
			},
		}),
		setFilter: (state, action: PayloadAction<ClientOverviewFilter>) => ({
			...state,
			filter: action.payload,
		}),
		removeFilter: (state, action: PayloadAction<keyof ClientOverviewFilter>) => ({
			...state,
			filter: {
				...state.filter,
				[action.payload]: undefined,
			},
		}),
	},

	extraReducers: builder => {
		builder.addCase(successfulLogin, state => ({
			...state,
			index: initialState.index,
		}));
	},
});

export const {
	setClientOverviewIndex,
	resetClientOverviewIdices,
	setFilter,
	removeFilter,
} = clientOverviewSlice.actions;

export default clientOverviewSlice.reducer;
