import { ThunkType } from "../../../../core/store/reducers";
import { setBankGuarantees, invalidateBankGuarantee } from "../bankguaranteesDataSlice";
import getBankGuaranteesSelected from "../api/getBankGuaranteesSelected";
import { SimplBankGuaranteeFilter } from "../types";

export const loadAllBankGuaranteesSelected = (
  filter: SimplBankGuaranteeFilter
): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const user = store.data.auth.current.user;
  const bankguaranteesResponse = await dispatch(
    getBankGuaranteesSelected(user.userId, filter)
  );

  if (!bankguaranteesResponse || bankguaranteesResponse.resCode !== "OK") {
    return;
  }

return bankguaranteesResponse.obj;
};

