import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import { ViewOrder } from "../../../../../data/trading/orders/types";
import { LocalizationContext } from "../../../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewOrder } from "../../../../trading/orders/DisplayOrder";
import { DiplayOrderMod } from "../../../../trading/orders/DisplayOrderMainAttributes";

export function useOrderActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowOBOrder = useViewOrder(DiplayOrderMod.Full, true);

  return useMemo(() => {
    const result: MaterialTableProps<ViewOrder>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) => onShowOBOrder(rowData as ViewOrder),
      },
    ];
    return result;
  }, [onShowOBOrder, getString]);
}


