import { CtrlGoodsFilter } from "../types";
import { CtrlGoodsListData, GetCtrlGoodsParams } from "../types";
import { User } from "../../../auth/types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getCtrlGoodsWebApi = (
  user: User,
  page: number,
  itemsPerPage: number,
  filter: CtrlGoodsFilter
) => {
  const params: GetCtrlGoodsParams = {
    userid: user.userId,
    fromrow: page * itemsPerPage,
    numofrows: itemsPerPage,
    sellerid: filter?.seller?.cliId,
    buyerid:filter?.buyer?.cliId,
    cliid:filter?.client?.cliId,
    prodid:filter?.product?.prodId,
    datefrom: getParamsDateString(filter.datefrom),
    dateto: getParamsDateString(filter.dateto),
  };

  return webApiWithResponse<CtrlGoodsListData[]>({
    method: "GET",
    url: "clearing/getctrlgoodslist",
    params,
  });
};

export default getCtrlGoodsWebApi;
