import React from "react";
import { useDispatch } from "react-redux";
import { getSimpleTradesThunk } from "../thunks/getSimpleTradesThunk";
import { SimpleTransFilter, TransSimpleData } from "../types";

export const useSimpleTradesData = (filter?: SimpleTransFilter) => {
	const dispatch = useDispatch();
	const [orders, setOrders] = React.useState<TransSimpleData[] | undefined>();

	React.useEffect(() => {
		const loadOrders = async () => {
			if (filter) {
				const loaded = ((await dispatch(getSimpleTradesThunk(filter))) as any) as TransSimpleData[];
				setOrders(loaded);
			}
		};
		if (!filter) {
			setOrders(undefined);
		} else {
			loadOrders();
		}
	}, [filter, dispatch]);

	return [orders] as [typeof orders];
};
