import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useATRFilter } from "../../../data/reports/annualTrade/hooks/useATRFilter";
import {
  ATRFilterEditor,
  ATRFilterEditorType,
} from "../annualTrades/ATRFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const ATRFilterButton: React.FunctionComponent = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const showFilter = React.useCallback(
    () => showWithDrawer(<ATRsFilterEditor />),
    [showWithDrawer]
  );

  return (
    <CustomTooltip titleKeyword="filterTooltip">
      <IconButton color="secondary" component="span" onClick={showFilter}>
        <FilterIcon />
      </IconButton>
    </CustomTooltip>
  );
};

const ATRsFilterEditor: React.FunctionComponent = () => {
  const [filter, editFilter, save, , reset] = useATRFilter();
  return (
    <>
      {filter && (
        <ATRFilterEditor
          filter={filter}
          editFilter={editFilter}
          save={save}
          type={ATRFilterEditorType.Basic}
          reset={reset}
        />
      )}
    </>
  );
};
