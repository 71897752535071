import { ThunkType } from "../../../../core/store/reducers";
import createGuarantees from "../api/createGuarantees";
import updateGuarantees from "../api/updateGuarantees";
import { GuaranteeData } from "../types";
import { setFetchState, invalidateGuarantee } from "../guaranteesDataSlice";

export const upsertGuarantee = (guarantee: GuaranteeData): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = guarantee.payId
    ? await dispatch(updateGuarantees(userId, guarantee))
    : await dispatch(createGuarantees(userId, guarantee));

  if (result && result.resCode === "OK") {
    dispatch(setFetchState({ type: "successful", result: result.resDesc }));
    dispatch(invalidateGuarantee());
    return result.resDesc;
  } else {
    dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
  }
};
