import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteStockFee } from "../thunks/deleteStockFee";
import { setFetchState } from "../stockFeesDataReducer";

export const useStockFeeDelete = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.clearing.stockfees.fetchState
  );

  const dispatch = useDispatch();
  const dispatchDeleteStockFee = React.useCallback(
    (id: number) => dispatch(deleteStockFee(id)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchDeleteStockFee] as [
    typeof fetchState,
    typeof dispatchDeleteStockFee
  ];
};
