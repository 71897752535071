import React from "react";
import { useDisplayCurrencyRate } from "../../../data/listing/curencyRates/hooks/useDisplayCurrencyRate";
import { CurrencyRate } from "../../../data/listing/curencyRates/types";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayCurrencyBasicInfo } from "./DisplayCurrencyRateBasicInfo";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid } from "@material-ui/core";

export interface DisplayCurrencyProps {
	id?: number;
}

export const DisplayCurrency: React.FunctionComponent<DisplayCurrencyProps> = ({ id }) => {
	const rate = useDisplayCurrencyRate(id);
	const { getString } = React.useContext(LocalizationContext);

	return !rate ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel expanded>
				<ExpansionPanelSummary>
					<Typography>{getString("tableBasciInfo")}</Typography>
				</ExpansionPanelSummary>
				<DisplayCurrencyBasicInfo rate={rate} />
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewCurrency = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(rate: CurrencyRate) => showWithDrawer(<DisplayCurrency id={rate.currRateId} />),
		[showWithDrawer]
	);
};
