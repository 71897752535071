
import { useCopiedState } from "../../../../core/util/hooks";
import { setFilter, removeFilter, initialState } from "../newsUXSlice";
import { invalidateNews } from "../newsDataReducer";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { NewsDataFilter } from "../types";

export const useNewsFilter = () => {
  const [storeFilter] = useCurrentNewsFilter();
  const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<
  NewsDataFilter
  >(storeFilter);

  const dispatch = useDispatch();
  const dispatchSetFilter = React.useCallback(() => {
    dispatch(setFilter(filter!));
    dispatch(invalidateNews());
  }, [dispatch, filter]);

  const dispatchResetFilter = React.useCallback(() => {
    setCurrentFilter(initialState.filter);
  }, [setCurrentFilter]);

  return [
    filter,
    editFilter,
    dispatchSetFilter,
    changeState,
    dispatchResetFilter,
  ] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter,
    typeof changeState,
    typeof dispatchResetFilter
  ];
};

export const useCurrentNewsFilter = () => {
  const filter = useSelector<StoreType, NewsDataFilter>(
    (store) => store.ux.reports.news.filter
  );
  const dispatch = useDispatch();
  const dispatchRemoveFilter = React.useCallback(
    (type: keyof NewsDataFilter) => {
      dispatch(removeFilter(type));
      dispatch(invalidateNews());
    },
    [dispatch]
  );

  return [filter, dispatchRemoveFilter] as [
    typeof filter,
    typeof dispatchRemoveFilter
  ];
};
