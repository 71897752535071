import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { useAlarmFilter, useCurrentAlarmFilter } from "../../../data/surveillance/alarmParameters/hooks/useAlarmFilter";
import {
  AlarmFilterEditor,
  AlarmFilterEditorType,
} from "./AlarmsFilterEditor";
import { AlarmFilterChipsComponent } from "./AlarmsFilterChips";
import { CustomTooltip } from "../../components/common/CustomTooltip";

export const AlarmFilterButton: React.FunctionComponent = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  const showFilter = React.useCallback(
    () => showWithDrawer(<AlarmsFilterEditor />),
    [showWithDrawer]
  );

	const [filter, removeFilter] = useCurrentAlarmFilter();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <AlarmFilterChipsComponent filter={filter} removeFilter={removeFilter} />
      </Grid>
      <Grid item>
        <CustomTooltip titleKeyword="filterTooltip">
          <IconButton color="secondary" component="span" onClick={showFilter}>
            <FilterIcon />
          </IconButton>
        </CustomTooltip>
      </Grid>
    </Grid>
  );
};


const AlarmsFilterEditor: React.FunctionComponent = () => {
  const [filter, editFilter, save, ,rest] = useAlarmFilter();
  return (
    <AlarmFilterEditor
      filter={filter}
      editFilter={editFilter}
      save={save}
      type={AlarmFilterEditorType.Extended}
			reset={rest}
    />
  );
};
