import React from "react";
import { AttrCompType } from "../../types";

interface AttrCompTypeOption {
	label: string;
	value: AttrCompType;
}

 export const useAttributeComparatorType = (): AttrCompTypeOption[] => {
	return React.useMemo(
		() => [
			{
				label: (AttrCompType.LessThan) as string,
				value: AttrCompType.LessThan,
			},
			{
				label: (AttrCompType.Equal) as string,
				value: AttrCompType.Equal,
			},
			{
				label: (AttrCompType.MoreThan) as string,
				value: AttrCompType.MoreThan,
			},
		],
		[]
	);
};  

