import { Currency, CurrencyValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const currencyValidationState: CurrencyValidationState = {
	currCode: true,
	currName: true,
	numCode: true,
};

export const useCurrencyValidator = createUseValidator<Currency, CurrencyValidationState>(
	currencyValidationState
);
