import { webApi } from "../../../../core/http/httpThunks";

export const deleteProductGroupApi = (userId: string, prodgroupid: string) =>
	webApi<string>({
		method: "DELETE",
		url: "listing/delprodgroup",
		params: {
			userId,
			prodgroupid
		},
	});

export default deleteProductGroupApi;
