import { NavigationItem } from "../../../core/navigation/types";
import UserPage from "./UserPage";
import { EditUserPage } from "./EditUserPage";
import { usersLocation, editRouter } from "./Locations";
export const userComponents: NavigationItem[] = [
	{
		Component: UserPage,
		isMenuItem: true,
		path: usersLocation,
	},
	{
		Component: EditUserPage,
		isMenuItem: false,
		path: editRouter,
	},
];
