import React from "react";
import { useDisplayWhs } from "../../../data/listing/warehouses/hooks/useDisplayWhs";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { Warehouse } from "../../../data/listing/warehouses/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayWhsMainAttributes } from "./DisplayWhsMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,
} from "@material-ui/core";

export interface DisplayWhsProps {
  id?: string;
}

export const DisplayWhs: React.FunctionComponent<DisplayWhsProps> = ({
  id,
}) => {
  const whs = useDisplayWhs(id);
  const { getString } = React.useContext(LocalizationContext);

  return !whs ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Typography>{getString("tableBasciInfo")}</Typography>
        </ExpansionPanelSummary>
        <DisplayWhsMainAttributes whs={whs} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewWhs = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (whs: Warehouse) => showWithDrawer(<DisplayWhs id={whs.whsId} />),
    [showWithDrawer]
  );
};
