import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { MarginsData } from "../../../data/clearing/marginsData/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewMarginsData } from "./DisplayMarginsData";
import { useHistory } from "react-router-dom";
import { getMarginsDataEditLocation } from "../marginsData/Locations";
import { DisplayMarginsDataMainAttributes } from "../marginsData/DisplayMarginsDataMainAttributes";

export function useMarginsDataActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowMarginsData = useViewMarginsData();

  return useMemo(() => {
    const result: MaterialTableProps<MarginsData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) => onShowMarginsData(rowData as MarginsData),
      },
    ];
    return result;
  }, [onShowMarginsData,  getString]);
}

