import React from "react";
import { TradingData } from "../../../data/clearing/tradingData/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status } from "../../../data/models";

export interface DisplayTradingDataMainAttributesProps {
  tradingData: TradingData;
}

export const DisplayTradingDataMainAttributes: React.FunctionComponent<DisplayTradingDataMainAttributesProps> = ({
  tradingData,
}) => {
  const style = useDisplaySingleEmementStyles();
  const { getString, getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataDataTime")}</span>
        <span className={style.value}>{getDateString(tradingData.tradingDate)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataProductCode")}</span>
        <span className={style.value}>{tradingData.productCode}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataProductName")}</span>
        <span className={style.value}>{tradingData.productName}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataRefPrice")}</span>
        <span className={style.value}>{getFormatedNumberString(tradingData.refPrice, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataBestBid")}</span>
        <span className={style.value}>{getFormatedNumberString(tradingData.bestBid, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataBestBidQty")}</span>
        <span className={style.value}>{getFormatedNumberString(tradingData.bestBidQty, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataBestAsk")}</span>
        <span className={style.value}>{getFormatedNumberString(tradingData.bestAsk, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataBestAskQty")}</span>
        <span className={style.value}>{getFormatedNumberString(tradingData.bestAskQty, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataHighPrice")}</span>
        <span className={style.value}>{getFormatedNumberString(tradingData.highPrice, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataLowPrice")}</span>
        <span className={style.value}>{getFormatedNumberString(tradingData.lowPrice, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataVolume")}</span>
        <span className={style.value}>{getFormatedNumberString(tradingData.volume, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataAmount")}</span>
        <span className={style.value}>{getFormatedNumberString(tradingData.amount, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataWeightedPrice")}</span>
        <span className={style.value}>{getFormatedNumberString(tradingData.weightedPrice, true)}</span>
      </div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("tradingDataMidPrice")}</span>
        <span className={style.value}>{getFormatedNumberString(tradingData.midPrice, true)}</span>
      </div>
    </div>
  );
};
