import { webApi } from "../../../../core/http/httpThunks";
import { Commission } from "../types";

export const getCommisionApi = (userId: string, commid: string) =>
	webApi<Commission>({
		method: "GET",
		url: "listing/getcomm",
		params: {
			userId,
			commid
		},
	});

export default getCommisionApi;
