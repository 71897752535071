import { webApi } from "../../../../core/http/httpThunks";

export const deleteAlarmApi = (userId: string, almid: string) =>
  webApi<string>({
    method: "DELETE",
    url: "surveillance/delalarmdef",
    params: {
      userId,
      almid,
    },
  });

export default deleteAlarmApi;
