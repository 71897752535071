import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";
import { upsertClientAccount } from "../../thunks/clientAccount/upsertClientAccount";
import { setFetchStateAccount } from "../../clientsDataReducer";
import { ClientAccount } from "../../types";

export const useClientAccountUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.clients.fetchStateAccount
	);

	const dispatch = useDispatch();
	const dispatchUpsertContract = React.useCallback(
		(account: ClientAccount) => dispatch(upsertClientAccount(account)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateAccount({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchUpsertContract] as [typeof fetchState, typeof dispatchUpsertContract]
};
