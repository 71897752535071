import React from "react";
import { useDisplayAttribute } from "./useDisplayAttribute";
import { Attribute, AttributeManditory, AttributeType, AttrCompType} from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

export const defauiltAttribute: Attribute = {
	mandatory: AttributeManditory.False,
	attrName: "",
	attrNameen: "",
	attrType: AttributeType.Text,
	comparator: AttrCompType.LessThan,
	status: Status.Active,
};

export const useAttributeState = (attributeId?: string) => {
	const displayRole = useDisplayAttribute(attributeId);

	return React.useMemo(() => {
		if (attributeId !== undefined && displayRole === undefined) {
			return undefined;
		}

		if (attributeId === undefined) {
			return { ...defauiltAttribute };
		}

		return displayRole ? { ...displayRole } : undefined;
	}, [attributeId, displayRole]);
};

export const useEditAttributeState = (id?: string) => {
	const attribute = useAttributeState(id);
	return useCopiedState(attribute);
};
