import { webApi } from "../../../../core/http/httpThunks";

export const deleteNewsApi = (userId: string, newsid: string) =>
  webApi<string>({
    method: "DELETE",
    url: "news/delnews",
    params: {
      userId,
      newsid,
    },
  });

export default deleteNewsApi;
