import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { DeliveryData } from "../types";

export const updateDeliveryApi = (userId: string, deliveries: DeliveryData) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "clearing/upddlvrgoods",
    params: {
      userId,
    },
    data: deliveries,
  });

export default updateDeliveryApi;
