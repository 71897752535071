import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageIndex } from "../../models";
import { successfulLogin } from "../../auth/authDataSlice";
import { CreateInvoicesTranFilter } from "./types";

type CreateInvoicesPageState = {
  index: PageIndex;
  filter: CreateInvoicesTranFilter;
};

const now = new Date();
export const initialState: CreateInvoicesPageState = {
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
  filter: {
    dateto: now.toISOString(),
    datefrom: now.toISOString(),
  },
};

const name = "createInvoices";

const createInvoicesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    resetCreateInvoicesIdices: (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }),
    setFilter: (state, action: PayloadAction<CreateInvoicesTranFilter>) => ({
      ...state,
      filter: action.payload,
    }),
    removeFilter: (
      state,
      action: PayloadAction<keyof CreateInvoicesTranFilter>
    ) => ({
      ...state,
      filter: {
        ...state.filter,
        [action.payload]: undefined,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(successfulLogin, (state) => ({
      ...state,
      index: {
        itemsPerPage: state.index.itemsPerPage,
        pageNumber: initialState.index.pageNumber,
      },
    }));
  },
});

export const {
  setIndex,
  resetCreateInvoicesIdices,
  setFilter,
  removeFilter,
} = createInvoicesSlice.actions;

export default createInvoicesSlice.reducer;
