import { DisputeFilter } from "../types";
import { DisputeListData, GetDisputeParams } from "../types";
import { User } from "../../../auth/types";
import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getDisputesWebApi = (
  user: User,
  page: number,
  itemsPerPage: number,
  filter: DisputeFilter
) => {
  const params: GetDisputeParams = {
    userid: user.userId,
    fromrow: page * itemsPerPage,
    numofrows: itemsPerPage,
    sellerid: filter?.seller?.cliId,
    buyerid:filter?.buyer?.cliId,
    cliid:filter?.client?.cliId,
    prodid:filter?.product?.prodId,
    datefrom: getParamsDateString(filter.datefrom),
    dateto: getParamsDateString(filter.dateto),
  };
  
  return webApiWithResponse<DisputeListData[]>({
    method: "GET",
    url: "clearing/getdispsitlist",
    params,
  });
};

export default getDisputesWebApi;
