import { NavigationItem } from "../../../core/navigation/types";
import { EditCityPage } from "./EditCityPage";
import CityPage from "./CitiesPage";
import { citiesLocation, editRouter } from "./Locations";
export const cityComponents: NavigationItem[] = [
	{
		Component: CityPage,
		isMenuItem: true,
		path: citiesLocation,
	},
	{
		Component: EditCityPage,
		isMenuItem: false,
		path: editRouter,
	},
];
