import React from "react";
import { User } from "../../data/auth/types";
import { useCapacitorAppState } from "../util/useCapacitorAppState";
import { NotificationContext } from "./NotificationContext";

export const useNotificationHandler = <TNotification>(
	type: string,
	handler: (e: TNotification) => void,
	deps: React.DependencyList
) => {
	const connectionManager = React.useContext(NotificationContext);
	const handlerCallback = React.useCallback(handler, deps);
	React.useEffect(
		() => connectionManager.registerHandler(type, handlerCallback),
		[connectionManager, type, handlerCallback]
	);
};

export const useAlarmsChangedHandler = () => {
	const connectionManager = React.useContext(NotificationContext);
	useNotificationHandler("AlarmsModified", () => connectionManager.reconnect(), [connectionManager]);
};

export const canRecieveNotifications = (user: User) =>
	user.roleCode === "BROKER" || user.roleCode === "CONTROL";

export const useNotificationStatus = () => {
	const connectionManager = React.useContext(NotificationContext);
	const { isVisiable } = useCapacitorAppState()

	const [isActive, setIsActive] = React.useState(connectionManager.isActive());

	React.useEffect(() => {
		connectionManager.onStatusChange(setIsActive);
	}, [connectionManager, setIsActive]);

	React.useEffect(() => {
		if (isVisiable) {
			setIsActive(connectionManager.isActive());
		}
	}, [connectionManager, isVisiable, setIsActive]);

	return isActive;
};
