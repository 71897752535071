import { useDispatch, useSelector } from "react-redux";
import { downloadOrdersReport } from "../thunks/downloadReport";
import React from "react";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";

export const useQuarterlySecurityReportDownloader = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.reports.securityCommission.quarterlylSecurity.downloadReportFetchState
	);
	const dispatch = useDispatch();
	const download = React.useCallback(() => dispatch(downloadOrdersReport()), [dispatch]);

	return [download, fetchState] as [typeof download, typeof fetchState];
};
