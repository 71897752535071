import PushNotificationsReport from "./PushNotificationsReport";
import { NavigationItem } from "../../../core/navigation/types";
import { pushNotificationsReportLocation } from "./Locations";

export const pushNotificationsComponents: NavigationItem[] = [
  {
    Component: PushNotificationsReport,
    isMenuItem: true,
    path: pushNotificationsReportLocation,
  },
];
