import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../../models";
import { WeightedPricesReportData } from "./types";

type TradesReportData = {
  data?: WeightedPricesReportData[];
  downloadReportFetchState: FetchStateType;
};

const initialState: TradesReportData = {
  downloadReportFetchState: { type: "not-started" },
};

const name = "weightedPrices";

const weightedPricesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<WeightedPricesReportData[] | undefined>
    ) => ({
      ...state,
      data: action.payload,
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      downloadReportFetchState: action.payload,
    }),
  },
});

export const { setData, setFetchState } = weightedPricesSlice.actions;

export default weightedPricesSlice.reducer;
