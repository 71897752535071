import React from "react";
import { TradeList } from "../../../data/trading/trades/types";
import { Grid, InputAdornment, Button, TextField, Typography } from "@material-ui/core";
import { useEditCalculationParamsState } from "../../../data/clearing/transportAndStorage/hooks/useEditCalculationParamsState";
import { useCalculationParamsValidator } from "../../../data/clearing/transportAndStorage/hooks/useCalculationParamsValidator";
import { useTASCalculator } from "../../../data/clearing/transportAndStorage/hooks/useTASCalculator";
import { useTASCostSaver } from "../../../data/clearing/transportAndStorage/hooks/useTASCostSaver";
import { BoolInput } from "../../components/common/BoolInput";
import { CalculationParams, ShouldCalc } from "../../../data/clearing/transportAndStorage/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { NumberInput } from "../../components/common/NumberInput";
import { OrderMarkt } from "../../../data/trading/orders/types";

export interface CancelTradeProps {
	trade: TradeList;
	close: () => void;
}

export const ClaculateTASCosts: React.FunctionComponent<CancelTradeProps> = ({ trade, close }) => {
	const { getString } = React.useContext(LocalizationContext);
	const [params, editParams] = useEditCalculationParamsState(trade);
	const [validationState, validate] = useCalculationParamsValidator();
	const [calculationFetchState, calculate, reset] = useTASCalculator();
	const [saveFetchState, save] = useTASCostSaver();

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editParams(event.target.name as any, event.target.value);
		},
		[editParams]
	);

	React.useEffect(() => {
		if (params?.shouldCalcDistance === ShouldCalc.False && params.distance !== undefined) {
			params.distance = undefined;
		}
	}, [params]);

	const tasCosts = React.useMemo(
		() => (calculationFetchState.type === "successful" ? calculationFetchState.result : undefined),
		[calculationFetchState]
	);
	const onCalculate = React.useCallback(() => {
		const validParams = validate(params);
		if (validParams) {
			calculate(validParams);
		}
	}, [calculate, validate, params]);

	const onSave = React.useCallback(() => {
		if (tasCosts) {
			save(tasCosts);
		}
	}, [save, tasCosts]);

	React.useEffect(() => {
		if (saveFetchState.type === "successful") {
			close();
		}
	}, [saveFetchState, close]);

	if (!params) return null;


	return (
		<Grid container direction="column" spacing={3}>
			<Grid item>
				<BoolInput<CalculationParams>
					property="shouldCalcDistance"
					item={params}
					onChange={handleChange}
					falsyValue={ShouldCalc.False}
					thrutyValue={ShouldCalc.True}
					label={getString("TASCalTransport")}
					disabled={calculationFetchState.type !== "not-started"}
				/>
			</Grid>
			{params.shouldCalcDistance === ShouldCalc.True && (
				<Grid item>
					<NumberInput<CalculationParams>
						property="distance"
						item={params}
						validationState={validationState}
						onChange={handleChange}
						label={getString("TASDistance")}
						InputProps={{
							endAdornment: <InputAdornment position="end">{"km"}</InputAdornment>,
						}}
						disabled={calculationFetchState.type !== "not-started"}
					/>
				</Grid>
			)}
			{trade.market === OrderMarkt.Forward && (
				<Grid item>
					<BoolInput<CalculationParams>
						property="shouldCalcStorage"
						item={params}
						onChange={handleChange}
						falsyValue={ShouldCalc.False}
						thrutyValue={ShouldCalc.True}
						label={getString("TASCalcStorage")}
						disabled={calculationFetchState.type !== "not-started"}
					/>
				</Grid>
			)}
            {trade.transCostBuy+trade.transCostSell+trade.storCostBuy+trade.storCostSell !==0 && (
				<>
				<Typography variant="h6">
						{getString("TASWarning")}
				</Typography>

				</>
			)}
			{tasCosts && (
				<>
					<Grid item>
						<TextField
							value={tasCosts.transportCost}
							fullWidth
							label={getString("TASTransportCosts")}
							InputProps={{
								readOnly: true,
							}}
							variant="outlined"
						/>
					</Grid>
					<Grid item>
						<TextField
							value={tasCosts.storageCost}
							fullWidth
							label={getString("TASStorageCosts")}
							InputProps={{
								readOnly: true,
							}}
							variant="outlined"
						/>
					</Grid>
				</>
			)}
			{!tasCosts && (
				<Grid item container direction="row-reverse">
					<Button
						onClick={onCalculate}
						variant="contained"
						color="primary"
						disabled={
							calculationFetchState.type !== "not-started" ||
							(params.shouldCalcDistance === ShouldCalc.False &&
								params.shouldCalcStorage === ShouldCalc.False)
						}
					>
						{getString("TASCaclulate")}
					</Button>
				</Grid>
			)}
			{tasCosts && (
				<Grid item container direction="row-reverse" spacing={2}>
					<Grid item>
						<Button
							onClick={onSave}
							variant="contained"
							color="primary"
							disabled={saveFetchState.type !== "not-started"}
						>
							{getString("TASConfirm")}
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={reset} disabled={saveFetchState.type !== "not-started"}>
							{getString("TASCancel")}
						</Button>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};
