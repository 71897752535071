import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { OrderStatus } from "../../models";

const getStatusLabel = (status?: OrderStatus) => (status ? status.ftDescr : "");

export const useOrderStatuses = () => {
	const statuses = useSelector<StoreType, OrderStatus[]>(store => store.data.initialData.orderStatuses);

	return [statuses, getStatusLabel] as [typeof statuses, typeof getStatusLabel];
};
