import { ThunkType } from "../../../../core/store/reducers";
import { setData } from "../dailyTradeDataSlice";
import getDailyTradingDataApi from "../api/getDailyTradingData";

export const loadReport = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const user = store.data.auth.current.user;
	const { filter } = store.ux.reports.dailyTrade;

	const pagedTrades = await dispatch(getDailyTradingDataApi(user, filter));

	dispatch(setData(pagedTrades || []));
};
