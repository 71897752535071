import { ThunkType } from "../../../../core/store/reducers";
import getTradingDataApi from "../api/getTradingData";
import { setDisplayed } from "../tradingDataSlice";
import { TradingData } from "../types";

export const loadTradingData = (prodId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const tradingData = await dispatch(getTradingData(prodId));

  dispatch(setDisplayed(tradingData));
};

export const getTradingData = (
  prodId: string
): ThunkType<Promise<TradingData | undefined>> => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getTradingDataApi(userId, prodId));
};
