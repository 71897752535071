import { webApi } from "../../../../core/http/httpThunks";

export const deletePayTypeApi = (userId: string, paytypeid: string) =>
  webApi<string>({
    method: "DELETE",
    url: "listing/delpaytype",
    params: {
      userId,
      paytypeid,
    },
  });

export default deletePayTypeApi;
