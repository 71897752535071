import { webApi } from "../../../../core/http/httpThunks";

export const deleteUserApi = (userId: string, usrid: string) =>
	webApi<string>({
		method: "DELETE",
		url: "listing/deluser",
		params: {
			userId,
			usrid
		},
	});

export default deleteUserApi;
