import { DailyTradingReportData } from "../../../data/reports/dailyTrade/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const useDailyTradesColumns = () => {
	const { getString, getFormatedNumberString } = React.useContext(LocalizationContext);

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	return React.useMemo(
		() => [
			{
				title: getString("dailyTradeReportProduct"),
				field: "productName",
				sorting: false,
			},
			{
				title: getString("dailyTradeReportTurnoverRSD"),
				render: row => `${getFormatedNumberString(row.turnoverRsd,true)}`,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					textAlign: "end",
				},
			},
			{
				title: getString("dailyTradeReportTurnoverEUR"),
				render: row => `${getFormatedNumberString(row.turnoverEur,true)}`,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					textAlign: "end",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("dailyTradeReportCountTransaction"),
				field: "transactionCount",
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					textAlign: "end",
				},
				hidden: screenExtraSmall,
			},
			{
				title: getString("dailyTradeReportQuantity"),
				field: "quantity",
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					textAlign: "end",
				},
			},
			{
				title: getString("dailyTradeReportReportWeightedRSD"),
				render: row => `${getFormatedNumberString(row.weightedPriceRsd,true)}`,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					textAlign: "end",
				},
			},
			{
				title: getString("dailyTradeReportReportWeightedEUR"),
				render: row => `${getFormatedNumberString(row.weightedPriceEur,true)}`,
				sorting: false,
				cellStyle: {
					whiteSpace: "nowrap",
					textAlign: "end",
				},
				headerStyle: {
					textAlign: "end",
				},
				hidden: screenExtraSmall,
			},
		],
		[getString, getFormatedNumberString, screenExtraSmall]
	) as Column<DailyTradingReportData>[];
};
