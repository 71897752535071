import { webApi } from "../../../../../core/http/httpThunks";
import { ClientBroker } from "../../types";

export const getAllClientBrokers = (userId: string, cliid: string) =>
	webApi<ClientBroker[]>({
		method: "GET",
		url: "listing/getclibroklist",
		params: {
			userId,
			cliid
		},
	});

export default getAllClientBrokers;
