import React from "react";
import { AutocompleteSelect, AutocompleteSelectType } from "../common/AutocompleteSelect";
import { useSimpleTradesData } from "../../../data/trading/transactions/hooks/useSimpleTradesData";
import { TransSimpleData, SimpleTransFilter } from "../../../data/trading/transactions/types";

type FiltredTransactionsSelectPropsType = Omit<
	AutocompleteSelectType<TransSimpleData>,
	"data" | "getLabel"
> & {
	filter?: SimpleTransFilter;
	disabled?: boolean;
};

export const FiltredTransactionsSelect: React.FunctionComponent<FiltredTransactionsSelectPropsType> = ({
	filter,
	disabled,
	...otherProps
}) => {
	const [trades] = useSimpleTradesData(filter);

	return (
		<AutocompleteSelect<TransSimpleData>
			{...(otherProps as AutocompleteSelectType<TransSimpleData>)}
			data={trades || []}
			getLabel={renderAttributeLabel}
			disabled={filter === undefined || disabled}
		/>
	);
};

const renderAttributeLabel = (trans: TransSimpleData) => trans.ordData;
