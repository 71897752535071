import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllRegions } from "../thunks/loadAllRegions";
import { Region } from "../types";
import { RegType } from "../../../models";

export const useRegionData = (regtype?: RegType) => {
	const type = regtype || RegType.Region;
	const data = useSelector<StoreType, Region[] | undefined>(s => s.data.listing.regions.displayData[type]);
	const allData = useSelector<StoreType, Region[] | undefined>(s => s.data.listing.regions.all[type]);

	const dispatch = useDispatch();

	const dispatchLoadAllRegions = React.useCallback(() => {
		if (regtype) {
			dispatch(loadAllRegions(regtype));
		}
	}, [dispatch, regtype]);

	return [data, dispatchLoadAllRegions, allData] as [
		typeof data,
		typeof dispatchLoadAllRegions,
		typeof allData
	];
};
