import { useDispatch, useSelector } from "react-redux";
import { downloadTradeByClientsReport } from "../thunks/downloadReport";
import React from "react";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";

export const useTradeByClientsReportDownloader = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (s) => s.data.reports.exchbusiness.tradeByClients.downloadReportFetchState
  );
  const dispatch = useDispatch();
  const download = React.useCallback(
    () => dispatch(downloadTradeByClientsReport()),
    [dispatch]
  );

  return [download, fetchState] as [typeof download, typeof fetchState];
};
