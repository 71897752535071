import { WeightedPricesReportData } from "../../../../data/reports/exchbusiness/weightedPrices/types";
import React from "react";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Column } from "material-table";

export const useWeightedPricesColumns = () => {
  const { getString, getFormatedNumberString } = React.useContext(
    LocalizationContext
  );

  return React.useMemo(
    () => [
      {
        title: getString("exchProduct"),
        field: "product",
        sorting: false,
      },
      {
        title: getString("exchQuantity"),
        render: (row) => `${getFormatedNumberString(row.quantity)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchTurnoverRsd"),
        render: (row) => `${getFormatedNumberString(row.turnoverRsd, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchTurnoverEur"),
        render: (row) => `${getFormatedNumberString(row.turnoverEur, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchWeightedPriceRsd"),
        render: (row) =>
          `${getFormatedNumberString(row.weightedPriceRsd, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchWeightedPriceEur"),
        render: (row) =>
          `${getFormatedNumberString(row.weightedPriceEur, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
    ],
    [getString, getFormatedNumberString]
  ) as Column<WeightedPricesReportData>[];
};
