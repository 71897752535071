import { Region } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";
import { RegType } from "../../models";

type RegionStateType<T> = {
	[type in RegType]: T;
};

type RegionArgType<T> = {
	type: RegType;
	data: T;
};

type RegionDataArg = RegionArgType<Region[] | undefined>;
type RegionDataType = RegionStateType<Region[] | undefined>;

type RegionaSearchArg = RegionArgType<string>;
type RegionSearchType = RegionStateType<string>;

type RegionaFetchArg = RegionArgType<FetchStateType>;
type RegionFetchType = RegionStateType<FetchStateType>;

interface IRegionData {
	all: RegionDataType;
	searchTerm: RegionSearchType;
	displayData: RegionDataType;
	displayedRegion?: Region;
	fetchState: RegionFetchType;
	regType?: RegType;
}

const initialState: IRegionData = {
	searchTerm: {
		[RegType.Region]: "",
		[RegType.TopRegion]: "",
		[RegType.SubRegion]: "",
	},
	all: {
		[RegType.Region]: undefined,
		[RegType.TopRegion]: undefined,
		[RegType.SubRegion]: undefined,
	},
	displayData: {
		[RegType.Region]: undefined,
		[RegType.TopRegion]: undefined,
		[RegType.SubRegion]: undefined,
	},
	fetchState: {
		[RegType.Region]: { type: "not-started" },
		[RegType.TopRegion]: { type: "not-started" },
		[RegType.SubRegion]: { type: "not-started" },
	},
};

const name = "regions";

const regionSlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<RegionDataArg>) => ({
			...state,
			all: {
				...state.all,
				[action.payload.type]: action.payload.data,
			},
			displayData: {
				...state.displayData,
				[action.payload.type]: filterData(action.payload.data, state.searchTerm[action.payload.type]),
			},
		}),
		setSearchTerm: (state, action: PayloadAction<RegionaSearchArg>) => ({
			...state,
			searchTerm: {
				...state.searchTerm,
				[action.payload.type]: action.payload.data,
			},
			displayData: {
				...state.displayData,
				[action.payload.type]: filterData(state.all[action.payload.type], action.payload.data),
			},
		}),
		setDisplayed: (state, action: PayloadAction<Region | undefined>) => ({
			...state,
			displayedRegion: action.payload,
		}),
		setFetchState: (state, action: PayloadAction<RegionaFetchArg>) => ({
			...state,
			displayedRegion: action.payload.data.type === "successful" ? undefined : state.displayedRegion,
			all: {
				...state.all,
				[action.payload.type]:
					action.payload.data.type === "successful" ? undefined : state.all[action.payload.type],
			},
			displayData: {
				...state.displayData,
				[action.payload.type]:
					action.payload.data.type === "successful"
						? undefined
						: state.displayData[action.payload.type],
			},
			fetchState: {
				...state.fetchState,
				[action.payload.type]: getFetchStateChange(
					state.fetchState[action.payload.type],
					action.payload.data
				),
			},
		}),
		setReg: (state, action: PayloadAction<RegType>) => ({
			...state,
			regType: action.payload,
		}),
	},
});

const filterData = (data?: Region[], searchTerm?: string) =>
	searchTerm === undefined || searchTerm === ""
		? data
		: data?.filter(
				e =>
					e.regName &&
					e.regName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())
		  );

export const { setData, setSearchTerm, setDisplayed, setFetchState, setReg } = regionSlice.actions;

export default regionSlice.reducer;
