import React from "react";
import { useClientContracts } from "../../../data/listing/clients/hooks/clientContract/useClientContracts";
import { ClientContractList } from "../../../data/listing/clients/types";
import { AutocompleteSelectType, AutocompleteSelect } from "../common/AutocompleteSelect";

export interface ClientContractSelectProps {
	clientId: string;
}

export type ClientContractSelectPropsType = Omit<
	AutocompleteSelectType<ClientContractList>,
	"data" | "getLabel"
> &
	ClientContractSelectProps;

export const ClientContractSelect: React.FunctionComponent<ClientContractSelectPropsType> = ({
	clientId,
	...otherProps
}) => {
	const [contracts] = useClientContracts(clientId);

	return (
		<AutocompleteSelect<ClientContractList>
			data={contracts}
			getLabel={renderClientContractLabel}
			{...otherProps}
		/>
	);
};

const renderClientContractLabel = (contract: ClientContractList | null) => (contract ? contract.contrNo : "");
