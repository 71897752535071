import React from "react";
import { useEditClientPersonState } from "../../../../data/listing/clients/hooks/clientPerson/useEditClientPersonState";
import { useClientValidator } from "../../../../data/listing/clients/hooks/clientPerson/useClientPersonValidator";
import { useClientPersonUpsert } from "../../../../data/listing/clients/hooks/clientPerson/useClientPersonUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../../pageStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../../components/common/FabSave";
import { ClientPerson } from "../../../../data/listing/clients/types";
import { StringInput } from "../../../components/common/StringInput";
import { ClientEditParts, getClientsEditLocation } from "../Locations";
import { BoolInput } from "../../../components/common/BoolInput";
import { Status } from "../../../../data/models";
import { LeavePrompt } from "../../../components/common/LeavePrompt";
import { PageTitle } from "../../../components/common/PageTitle";
import { ClientPersonRoleSelect } from "../../../components/common/ClientPersonRoleSelect";
import { DateInput } from "../../../components/common/DateInput";

export interface EditPersonInfo {
	personId?: string;
	clientId: string;
}

export interface EditPersonProps extends RouteComponentProps<EditPersonInfo> {}

export const EditPerson: React.FunctionComponent<EditPersonProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const { clientId, personId } = props.match.params;
	const [person, editPerson, changed] = useEditClientPersonState(clientId, personId);
	const [validationState, validate] = useClientValidator();
	const [fetchState, upsert] = useClientPersonUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editPerson(event.target.name as any, event.target.value);
		},
		[editPerson]
	);

	const backLocation = React.useMemo(() => getClientsEditLocation(ClientEditParts.Persons, clientId), [
		clientId,
	]);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(backLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(person);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [person, validate, fetchState, history, upsert, backLocation]);

	const pageTitle = person && person.cliPrsId ? "clientPersonEditTitle" : "clientPersonCreateTitle";

	if (!person) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={backLocation} />} />
					<Divider />
					<CardContent>
						<Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
							<Grid item xs={2}>
								<ClientPersonRoleSelect
									label={getString("clientPersonRole")}
									predicate={role => role.ftValue === person.cliPrsRole}
									onChangeItem={role => editPerson("cliPrsRole", role?.ftValue)}
									required={validationState.cliPrsRole !== undefined}
									error={validationState.cliPrsRole !== undefined && !validationState.cliPrsRole}
								/>
							</Grid>
							<Grid item xs={2}>
								<DateInput
									label={getString("clientPersonDateCertified")}
									value={person.dateCertified || null}
									onChange={date => editPerson("dateCertified", date)}
									required={validationState.dateCertified !== undefined}
									error={validationState.dateCertified !== undefined && !validationState.dateCertified}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="flex-start"
							spacing={4}
						>
							<Grid item xs={3}>
								<StringInput<ClientPerson>
									property="cliPrsName"
									item={person}
									validationState={validationState}
									onChange={handleChange}
									label={getString("clientPersonName")}
								/>
							</Grid>
							<Grid item xs={2}>
								<StringInput<ClientPerson>
									property="cliPrsIdNo"
									item={person}
									validationState={validationState}
									onChange={handleChange}
									label={getString("clientPersonIDNumber")}
								/>
							</Grid>
							<Grid item xs={2}>
								<StringInput<ClientPerson>
									property="phone"
									item={person}
									validationState={validationState}
									onChange={handleChange}
									label={getString("clientPersonPhone")}
								/>
							</Grid>
							<Grid item xs={2}>
								<StringInput<ClientPerson>
									property="email"
									item={person}
									validationState={validationState}
									onChange={handleChange}
									label={getString("clientPersonEmail")}
								/>
							</Grid>
							<Grid item xs={3}>
								<BoolInput<ClientPerson>
									property="status"
									item={person}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("clientPersonStatus")}
								/>
							</Grid>
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
