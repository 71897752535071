import { useDispatch, useSelector } from "react-redux";
import { downloadDisputeExcel } from "../thunks/downloadReportExcel";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";

export const useDisputeExcelDownloader = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (s) =>
      s.data.clearing.disputes.downloadReportFetchState
  );
  const dispatch = useDispatch();
  const download = React.useCallback(
    () => dispatch(downloadDisputeExcel()),
    [dispatch]
  );

  return [download, fetchState] as [typeof download, typeof fetchState];
};
