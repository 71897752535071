import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useOrderMarketOptions } from "../../../data/trading/orders/hooks/useOrderMarketOptions";
import { TextField, MenuItem } from "@material-ui/core";
import { OrderMarkt } from "../../../data/trading/orders/types";

interface OrderMarketSelectProps {
	market?: OrderMarkt;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	nullable?: boolean;
}

export const OrderMarketSelect: React.FunctionComponent<OrderMarketSelectProps> = ({
	market,
	handleChange,
	disabled,
	nullable,
}) => {
	const { getString } = React.useContext(LocalizationContext);
	const options = useOrderMarketOptions();
	const enchanchedOptions = React.useMemo(() => {
		return !nullable ? options : [
			{
				label: "--",
				value: "",
			},
			...options
		]
	}, [nullable, options])

	return (
		<TextField
			name="market"
			value={market !== undefined ? market : ""}
			onChange={handleChange}
			fullWidth
			select
			label={getString("orderMarket")}
			disabled={disabled}
		>
			{enchanchedOptions.map(option => (
				<MenuItem key={option.value} value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</TextField>
	);
};
