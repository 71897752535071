import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../models";
import { AnnualTradingReportData } from "./types";

type AnnualTradesReportData = {
  data?: AnnualTradingReportData[];
  downloadReportFetchState: FetchStateType;
};

const initialState: AnnualTradesReportData = {
  downloadReportFetchState: { type: "not-started" },
};

const name = "annualTrade";

const annualTradeSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<AnnualTradingReportData[] | undefined>
    ) => ({
      ...state,
      data: action.payload,
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      downloadReportFetchState: action.payload,
    }),
  },
});

export const { setData, setFetchState } = annualTradeSlice.actions;

export default annualTradeSlice.reducer;
