import React from "react";
import { useDispatch } from "react-redux";
import { CommissionDetail } from "../../types";
import { loadCommissionDetail } from "../../thunks/detailes/loadCommissionDetail";

export const useDetail = (detailId?: string) => {
	const displatch = useDispatch();
	const [detail, setDetail] = React.useState<CommissionDetail | undefined>();

	React.useEffect(() => {
		const load = async () => {
			if (detailId) {
				const loaded = (await displatch(loadCommissionDetail(detailId))) as any;
				setDetail(loaded);
			}
		};
		load();
	}, [displatch, detailId, setDetail]);

	return [detail];
};
