import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PageData,
  PageDataStatus,
  FetchStateType,
  getFetchStateChange,
} from "../../models";
import { GuaranteeData, GuaranteeListData } from "./types";

type GuaranteesData = {
  all?: GuaranteeData[];
  displayData?: GuaranteeData[];
  currentPage?: PageData<GuaranteeListData>;
  displayedGuarantee?: GuaranteeData;
  fetchState: FetchStateType;
  cancelFetchState: FetchStateType;
  downloadReportFetchState: FetchStateType;
	printGuaranteeFetchState: FetchStateType;
};

const initialState: GuaranteesData = {
  cancelFetchState: { type: "not-started" },
  fetchState: { type: "not-started" },
  downloadReportFetchState: { type: "not-started" },
  printGuaranteeFetchState: { type: "not-started" },

};

const name = "guarantees";

const guaranteesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setGuarantees: (
      state,
      action: PayloadAction<PageData<GuaranteeListData>>
    ) => ({
      ...state,
      currentPage: action.payload,
    }),

    setDisplayed: (
      state,
      action: PayloadAction<GuaranteeData | undefined>
    ) => ({
      ...state,
      displayedGuarantee: action.payload,
    }),

    invalidateGuarantee: (state) => {
      if (!state.currentPage) return state;

      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          status: PageDataStatus.DataInvalidated,
        },
      };
    },
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      displayedGuarantee:
        action.payload.type === "successful"
          ? undefined
          : state.displayedGuarantee,
      all: action.payload.type === "successful" ? undefined : state.all,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: getFetchStateChange(state.fetchState, action.payload),
      downloadReportFetchState: action.payload,
    }),
    setCancelGuaranteeFetchState: (
      state,
      action: PayloadAction<FetchStateType>
    ) => ({
      ...state,
      cancelFetchState: action.payload,
    }),
    setPrintGuaranteeFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			printGuaranteeFetchState: action.payload,
		}),

  },
});

export const {
  setGuarantees,
  setDisplayed,
  invalidateGuarantee,
  setFetchState,
  setCancelGuaranteeFetchState,
  setPrintGuaranteeFetchState,
} = guaranteesSlice.actions;

export default guaranteesSlice.reducer;
