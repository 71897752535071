import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TickerPRoductState = {
	graphProductId: {
		[k: string]: string | undefined;
	};
};

export type TickerArg<T> = {
	graphId: string;
	data: T;
};

export const initialState: TickerPRoductState = {
//	graphProductId: {"0001":"00000054", "0002":"00000048", "0003":"00000055"},
	graphProductId: {"0001":"00000070", "0002":"0000006C", "0003":"00000073"},
};

const name = "ticker";

const tickerSlice = createSlice({
	name,
	initialState,
	reducers: {
		setGraphProductId: (state, action: PayloadAction<TickerArg<string | undefined>>) => ({
			...state,
			graphProductId: {
				...state.graphProductId,
				[action.payload.graphId]: action.payload.data,
			},
		}),
	},
});

export const { setGraphProductId } = tickerSlice.actions;

export default tickerSlice.reducer;
