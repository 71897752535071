import { AntiLaunderingReportData, AntiLaunderingReportFilter } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getAntiLaunderingData = (
  user: User,
  filter: AntiLaunderingReportFilter
) => {
  return webApi<AntiLaunderingReportData[]>({
    method: "GET",
    url: "reporting/getantimnylndcommlist",
    params: {
      userid: user.userId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      limit: filter.limit,
    },
  });
};

export default getAntiLaunderingData;
