import React from "react";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import ExcelIcon from "@material-ui/icons/TableChartOutlined";
import { IconButton } from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import { usePaymentsReportDownloader } from "../../../data/reports/exchbusiness/clientOverview/hooks/usePaymentsReportDownloader";
import { usePaymentsExcelReportDownloader } from "../../../data/reports/exchbusiness/clientOverview/hooks/usePaymentsExcelReportDownloader";

export const DownloadPaymentsButton: React.FunctionComponent = () => {
	const [downloader, fetchState] = usePaymentsReportDownloader();
	return (
		<CustomTooltip titleKeyword="exportPDFTooltip">
			<IconButton
				color="secondary"
				component="span"
				onClick={downloader}
				disabled={fetchState.type !== "not-started"}
			>
				<DownloadIcon />
			</IconButton>
		</CustomTooltip>
	);
};

export const DownloadPaymentsButtonExcel: React.FunctionComponent = () => {
	const [downloader, fetchState] = usePaymentsExcelReportDownloader();
	return (
		<CustomTooltip titleKeyword="exportXLSXTooltip">
			<IconButton
				color="secondary"
				component="span"
				onClick={downloader}
				disabled={fetchState.type !== "not-started"}
			>
				<ExcelIcon />
			</IconButton>
		</CustomTooltip>
	);
};
