import { TradeReviewReportData, TradeReviewReportFilter } from "../types";
import { User } from "../../../../auth/types";
import { webApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

const getTradeReviewData = (user: User, filter: TradeReviewReportFilter) => {
  return webApi<TradeReviewReportData[]>({
    method: "GET",
    url: "reporting/gettradereviewlist",
    params: {
      userid: user.userId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      productid: filter.productid,
    },
  });
};

export default getTradeReviewData;
