import React from "react";
import { useEditDeliveryState } from "../../../data/clearing/delivery/hooks/useEditDeliveryState";
import { useDeliveryValidator } from "../../../data/clearing/delivery/hooks/useDeliveryValidator";
import { useDeliveryUpsert } from "../../../data/clearing/delivery/hooks/useDeliveryUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent, TextField } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { DeliveryData } from "../../../data/clearing/delivery/types";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { Status } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { deliveriesLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { ClientSelect } from "../../components/listing/ClientSelect";
import { NumberInput } from "../../components/common/NumberInput";
import { DateInput } from "../../components/common/DateInput";
import { ProductSelect } from "../../components/listing/ProductSelect";
import { CitySelect } from "../../components/common/CitySelect";
import { City } from "../../../data/models";
import { UnitSelect } from "../../components/common/UnitSelect";
import { WhsSelect } from "../../components/listing/WarehouseSelect";
import { TradesSelect } from "../../components/trading/TradesSelect";

export interface EditDeliveryInfo {
	dlvrId?: string;
}

export interface EditDeliveryProps extends RouteComponentProps<EditDeliveryInfo> {}

export const EditDeliveryPage: React.FunctionComponent<EditDeliveryProps> = props => {
	const { getString, getFormatedNumberString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [delivery, editDelivery, changed] = useEditDeliveryState(props.match.params.dlvrId);
	const [validationState, validate] = useDeliveryValidator();
	const [fetchState, upsert] = useDeliveryUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editDelivery(event.target.name as any, event.target.value);
		},
		[editDelivery]
	);

	const changeQty=React.useCallback((e: any) => {
		const newValue =  e.target.value;
		editDelivery("deliveryQty", newValue);
		editDelivery("deliveryAmt", delivery && newValue && delivery.price ? newValue *delivery.price : 0);
	},	[editDelivery,delivery]	)
	
	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(deliveriesLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(delivery);

			if (normalized) {
				upsert(normalized);
			}
		}
	}, [delivery, validate, fetchState, history, upsert]);

	const pageTitle = delivery && delivery.deliveryId ? "dlvrEdit" : "dlvrCreate";

	if (!delivery) {
		return null;
	}
	
	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={deliveriesLocation} />} />
					<Divider />
					<CardContent>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={3}>
								<DateInput
									label={getString("ctrlcontrolDate")}
									value={delivery.deliveryDate || null}
									onChange={date => editDelivery("deliveryDate", date)}
									error={
										validationState.deliveryDate !== undefined &&
										!validationState.deliveryDate
									}
									required={validationState.deliveryDate !== undefined}
								/>
							</Grid>
						</Grid>

						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={4}>
								<TradesSelect
									label={getString("ctrltradeNo")}
									predicate={trade => trade.tradeId === delivery.tradeId}
									onChangeItem={trade => {
										editDelivery("tradeId", trade?.tradeId);
										editDelivery("productId", trade?.productId);
										editDelivery("price", trade?.price);
										editDelivery("deliveryAmt", delivery.deliveryAmt);
										editDelivery("unit", trade?.unit);
										editDelivery("unitDescr", trade?.unitDescr);
//										editDelivery("deliveryAmt", delivery.deliveryQty && trade ? trade.price*delivery.deliveryQty : 0);
									}}
									onSellerChange={seller =>
										seller?.cliId !== delivery.sellerId &&
										editDelivery("sellerId", seller?.cliId)
									}
									onBuyerChange={buyer =>
										buyer?.cliId !== delivery.buyerId &&
										editDelivery("buyerId", buyer?.cliId)
									}
									required={validationState.tradeId !== undefined}
									error={validationState.tradeId !== undefined && !validationState.tradeId}
//									filter={{}}
								/>
							</Grid>
						</Grid>

						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={4}>
								<ClientSelect
									label={getString("dissellerName")}
									predicate={client => client.cliId === delivery.sellerId}
									onChangeItem={client =>
										editDelivery("sellerId", client ? client.cliId : undefined)
									}
									required={validationState.sellerId !== undefined}
									error={
										validationState.sellerId !== undefined && !validationState.sellerId
									}
									disabled
								/>
							</Grid>
							<Grid item xs={4}>
								<ClientSelect
									label={getString("disbuyerName")}
									predicate={client => client.cliId === delivery.buyerId}
									onChangeItem={client =>
										editDelivery("buyerId", client ? client.cliId : undefined)
									}
									required={validationState.buyerId !== undefined}
									error={validationState.buyerId !== undefined && !validationState.buyerId}
									disabled
								/>
							</Grid>
							<Grid item xs={3}>
								<ProductSelect
									label={getString("orderProduct")}
									predicate={prod => prod.prodId === delivery.productId}
									onChangeItem={prod => {
										editDelivery("productId", prod ? prod.prodId : undefined);
									}}
									required={validationState.productId !== undefined}
									error={
										validationState.productId !== undefined && !validationState.productId
									}
									disabled
								/>
							</Grid>
						</Grid>

						<Grid
							container
							item
							direction="row"
							justify="flex-start"
							alignItems="center"
							spacing={3}
						>
							<Grid item xs={2}>
								<NumberInput<DeliveryData>
									property="deliveryQty"
									item={delivery}
									validationState={validationState}
									onChange={changeQty}
//									onChange={handleChange}
									label={getString("dlvrDeliverQty")}
								/>
							</Grid>
							<Grid item xs={2}>
								<TextField
									label={getString("productUnit")}
									value={delivery.unitDescr}
//									value={getFormatedNumberString(delivery.deliveryAmt,true)}
									InputProps={{readOnly: true,}}
									fullWidth
									disabled
								/>
							</Grid>

{/*							<Grid item xs={2}>
								<UnitSelect
									label={getString("productUnit")}
									predicate={unit => unit.ftValue === delivery.unit}
									onChangeItem={unit => {
										editDelivery("unit", unit?.ftValue);
										editDelivery("unitDescr", unit?.ftDescr);
										editDelivery("deliveryAmt", delivery.deliveryQty && delivery.price ? delivery.price*delivery.deliveryQty : 0);
									}}
									required={validationState.unit !== undefined}
									error={validationState.unit !== undefined && !validationState.unit}
								/>
							</Grid>  */}

							<Grid item xs={2}>
								<TextField
									label={getString("orderPrice")}
									value={getFormatedNumberString(delivery.price,true)}
									InputProps={{readOnly: true,}}
									fullWidth
									disabled
								/>
							</Grid>  

							<Grid item xs={2}>
								<TextField
									label={getString("dlvrValue")}
									value={getFormatedNumberString(delivery.deliveryAmt,true)}
									InputProps={{readOnly: true,}}
									fullWidth
									disabled
								/>
							</Grid>

						</Grid>

{/*						<Grid
							container
							item
							direction="row"
							justify="flex-start"
							alignItems="center"
							spacing={3}
						>
							<Grid item xs={3}>
								<WhsSelect
									label={getString("dlvrWarehouseName")}
									predicate={warehouse => warehouse.whsId === delivery.warehouseId}
									onChangeItem={warehouse =>
										editDelivery("warehouseId", warehouse ? warehouse.whsId : undefined)
									}
									required={validationState.warehouseId !== undefined}
									error={
										validationState.warehouseId !== undefined &&
										!validationState.warehouseId
									}
								/>
							</Grid>
						</Grid>  */}

						<Grid
							container
							item
							direction="row"
							justify="flex-start"
							alignItems="center"
							spacing={3}
						>
							<Grid item xs={1}>
								<BoolInput<DeliveryData>
									property="status"
									item={delivery}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("ctrlstatus")}
								/>
							</Grid>
						</Grid>

						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
