import { GuaranteeListData } from "../../../data/clearing/guarantees/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";

export const useGuaranteeColumns = () => {
  const {
    getString,
    getDateString,
    getFormatedNumberString,
  } = React.useContext(LocalizationContext);


  return React.useMemo(
    () => [
      {
        title: getString("paymentDataTime"),
        render: (guarantee) => getDateString(guarantee.payDate, "DD.MM.YYYY"),
        sorting: false,
      },
      {
        title: getString("paymentClientCode"),
        field: "clientIdNo",
        sorting: false,
      },
      {
        title: getString("paymentClientName"),
        field: "clientName",
        sorting: false,
      },
      {
        title: getString("paymentPayType"),
        field: "payTypeDescr",
        sorting: false,
        width: "300px",
      },
      {
        title: getString("paymentClmOrdNo"),
        field: "orderNo",
        sorting: false,
      },
/*      {
        title: getString("paymentTranNo"),
        field: "tranNo",
        sorting: false,
      }, */
      {
        title: getString("paymentBankGuarantee"),
        field: "grntNo",
        sorting: false,
      },
      {
        title: getString("paymentAmount"),
        render: (guarantee) => getFormatedNumberString(guarantee.amtPay, true),
        sorting: false,
				width: "140px",
				cellStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
      },
      {
        title: getString("paymentAmtBal"),
        render: (guarantee) => getFormatedNumberString(guarantee.amtBal, true),
        sorting: false,
				width: "140px",
				cellStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
      },
      {
        title: getString("paymentAmtBalNonAlloc"),
        render: (guarantee) => getFormatedNumberString(guarantee.amtNonAllocBal, true),
        sorting: false,
				width: "140px",
				cellStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
      },
    ],
    [getString, getFormatedNumberString, getDateString]
  ) as Column<GuaranteeListData>[];
};
