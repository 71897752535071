import React from "react";
import { useEditProductGroupState } from "../../../data/listing/productGroups/hooks/useEditProductGroupState";
import { useProductGroupValidator } from "../../../data/listing/productGroups/hooks/useProductGroupValidator";
import { useProductGroupUpsert } from "../../../data/listing/productGroups/hooks/useProductGroupUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { ProductGroupData } from "../../../data/listing/productGroups/types";
import { StringInput } from "../../components/common/StringInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { BoolInput } from "../../components/common/BoolInput";
import { Status } from "../../../data/models";
import { productGroupsLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";

export interface EditProductGroupInfo {
	groupId?: string;
}

export interface EditProductGrouProps extends RouteComponentProps<EditProductGroupInfo> {}

export const EditProductGroupPage: React.FunctionComponent<EditProductGrouProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [group, editGroup, changed] = useEditProductGroupState(props.match.params.groupId);
	const [validationState, validate] = useProductGroupValidator();
	const [fetchState, upsert] = useProductGroupUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editGroup(event.target.name as any, event.target.value);
		},
		[editGroup]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(productGroupsLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(group);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [group, validate, fetchState, history, upsert]);

	const pageTitle = group && group.prodGroupID ? "productGroupEditTitle" : "productGroupCreateTitle";

	if (!group) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader
						title={<PageTitle title={pageTitle} backLocation={productGroupsLocation} />}
					/>
					<Divider />
					<CardContent>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="flex-start"
							spacing={4}
						>
							<Grid item xs={3}>
								<StringInput<ProductGroupData>
									property="prodGroupName"
									item={group}
									validationState={validationState}
									onChange={handleChange}
									label={getString("productGroupNameSR")}
								/>
							</Grid>
							<Grid item xs={2}>
								<StringInput<ProductGroupData>
									property="prodGroupNameEn"
									item={group}
									validationState={validationState}
									onChange={handleChange}
									label={getString("productGroupNameEN")}
								/>
							</Grid>
							<Grid item xs={3}>
								<BoolInput<ProductGroupData>
									property="status"
									item={group}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("productGroupStatus")}
								/>
							</Grid>
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
