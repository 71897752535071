import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { PaymentData } from "../types";

export const createPaymentApi = (userId: string, payment: PaymentData) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "clearing/newpayment",
    params: {
      userId,
    },
    data: payment,
  });

export default createPaymentApi;
