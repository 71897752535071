import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { TransportFee } from "../types";
import { loadTransportFee } from "../thunks/loadTransportFee";

export const useDisplayTransportFee = (trfeeId?: number) => {
  const storeTransportFee = useSelector<StoreType, TransportFee | undefined>(
    (store) => store.data.clearing.transportfees.displayedTransportFee
  );

  const displayedTransportFee =
    storeTransportFee && storeTransportFee.id === trfeeId
      ? storeTransportFee
      : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (trfeeId) {
      displatch(loadTransportFee(trfeeId));
    }
  }, [displatch, trfeeId]);

  return displayedTransportFee;
};
