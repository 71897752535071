import React from "react";
import {
	Grid,
	CardContent,
	CardHeader,
	Divider,
	Card,
	Fab,
	makeStyles,
	Theme,
	createStyles,
} from "@material-ui/core";
import CurrencyTable from "./CurrencyRateTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent, AccessRights } from "../../../core/navigation/types";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../../components/common/PageTitle";
import { getCurrencyRateEditLocation } from "./Locations";
import { useCurrencyRateFilter } from "../../../data/listing/curencyRates/hooks/useCurrencyRateFilter";
import { CurrencySelect } from "../../components/common/CurrencySelect";
import { LocalizationContext } from "../../../core/localization/Localization";

const useCurrencyStyles = makeStyles((theme: Theme) => {
	return createStyles({
		currencySelect: {
			width: "250px",
		},
	});
});

const CurrencyRatePage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();
	const styles = useCurrencyStyles();
	const [currencyId, setCurrencyId] = useCurrencyRateFilter();
	const { getString } = React.useContext(LocalizationContext);

	const history = useHistory();

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader
						title={<PageTitle title="curRateTitle" />}
						action={
							<CurrencySelect
								label={getString("accountCurrency")}
								onChangeItem={currency => setCurrencyId(currency?.currId)}
								predicate={currency => currency.currId === currencyId}
								className={styles.currencySelect}
							/>
						}
					/>
					<Divider />
					<CardContent>
						<CurrencyTable userAccessRights={userAccessRights} />
					</CardContent>
				</Card>
				{userAccessRights === AccessRights.All && (
					<Fab
						color="secondary"
						aria-label="add"
						className={pageStyles.mainFab}
						onClick={() => history.push(getCurrencyRateEditLocation())}
					>
						<AddIcon />
					</Fab>
				)}
			</Grid>
		</Grid>
	);
};

export default CurrencyRatePage;
