import React from "react";
import { usePaymentTypes } from "../../../data/initialData/hooks/usePaymentTypes";
import { PayTypes } from "../../../data/models";
import {
  AutocompleteSelect,
  AutocompleteSelectType,
} from "./AutocompleteSelect";

type PayTypesPropsType = Omit<
  AutocompleteSelectType<PayTypes>,
  "data" | "getLabel"
>;

export const PaymentTypeSelect: React.FunctionComponent<PayTypesPropsType> = (
  props
) => {
  const [payTypes, renderLabel] = usePaymentTypes();

  return (
    <AutocompleteSelect<PayTypes>
      data={payTypes}
      getLabel={renderLabel}
      {...(props as any)}
    />
  );
};
