import {
  BusinessActivitiesReportData,
  BusinessActivitiesReportFilter,
} from "../types";
import { User } from "../../../../auth/types";
import { webApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

const getBusinessActivitiesData = (
  user: User,
  filter: BusinessActivitiesReportFilter
) => {
  return webApi<BusinessActivitiesReportData[]>({
    method: "GET",
    url: "reporting/getbusinessactivitieslist",
    params: {
      userid: user.userId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
    },
  });
};

export default getBusinessActivitiesData;
