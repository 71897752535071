import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";

export const printInvoice = (user: User, invid: string) => {
  return httpWebApi<Blob>({
    method: "GET",
    responseType: "blob",
    timeout: 60 * 4 * 1000,
    url: "accounting/getinvoicereport",
    params: {
      userid: user.userId,
      invid,
    },
  });
};

export default printInvoice;
