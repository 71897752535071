import React from "react";
import { useDisplayTransportFee } from "../../../data/clearing/transportfees/hooks/useDisplayTransportFee";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { TransportFee } from "../../../data/clearing/transportfees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayTransportFeeMainAttributes } from "./DisplayTransportFeeMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,
} from "@material-ui/core";

export interface DisplayTransportFeeProps {
  id?: number;
}

export const DisplayTransportFee: React.FunctionComponent<DisplayTransportFeeProps> = ({
  id,
}) => {
  const trfee = useDisplayTransportFee(id);
  const { getString } = React.useContext(LocalizationContext);

  return !trfee ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Typography>{getString("tableBasciInfo")}</Typography>
        </ExpansionPanelSummary>
        <DisplayTransportFeeMainAttributes trfee={trfee} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewTransportFee = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (trfee: TransportFee) =>
      showWithDrawer(<DisplayTransportFee id={trfee.id} />),
    [showWithDrawer]
  );
};
