import React from "react";
import { useInitProductGroups } from "../../../data/initialData/hooks/useInitProductGroups";
import { ProductGroup } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType } from "./AutocompleteSelect";

type ProductGroupSelectPropsType = Omit<AutocompleteSelectType<ProductGroup>, "data" | "getLabel">;

export const ProductGroupSelect: React.FunctionComponent<ProductGroupSelectPropsType> = props => {
	const [groups, renderGroupLabel] = useInitProductGroups();

	return (
		<AutocompleteSelect<ProductGroup>
			{...(props as AutocompleteSelectType<ProductGroup>)}
			data={groups}
			getLabel={renderGroupLabel}
		/>
	);
};
