import React from "react";
import { App } from "@capacitor/app";

export const useCapacitorAppState = () => {
	const [isVisiable, setIsVisiable] = React.useState<boolean>(true);
	React.useEffect(() => {
		console.log("registering appstate change");
		const listener = App.addListener("appStateChange", (state: any) => {
			console.log("App state changed. Is active?", state.isActive);
			setIsVisiable(state.isActive);
		});
		return () => {
			listener.remove();
		};
	}, [setIsVisiable]);

	return {
		isVisiable,
	};
};
