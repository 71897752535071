import { PushNotification } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";

const getPushNotificationsList = (user: User) => {
  return webApi<PushNotification[]>({
    method: "GET",
    url: "common/getpushnotificationslist",
    params: {
      userid: user.userId,
    },
  });
};

export default getPushNotificationsList;
