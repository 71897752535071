import React from "react";
import { Region } from "../../../data/listing/regions/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { Status, RegType } from "../../../data/models";
import { useCountryGetter } from "../../../data/listing/countries/hooks/useCountryData";

export interface DisplayRegionMainAttributesProps {
	region: Region;
}

export const DisplayRegionMainAttributes: React.FunctionComponent<DisplayRegionMainAttributesProps> = ({
	region,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString } = React.useContext(LocalizationContext);
	const [getCountry] = useCountryGetter();
	const regionName = region.regType === RegType.TopRegion ? "regName" : "regNameDistrict"

	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString(regionName)}</span>
				<span className={style.value}>{region.regName}</span>
			</div>
			{region.regType !== RegType.TopRegion && (
				<div className={`${style.row} ${style.rowBorder}`}>
					<span className={style.label}>{getString("parentRegName")}</span>
					<span className={style.value}>{region.parentRegName}</span>
				</div>
			)}
			{region.regType === RegType.TopRegion && (
				<div className={`${style.row} ${style.rowBorder}`}>
					<span className={style.label}>{getString("countryReg")}</span>
					<span className={style.value}>{getCountry(region.country)?.cntName}</span>
				</div>
			)}
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("cliStatus")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={region.status}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				</span>
			</div>
		</div>
	);
};
