import React from "react";
import { useEditWhsState } from "../../../data/listing/warehouses/hooks/useEditWhsState";
import { useWhsValidator } from "../../../data/listing/warehouses/hooks/useWhsValidator";
import { useWhsUpsert } from "../../../data/listing/warehouses/hooks/useWhsUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { Warehouse } from "../../../data/listing/warehouses/types";
import { StringInput } from "../../components/common/StringInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { CitySelect } from "../../components/common/CitySelect";
import { City, Ports, Status } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { whsLocation } from "./Locations";
import { PageTitle } from "../../components/common/PageTitle";
import { NumberInput } from "../../components/common/NumberInput";
import { PortSelect } from "../../components/common/PortSelect";

export interface EditWhsInfo {
	whsId?: string;
}

export interface EditWhsProps extends RouteComponentProps<EditWhsInfo> {}

export const EditWhsPage: React.FunctionComponent<EditWhsProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const [whs, editWhs, changed] = useEditWhsState(props.match.params.whsId);

	const [validationState, validate] = useWhsValidator();
	const [fetchState, upsert] = useWhsUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editWhs(event.target.name as any, event.target.value);
		},
		[editWhs]
	);

	const changeCity = React.useCallback(
		(city: City | null) => {
			editWhs("cityId", city ? city.cityId : "");
		},
		[editWhs]
	);

	const changePort = React.useCallback(
		(port: Ports | null) => {
			editWhs("portId", port ? port.cityId : "");
		},
		[editWhs]
	);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(whsLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(whs);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [whs, validate, fetchState, history, upsert]);

	const pageTitle = whs && whs.whsId ? "whsEdit" : "whsCreate";

	if (!whs) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={whsLocation} />} />
					<Divider />
					<CardContent>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={2}>
								<StringInput<Warehouse>
									property="whsCode"
									item={whs}
									validationState={validationState}
									onChange={handleChange}
									label={getString("whsCode")}
								/>
							</Grid>
							<Grid item xs={4}>
								<StringInput<Warehouse>
									property="whsName"
									item={whs}
									validationState={validationState}
									onChange={handleChange}
									label={getString("whsName")}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
						<Grid item xs={3}>
								<CitySelect
									label={getString("cliCity")}
									predicate={city => city.cityId === whs.cityId}
									onChangeItem={changeCity}
								/>
							</Grid>

							<Grid item xs={3}>
								<StringInput<Warehouse>
									property="address"
									item={whs}
									validationState={validationState}
									onChange={handleChange}
									label={getString("cliAddress")}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
						<Grid item xs={3}>
								<PortSelect
									label={getString("whsPort")}
									predicate={port => port.cityId === whs.portId}
									onChangeItem={changePort}
								/>
							</Grid>
						</Grid>
						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={3}>
									<NumberInput<Warehouse>
										property="distanceToPort"
										item={whs}
//										validationState={validationState}
										onChange={handleChange}
										label={getString("whsDistanceToPort")}
										money
									/>
							</Grid>
						</Grid>
						

						<Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
							<Grid item xs={1}>
								<BoolInput<Warehouse>
									property="status"
									item={whs}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("cliStatus")}
								/>
							</Grid>
						</Grid>

						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
