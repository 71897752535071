import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertProductGroup } from "../thunks/upsertProductGroup";
import { setFetchState } from "../productGroupDataReducer";
import { ProductGroupData } from "../types";

export const useProductGroupUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.productGroups.fetchState
	);

	const dispatch = useDispatch();
	const dispatchUpserGroup = React.useCallback(
		(group: ProductGroupData) => dispatch(upsertProductGroup(group)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchState({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchUpserGroup] as [typeof fetchState, typeof dispatchUpserGroup]
};
