import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { UserData } from "../types";

export const createUserApi = (userId: string, user: UserData) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/newuser",
		params: {
			userId
		},
		data: user
	});

export default createUserApi;
