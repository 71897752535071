import React from "react";
import { useEditClientBrokerState } from "../../../../data/listing/clients/hooks/clientBroker/useEditClientBrokerState";
import { useClientBrokerValidator } from "../../../../data/listing/clients/hooks/clientBroker/useClientBrokerValidator";
import { useClientBrokerUpsert } from "../../../../data/listing/clients/hooks/clientBroker/useClientBrokerUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../../pageStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../../components/common/FabSave";
import { ClientBroker } from "../../../../data/listing/clients/types";
import { StringInput } from "../../../components/common/StringInput";
import { ClientEditParts, getClientsEditLocation } from "../Locations";
import { EmployeeSelect } from "../../../components/listing/EmployeeSelect";
import { BoolInput } from "../../../components/common/BoolInput";
import { Status } from "../../../../data/models";
import { LeavePrompt } from "../../../components/common/LeavePrompt";
import { PageTitle } from "../../../components/common/PageTitle";

export interface EditBrokerInfo {
	brokerId?: string;
	clientId: string;
}

export interface EditBrokerProps extends RouteComponentProps<EditBrokerInfo> {}

export const EditBroker: React.FunctionComponent<EditBrokerProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const { clientId, brokerId } = props.match.params;
	const [broker, editBroker, changed] = useEditClientBrokerState(clientId, brokerId);
	const [validationState, validate] = useClientBrokerValidator();
	const [fetchState, upsert] = useClientBrokerUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editBroker(event.target.name as any, event.target.value);
		},
		[editBroker]
	);

	const backLocation = React.useMemo(() => getClientsEditLocation(ClientEditParts.Brokers, clientId), [
		clientId,
	]);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(backLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(broker);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [broker, validate, fetchState, history, upsert, backLocation]);

	const pageTitle = broker && broker.brokIdNo ? "clientBrokerEditTitle" : "clientBrokerCreateTitle";

	if (!broker) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={backLocation} />} />
					<Divider />
					<CardContent>
						<Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
							<Grid item xs={3}>
								<EmployeeSelect
									label={getString("cliBroker")}
									predicate={empl => empl.empId === broker.brokerId}
									onChangeItem={empl => {
										editBroker("brokerId", empl ? empl.empId : undefined);
										editBroker(
											"brokName",
											empl ? `${empl.lastName} ${empl.firstName}` : undefined
										);
										editBroker("brokIdNo", empl ? empl.empIdNumber : undefined);
									}}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="flex-start"
							spacing={4}
						>
							<Grid item xs={3}>
								<StringInput<ClientBroker>
									property="brokName"
									item={broker}
									validationState={validationState}
									onChange={handleChange}
									label={getString("clientBrokerName")}
									disabled
								/>
							</Grid>
							<Grid item xs={2}>
								<StringInput<ClientBroker>
									property="brokIdNo"
									item={broker}
									validationState={validationState}
									onChange={handleChange}
									label={getString("clientBrokerIDNumber")}
									disabled
								/>
							</Grid>
							<Grid item xs={3}>
								<BoolInput<ClientBroker>
									property="status"
									item={broker}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("clientBrokerStatus")}
								/>
							</Grid>
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
