import React from "react";
import { usePayTypes } from "../../../data/initialData/hooks/usePayTypes";
import { Pay_Type } from "../../../data/models";
import { AutocompleteSelectType, AutocompleteSelect } from "./AutocompleteSelect";


type Pay_TypeSelectPropsType = Omit<AutocompleteSelectType<Pay_Type>, "data" | "getLabel">;

export const Pay_TypeSelect: React.FunctionComponent<Pay_TypeSelectPropsType> = props => {
	const [pay_Types, renderLabel] = usePayTypes();

	return (
		<AutocompleteSelect<Pay_Type>
			data={pay_Types}
			getLabel={renderLabel}
			{...(props as any)}
		/>
	);
};

