import React from "react";
import { Chip, Grid } from "@material-ui/core";
import { DeliveryFilter } from "../../../data/clearing/delivery/types";
import { LocalizationContext } from "../../../core/localization/Localization";

export const DeliveryFilterChipsComponent: React.FunctionComponent<DeliveryFilterChipsProps> = ({
  filter,
  removeFilter,
}) => {
  const { getDateString, getString } = React.useContext(LocalizationContext);
  return (
    <Grid container spacing={1}>
      {filter.datefrom && (
        <Grid item>
          <Chip
            label={`${getString("filterFrom")}: ${getDateString(
              new Date(filter.datefrom)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("datefrom")}
          />
        </Grid>
      )}
      {filter.dateto && (
        <Grid item>
          <Chip
            label={`${getString("filterTo")}: ${getDateString(
              new Date(filter.dateto)
            )}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("dateto")}
          />
        </Grid>
      )}
      {filter.product && (
        <Grid item>
          <Chip
            label={`${getString("dlvrproductName")}: ${filter.product.prodName}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("product")}
          />
        </Grid>
      )}
      {filter.client && (
        <Grid item>
          <Chip
            label={`${getString("paymentClientName")}: ${filter.client.cliName}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("client")}
          />
        </Grid>
      )}
      {filter.buyer && (
        <Grid item>
          <Chip
            label={`${getString("dlvrbuyerName")}: ${filter.buyer.cliName}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("buyer")}
          />
        </Grid>
      )}
      {filter.seller && (
        <Grid item>
          <Chip
            label={`${getString("dlvrsellerName")}: ${filter.seller.cliName}`}
            color="secondary"
            variant="outlined"
            onDelete={() => removeFilter("seller")}
          />
        </Grid>
      )}
    </Grid>
  );
};

interface DeliveryFilterChipsProps {
  filter: DeliveryFilter;
  removeFilter: (type: keyof DeliveryFilter) => void;
}
