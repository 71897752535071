import React from "react";
import { useActStatusClinetsTypes } from "../../../data/initialData/hooks/useActStatusClients";
import { ActStatuses } from "../../../data/models";
import { AutocompleteSelectType, AutocompleteSelect } from "./AutocompleteSelect";


type ActStatusClientsPropsType = Omit<AutocompleteSelectType<ActStatuses>, "data" | "getLabel">;

export const ActStatusClientsSelect: React.FunctionComponent<ActStatusClientsPropsType> = (props) => {
	const [actStatusClinet, renderLabel] = useActStatusClinetsTypes();

	return (
		<AutocompleteSelect<ActStatuses>
			data={actStatusClinet}
			getLabel={renderLabel}
			{...(props as any)}
		/>
	);
};


