import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";
import { Warehouse } from "../../../data/listing/warehouses/types";

export function useWhsColumns() {
  const { getString, getFormatedNumberString } = useContext(LocalizationContext);

  return useMemo(
    () => [
      {
        title: getString("whsCode"),
        field: "whsCode",
      },
      {
        title: getString("whsName"),
        field: "whsName",
      },
      {
        title: getString("cliCity"),
        field: "city",
      },
      {
        title: getString("whsPort"),
        field: "portName",
      },
      {
        title: getString("whsDistanceToPort"),
        render: (whs) => getFormatedNumberString(whs.distanceToPort, true),
        sorting: false,
				width: "140px",
				cellStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
					textAlign: "end",
					paddingRight: "10px",
				},
      },
    ],
    [getString]
  ) as Column<Warehouse>[];
}
