import { ThunkType } from "../../../../../core/store/reducers";
import getAllClientContracts from "../../api/clientContract/getAllClientContracts";
import { ClientContractList } from "../../types";

export const getClientContracts = (clientId: string): ThunkType<Promise<ClientContractList[] | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getAllClientContracts(userId, clientId));
}
