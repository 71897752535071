import { webApiWithResponse } from "../../../../../core/http/httpThunks";
import { ClientAccount } from "../../types";

export const createClientAccountApi = (userId: string, account: ClientAccount) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/newclimonacc",
		params: {
			userId
		},
		data: account
	});

export default createClientAccountApi;
