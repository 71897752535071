import { ClientPerson } from "../../types";
import { ValidationState } from "../../../../../core/util/types";
import { createUseValidator } from "../../../../../core/util/hooks";

export const personValidation: ValidationState<ClientPerson> = {
	cliPrsName: true,
	cliPrsIdNo: true,
	cliPrsRole: true,
}

export const useClientValidator = createUseValidator<ClientPerson, ValidationState<ClientPerson>>(personValidation);
