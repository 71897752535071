import { CommReviewReportData, ComReviewReportFilter } from "../types";
import { User } from "../../../../auth/types";
import { webApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";

const getCommReviewData = (user: User, filter: ComReviewReportFilter) => {
  return webApi<CommReviewReportData[]>({
    method: "GET",
    url: "reporting/getcommreviewlist",
    params: {
      userid: user.userId,
      datefrom: getParamsDateString(filter.datefrom),
      dateto: getParamsDateString(filter.dateto),
      productid: filter.product?.prodId,
      clientid: filter.client?.cliId,
      broker: filter.broker?.empId,
      side: filter.side,
    },
  });
};

export default getCommReviewData;
