import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { ValidationState } from "../../../core/util/types";
import { LocalizationContext } from "../../../core/localization/Localization";

interface StringInputProps<T> {
	property: keyof T;
	item: T;
	validationState?: ValidationState<T>;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	errorMessage?: string;
}

export type StringInputPropsType<T> = StringInputProps<T> & TextFieldProps;

export function StringInput<T>(props: StringInputPropsType<T>) {
	const { getString } = React.useContext(LocalizationContext);
	const { property, item, validationState, label, onChange, errorMessage, ...otherProps } = props;
	const error = validationState && validationState[property] !== undefined && !validationState[property];
	return (
		<TextField
			name={property as any}
			value={item[property] || ""}
			onChange={onChange}
			fullWidth
			label={label}
			required={validationState && validationState[property] !== undefined}
			error={error || errorMessage !== undefined}
			helperText={errorMessage || (error && getString("requiredMessage"))}
			{...otherProps}
		/>
	);
}
