import { webApi } from "../../../../core/http/httpThunks";
import { AlarmData } from "../types";

export const getAlarmApi = (userId: string, almid: string) =>
  webApi<AlarmData>({
    method: "GET",
    url: "surveillance/getalarmdef",
    params: {
      userId,
      almid,
    },
  });

export default getAlarmApi;
