import React from "react";
import { ViewOrder } from "../../../data/trading/orders/types";
import { PaginationTable, PaginationDataProps } from "../../components/common/PagginationTable";
import { useOrderBookActions } from "./useOrderBookActions";
import { useOrderBookColumns } from "./useOrderBookColumns";
import { useOrderBookColumnsClient } from "./useOrderBookColumnsClient";
import { AccessRights } from "../../../core/navigation/types";
import { useOrderRowColor } from "../orders/useOrderRowColor";
import { useAuth } from "../../../data/auth/hooks";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export interface OrderBookTableProps {
	userAccessRights: AccessRights;
	getData: () => void;
}

type OrderBookTablePropsType = OrderBookTableProps & PaginationDataProps<ViewOrder>;

const OrdersBookTable: React.FunctionComponent<OrderBookTablePropsType> = ({
	userAccessRights,
	getData,
	...tableProps
}) => {

  const [auth] = useAuth();

  const theme = useTheme();
  const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

  const actions = useOrderBookActions();
  const columns = useOrderBookColumns();
  const columnsClient = useOrderBookColumnsClient();
  const rowStyle = useOrderRowColor()

	return (
		<PaginationTable<ViewOrder>
			{...tableProps}
			getData={getData}
			title=""
			columns={auth.status === "successful" &&  auth.user.roleCode !== "MEMTRADER" &&  auth.user.roleCode !== "MEMMANAGER" &&  auth.user.roleCode !== "MEMCONTACT" ? columns: columnsClient}
			actions={actions}
			options={{
				actionsColumnIndex: screenExtraSmall === true ? 0 : -1 ,
				search: false,
				toolbar: false,
				actionsCellStyle: {
					width: "100px",
					maxWidth: "100px",
				},
        rowStyle
      }}
		/>
	);
};

export default OrdersBookTable;
