import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useTransportFeeData } from "../../../data/clearing/transportfees/hooks/useTransportFeeData";
import { TransportFee } from "../../../data/clearing/transportfees/types";
import { useTransportFeeColumns } from "./useTransportFeeColumns";
import { AccessRights } from "../../../core/navigation/types";
import {
  useTransportFeeActions,
  useDeleteTransportFeeAction,
} from "./useTransportFeeActions";

export interface TransportFeeTableProps {
  userAccessRights: AccessRights;
}

type TransportFeeTablePropsType = TransportFeeTableProps &
  TableDataProps<TransportFee>;

export const TransportFeesTable: React.FunctionComponent<TransportFeeTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const [data, loadAll] = useTransportFeeData();

  const columns = useTransportFeeColumns();
  const actions = useTransportFeeActions();
  const onDeleteTransportFee = useDeleteTransportFeeAction();

  return (
    <Table<TransportFee>
      {...tableProps}
      getData={loadAll}
      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        toolbar: false,
        pageSize: 10,
        actionsColumnIndex: -1,
      }}
      editable={{
        onRowDelete: onDeleteTransportFee,
      }}
    />
  );
};

export default TransportFeesTable;
