import { combineReducers } from "@reduxjs/toolkit";
import payments from "./payments/paymentsUXSlice";
import controls from "./ctrlgoods/ctrlGoodsUXSlice";
import deliveries from "./delivery/deliveryUXSlice";
import disputes from "./dispute/disputeUXSlice";
import guarantees from "./guarantees/guaranteesUXSlice";
import transportAndStorage from "./transportAndStorage/TASUXSlice";
import tradingData from "./tradingData/tradingUXSlice";
import marginsData from "./marginsData/marginsUXSlice";
import bankguarantees from "./bankguarantees/bankguaranteesUXSlice";

export default combineReducers({
  payments,
  controls,
  deliveries,
  disputes,
  guarantees,
  transportAndStorage,
  tradingData,
  marginsData,
  bankguarantees,
});