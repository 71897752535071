import React from "react";
import { AutocompleteSelect, AutocompleteSelectType } from "../common/AutocompleteSelect";
import { useTradesActiveData } from "../../../data/trading/trades/hooks/useTradesActiveData"
import { TradeFilter, TradesSimpleListData } from "../../../data/trading/trades/types"
import { ClientListData } from "../../../data/listing/clients/types";
import { useClientSelector } from "../../../data/listing/clients/hooks/useClientData";

type TradesSelectPropsType = Omit<AutocompleteSelectType<TradesSimpleListData>, "data" | "getLabel"> & {
//	filter?: TradeFilter;
	onSellerChange?: (seller?: ClientListData) => void;
	onBuyerChange?: (buyer?: ClientListData) => void;
};

export const TradesSelect: React.FunctionComponent<TradesSelectPropsType> = ({
//	filter,
	predicate,
	onSellerChange,
	onBuyerChange,
	...otherProps
}) => {
//	const [trades] = useTradesActiveData(undefined, undefined, filter);
	const [trades] = useTradesActiveData();

	const selected = React.useMemo(() => predicate && trades?.find(predicate), [trades, predicate])

	const sellerSelector = React.useCallback((client: ClientListData) => client.cliId === selected?.sellerId, [selected])
	const buyerSelector = React.useCallback((client: ClientListData) => client.cliId === selected?.buyerId, [selected])

	const [seller] = useClientSelector(sellerSelector)
	const [buyer] = useClientSelector(buyerSelector)

	React.useEffect(() => {
		onSellerChange && onSellerChange(seller)
	}, [seller, onSellerChange])

	React.useEffect(() => {
		onBuyerChange && onBuyerChange(buyer)
	}, [buyer, onBuyerChange])

	return (
		<AutocompleteSelect<TradesSimpleListData>
			{...(otherProps as AutocompleteSelectType<TradesSimpleListData>)}
			data={trades || []}
			predicate={predicate}
			getLabel={renderAttributeLabel}
//			disabled={filter === undefined}
		/>
	);
};

const renderAttributeLabel = (trade: TradesSimpleListData) => trade.tradeData;
