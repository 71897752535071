import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { GuaranteeData } from "../types";
import { loadGuarantee } from "../thunks/loadGuarantees";

export const useDisplayGuarantee = (payId?: string) => {
  const storeGuarantee = useSelector<StoreType, GuaranteeData | undefined>(
    (store) => store.data.clearing.guarantees.displayedGuarantee
  );

  const displayedGuarantee =
    storeGuarantee && storeGuarantee.payId === payId
      ? storeGuarantee
      : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (payId) {
      displatch(loadGuarantee(payId));
    }
  }, [displatch, payId]);

  return displayedGuarantee;
};
