import React from "react";
import { Grid, CardContent, CardHeader, Card, Divider } from "@material-ui/core";
import MarginsDataTable from "./MarginsDataTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { useMarginsDataData } from "../../../data/clearing/marginsData/hooks/useMarginsDataData";
import { useMarginsDataIndex } from "../../../data/clearing/marginsData/hooks/useMarginsDataIndex";
import { PageDataStatus } from "../../../data/models";
import { MarginsDataFilterButton } from "./MarginsDataFilter";
import { MarginsDataButton } from "./MarginsDataButton";
import { MarginsDateInput } from "./MarginsDateInput";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";

const MarginsDataPage: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [pageData, dispatchLoadMarginsData] = useMarginsDataData();
	const [pageIndex, dispatchSetIndex] = useMarginsDataIndex();

	React.useEffect(() => {
		if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
			dispatchLoadMarginsData();
		}
	}, [dispatchLoadMarginsData, pageData]);

	return (
		<>
			<Grid container className={pageStyles.root}>
				<Grid item xs={12} className={pageStyles.gridItem}>
					<Card>
						<CardHeader
							title={<PageTitle title="marginsDataTitle" />}
							action={<MarginsDataActions />}
						/>
						<Divider />
						<CardContent>
							<MarginsDataTable
								pageIndex={pageIndex}
								pageData={pageData}
								onPageIndexChange={dispatchSetIndex}
								userAccessRights={userAccessRights}
								getData={dispatchLoadMarginsData}
							/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			<MarginsDataButton />
		</>
	);
};

export const MarginsDataActions: React.FunctionComponent = () => (
	<Grid container spacing={4}>
		<Grid item>
			<MarginsDateInput />
		</Grid>
		<Grid item>
 		    <DownloadButton />
    	</Grid>
    	<Grid item>
      		<DownloadExcelButton />
    	</Grid>
		<Grid item>
			<MarginsDataFilterButton />
		</Grid>
	</Grid>
);

export default MarginsDataPage;
