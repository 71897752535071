import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType } from "../../models";
import { AntiLaunderingReportData } from "./types";

type TradesReportData = {
  data?: AntiLaunderingReportData[];
  downloadReportFetchState: FetchStateType;
};

const initialState: TradesReportData = {
  downloadReportFetchState: { type: "not-started" },
};

const name = "antiLaundering";

const antiLaunderingSlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<AntiLaunderingReportData[] | undefined>
    ) => ({
      ...state,
      data: action.payload,
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      downloadReportFetchState: action.payload,
    }),
  },
});

export const { setData, setFetchState } = antiLaunderingSlice.actions;

export default antiLaunderingSlice.reducer;
