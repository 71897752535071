import React from "react";
import { useDispatch } from "react-redux";
import { ProductAttribute } from "../../types";
import { getAttribute } from "../../thunks/attributes/loadAttribute";

export const useAttribute = (attributeId?: string) => {
	const displatch = useDispatch();
	const [attribute, setAttribute] = React.useState<ProductAttribute | undefined>();

	React.useEffect(() => {
		const loadClient = async () => {
			if (attributeId) {
				const loadedAttribute = (await displatch(getAttribute(attributeId))) as any;
				setAttribute(loadedAttribute);
			}
		};
		loadClient();
	}, [displatch, attributeId, setAttribute]);

	return [attribute];
};
