import React from "react";
import { useDisplayCity } from "../../../data/listing/cities/hooks/useDisplayCity";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { City } from "../../../data/listing/cities/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayCityMainAttributes } from "./DisplayCityMainAttributes";
import { ExpansionPanel, ExpansionPanelSummary, Typography, Grid } from "@material-ui/core";

export interface DisplayCitiesProps {
	id?: string;
}

export const DisplayCity: React.FunctionComponent<DisplayCitiesProps> = ({ id }) => {
	const city = useDisplayCity(id);
	const { getString } = React.useContext(LocalizationContext);

	return !city ? (
		<>{"loading placeholder"}</>
	) : (
		<Grid container justify="center" direction="column">
			<ExpansionPanel expanded>
				<ExpansionPanelSummary>
					<Typography>{getString("tableBasciInfo")}</Typography>
				</ExpansionPanelSummary>
				<DisplayCityMainAttributes city={city} />
			</ExpansionPanel>
		</Grid>
	);
};

export const useViewCity = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	return React.useCallback(
		(city: City) => showWithDrawer(<DisplayCity id={city.cityId} />),
		[showWithDrawer]
	);
};
