import MonthlyTradesReport from "./MonthlyTradesReport";
import { NavigationItem } from "../../../core/navigation/types";
import {
  monthlyTradesReportLocation,
  monthlyProductLocation,
  monthlyClientLocation,
  monthlyDateLocation,
} from "./Locations";
import MTRProductPage from "./MTRProductPage";
import MTRClientPage from "./MTRClientPage";
import MTRDatePage from "./MTRDatePage";

export const monthlyTradesReportComponents: NavigationItem[] = [
  {
    Component: MonthlyTradesReport,
    isMenuItem: true,
    path: monthlyTradesReportLocation,
  },
  {
    Component: MTRProductPage,
    isMenuItem: false,
    path: monthlyProductLocation,
  },
  {
    Component: MTRClientPage,
    isMenuItem: false,
    path: monthlyClientLocation,
  },
  {
    Component: MTRDatePage,
    isMenuItem: false,
    path: monthlyDateLocation,
  },
];
