import MainBoard from "./MainBoard";
import { NavigationItem } from "../../../core/navigation/types";
import { mainBoardMLocation, baseEdit } from "./Locations";
import { EditOrderPage } from "../orders/EditOrderPage"

export const mainBoardMComponents: NavigationItem[] = [
	{
		Component: MainBoard,
		isMenuItem: true,
		path: mainBoardMLocation,
		notifications: true,
	},
	{
		Component: EditOrderPage,
		isMenuItem: false,
		path: baseEdit,
	},
];
