import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import {
	useOrderBookFilter,
	useCurrentOrderBookFilter,
} from "../../../data/trading/orderBook/hooks/useOrderBookFilter";
import { OrderFilterEditor, OrderFilterEditorType, OrderFilterChips } from "../orders/OrderFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const OrderBookFilterButton: React.FunctionComponent = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<OrderBookFilterEditor />), [showWithDrawer]);

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	const [filter, remnoveFilter] = useCurrentOrderBookFilter();

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<OrderFilterChips filter={filter} remnoveFilter={remnoveFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip" >
					<IconButton color={!screenExtraSmall || Object.keys(filter).length===0 ? "secondary" : "primary" } component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const OrderBookFilterEditor: React.FunctionComponent = () => {
	const [filter, editFilter, save, , reset] = useOrderBookFilter();
	return (
		<OrderFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
			type={OrderFilterEditorType.Extended}
			reset={reset}
		/>
	);
};
