import React, { useContext, useMemo } from "react";
import ViewIcon from "@material-ui/icons/InputOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { InvoiceListData } from "../../../data/accounting/invoices/types";
import { InvoiceData } from "../../../data/accounting/invoices/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { MaterialTableProps } from "material-table";
import { useViewInvoice } from "./DisplayInvoice";
import { useHistory } from "react-router-dom";
import { getInvoicesEditLocation } from "./Locations";
import { DisplayInvoiceMainAttributes } from "./DisplayInvoiceMainAttributes";
import { useInvoiceDelete } from "../../../data/accounting/invoices/hooks/useInvoiceDelete";
import { useNewsDownload } from "../../../data/reports/news/hooks/useNewsDownload"

export function useInvoiceActions() {
  const { getString } = useContext(LocalizationContext);
  const onShowInvoice = useViewInvoice();
//  const onShowInvoice = useNewsDownload();
  const onEditInvoice = useEditInvoiceAction();

  return useMemo(() => {
    const result: MaterialTableProps<InvoiceListData>["actions"] = [
      {
        icon: () => <ViewIcon />,
        tooltip: getString("orderActView"),
        onClick: (event, rowData) => onShowInvoice(rowData as InvoiceListData),
      },
      {
        icon: () => <EditIcon />,
        tooltip: getString("tableEdit"),
        onClick: (event, rowData) => onEditInvoice(rowData as InvoiceListData),
      },
    ];
    return result;
  }, [onShowInvoice, onEditInvoice, getString]);
}

export const useDeleteInvoiceAction = () => {
  const [, deleteInvoice] = useInvoiceDelete();
  return React.useCallback(
    async (invoice: InvoiceListData) => {
      if (invoice.invId) {
        await deleteInvoice(invoice.invId);
      }
    },
    [deleteInvoice]
  );
};

export const useEditInvoiceAction = () => {
  const history = useHistory();
  return React.useCallback(
    (invoices: InvoiceListData) =>
      history.push(getInvoicesEditLocation(invoices.invId)),
    [history]
  );
};


