import React from "react";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import { IconButton, Grid } from "@material-ui/core";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import {
	useMainBoardFilter,
	useCurrentMainBoardFilter,
} from "../../../data/trading/mainBoard/hooks/useMainBoardFilter";
import { OrderFilterEditor, OrderFilterEditorType, OrderFilterChips } from "../orders/OrderFilterEditor";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const MainBoarFilterButton: React.FunctionComponent = () => {
	const showWithDrawer = React.useContext(DisplayDrawerContext);
	const showFilter = React.useCallback(() => showWithDrawer(<MainBoarFilterEditor />), [showWithDrawer]);

	const theme = useTheme();
	const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

	const [filter, remnoveFilter] = useCurrentMainBoardFilter();

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<OrderFilterChips filter={filter} remnoveFilter={remnoveFilter} />
			</Grid>
			<Grid item>
				<CustomTooltip titleKeyword="filterTooltip">
					<IconButton color={!screenExtraSmall || Object.keys(filter).length===1 ? "secondary" : "primary" } component="span" onClick={showFilter}>
						<FilterIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

const MainBoarFilterEditor: React.FunctionComponent = () => {
	const [filter, editFilter, save, , rest] = useMainBoardFilter();
	return (
		<OrderFilterEditor
			filter={filter}
			editFilter={editFilter}
			save={save}
			type={OrderFilterEditorType.Basic}
			reset={rest}
		/>
	);
};
