import { DailyTradingReportData, DailyTradingReportFilter } from "../types";
import { User } from "../../../auth/types";
import { webApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";

const getDailyTradingData = (
  user: User,
  filter: DailyTradingReportFilter,
) => {

  return webApi<DailyTradingReportData[]>({
    method: "GET",
    url: "reporting/getdailytradinglist",
    params: {
      userid: user.userId,
      reportdate: getParamsDateString(filter.reportDate)
    },
  });
};

export default getDailyTradingData;
