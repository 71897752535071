import { ThunkType } from "../../../../core/store/reducers";
import getControlApi from "../api/getCtrlGood";
import { setDisplayed } from "../ctrlGoodsDataSlice";
import { CtrlGoodsData } from "../types";

export const loadCtrlGood = (controlId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const ctrlGood = await dispatch(getControlGood(controlId));

  dispatch(setDisplayed(ctrlGood));
};

export const getControlGood = (
  controlId: string
): ThunkType<Promise<CtrlGoodsData | undefined>> => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getControlApi(userId, controlId));
};
