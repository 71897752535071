import TradeReviewReport from "./TradeReviewReport";
import { NavigationItem } from "../../../../core/navigation/types";
import { tradeReviewLocation } from "./Locations";

export const tradeReviewReportComponents: NavigationItem[] = [
  {
    Component: TradeReviewReport,
    isMenuItem: true,
    path: tradeReviewLocation,
  },
];
