import React from "react";
import { Employee } from "../../../data/listing/employees/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayBoolNumber } from "../../components/common/DisplayBoolNumber";
import { useDisplaySingleEmementStyles } from "../../useDisplaySingleEmementStyles";
import { Status } from "../../../data/models";

export interface DisplayEmployeeMainAttributesProps {
	employee: Employee;
}

export const DisplayEmployeeMainAttributes: React.FunctionComponent<DisplayEmployeeMainAttributesProps> = ({
	employee,
}) => {
	const style = useDisplaySingleEmementStyles();
	const { getString, getGender, getDateString } = React.useContext(LocalizationContext);

	return (
		<div className={style.root}>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empFirstName")}</span>
				<span className={style.value}>{employee.firstName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empMidName")}</span>
				<span className={style.value}>{employee.midName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empLastName")}</span>
				<span className={style.value}>{employee.lastName}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empIdNumber")}</span>
				<span className={style.value}>{employee.empIdNumber}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empGender")}</span>
				<span className={style.value}>{getGender(employee.gender)}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empAddress")}</span>
				<span className={style.value}>{employee.address}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empZipCode")}</span>
				<span className={style.value}>{employee.zipCode}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empCity")}</span>
				<span className={style.value}>{employee.city}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empEmail")}</span>
				<span className={style.value}>{employee.email}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empPhone")}</span>
				<span className={style.value}>{employee.phone}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empOfficePhone")}</span>
				<span className={style.value}>{employee.phoneOffice}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empFax")}</span>
				<span className={style.value}>{employee.fax}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empDateOfBirth")}</span>
				<span className={style.value}>
					{employee.dateOfBirth && getDateString(employee.dateOfBirth)}
				</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empPosition")}</span>
				<span className={style.value}>{employee.position}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empBranch")}</span>
				<span className={style.value}>{employee.branch}</span>
			</div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("empStatus")}</span>
				<span className={style.value}>
					<DisplayBoolNumber
						value={employee.status}
						thrutyValue={Status.Active}
						className={style.icon}
					/>
				</span>
			</div>
		</div>
	);
};
