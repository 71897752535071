import { getEditLocation } from "../../../core/util/functions";
import { RegType } from "../../../data/models";

export const regionsLocation = "/listing/codebook/region";
export const topRegionsLocation = "/listing/codebook/topregion";
export const subRegionsLocation = "/listing/codebook/subregion";

export const getRegionsEditLocation = (id?: string) => getEditLocation(regionsLocation, undefined, id);

export const getRegionsLocation = (type?: RegType) => {
	switch (type) {
		case RegType.TopRegion:
			return topRegionsLocation;
		case RegType.Region:
		default:
			return regionsLocation;
	}
};

export const editRouter = getRegionsEditLocation(":regId?");
