import { ThunkType } from "../../../../core/store/reducers";
import createDisputeApi from "../api/createDispute";
import updateDisputeApi from "../api/updateDispute";
import { DisputeData } from "../types";
import { setFetchState, invalidateDispute } from "../disputeDataSlice";

export const upsertDispute = (dispute: DisputeData): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;

  const result = dispute.dispSitId
    ? await dispatch(updateDisputeApi(userId, dispute))
    : await dispatch(createDisputeApi(userId, dispute));

  if (result && result.resCode === "OK") {
    dispatch(setFetchState({ type: "successful", result: result.resDesc }));
    dispatch(invalidateDispute());
    return result.resDesc;
  } else {
    dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
  }
};
