import { ThunkType } from "../../../../core/store/reducers";
import getCICreateButton from "../api/getCIDataCreateButton";
import { setFetchState } from "../createInvoicesDataSlice";

export const downloadCreateInvoicesCreateButton = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { filter } = store.ux.accounting.createInvoices;
	const user = store.data.auth.current.user;
	dispatch(setFetchState({ type: "started" }));
	const ordersReport = await dispatch(getCICreateButton(user, filter));
	dispatch(setFetchState({ type: "successful" }));
};
