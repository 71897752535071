import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { setIndex } from "../monthlyTradeUXSlice";
import { PageIndex } from "../../../models";

export const useMonthlyTradesReportIndex = () => {
  const pageIndex = useSelector<StoreType, PageIndex>(
    (s) => s.ux.reports.monthlyTrade.index
  );

  const dispatch = useDispatch();
  const dispatchSetIndex = React.useCallback(
    (index: PageIndex) => dispatch(setIndex(index)),
    [dispatch]
  );

  return [pageIndex, dispatchSetIndex] as [
    typeof pageIndex,
    typeof dispatchSetIndex
  ];
};
