import { webApiWithResponse } from "../../../../../core/http/httpThunks";
import { ClientBroker } from "../../types";

export const updateClientBrokerApi = (userId: string, person: ClientBroker) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/updclibrok",
		params: {
			userId
		},
		data: person
	});

export default updateClientBrokerApi;
