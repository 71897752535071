import { ThunkType } from "../../../../core/store/reducers";
import createDeliveryApi from "../api/createDelivery";
import updateDeliveryApi from "../api/updateDelivery";
import { DeliveryData } from "../types";
import { setFetchState, invalidateDelivery } from "../deliveryDataSlice";

export const upsertDelivery = (deliveries: DeliveryData): ThunkType => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  dispatch(setFetchState({ type: "started" }));

  const { userId } = store.data.auth.current.user;
  const result = deliveries.deliveryId
    ? await dispatch(updateDeliveryApi(userId, deliveries))
    : await dispatch(createDeliveryApi(userId, deliveries));

  if (result && result.resCode === "OK") {
    dispatch(setFetchState({ type: "successful", result: result.resDesc }));
    dispatch(invalidateDelivery());
    return result.resDesc;
  } else {
    dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
  }
};
