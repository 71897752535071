import { UserData, UserValidationState, UserSubscribtionUnlimited, UserEmployee, UserChangePass } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const userValidationState: UserValidationState = {
    loginUser: true,
    loginPass: true,
    subscribed_Until: true,
    roleId: true,
    userName: true,
    cliPrsId: true,
    clientId: true,
    empId: true,
};

export const useUserValidator = createUseValidator<UserData, UserValidationState>(userValidationState, (newState, validation) => ({
    ...validation,
    loginPass: validation.loginPass || newState.changePass === UserChangePass.False,
    subscribed_Until: validation.subscribed_Until || newState.subscrUnlimited === UserSubscribtionUnlimited.True,
    empId: validation.empId || newState.isEmployee === UserEmployee.False,
    clientId: validation.clientId || newState.isEmployee === UserEmployee.True,
    cliPrsId: validation.cliPrsId || newState.isEmployee === UserEmployee.True,
}));
