import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { loadAllCountries } from "../thunks/loadAllCountries";
import { Country } from "../types";

export const useCountryData = () => {
	const data = useSelector<StoreType, Country[] | undefined>(s => s.data.listing.countries.displayData);
	const allData = useSelector<StoreType, Country[] | undefined>(s => s.data.listing.countries.all);

	const dispatch = useDispatch();
	const dispatchLoadAllCountries = React.useCallback(() => dispatch(loadAllCountries()), [dispatch]);

	return [data, dispatchLoadAllCountries, allData] as [
		typeof data,
		typeof dispatchLoadAllCountries,
		typeof allData
	];
};

export const useCountry = (id?: string) => {
	const [countryGetter] = useCountryGetter();
	return countryGetter(id);
};

export const useCountryGetter = () => {
	const allData = useSelector<StoreType, Country[] | undefined>(s => s.data.listing.countries.all);

	const dispatch = useDispatch();
	const dispatchLoadAllCountries = React.useCallback(() => dispatch(loadAllCountries()), [dispatch]);

	React.useEffect(() => {
		if (!allData) {
			dispatchLoadAllCountries();
		}
	}, [allData, dispatchLoadAllCountries]);

	const getCountry = React.useCallback(
		(id?: string) => {
			if (!allData) {
				return undefined;
			} else {
				return allData?.find(e => e.cntId === id);
			}
		},
		[allData]
	);

	return [getCountry] as [typeof getCountry];
};
