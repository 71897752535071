import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useCurrencyRateData } from "../../../data/listing/curencyRates/hooks/useCurrencyRateData";
import { CurrencyRate } from "../../../data/listing/curencyRates/types";
import { useCurrencyRatesColumns } from "./useCurrencyRatesColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useCurrenciesActions } from "./useCurrencyRatesActions";

export interface CurrencyRateTableProps {
	userAccessRights: AccessRights;
}

type CurrencyRateTablePropsType = CurrencyRateTableProps & TableDataProps<CurrencyRate>;

export const CurrencyRateTable: React.FunctionComponent<CurrencyRateTablePropsType> = ({
	userAccessRights,
	...tableProps
}) => {
	const [data, loadAll] = useCurrencyRateData();

	const columns = useCurrencyRatesColumns();
	const actions = useCurrenciesActions();

	return (
		<Table<CurrencyRate>
			{...tableProps}
			getData={loadAll}
			immutableData={data}
			title=""
			columns={columns}
			actions={actions}
			options={{
				toolbar: false,
				pageSize: 10,
				actionsColumnIndex: -1,
			}}
		/>
	);
};

export default CurrencyRateTable;
