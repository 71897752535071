import React from "react";
import { useBanks } from "../../../data/initialData/hooks/useBanks";
import { Bank } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType } from "./AutocompleteSelect";

type BankSelectPropsType = Omit<AutocompleteSelectType<Bank>, "data" | "getLabel">;

export const BankSelect: React.FunctionComponent<BankSelectPropsType> = props => {
	const [banks, renderBankLabel] = useBanks();

	return <AutocompleteSelect<Bank> data={banks} getLabel={renderBankLabel} {...props as any} />;
};
