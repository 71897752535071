import { OrderSide } from "../../models";
import { AttributeManditory, AttributeType } from "../../listing/attributes/types";
import {ProducAttributeHasStandard } from "../../listing/products/types";
import {ClientActive } from "../../listing/clients/types";

export interface ViewOrder {
	ordId: string;
	timeReceived: string;
	ordNo: string;
	ordType: string;
	refOrd: string;
	clientName: string;
	product: string;
	prodCode: string;
	side: string;
	qty: number;
	unit: string;
	allOrNothing: boolean;
	price: number;
	priceWithVat: number;
	currCode: string;
	expiryDate: string;
	exportReady: boolean;
	status: string;
	statusN: number;
	quality: string;
	totNoOfRows: number;
	canVerify: number; //		indikator da li se u redu pojavljuje ikona za verifikaciju
	canCancel: number; //		isto za cancel orde
	canReplace: number; //		isto za replace order ili opposite order
	canDelete: number; //		broker ili kontrolor brišu nalog
	market: OrderMarkt;
	qtyLeft?: number;
	priceEur: number;  //       cena neto u eur
	srps?: number,
}

export interface GetOrdersParams {
	userid: string;
	side?: OrderSide;
	empid?: string;
	cliid?: string;
	cliprsid?: string;
	prodid?: string;
	status?: number;
	datefrom?: string;
	dateto?: string;
	fromrow?: number;
	numofrows?: number;
	market?: OrderMarkt;
}

export interface GetSimpleOrdersParams {
	userid: string;
	side?: OrderSide;
	cliid: string;
	prodid?: string;
	status?: number;
}

export type SimpleOrderFilter = Omit<GetSimpleOrdersParams, "userid">;

export enum OrderAllOrNothing {
	True = 1,
	False = 0,
}

export enum OrderExportReady {
	True = 1,
	False = 0,
}

export interface Order {
	ordId: string;
	ordNo: string;
	timeReceived: Date;
	receiveMethod: number;
	receiveMethodDescr: string;
	ordType: number;
	ordTypeDescr: string;
	refOrdId: string;
	refOrdNo: string;
	brokId: string;
	brokName: string;
	userId: string;
	userName: string;
	clientId: string;
	clientName: string;
	cliPrsId: string;
	cliPrsName: string;
	prodId: string;
	prodCode: string;
	prodName: string;
	side: OrderSide;
	unit: number;
	unitDescr: string;
	franco: number;
	francoDescr: string;
	qty: number;
	qtyLeft: number;
	price: number;
	percVat: number;
	priceWithVat: number;
	allOrNothing: OrderAllOrNothing;
	minQty: number;
	expiryDate: Date;
	payTypeId: string;
	payTypeDescr: string;
	tradePlcId: string;
	tradePlcName: string;
	checkPoint: number;
	checkPointDescr: string;
	paysCheck: number;
	paysCheckDescr: string;
	goodsReadyFrom: Date;
	goodsReadyTo: Date;
	exportReady: OrderExportReady;
	comment: string;
	commission: number;
	cancReplTime: Date;
	cancReplCliPersonId: string;
	ordStatus: string;
	ordStatusDescr: string;
	orderAttrList?: OrderAttribute[];
	locCountryId?: string; // 	ID drzave (default SR)
	locCountryName?: string; //
	locRegionId?: string; // 		ID regiona bivsi nadregion (regtype=10)
	locRegionName?: string; //
	locDistrictId?: string; // 	ID distrikta bivsi region (regtype=20)
	locDistrictName?: string; //
	locCityId?: string; // 		ID grada
	locCityName?: string; //
	locWarehouseId?: string; // 	ID skladista
	locWarehouseName?: string; //
	market: OrderMarkt;
	priceEur: number;  //       cena neto u eur
	userIsEmployee?: number; 
	orderLogList?: OrderLogData[];
	fundsCheckStatus: FundsCheckStatus;
	bankGuarantee: number,
	guaranteeDescr?: string,
	paymentDays?: number,
	srps?: number,
}

export interface EditOrder {
	ordId?: string; //			A iz internih brojaca
	ordNo?: string; //			A isto
	timeReceived?: Date; //	A tekuce vreme
	receiveMethod?: number; //	A ako je uneo klijent a broker bira. Default staviti telefonom
	ordType?: OrderType; //	A 0 za normalni, 1 replace nalog, 2 cancel nalog
	refOrdId?: string; //		koristi se kod replace/cancel naloga
	brokId?: string; //		A
	userId: string; //		korisnik koji unosi nalog (koji je ulogovan)
	clientId?: string; //		klijent koji ispostavlja nalog, ako unosi lice klijenta, prosledjuje se na osnovu ulogovanog korisnika, ako unosi broker, bira klijenta
	cliPrsId?: string; //		lice koje daje nalog. Odredjuje se u backend-u prema ulogovanom korisniku ili kao podrazumevano lice.
	prodId?: string; //		ID proizvoda
	unit?: number; //			popunjava se iz izabranog produkta
	side: OrderSide; //			BUY ili SELL
	franco?: number; //			bira se iz liste
	qty?: number; //		kolicina. Ako je jedinica kg, mora da bude n * 25000
	qtyLeft?: number; //		A preostala kolicina u nalogu, na osnovu realizacije
	price?: number; //		cena
	percVat?: number; //		A procenat PDV
	priceWithVat?: number; //	A cena sa pdv
	allOrNothing?: number; //		sve ili nista – checkbox
	minQty?: number; //		minimalna kolicina unosi se po potrebi
	expiryDate?: Date; //;	datum do kad vazi nalog (max 30 dana)
	payTypeId?: string; //		RSD, valuta ???
	tradPlcId?: string; //		mesto prometa (paritet), unosi se (PARITET)
	checkPoint?: number; //		bira se iz liste
	paysCheck?: number; //		isto
	goodsReadyFrom?: Date; //;	unosi se datum od kada je roba spremna za isporuku
	goodsReadyTo?: Date; //;	do kog datuma je roba spremna
	comment?: string; //		komentar, unosi se opciono
	commission?: number; //		A % provizije PB
	cancReplTime?: Date; //;	A datum/vreme opoziva/modifikacije naloga
	cancReplCliPersonId?: string; //	A lice koje je izvrsilo/zahtevalo opoz/modif
	ordStatus?: number; //		A status naloga
	exportReady: number;
	orderAttrList?: OrderAttribute[];
	locCountryId?: string; // 	ID drzave (default SR)
	locCountryName?: string; //
	locRegionId?: string; // 		ID regiona bivsi nadregion (regtype=10)
	locRegionName?: string; //
	locDistrictId?: string; // 	ID distrikta bivsi region (regtype=20)
	locDistrictName?: string; //
	locCityId?: string; // 		ID grada
	locCityName?: string; //
	locWarehouseId?: string; // 	ID skladista
	locWarehouseName?: string; //
	market: OrderMarkt;
	fundsCheckStatus: FundsCheckStatus;
	bankGuarantee?: number,
	paymentDays?: number,
	srps?: number,
	active?: ClientActive; //		0=ima pristup app (def), 1=zabranjen pristup
}


export interface OrderAttribute {
	ordAttrId?: string; //	PK
	ordId?: string; //		ID naloga
	attrId?: string; //	ID atributa
	attrName?: string; //	naziv atributa
	attrValId?: string; //	ID vredosti atributa (popunjeno za atribute tipa SELECT)
	attrValue?: number; //	vrednost atributa (popunjeno za atribute tipa TEXT)
	//jedan i samo jedan od poslednja 2 mora biti popunjen
	manditory?: AttributeManditory;
	type?: AttributeType;
	comparator?: string;
	hasStandard?: ProducAttributeHasStandard;
	standardValue?: number;
}

export interface OrderLogData {
	changeTime?: Date; 
	userName?: string; 
	activity?: string; 
	status?: string; 
	refOrder?: string; 
}


export enum OrderType {
	New = 0,
	Replace = 1,
	Cancel = 2,
}

export enum OrdersEditAction {
	New = "new",
	Replay = "replay",
	Verify = "verify",
	Modify = "modify",
}

export enum OrderMarkt {
	Spot = 0,
	Forward = 1,
}

export enum FundsCheckStatus {
	NotChecked = "NOTCHECKED",
	Ignore = "IGNORE",
	Confirmed = "CONFIRMED",
}

export type OrderUpsertResult =
	| { status: "CREATED"; newId: string }
	| { status: "NOFUNDS"; message: string }
	| { status: "ERROR"; message?: string };

export interface OrderSimpleData {
	ordId: string;
	ordData: string;
	depAmt: number;
	depAmtPayed: number;
	price: number;
	qty: number;
	side: string;
	nonAllocAmt: number;
}

export type OrderSideDictionary<T> = {
	[key in OrderSide]: T;
};

export type OrderSideArgs<T> = {
	side: OrderSide;
	value: T;
};
