import React from "react";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { useDetails } from "../../../../data/listing/commissions/hooks/detailes/useDetails";
import { CommissionDetail } from "../../../../data/listing/commissions/types";
import {
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	TableBody,
	Typography,
	Grid,
} from "@material-ui/core";

export interface DisplayCommissionDetaileProps {
	commissionId: string;
}

export const DisplayCommissionDetailes: React.FunctionComponent<DisplayCommissionDetaileProps> = ({ commissionId }) => {
	const style = useDisplaySingleEmementStyles();
	const [detailes] = useDetails(commissionId);

	return (
		<div className={style.root}>
			<div className={style.row}>
				{detailes ? <DisplayCommissionDetaileTable detailes={detailes} /> : "loading placeholder"}
			</div>
		</div>
	);
};

interface DisplayCommissionDetaileTableProps {
	detailes: CommissionDetail[];
}

const DisplayCommissionDetaileTable: React.FunctionComponent<DisplayCommissionDetaileTableProps> = ({ detailes }) => {
	const { getString, getDateString } = React.useContext(LocalizationContext);

	if (detailes.length === 0) {
		return (
			<Grid container justify="center">
				<Typography>{getString("productAttributeEmpty")}</Typography>
			</Grid>
		);
	}

	return (
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>{getString("commDetailsDate")}</TableCell>
						<TableCell>{getString("commDetailsProductGroup")}</TableCell>
						<TableCell>{getString("commDetailsProduct")}</TableCell>
						<TableCell>{getString("commDetailsCurrency")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{detailes.map(attribute => (
						<TableRow key={attribute.commDetId}>
							<TableCell>{getDateString(attribute.fromDate, "DD.MM.YYYY. HH:mm")}</TableCell>
							<TableCell>{attribute.prodGroupName}</TableCell>
							<TableCell>{attribute.prodName}</TableCell>
							<TableCell>{attribute.currCode}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
