import React from "react";
import { Table, TableDataProps } from "../../components/common/Table";
import { useEmployeeData } from "../../../data/listing/employees/hooks/useEmployeeData";
import { Employee } from "../../../data/listing/employees/types";
import { useEmployeeColumns } from "./useEmployeeColumns";
import { AccessRights } from "../../../core/navigation/types";
import { useEmployeeActions, useDeleteEmployeeAction } from "./useEmployeeActions";

export interface CompaniesTableProps {
	userAccessRights: AccessRights;
}

type EmployeesTablePropsType = CompaniesTableProps & TableDataProps<Employee>;

export const EmployeesTable: React.FunctionComponent<EmployeesTablePropsType> = ({
	userAccessRights,
	...tableProps
}) => {
	const [data, loadAllEmployees] = useEmployeeData();

	const columns = useEmployeeColumns();
	const actions = useEmployeeActions(userAccessRights);
	const onDeleteEmployee = useDeleteEmployeeAction();

	return (
		<Table<Employee>
			{...tableProps}
			getData={loadAllEmployees}
			immutableData={data}
			title=""
			columns={columns}
			actions={actions}
			options={{
				toolbar: false,
				pageSize: 10,
				actionsColumnIndex: -1,
			}}
			editable={userAccessRights !== AccessRights.ReadOnly ? {
				onRowDelete:  onDeleteEmployee,
			  }: {}}
		/>
	);
};

export default EmployeesTable;
