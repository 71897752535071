import { ThunkType } from "../../../../core/store/reducers";
import deleteEmployeeApi from "../api/deleteEmployee";
import { setFetchStateEmployee } from "../employeesDataReducer";

export const deleteEmployee = (empId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchStateEmployee({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = await dispatch(deleteEmployeeApi(userId, empId));

	dispatch(setFetchStateEmployee(result !== undefined ? { type: "successful" } : { type: "error"}));
};
