import React from "react";
import { usePushNotificationsColumns } from "./usePushNotificationsReportColumns";
import { AccessRights } from "../../../core/navigation/types";
import { PushNotification } from "../../../data/reports/notifications/types";
import { ControledTable } from "../../components/common/ControledTable";
import { usePushNotificationsListData } from "../../../data/reports/notifications/hooks/usePushnotificationsListData";
import { usePushnotificationListIndex } from "../../../data/reports/notifications/hooks/usePushnotificationsListIndex";

export interface PushNotificationsTableProps {
  userAccessRights: AccessRights;
}

type PushNotificationsTablePropsType = PushNotificationsTableProps

const PushNotificationsReportTable: React.FunctionComponent<PushNotificationsTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const columns = usePushNotificationsColumns();
  const [data, load] = usePushNotificationsListData();
  const [index, setIndex] = usePushnotificationListIndex()

  return (
    <ControledTable<PushNotification>
      {...tableProps}
      getData={load}
      pageIndex={index}
      onPageIndexChange={setIndex}
      immutableData={data}
      title=""
      columns={columns}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
      }}
    />
  );
};

export default PushNotificationsReportTable;
