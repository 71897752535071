import QuarterlyTradesReport from "./QuarterlyTradesReport";
import { NavigationItem } from "../../../../core/navigation/types";
import { quarterlyTradesReportLocation, } from "./Locations";

export const quarterlySecurityReportComponents: NavigationItem[] = [
	{
		Component: QuarterlyTradesReport,
		isMenuItem: true,
		path: quarterlyTradesReportLocation,
	},
];
