import { ThunkType } from "../../../../core/store/reducers";
import getAllProductsAPI from "../api/getAllAttributes";
import { setData } from "../attributeDataReducer";

export const loadAllAttributes = (): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setData());

	const { userId } = store.data.auth.current.user;
	const attributes = await dispatch(getAllProductsAPI(userId));

	dispatch(setData(attributes || []));
};
