import React from "react";
import { useDisplayInvoice } from "./useDisplayInvoice";
import { InvoiceData } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";
import { Status } from "../../../models";

const now = new Date();
export const defaultInvoice: InvoiceData = {
  invId: "",
  invNo: "",
  clientId: "",
  currId: "00000001",
};

export const useEditInvoice = (invId?: string): InvoiceData | undefined => {
  const displayInvoice = useDisplayInvoice(invId);

  return React.useMemo(() => {
    if (invId !== undefined && displayInvoice === undefined) {
      return undefined;
    }

    if (invId === undefined) {
      return { ...defaultInvoice };
    }

    return displayInvoice ? { ...displayInvoice } : undefined;
  }, [invId, displayInvoice]);
};

export const useEditInvoiceState = (invId?: string) => {
  const invoice = useEditInvoice(invId);
  return useCopiedState(invoice);
};
