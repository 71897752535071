import React from "react";
import { ClientContractList } from "../../../../data/listing/clients/types";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";
import { DisplayBoolNumber } from "../../../components/common/DisplayBoolNumber";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { useClientContracts } from "../../../../data/listing/clients/hooks/clientContract/useClientContracts";
import {
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	TableBody,
	Typography,
	Grid,
} from "@material-ui/core";
import { Status } from "../../../../data/models";

export interface DisplayClientBrokersProps {
	clientId: string;
}

export const DisplayClientContracts: React.FunctionComponent<DisplayClientBrokersProps> = ({ clientId }) => {
	const style = useDisplaySingleEmementStyles();
	const [contracts] = useClientContracts(clientId);

	return (
		<div className={style.root}>
			<div className={style.row}>
				{contracts ? <ClientContractsDisplayTable contracts={contracts} /> : "loading placeholder"}
			</div>
		</div>
	);
};

interface ClientContractsDisplayTableProps {
	contracts: ClientContractList[];
}

const ClientContractsDisplayTable: React.FunctionComponent<ClientContractsDisplayTableProps> = ({ contracts }) => {
	const style = useDisplaySingleEmementStyles();
	const { getString, getDateString } = React.useContext(LocalizationContext);

	if (contracts.length === 0) {
		return (
			<Grid container justify="center">
				<Typography>{getString("contractEmpty")}</Typography>
			</Grid>
		);
	}

	return (
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>{getString("contractNumber")}</TableCell>
						<TableCell>{getString("contractDate")}</TableCell>
						<TableCell>{getString("contractStatus")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{contracts.map(contract => (
						<TableRow key={contract.cliContrId}>
							<TableCell>{contract.contrNo}</TableCell>
							<TableCell>{getDateString(contract.contrDate)}</TableCell>
							<TableCell>
								<DisplayBoolNumber
									value={contract.status}
									thrutyValue={Status.Active}
									className={style.icon}
								></DisplayBoolNumber>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
