import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Warehouse } from "../types";

export const updateWhsApi = (userId: string, whs: Warehouse) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "listing/updwhs",
    params: {
      userId,
    },
    data: whs,
  });

export default updateWhsApi;
