import { ThunkType } from "../store/reducers";

export interface ErrorData {
	message: string;
	code: string;
}

export enum ErrorCodes {
	AuthWrongToken = "ERROR-4",
	HTTPLoginError = "http-login-error",
	OrderNoFunds = "NOFUNDS",
	UniqueConstraint = "E-91",
	OrderNoFile = "E--1",
}

export type APIResponse<T> =
	| SucessAPIResponse<T>
	| ApiErrorResponse;

export type SucessAPIResponse<T> = {
	resCode: "OK";
	resDesc: string;
	obj: T;
};

export type ApiErrorResponse = {
	resCode: ErrorCodes;
	resDesc: string;
};

export type HTTPWebApiResponse<T> = WebApiResponse<APIResponse<T>>;

export type WebApiResponse<T> = ThunkType<Promise<T | undefined>>;

export type WebApiWholeResponse<T> = ThunkType<Promise<APIResponse<T> | undefined>>;
