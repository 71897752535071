import React from "react";
import { CardContent, CardHeader, Card, Divider, Typography, Grid } from "@material-ui/core";
import { useGraphDataH } from "../../../../data/trading/ticker/hooks/useGraphDataH";
import { useGraphPeriodH } from "../../../../data/trading/ticker/hooks/useGraphPeriodH";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ChartPeriod } from "../../../../data/trading/ticker/types";
import { CartesianGrid, Line, LineChart, XAxis, Tooltip, YAxis, ResponsiveContainer } from "recharts";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { ProductSelect } from "../../../components/listing/ProductSelect";
import { useGraphProduct } from "../../../../data/trading/ticker/hooks/useGraphProduct";

export interface ProductGraphProps {
	graphId: string;
}

export const ProductGraph: React.FunctionComponent<ProductGraphProps> = ({ graphId }) => {
	const { getString, getDateString, getDateTimeString, getFormatedNumberString } = React.useContext(
		LocalizationContext
	);

	const [prodId, setProdId] = useGraphProduct(graphId);
	const [data] = useGraphDataH(graphId);
	const [period, setPeriod] = useGraphPeriodH(graphId);
	
console.log(period)

	return (
		<Card>
			<CardHeader
				title={
					<Grid container spacing={2} alignItems="baseline" alignContent="space-between" justify="space-between">
						<Grid item xs={5}>
{/*							<Typography>{ prodId && prodId==='00000001' ? getString("historyProd1"): prodId==='00000002' ? getString("historyProd2") : getString("historyProd3") }</Typography> */}
							<Typography>{ graphId && graphId==='0001' ? getString("historyProd1"): graphId==='0002' ? getString("historyProd2") : getString("historyProd3") }</Typography>

						</Grid>
						<Grid item md={7} xl={6}>
							<ToggleButtonGroup
								value={period}
								exclusive
								onChange={(e, v) => setPeriod(v)}
								size="small"
							>
								<ToggleButton size="small" value={ChartPeriod.Month}>1M</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.ThreeMonts}>3M</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.HalfYear}>6M</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.FromYearBeggining}>YTD</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.Year}>1Y</ToggleButton>
								<ToggleButton size="small" value={ChartPeriod.All}>All</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
					</Grid>
				}
			>
				</CardHeader>
			<Divider />
			<CardContent>
				<ResponsiveContainer width="100%" height={400}>
					<LineChart data={data} margin={{ top: 5, right: 20, left: 0, bottom: 5 }}>
						<XAxis dataKey="tradeTime" tickFormatter={date => getDateString(date, "DD.MM.")} />
						<YAxis yAxisId={0} domain={["auto", "auto"]} />
						<Tooltip
							formatter={value => [
								getFormatedNumberString(value as any, true),
								getString("orderPrice"),
							]}
//							labelFormatter={(date: any) => getDateTimeString(date)}
							labelFormatter={(date: any) => getDateString(date)}
						/>
						<CartesianGrid stroke="#f5f5f5" />
						<Line type="monotone" dataKey="price" stroke="#772E25" yAxisId={0} dot={false} />
					</LineChart>
				</ResponsiveContainer>
			</CardContent>
		</Card>
	);
};
