import React from "react";
import { useDisplayMarginsData } from "../../../data/clearing/marginsData/hooks/useDisplayMarginsData";
import { DisplayDrawerContext } from "../../../layout/DisplayDrawerContext";
import { MarginsData } from "../../../data/clearing/marginsData/types";
import { LocalizationContext } from "../../../core/localization/Localization";
import { DisplayMarginsDataMainAttributes } from "./DisplayMarginsDataMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,IconButton,
} from "@material-ui/core";
import { CustomTooltip } from "../../components/common/CustomTooltip";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { useMarginsPrinter } from "../../../data/clearing/marginsData/hooks/useMarginsPrinter";

export interface DisplayMarginsDataProps {
  id?: string;
}

export const DisplayMarginsData: React.FunctionComponent<DisplayMarginsDataProps> = ({
  id,
}) => {
  const marginsData = useDisplayMarginsData(id);
  const { getString } = React.useContext(LocalizationContext);
	const [download, fetchState, setfetch] = useMarginsPrinter(marginsData);

  return !marginsData ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
        <Grid container justify="space-between">
            <Grid item>
              <Typography>{getString("tableBasciInfo")}</Typography>
            </Grid>
							<Grid item>
								<CustomTooltip titleKeyword="exportPDFTooltip">
									<IconButton
										color="secondary"
										component="span"
										onClick={download}
										disabled={fetchState.type !== "not-started"}
									>
										<DownloadIcon />
									</IconButton>
								</CustomTooltip>
                { fetchState.type !== "not-started"  ? getString("pleaseWait") : null }
							</Grid>
          </Grid>
        </ExpansionPanelSummary>
        <DisplayMarginsDataMainAttributes marginsData={marginsData} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewMarginsData = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (marginsData: MarginsData) =>
      showWithDrawer(<DisplayMarginsData id={marginsData.mrgId} />),
    [showWithDrawer]
  );
};
