import { ThunkType } from "../../../../core/store/reducers";
import { setNews, invalidateNews } from "../newsDataReducer";
import { setIndex } from "../newsUXSlice";
import getAllNewsApi from "../api/getAllNews";
import { PageIndex, PageDataStatus } from "../../../models";

export const loadAllNews = (): ThunkType => async (dispatch, getStore) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return;
  }

  const { pageNumber, itemsPerPage } = store.ux.reports.news.index;
  const { filter } = store.ux.reports.news;
  const user = store.data.auth.current.user;

  const newsResponse = await dispatch(
    getAllNewsApi(user, pageNumber, itemsPerPage, filter)
  );

  if (!newsResponse || newsResponse.resCode !== "OK") {
    return;
  }

  const news = {
    currentPageData: newsResponse.obj,
    totalCount: Number(newsResponse.resDesc) || 0,
    status: PageDataStatus.DataCurrent,
  };

  dispatch(setNews(news));
};

export const setNewsIndexThunk = (index: PageIndex): ThunkType => (
  dispatch
) => {
  dispatch(setIndex(index));
  dispatch(invalidateNews());
};
