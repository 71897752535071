import React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { ClientType } from "../../models";

const getTypeLabel = (type?: ClientType) => (type ? type.ftDescr : "");

export const useClientTypes = () => {
	const cities = useSelector<StoreType, ClientType[]>(store => store.data.initialData.clientTypes);

	return [cities, getTypeLabel] as [typeof cities, typeof getTypeLabel];
};

export const useClientType = (typeId?: number) => {
	const [cities] = useClientTypes();

	const type = React.useMemo(() => cities.find(c => c.ftValue === typeId), [cities, typeId]);
	const typeLabel = React.useMemo(() => getTypeLabel(type), [type]);

	return [type, typeLabel] as [typeof type, typeof typeLabel];
};
