import React from "react";
import { ClientContract } from "../../../../data/listing/clients/types";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { useDisplaySingleEmementStyles } from "../../../useDisplaySingleEmementStyles";

export interface DisplayContractMainAttributesProps {
  contract: ClientContract;
}

export const DisplayContractMainAttributes: React.FunctionComponent<DisplayContractMainAttributesProps> = ({
  contract,
}) => {
  const style = useDisplaySingleEmementStyles();
  const { getString, getDateString } = React.useContext(LocalizationContext);

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("contractNumber")}</span>
        <span className={style.value}>{contract.contrNo}</span>
      </div>
			<div className={`${style.row} ${style.rowBorder}`}>
				<span className={style.label}>{getString("contractDate")}</span>
				<span className={style.value}>{getDateString(contract.contrDate)}</span>
			</div>
      <div className={`${style.row} ${style.rowBorder}`}>
        <span className={style.label}>{getString("contractDescription")}</span>
        <span className={style.value}>{contract.contrDescr}</span>
      </div>
    </div>
  );
};
