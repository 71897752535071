import { combineReducers } from "@reduxjs/toolkit";
import monthlyTrade from "./monthlyTrade/monthlyTradeUXSlice";
import dailySecurity from "./securityDaily/dailySecurityUXSlice";
import annualSecurity from "./securityAnnual/annualSecurityUXSlice";
import quarterlylSecurity from "./securityQuarterly/quarterlySecurityUXSlice";

const securityCommissionReportUXReduer = combineReducers({
	monthlyTrade,
	dailySecurity,
	annualSecurity,
	quarterlylSecurity,
});

export default securityCommissionReportUXReduer;
