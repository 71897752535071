import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const useLoginStyles = makeStyles((theme: Theme) => {
	
	return createStyles({
		loginDrawerPaper: {
			width: "100%",
			[theme.breakpoints.only("xs")]: {
				width: "100vw",
				height: "100vh",
			},
			[theme.breakpoints.only("sm")]: {
				width: "60vw",
			},
			[theme.breakpoints.up("sm")]: {
				width: "50vw",
			},
			[theme.breakpoints.up("lg")]: {
				width: "30vw",
			},
		},

		wraper: {
			backgroundImage: `url(./login-register.jpg)`,
			height: "100vh",
			overflow: "hidden",
		},
		formWrpaer: {
			height: "100%",
		},
		formItem: {
			"&:not(:first-child)": {
				marginTop: theme.spacing(2),
			},
			width: "60%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		textField: {
			width: "100%",
		},
		formContainer: {
			height: "100%",
			padding: "20px",
		},
		localeSelection: {
			width: "60%",
			display: "flex",
			justifyContent: "flex-end",
			alignItems: "center",
		},
		alert: {
			position: "absolute",
			top: theme.spacing(2),
			width: "100%",
		},
		footer: {
			position: "absolute",
			bottom: theme.spacing(2),
			width: "100%",
	   },
	});
});
