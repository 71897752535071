import { ThunkType } from "../../../../../core/store/reducers";
import getAllAttributes from "../../api/attributeValues/getAllAttributeValues";
import { AttributeValue } from "../../types";

export const loadAttributeValues = (attributeId: string): ThunkType<Promise<AttributeValue[] | undefined>> => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return undefined;
	}

	const { userId } = store.data.auth.current.user;
	return await dispatch(getAllAttributes(userId, attributeId));
}
