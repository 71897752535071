import React from "react";
import { useClientData } from "../../../data/listing/clients/hooks/useClientData";
import { ClientListData } from "../../../data/listing/clients/types";
import { AutocompleteSelectType, AutocompleteSelect } from "../common/AutocompleteSelect";

export type ClientSelectPropsType = Omit<
	AutocompleteSelectType<ClientListData>,
	"data" | "getLabel"
>

export const ClientSelect: React.FunctionComponent<ClientSelectPropsType> = props => {
	const [, loadAllClients, clients] = useClientData();

	React.useEffect(() => {
		if (!clients) {
			loadAllClients();
		}
	}, [clients, loadAllClients]);

	return (
		<AutocompleteSelect<ClientListData>
			data={clients?.filter(cli => cli.status!==0)}
			getLabel={renderEmployeeLabel}
			{...props}
		/>
	);
};

const renderEmployeeLabel = (client: ClientListData | null) =>
	client ? `${client.cliName} ${renderTaxCode(client.taxCode)}` : "";

const renderTaxCode = (taxCode?: string) => (taxCode ? `(${taxCode})` : "");
