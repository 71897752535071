import { ThunkType } from "../../../../core/store/reducers";
import { getAlarmApi } from "../api/getAlarm";
import { setDisplayed } from "../alarmDataSlice";
import { AlarmData } from "../types";

export const loadAlarm = (alarmId: string): ThunkType => async (
  dispatch,
  getStore
) => {
  dispatch(setDisplayed());

  const alarm = await dispatch(getAlarm(alarmId));

  dispatch(setDisplayed(alarm));
};

export const getAlarm = (
  alarmId: string
): ThunkType<Promise<AlarmData | undefined>> => async (
  dispatch,
  getStore
) => {
  const store = getStore();

  if (store.data.auth.current.status !== "successful") {
    return undefined;
  }

  const { userId } = store.data.auth.current.user;
  return await dispatch(getAlarmApi(userId, alarmId));
};
