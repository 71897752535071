import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageData, PageDataStatus, FetchStateType } from "../../models";
import { ViewOrder, Order, OrderSideDictionary, OrderSideArgs } from "../orders/types";
import { OrderSide } from "../../models";

type OrderPagedData = PageData<ViewOrder> | undefined;

type OrdersData = {
	currentPage: OrderSideDictionary<OrderPagedData>;
	displayedMBOrder?: Order;
	newOrderFetchState: FetchStateType;
	attributeFetchState: FetchStateType;
	cancelOrderFetchState: FetchStateType;
	confirmOrderFetchState: FetchStateType;
	deleteOrderFetchState: FetchStateType;
};

const initialState: OrdersData = {
	currentPage: {
		[OrderSide.Buy]: undefined,
		[OrderSide.Sell]: undefined,
	},
	newOrderFetchState: { type: "not-started" },
	attributeFetchState: { type: "not-started" },
	cancelOrderFetchState: { type: "not-started" },
	confirmOrderFetchState: { type: "not-started" },
	deleteOrderFetchState: { type: "not-started" },
};

const name = "mainBoard";

const ordersSlice = createSlice({
	name,
	initialState,
	reducers: {
		setOrders: (state, action: PayloadAction<OrderSideArgs<PageData<ViewOrder>>>) => ({
			...state,
			currentPage: {
				...state.currentPage,
				[action.payload.side]: action.payload.value,
			},
		}),
		setDisplayed: (state, action: PayloadAction<Order | undefined>) => ({
			...state,
			displayedMBOrder: action.payload,
		}),
		invalidateOrders: state => {
			const buyInvalidated = !state.currentPage[OrderSide.Buy]
				? undefined
				: ({
						...state.currentPage[OrderSide.Buy],
						status: PageDataStatus.DataInvalidated,
				  } as OrderPagedData);

			const sellInvalidated = !state.currentPage[OrderSide.Sell]
				? undefined
				: ({
						...state.currentPage[OrderSide.Sell],
						status: PageDataStatus.DataInvalidated,
				  } as OrderPagedData);

			return {
				...state,
				currentPage: {
					[OrderSide.Buy]: buyInvalidated,
					[OrderSide.Sell]: sellInvalidated,
				},
			};
		},
		invalidateSideOrders: (state, action: PayloadAction<OrderSide>) => {
			const side = action.payload;
			const invalidated = !state.currentPage[side]
				? undefined
				: ({
						...state.currentPage[side],
						status: PageDataStatus.DataInvalidated,
				  } as OrderPagedData);

			return {
				...state,
				currentPage: {
					...state.currentPage,
					[side]: invalidated,
				},
			};
		},
		setNewOrderFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			newOrderFetchState: action.payload,
			displayedMBOrder: action.payload.type === "successful" ? undefined : state.displayedMBOrder,
		}),
		setAttributeFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			attributeFetchState: action.payload,
		}),
		setCancelOrderFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			cancelOrderFetchState: action.payload,
		}),
		setConfirmOrderFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			confirmOrderFetchState: action.payload,
		}),
		setDeleteOrderFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			deleteOrderFetchState: action.payload,
		}),
	},
});

export const {
	setOrders,
	setDisplayed,
	invalidateOrders,
	setNewOrderFetchState,
	setAttributeFetchState,
  setCancelOrderFetchState,
  setConfirmOrderFetchState,
  setDeleteOrderFetchState,
  invalidateSideOrders
} = ordersSlice.actions;

export default ordersSlice.reducer;
