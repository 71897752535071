import React from "react";
import { Grid } from "@material-ui/core";
import { useCurrentSecurityReportFilter } from "../../../../data/reports/securityCommission/securityDaily/hooks/useSecurityReportFilter";
import { DateInput } from "../../../components/common/DateInput";
import { isValidDate } from "../../../../core/util/functions";
import { LocalizationContext } from "../../../../core/localization/Localization";

export const DailySecurityReportFilter: React.FunctionComponent = () => {
	const [filter, setReportDate] = useCurrentSecurityReportFilter();
	const { getString } = React.useContext(LocalizationContext);

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item>
				<DateInput
					clearable={false}
					label={getString("dailyTradeReportReportDate")}
					value={filter?.reportDate || null}
					onChange={date => {
						if (isValidDate(date)) {
							setReportDate(date.toISOString());
						}
					}}
				/>
			</Grid>
		</Grid>
	);
};
