import { useCopiedState } from "../../../../core/util/hooks";
import { setFilter, removeFilter, initialState } from "../guaranteesUXSlice";
import { invalidateGuarantee } from "../guaranteesDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { GuaranteeFilter } from "../types";


export const useGuaranteeFilter = () => {
	const [storeFilter] = useCurrentGuaranteeFilter();
	const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<GuaranteeFilter>(storeFilter);

	const dispatch = useDispatch();
	const dispatchSetFilter = React.useCallback(() => {
		dispatch(setFilter(filter!));
		dispatch(invalidateGuarantee());
	}, [dispatch, filter]);

	const dispatchResetFilter = React.useCallback(() => {
		setCurrentFilter(initialState.filter);
	}, [setCurrentFilter]);

	return [filter, editFilter, dispatchSetFilter, changeState, dispatchResetFilter] as [
		typeof filter,
		typeof editFilter,
		typeof dispatchSetFilter,
		typeof changeState,
		typeof dispatchResetFilter
	];
};

export const useCurrentGuaranteeFilter = () => {
	const filter = useSelector<StoreType, GuaranteeFilter>(
	  (store) => store.ux.clearing.guarantees.filter
	);
	const dispatch = useDispatch();
	const dispatchRemoveFilter = React.useCallback(
	  (type: keyof GuaranteeFilter) => {
		dispatch(removeFilter(type));
		dispatch(invalidateGuarantee());
	  },
	  [dispatch]
	);
  
	return [filter, dispatchRemoveFilter] as [
	  typeof filter,
	  typeof dispatchRemoveFilter
	];
  };
  