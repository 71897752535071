import React from "react";
import { useDisplayContract } from "../../../../data/listing/clients/hooks/clientContract/useDisplayContract";
import { useClientContract } from "../../../../data/listing/clients/hooks/clientContract/useClientContract";
import { DisplayDrawerContext } from "../../../../layout/DisplayDrawerContext";
import { ClientContract, ClientContractList } from "../../../../data/listing/clients/types";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { DisplayContractMainAttributes } from "./DisplayContractMainAttributes";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Grid,IconButton,
} from "@material-ui/core";
import { CustomTooltip } from "../../../components/common/CustomTooltip";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { useContractPrinter } from "../../../../data/listing/clients/hooks/clientContract/useContractPrinter";

export interface DisplayContractProps {
  id?: string;
}

export const DisplayContract: React.FunctionComponent<DisplayContractProps> = ({
  id,
}) => {

  const contract = useDisplayContract(id);
  const { getString } = React.useContext(LocalizationContext);
	const [download, fetchState, setfetch] = useContractPrinter(contract);

  return !contract ? (
    <>{"loading placeholder"}</>
  ) : (
    <Grid container justify="center" direction="column">
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
        <Grid container justify="space-between">
            <Grid item>
              <Typography>{getString("tableBasciInfo")}</Typography>
            </Grid>
							<Grid item>
								<CustomTooltip titleKeyword="exportPDFTooltip">
									<IconButton
										color="secondary"
										component="span"
										onClick={download}
										disabled={fetchState.type !== "not-started"}
									>
										<DownloadIcon />
									</IconButton>
								</CustomTooltip>
                { fetchState.type !== "not-started"  ? getString("pleaseWait") : null }
							</Grid>
          </Grid>
        </ExpansionPanelSummary>
        <DisplayContractMainAttributes contract={contract} />
      </ExpansionPanel>
    </Grid>
  );
};

export const useViewContract = () => {
  const showWithDrawer = React.useContext(DisplayDrawerContext);
  return React.useCallback(
    (contract: ClientContractList) => showWithDrawer(<DisplayContract id={contract.cliContrId} />),
    [showWithDrawer]
  );
};
