import { combineReducers } from "@reduxjs/toolkit";
import tradeReview from "./tradeReview/tradeReviewUXSlice";
import tradeByProducts from "./tradeByProducts/tradeByProductsUXSlice";
import tradeByClients from "./tradeByClients/tradeByClientsUXSlice";
import tradeByBrokers from "./tradeByBrokers/tradeByBrokersUXSlice";
import weightedPrice from "./weightedPrices/weightedPriceUXSlice";
import businessActivities from "./businessActivities/businessActivitiesUXSlice";
import commReview from "./commReview/commReviewUXSlice";
import memberContracts from "./memberContracts/memberContractsUXSlice";
import clientOverview from "./clientOverview/clientOverviewUXSlice";


const exchBusinessUXReducer = combineReducers({
  tradeReview,
  tradeByProducts,
  tradeByClients,
  tradeByBrokers,
  weightedPrice,
  businessActivities,
  commReview,
  memberContracts,
  clientOverview,
});

export default exchBusinessUXReducer;
