import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TradeFilter } from "../../../trading/trades/types";
import { DailySecurityReportFilter } from "./types";
import { PageIndex } from "../../../models";

type TradesReportState = {
  filter: DailySecurityReportFilter;
  index: PageIndex;
};

export const initialState: TradesReportState = {
  filter: {
    reportDate: new Date().toISOString(),
  },
  index: {
    itemsPerPage: 20,
    pageNumber: 0,
  },
};

const name = "dailySecurity";

const dailySecuritySlice = createSlice({
  name,
  initialState,
  reducers: {
    setIndex: (state, action: PayloadAction<PageIndex>) => ({
      ...state,
      index: action.payload,
    }),
    setReportData: (state, action: PayloadAction<string>) => ({
      ...state,
      filter: {
        reportDate: action.payload,
      },
    }),
    removeFilter: (state, action: PayloadAction<keyof TradeFilter>) => ({
      ...state,
      filter: {
        ...state.filter,
        [action.payload]: undefined,
      },
    }),
  },
});

export const {
  setReportData,
  removeFilter,
  setIndex,
} = dailySecuritySlice.actions;

export default dailySecuritySlice.reducer;
