import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { DeliveryData } from "../types";
import { loadDelivery } from "../thunks/loadDelivery";

export const useDisplayDelivery = (dlvrId?: string) => {
  const storeDeliveries = useSelector<StoreType, DeliveryData | undefined>(
    (store) => store.data.clearing.deliveries.displayedDelivery
  );

  const displayedDelivery =
    storeDeliveries && storeDeliveries.deliveryId === dlvrId
      ? storeDeliveries
      : undefined;

  const displatch = useDispatch();

  React.useEffect(() => {
    if (dlvrId) {
      displatch(loadDelivery(dlvrId));
    }
  }, [displatch, dlvrId]);

  return displayedDelivery;
};
