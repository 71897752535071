import React from "react";
import { useMonthlyTradesColumns } from "./useMonthlyTradingReportColumns";
import { AccessRights } from "../../../core/navigation/types";
import { ControledTable } from "../../components/common/ControledTable";
import { MonthlyTradingReportData } from "../../../data/reports/monthlyTrade/types";
import { useMonthlyTradesReportIndex } from "../../../data/reports/monthlyTrade/hooks/useMonthlyTradesReportIndex";
import { useTradesReportData } from "../../../data/reports/monthlyTrade/hooks/useTradesReportData";
import { useMonthlyActions } from "./useMonthlyActions";

export interface MonthlyTradesTableProps {
  userAccessRights: AccessRights;
}

type MonthlyTradesTablePropsType = MonthlyTradesTableProps;

const MonthlyTradesReportTable: React.FunctionComponent<MonthlyTradesTablePropsType> = ({
  userAccessRights,
  ...tableProps
}) => {
  const actions = useMonthlyActions();
  const columns = useMonthlyTradesColumns();

  const [data, load] = useTradesReportData();
  const [index, setIndex] = useMonthlyTradesReportIndex();

  return (
    <ControledTable<MonthlyTradingReportData>
      {...tableProps}
      getData={load}
      onPageIndexChange={setIndex}
      pageIndex={index}
      immutableData={data}
      title=""
      columns={columns}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        toolbar: false,
        pageSize: 20,
      }}
    />
  );
};

export default MonthlyTradesReportTable;
