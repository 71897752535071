import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { deleteEmployee } from "../thunks/deleteEmployee";
import { setFetchStateEmployee } from "../employeesDataReducer";

export const useEmployeeDelete = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.employees.fetchState
	);

	const dispatch = useDispatch();
	const dispatchDeleteEmployee = React.useCallback(
		(id: string) => dispatch(deleteEmployee(id)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateEmployee({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchDeleteEmployee] as [typeof fetchState, typeof dispatchDeleteEmployee]
};
