import { useDispatch, useSelector } from "react-redux";
import { printBankGuaranteesThunk } from "../thunks/printBankGuaranteesThunk";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { BankGuaranteeData } from "../types";
import { setDownloadFetchState } from "../bankguaranteesDataSlice";

export const useBankGuaranteesPrinter = (bankguarantee?: BankGuaranteeData) => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.clearing.bankguarantees.downloadReportFetchState
	);
	const dispatch = useDispatch();

	const download = React.useCallback(() => {
			dispatch(printBankGuaranteesThunk(bankguarantee));
	}, [dispatch,bankguarantee]);

	const dispatchSetFetch = React.useCallback(() => {                   //  Ovo da prvo izađe customDialog box
		dispatch(setDownloadFetchState({ type: "started" }))
	},	[dispatch] );
	
	return [download, fetchState, dispatchSetFetch] as [typeof download, typeof fetchState, typeof dispatchSetFetch];
};
