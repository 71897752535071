import React from "react";
import { Grid, CardContent, CardHeader, Card, Divider } from "@material-ui/core";
import OrdersTable from "./OrdersTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { useOrdersReportData } from "../../../data/reports/ordersReport/hooks/useOrdersReportData";
import { useOrdersReportIndex } from "../../../data/reports/ordersReport/hooks/useOrdersReportIndex";
import { PageDataStatus } from "../../../data/models";
import { OrdersReportFilterFilterButton } from "./OrdersReportFilter";
import { DownloadButton, DownloadExcelButton } from "./DownloadButton";

const OrdersReport: PageComponent = ({ userAccessRights }) => {
	const pageStyles = usePageStyles();

	const [pageData, dispatchLoadData] = useOrdersReportData();
	const [pageIndex, dispatchSetIndex] = useOrdersReportIndex();

	React.useEffect(() => {
		if (pageData && pageData.status === PageDataStatus.DataInvalidated) {
			dispatchLoadData();
		}
	}, [dispatchLoadData, pageData]);

	return (
		<>
			<Grid container className={pageStyles.root}>
				<Grid item xs={12} className={pageStyles.gridItem}>
					<Card>
						<CardHeader
							title={<PageTitle title="navOrdersReport" />}
							action={<OrderReportActions />}
						/>
						<Divider />
						<CardContent>
							<OrdersTable
								pageIndex={pageIndex}
								pageData={pageData}
								onPageIndexChange={dispatchSetIndex}
								userAccessRights={userAccessRights}
								getData={dispatchLoadData}
							/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

export const OrderReportActions: React.FunctionComponent = () => (
	<Grid container>
		<Grid item>
			<OrdersReportFilterFilterButton />
		</Grid>
		<Grid item>
			<DownloadButton />
		</Grid>
		<Grid item>
			<DownloadExcelButton />
		</Grid>
	</Grid>
)

export default OrdersReport;
