import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TradePricesChartData, ChartPeriod } from "./types";

type TickerData = {
	graphData: {
		[k: string]: TradePricesChartData[] | undefined;
	};
	graphPeriod: {
		[k: string]: ChartPeriod;
	};
	graphPeriodH: {
		[k: string]: ChartPeriod;
	};
//	graphProductId: {
//		[k: string]: string | undefined;
//	};
	ticketData?: TradePricesChartData[];
};

export type TickerArg<T> = {
	graphId: string;
	data: T;
};

const initialState: TickerData = {
	graphData: {},
	graphPeriod: {},
	graphPeriodH: {},
//	graphProductId: {"0001":"00000001", "0002":"00000002", "0003":"00000003"},
};

const name = "ticker";

const tickerSlice = createSlice({
	name,
	initialState,
	reducers: {
		setGraphData: (state, action: PayloadAction<TickerArg<TradePricesChartData[] | undefined>>) => ({
			...state,
			graphData: {
				...state.graphData,
				[action.payload.graphId]: action.payload.data,
			},
		}),
		setGraphPeriod: (state, action: PayloadAction<TickerArg<ChartPeriod>>) => ({
			...state,
			graphPeriod: {
				...state.graphPeriod,
				[action.payload.graphId]: action.payload.data,
			},
		}),
		setGraphPeriodH: (state, action: PayloadAction<TickerArg<ChartPeriod>>) => ({
			...state,
			graphPeriodH: {
				...state.graphPeriodH,
				[action.payload.graphId]: action.payload.data,
			},
		}),

		//		setGraphProductId: (state, action: PayloadAction<TickerArg<string | undefined>>) => ({
//			...state,
//			graphProductId: {
//				...state.graphProductId,
//				[action.payload.graphId]: action.payload.data,
//			},
//		}),
		setTicketData: (state, action: PayloadAction<TradePricesChartData[] | undefined>) => ({
			...state,
			ticketData: action.payload,
		}),
	},
});

//export const { setGraphData, setGraphPeriod, setTicketData, setGraphProductId } = tickerSlice.actions;
export const { setGraphData, setGraphPeriod, setTicketData, setGraphPeriodH } = tickerSlice.actions;

export default tickerSlice.reducer;
