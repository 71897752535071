import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../../core/store/reducers";
import { FetchStateType } from "../../../../models";
import { upsertClientPerson } from "../../thunks/clientPerson/upsertClientPerson";
import { setFetchStateClient } from "../../clientsDataReducer";
import { ClientPerson } from "../../types";

export const useClientPersonUpsert = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		store => store.data.listing.clients.fetchStatePerson
	);

	const dispatch = useDispatch();
	const dispatchUpsertPerson = React.useCallback(
		(client: ClientPerson) => dispatch(upsertClientPerson(client)),
		[dispatch]
	);

	React.useEffect(() => {
		dispatch(setFetchStateClient({ type: "not-started" }))
	}, [dispatch])

	return [fetchState, dispatchUpsertPerson] as [typeof fetchState, typeof dispatchUpsertPerson]
};
