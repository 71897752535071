import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useMenuItemStyles = (level: number) =>
	makeStyles((theme: Theme) => {
		return createStyles({
			menuItem: {
				whiteSpace: "nowrap",
				paddingLeft: theme.spacing(2 + level * 2),
			},
		});
	})();
