import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { TradingData } from "../types";

export const updateTradingDataApi = (userId: string, traddata: TradingData) =>
  webApiWithResponse<string>({
    method: "PUT",
    url: "clearing/updprodtradingdata",
    params: {
      userId,
    },
    data: traddata,
  });

export default updateTradingDataApi;
