import { CommReviewReportData } from "../../../../data/reports/exchbusiness/commReview/types";
import React from "react";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Column } from "material-table";

export const useCommReviewColumns = () => {
  const { getString, getFormatedNumberString } = React.useContext(
    LocalizationContext
  );

  return React.useMemo(
    () => [
      {
        title: getString("exchClient"),
        field: "client",
        sorting: false,
      },
      {
        title: getString("exchTransactionDate"),
        field: "transactionDate",
        sorting: false,
      },
      {
        title: getString("exchTransactionNumber"),
        field: "transactionNumber",
        sorting: false,
      },
      {
        title: getString("exchOrderNumber"),
        field: "orderNumber",
        sorting: false,
      },
      {
        title: getString("exchSide"),
        field: "side",
        sorting: false,
      },
      {
        title: getString("exchProduct"),
        field: "product",
        sorting: false,
      },
      {
        title: getString("exchPrice"),
        render: (row) => `${getFormatedNumberString(row.price, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchAmount"),
        render: (row) => `${getFormatedNumberString(row.amount)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
      {
        title: getString("exchCommission"),
        render: (row) => `${getFormatedNumberString(row.commission, true)}`,
        sorting: false,
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "end",
        },
        headerStyle: {
          textAlign: "end",
        },
      },
    ],
    [getString, getFormatedNumberString]
  ) as Column<CommReviewReportData>[];
};
