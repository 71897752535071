import { combineReducers, AnyAction } from "@reduxjs/toolkit";
import employees from "./employees/employeesDataReducer";
import clients from "./clients/clientsDataReducer";
import roles from "./roles/rolesDataReducer";
import users from "./users/usersDataReducer";
import products from "./products/productsDataReducer";
import productGroups from "./productGroups/productGroupDataReducer";
import banks from "./banks/banksDataReducer";
import warehouses from "./warehouses/warehousesDataReducer";
import attributes from "./attributes/attributeDataReducer";
import currencies from "./currencies/currenciesDataReducer";
import regions from "./regions/regionsDataReducer";
import currencyRates from "./curencyRates/currencyRatesDataReducer";
import countries from "./countries/countriesDataReducer";
import cities from "./cities/citiesDataReducer";
import commissions from "./commissions/commissionsDataReducer";
import { resetLoginState } from "../auth/authDataSlice";
import payTypes from "./payTypes/payTypesDataReducer";

const listingReducer = combineReducers({
	employees,
	clients,
	roles,
	users,
	products,
	productGroups,
	banks,
	warehouses,
	attributes,
	currencies,
	regions,
	currencyRates,
	countries,
	cities,
	commissions,
	payTypes,
});

export default (state: any, action: AnyAction) => {
	if (action.type === resetLoginState.type) {
		return listingReducer(undefined, action);
	} else {
		return listingReducer(state, action);
	}
};
