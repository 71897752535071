import { useCopiedState } from "../../../../core/util/hooks";
import { setFilter, removeFilter, initialState } from "../paymentsUXSlice";
import { invalidatePayment } from "../paymentsDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import React from "react";
import { PaymentFilter } from "../types";

export const usePaymentsFilter = () => {
  const [storeFilter] = useCurrentPaymentsFilter();
  const [filter, editFilter, changeState, setCurrentFilter] = useCopiedState<
    PaymentFilter
  >(storeFilter);

  const dispatch = useDispatch();
  const dispatchSetFilter = React.useCallback(() => {
    dispatch(setFilter(filter!));
    dispatch(invalidatePayment());
  }, [dispatch, filter]);

  const dispatchResetFilter = React.useCallback(() => {
    setCurrentFilter(initialState.filter);
  }, [setCurrentFilter]);

  return [
    filter,
    editFilter,
    dispatchSetFilter,
    changeState,
    dispatchResetFilter,
  ] as [
    typeof filter,
    typeof editFilter,
    typeof dispatchSetFilter,
    typeof changeState,
    typeof dispatchResetFilter
  ];
};

export const useCurrentPaymentsFilter = () => {
  const filter = useSelector<StoreType, PaymentFilter>(
    (store) => store.ux.clearing.payments.filter
  );
  const dispatch = useDispatch();
  const dispatchRemoveFilter = React.useCallback(
    (type: keyof PaymentFilter) => {
      dispatch(removeFilter(type));
      dispatch(invalidatePayment());
    },
    [dispatch]
  );

  return [filter, dispatchRemoveFilter] as [
    typeof filter,
    typeof dispatchRemoveFilter
  ];
};
