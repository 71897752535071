import { useSelector } from "react-redux";
import { StoreType } from "../../../core/store/reducers";
import { OrderNote } from "../../models";

const renderLabel = (item: OrderNote) => item.ftDescr;

export const useOrderNotes = () => {
	const orderNotes = useSelector<StoreType, OrderNote[]>(store => store.data.initialData.orderNotes);

	return [orderNotes, renderLabel] as [typeof orderNotes, typeof renderLabel];
};
