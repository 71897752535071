import { webApiWithResponse } from "../../../../core/http/httpThunks";
import { Commission } from "../types";

export const updateCommisionApi = (userId: string, commission: Commission) =>
	webApiWithResponse<string>({
		method: "PUT",
		url: "listing/updcomm",
		params: {
			userId
		},
		data: commission
	});

export default updateCommisionApi;
