import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";
import { upsertBank } from "../thunks/upsertBank";
import { setFetchState } from "../banksDataReducer";
import { Bank } from "../types";

export const useBankUpsert = () => {
  const fetchState = useSelector<StoreType, FetchStateType>(
    (store) => store.data.listing.banks.fetchState
  );

  const dispatch = useDispatch();
  const dispatchUpsertBank = React.useCallback(
    (bank: Bank) => dispatch(upsertBank(bank)),
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(setFetchState({ type: "not-started" }));
  }, [dispatch]);

  return [fetchState, dispatchUpsertBank] as [
    typeof fetchState,
    typeof dispatchUpsertBank
  ];
};
