import CommissionPage from "./CommissionPage";
import { EditCommissionPage } from "./EditCommissionPage";
import { EditCommissionDetail } from "./details/EditCommissionDetail";
import { NavigationItem } from "../../../core/navigation/types";
import { commissionLocation, detailEdit, basicInfoEdit } from "./Locations";
export const commisionsComponents: NavigationItem[] = [
	{
		Component: CommissionPage,
		isMenuItem: true,
		path: commissionLocation,
	},
	{
		Component: EditCommissionDetail,
		isMenuItem: false,
		path: detailEdit,
	},
	{
		Component: EditCommissionPage,
		isMenuItem: false,
		path: basicInfoEdit,
	},
];
