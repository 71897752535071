import { PaymentData, PaymentValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const paymentValidationState: PaymentValidationState = {
  payDate: true,
  payType: true,
  clientId: true,
  amt: true,
};

export const usePaymentValidator = createUseValidator< PaymentData, PaymentValidationState>
(paymentValidationState,
  (item, validation) => ({
    ...validation,
    tranId: (item.tranId && item.hasOrdTran===2) || (!item.tranId && item.hasOrdTran!==2)
  })
  );
