import { PaymentListData } from "../../../data/clearing/payments/types";
import React from "react";
import { LocalizationContext } from "../../../core/localization/Localization";
import { Column } from "material-table";

export const usePaymentsColumns = () => {
	const { getString, getDateString, getFormatedNumberString } = React.useContext(LocalizationContext);

	return React.useMemo(
		() => [
			{
				title: getString("paymentDataTime"),
				render: payment => getDateString(payment.payDate, "DD.MM.YYYY"),
				sorting: false,
			},
			{
				title: getString("paymentClientCode"),
				field: "clientIdNo",
				sorting: false,
			},
			{
				title: getString("paymentClientName"),
				field: "clientName",
				sorting: false,
			},
			{
				title: getString("paymentPayType"),
				field: "payTypeDescr",
				sorting: false,
			},
			{
				title: getString("paymentClmOrdNo"),
				field: "orderNo",
				sorting: false,
			},
			{
				title: getString("paymentTranNo"),
				field: "tranNo",
				sorting: false,
			},
			{
				title: getString("paymentAmount"),
				render: payment => getFormatedNumberString(payment.amtPay, true),
				sorting: false,
				width: "140px",
				cellStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("paymentAmtBal"),
				render: payment => getFormatedNumberString(payment.amtBal, true),
				sorting: false,
				width: "140px",
				cellStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
			{
				title: getString("paymentAmtBalNonAlloc"),
				render: payment => getFormatedNumberString(payment.amtNonAllocBal, true),
				sorting: false,
				width: "140px",
				cellStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
				headerStyle: {
					maxWidth: "140px",
					minWidth: "140px",
					width: "140px",
//					whiteSpace: "nowrap",
					textAlign: "end",
					paddingRight: "10px",
				},
			},
		],
		[getString, getFormatedNumberString, getDateString]
	) as Column<PaymentListData>[];
};
