import { webApi } from "../../../../core/http/httpThunks";

export const deleteBankApi = (userId: string, bankid: string) =>
  webApi<string>({
    method: "DELETE",
    url: "listing/delbank",
    params: {
      userId,
      bankid,
    },
  });

export default deleteBankApi;
