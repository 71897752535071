import { ThunkType } from "../../../../core/store/reducers";
import createWhsApi from "../api/createWhs";
import updateWhsApi from "../api/updateWhs";
import { setFetchState } from "../warehousesDataReducer";
import { Warehouse } from "../types";

export const upsertWhs = (whs: Warehouse): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setFetchState({ type: "started" }));

	const { userId } = store.data.auth.current.user;
	const result = whs.whsId
		? await dispatch(updateWhsApi(userId, whs))
		: await dispatch(createWhsApi(userId, whs));

	if (result && result.resCode === "OK") {
		dispatch(setFetchState({ type: "successful", result: result.resDesc }));
		return result.resDesc;
	} else {
		dispatch(setFetchState({ type: "error", errorCode: result?.resCode }));
	}
};
