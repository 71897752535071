import { Commission, ProductValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const commissionValidationState: ProductValidationState = {
	commCode: true,
	commName: true,
};

export const useCommissionValidator = createUseValidator<Commission, ProductValidationState>(
	commissionValidationState
);
