import { ThunkType } from "../../../../core/store/reducers";
import getUserApi from "../api/getUser";
import { setDisplayed } from "../usersDataReducer";

export const loadRole = (roleId: string): ThunkType => async (dispatch, getStore) => {
	const store = getStore();

	if (store.data.auth.current.status !== "successful") {
		return;
	}

	dispatch(setDisplayed());

	const { userId } = store.data.auth.current.user;
	const user = await dispatch(getUserApi(userId, roleId));

	dispatch(setDisplayed(user));
};
