import { Currency } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";

interface ICurrencyData {
	all?: Currency[];
	searchTerm: string;
	displayData?: Currency[];
	displayed?: Currency;
	fetchState: FetchStateType;
}

const initialState: ICurrencyData = {
	searchTerm: "",
	fetchState: { type: "not-started" },
};

const name = "currencies";

const currencySlice = createSlice({
	name,
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<Currency[] | undefined>) => ({
			...state,
			all: action.payload,
			displayData: filterData(action.payload, state.searchTerm),
		}),
		setSearchTerm: (state, action: PayloadAction<string>) => ({
			...state,
			searchTerm: action.payload.toLocaleLowerCase(),
			displayData: filterData(state.all, action.payload),
		}),
		setDisplayed: (state, action: PayloadAction<Currency | undefined>) => ({
			...state,
			displayed: action.payload,
		}),
		setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
			...state,
			displayed: action.payload.type === "successful" ? undefined : state.displayed,
			all: action.payload.type === "successful" ? undefined : state.all,
			displayData: action.payload.type === "successful" ? undefined : state.displayData,
			fetchState: getFetchStateChange(state.fetchState, action.payload),
		}),
	},
});

const filterData = (data?: Currency[], searchTerm?: string) =>
	searchTerm === undefined || searchTerm === ""
		? data
		: data?.filter(
				e =>
					(e.currCode &&
						e.currCode
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
					(e.currName &&
						e.currName
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
					(e.numCode &&
						e.numCode
							.toLocaleLowerCase()
							.includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase()))
		  );

export const { setData, setSearchTerm, setDisplayed, setFetchState } = currencySlice.actions;

export default currencySlice.reducer;
