import React from "react";
import { useClientBroker } from "./useClientBroker";
import { ClientBroker } from "../../types";
import { Status } from "../../../../../data/models";
import { useCopiedState } from "../../../../../core/util/hooks";

export const createDefaultBroker = (clientId: string): ClientBroker => ({
	brokIdNo: "",
	brokName: "",
	clientId: clientId,
	status: Status.Active,
});

export const useEditClientBroker = (clientId: string, clientPersonId?: string) => {
	const [displayClient] = useClientBroker(clientPersonId);

	return React.useMemo(() => {
		if (clientPersonId !== undefined && displayClient === undefined) {
			return undefined;
        }

		if (clientPersonId === undefined) {
			const defaultPerson = createDefaultBroker(clientId);
			return { ...defaultPerson };
        }

		return displayClient ? { ...displayClient } : undefined;
	}, [clientId, clientPersonId, displayClient]);
};

export const useEditClientBrokerState = (clientId: string, clientPersonId?: string) => {
	const broker = useEditClientBroker(clientId, clientPersonId);
	return useCopiedState(broker)
};
