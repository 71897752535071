import { webApi } from "../../../../core/http/httpThunks";
import { PayType } from "../types";

export const getPayTypeApi = (userId: string, paytypeid: string) =>
  webApi<PayType>({
    method: "GET",
    url: "listing/getpaytype",
    params: {
      userId,
      paytypeid,
    },
  });

export default getPayTypeApi;
