import { User } from "../../../../auth/types";
import { httpWebApi } from "../../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../../core/util/functions";
import { ClientOverviewFilter } from "../types";

export const getOrderListReport = (user: User, filter?: ClientOverviewFilter) => {
	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/getorderbookreport",
		params: {
			userid: user.userId,
			status: filter?.orderStatus?.ftValue,
			cliid: filter?.client?.cliId,
			datefrom: getParamsDateString(filter?.datefrom),
			dateto: getParamsDateString(filter?.dateto),
			clicard: "C",
		},
	});
};

export default getOrderListReport;
