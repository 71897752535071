import React from "react";
import { ContentLayout } from "../layout/ContentLayout";
import { LoginPage } from "./login/LoginPage";
import { PageRouterSwitch } from "./navigation/PageRouterSwitch";
import { useAuth } from "../data/auth/hooks";
import { ThemeComponent } from "../layout/Theme";
import { SnackbarMessages } from "./snackbar/SnackbarMessages";
import { NotificationContextProvider } from "../core/notifications/NotificationContext";
import { appConfig } from "../core/config/config";
import { SurveillanceNotificationHandlers } from "./surveillance/SurveillanceNotificationHandlers";
import { canRecieveNotifications } from "../core/notifications/hooks";
import { useShouldConnectNotifications } from "./navigation/hooks/useNavigationItems";
import { PushNotificationProvider } from "../core/notifications/PushNotifications";

export const ContentRoot: React.FunctionComponent = props => {
	const [auth, , , token] = useAuth();
	const shouldUseNotifications = useShouldConnectNotifications();

	return (
		<ThemeComponent>
			<SnackbarMessages>
				{auth.status === "successful" && token ? (
					<NotificationContextProvider
						hubUrl={appConfig.notifications.uri}
						token={token}
						connect={canRecieveNotifications(auth.user) || shouldUseNotifications}
						//connect={false}
					>
						<ContentLayout>
							<>
								<SurveillanceNotificationHandlers />
								<PageRouterSwitch />
							</>
						</ContentLayout>
					</NotificationContextProvider>
				) : (
					<LoginPage />
				)}
			</SnackbarMessages>
			<PushNotificationProvider
				authToken={(auth as any)?.user?.token}
				isAuth={auth.status === "successful"}
			/>
		</ThemeComponent>
	);
};
