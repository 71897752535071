import { useDispatch, useSelector } from "react-redux";
import { downloadOrdersReportExcel } from "../thunks/downloadReportExcel";
import React from "react";
import { StoreType } from "../../../../core/store/reducers";
import { FetchStateType } from "../../../models";

export const useOrderExcelReportDownloader = () => {
	const fetchState = useSelector<StoreType, FetchStateType>(
		s => s.data.reports.ordersReport.downloadReportFetchState
	);
	const dispatch = useDispatch();
	const download = React.useCallback(() => dispatch(downloadOrdersReportExcel()), [dispatch]);

	return [download, fetchState] as [typeof download, typeof fetchState];
};
