import React from "react";
import { useDispSitList } from "../../../data/initialData/hooks/useDispSitList";
import { DispSitList } from "../../../data/models";
import { AutocompleteSelect, AutocompleteSelectType } from "./AutocompleteSelect";

type DispSitListSelectPropsType = Omit<AutocompleteSelectType<DispSitList>, "data" | "getLabel">;

export const DispSitListSelect: React.FunctionComponent<DispSitListSelectPropsType> = props => {
	const [groups, renderGroupLabel] = useDispSitList();

	return (
		<AutocompleteSelect<DispSitList>
			{...(props as AutocompleteSelectType<DispSitList>)}
			data={groups}
			getLabel={renderGroupLabel}
		/>
	);
};
