import { User } from "../../../auth/types";
import { httpWebApi } from "../../../../core/http/httpThunks";
import { getParamsDateString } from "../../../../core/util/functions";
import { GetTradesParams, TradeFilter } from "../../../trading/trades/types";

export const getTradeBookExcel = (
	user: User,
	filter?: TradeFilter,
) => {
	const params: GetTradesParams = {
		userid: user.userId,
		prodid: filter?.product?.prodId,
		status: filter?.tradeStatus?.ftValue,
		datefrom: getParamsDateString(filter?.datefrom),
		dateto: getParamsDateString(filter?.dateto),
		market: filter?.market,
		clientid: user.roleCode !== "MEMTRADER" &&  user.roleCode !== "MEMMANAGER" &&  user.roleCode !== "MEMCONTACT"? filter?.client?.cliId : "",
		client2id: user.roleCode === "MEMTRADER" ||  user.roleCode === "MEMMANAGER" ||  user.roleCode === "MEMCONTACT"? filter?.client?.cliId : "",
		};

	return httpWebApi<Blob>({
		method: "GET",
		responseType: "blob",
		timeout: 60 * 4 * 1000,
		url: "reporting/gettradebookxls",
		params,
	});
};

export default getTradeBookExcel;
