import React from "react";
import { useDisplayRole } from "./useDisplayRole";
import { Role } from "../types";
import { useCopiedState } from "../../../../core/util/hooks";

export const defauiltRole: Role = {
	roleCode: "",
	roleName: "",
	roleNameEn: "",
	isEmployee: 0,
};

export const useEditRole = (roleId?: string) => {
	const displayRole = useDisplayRole(roleId);

	return React.useMemo(() => {
		if (roleId !== undefined && displayRole === undefined) {
			return undefined;
        }

		if (roleId === undefined) {
			return { ...defauiltRole };
        }

		return displayRole ? { ...displayRole } : undefined;
	}, [roleId, displayRole]);
};

export const useEditRoleState = (empId?: string) => {
	const role = useEditRole(empId);
	return useCopiedState(role)
};
